import FalconComponentCard from 'components/common/FalconComponentCard'
import PageHeader from 'components/common/PageHeader'
import IconButton from 'components/common/IconButton'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Dropdown } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from 'utils'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Select from 'react-select'
import LoadingIcon from 'helpers/LoadingIcon'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import Flex from 'components/common/Flex'
import Swal from 'sweetalert2'
import { authHeaderForm } from 'state/ducs/auth/utils'

const DepositFormForUpdate = () => {
    const [depositById, setDepositById] = useState([])
    const [banksList, setBanksList] = useState([])
    const [bankAccounts, setBankAccounts] = useState([])
    const [imageFile, setImageFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [imageLoading, setImageLoading] = useState(false)
    const [files, setFiles] = useState(null)
    const [imageDatabaseId, setImageDatabaseId] = useState(null)
    const { updateID } = useParams()
    const navigate = useNavigate()
    // const distributorInfo = useSelector((state) => state.auth.user.data)

    const [distributorInfo, setDistributorInfo] = useState(null)
    // const distributorInfo = useSelector((state) => state.auth.user.data)

    console.log('files', files)

    //  Date Format
    function formatDateToYYYYMMDD(isoDateString) {
        const date = new Date(isoDateString)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0') // Month is 0-based
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    const AllBanksList = [
        { value: 'AB Bank PLC', label: 'AB Bank PLC', sortForm: 'AB' },
        {
            value: 'Agrani Bank PLC',
            label: 'Agrani Bank PLC',
            sortForm: 'Agrani',
        },
        {
            value: 'Al-Arafah Islami Bank PLC',
            label: 'Al-Arafah Islami Bank PLC',
            sortForm: 'Al-AIB',
        },
        {
            value: 'Bangladesh Commerce Bank Limited',
            label: 'Bangladesh Commerce Bank Limited',
            sortForm: 'BCB',
        },
        {
            value: 'Bangladesh Development Bank PLC',
            label: 'Bangladesh Development Bank PLC',
            sortForm: 'BDB',
        },
        {
            value: 'Bangladesh Krishi Bank',
            label: 'Bangladesh Krishi Bank',
            sortForm: 'BKB',
        },
        {
            value: 'Bank Al-Falah Limited',
            label: 'Bank Al-Falah Limited',
            sortForm: 'AlFalah',
        },
        { value: 'Bank Asia PLC.', label: 'Bank Asia PLC.', sortForm: 'BAsia' },
        {
            value: 'BASIC Bank Limited',
            label: 'BASIC Bank Limited',
            sortForm: 'Basic',
        },
        {
            value: 'Bengal Commercial Bank PLC.',
            label: 'Bengal Commercial Bank PLC.',
            sortForm: 'BengalCB',
        },
        { value: 'BRAC Bank PLC', label: 'BRAC Bank PLC', sortForm: 'Barc' },
        { value: 'Citibank N.A', label: 'Citibank N.A', sortForm: 'CityNA' },
        {
            value: 'Citizens Bank PLC',
            label: 'Citizens Bank PLC',
            sortForm: 'Citizen',
        },
        { value: 'City Bank PLC', label: 'City Bank PLC', sortForm: 'City' },
        {
            value: 'Commercial Bank of Ceylon Limited',
            label: 'Commercial Bank of Ceylon Limited',
            sortForm: 'CBC',
        },
        {
            value: 'Community Bank Bangladesh PLC.',
            label: 'Community Bank Bangladesh PLC.',
            sortForm: 'Community',
        },
        { value: 'Dhaka Bank PLC', label: 'Dhaka Bank PLC', sortForm: 'Dhaka' },
        {
            value: 'Dutch-Bangla Bank PLC',
            label: 'Dutch-Bangla Bank PLC',
            sortForm: 'DBBL',
        },
        {
            value: 'Eastern Bank PLC',
            label: 'Eastern Bank PLC',
            sortForm: 'EB',
        },
        { value: 'EXIM Bank PLC', label: 'EXIM Bank PLC', sortForm: 'EXIM' },
        {
            value: 'First Security Islami Bank PLC',
            label: 'First Security Islami Bank PLC',
            sortForm: 'FSIB',
        },
        {
            value: 'Global Islami Bank PLC',
            label: 'Global Islami Bank PLC',
            sortForm: 'Global',
        },
        {
            value: 'Habib Bank Ltd.',
            label: 'Habib Bank Ltd.',
            sortForm: 'Habib',
        },
        {
            value: 'ICB Islamic Bank Ltd.',
            label: 'ICB Islamic Bank Ltd.',
            sortForm: 'ICB',
        },
        { value: 'IFIC Bank PLC', label: 'IFIC Bank PLC', sortForm: 'IFIC' },
        {
            value: 'Islami Bank Bangladesh PLC',
            label: 'Islami Bank Bangladesh PLC',
            sortForm: 'IBBL',
        },
        {
            value: 'Jamuna Bank PLC',
            label: 'Jamuna Bank PLC',
            sortForm: 'Jamuna',
        },
        {
            value: 'Janata Bank PLC',
            label: 'Janata Bank PLC',
            sortForm: 'Janata',
        },
        {
            value: 'Meghna Bank PLC',
            label: 'Meghna Bank PLC',
            sortForm: 'Meghna',
        },
        {
            value: 'Mercantile Bank PLC',
            label: 'Mercantile Bank PLC',
            sortForm: 'Mercantile',
        },
        {
            value: 'Midland Bank Limited',
            label: 'Midland Bank Limited',
            sortForm: 'Midland',
        },
        {
            value: 'Modhumoti Bank PLC',
            label: 'Modhumoti Bank PLC',
            sortForm: 'Modhumoti',
        },
        {
            value: 'Mutual Trust Bank PLC',
            label: 'Mutual Trust Bank PLC',
            sortForm: 'Mutual',
        },
        {
            value: 'Nagad Digital Bank PLC.',
            label: 'Nagad Digital Bank PLC.',
            sortForm: 'Nagad',
        },
        {
            value: 'National Bank Limited',
            label: 'National Bank Limited',
            sortForm: 'National',
        },
        {
            value: 'National Bank of Pakistan',
            label: 'National Bank of Pakistan',
            sortForm: 'NBP',
        },
        {
            value: 'National Credit & Commerce Bank PLC',
            label: 'National Credit & Commerce Bank PLC',
            sortForm: 'NCCB',
        },
        {
            value: 'NRB Bank Limited',
            label: 'NRB Bank Limited',
            sortForm: 'NRB',
        },
        { value: 'NRBC Bank PLC', label: 'NRBC Bank PLC', sortForm: 'NRBC' },
        { value: 'One Bank PLC', label: 'One Bank PLC', sortForm: 'One' },
        { value: 'Padma Bank PLC', label: 'Padma Bank PLC', sortForm: 'Padma' },
        { value: 'Prime Bank PLC', label: 'Prime Bank PLC', sortForm: 'Prime' },
        {
            value: 'Probashi Kollyan Bank',
            label: 'Probashi Kollyan Bank',
            sortForm: 'PROBASHI',
        },
        {
            value: 'Pubali Bank PLC',
            label: 'Pubali Bank PLC',
            sortForm: 'PUBALI',
        },
        {
            value: 'Rajshahi Krishi Unnayan Bank',
            label: 'Rajshahi Krishi Unnayan Bank',
            sortForm: 'RKUB',
        },
        {
            value: 'Rupali Bank PLC',
            label: 'Rupali Bank PLC',
            sortForm: 'Rupali',
        },
        { value: 'SBAC Bank PLC', label: 'SBAC Bank PLC', sortForm: 'SBAC' },
        {
            value: 'Shahjalal Islami Bank PLC',
            label: 'Shahjalal Islami Bank PLC',
            sortForm: 'SIBL',
        },
        {
            value: 'Shimanto Bank PLC',
            label: 'Shimanto Bank PLC',
            sortForm: 'Shimanto',
        },
        {
            value: 'Social Islami Bank PLC',
            label: 'Social Islami Bank PLC',
            sortForm: 'SIB',
        },
        {
            value: 'Sonali Bank PLC',
            label: 'Sonali Bank PLC',
            sortForm: 'Sonali',
        },
        {
            value: 'Southeast Bank PLC',
            label: 'Southeast Bank PLC',
            sortForm: 'Southeast',
        },
        {
            value: 'Standard Bank PLC',
            label: 'Standard Bank PLC',
            sortForm: 'SB',
        },
        {
            value: 'Standard Chartered Bank',
            label: 'Standard Chartered Bank',
            sortForm: 'SCB',
        },
        {
            value: 'State Bank of India',
            label: 'State Bank of India',
            sortForm: 'SBI',
        },
        {
            value: 'The Hong Kong and Shanghai Banking Corporation. Ltd.',
            label: 'The Hong Kong and Shanghai Banking Corporation. Ltd.',
            sortForm: 'HKSBC',
        },
        {
            value: 'The Premier Bank PLC',
            label: 'The Premier Bank PLC',
            sortForm: 'Premier',
        },
        {
            value: 'Trust Bank Limited',
            label: 'Trust Bank Limited',
            sortForm: 'Trust',
        },
        { value: 'Union Bank PLC', label: 'Union Bank PLC', sortForm: 'Union' },
        {
            value: 'United Commercial Bank PLC',
            label: 'United Commercial Bank PLC',
            sortForm: 'UCB',
        },
        {
            value: 'Uttara Bank PLC',
            label: 'Uttara Bank PLC',
            sortForm: 'Uttara',
        },
        { value: 'Woori Bank', label: 'Woori Bank', sortForm: 'Woori' },
    ]
    // Get Image
    const getImage = async (imageId) => {
        setIsLoading(true)
        setImageLoading(true)
        try {
            const response = await axios.get(
                `https://khoz.net:3015/api/v1/image/${imageId}`,
                { headers: authHeader() }
            )
            if (response.data.success && response.data.payload) {
                const base64Image = response.data.payload.imageData.image

                // Check if the base64 string contains the data URL prefix and remove it if present
                const base64Prefix = 'data:image/jpeg;base64,'
                let base64String
                if (base64Image.startsWith(base64Prefix)) {
                    base64String = base64Image.substring(base64Prefix.length)
                } else {
                    base64String = base64Image
                }

                // Convert base64 to Blob
                try {
                    const byteCharacters = atob(base64String)
                    const byteNumbers = new Array(byteCharacters.length)
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i)
                    }
                    const byteArray = new Uint8Array(byteNumbers)
                    const blob = new Blob([byteArray], { type: 'image/jpeg' })

                    // Create a URL for the Blob and open it in a new tab
                    const imageUrl = URL.createObjectURL(blob)
                    setFiles({
                        preview: imageUrl,
                        path: imageId,
                        imageDatabseId: response.data.payload.imageData?._id,
                    })
                    setImageDatabaseId(response.data.payload.imageData?._id)
                    setIsLoading(false)
                    setImageLoading(false)

                } catch (e) {
                    console.error('Error decoding base64 string:', e)
                }
            } else {
                console.error('Failed to fetch image:', response.data.message)
            }
        } catch (error) {
            console.error('Error fetching the image:', error)
        }
    }

    // Deposit by ID
    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true)
                try {
                    const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/deposit/${updateID}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success) {
                        const data = await response.data.payload.depositData
                        setDepositById(data)
                        getImage(data?.trId)

                        setDistributorInfo({
                            userId: data?.dealerId,
                            phoneNumber: data?.depositorPhone,
                            distributorName: data?.dealerName,
                            userRole: data?.dbType,
                            companyName: data?.companyName,
                            dbPoint: data?.dbPoint,
                            areaName: data?.area,
                            regionName: data?.region,
                            zoneName: data?.zone,
                        });
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading(false)
                }
            }
        }

        fetchData()
    }, [updateID])

    //banksList
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}bank`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success) {
                    const data = await response.data.payload.result
                    console.log('data', data)

                    // Use reduce() to format data
                    const result = data.reduce((acc, currentItem) => {
                        const existingBank = acc.find(
                            (item) => item.bankName === currentItem.bankName
                        )
                        if (existingBank) {
                            existingBank.bankAccounts.push(
                                currentItem.bankAccountNo
                            )
                        } else {
                            acc.push({
                                bankName: currentItem?.bankName,
                                bankAccounts: [currentItem?.bankAccountNo],
                            })
                        }
                        return acc
                    }, [])

                    const bankList = result.map((item) => ({
                        value: item.bankName,
                        label: item.bankName,
                        bankAccounts: item?.bankAccounts?.map((account) => ({
                            value: account,
                            label: account,
                        })),
                    }))

                    setBanksList(bankList)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [])

    //Image Upload

    // Dropzone
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )[0]
            )

            // Set the first dropped file as the image file
            setImageFile(acceptedFiles[0])
        },
    })

    // Remove file
    const handleRemove = async () => {
        // Confirm deletion
        const confirmResult = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4c8118',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
        })

        setFiles(null)
        // setCount((prevCount) => prevCount + 1)
    }

    // Convert image to base64
    function imageToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
            reader.readAsDataURL(file)
        })
    }

    // submit form
    const handleSubmitForm = async (values, actions) => {
        let imageUploadResponse // Define imageUploadResponse outside the if block

        try {
            setIsLoading(true)
            if (imageFile) {
                // Upload image
                // const imageToBase64String = await imageToBase64(imageFile);



                const formData = new FormData()
                formData.append('image', imageFile)
                formData.append('dealerId', distributorInfo?.userId)
                formData.append('TrNo', values.trId)
                formData.append('paymentDate', values.paymentDate)


                // Log the FormData contents
                for (const [key, value] of formData.entries()) {
                    console.log(`formData ${key}:`, value);
                }


                if (updateID) {



                    imageUploadResponse = await axios.post(
                        'https://khoz.net:3015/api/v1/image/updateImage',
                        formData /* {
                        dealerId: distributorInfo?.userId,
                        TrNo: values.trId,
                        paymentDate: values.paymentDate,
                        image: imageToBase64String
                        image: imageFile
                    } */,
                        { headers: authHeaderForm() }
                    )


                    if (

                        imageUploadResponse.status !== 200
                        // ||
                        // !imageUploadResponse.data.status
                    ) {
                        console.log("imageUploadResponse", imageUploadResponse);
                        throw new Error('Image upload failed')
                    }
                } else {
                    imageUploadResponse = await axios.post(
                        'https://khoz.net:3015/api/v1/image/saveImage',
                        formData /* {
                        dealerId: distributorInfo?.userId,
                        TrNo: values.trId,
                        paymentDate: values.paymentDate,
                        image: imageToBase64String
                        image: imageFile
                    } */,
                        { headers: authHeaderForm() }
                    )

                    if (
                        imageUploadResponse.status !== 201 ||
                        !imageUploadResponse.data.status
                    ) {
                        throw new Error('Image upload failed')
                    }
                }
            }

            // Prepare data for submitting
            const handleSubmitData = {
                zone: distributorInfo.zoneName,
                region: distributorInfo.regionName,
                area: distributorInfo.areaName,
                dbPoint: distributorInfo.dbPoint,
                bankNames: values.bankNames.value,
                bankAccountNo: values.bankAccountNo.value,
                depositorsBankName: values.depositorsBankName.value,
                depositorsBranchName: values.depositorsBranchName,
                depositorPhone: values.depositorPhone,
                drBDT: values.BDTAmount,
                paymentNote: values.note,
                distributorId: distributorInfo.userId,
                companyName: distributorInfo.companyName,
                dealerId: distributorInfo.userId,
                dbType: distributorInfo.userRole,
                dealerName: distributorInfo.distributorName,
                DealerPhone: distributorInfo.phoneNumber,
                paymentDate: values.paymentDate,
                trId: values.trId,
            }

            /*  if (imageUploadResponse && imageUploadResponse.data.status) { // Check if imageUploadResponse exists
                 handleSubmitData.image = imageUploadResponse.data.data.url;
                 handleSubmitData.deleteImageUrl = imageUploadResponse.data.data.delete_url;
             } */

            // Submit data
            const url = updateID
                ? `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/deposit/${updateID}`
                : `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/create`
            const axiosMethod = updateID ? axios.put : axios.post
            const response = await axiosMethod(url, handleSubmitData, {
                headers: authHeader(),
            })

            if (response.status === 200 || response.data.status) {
                setIsLoading(false)
                navigate('/master/deposits')
                toast.success('successfully submitted')
            }
        } catch (error) {
            console.error('Error submitting form:', error)
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading(false)
        }
    }

    // Yup validation schema
    const handleSchema = Yup.object().shape({
        bankNames: Yup.object().required('Bank Name is required'),
        bankAccountNo: Yup.object().required('Bank Account No is required'),
        depositorsBankName: Yup.object().required(
            'Depositors Bank Name is required'
        ),
        depositorsBranchName: Yup.string().required(
            'Depositors Branch Name is required'
        ),
        depositorPhone: Yup.string().required('Depositor Phone is required'),
        BDTAmount: Yup.number().required('BDT Amount is required'),
        paymentDate: Yup.string().required('Payment Date is required'),
        note: Yup.string().nullable(),
        trId: Yup.string().nullable(),
    })

    return (
        <>
            <PageHeader
                title={updateID ? 'Update Deposit' : 'Add Deposit'}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard>
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    {/* {isLoading && <LoadingIcon />} */}
                    {isLoading && <h4>Loading...</h4>}
                    <Formik
                        initialValues={{
                            bankNames:
                                banksList.find(
                                    (item) =>
                                        item.value === depositById?.bankNames
                                ) || '',
                            bankAccountNo:
                                bankAccounts.find(
                                    (item) =>
                                        item.value ===
                                        depositById?.bankAccountNo
                                ) || '',
                            depositorsBankName:
                                AllBanksList.find(
                                    (item) =>
                                        item.value ===
                                        depositById?.depositorsBankName
                                ) || '',
                            depositorsBranchName:
                                depositById?.depositorsBranchName || '',
                            depositorPhone: depositById?.depositorPhone || '',
                            BDTAmount: depositById?.drBDT || '',
                            paymentDate: depositById?.paymentDate
                                ? formatDateToYYYYMMDD(depositById?.paymentDate)
                                : '',
                            note: depositById?.paymentNote || '',
                            trId: depositById?.trId || '',
                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched,
                        }) => {
                            if (values.bankNames) {
                                setBankAccounts(values.bankNames?.bankAccounts)
                            }
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-2">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select Company Bank
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={banksList}
                                                placeholder="Select Company Bank"
                                                classNamePrefix="react-select"
                                                name="bankNames"
                                                value={values.bankNames} // Assuming values.bankNames is a string or null
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'bankNames',
                                                        selectedOption // Assuming selectedOption is a string or null
                                                    )
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {errors.bankNames && (
                                                <div style={{ color: 'red' }}>
                                                    {errors.bankNames}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select Bank Account
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={bankAccounts}
                                                placeholder="Select Bank Account"
                                                classNamePrefix="react-select"
                                                name="bankAccountNo"
                                                value={values.bankAccountNo} // Assuming values.bankNames is a string or null
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'bankAccountNo',
                                                        selectedOption // Assuming selectedOption is a string or null
                                                    )
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {errors.bankAccountNo && (
                                                <div style={{ color: 'red' }}>
                                                    {errors.bankAccountNo}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-2">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select Depositors Bank
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={AllBanksList}
                                                placeholder="Select Depositors Bank"
                                                classNamePrefix="react-select"
                                                name="depositorsBankName"
                                                value={
                                                    values.depositorsBankName
                                                } // Assuming values.bankNames is a string or null
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'depositorsBankName',
                                                        selectedOption // Assuming selectedOption is a string or null
                                                    )
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {errors.depositorsBankName && (
                                                <div style={{ color: 'red' }}>
                                                    {errors.depositorsBankName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleState"
                                        >
                                            <Form.Label>
                                                Depositor Branch Name
                                            </Form.Label>
                                            <Form.Control
                                                name="depositorsBranchName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Depositor Branch Name"
                                                required
                                                value={
                                                    values.depositorsBranchName
                                                }
                                            />
                                            {touched.depositorsBranchName &&
                                                errors.depositorsBranchName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {
                                                            errors.depositorsBranchName
                                                        }
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-2">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleState"
                                        >
                                            <Form.Label>
                                                Depositor Cell No
                                            </Form.Label>
                                            <Form.Control
                                                name="depositorPhone"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Depositor Cell No"
                                                required
                                                value={values.depositorPhone}
                                            />
                                            {touched.depositorPhone &&
                                                errors.depositorPhone && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.depositorPhone}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleState"
                                        >
                                            <Form.Label>BDT Amount</Form.Label>
                                            <Form.Control
                                                name="BDTAmount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter BDT Amount"
                                                required
                                                value={values.BDTAmount}
                                            />
                                            {touched.BDTAmount &&
                                                errors.BDTAmount && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.BDTAmount}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleState"
                                        >
                                            <Form.Label>
                                                Payment Date
                                            </Form.Label>
                                            <Form.Control
                                                name="paymentDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Select Date"
                                                value={values.paymentDate}
                                            />
                                            {touched.paymentDate &&
                                                errors.paymentDate && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.paymentDate}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleState"
                                        >
                                            <Form.Label>
                                                Tran. Id/Deposit Slip No. /Ref.
                                                No
                                            </Form.Label>
                                            <Form.Control
                                                name="trId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Transaction ID"
                                                value={values.trId}
                                            />
                                            {touched.trId && errors.trId && (
                                                <div style={{ color: 'red' }}>
                                                    {errors.trId}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleState"
                                        >
                                            <Form.Label>Note</Form.Label>
                                            <Form.Control
                                                name="note"
                                                as={'textarea'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Note"
                                                value={values.note}
                                            />
                                            {touched.note && errors.note && (
                                                <div style={{ color: 'red' }}>
                                                    {errors.note}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    {imageLoading && <h4>Loading...</h4>}


                                    {values?.trId && (
                                        <>
                                            <div
                                                {...getRootProps({
                                                    className:
                                                        'dropzone-area py-6',
                                                })}
                                            >
                                                <input
                                                    name="uploadedFiles"
                                                    {...getInputProps()}
                                                />
                                                <div className="fs--1">
                                                    <span className="d-none d-lg-inline">
                                                        Drag your image here
                                                        <br />
                                                        Or,Please upload the
                                                        image less than 8MB{' '}
                                                    </span>
                                                    <Button
                                                        variant="link"
                                                        size="sm"
                                                        className="p-0 fs--1"
                                                    >
                                                        Browse
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                {files ? (
                                                    <Flex
                                                        alignItems="center"
                                                        className="py-3 border-bottom btn-reveal-trigger"
                                                        key={files.path}
                                                    >
                                                        <img
                                                            className="rounded"
                                                            width={40}
                                                            height={40}
                                                            src={files.preview}
                                                            alt={files.path}
                                                        />
                                                        <Flex
                                                            justifyContent="between"
                                                            alignItems="center"
                                                            className="ms-3 flex-1"
                                                        >
                                                            <div>
                                                                <h6>
                                                                    {files.path}
                                                                </h6>
                                                                <Flex
                                                                    className="position-relative"
                                                                    alignItems="center"
                                                                >
                                                                    <p className="mb-0 fs--1 text-400 line-height-1">
                                                                        <strong>
                                                                            {
                                                                                files.size
                                                                            }{' '}
                                                                            bytes
                                                                        </strong>
                                                                    </p>
                                                                </Flex>
                                                            </div>
                                                        </Flex>
                                                        <Dropdown>
                                                            <div className="py-2">
                                                                <Dropdown.Item
                                                                    className="text-danger"
                                                                    onClick={() =>
                                                                        handleRemove(
                                                                            values?.trId
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </Dropdown.Item>
                                                            </div>
                                                        </Dropdown>
                                                    </Flex>
                                                ) : null}
                                            </div>
                                        </>
                                    )}

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? 'Save' : 'Submit'}
                                    </IconButton>
                                    <Button
                                        onClick={() =>
                                            navigate('/master/deposit')
                                        }
                                        variant="danger"
                                        type="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    )
}

export default DepositFormForUpdate
