import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Col, Form, Nav, ProgressBar, Row, Tab } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import FalconLink from 'components/common/FalconLink';
import Select from "react-select";
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardHeader from 'components/common/FalconCardHeader';
import DatePicker from 'react-datepicker';
import axios, { all } from 'axios'
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import Swal from 'sweetalert2'
import OrderAndPaymentSummery from './OrderAndPaymentSummery';
import Details from './Details';
import { Formik } from "formik";

const Index = () => {
    const [count, setCount] = useState(0);
    const [ordersAndPaymentSummery, setOrdersAndPaymentSummery] = useState({});
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [zoneList, setZoneList] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [allRegion, setAllRegion] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [allArea, setAllArea] = useState([]);
    const [selectedArea, setSelectedArea] = useState(null);
    const [allDbPoint, setAllDbPoint] = useState([]);
    const [selectedDbPoint, setSelectedDbPoint] = useState(null);
    const [allDistributor, setAllDistributor] = useState([]);
    const [filterDistributor, setFilterDistributor] = useState([]);
    const [selectedDb, setSelectedDb] = useState(null);
    const [isLoading, setIsLoading] = useState({
        general: false,
        zone: false,
        region: false,
        area: false,
        dbPoint: false,
        distributor: false
    })

    // get zone
    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, zone: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setZoneList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, zone: false }));
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedZone) {
            setIsLoading(prevState => ({ ...prevState, region: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.regionName,
                            value: data.regionName
                        }));

                        setAllRegion(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, region: false }));
                }
            };

            fetchData();
        }
    }, [selectedZone]);

    // get area name
    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, area: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}areas/${selectedRegion}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.areaName,
                        value: data.areaName
                    }));

                    setAllArea(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, area: false }));
            }

        }
        fetchData();
    }, [selectedRegion]);

    // db points list
    useEffect(() => {
        if (selectedArea) {
            setIsLoading(prevState => ({ ...prevState, dbPoint: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`;
                    const response = await axios.get(url, { headers: authHeader() });
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.dbPointName,
                            value: data.dbPointName
                        }));
                        setAllDbPoint(modifyData);
                    }
                }
                catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, dbPoint: false }));
                }
            }
            fetchData();
        }
    }, [selectedArea])

    useEffect(() => {
        if (selectedDbPoint) {
            setIsLoading(prevState => ({ ...prevState, distributor: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?dbPoint=${selectedDbPoint}`
                    const response = await axios.get(url, { headers: authHeader() });
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.distributors.map(data => ({
                            id: data.userId,
                            label: `${data.distributorName} - ${data.userId}`,
                            value: data.userId
                        }));
                        setFilterDistributor(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, distributor: false }));
                }
            }
            fetchData();
        }

    }, [selectedDbPoint])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(prevState => ({ ...prevState, distributor: true }));
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors`
                const response = await axios.get(url, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.distributors.map(data => ({
                        id: data.userId,
                        label: `${data.distributorName} - ${data.userId}`,
                        value: data.userId
                    }));
                    setAllDistributor(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, distributor: false }));
            }
        }
        fetchData();


    }, [])

    // Handle Search Data Here
    const handleSubmitForm = async (values, actions) => {
        console.log("values", values);
        if (!values.dbName) {
            Swal.fire({
                icon: 'error',
                title: 'Please select a DB',
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }
        if (values.fromDate && !values.toDate) {
            Swal.fire({
                icon: 'error',
                title: 'Please select a To Date',
                showConfirmButton: false,
                timer: 1500
            });
        }

        const fetchData = async () => {
            const url1 = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBReconcilation`;
            const url2 = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBReconcilationDemandOrder`;
            const url3 = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBReconcilationDemandOrderTk`;
            const url4 = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBReconcilationDemandOrderTkApproved`;
            const url5 = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalDepositPending`;
            const url6 = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalDepositApproved`;
            const url7 = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalExpense`;
            const dbProfileUrl = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dealer/${selectedDb}`;
            const url9 = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalCrExpense`;
            try {
                setIsLoading(prevState => ({ ...prevState, general: true }));

                const [response1, response2, response3, response4, response5, response6, response7, response8, response9] = await Promise.all([
                    axios.post(url1, {
                        dealerId: selectedDb,
                        DateFrom: selectedFromDate,
                        DateTo: selectedToDate
                    }, { headers: authHeader() }),
                    axios.post(url2, {
                        dealerId: selectedDb,
                        DateFrom: selectedFromDate,
                        DateTo: selectedToDate
                    }, { headers: authHeader() }),
                    axios.post(url3, {
                        dealerId: selectedDb,
                        DateFrom: selectedFromDate,
                        DateTo: selectedToDate
                    }, { headers: authHeader() }),
                    axios.post(url4, {
                        dealerId: selectedDb,
                        DateFrom: selectedFromDate,
                        DateTo: selectedToDate
                    }, { headers: authHeader() }),
                    axios.post(url5, {
                        dealerId: selectedDb,
                        DateFrom: selectedFromDate,
                        DateTo: selectedToDate
                    }, { headers: authHeader() }),
                    axios.post(url6, {
                        dealerId: selectedDb,
                        DateFrom: selectedFromDate,
                        DateTo: selectedToDate
                    }, { headers: authHeader() }),
                    axios.post(url7, {
                        dealerId: selectedDb,
                        DateFrom: selectedFromDate,
                        DateTo: selectedToDate
                    }, { headers: authHeader() }),
                    axios.get(dbProfileUrl, { headers: authHeader() }),
                    axios.post(url9, {
                        dealerId: selectedDb,
                        DateFrom: selectedFromDate,
                        DateTo: selectedToDate
                    }, { headers: authHeader() }),
                ]);

                if (response1.data.success || response1.status === 200) {
                    setOrdersAndPaymentSummery(prevState => ({
                        ...prevState, totalDemandOrder: response1.data.payload.distinctDoNoCount,
                    }));
                }
                if (response2.data.success || response2.status === 200) {
                    setOrdersAndPaymentSummery(prevState => ({
                        ...prevState, totalApprovedDemandOrder: response2.data.payload.distinctDoNoCount,
                    }));
                }
                if (response3.data.success || response3.status === 200) {
                    setOrdersAndPaymentSummery(prevState => ({
                        ...prevState, totalDemandOrderTk: response3.data.payload.totalPrice,
                    }));
                }
                if (response4.data.success || response4.status === 200) {
                    setOrdersAndPaymentSummery(prevState => ({
                        ...prevState, totalApprovedDemandOrderTk: response4.data.payload.totalPrice,
                    }));
                }
                if (response5.data.success || response5.status === 200) {
                    setOrdersAndPaymentSummery(prevState => ({
                        ...prevState, totalDepositPending: response5.data.payload.drBDT,
                    }));
                }
                if (response6.data.success || response6.status === 200) {
                    setOrdersAndPaymentSummery(prevState => ({
                        ...prevState, totalDepositApproved: response6.data.payload.drBDT,
                    }));
                }
                if (response7.data.success || response7.status === 200) {
                    setOrdersAndPaymentSummery(prevState => ({
                        ...prevState, totalExpense: response7.data.payload.drBDT,
                    }));
                }
                if (response8.data.success || response8.status === 200) {
                    setOrdersAndPaymentSummery(prevState => ({
                        ...prevState, distributor: response8.data.payload.distributorData,
                    }));
                }
                if (response9.data.success || response9.status === 200) {
                    setOrdersAndPaymentSummery(prevState => ({
                        ...prevState, totalExpense: response9.data.payload.crBDT,
                    }));
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, general: false }));
            }
        };
        fetchData();
    }



    // if (isLoading.general) {
    //     return <LoadingIcon />
    // }

    return (
        <>
            <FalconComponentCard>
                <FalconCardHeader title="DB Reconciliation" />
                <FalconComponentCard.Body>

                    <Formik
                        initialValues={{
                            zoneName: null,
                            regionName: null,
                            areaName: null,
                            dbPointName: null,
                            dbName: null,
                            fromDate: "",
                            toDate: ""
                        }}
                        // validationSchema={{}}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={false}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched,
                            resetForm
                        }) => {
                            const handleClearForm = () => {
                                resetForm(); // Reset Formik form fields
                                // Reset any other state variables if needed
                                setSelectedZone(null);
                                setSelectedRegion(null);
                                setSelectedArea(null);
                                setSelectedDbPoint(null);
                                setSelectedDb(null);
                                setSelectedFromDate(null);
                                setSelectedToDate(null);
                            };

                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Col md="4" className='d-flex justify-content-center align-items-center gap-2'>
                                            <label className="form-label me-1">Zone:</label>
                                            <Form.Group controlId="zoneName" className="flex-grow-1">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={zoneList}
                                                    placeholder="Select Zone"
                                                    classNamePrefix="react-select"
                                                    name="zoneName"
                                                    value={values.zoneName}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue("zoneName", selectedOption);
                                                        setSelectedZone(selectedOption.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    isLoading={isLoading.zone}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4" className='d-flex justify-content-center align-items-center gap-2'>
                                            <label className="form-label me-1">Region:</label>
                                            <Form.Group controlId="regionName" className="flex-grow-1">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={allRegion}
                                                    placeholder="Select Region"
                                                    classNamePrefix="react-select"
                                                    name="regionName"
                                                    value={values.regionName}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue("regionName", selectedOption);
                                                        setSelectedRegion(selectedOption.value)
                                                    }}
                                                    onBlur={handleBlur}
                                                    isLoading={isLoading.region}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4" className='d-flex justify-content-center align-items-center gap-2'>
                                            <label className="form-label me-1">Area:</label>
                                            <Form.Group controlId="areaName" className="flex-grow-1">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={allArea}
                                                    placeholder="Select Area"
                                                    classNamePrefix="react-select"
                                                    name="areaName"
                                                    value={values.areaName}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue("areaName", selectedOption);
                                                        setSelectedArea(selectedOption.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    isLoading={isLoading.area}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="4" className='d-flex justify-content-center align-items-center gap-2'>
                                            <label className="form-label me-1">DB Point:</label>
                                            <Form.Group controlId="dbPointName" className="flex-grow-1">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={allDbPoint}
                                                    placeholder="Select DB Point"
                                                    classNamePrefix="react-select"
                                                    name="dbPointName"
                                                    value={values.dbPointName}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue("dbPointName", selectedOption);
                                                        setSelectedDbPoint(selectedOption.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    isLoading={isLoading.dbPoint}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4" className='d-flex justify-content-center align-items-center gap-2'>
                                            <label className="form-label me-1">Select DB:</label>
                                            <Form.Group controlId="dbName" className="flex-grow-1">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={values.dbPointName ? filterDistributor : allDistributor}
                                                    placeholder="Select DB"
                                                    classNamePrefix="react-select"
                                                    name="dbName"
                                                    value={values.dbName}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue("dbName", selectedOption);
                                                        setSelectedDb(selectedOption.id);
                                                    }}
                                                    onBlur={handleBlur}
                                                    isLoading={isLoading.distributor}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4" className='d-flex justify-content-center align-items-center gap-2'>
                                            <label className="form-label me-2">From Date: </label>
                                            <Form.Group controlId="fromDate" className="flex-grow-1">
                                                <input type="date" className="form-control" placeholder="From Date" name="fromDate"
                                                    onChange={(event) => {
                                                        setFieldValue("fromDate", event.target.value);
                                                        setSelectedFromDate(event.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.fromDate}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="4" className='d-flex justify-content-center align-items-center gap-2'>
                                            <label className="form-label">To Date: </label>
                                            <Form.Group controlId="toDate" className="flex-grow-1">
                                                <input type="date" className="form-control" placeholder="To Date" name="toDate"
                                                    onChange={(event) => {
                                                        setFieldValue("toDate", event.target.value);
                                                        setSelectedToDate(event.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.toDate}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="2" className='d-flex justify-content-start align-items-center'>
                                            <button className="btn btn-primary ms-3 px-5 me-2" type="submit">Find</button>
                                            <button className="btn btn-danger ms-3 px-5" type='button' onClick={handleClearForm}>Clear</button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik>

                </FalconComponentCard.Body>
            </FalconComponentCard>

            <Card className="mt-3">
                <Tab.Container defaultActiveKey="tickets-created">
                    <Card.Header className="p-0 bg-light overflow-hidden">
                        <SimpleBarReact>
                            <Nav className="nav-tabs tab-tickets-status flex-nowrap border-0">
                                <Nav.Item className="text-nowrap">
                                    <Nav.Link
                                        eventKey="tickets-created"
                                        className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                                    >
                                        <FontAwesomeIcon icon="ticket-alt" className="text-600" />
                                        <h6 className="mb-0 text-600">
                                            Order & Payment Summery
                                        </h6>
                                    </Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item className="text-nowrap">
                                    <Nav.Link
                                        eventKey="tickets-resolved"
                                        className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                                    >
                                        <FontAwesomeIcon icon="check" className="text-600" />
                                        <h6 className="mb-0 text-600">Details</h6>
                                    </Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                        </SimpleBarReact>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <Tab.Content>
                            <Tab.Pane eventKey="tickets-created">
                                <OrderAndPaymentSummery data={ordersAndPaymentSummery} selectedToDate={selectedToDate} selectedFromDate={selectedFromDate} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tickets-resolved">
                                <Details />
                            </Tab.Pane>
                        </Tab.Content>
                    </Card.Body>
                </Tab.Container>
                {/* <Card.Footer className="bg-light py-2">
                    <Row className="g-0 flex-between-center">
                        <Col xs="auto">
                            <Form.Select size="sm" className="me-2">
                                <option>Last 7 days</option>
                                <option>Last Month</option>
                                <option>Last Year</option>
                            </Form.Select>
                        </Col>
                        <Col xs="auto">
                            <FalconLink title="View all" className="px-0 fw-medium" />
                        </Col>
                    </Row>
                </Card.Footer> */}
            </Card>
        </>

    );
};
export default Index;