import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import BasicECharts from 'components/common/BasicEChart';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { set } from 'date-fns';
import { BarChart } from 'echarts/charts';
import {
    GridComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import handleApiResponseErrors from 'helpers/handleApiResponse';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import image from 'assets/img/icons/spot-illustrations/corner-1.png';
import Background from 'components/common/Background';
import { Link } from 'react-router-dom';
import LoadingIcon from 'helpers/LoadingIcon';
import axios from 'axios';
import { authHeader } from 'utils';

const ConfirmPayment = ({ setIsLoading }) => {
    const [pendingPaymentConfirmedCount, setPendingPaymentConfirmedCount] = useState(0);
    const navigate = useNavigate();

    // fetch orders data from API
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                   `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/confirm`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const orders = response?.data?.payload?.result;
                    setPendingPaymentConfirmedCount(orders?.length);
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <Card className='overflow-hidden'  >
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="warning" pill className="">
                    Pending
                </SoftBadge>
                <h5>

                    Confirm Order Payment

                </h5>
                <div
                    className=
                    'display-4 fs-4 mb-2 fw-normal font-sans-serif'

                >
                    {pendingPaymentConfirmedCount || 0}
                </div>
                <Link to="/accounts/confirm-orders" className="fw-semi-bold fs--1 text-nowrap">
                    See Details
                    <FontAwesomeIcon
                        icon="angle-right"
                        className="ms-1"
                        transform="down-1"
                    />
                </Link>
            </Card.Body>
        </Card>
    );
};


export default ConfirmPayment;
