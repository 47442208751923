import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { toast } from "react-toastify";

const VendorForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [bankByID, setBankById] = useState([]);

    
    const { updateID } = useParams();
    const navigate = useNavigate();


    // BANK BY ID
    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}vendor/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success) {
                        setBankById(response?.data?.payload.result);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);




    const handleSubmitForm = (values, actions) => {

        const handleSubmitData = {
            vendorName: values.vendorName,
            vendorContactNo: values.vendorContactNo,
            vendorAddress: values.vendorAddress,
           
        };

        setIsLoading(true);
        const url = updateID
            ? `${process.env.REACT_APP_SETTINGS_BASE_URL}vendor/${updateID}`
            : `${process.env.REACT_APP_SETTINGS_BASE_URL}vendor`;

        const axiosMethod = updateID ? axios.patch : axios.post;

        axiosMethod(url, handleSubmitData, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    navigate("/master/vendor");
                    toast.success(response.data.message);
                }
            })
            .catch(error => {
                console.log('test', error.response);
                handleApiResponseErrors(error.response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({
       
      
        vendorName: Yup.string().min(2, 'Too Short!').required("Please enter a value for the Vendor Name field"),
        vendorContactNo: Yup.string().min(2, 'Too Short!').required("Please enter a value for the Vendor Contact No field"),
        vendorAddress: Yup.string().min(2, 'Too Short!').required("Please enter a value for the Vendor Address field"),


    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Vendor" : "Add Vendor"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{

                            vendorName: bankByID?.vendorName || '',
                            vendorContactNo: bankByID?.vendorContactNo || '',
                            vendorAddress: bankByID?.vendorAddress || '',
                           
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            console.log(errors);
                            

                            return (
                                <Form onSubmit={handleSubmit}>

                                  
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Vendor Name</Form.Label>
                                            <Form.Control
                                                name="vendorName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Vendor Name"
                                                required
                                                value={values.vendorName}
                                            />
                                            {touched.vendorName && errors.vendorName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.vendorName}
                                                </div>
                                            )}
                                        </Form.Group>


                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Contact No</Form.Label>
                                            <Form.Control
                                                name="vendorContactNo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Vendor Contact No"
                                                required
                                                value={values.vendorContactNo}
                                            />
                                            {touched.vendorContactNo && errors.vendorContactNo && (
                                                <div style={{ color: "red" }}>
                                                    {errors.vendorContactNo}
                                                </div>
                                            )}
                                        </Form.Group>

                                        
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Vendor Address</Form.Label>
                                            <Form.Control
                                                name="vendorAddress"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Vendor Contact No"
                                                required
                                                value={values.vendorAddress}
                                            />
                                            {touched.vendorAddress && errors.vendorAddress && (
                                                <div style={{ color: "red" }}>
                                                    {errors.vendorAddress}
                                                </div>
                                            )}
                                        </Form.Group>

                                        
                                    </Row>

                                 


                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/vendor")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default VendorForm;

