import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import coverSrc from 'assets/img/generic/4.jpg'
import apple from 'assets/img/logos/apple.png'
import google from 'assets/img/logos/g.png'
import hp from 'assets/img/logos/hp.png'
import avatar from 'assets/img/team/2.jpg'
import Flex from 'components/common/Flex'
import VerifiedBadge from 'components/common/VerifiedBadge'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import ProfileBanner from '../user/ProfileBanner'
import Distributor from '../UserManagement/Distributor'
import { authHeader } from 'utils'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import axios from 'axios'
import { formatNumber } from 'helpers/utils'
import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import DistributorDetailsTable from './DistributorDetailsTable'
import LoadingIcon from 'helpers/LoadingIcon'
import user from 'assets/img/team/user.png';

const DistributorViewDetails = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [distributiorDetails, setDistributiorDetails] = useState(null)
    const [distributiorScr, setDistributiorScr] = useState(null)
    const [depositList, setDepositList] = useState([])
    const [creditList, setCreditList] = useState([])
    const [tillDateExpenses, setTillDateExpenses] = useState(0)
    const [totalCredit, setTotalCredit] = useState(0)
    const { id } = useParams()

    const [rightSidedItems] = useState([
        {
            title: 'Google',
            image: google,
        },
        {
            title: 'Apple',
            image: apple,
        },
        {
            title: ' Hewlett Packard',
            image: hp,
        },
    ])

    //scr
    const date = new Date()
    const month = (date.getMonth() + 1).toString()
    const year = date.getFullYear().toString()

    const formattedDate = `${month}/${year}`

    useEffect(() => {
        setIsLoading(true)
        const fetchData2 = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/TotalSCrByDb/${id}/${formattedDate}`
                const response = await axios.get(url, { headers: authHeader() })
                if (response.status === 200) {
                    setDistributiorScr(response.data.payload.sCrBDT)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData2()
    }, [id])

    // call distributor details api
    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dealer/${id}`
                const response = await axios.get(url, { headers: authHeader() })
                if (response.status === 200) {
                    setDistributiorDetails(
                        response.data.payload.distributorData
                    )
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [id])

    /* PAYMENT START */

    // deposit list
    useEffect(() => {
        if (!distributiorDetails?.userId) return
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositsByDealer/${distributiorDetails?.userId}`,
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.result
                    const result = data?.filter((data) => data.status === 2)
                    setDepositList(result)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [distributiorDetails?.userId])

    // Credit list
    useEffect(() => {
        if (!distributiorDetails?.userId) return
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/getAllOrdersWithBankByDB/${distributiorDetails?.userId}`,
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.result;
                    const responseData = data?.filter((data) => data.status === 2);
                    let totalCredit = 0
                    responseData?.forEach((item) => {
                        if (item?.status === 2 && item.crBDT) {
                            totalCredit += item.crBDT
                        }
                    })
                    const tillDateExpenses = responseData?.reduce(
                        (total, item) => total + item.drBDT,
                        0
                    )
                    setTotalCredit(parseFloat(totalCredit))
                    setCreditList(responseData)
                    setTillDateExpenses(tillDateExpenses)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [distributiorDetails?.userId])

    // Calculate total deposit
    const calculateTotalDeposit = (deposit, credit) => {
        const sumData1 = deposit?.reduce((total, item) => total + item.drBDT, 0)
        const sumData2 = credit?.reduce((total, item) => total + item.drBDT, 0)
        return sumData1 - sumData2
    }

    if (isLoading) return <LoadingIcon />

    return (
        <>
            <ProfileBanner>
                <ProfileBanner.Header avatar={user} coverSrc={coverSrc} />
                <ProfileBanner.Body>
                    <Row>
                        <Col lg={8}>
                            <h4 className="mb-1">
                                {distributiorDetails?.distributorName}
                                <VerifiedBadge />
                            </h4>
                            <h5 className="fs-0 fw-normal">
                                {distributiorDetails?.userRole}
                            </h5>
                            <h5 className="fs-0 fw-normal">
                                {distributiorDetails?.phoneNumber}
                            </h5>

                            <p className="text-500">{`${distributiorDetails?.zoneName}, ${distributiorDetails?.regionName}, ${distributiorDetails?.areaName}, ${distributiorDetails?.dbPoint}`}</p>

                            <div className="border-dashed border-bottom my-4 d-lg-none" />
                        </Col>
                        <Col className="ps-2 ps-lg-3">
                            <Flex alignItems="center" className="mb-2 ">
                                <div>
                                    <h3 className="fs-1 fw-normal">
                                        Available credit:{' '}
                                    </h3>
                                </div>

                                <div className="flex-1 ms-1">
                                    <h3 className="fs-1 fw-bold">
                                        {' '}
                                        {formatNumber(
                                            parseFloat(
                                                distributiorDetails?.crBDTLimit
                                            ) - parseFloat(totalCredit)
                                        )}{' '}
                                        BDT
                                    </h3>
                                </div>
                            </Flex>
                            <Flex alignItems="center" className="mb-2">
                                <div>
                                    <h3 className="fs-1 fw-normal">
                                        Available balance:{' '}
                                    </h3>
                                </div>

                                <div className="flex-1 ms-1">
                                    <h3 className="fs-1 fw-bold">
                                        {' '}
                                        {formatNumber(
                                            calculateTotalDeposit(
                                                depositList,
                                                creditList
                                            )
                                        ) || 0}{' '}
                                        BDT
                                    </h3>
                                </div>
                            </Flex>
                            <Flex alignItems="center" className="mb-2">
                                <div>
                                    <h3 className="fs-1 fw-normal">
                                        Till Date Expenses:{' '}
                                    </h3>
                                </div>

                                <div className="flex-1 ms-1">
                                    <h3 className="fs-1 fw-bold">
                                        {' '}
                                        {formatNumber(
                                            tillDateExpenses || 0
                                        )}{' '}
                                        BDT
                                    </h3>
                                </div>
                            </Flex>
                        </Col>
                    </Row>
                    {distributiorDetails && (
                        <DistributorDetailsTable
                            distributorDetails={distributiorDetails}
                            distributiorScr={distributiorScr}
                        />
                    )}
                </ProfileBanner.Body>
            </ProfileBanner>
        </>
    )
}

export default DistributorViewDetails
