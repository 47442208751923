import axios from 'axios'
import Flex from 'components/common/Flex'
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import Swal from 'sweetalert2'
import Select from 'react-select'
import FalconComponentCard from 'components/common/FalconComponentCard'
import FalconCardHeader from 'components/common/FalconCardHeader'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import { formatNumber } from 'helpers/utils'
import IconButton from "components/common/IconButton";
import { Link } from "react-router-dom";
const DepositSearch = () => {
    const [searchData, setsearchData] = useState([])
    const [payFormDate, setPayFormDate] = useState('')
    const [payToDate, setPayToDate] = useState('')
    const [selectedBank, setSelectedBank] = useState('')
    const [bankAccountNo, setBankAccountNo] = useState('')
    const [selectDoNo, setSelectDoNo] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [depositrsBankName, setDepositrsBankName] = useState('')
    const [depositorsPhone, setDepositorsPhone] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('');
    const [allZone, setAllZone] = useState([])
    const [selectedZone, setSelectedZone] = useState('')
    const [allRegion, setAllRegion] = useState([])
    const [selectedRegion, setSelectedRegion] = useState('')
    const [allArea, setAllArea] = useState([])
    const [selectedArea, setSelectedArea] = useState('')
    const [allDbPoint, setAllDbPoint] = useState([])
    const [selectedDbPoint, setSelectedDbPoint] = useState('')
    const [allDistributor, setAllDistributor] = useState([])
    const [selectedDb, setSelectedDb] = useState('')
    const [isLoading, setIsLoading] = useState({
        general: false,
        zone: false,
        region: false,
        area: false,
        dbPoint: false,
        distributor: false,
    })
    const [orders, setOrders] = useState([])
    const [targetAndAchievement, setTargetAndAchievement] = useState({})
    const { zoneName } = useSelector((state) => state.auth.user.data)

    const AllBanksList = [
        { value: 'AB Bank PLC', label: 'AB Bank PLC', sortForm: 'AB' },
        {
            value: 'Agrani Bank PLC',
            label: 'Agrani Bank PLC',
            sortForm: 'Agrani',
        },
        {
            value: 'Al-Arafah Islami Bank PLC',
            label: 'Al-Arafah Islami Bank PLC',
            sortForm: 'Al-AIB',
        },
        {
            value: 'Bangladesh Commerce Bank Limited',
            label: 'Bangladesh Commerce Bank Limited',
            sortForm: 'BCB',
        },
        {
            value: 'Bangladesh Development Bank PLC',
            label: 'Bangladesh Development Bank PLC',
            sortForm: 'BDB',
        },
        {
            value: 'Bangladesh Krishi Bank',
            label: 'Bangladesh Krishi Bank',
            sortForm: 'BKB',
        },
        {
            value: 'Bank Al-Falah Limited',
            label: 'Bank Al-Falah Limited',
            sortForm: 'AlFalah',
        },
        { value: 'Bank Asia PLC.', label: 'Bank Asia PLC.', sortForm: 'BAsia' },
        {
            value: 'BASIC Bank Limited',
            label: 'BASIC Bank Limited',
            sortForm: 'Basic',
        },
        {
            value: 'Bengal Commercial Bank PLC.',
            label: 'Bengal Commercial Bank PLC.',
            sortForm: 'BengalCB',
        },
        { value: 'BRAC Bank PLC', label: 'BRAC Bank PLC', sortForm: 'Barc' },
        { value: 'Citibank N.A', label: 'Citibank N.A', sortForm: 'CityNA' },
        {
            value: 'Citizens Bank PLC',
            label: 'Citizens Bank PLC',
            sortForm: 'Citizen',
        },
        { value: 'City Bank PLC', label: 'City Bank PLC', sortForm: 'City' },
        {
            value: 'Commercial Bank of Ceylon Limited',
            label: 'Commercial Bank of Ceylon Limited',
            sortForm: 'CBC',
        },
        {
            value: 'Community Bank Bangladesh PLC.',
            label: 'Community Bank Bangladesh PLC.',
            sortForm: 'Community',
        },
        { value: 'Dhaka Bank PLC', label: 'Dhaka Bank PLC', sortForm: 'Dhaka' },
        {
            value: 'Dutch-Bangla Bank PLC',
            label: 'Dutch-Bangla Bank PLC',
            sortForm: 'DBBL',
        },
        {
            value: 'Eastern Bank PLC',
            label: 'Eastern Bank PLC',
            sortForm: 'EB',
        },
        { value: 'EXIM Bank PLC', label: 'EXIM Bank PLC', sortForm: 'EXIM' },
        {
            value: 'First Security Islami Bank PLC',
            label: 'First Security Islami Bank PLC',
            sortForm: 'FSIB',
        },
        {
            value: 'Global Islami Bank PLC',
            label: 'Global Islami Bank PLC',
            sortForm: 'Global',
        },
        {
            value: 'Habib Bank Ltd.',
            label: 'Habib Bank Ltd.',
            sortForm: 'Habib',
        },
        {
            value: 'ICB Islamic Bank Ltd.',
            label: 'ICB Islamic Bank Ltd.',
            sortForm: 'ICB',
        },
        { value: 'IFIC Bank PLC', label: 'IFIC Bank PLC', sortForm: 'IFIC' },
        {
            value: 'Islami Bank Bangladesh PLC',
            label: 'Islami Bank Bangladesh PLC',
            sortForm: 'IBBL',
        },
        {
            value: 'Jamuna Bank PLC',
            label: 'Jamuna Bank PLC',
            sortForm: 'Jamuna',
        },
        {
            value: 'Janata Bank PLC',
            label: 'Janata Bank PLC',
            sortForm: 'Janata',
        },
        {
            value: 'Meghna Bank PLC',
            label: 'Meghna Bank PLC',
            sortForm: 'Meghna',
        },
        {
            value: 'Mercantile Bank PLC',
            label: 'Mercantile Bank PLC',
            sortForm: 'Mercantile',
        },
        {
            value: 'Midland Bank Limited',
            label: 'Midland Bank Limited',
            sortForm: 'Midland',
        },
        {
            value: 'Modhumoti Bank PLC',
            label: 'Modhumoti Bank PLC',
            sortForm: 'Modhumoti',
        },
        {
            value: 'Mutual Trust Bank PLC',
            label: 'Mutual Trust Bank PLC',
            sortForm: 'Mutual',
        },
        {
            value: 'Nagad Digital Bank PLC.',
            label: 'Nagad Digital Bank PLC.',
            sortForm: 'Nagad',
        },
        {
            value: 'National Bank Limited',
            label: 'National Bank Limited',
            sortForm: 'National',
        },
        {
            value: 'National Bank of Pakistan',
            label: 'National Bank of Pakistan',
            sortForm: 'NBP',
        },
        {
            value: 'National Credit & Commerce Bank PLC',
            label: 'National Credit & Commerce Bank PLC',
            sortForm: 'NCCB',
        },
        {
            value: 'NRB Bank Limited',
            label: 'NRB Bank Limited',
            sortForm: 'NRB',
        },
        { value: 'NRBC Bank PLC', label: 'NRBC Bank PLC', sortForm: 'NRBC' },
        { value: 'One Bank PLC', label: 'One Bank PLC', sortForm: 'One' },
        { value: 'Padma Bank PLC', label: 'Padma Bank PLC', sortForm: 'Padma' },
        { value: 'Prime Bank PLC', label: 'Prime Bank PLC', sortForm: 'Prime' },
        {
            value: 'Probashi Kollyan Bank',
            label: 'Probashi Kollyan Bank',
            sortForm: 'PROBASHI',
        },
        {
            value: 'Pubali Bank PLC',
            label: 'Pubali Bank PLC',
            sortForm: 'PUBALI',
        },
        {
            value: 'Rajshahi Krishi Unnayan Bank',
            label: 'Rajshahi Krishi Unnayan Bank',
            sortForm: 'RKUB',
        },
        {
            value: 'Rupali Bank PLC',
            label: 'Rupali Bank PLC',
            sortForm: 'Rupali',
        },
        { value: 'SBAC Bank PLC', label: 'SBAC Bank PLC', sortForm: 'SBAC' },
        {
            value: 'Shahjalal Islami Bank PLC',
            label: 'Shahjalal Islami Bank PLC',
            sortForm: 'SIBL',
        },
        {
            value: 'Shimanto Bank PLC',
            label: 'Shimanto Bank PLC',
            sortForm: 'Shimanto',
        },
        {
            value: 'Social Islami Bank PLC',
            label: 'Social Islami Bank PLC',
            sortForm: 'SIB',
        },
        {
            value: 'Sonali Bank PLC',
            label: 'Sonali Bank PLC',
            sortForm: 'Sonali',
        },
        {
            value: 'Southeast Bank PLC',
            label: 'Southeast Bank PLC',
            sortForm: 'Southeast',
        },
        {
            value: 'Standard Bank PLC',
            label: 'Standard Bank PLC',
            sortForm: 'SB',
        },
        {
            value: 'Standard Chartered Bank',
            label: 'Standard Chartered Bank',
            sortForm: 'SCB',
        },
        {
            value: 'State Bank of India',
            label: 'State Bank of India',
            sortForm: 'SBI',
        },
        {
            value: 'The Hong Kong and Shanghai Banking Corporation. Ltd.',
            label: 'The Hong Kong and Shanghai Banking Corporation. Ltd.',
            sortForm: 'HKSBC',
        },
        {
            value: 'The Premier Bank PLC',
            label: 'The Premier Bank PLC',
            sortForm: 'Premier',
        },
        {
            value: 'Trust Bank Limited',
            label: 'Trust Bank Limited',
            sortForm: 'Trust',
        },
        { value: 'Union Bank PLC', label: 'Union Bank PLC', sortForm: 'Union' },
        {
            value: 'United Commercial Bank PLC',
            label: 'United Commercial Bank PLC',
            sortForm: 'UCB',
        },
        {
            value: 'Uttara Bank PLC',
            label: 'Uttara Bank PLC',
            sortForm: 'Uttara',
        },
        { value: 'Woori Bank', label: 'Woori Bank', sortForm: 'Woori' },
    ]
    const statusOptions = [
        { id: 0, value: 'All', label: 'All' },
        { id: 4, value: 'Pending', label: 'Pending' },
        { id: 1, value: 'Confirm', label: 'Confirm' },
        { id: 2, value: 'Approved', label: 'Approved' },
        { id: 3, value: 'Rejected', label: 'Rejected' },
    ];
    // get zone list
    useEffect(() => {
        setIsLoading((prevState) => ({ ...prevState, zone: true }))
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(
                        (data) => ({
                            id: data._id,
                            label: data.name,
                            value: data.name,
                        })
                    )

                    setAllZone(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [])

    // get region
    useEffect(() => {
        setIsLoading((prevState) => ({ ...prevState, region: true }))
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(
                        (data) => ({
                            id: data._id,
                            label: data.regionName,
                            value: data.regionName,
                        })
                    )

                    setAllRegion(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, region: false }))
            }
        }
        fetchData()
    }, [selectedZone])

    // get area name
    useEffect(() => {
        setIsLoading((prevState) => ({ ...prevState, area: true }))
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}areas/${selectedRegion}`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(
                        (data) => ({
                            id: data._id,
                            label: data.areaName,
                            value: data.areaName,
                        })
                    )

                    setAllArea(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, area: false }))
            }
        }
        fetchData()
    }, [selectedRegion])

    // db points list
    useEffect(() => {
        if (selectedArea) {
            setIsLoading((prevState) => ({ ...prevState, dbPoint: true }))
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.dbPointName,
                                value: data.dbPointName,
                            })
                        )
                        setAllDbPoint(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        dbPoint: false,
                    }))
                }
            }
            fetchData()
        }
    }, [selectedArea])

    useEffect(() => {
        if (selectedDbPoint) {
            setIsLoading((prevState) => ({ ...prevState, distributor: true }))
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?dbPoint=${selectedDbPoint}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })
                    if (response.data.success || response.status === 200) {
                        const modifyData =
                            response?.data?.payload?.distributors.map(
                                (data) => ({
                                    id: data.userId,
                                    label: data.distributorName,
                                    value: data.userId,
                                })
                            )
                        setAllDistributor(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        distributor: false,
                    }))
                }
            }
            fetchData()
        }
    }, [selectedDbPoint])


    const formatDate = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US') : ''
    }

    const handleSearch = () => {
        setIsLoading((prevState) => ({ ...prevState, general: true }))
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositFilters`
                const response = await axios.post(
                    url,
                    {
                        zone: selectedZone,
                        region: selectedRegion,
                        area: selectedArea,
                        dbPoint: selectedDbPoint,
                        dealerId: selectedDb,
                        bankNames: selectedBank,
                        bankAccountNo,
                        doNo:selectDoNo,
                        trId: transactionId,
                        depositorsBankName: depositrsBankName,
                        depositorPhone: depositorsPhone,
                        status: Number(selectedStatus),
                        payDateFrom: formatDate(payFormDate),
                        payDateTo: formatDate(payToDate),
                        
                    },
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    const modifyData =
                        response?.data?.payload?.depositInfo?.map(
                            (data, index) => ({
                                ...data,
                                index: index + 1,
                            })
                        )
                    setsearchData(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, general: false }))
            }
        }

        fetchData()
    }

    const handleClear = () => {
        setSelectedZone('')
        setSelectedRegion('')
        setSelectedArea('')
        setSelectedDbPoint('')
        setSelectedDb('')
        setSelectedBank('')
        setBankAccountNo('')
        setTransactionId('')
        setDepositrsBankName('')
        setTransactionId('')
        setDepositorsPhone('')
        setPayToDate('')
        setPayFormDate('')
        setSelectedStatus('')
        setSelectDoNo('')
       
    }

       // VIEW IMAGE
       const viewImage = async (imageId) => {

        try {
            const response = await axios.get(`https://khoz.net:3015/api/v1/image/${imageId}`, { headers: authHeader() });
            if (response.data.success && response.data.payload) {
                const data = response.data.payload;
                if (!data.imageData) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No image found!',
                    });
                    return;
                }
                
                const base64Image = response.data.payload.imageData.image;

                // Check if the base64 string contains the data URL prefix and remove it if present
                const base64Prefix = 'data:image/jpeg;base64,';
                let base64String;
                if (base64Image.startsWith(base64Prefix)) {
                    base64String = base64Image.substring(base64Prefix.length);
                } else {
                    base64String = base64Image;
                }

                // Convert base64 to Blob
                try {
                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'image/jpeg' });

                    // Create a URL for the Blob and open it in a new tab
                    const imageUrl = URL.createObjectURL(blob);
                    window.open(imageUrl, '_blank');
                } catch (e) {
                    console.error('Error decoding base64 string:', e);
                }
            } else {
                console.error('Failed to fetch image:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching the image:', error);
        }
    };


    if (isLoading.general) {
        return <LoadingIcon />
    }

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{index}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'zone',
            Header: 'Zone',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { zone } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {zone || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'region',
            Header: 'Region',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { region } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {region || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'area',
            Header: 'Area',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { area } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {area || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'dbPoint',
            Header: 'DB Point',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dbPoint } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {dbPoint || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'dealerId',
            Header: 'Dealer ID',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dealerId } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {dealerId || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'distributorName',
            Header: 'Distributor Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dealerName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {dealerName || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'dbType',
            Header: 'DB Type',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dbType } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {dbType || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'bankNames',
            Header: 'Bank Names',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { bankNames } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {bankNames || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'doNo',
            Header: 'Do No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {doNo|| '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'bankAccountNo',
            Header: 'Account No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { bankAccountNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {bankAccountNo || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'depositorsBankName',
            Header: 'Depositor Bank Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { depositorsBankName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {depositorsBankName || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'depositorsBranchName',
            Header: 'Depositor Branch Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { depositorsBranchName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {depositorsBranchName || '--'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'drBDT',
            Header: 'Amount BDT',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { drBDT } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formatNumber(drBDT)}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'depositorPhone',
            Header: 'Depositor Phone',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { depositorPhone } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {depositorPhone}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'trId',
            Header: 'Transaction ID',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { trId } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {trId ? trId : '-'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'paymentNote',
            Header: 'Payment Note',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { paymentNote } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {paymentNote ? paymentNote : '-'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'paymentDate',
            Header: 'Payment Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { paymentDate } = rowData.row.original
                const date = new Date(paymentDate)
                const formateDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formateDate}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'status',
            Header: 'Status',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            {status === 0 && (
                                <h6
                                    style={{
                                        backgroundColor: 'orange',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '5px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <i
                                        className="fas fa-hourglass-half"
                                        style={{ marginRight: '5px' }}
                                    ></i>
                                    Pending..
                                </h6>
                            )}
                            {status === 1 && (
                                <h6
                                    style={{
                                        backgroundColor: 'blue',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '5px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <i
                                        className="fas fa-thumbs-up"
                                        style={{ marginRight: '5px' }}
                                    ></i>
                                    Approved By Account
                                </h6>
                            )}
                            {status === 2 && (
                                <h6
                                    style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '5px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <i
                                        className="fas fa-check-circle"
                                        style={{ marginRight: '5px' }}
                                    ></i>
                                    Approved
                                </h6>
                            )}
                            {status === 3 && (
                                <h6
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '5px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <i
                                        className="fas fa-times-circle"
                                        style={{ marginRight: '5px' }}
                                    ></i>
                                    Rejected
                                </h6>
                            )}
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: "action",
            Header: "Action",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { _id, trId, status } = rowData.row.original;
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {trId && <Dropdown.Item onClick={() => viewImage(trId)}>

                                {/* <Link to={image} target="_blank"> */}
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="show"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">View Attachment</span>
                                </IconButton>
                                {/* </Link> */}
                            </Dropdown.Item>}
                            {status === 0 && <>
                                <Dropdown.Item href="">

                                    <Link to={`/master/deposit/add/${_id}`}>
                                        <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon="edit"
                                            transform="shrink-2"
                                            iconAlign="middle"
                                            className="me-2"

                                        >
                                            <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                        </IconButton>
                                    </Link>
                                </Dropdown.Item>
                                {/* <Dropdown.Item href="">
                                    <IconButton
                                        onClick={() => handleDelete(_id)}
                                        variant="falcon-default"
                                        size="sm"
                                        icon="trash-alt"
                                        iconAlign="middle"
                                        className="d-none d-sm-block me-2"
                                    // disabled={true}
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                    </IconButton>
                                </Dropdown.Item> */}
                            </>}
                        </Dropdown.Menu>
                    </Dropdown>
                );
            },
        },
    ]

    return (
        <>
            <FalconComponentCard>
                <FalconCardHeader title="Deposit Filter" />
                <FalconComponentCard.Body>
                    <Row className="mb-2">
                        <Col md="4">
                            <Form.Label>Select Zone</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allZone}
                                    placeholder="Select Zone"
                                    classNamePrefix="react-select"
                                    name="zoneName"
                                    value={selectedZone ? { label: selectedZone, value: selectedZone } : ''}
                                    onChange={(selectedOption) => {
                                        setSelectedZone(selectedOption.value);
                                    }}
                                    isLoading={isLoading.zone}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Select Region</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allRegion}
                                    placeholder="Select Area"
                                    classNamePrefix="react-select"
                                    name="areaName"
                                    value={selectedRegion ? { label: selectedRegion, value: selectedRegion } : ''}
                                    onChange={(selectedOption) => {
                                        setSelectedRegion(selectedOption.value)
                                    }}
                                    isLoading={isLoading.region}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Select Area</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allArea}
                                    placeholder="Select Area"
                                    classNamePrefix="react-select"
                                    name="areaName"
                                    value={selectedArea ? { label: selectedArea, value: selectedArea } : ''}
                                    onChange={(selectedOption) => {
                                        setSelectedArea(selectedOption.value)
                                    }}
                                    isLoading={isLoading.area}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md="4">
                            <Form.Label>Select DB Point</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allDbPoint}
                                    placeholder="Select DB Point"
                                    classNamePrefix="react-select"
                                    name="dbPoint"
                                    value={selectedDbPoint ? { label: selectedDbPoint, value: selectedDbPoint } : ''}
                                    onChange={(selectedOption) => {
                                        setSelectedDbPoint(selectedOption.value)
                                    }}
                                    isLoading={isLoading.dbPoint}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Select DB</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allDistributor}
                                    placeholder="Select DB"
                                    classNamePrefix="react-select"
                                    name="dbName"
                                    value={allDistributor.find(option => option.value === selectedDb) || null}
                                    onChange={(selectedOption) => {
                                        setSelectedDb(selectedOption ? selectedOption.value : null);
                                    }}
                                    isLoading={isLoading.distributor}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Select Company Bank</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={AllBanksList}
                                    placeholder="Select Bank"
                                    classNamePrefix="react-select"
                                    name="bankNames"
                                    value={selectedBank ? { label: selectedBank, value: selectedBank } : ''}

                                    onChange={(selectedOption) => {
                                        setSelectedBank(selectedOption.value)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Do No</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Do No"
                                    value={selectDoNo}
                                    onChange={(e) =>
                                        setSelectDoNo(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Bank Account No</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Bank Account No"
                                    value={bankAccountNo}
                                    onChange={(e) =>
                                        setBankAccountNo(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Transaction ID</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Transaction ID"
                                    value={transactionId}
                                    onChange={(e) =>
                                        setTransactionId(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Select Depositors Bank</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Depositors Bank Name"
                                    value={depositrsBankName}
                                    onChange={(e) =>
                                        setDepositrsBankName(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Depositors Phone Number</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Depositors Phone"
                                    value={depositorsPhone}
                                    onChange={(e) =>
                                        setDepositorsPhone(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>{' '}
                        <Col md="4">
                            <Form.Label>Select Status</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={statusOptions}
                                    placeholder="Select Status"
                                    classNamePrefix="react-select"
                                    name="status"
                                    value={statusOptions.find(option => option.id === selectedStatus) || ''}
                                    onChange={(selectedOption) => {
                                        setSelectedStatus(selectedOption ? selectedOption.id : '');
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Pay Date From</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Form.Control
                                    selected={payFormDate}
                                    type="date"
                                    value={payFormDate}
                                    onChange={(e) =>
                                        setPayFormDate(e.target.value)
                                    }
                                    showMonthYearPicker
                                    placeholderText="Select Pay Date From"
                                    className="form-control w-100"
                                    width="100%"
                                    
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Pay Date To</Form.Label>
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <Form.Control
                                    selected={payToDate}
                                    type="date"
                                    value={payToDate}
                                    onChange={(e) =>
                                        setPayToDate(e.target.value)
                                    }
                                    showMonthYearPicker
                                    placeholderText="Select Pay Date To"
                                    className="form-control w-100"
                                    width="100%"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4" className="d-flex align-items-center mt-4">
                            <Form.Group
                                controlId="exampleFirstName"
                                className="flex-grow-1"
                            >
                                <button
                                    className="btn btn-primary px-5"
                                    onClick={() => handleSearch()}
                                >
                                    Search
                                </button>
                                <button
                                    className="btn btn-danger px-5 ms-3"
                                    onClick={handleClear}
                                >
                                    Clear
                                </button>
                            </Form.Group>
                        </Col>
                        
                    </Row>
                </FalconComponentCard.Body>
            </FalconComponentCard>
            <AdvanceTableWrapper
                columns={columns}
                data={searchData}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header></Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        {/* <AdvanceTablePagination
                            table
                            paginationPageCount={paginationPageCount}
                            setPaginationPageCount={setPaginationPageCount}
                            pageDataCount={pageDataCount}
                            setPageDataCount={setPageDataCount}
                            totalPages={totalPages}
                        /> */}
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}
export default DepositSearch
