import LoginForm from 'components/authentication/LoginForm'
import React from 'react'
import image from 'assets/img/logos/tkgmis.png'
import logo from '../../../assets/img/logos/tkgl/techknowgram.fa1a6b163526beb3e5a6.png'
import AuthSplitLayout from 'layouts/AuthSplitLayout'
import { Col, Container, Row } from 'react-bootstrap'
import pustiLogo from 'assets/img/logos/PustiLogo/pusti_logo.png'

const Login = () => {
    return (
        <AuthSplitLayout
            leftSideContent={
                <>
                    <div
                        style={{ marginTop: '65px' }}
                        className="md-justify-content-end"
                    >
                        <a href="https://techknowgram.com/" target="_blank">
                            <img
                                src={logo}
                                style={{ width: '100%', marginTop: '10px' }}
                            ></img>
                        </a>
                    </div>
                </>
            }
        >
            <div className="ms-20">
            <a href="https://tkfoodsbd.com/" target="_blank">
                    <img
                        src={pustiLogo}
                        className="d-none d-md-block"
                        style={{
                            width: '40%',
                            marginLeft: '70%',
                            marginBottom: '35px',
                        }}
                    ></img>
                </a>
            </div>
            {/* <Container>
                <Row>
                    <Col className="d-flex justify-content-center justify-content-md-end">
                        <img
                            src={pustiLogo}
                            className="d-none d-md-block"
                            style={{ width: '40%', marginTop: '10px' }}
                        ></img>
                    </Col>
                </Row>
            </Container> */}

            <h3>Distributor Login</h3>
            <LoginForm layout="card" userType="distributor" hasLabel />
        </AuthSplitLayout>
    )
}

export default Login
