import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SoftBadge from 'components/common/SoftBadge'
import { formatNumber } from 'helpers/utils'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import image from 'assets/img/icons/spot-illustrations/corner-2.png'
import Background from 'components/common/Background'
import { Link } from 'react-router-dom'

const YearTillDateNo = ({ data }) => {
    // Formtting the date   
    const selectedDate = new Date();
    const year = selectedDate?.getFullYear();

    const formattedDate = `${year}`;
    const navigate = useNavigate()
    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="info" pill className="">
                    Year
                </SoftBadge>
                <h5 style={{ fontSize: '1rem' }}>Till Date Confirm DO</h5>
                <div className=" display-4 fs-1 mb-2 fw-normal font-sans-serif">
                    No. {formatNumber(data?.TillDateConfirmDo) || 0}
                </div>
                <span className="fw-semi-bold fs--1 text-nowrap">{formattedDate}</span>
            </Card.Body>
        </Card>
    )
}

export default YearTillDateNo
