import FalconCardHeader from 'components/common/FalconCardHeader'
import Flex from 'components/common/Flex'
import PropTypes from 'prop-types'
import React from 'react'
import { Card } from 'react-bootstrap'
import { formatNumber } from 'helpers/utils'


const SharedFiles = ({ files, className, title }) => {
    return (
        <Card className={className}
        style={{ width: '400px' }}
        >
            <FalconCardHeader
                title={title}
                titleTag="h6"
                className="py-2"
                light
                endEl={
                    <div className="py-1 fs--1 font-sans-serif">
                        Quantity
                    </div>
                }
            />
            <Card.Body className="pb-0">
                {files.map((file, index) => (
                    <SharedFile
                        key={file._id}
                        file={file}
                        isLast={index === files.length - 1}
                    />
                ))}
            </Card.Body>
        </Card>
    )
}

const SharedFile = ({ file, isLast }) => {
    const { img, skuName, rcvQtyCTN, time, border, rcvQtyPCS, dbType, stockDeliveredQtyCTN, stockDeliveredQtyPCS } = file
    return (
        <>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                className="mb-3 hover-actions-trigger"
            >
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                    <h6 className="mb-1">{skuName}</h6>

                    
                </div>
                <div className="end-0 top-50">{dbType=="SPECIAL DISTRIBUTOR" ? formatNumber(rcvQtyPCS-stockDeliveredQtyPCS) : formatNumber(rcvQtyCTN-stockDeliveredQtyCTN)} <span>{dbType=="SPECIAL DISTRIBUTOR" ? "PCS":"CTN"}</span></div>
            </Flex>
            {!isLast && <hr className="text-200" />}
        </>
    )
}

SharedFile.propTypes = {
    file: PropTypes.shape({
        img: PropTypes.string,
        name: PropTypes.string,
        user: PropTypes.string,
        time: PropTypes.string,
        border: PropTypes.bool,
    }),
    isLast: PropTypes.bool,
}

SharedFiles.propTypes = {
    files: PropTypes.arrayOf(SharedFile.propTypes.file),
    className: PropTypes.string,
}

export default SharedFiles
