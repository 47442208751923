import DsmTarget from "./Target/index";
import DashboardCards from '../Common/DashboardCards/Dashboard';
const { Row } = require("react-bootstrap");

const DsmDashboard = ({ setIsLoading }) => {
    return (
        <div>
            <Row className="g-3 mb-3">
                <DashboardCards setIsLoading={setIsLoading}></DashboardCards>
                <DsmTarget setIsLoading={setIsLoading} />
            </Row>
        </div>
    );
};

export default DsmDashboard;