import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
// import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import { Button, Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap'
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import useUserRole from "hooks/useUserRole";
import { useDropzone } from "react-dropzone";
import FalconCloseButton from "components/common/FalconCloseButton";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { authHeaderForm } from "state/ducs/auth/utils";
import Select from 'react-select';
import DatePicker from 'react-datepicker'



const AdminOfferReceivedListConfirmed = () => {

    const [receivedList, setReceivedList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [showUploadCSV, setShowUploadCSV] = useState(false);

    const [searched, setSearched] = useState(false);

    const [count, setCount] = useState(0);

    const [serachedReceivedList, setSerachedReceivedList] = useState([]);

    const [offerProductsList, setOfferProductsList] = useState([]);

    const [offerProductsCategoryList, setOfferProductsCategoryList] = useState([]);


    const [deliDateFrom, setDeliDateFrom] = useState(null);

    const [deliDateTo, setDeliDateTo] = useState(null);



    const [factory, setFactory] = useState('');

    const [productCategory, setProductCategory] = useState('');

    const userInfo = useSelector((state) => state.auth.user?.data);
    const userRole = useUserRole();


    const factoryList = [
        {
            label: 'All',
            value: 'All',
        },
        {
            label: 'SORL',
            value: 'SORL',
        },
        {
            label: 'SVOIL',
            value: 'SVOIL',
        },
        {
            label: 'Narshingdi',
            value: 'Narshingdi',
        },
        {
            label: 'Dhamrai',
            value: 'Dhamrai',
        },
    ]

    const formateExcelData = (data) => {
        const formatDate = (date) => {
            return date ? new Date(date).toLocaleDateString('en-US') : ''
        }

        const formateData = data?.map((item, index) => ({
            Sl: index + 1,
            // 'Offer Name': item.conditionName,
            'Offer Sku Name': item.offerSkuName,
            // 'Offer Type': item.offerType,
            'Offer Rcv Qty PCS': item.OfferRcvQty,
            // 'Offer Unit': item.offerUnit,
            'Factory name': item.factoryName,
            // 'Offer End Date': formatDate(item.offerEndDate),
            // 'Status': !item?.status ? "Pending" : "Received",
            'Delivery Date': formatDate(item.deliDateToFactory),
            'Received Date': formatDate(item.offerRcvDate),
        }))

        return formateData
    }

    function uniqueObjects(array, key) {
        let seen = new Set();
        return array.filter(item => {
            let k = item[key];
            return seen.has(k) ? false : seen.add(k);
        });
    }


    // 
    useEffect(() => {

        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

        const fetchData = async () => {


            let url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/OfferDeliverySearch`;

            try {
                const response = await axios.post(
                    url,
                    {
                        factoryName: factory,
                        offerSkuName: searchText,
                        deliDateFrom: deliDateFrom,
                        deliDateTo: deliDateTo,
                        offerProductCategory: productCategory

                    },
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const totalPages = response?.data?.payload?.pagination?.totalPages;
                    const result = response?.data?.payload?.offerDeliveryInfo?.map((element, index) => ({
                        ...element,
                        index: startIndex + index
                    }));

                    setTotalPages(totalPages)
                    setReceivedList(result);
                    //
                    // setFactory(factory)
                    // setSearchText(searchText)
                    setDeliDateFrom(null);
                    setDeliDateTo(null);
                    // setProductCategory(productCategory);

                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

    }, [count, paginationPageCount, pageDataCount]);



    useEffect(() => {
        setIsLoading(true);
        // const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offerproduct`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const totalPages = response?.data?.payload?.pagination?.totalPages;

                    const allOffers = response?.data?.payload?.products;

                    //   const result = response.data.payload.
                    //     products.map((element, index) => ({
                    //       ...element,
                    //       index: startIndex + index,
                    //       label
                    //     }));


                    const SETtoArray = uniqueObjects(allOffers, 'skuName')?.map(item => ({ ...item, label: item.skuName, value: item.skuName }));

                    const SETtoArrayofferProductCategories = uniqueObjects(allOffers, 'categoryName')?.map(item => ({ ...item, label: item.categoryName, value: item.categoryName }));

                    //   setTotalPages(totalPages);
                    console.log("SETtoArray", SETtoArray);

                    console.log("SETtoArrayofferProductCategories", SETtoArrayofferProductCategories);


                    setOfferProductsList(SETtoArray);
                    setOfferProductsCategoryList(SETtoArrayofferProductCategories)
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount]);

    // DELETE DISTRIBUTOR

    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleSearch = () => {

        setCount((prevCount) => prevCount + 1);

    }



    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "SL",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        // {
        //     accessor: "productCategory",
        //     Header: "Category",
        //     headerProps: { className: "pe-1" },
        //     cellProps: {
        //         className: "py-2",
        //     },
        //     Cell: (rowData) => {
        //         const { productCategory } = rowData.row.original;
        //         return (

        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{productCategory}</h5>
        //                 </div>
        //             </Flex>

        //         );
        //     },
        // },
        // {
        //     accessor: "skuName",
        //     Header: "skuName",
        //     headerProps: { className: "pe-1" },
        //     cellProps: {
        //         className: "py-2",
        //     },
        //     Cell: (rowData) => {
        //         const { skuName } = rowData.row.original;
        //         return (

        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{skuName}</h5>
        //                 </div>
        //             </Flex>

        //         );
        //     },
        // },

        // {
        //     accessor: "conditionName",
        //     Header: "Offer Name",
        //     headerProps: { className: "pe-1" },
        //     cellProps: {
        //         className: "py-2",
        //     },
        //     Cell: (rowData) => {
        //         const { conditionName } = rowData.row.original;
        //         return (

        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{conditionName}</h5>
        //                 </div>
        //             </Flex>

        //         );
        //     },
        // },


        {
            accessor: "offerSkuName",
            Header: "Offer SKU Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { offerSkuName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{offerSkuName}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        // {
        //     accessor: "offerType",
        //     Header: "Offer Type",
        //     headerProps: { className: "pe-1" },
        //     cellProps: {
        //         className: "py-2",
        //     },
        //     Cell: (rowData) => {
        //         const { offerType } = rowData.row.original;
        //         return (

        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{offerType}</h5>
        //                 </div>
        //             </Flex>

        //         );
        //     },
        // },

        {
            accessor: "OfferRcvQty",
            Header: "Offer Qty",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { OfferRcvQty } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{OfferRcvQty}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        // {
        //     accessor: "offerUnit",
        //     Header: "Offer Unit",
        //     headerProps: { className: "pe-1" },
        //     cellProps: {
        //         className: "py-2",
        //     },
        //     Cell: (rowData) => {
        //         const { offerUnit } = rowData.row.original;
        //         return (

        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{offerUnit}</h5>
        //                 </div>
        //             </Flex>

        //         );
        //     },
        // },

        // {
        //     accessor: "offerStartDate",
        //     Header: "Offer Start Date",
        //     headerProps: { className: "pe-1" },
        //     cellProps: {
        //         className: "py-2",
        //     },
        //     Cell: (rowData) => {
        //         const { offerStartDate } = rowData.row.original;
        //         const date = new Date(offerStartDate);
        //         const formattedDate = date.toLocaleDateString();
        //         return (

        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
        //                 </div>
        //             </Flex>

        //         );
        //     },
        // },

        // {
        //     accessor: "offerEndDate",
        //     Header: "Offer End Date",
        //     headerProps: { className: "pe-1" },
        //     cellProps: {
        //         className: "py-2",
        //     },
        //     Cell: (rowData) => {
        //         const { offerEndDate } = rowData.row.original;
        //         const date = new Date(offerEndDate);
        //         const formattedDate = date.toLocaleDateString();
        //         return (

        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
        //                 </div>
        //             </Flex>

        //         );
        //     },
        // },

        {
            accessor: "factoryName",
            Header: "Factory Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { factoryName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{factoryName}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        // {
        //     accessor: "status",
        //     Header: "Status",
        //     headerProps: { className: "pe-1" },
        //     cellProps: {
        //         className: "py-2",
        //     },
        //     Cell: (rowData) => {
        //         const { status } = rowData.row.original;
        //         // const date = new Date(rcvDate);
        //         // const formattedDate = date.toLocaleDateString();
        //         return (

        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{!status ? "Pending" : "Received"}</h5>
        //                 </div>
        //             </Flex>

        //         );
        //     },
        // },



        {
            accessor: "deliDateToFactory",
            Header: "Delivery Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {

                const { deliDateToFactory } = rowData.row.original;
                const date = new Date(deliDateToFactory);
                const formattedDate = date.toLocaleDateString();

                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "offerRcvDate",
            Header: "Received Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { offerRcvDate } = rowData.row.original;
                const date = new Date(offerRcvDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            },
        }
    ];

    /*******************
    Columns End.
    *******************/

    console.log("receivedList", receivedList);




    return (
        <>
            <PageHeader
                title="Offer Product Received List"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={receivedList}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Form>
                        <Row className="mt-3 ms-2">
                            <Form.Group
                                as={Col}
                                md="3"
                                controlId="exampleFirstName"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={factoryList}
                                    placeholder={
                                        isLoading
                                            ? 'loading...'
                                            : 'Select Factory Name'
                                    }
                                    classNamePrefix="react-select"
                                    onChange={(selectedOption) => {
                                        setFactory(selectedOption.value)
                                    }}
                                    isLoading={isLoading}
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="3"
                                controlId="exampleFirstName"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={offerProductsCategoryList}
                                    placeholder={
                                        isLoading
                                            ? 'loading...'
                                            : 'Select Product Category'
                                    }
                                    classNamePrefix="react-select"
                                    onChange={(selectedOption) => {
                                        setProductCategory(selectedOption.value)
                                    }}
                                    isLoading={isLoading}
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="3"
                                controlId="exampleFirstName"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={offerProductsList}
                                    placeholder={
                                        isLoading
                                            ? 'loading...'
                                            : 'Select Offer Product'
                                    }
                                    classNamePrefix="react-select"
                                    onChange={(selectedOption) => {
                                        setSearchText(selectedOption.value)
                                    }}
                                    isLoading={isLoading}
                                />
                            </Form.Group>


                        </Row>
                        <Row className="mt-3 ms-2">

                            <Form.Group
                                as={Col}
                                md="3"
                                controlId="exampleZip"
                            >
                                <DatePicker
                                    selected={deliDateFrom}
                                    onChange={(date) => setDeliDateFrom(date)}
                                    formatWeekDay={(day) => day.slice(0, 3)}
                                    className="form-control my-3"
                                    placeholderText="Delivery Start Date"
                                    // disabled={deliveryQty <= 0}
                                    popperPlacement="top-start"
                                    // style={{ width: '100%' }}
                                    required

                                />

                            </Form.Group>

                            <Form.Group
                                as={Col}
                                md="3"
                                controlId="exampleZip"
                            >
                                <DatePicker
                                    selected={deliDateTo}
                                    onChange={(date) => setDeliDateTo(date)}
                                    formatWeekDay={(day) => day.slice(0, 3)}
                                    className="form-control my-3"
                                    placeholderText="Delivery End Date"
                                    // disabled={deliveryQty <= 0}
                                    popperPlacement="top-start"
                                    // style={{ width: '100%' }}
                                    required


                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="2"
                                controlId="exampleState"
                            >
                                <Button
                                    onClick={handleSearch}
                                    variant="primary"
                                    className="mt-3"
                                    style={{ width: '188px' }}
                                >
                                    Search
                                </Button>
                            </Form.Group>

                        </Row>
                    </Form>
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            // isSearch={true}
                            // setSearchText={setSearchText}
                            table
                            excelFileName="Offer Product Received List"
                            excelData={formateExcelData(receivedList)}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

        </>
    );

};

export default AdminOfferReceivedListConfirmed;
