import axios from 'axios'
import { Card, Col, Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Target from "./TargetFiles/index";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import Swal from 'sweetalert2'
import Select from "react-select";
import FalconComponentCard from 'components/common/FalconComponentCard';



const index = () => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [allZone, setAllZone] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [allRegion, setAllRegion] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [allArea, setAllArea] = useState([]);
    const [selectedArea, setSelectedArea] = useState(null);
    const [allDbPoint, setAllDbPoint] = useState([]);
    const [selectedDbPoint, setSelectedDbPoint] = useState(null);
    const [allDistributor, setAllDistributor] = useState([]);
    const [selectedDb, setSelectedDb] = useState(null);
    const [allUnderDB, setAllUnderDB] = useState([]);
    const [selectedUnderDb, setSelectedUnderDb] = useState(null);
    const [isLoading, setIsLoading] = useState({
        general: false,
        zone: false,
        region: false,
        area: false,
        dbPoint: false,
        distributor: false,
        underDb: false
    })
    const [orders, setOrders] = useState([]);
    const [targetAndAchievement, setTargetAndAchievement] = useState({});
    const { zoneName } = useSelector(state => state.auth.user.data);

    // get zone list
    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, zone: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setAllZone(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // get region
    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, region: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.regionName,
                        value: data.regionName
                    }));

                    setAllRegion(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, region: false }));
            }
        };
        fetchData();
    }, [selectedZone]);


    // get area name
    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, area: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}areas/${selectedRegion}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.areaName,
                        value: data.areaName
                    }));

                    setAllArea(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, area: false }));
            }

        }
        fetchData();
    }, [selectedRegion]);

    // db points list
    useEffect(() => {
        if (selectedArea) {
            setIsLoading(prevState => ({ ...prevState, dbPoint: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`;
                    const response = await axios.get(url, { headers: authHeader() });
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.dbPointName,
                            value: data.dbPointName
                        }));
                        setAllDbPoint(modifyData);
                    }
                }
                catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, dbPoint: false }));
                }
            }
            fetchData();
        }
    }, [selectedArea])

    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, distributor: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/getSuperDistributors`
                const response = await axios.post(url, {
                    zoneName: selectedZone,
                    regionName: selectedRegion,
                    areaName: selectedArea,
                    dbPointName: selectedDbPoint
                }, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.DBInfo.map(data => ({
                        id: data.userId,
                        label: data.distributorName,
                        value: data.userId
                    }));
                    setAllDistributor(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, distributor: false }));
            }
        }
        fetchData();
    }, [selectedZone, selectedRegion, selectedArea, selectedDbPoint]);

    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, underDb: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dbsUnderSdb/${selectedDb}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.distributorData?.map(data => ({
                        id: data.userId,
                        label: data.distributorName,
                        value: data.userId
                    }));

                    setAllUnderDB(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, underDb: false }));
            }
        };

        fetchData();
    }, [selectedDb]);

    const handleSearch = () => {
        if (!selectedMonth) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a month!',
            });
        }
        if (!selectedYear) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a year!',
            });
        }
        if (!selectedYear) return alert("Please select a year");
        if (selectedMonth && selectedYear) {
            const month = selectedMonth.getMonth() + 1;
            const year = selectedYear.getFullYear();

            setIsLoading(prevState => ({ ...prevState, general: true }));
            const fetchData = async () => {
                /* let targetUrl;
                let ordersUrl;
                if (selectedZone && !selectedRegion) {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/ZonewiseOrderTarget/${selectedZone}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/ZoneWiseOrdersVsTarget/${selectedZone}/${year}/${month}`;
                }
                else if (selectedRegion && !selectedArea) {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/RegioniseOrderTarget/${selectedRegion}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/RegionWiseOrdersVsTarget/${selectedRegion}/${year}/${month}`;
                }
                else if (selectedArea && !selectedDbPoint) {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/AreaWiseOrderTarget/${selectedArea}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/AreaWiseOrdersVsTarget/${selectedArea}/${year}/${month}`;
                }
                else if (selectedDbPoint && !selectedDb) {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBPointWiseOrderTarget/${selectedDbPoint}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBPointWiseOrdersVsTarget/${selectedDbPoint}/${year}/${month}`;
                } else if (selectedDbPoint && selectedDb) {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrderTarget/${selectedDb}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrdersVsTarget/${selectedDb}/${year}/${month}`;
                } */
                if (selectedUnderDb) {
                    const targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrderTarget/${selectedUnderDb}/${year}/${month}`;
                    const ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrdersVsTarget/${selectedUnderDb}/${year}/${month}`;
                    try {
                        const [targetResponse, ordersResponse] = await Promise.all([
                            axios.get(targetUrl, { headers: authHeader() }),
                            axios.get(ordersUrl, { headers: authHeader() })
                        ]);

                        if ((targetResponse.data.success || targetResponse.status === 200) &&
                            (ordersResponse.data.success || ordersResponse.status === 200)) {

                            const targetData = targetResponse.data.payload.targetInfo;
                            const orderVsTarget = ordersResponse.data.payload.orderVsTarget;
                            let totalTargetCnt = 0;
                            let totalAchievementCnt = 0;
                            let totalAchievementAmount = 0;
                            const combinedData = targetData.map(targetItem => {
                                totalTargetCnt += targetItem.targetQuantityCtn;
                                const order = orderVsTarget.find(orderItem => orderItem.skuName === targetItem.skuName);
                                if (order) {
                                    totalAchievementCnt += order.orderQtyCtn;
                                    totalAchievementAmount += parseFloat(order.orderQtyCtn) * parseFloat(targetItem.ctnPrice);
                                }
                                return order ? { ...targetItem, ...order } : targetItem;
                            });

                            setOrders(combinedData);
                            setTargetAndAchievement({
                                totalTargetCnt,
                                totalAchievementCnt,
                                totalAchievementAmount
                            });
                        }
                    } catch (error) {
                        handleApiResponseErrors(error.response);
                    } finally {
                        setIsLoading(prevState => ({ ...prevState, general: false }));
                    }
                } else {
                    const targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/MonthWiseOrderTargetSuperDB`;
                    const ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/MonthWiseOrdersVsTargetSuperDB`;
                    try {
                        const [targetResponse, ordersResponse] = await Promise.all([
                            axios.post(targetUrl, {
                                zoneName: selectedZone,
                                regionName: selectedRegion,
                                areaName: selectedArea,
                                dbPointName: selectedDbPoint,
                                superDBId: selectedDb,
                            }, { headers: authHeader() }),
                            axios.post(ordersUrl, {
                                zoneName: selectedZone,
                                regionName: selectedRegion,
                                areaName: selectedArea,
                                dbPointName: selectedDbPoint,
                                superDBId: selectedDb,
                            }, { headers: authHeader() })
                        ]);

                        if ((targetResponse.data.success || targetResponse.status === 200) &&
                            (ordersResponse.data.success || ordersResponse.status === 200)) {

                            const targetData = targetResponse.data.payload.targetInfo;
                            const orderVsTarget = ordersResponse.data.payload.orderVsTarget;
                            let totalTargetCnt = 0;
                            let totalAchievementCnt = 0;
                            let totalAchievementAmount = 0;
                            const combinedData = targetData.map(targetItem => {
                                totalTargetCnt += targetItem.targetQuantityCtn;
                                const order = orderVsTarget.find(orderItem => orderItem.skuName === targetItem.skuName);
                                if (order) {
                                    totalAchievementCnt += order.orderQtyCtn;
                                    totalAchievementAmount += parseFloat(order.orderQtyCtn) * parseFloat(targetItem.ctnPrice);
                                }
                                return order ? { ...targetItem, ...order } : targetItem;
                            });

                            setOrders(combinedData);
                            setTargetAndAchievement({
                                totalTargetCnt,
                                totalAchievementCnt,
                                totalAchievementAmount
                            });
                        }
                    } catch (error) {
                        handleApiResponseErrors(error.response);
                    } finally {
                        setIsLoading(prevState => ({ ...prevState, general: false }));
                    }
                }
            };

            fetchData();
        }
    }

    if (isLoading.general) {
        return <LoadingIcon />
    }
    return (
        <>
            <FalconComponentCard>
                <FalconComponentCard.Body>
                    <Row className="mb-2">
                        <Col md="4">
                            <Form.Label>Select Zone</Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allZone}
                                    placeholder="Select Zone"
                                    classNamePrefix="react-select"
                                    name="zoneName"
                                    value={allZone.find(option => option.value === selectedZone)}
                                    onChange={(selectedOption) => {
                                        setSelectedZone(selectedOption.value);
                                    }}
                                    isLoading={isLoading.zone}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4" >
                            <Form.Label>Select Region</Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allRegion}
                                    placeholder="Select Area"
                                    classNamePrefix="react-select"
                                    name="areaName"
                                    value={allRegion.find(option => option.value === selectedRegion)}
                                    onChange={(selectedOption) => {
                                        setSelectedRegion(selectedOption.value);
                                    }}
                                    isLoading={isLoading.region}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4" >
                            <Form.Label>Select Area</Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allArea}
                                    placeholder="Select Area"
                                    classNamePrefix="react-select"
                                    name="areaName"
                                    value={allArea.find(option => option.value === selectedArea)}
                                    onChange={(selectedOption) => {
                                        setSelectedArea(selectedOption.value);
                                    }}
                                    isLoading={isLoading.area}
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row className="mb-2">
                        <Col md="4" >
                            <Form.Label>Select DB Point</Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allDbPoint}
                                    placeholder="Select DB Point"
                                    classNamePrefix="react-select"
                                    name="dbPoint"
                                    value={allDbPoint.find(option => option.value === selectedDbPoint)}
                                    onChange={(selectedOption) => {
                                        setSelectedDbPoint(selectedOption.value);
                                    }}
                                    isLoading={isLoading.dbPoint}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4" >
                            <Form.Label>Select Super DB</Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allDistributor}
                                    placeholder="Select DB"
                                    classNamePrefix="react-select"
                                    name="dbName"
                                    value={allDistributor.find(option => option.value === selectedDb)}
                                    onChange={(selectedOption) => {
                                        setSelectedDb(selectedOption.value);
                                    }}
                                    isLoading={isLoading.distributor}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4" >
                            <Form.Label>Select Under DB</Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allUnderDB}
                                    placeholder="Select Under DB"
                                    classNamePrefix="react-select"
                                    name="underDb"
                                    value={allUnderDB.find(option => option.value === selectedUnderDb)}
                                    onChange={(selectedOption) => {
                                        setSelectedUnderDb(selectedOption.value);
                                    }}
                                    isLoading={isLoading.underDb}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" >
                            <Form.Label>Select Month</Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <DatePicker
                                    selected={selectedMonth}
                                    onChange={(date) => setSelectedMonth(date)}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    placeholderText="Select Month"
                                    className="form-control w-100"
                                    width="100%"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6" >
                            <Form.Label>Select Year</Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <DatePicker
                                    selected={selectedYear}
                                    onChange={(date) => setSelectedYear(date)}
                                    dateFormat="yyyy"
                                    showYearPicker
                                    placeholderText="Select Year"
                                    className="form-control"
                                    style={{ width: '100%' }} // Ensures full width
                                />
                                <button className="btn btn-primary px-5 ms-3" onClick={handleSearch}>Search</button>
                            </Form.Group>
                        </Col>
                    </Row>
                </FalconComponentCard.Body>
            </FalconComponentCard>
            <Row className="g-3 my-3">
                <Target orders={orders} targetAndAchievement={targetAndAchievement} />
            </Row>
        </ >

    )
}
export default index;