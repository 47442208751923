import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ExcelDownload = ({ apiData, fileName }) => {
    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const csv = XLSX.utils.sheet_to_csv(ws);
        const data = new Blob([csv], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <div>
            <span className="d-none d-sm-inline-block ms-1" onClick={() => exportToCSV(apiData, fileName)}>
                Export
            </span>
        </div>
    );
};

export default ExcelDownload;
