import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import corner1 from 'assets/img/illustrations/corner-1.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'components/common/Background';
import { formatNumber } from "helpers/utils";
import { getBalance, getPaymentsValueSumByDealerId, getSingleDistributorInfo } from 'services/api';
import { useQuery } from 'react-query'


const SpaceWarning = ({ className, dealerId, dealerName }) => {

  const {
    data: balanceData,
    isLoading: balanceLoading,
    isError: balanceError,
    refetch: balanceRefetch,
  } = useQuery({
    queryKey: ['balance'],
    queryFn: () => getBalance(dealerId),
  });

  const {
    data: paymentsSumData,
    isLoading: paymentsSumDataLoading,
    isError: paymentsSumDataError,
    refetch: paymentsSumDataRefetch,
  } = useQuery({
    queryKey: ['paymentsSum'],
    queryFn: () => getPaymentsValueSumByDealerId(dealerId),
  });

  const {
    data: singleDistributorData,
    isLoading: singleDistributorDataLoading,
    isError: singleDistributorDataError,
    refetch: singleDistributorDataRefetch,
  } = useQuery({
    queryKey: ['singleDistributor'],
    queryFn: () => getSingleDistributorInfo(dealerId),
  });

  useEffect(() => {
    balanceRefetch();
    paymentsSumDataRefetch();
    singleDistributorDataRefetch();
  }, [dealerId]);


  let availableBalanceSum = 0;
  let orderPaymentSumData = 0;
  let userCreditLimit = 0;
  let distributorName= null;

  if (balanceData?.data?.payload?.result) {
    availableBalanceSum = balanceData.data.payload.result.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.drBDT;
      },
      0,
    )
  }

  if (paymentsSumData?.data?.payload?.orderPaymentSumData) {
    orderPaymentSumData= paymentsSumData.data.payload.orderPaymentSumData
  }

  if (singleDistributorData?.data?.payload?.distributorData) {
    userCreditLimit= singleDistributorData.data.payload.distributorData.crBDTLimit
    distributorName= singleDistributorData.data.payload.distributorData.distributorName
  }



  return (

    <Card className={`overflow-hidden ${className}`}>
      <Background image={corner1} className="p-x1 bg-card" />
      <Card.Body className="position-relative">
        <h5 className="text-success my-3">Distributor Name: {dealerName || distributorName}</h5>

        <p className="text">Available Balance: {formatNumber(availableBalanceSum - orderPaymentSumData)}</p>
        <p className="text">Available Credit: {formatNumber(userCreditLimit - (availableBalanceSum - orderPaymentSumData))}</p>

        {/* <h5 className="text-warning">Total Spent: {formatNumber(paymentsSumData.data.payload.orderPaymentSumData)}</h5> */}
        {/* <p className="fs--1 mb-0">
        Your storage will be running out soon. Get more
        <br /> space and powerful productivity features.
      </p> */}
        {/* <Button
        as={Link}
        variant="link"
        className="fs--1 text-warning mt-lg-3 ps-0"
        to="#!"
      >
        Upgrade storage
        <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
      </Button> */}
      </Card.Body>
    </Card>
  )
};

SpaceWarning.propTypes = {
  className: PropTypes.string,
  dealerId: PropTypes.number
};

export default memo(SpaceWarning);
