import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const SrFrom = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [zoneList, setZoneList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [designationList, setdesignationList] = useState([]);
    const [districtsList, setdistrictsList] = useState([]);
    const [productById, setProductById] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();



    const unitsList = [
        {
            label: "10",
            value: "1"
        },
        {
            label: "20",
            value: "2"
        },
        {
            label: "30",
            value: "3"
        },
        {
            label: "40",
            value: "4"
        },
    ]

    const bloodGroup = [
        {
            label: "A+",
            value: "A+"
        },
        {
            label: "A-",
            value: "A-"
        },
        {
            label: "B+",
            value: "B+"
        },
        {
            label: "B-",
            value: "B-"
        },
        {
            label: "AB+",
            value: "AB+"
        },
        {
            label: "AB-",
            value: "AB-"
        },
        {
            label: "O+",
            value: "O+"
        },
        {
            label: "O-",
            value: "O-"
        },
    ]
    const emergencyContactRelation = [
        {
            label: "Parents",
            value: "Parents"
        },
        {
            label: "Children",
            value: "Children"
        },
        {
            label: "Siblings",
            value: "Siblings"
        },
        {
            label: "Grandparents",
            value: "Grandparents"
        },
        {
            label: "Aunts and Uncles",
            value: "Aunts and Uncles"
        },
        {
            label: "Cousins",
            value: "Cousins"
        },
        {
            label: "In-laws",
            value: "In-laws"
        },
        {
            label: "Nieces and Nephews",
            value: "Nieces and Nephews"
        },
        {
            label: "Extended Family",
            value: "Extended Family"
        },
    ]

    const statusList = [
        {
            id: true,
            label: "Active",
            value: true

        },
        {
            id: false,
            label: "InActive",
            value: false
        }
    ]

    // GET BY ID
    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_SR_MANAGEMENT_BASE_URL}sales-representitives/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        setProductById(response?.data?.payload?.product);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);

    console.log('product', productById);
    // SR zone
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.status || response.status === 200 || response.status === 201) {
                    const modifyData = response?.data?.payload?.result.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name
                    }));

                    setZoneList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // SR Region
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.status || response.status === 200 || response.status === 201) {
                    const modifyData = response?.data?.payload?.result.map(data => ({
                        id: data.id,
                        label: data.regionName,
                        value: data.regionName
                    }));

                    setRegionList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // SR area
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}area`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.status || response.status === 200 || response.status === 201) {
                    const modifyData = response?.data?.payload?.result.map(data => ({
                        id: data.id,
                        label: data.areaName,
                        value: data.areaName,
                    }));

                    setAreaList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // SR Bank
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}bank`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.status || response.status === 200 || response.status === 201) {
                    const modifyData = response?.data?.payload?.result.map(data => ({
                        id: data.id,
                        label: data.bankName,
                        value: data.bankName,
                    }));

                    setBankList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // SR designation
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}designation`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.status || response.status === 200 || response.status === 201) {
                    const modifyData = response?.data?.payload?.result.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name,
                    }));

                    setdesignationList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // SR districts
    const districts = [
        {
            label: "Dhaka",
            value: "Dhaka"
        },
        {
            label: "Bogra",
            value: "Bogra"
        },
        {
            label: "Joypurhat",
            value: "Joypurhat"
        },
        {
            label: "Rajshahi",
            value: "Rajshahi"
        },
    ]


    const handleSubmitForm = (values, actions) => {
        const handleSubmitData = {
            dealer: values.dealer.value,
            designation: values.designation.value,
            zone: values.zone.value,
            region: values.region.value,
            area: values.area.value,
            bloodGroup: values.bloodGroup.value,
            district: values.district.value,
            bankName: values.bankName.value,
            emergencyContactRelation: values.emergencyContactRelation.value,
            srName: values.srName,
            basicSalary: values.basicSalary,
            houseRent: values.houseRent,
            medical: values.medical,
            taDa: values.taDa,
            phoneBill: values.phoneBill,
            meetingTa: values.meetingTa.value,
            meetingDa: values.meetingDa,
            mobileNo: values.mobileNo,
            sscYear: values.sscYear,
            hDegree: values.hDegree,
            dobDate: values.dobDate,
            dojDate: values.dojDate,
            email: values.email,
            bankAccountNo: values.bankAccountNo,
            Company: values.Company,
            contribution: values.contribution,
            textDataID: values.textDataID,
            emergencyContactName: values.emergencyContactName,
            emergencyContactMobile: values.emergencyContactMobile,
            statusNew: values.statusNew.value,
            productStatus: values.productStatus.value
        };


        setIsLoading(true);
        const url = updateID
            ? `${process.env.REACT_APP_SR_MANAGEMENT_BASE_URL}sales-representitives${updateID}`
            : `${process.env.REACT_APP_SR_MANAGEMENT_BASE_URL}sales-representitives`;
        const axiosMethod = updateID ? axios.put : axios.post;

        axiosMethod(url, handleSubmitData, { headers: authHeader() })
            .then(response => {
                if (response.data.status || response.status === 200) {
                    navigate("/master/sr");
                    toast.success(response.data.message);
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({
        dealer: Yup.object().required("Please select a value for the dealer field"),
        zone: Yup.object().required("Please select a value for the zone field"),
        region: Yup.object().required("Please select a value for the region field"),
        area: Yup.object().required("Please select a value for the area field"),
        designation: Yup.object().required("Please select a value for the designation field"),
        bloodGroup: Yup.object().required("Please select a value for the blood group field"),
        district: Yup.object().required("Please select a value for the district field"),
        bankName: Yup.object().required("Please select a value for the Bank Name field"),
        emergencyContactRelation: Yup.object().required("Please select a value for the emergencyContactRelation Name field"),
        srName: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please enter a value for the SR Name english name field"),
        basicSalary: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please enter a value for the basic Salry field"),
        houseRent: Yup.number().required("Please enter a value for the House Rent field"),
        Medical: Yup.number().required("Please enter a value for the Medical field"),
        taDa: Yup.string().required("Please enter a value for the TA DA field"),
        PhoneBill: Yup.string().required("Please select a value for the Phone Bill field"),
        meetingTa: Yup.string().required("Please select a value for the Meeting Ta field"),
        meetingDa: Yup.string().required("Please select a value for the meeting Da field"),
        mobileNo: Yup.number().required("Please enter a value for the mobile No field"),
        sscYear: Yup.string().required("Please enter a value for the ssc Year field"),
        hDegree: Yup.string().required("Please enter a value for the High Degree field"),
        email: Yup.string().required("Please enter a value for the email field"),
        bankAccountNo: Yup.number().required("Please enter a value for the Bank account No field"),
        Company: Yup.string().required("Please enter a value for the Company field"),
        Contribution: Yup.string().required("Please enter a value for the Contribution field"),
        textDataID: Yup.string().required("Please enter a value for the text Data ID field"),
        emergencyContactName: Yup.string().required("Please enter a value for the emergency Name field"),
        emergencyContactMobile: Yup.string().required("Please enter a value for the emergency mobile field"),
        dobDate: Yup.string(),
        dojDate: Yup.string(),
        statusNew: Yup.object().required("Please select a value for the is new product field"),
    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update SR" : "Add SR"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{

                            zone: unitsList.find(item => item.value === productById?.zone) || null,
                            region: unitsList.find(item => item.value === productById?.region) || null,
                            area: unitsList.find(item => item.value === productById?.area) || null,
                            dealer: unitsList.find(item => item.value === productById?.dealer) || null,
                            designation: unitsList.find(item => item.value === productById?.designation) || null,
                            bloodGroup: unitsList.find(item => item.value === productById?.bloodGroup) || null,
                            district: unitsList.find(item => item.value === productById?.district) || null,
                            bankName: unitsList.find(item => item.value === productById?.bankName) || null,
                            emergencyContactRelation: unitsList.find(item => item.value === productById?.emergencyContactRelation) || null,
                            srName: productById?.srName || "",
                            basicSalary: productById?.basicSalary || "",
                            houseRent: productById?.houseRent || "",
                            Medical: productById?.Medical || "",
                            taDa: productById?.taDa || "",
                            PhoneBill: productById?.PhoneBill || "",
                            meetingTa: productById?.meetingTa || "",
                            meetingDa: productById?.meetingDa || "",
                            mobileNo: productById?.mobileNo || "",
                            sscYear: productById?.sscYear || "",
                            hDegree: productById?.hDegree || "",
                            email: productById?.email || "",
                            bankAccountNo: productById?.bankAccountNo || "",
                            Company: productById?.Company || "",
                            Contribution: productById?.Contribution || "",
                            textDataID: productById?.textDataID || "",
                            emergencyContactName: productById?.emergencyContactName || "",
                            emergencyContactMobile: productById?.emergencyContactMobile || "",
                            dobDate: productById?.dobDate || "",
                            dojDate: productById?.dojDate || "",
                            productStatus: statusList.find(item => item.value === productById?.status) || null
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Zone</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={zoneList}
                                                placeholder="Select Zone"
                                                classNamePrefix="react-select"
                                                name="zone"
                                                value={values.zone}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "zone",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.zone && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.zone}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Region</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={regionList}
                                                placeholder="Select Region"
                                                classNamePrefix="react-select"
                                                name="region"
                                                value={values.region}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "region",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.region && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.region}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Area</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={areaList}
                                                placeholder="Select Area"
                                                classNamePrefix="react-select"
                                                name="Area"
                                                value={values.area}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "area",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.area && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.area}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select dealer</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={unitsList}
                                                placeholder="Select dealer"
                                                classNamePrefix="react-select"
                                                name="dealer"
                                                value={values.dealer}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "dealer",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.dealer && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.dealer}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select designation</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={designationList}
                                                placeholder="Select designation"
                                                classNamePrefix="react-select"
                                                name="designation"
                                                value={values.designation}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "designation",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.designation && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.designation}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>SR Name</Form.Label>
                                            <Form.Control
                                                name="srName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter SR Name"
                                                required
                                                value={values.srName}
                                            />
                                            {touched.srName && errors.srName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.srName}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstSalry">
                                            <Form.Label>Basic Salry</Form.Label>
                                            <Form.Control
                                                name="basicSalary"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Basic Salry"
                                                required
                                                value={values.basicSalary}
                                            />
                                            {touched.basicSalary && errors.basicSalary && (
                                                <div style={{ color: "red" }}>
                                                    {errors.basicSalary}
                                                </div>
                                            )}

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label> House Rent</Form.Label>
                                            <Form.Control
                                                name="houseRent"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter  House Rent"
                                                required
                                                value={values.houseRent}
                                            />
                                            {touched.houseRent && errors.houseRent && (
                                                <div style={{ color: "red" }}>
                                                    {errors.houseRent}
                                                </div>
                                            )}

                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Medical</Form.Label>
                                            <Form.Control
                                                name="Medical"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Medical"
                                                required
                                                value={values.Medical}
                                            />
                                            {touched.Medical && errors.Medical && (
                                                <div style={{ color: "red" }}>
                                                    {errors.Medical}
                                                </div>
                                            )}

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>TA DA</Form.Label>
                                            <Form.Control
                                                name="taDa"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter TA DA"
                                                required
                                                value={values.taDa}
                                            />
                                            {touched.taDa && errors.taDa && (
                                                <div style={{ color: "red" }}>
                                                    {errors.taDa}
                                                </div>
                                            )}

                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">


                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label> Phone Bill</Form.Label>
                                            <Form.Control
                                                name="PhoneBill"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Phone Bill"
                                                required
                                                value={values.PhoneBill}
                                            />
                                            {touched.PhoneBill && errors.PhoneBill && (
                                                <div style={{ color: "red" }}>
                                                    {errors.PhoneBill}
                                                </div>
                                            )}

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label> Meeting TA</Form.Label>
                                            <Form.Control
                                                name="meetingTa"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Meeting TA"
                                                required
                                                value={values.meetingTa}
                                            />
                                            {touched.meetingTa && errors.meetingTa && (
                                                <div style={{ color: "red" }}>
                                                    {errors.meetingTa}
                                                </div>
                                            )}

                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">


                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label> Meeting DA</Form.Label>
                                            <Form.Control
                                                name="meetingDa"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter  Meeting DA"
                                                required
                                                value={values.meetingDa}
                                            />
                                            {touched.meetingDa && errors.meetingDa && (
                                                <div style={{ color: "red" }}>
                                                    {errors.meetingDa}
                                                </div>
                                            )}

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label> Mobile No</Form.Label>
                                            <Form.Control
                                                name="mobileNo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Mobile No"
                                                required
                                                value={values.mobileNo}
                                            />
                                            {touched.mobileNo && errors.mobileNo && (
                                                <div style={{ color: "red" }}>
                                                    {errors.mobileNo}
                                                </div>
                                            )}

                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">



                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label> SSC Year</Form.Label>
                                            <Form.Control
                                                name="sscYear"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter SSC Year"
                                                required
                                                value={values.sscYear}
                                            />
                                            {touched.sscYear && errors.sscYear && (
                                                <div style={{ color: "red" }}>
                                                    {errors.sscYear}
                                                </div>
                                            )}

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>H.Degree</Form.Label>
                                            <Form.Control
                                                name="hDegree"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter H.Degree"
                                                required
                                                value={values.hDegree}
                                            />
                                            {touched.hDegree && errors.hDegree && (
                                                <div style={{ color: "red" }}>
                                                    {errors.hDegree}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">


                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Blood Group</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={bloodGroup}
                                                placeholder="Select Blood Group"
                                                classNamePrefix="react-select"
                                                name="bloodGroup"
                                                value={values.bloodGroup}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "bloodGroup",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />
                                            {
                                                errors.bloodGroup && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.bloodGroup}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>DOB Date</Form.Label>
                                            <Form.Control
                                                name="dobDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter DOB Date"
                                                value={values.dobDate}
                                            />
                                            {touched.dobDate && errors.dobDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.dobDate}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">


                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>DOJ Date</Form.Label>
                                            <Form.Control
                                                name="dojDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter DOJ Date"
                                                value={values.dojDate}
                                            />
                                            {touched.dojDate && errors.dojDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.dojDate}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select district </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={districts}
                                                placeholder="Select district "
                                                classNamePrefix="react-select"
                                                name="district"
                                                value={values.district}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "district",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.district && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.district}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleStateMail">
                                            <Form.Label>Mail</Form.Label>
                                            <Form.Control
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="email"
                                                placeholder="Enter Mail"
                                                required
                                                value={values.email}
                                            />
                                            {touched.email && errors.email && (
                                                <div style={{ color: "red" }}>
                                                    {errors.email}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Bank Name </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={bankList}
                                                placeholder="Select Bank Name "
                                                classNamePrefix="react-select"
                                                name="unit"
                                                value={values.bankName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "bankName",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.bankName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.bankName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleStateMail">
                                            <Form.Label>Bank Account No</Form.Label>
                                            <Form.Control
                                                name="bankAccountNo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Bank Account No"
                                                required
                                                value={values.bankAccountNo}
                                            />
                                            {touched.bankAccountNo && errors.bankAccountNo && (
                                                <div style={{ color: "red" }}>
                                                    {errors.bankAccountNo}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleStateMail">
                                            <Form.Label>Company</Form.Label>
                                            <Form.Control
                                                name="Company"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Company"
                                                required
                                                value={values.Company}
                                            />
                                            {touched.Company && errors.Company && (
                                                <div style={{ color: "red" }}>
                                                    {errors.Company}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleStateMail">
                                            <Form.Label> Contribution</Form.Label>
                                            <Form.Control
                                                name="Contribution"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Contribution"
                                                required
                                                value={values.Contribution}
                                            />
                                            {touched.Contribution && errors.Contribution && (
                                                <div style={{ color: "red" }}>
                                                    {errors.Contribution}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleStateMail">
                                            <Form.Label>Text Data ID</Form.Label>
                                            <Form.Control
                                                name="textDataID"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Text Data ID"
                                                required
                                                value={values.textDataID}
                                            />
                                            {touched.textDataID && errors.textDataID && (
                                                <div style={{ color: "red" }}>
                                                    {errors.textDataID}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleStateMail">
                                            <Form.Label>Emergency Contact Name</Form.Label>
                                            <Form.Control
                                                name="emergencyContactName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Emergency Name"
                                                required
                                                value={values.emergencyContactName}
                                            />
                                            {touched.emergencyContactName && errors.emergencyContactName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.emergencyContactName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleStateMail">
                                            <Form.Label>Emergency Contact Mobile</Form.Label>
                                            <Form.Control
                                                name="emergencyContactMobile"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Emergency mobile"
                                                required
                                                value={values.emergencyContactMobile}
                                            />
                                            {touched.emergencyContactMobile && errors.emergencyContactMobile && (
                                                <div style={{ color: "red" }}>
                                                    {errors.emergencyContactMobile}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">


                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select emergencyContactRelation </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={emergencyContactRelation}
                                                placeholder="Select emergencyContactRelation"
                                                classNamePrefix="react-select"
                                                name="emergencyContactRelation"
                                                value={values.emergencyContactRelation}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "emergencyContactRelation",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.emergencyContactRelation && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.emergencyContactRelation}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleStateOther">
                                            <Form.Label>Other</Form.Label>
                                            <Form.Control
                                                name="Other"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Other"
                                                required
                                                value={values.Other}
                                            />
                                            {touched.Other && errors.Other && (
                                                <div style={{ color: "red" }}>
                                                    {errors.Other}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Status</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={statusList}
                                                placeholder="Select Product Status"
                                                classNamePrefix="react-select"
                                                name="productStatus"
                                                value={values.productStatus}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "productStatus",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.productStatus && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.productStatus}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/sr")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default SrFrom;


