import React from 'react'
import { Col, Row } from 'react-bootstrap'

import AvailableStock from './AvailableStock'
import PendingDeliveries from './PendingDeliveries'
import ReceivedStock from './ReceivedStock'
import TotalDelivery from './TotalDelivery'

const Inventory = () => {
    return (
        <>
            {/* <div class="container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <h1 class="text-center">Dashboard...</h1>
    </div> */}
            <Row className="g-3 mb-3">
                <Col md={6} xxl={3}>
                    <PendingDeliveries />
                </Col>
                <Col md={6} xxl={3}>
                    {/* <TotalOrder data={totalOrder} /> */}
                    <ReceivedStock />
                </Col>
                <Col md={6} xxl={3}>
                    {/* <MarketShare data={marketShare} radius={['100%', '87%']} /> */}
                    <TotalDelivery />
                </Col>
                <Col md={6} xxl={3}>
                    {/* <Weather data={weather} /> */}
                    <AvailableStock />
                </Col>
            </Row>
            {/* 

    <Row className="g-3 mb-3">
      <Col lg={6}>
        <RunningProjects data={runningProjects} />
      </Col>
      <Col lg={6}>
        <TotalSales data={totalSales} />
      </Col>
    </Row>

    <Row className="g-3 mb-3">
      <Col lg={6} xl={7} xxl={8}>
        <StorageStatus className="h-lg-100" data={storageStatus} />
      </Col>
      <Col lg={6} xl={5} xxl={4}>
        <SpaceWarning />
      </Col>
    </Row>

    <Row className="g-3 mb-3">
      <Col lg={7} xl={8}>
        <BestSellingProducts products={products} />
      </Col>
      <Col lg={5} xl={4}>
        <SharedFiles files={files} className="h-lg-100" />
      </Col>
    </Row>

    <Row className="g-3">
      <Col sm={6} xxl={3}>
        <ActiveUsers className="h-100" users={users} />
      </Col>
      <Col sm={6} xxl={3} className="order-xxl-1">
        <BandwidthSaved />
      </Col>
      <Col xxl={6}>
        <TopProducts data={topProducts} className="h-100" />
      </Col>
    </Row> */}
        </>
    )
}

export default Inventory
