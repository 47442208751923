import FalconComponentCard from 'components/common/FalconComponentCard'
import SharedFiles from 'components/dashboards/default/SharedFiles'
import SharedFiles2 from 'components/dashboards/default/SharedFiles2'
import SpaceWarning from 'components/dashboards/default/SpaceWarning'
import BestSellingProducts from 'components/dashboards/e-commerce/BestSellingProducts'
import AppContext from 'context/Context'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, Row, Table, Card, Button, FormCheck, FormLabel } from 'react-bootstrap'
import SimpleBarReact from 'simplebar-react'
import axios from 'axios'
import { formatNumber } from 'helpers/utils'

import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import Swal from 'sweetalert2'
import CollapseSchedule from './CollapseSchedule'

import {
    getAllSchedules,
    getAreas,
    getDBPoints,
    getDistributors,
    getInventoryStocks,
    getInventoryStocksSum,
    getOrdersByDealerIds,
    getInventoryStocksDeliveredSum,
    getRegions,
    getZones,
    scheduleSubmit,
    getOrdersByDealerIdFactory,
    orderUpdate,
    getScheduleDataBySkuDo
} from 'services/api'
import { set } from 'date-fns'

const Scheduling = ({ user }) => {
    const [first, setfirst] = useState('')
    const [validated, setValidated] = useState(false)
    const navigate = useNavigate()
    const [zoneValue, setZoneValue] = useState(null)
    const [regionValue, setRegionValue] = useState(null)
    const [areaValue, setAreaValue] = useState(null)
    const [dbPointValue, setDBPointValue] = useState(null)
    const [distributorValue, setDistributorValue] = useState([])
    const [schedulableOrders, setSchedulableOrders] = useState([]);
    const [groupedOrders, setGroupedOrders] = useState([])
    const [selectedFactoryname, setSelectedFactoryName] = useState('')
    const [selectedSKUname, setSelectedSKUName] = useState('')
    const [selectedDoNo, setSelectedDoNo] = useState('')
    const [offerFactoryName, setOfferFactoryName] = useState(null)
    const [allOrdersData, setAllOrdersData] = useState([])
    const [expand, setExpand] = useState(false)

    const [distributorOptions, setDistributorOptions] = useState([])

    const [updatableCombos, setUpdatableCombos] = useState([])
    const [checkedOrders, setCheckedOrders] = useState([])


    const [myFactory, setMyFactory] = useState(null);

    const [stocksList, setStocksList] = useState([]);




    const [sc, setSc] = useState(Math.floor(Math.random() * 1000000000) + '-' + user.data.userId)



    //for setting dealer id  when changing quantity
    const [dealerId, setDealerId] = useState(null)
    const [dealerName, setDealerName] = useState(null)
    const [selectedDbType, setSelectedDbType] = useState(null)

    var firstDay = new Date();
    var nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);

    const [deliveryDate, setDeliveryDate] = useState(firstDay);
    const [deliveryDateEnd, setDeliveryDateEnd] = useState(nextWeek);


    const factoryList = [

        {
            label: "SORL",
            value: "SORL"
        },
        {
            label: "SVOIL",
            value: "SVOIL"
        },
        {
            label: "Narshingdi",
            value: "Narshingdi"
        },
        {
            label: "Dhamrai",
            value: "Dhamrai"
        },
        {
            label: "Dhaka Central Depot",
            value: "Dhaka Central Depot"
        },
        {
            label: "Ctg Depot",
            value: "Ctg Depot"
        },
        {
            label: "Noakhali Depot",
            value: "Noakhali Depot"
        },
        {
            label: "Sylhet Depot",
            value: "Sylhet Depot"
        },
        {
            label: "Jessore Depot",
            value: "Jessore Depot"
        },
        {
            label: "Mymensingh Depot",
            value: "Mymensingh Depot"
        },
        {
            label: "Bogra Depot",
            value: "Bogra Depot"
        },
        {
            label: "Barisal Depot",
            value: "Barisal Depot"
        },
        {
            label: "Tekerhat Depot",
            value: "Tekerhat Depot"
        },
        {
            label: "Rangpur Depot",
            value: "Rangpur Depot"
        },
    ]




    const [submittedOrders, setSubmittedOrders] = useState([])

    const { data: zoneData, isLoading: isLoadingZone } = useQuery({
        queryKey: ['zones'],
        queryFn: getZones,
    })

    const { data: regionData, isLoading: isLoadingRegion } = useQuery({
        queryKey: ['regions'],
        queryFn: getRegions,
    })

    const { data: areaData, isLoading: isLoadingArea } = useQuery({
        queryKey: ['areas'],
        queryFn: getAreas,
    })

    const { data: dbPointData, isLoading: isLoadingDBPoint } = useQuery({
        queryKey: ['dbPoints'],
        queryFn: getDBPoints,
    })

    const { data: distributorData, isLoading: isLoadingDistributor } = useQuery(
        {
            queryKey: ['distributors'],
            queryFn: getDistributors,
        }
    )

    console.log("Schedule Number", sc);


    // const {
    //     data: orderData,
    //     isLoading: isLoadingOrder,
    //     isError: orderDataError,
    //     refetch: orderDataRefetch,
    // } = useQuery({
    //     queryKey: ['orders'],
    //     queryFn: () => getOrdersByDealerId(distributorValue?.userId || ''),
    // })

    const fetchOrdersData = () => {
        // getOrdersByDealerIds({ dealerIds: distributorValue.map(item => item.userId), factoryName: myFactory.value })

        getOrdersByDealerIdFactory({ dealerIds: distributorValue.map(item => item.dealerId), factoryName: myFactory.value })
            .then((res) => {
                if (res.data?.success) {
                    console.log(res.data?.message)
                    const groupedOrders = res.data?.payload?.groupedOrders;

                    //extract scOrders arrays from the above groupedOrders then flatten them

                    const scOrders = Object.values(groupedOrders).map((order) => {
                        return order.scOrders
                    }).flat()


                    // const modifiedOrders = res.data?.payload?.moreDBOrders?.map((order) => {
                    const modifiedOrders = scOrders?.map((order) => {
                        return {
                            ...order,
                            deliveryQty: user.data.userRole == "SPECIAL DISTRIBUTOR" ? order.totalOrderQtyPCS : order.orderQtyCtn
                        }
                    })

                    setGroupedOrders(groupedOrders)

                    setDistributorOptions(groupedOrders.map((order) => {
                        return {
                            ...order,
                            value: order.dealerId,
                            label: order.dealerId + " (" + order.distributorName + ")"
                        }
                    }))


                    setSchedulableOrders(modifiedOrders)
                    // setAllOrdersData(res.data.payload.moreDBOrders)
                    setAllOrdersData(modifiedOrders)

                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    toast.error(e.response?.data?.errors)
                }
            })
    }


    const {
        data: stockData,
        isLoading: isLoadingStock,
        isError,
        refetch,
    } = useQuery({
        queryKey: ['stocks'],
        queryFn: () => getInventoryStocks(selectedSKUname, selectedFactoryname),
    })


    useEffect(() => {

        // if (distributorValue.length && myFactory) {
        //     fetchOrdersData();
        // }

        if (myFactory) {
            fetchOrdersData();
        }

    }, [distributorValue.length, submittedOrders.length, myFactory])

    // useEffect(() => {
    //     refetch()
    // }, [selectedSKUname, selectedFactoryname])

    // useEffect(() => {
    //     scheduleRefetch()
    // }, [selectedDoNo, selectedSKUname])

    // useEffect(() => {
    //     refetch()
    //     // scheduleRefetch()
    // }, [submittedOrders.length])

    useEffect(() => {

        const allDatas = Object.values(groupedOrders).map((order) => {
            return order.scOrders.filter((tr) => tr.isChecked)
        }).flat()


        allDatas.forEach((item) => {
            item.rcvQtyCTN = 0
            item.rcvQtyPcs = 0

            item.stockDeliveredQtyCTN = 0
            item.stockDeliveredQtyPcs = 0
        })


        const receivedResponse = axios.all(allDatas.map((item) => !item.offerNo && getInventoryStocksSum(item.skuName, item.factoryName)))
            .then(axios.spread((...responses) => {
                responses.forEach((response) => {
                    const { data } = response;
                    if (data?.payload?.length > 0) {
                        allDatas.forEach((item) => {

                            if (item.skuName === data?.payload[0]?.skuName && item.factoryName[0] === data?.payload[0]?.factoryName) {

                                item.rcvQtyCTN = data?.payload?.reduce((a, b) => a + b?.rcvQtyCTN, 0);
                                item.rcvQtyPcs = data?.payload?.reduce((a, b) => a + b?.rcvQtyPcs, 0);
                            }

                        })
                    }
                });


                // setStocksList([...allDatas]) 


                const deliveredResponse = axios.all(allDatas.map((item) => !item.offerNo && getInventoryStocksDeliveredSum(item.skuName, item.factoryName)))
                    .then(axios.spread((...responses) => {
                        responses.forEach((response) => {
                            const { data } = response;
                            if (data?.payload?.length > 0) {
                                allDatas.forEach((item) => {

                                    if (item.skuName === data?.payload[0]?.skuName && item.factoryName[0] === data?.payload[0]?.factoryName) {

                                        item.stockDeliveredQtyCTN = data?.payload?.reduce((a, b) => a + b?.delQtyCtn, 0);
                                        item.stockDeliveredQtyPcs = data?.payload?.reduce((a, b) => a + b?.delQtyPcs, 0);
                                    }

                                })
                            }
                        });


                        setStocksList([...allDatas.filter((item) => !item?.offerNo)])




                    }))
                    .catch(errors => console.log(errors)).finally(() => {
                        console.log("done");

                    })




            }))
            .catch(errors => console.log(errors)).finally(() => {
                console.log("done");

            })
    }, [groupedOrders])

    console.log("stocksList", stocksList);



    const handleSubmit = (event) => {
        console.log('distributorValue', distributorValue)
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }

        setValidated(true)
    }

    const {
        config: { isNavbarVerticalCollapsed, isFluid, isRTL },
        setConfig,
    } = useContext(AppContext)
    useEffect(() => {
        const handleClick = () => {
            document
                .getElementsByTagName('html')[0]
                .classList.toggle('navbar-vertical-collapsed')
            setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed)
        }

        handleClick()
    }, [])

    // const zoneOptions = zoneData?.data?.payload?.result.map((zone) => ({
    //     value: zone._id,
    //     label: zone.name,
    //     ...zone,
    // }))

    let zoneOptions = []
    if (zoneData && zoneData.data.payload.result.length) {
        zoneOptions = zoneData.data.payload.result.map((zone) => ({
            value: zone._id,
            label: zone.name,
            ...zone,
        }))
    }

    // const regionOptions = regionData?.data?.payload?.result.map((region) => ({
    //     value: region._id,
    //     label: region.regionName,
    //     ...region,
    // }))

    let regionOptions = []
    if (regionData && regionData.data.payload.result.length && zoneValue) {
        regionOptions = regionData.data.payload.result
            .filter((item) => item.zoneName == zoneValue.label)
            .map((region) => ({
                value: region._id,
                label: region.regionName,
                ...region,
            }))
    }

    // const areaOptions = areaData?.data?.payload?.result.map((area) => ({
    //     value: area._id,
    //     label: area.areaName,
    //     ...area,
    // }))

    let areaOptions = []
    if (areaData && areaData.data.payload.result.length && regionValue) {
        areaOptions = areaData.data.payload.result
            .filter((item) => item.regionName == regionValue.label)
            .map((area) => ({
                value: area._id,
                label: area.areaName,
                ...area,
            }))
    }

    // const dbPointOptions = dbPointData?.data?.payload?.result.map(
    //     (dbPoint) => ({
    //         value: dbPoint._id,
    //         label: dbPoint.dbPointName,
    //         ...dbPoint,
    //     })
    // )

    let dbPointOptions = []
    if (dbPointData && dbPointData.data.payload.result.length && areaValue) {
        dbPointOptions = dbPointData.data.payload.result
            .filter((item) => item.areaName == areaValue.label)
            .map((dbPoint) => ({
                value: dbPoint._id,
                label: dbPoint.dbPointName,
                ...dbPoint,
            }))
    }

    // const distributorOptions = distributorData?.data?.payload?.distributors.map(
    //     (distributor) => ({
    //         value: distributor._id,
    //         label: distributor.distributorName,
    //         ...distributor,
    //     })
    // )

    // let distributorOptions = []
    // if (
    //     distributorData &&
    //     distributorData.data.payload.distributors.length
    //     // && dbPointValue
    // ) {
    //     distributorOptions = distributorData.data.payload.distributors
    //         .filter((item) => !item.superDBId)
    //         .map((distributor) => ({
    //             // value: distributor._id,
    //             // label: distributor.distributorName,

    //             value: distributor.userId,
    //             label: distributor.userId + " (" + distributor.distributorName + ")",
    //             ...distributor,
    //         }))
    // }

    // const allOrdersData = orderData?.data?.payload?.result || []

    // let allOrdersData = []
    // if (orderData && orderData.data.payload.result.length) {
    //     allOrdersData = orderData.data.payload.result.filter(
    //         (item) => item.status == 6
    //     )
    // }

    // const allStocksData = stockData?.data?.payload?.result || []

    let allStocksData = []
    if (stockData && stockData.data.payload.result.length) {
        allStocksData = stockData.data.payload.result
    }



    const getFormattedDate = (data) => {
        const originalDateString = data
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`

        return formattedDateString
    }

    const getFormattedDate2 = (dt) => {
        const deliveryDate = new Date(dt)

        const year = deliveryDate.getFullYear()
        const month = String(deliveryDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(deliveryDate.getDate()).padStart(2, '0')

        const formattedDate = `${year}-${month}-${day}`

        return formattedDate
    }

    console.log("updatableCombos", updatableCombos);


    const handleScheduleSubmit = (e) => {
        e.preventDefault();
        // if (!deliveryDate || !deliveryDateEnd) {

        //     // alert("Please select the dates");

        //     Swal.fire({
        //         title: 'Validation',
        //         text: 'Please select the dates',
        //         icon: 'warning',
        //     })

        //     return;
        // }

        //exctract schOrders from groupedOrders and then flatten them



        const scOrders = Object.values(groupedOrders).map((order) => {
            return order.scOrders.filter((tr) => tr.deliveryQty > 0 && tr.isChecked)
        }).flat()


        const scOffers = Object.values(groupedOrders).map((order) => {
            return order.scOrders.filter((tr) => tr.offerNo && tr.isChecked)
        }).flat()

        // return;




        const postArr = scOrders
            // .filter((tr) => tr.deliveryQty > 0)
            // .filter((tr) => tr.factoryName[0])
            .map((item) => {

                // let offerScheduleAlreadyDone = false;
                // const existing = allScheduleData.filter(mn => mn.skuName == item.skuName && mn.doNo == item.doNo);
                // for (let i = 0; i < existing.length; i++) {
                //     const element = existing[i];
                //     if (element.offerSkuName && element.offerCategory && element.offerQtyPCS) {
                //         offerScheduleAlreadyDone = true;
                //         break;
                //     }
                // }

                // delete item._id
                const ID = item._id
                delete item._id

                return {
                    ...item,
                    ID: ID,
                    skuName: item.skuName,
                    doNo: item.doNo,
                    factoryName: myFactory.value,
                    userId: user.data.userId,
                    scheduleNo: sc,
                    zoneName: item.zone,
                    regionName: item.region,
                    areaName: item.area,
                    delQtyCtn: item.dbType == "SPECIAL DISTRIBUTOR" ? 0 : +item.deliveryQty,
                    orderQtyCtn: item.orderQtyCtn,
                    alreadyScheduledQty: item.alreadyScheduledQty,
                    delQtyPcs: item.dbType == "SPECIAL DISTRIBUTOR" ? item.deliveryQty : item.pcsCTN * item.deliveryQty,
                    weightPerPC: item.weightPerPC,
                    erpId: item.erpId,
                    orderDate: item.doDate,
                    scheduleDate: getFormattedDate(new Date()),
                    // deliveryDate: getFormattedDate2(item.deliveryDate),
                    status: 8,
                    dbType: item.dbType,
                    productCategory: item.categoryName,
                    offerCategory: item.offerScheduleAlreadyDone ? "" : item.offerAccepted == "product" ? item.offerCategoryName : "",
                    offerSkuName: item.offerScheduleAlreadyDone ? "" : item.offerAccepted == "product" ? item.offerSkuName : "",
                    offerQtyPCS: item.offerScheduleAlreadyDone ? 0 : item.offerAccepted == "product" ? item.offerPc : 0,
                    superDBId: item.superDBId,
                    dealerId: item.dealerId,
                    distributorName: item.distributorName,
                    deliveryDate: deliveryDate,
                    deliveryDateEnd: deliveryDateEnd,
                    productSubCategory: item.subCategory || "",
                    perPCPrice: item.perPCPrice,
                    offerUnit: item?.offerUnit || "",
                }
            })

       

        const offerPostArr = scOffers.map(yap => {

            // delete yap._id;
            const ID = yap._id

            return {

                ...yap,
                ID: ID,
                skuName: yap.skuName,
                doNo: yap.doNo,
                // factoryName: yap.factoryName,
                factoryName: myFactory.value,
                userId: user.data.userId,
                scheduleNo: sc,
                zoneName: yap.zone,
                regionName: yap.region,
                areaName: yap.area,
                orderDate: yap.doDate,
                scheduleDate: getFormattedDate(new Date()),
                skuName: yap.offerName,
                superDBId: yap.superDBId,
                dealerId: yap.dealerId,
                dbType: yap.dbType,
                distributorName: yap.distributorName,
                doNo: yap.doNo,
                deliveryDate: deliveryDate,
                deliveryDateEnd: deliveryDateEnd,
                offerSkuName: yap.offerSkuName,
                offerQtyPCS: yap.offerPc,
                offerStatus: 1,
                offerNo: yap.offerNo,
                superDBId: yap.superDBId || "",
                status: 8,
                unitName: "PCS",
                perPCPrice: yap.perPCPrice,
                offerUnit: yap?.offerUnit || "",

                // delQtyCtn: yap.offerPc


            }
        })

        // if (offerPostArr.length == 0) {

        //     Swal.fire({
        //         title: 'Validation',
        //         text: 'No Orders Selected',
        //         icon: 'warning',
        //     })
        //     return;

        // }

        if (postArr.length == 0 && offerPostArr.length == 0) {

            Swal.fire({
                title: 'Validation',
                text: 'No Orders Selected',
                icon: 'warning',
            })
            return;

        }




        scheduleSubmit([...postArr, ...offerPostArr])
            .then((res) => {
                if (res.data?.success) {
                    console.log(res.data?.message)

                    const postArrWithId = postArr
                        // .filter((tr) => tr.deliveryQty > 0)
                        // .filter((tr) => tr.factoryName[0])
                        .map((item) => {

                            // let offerScheduleAlreadyDone = false;
                            // const existing = allScheduleData.filter(mn => mn.skuName == item.skuName && mn.doNo == item.doNo);
                            // for (let i = 0; i < existing.length; i++) {
                            //     const element = existing[i];
                            //     if (element.offerSkuName && element.offerCategory && element.offerQtyPCS) {
                            //         offerScheduleAlreadyDone = true;
                            //         break;
                            //     }
                            // }

                            return {
                                ...item,
                                _id: item.ID,
                                factoryName: myFactory.value,
                                userId: user.data.userId,
                                scheduleNo: sc,
                                zoneName: item.zone,
                                regionName: item.region,
                                areaName: item.area,
                                delQtyCtn: item.dbType == "SPECIAL DISTRIBUTOR" ? 0 : +item.deliveryQty,
                                orderQtyCtn: item.orderQtyCtn,
                                delQtyPcs: item.dbType == "SPECIAL DISTRIBUTOR" ? item.deliveryQty : item.pcsCTN * item.deliveryQty,
                                weightPerPC: item.weightPerPC,
                                erpId: item.erpId,
                                orderDate: item.doDate,
                                scheduleDate: getFormattedDate(new Date()),
                                deliveryDate: getFormattedDate2(item.deliveryDate),
                                status: 8,
                                dbType: item.dbType,
                                productCategory: item.categoryName,
                                offerCategory: item.offerScheduleAlreadyDone ? "" : item.offerAccepted == "product" ? item.offerCategoryName : "",
                                offerSkuName: item.offerScheduleAlreadyDone ? "" : item.offerAccepted == "product" ? item.offerSkuName : "",
                                offerQtyPCS: item.offerScheduleAlreadyDone ? 0 : item.offerAccepted == "product" ? item.offerPc : 0,
                                superDBId: item.superDBId,
                                dealerId: item.dealerId,
                                distributorName: item.distributorName,
                                deliveryDate: deliveryDate,
                                deliveryDateEnd: deliveryDateEnd,
                                productSubCategory: item.subCategory || "",
                                perPCPrice: item.perPCPrice,
                            }
                        })

                    const offerPostArrWithId = offerPostArr.map(yap => {



                        return {

                            ...yap,
                            _id: yap.ID,
                            factoryName: yap.factoryName,
                            userId: user.data.userId,
                            scheduleNo: sc,
                            zoneName: yap.zone,
                            regionName: yap.region,
                            areaName: yap.area,
                            orderDate: yap.doDate,
                            scheduleDate: getFormattedDate(new Date()),
                            skuName: yap.offerName,
                            superDBId: yap.superDBId,
                            dealerId: yap.dealerId,
                            dbType: yap.dbType,
                            distributorName: yap.distributorName,
                            doNo: yap.doNo,
                            deliveryDate: deliveryDate,
                            deliveryDateEnd: deliveryDateEnd,
                            offerSkuName: yap.offerSkuName,
                            offerQtyPCS: yap.offerPc,
                            offerStatus: 1,
                            offerNo: yap.offerNo,
                            superDBId: yap.superDBId || "",
                            status: 8,
                            unitName: "PCS",
                            perPCPrice: yap.perPCPrice

                            // delQtyCtn: yap.offerPc


                        }
                    })



                    const withIdsMerged = [...postArrWithId.filter(itm => (user.data.userRole == "SPECIAL DISTRIBUTOR" ? itm.totalOrderQtyPCS : itm.orderQtyCtn) == (+itm.deliveryQty + itm.alreadyScheduledQty)), ...offerPostArrWithId];


                    orderUpdate(withIdsMerged.map((item) => ({ _id: item._id, scheduleStatus: 1 }))).then((response) => {

                        if (response.data?.success) {

                            // alert(res.data?.message)
                            setSelectedFactoryName('')
                            setSelectedSKUName('')
                            setSelectedDoNo('')
                            // window.location.reload()
                            setSubmittedOrders([
                                ...submittedOrders,
                                ...res.data ? res.data?.insertedDistributionSkus : [],
                            ])

                            //set unique SubmittedOrders

                            // setSubmittedOrders([...new Set([
                            //     ...submittedOrders,
                            //     ...res.data ? res.data?.insertedDistributionSkus : [],
                            // ])]);



                            setSc(Math.floor(Math.random() * 1000000000) + '-' + user.data.userId)
                            setUpdatableCombos([])

                            // toast.success(response.data?.message)
                            toast.success("Schedule Submitted Successfully");
                            // navigate('/distribution/productscheduling/pending')

                        }
                    })
                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    toast.error(e.response?.data?.errors)
                }
            })
    }

    const getScheduledQuantityBySKU = (skuName, doNo) => {
        const totalQty = allScheduleData
            .filter((item) => item.skuName == skuName && item.doNo == doNo)
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    const getScheduledQuantityBySKUInPieces = (skuName, doNo) => {
        const totalQty = allScheduleData
            .filter((item) => item.skuName == skuName && item.doNo == doNo)
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyPcs
            }, 0)

        return totalQty
    }

    const getScheduledQuantityBySKUOnly = (skuName, factoryName) => {
        const totalQty = allScheduleData
            .filter(
                (item) =>
                    item.skuName == skuName && item.factoryName == factoryName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    const getScheduledQuantityBySKUOnlyInPieces = (skuName, factoryName) => {
        const totalQty = allScheduleData
            .filter(
                (item) =>
                    item.skuName == skuName && item.factoryName == factoryName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyPcs
            }, 0)

        return totalQty
    }

    console.log("updatableCombos", updatableCombos);

    const handleCheckAll = (event) => {
        // const isChecked = event.target.checked
        // if (isChecked) {
        //     setCheckedOrders(groupedOrders)
        // } else {
        //     setCheckedOrders([])
        // }

        groupedOrders.forEach((element, index) => {
            handleParentCheck(index, event.target.checked)
        })



    }
    const isChecked = (order) => {
        return checkedOrders.some((p) => p._id === order._id)
    }

    const handleCheckboxChange = (order) => {
        if (isChecked(order)) {
            setCheckedOrders(checkedOrders.filter((p) => p._id !== order._id))
        } else {
            setCheckedOrders([...checkedOrders, order])
        }

        handleParentCheck(0, true);
    }

    const expandHandler = (order) => {
        const orders = groupedOrders
        orders.forEach((element) => {
            element.open = false
        })
        const index = orders.findIndex((item) => item._id == order._id)
        orders[index].open = true
        setGroupedOrders(orders)
        setExpand(!expand)
    }


    const qtyChangeHandler = (id, ev) => {

        const existing = schedulableOrders



        const returned = existing.map((item) => {
            if (item._id == id) {


                let des = 0
                const inputValue = ev.target.value
                if (inputValue > item.orderQtyCtn) {
                    des = item.orderQtyCtn
                } else if (inputValue < 0) {
                    des = 0
                } else {
                    des = inputValue
                }

                return {
                    ...item,
                    deliveryQty: +des,
                }
            }
            return item
        })

        setSchedulableOrders(returned)
    }

    const qtyChangeHandlerForPieces = (id, ev) => {
        const existing = schedulableOrders
        const returned = existing.map((item) => {
            if (item._id == id) {
                setSelectedFactoryName(
                    item.factoryName.length == 1 ? item.factoryName[0] : ''
                )

                //if factory already not in the changed items
                // const sameFactoryExists = schedulableOrders.filter(mine => mine.deliveryQty).find(gj => gj.factoryName[0] == item?.factoryName[0]);

                // if (!isSuper && schedulableOrders.filter(mine => mine.deliveryQty).length && !sameFactoryExists && item.factoryName.length == 1) {
                //     Swal.fire({
                //         title: 'Cant Schedule multiple factory orders at a time',
                //         text: 'Cant Schedule multiple factory orders at a time',
                //         icon: 'warning',
                //     })
                //     return item;
                // }

                const schQtyPieces = getScheduledQuantityBySKUInPieces(item.skuName, item.doNo) || 0

                let des = 0
                const inputValue = ev.target.value
                if (inputValue > item.totalOrderQtyPCS - schQtyPieces) {
                    des = item.totalOrderQtyPCS - schQtyPieces
                } else if (inputValue < 0) {
                    des = 0
                } else {
                    des = inputValue
                }

                return {
                    ...item,
                    deliveryQty: +des,
                }
            }
            return item
        })

        setSchedulableOrders(returned)
    }

    const deliveryDateChangeHandler = (id, date) => {
        const existing = schedulableOrders
        const returned = existing.map((item) => {
            if (item._id == id) {
                return {
                    ...item,
                    deliveryDate: date,
                }
            }
            return item
        })

        setSchedulableOrders(returned)
    }

    const deliveryEndDateChangeHandler = (id, date) => {
        const existing = schedulableOrders
        const returned = existing.map((item) => {
            if (item._id == id) {
                return {
                    ...item,
                    deliveryDateEnd: date,
                }
            }
            return item
        })

        setSchedulableOrders(returned)
    }



    // Function to check/uncheck all children when parent is checked/unchecked
    const handleParentCheck = (parentIndex, isChecked) => {
        // Set the parent as checked/unchecked
        groupedOrders[parentIndex].isChecked = isChecked;

        // Loop through all children (scOrders) and check/uncheck them based on the parent
        groupedOrders[parentIndex].scOrders.forEach(child => {
            child.isChecked = isChecked;
            child.deliveryQty = isChecked ? (user.data.userRole == "SPECIAL DISTRIBUTOR" ? child.totalOrderQtyPCS : child.orderQtyCtn) : 0;
            child.alreadyScheduledQty = 0;
            child.offerScheduleAlreadyDone = false
        });


        const schedulesResponse = axios.all(groupedOrders[parentIndex].scOrders.map((item) => !item.offerNo && getScheduleDataBySkuDo(item.doNo, item.skuName)))
            .then(axios.spread((...responses) => {
                responses.forEach((response) => {
                    const { data } = response;
                    if (data?.payload?.length > 0) {
                        groupedOrders[parentIndex].scOrders.forEach((item) => {
                            if (item.doNo === data?.payload[0]?.doNo && item.skuName === data?.payload[0]?.skuName) {
                                const alreadydelQtyCtn = data?.payload[0]?.delQtyCtn || 0;
                                const alreadydelQtyPcs = data?.payload[0]?.delQtyPcs || 0;
                                const offerScheduleAlreadyDone = data?.payload[0]?.offerQtyPCS || false;
                                item.deliveryQty = isChecked ? ((user.data.userRole == "SPECIAL DISTRIBUTOR" ? item.totalOrderQtyPCS : item.orderQtyCtn) - (user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn)) : 0;
                                item.alreadyScheduledQty = user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn;
                                item.offerScheduleAlreadyDone = offerScheduleAlreadyDone;
                            }

                        })
                    }
                });


                setGroupedOrders([...groupedOrders]);
                setDealerId(groupedOrders[parentIndex]?.dealerId);
                setSelectedDbType(groupedOrders[parentIndex]?.scOrders[0]?.dbType)


            }))
            .catch(errors => console.log(errors)).finally(() => {
                console.log("done");

            })
    }

    // Function to handle individual child check/uncheck
    const handleChildCheck = async (parentIndex, childIndex, isChecked) => {
        // Set the specific child as checked/unchecked
        groupedOrders[parentIndex].scOrders[childIndex].isChecked = isChecked;

        let res = null;
        if (!groupedOrders[parentIndex].scOrders[childIndex].offerNo) {
            res = await getScheduleDataBySkuDo(groupedOrders[parentIndex].scOrders[childIndex].doNo, groupedOrders[parentIndex].scOrders[childIndex].skuName);
        }
        const alreadydelQtyCtn = res?.data?.payload[0]?.delQtyCtn || 0;
        const alreadydelQtyPcs = res?.data?.payload[0]?.delQtyPcs || 0;
        const offerScheduleAlreadyDone = res?.data?.payload[0]?.offerQtyPCS || false;
        groupedOrders[parentIndex].scOrders[childIndex].deliveryQty = isChecked ? (user.data.userRole == "SPECIAL DISTRIBUTOR" ? groupedOrders[parentIndex].scOrders[childIndex].totalOrderQtyPCS : groupedOrders[parentIndex].scOrders[childIndex].orderQtyCtn) - (user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn) : 0;
        groupedOrders[parentIndex].scOrders[childIndex].alreadyScheduledQty = user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn;
        groupedOrders[parentIndex].scOrders[childIndex].offerScheduleAlreadyDone = offerScheduleAlreadyDone;


        // Check if all children are checked
        const allChecked = groupedOrders[parentIndex].scOrders.every(child => child.isChecked);

        // Set the parent check based on the status of children
        groupedOrders[parentIndex].isChecked = allChecked;

        setGroupedOrders([...groupedOrders]);

        setDealerId(groupedOrders[parentIndex]?.scOrders[childIndex]?.dealerId);
        setSelectedDbType(groupedOrders[parentIndex]?.scOrders[childIndex]?.dbType);

        if (isChecked) {
            setSelectedSKUName(groupedOrders[parentIndex].scOrders[childIndex].skuName)
            setSelectedFactoryName(myFactory.value)
        }

    }


    const quanityChangeHandler = async (parentIndex, index, ev) => {



        const eventValue = +ev.target.value;


        let res = null;
        if (!groupedOrders[parentIndex].scOrders[index].offerNo) {
            res = await getScheduleDataBySkuDo(groupedOrders[parentIndex].scOrders[index].doNo, groupedOrders[parentIndex].scOrders[index].skuName);
        }
        const alreadydelQtyCtn = res?.data?.payload[0]?.delQtyCtn || 0;
        const alreadydelQtyPcs = res?.data?.payload[0]?.delQtyPcs || 0;
        const scheduledQty = user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn;

        // groupedOrders[parentIndex].scOrders[index].deliveryQty = +ev.target.value;

        if (eventValue > (user.data.userRole == "SPECIAL DISTRIBUTOR" ? groupedOrders[parentIndex].scOrders[index].totalOrderQtyPCS : groupedOrders[parentIndex].scOrders[index].orderQtyCtn)) {
            groupedOrders[parentIndex].scOrders[index].deliveryQty = user.data.userRole == "SPECIAL DISTRIBUTOR" ? groupedOrders[parentIndex].scOrders[index].totalOrderQtyPCS : groupedOrders[parentIndex].scOrders[index].orderQtyCtn;
        } else if (scheduledQty && (eventValue >= scheduledQty - 1)) {
            groupedOrders[parentIndex].scOrders[index].deliveryQty = (user.data.userRole == "SPECIAL DISTRIBUTOR" ? groupedOrders[parentIndex].scOrders[index].totalOrderQtyPCS : groupedOrders[parentIndex].scOrders[index].orderQtyCtn) - scheduledQty;

        } else if (eventValue < 0) {
            groupedOrders[parentIndex].scOrders[index].deliveryQty = 0
        } else {
            groupedOrders[parentIndex].scOrders[index].deliveryQty = eventValue;
        }

        groupedOrders[parentIndex].scOrders[index].alreadyScheduledQty = scheduledQty;
        setGroupedOrders([...groupedOrders]);
    }



    return (
        <>
            <Row className="g-3 mb-3">
                <Col lg={9} xl={9}>
                    <FalconComponentCard noPreview>
                        <FalconComponentCard.Header
                            title="Scheduling"
                            light={false}
                            noPreview
                        />
                        <FalconComponentCard.Body language="jsx">
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleScheduleSubmit}
                            >
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                        <Form.Label>Select Factory</Form.Label>
                                        <Select

                                            closeMenuOnSelect={true}
                                            options={factoryList}
                                            placeholder="Select Factory"
                                            classNamePrefix="react-select"
                                            name="factoryName"
                                            value={myFactory}
                                            onChange={(selectedOption) => {
                                                // setFieldValue(
                                                //     "factoryName",
                                                //     selectedOption
                                                // );

                                                // console.log("selectedOption", selectedOption);


                                                // setSelectedFactoryName(selectedOption.value)
                                                setMyFactory(selectedOption)
                                            }}
                                        // onBlur={handleBlur}

                                        />

                                        {/* {
                                            errors.factoryName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.factoryName}
                                                </div>
                                            )} */}
                                    </Form.Group>

                                    <Form.Group
                                        as={Col}
                                        md="8"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Distributor IDs</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={distributorOptions}
                                            placeholder="Select Distributor IDs"
                                            isMulti
                                            classNamePrefix="react-select"
                                            value={distributorValue}
                                            onChange={(value) =>
                                                setDistributorValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group
                                        as={Col}
                                        md="4"
                                        controlId="exampleZip"
                                    >
                                        <div className="datePickerContainer">
                                            <Form.Label >Start Date</Form.Label>
                                            <DatePicker
                                                selected={deliveryDate}
                                                onChange={(date) => setDeliveryDate(date)}
                                                formatWeekDay={(day) => day.slice(0, 3)}
                                                className="form-control"
                                                placeholderText="Delivery Start Date"
                                                // disabled={deliveryQty <= 0}
                                                popperPlacement="top-start"
                                                // style={{ width: '100%' }}
                                                required

                                            />
                                        </div>

                                    </Form.Group>

                                    <Form.Group
                                        as={Col}
                                        md="4"
                                        controlId="exampleZip"
                                    >
                                        <div className="datePickerContainer">
                                            <Form.Label>End Date</Form.Label>
                                            <DatePicker
                                                selected={deliveryDateEnd}
                                                onChange={(date) => setDeliveryDateEnd(date)}
                                                formatWeekDay={(day) => day.slice(0, 3)}
                                                className="form-control"
                                                placeholderText="Delivery End Date"
                                                // disabled={deliveryQty <= 0}
                                                popperPlacement="top-start"
                                                // style={{ width: '100%' }}
                                                required


                                            />
                                        </div>
                                    </Form.Group>

                                </Row>

                                <Button type="submit">Schedule Submit</Button>
                            </Form>
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
                <Col lg={3} xl={3}>
                    <div style={{ height: "300px", overflowY: "scroll" }}>

                        <SharedFiles
                            title={'Available Stock'}
                            // className="h-lg-60"
                            // files={
                            //     selectedSKUname && selectedFactoryname
                            //         ? [
                            //             {
                            //                 skuName: selectedSKUname,
                            //                 rcvQtyCTN:
                            //                     allStocksData.reduce(
                            //                         (
                            //                             accumulator,
                            //                             currentValue
                            //                         ) => {
                            //                             return (
                            //                                 accumulator +
                            //                                 currentValue.rcvQtyCTN
                            //                             )
                            //                         },
                            //                         0
                            //                     )
                            //                 -
                            //                 getScheduledQuantityBySKUOnly(
                            //                     selectedSKUname,
                            //                     selectedFactoryname
                            //                 )
                            //                 ,

                            //                 rcvQtyPCS: allStocksData.reduce(
                            //                     (
                            //                         accumulator,
                            //                         currentValue
                            //                     ) => {
                            //                         return (
                            //                             accumulator +
                            //                             currentValue.rcvQtyPCS
                            //                         )
                            //                     },
                            //                     0
                            //                 )

                            //                     -
                            //                     getScheduledQuantityBySKUOnlyInPieces(
                            //                         selectedSKUname,
                            //                         selectedFactoryname
                            //                     )

                            //                 ,

                            //                 selectedDbType: selectedDbType
                            //             },
                            //         ]
                            //         : []
                            // }

                            files={stocksList}
                        />
                    </div>

                    <div className='my-3'>
                        <SpaceWarning dealerId={dealerId} dealerName={dealerName} />
                    </div>

                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col lg={9} xl={9}>
                    {groupedOrders.length == 0 ? (
                        null
                    ) : (

                        <div className="bg-white" style={{ maxWidth: '100%' }}>
                            <Card className="p-0" style={{ maxWidth: '100%' }}>
                                <Card.Body>
                                    <div className="fs--1">
                                        <SimpleBarReact>

                                            {groupedOrders?.length > 0 ? (
                                                <Table borderless className="mb-10 fs--1">
                                                    <thead className="bg-light">
                                                        <tr
                                                            style={{
                                                                fontSize: 'medium',
                                                                fontWeight: '500',
                                                                fontFamily: 'Poppins',
                                                                color: '#354151',
                                                            }}
                                                        >
                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                ...
                                                            </th>
                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                <input
                                                                    type="checkbox"
                                                                    // checked={
                                                                    //     checkedOrders?.length ===
                                                                    //     groupedOrders?.length
                                                                    // }

                                                                    onChange={(e) =>
                                                                        handleCheckAll(e)
                                                                    }

                                                                    checked={groupedOrders?.every(order => order.isChecked)}
                                                                />
                                                            </th>

                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                SL
                                                            </th>


                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Distributor
                                                            </th>


                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Order Qty Ctn
                                                            </th>
                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Order Qty PCS
                                                            </th>
                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Discount BDT
                                                            </th>


                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Offer Qty PCS
                                                            </th>

                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Total Price
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody
                                                        className="list"
                                                        id="table-purchase-body"
                                                    >
                                                        {groupedOrders?.map(
                                                            (order, index) => {
                                                                return (
                                                                    <>
                                                                        <tr
                                                                            key={index}
                                                                            style={{
                                                                                fontSize:
                                                                                    'medium',
                                                                                fontWeight:
                                                                                    '500',
                                                                                fontFamily:
                                                                                    'Poppins',
                                                                                color: '#354151',
                                                                            }}
                                                                        >
                                                                            <td className="align-middle text-center border-0">
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        expandHandler(
                                                                                            order
                                                                                        )
                                                                                    }
                                                                                    aria-controls="example-collapse-text"
                                                                                    aria-expanded={
                                                                                        order.open
                                                                                    }
                                                                                    variant="falcon-primary"
                                                                                    className="custom-button" // Apply the custom class
                                                                                >
                                                                                    {order.open &&
                                                                                        expand
                                                                                        ? '-'
                                                                                        : '+'}
                                                                                </Button>
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    // checked={isChecked(
                                                                                    //     order
                                                                                    // )}
                                                                                    // onChange={() =>
                                                                                    //     handleCheckboxChange(
                                                                                    //         order
                                                                                    //     )
                                                                                    // }
                                                                                    checked={order.isChecked}
                                                                                    onChange={() => handleParentCheck(index, !order.isChecked)}
                                                                                />
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {index + 1}
                                                                            </td>

                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    order?.distributorName
                                                                                }
                                                                            </td>


                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber(order?.orderQtyCtn)
                                                                                }
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber(order?.totalOrderQtyPCS)
                                                                                }
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber(order?.discountBDT)
                                                                                }
                                                                            </td>

                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber(order?.offerPc)
                                                                                }
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber(order?.totalPrice)
                                                                                }
                                                                            </td>



                                                                        </tr>
                                                                        <tr
                                                                            style={{
                                                                                display:
                                                                                    order.open &&
                                                                                        expand
                                                                                        ? 'table-row'
                                                                                        : 'none',
                                                                            }}
                                                                        >
                                                                            <td colSpan={15}>
                                                                                <div className="p-0">
                                                                                    <CollapseSchedule

                                                                                        ordersInfo={order.scOrders}
                                                                                        setSelectedSKUName={setSelectedSKUName}
                                                                                        setSelectedDoNo={setSelectedDoNo}
                                                                                        setDealerId={setDealerId}
                                                                                        setDealerName={setDealerName}
                                                                                        setSelectedDbType={setSelectedDbType}
                                                                                        qtyChangeHandler={qtyChangeHandler}
                                                                                        qtyChangeHandlerForPieces={qtyChangeHandlerForPieces}
                                                                                        deliveryDateChangeHandler={deliveryDateChangeHandler}
                                                                                        deliveryEndDateChangeHandler={deliveryEndDateChangeHandler}
                                                                                        // comboOffers={order.scOrders.filter(item => !item.superDBId && item.offerNo && item.offerName)}
                                                                                        comboOffers={[]}
                                                                                        updatableCombos={updatableCombos}
                                                                                        setUpdatableCombos={setUpdatableCombos}
                                                                                        handleChildCheck={handleChildCheck}
                                                                                        parentIndex={index}
                                                                                        quanityChangeHandler={quanityChangeHandler}
                                                                                        handleParentCheck={handleParentCheck}

                                                                                    ></CollapseSchedule>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }
                                                        )}
                                                    </tbody>
                                                </Table>
                                            ) : (
                                                <div className="text-center p-4">
                                                    <h5>No Orders Found</h5>
                                                </div>
                                            )}

                                        </SimpleBarReact>

                                    </div>


                                </Card.Body>
                            </Card>
                        </div>
                    )}
                </Col>

                <Col lg={3} xl={3}>
                    {/* {isLoadingSchedule && allOrdersData.length == 0 ? (
                        <h1>Loading...</h1>
                    ) : (
                        <SharedFiles2
                            title={'Delivery Info'}
                            files={
                                // selectedSKUname &&
                                //     selectedFactoryname &&
                                //     selectedDoNo
                                //     ? allScheduleData.filter(
                                //         (item) =>
                                //             item.skuName == selectedSKUname &&
                                //             item.factoryName ==
                                //             selectedFactoryname &&
                                //             item.doNo == selectedDoNo
                                //     )
                                //     : []

                               Object.values(groupedOrders).map((order) => {
                                    return order.scOrders.filter((tr) => tr.alreadyScheduledQty > 0 && tr.isChecked)
                                }).flat()
                            }
                            className="h-lg-100"
                        />
                    )} */}

                    <SharedFiles2
                        title={'Delivery Info'}
                        files={
                            // selectedSKUname &&
                            //     selectedFactoryname &&
                            //     selectedDoNo
                            //     ? allScheduleData.filter(
                            //         (item) =>
                            //             item.skuName == selectedSKUname &&
                            //             item.factoryName ==
                            //             selectedFactoryname &&
                            //             item.doNo == selectedDoNo
                            //     )
                            //     : []

                            Object.values(groupedOrders).map((order) => {
                                return order.scOrders.filter((tr) => tr.alreadyScheduledQty > 0 && tr.isChecked)
                            }).flat()
                        }
                        className="h-lg-100"
                    />
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Scheduling)
