import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { IoIosCheckboxOutline } from "react-icons/io";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons'; // Example icon for pending status
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { formatNumber } from "helpers/utils";


const ConfirmDeposit = () => {
    const [depositList, setDepositList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState(0);
    const [checkedOrders, setCheckedOrders] = useState([]);
    const userInfo = useSelector(state => state?.auth?.user?.data);



    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/deposits?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.result;
                    const totalPages = response?.data?.payload?.pagination?.totalPages;
                    
                    const getPendingDeposit = result?.filter((deposit) => !deposit.doNo);

                    setTotalPages(totalPages)
                    setDepositList(getPendingDeposit);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount, searchText]);

    const handleCheckAll = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setCheckedOrders(depositList);
        } else {
            setCheckedOrders([]);
        }
    }

    // Handle checkbox change
    const handleCheckboxChange = (order) => {
        if (isChecked(order)) {
            setCheckedOrders(checkedOrders.filter(p => p._id !== order._id));
        } else {
            setCheckedOrders([...checkedOrders, order]);
        }
    };

    // Check if a product is checked
    const isChecked = (order) => {
        return checkedOrders.some(p => p._id === order._id);
    };

    // VIEW IMAGE
    const viewImage = async (imageId) => {

        try {
            const response = await axios.get(`https://khoz.net:3015/api/v1/image/${imageId}`, { headers: authHeader() });
            if (response.data.success && response.data.payload) {
                const data = response.data.payload;
                if (!data.imageData) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No image found!',
                    });
                    return;
                }

                const base64Image = response.data.payload.imageData.image;

                // Check if the base64 string contains the data URL prefix and remove it if present
                const base64Prefix = 'data:image/jpeg;base64,';
                let base64String;
                if (base64Image.startsWith(base64Prefix)) {
                    base64String = base64Image.substring(base64Prefix.length);
                } else {
                    base64String = base64Image;
                }

                // Convert base64 to Blob
                try {
                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'image/jpeg' });

                    // Create a URL for the Blob and open it in a new tab
                    const imageUrl = URL.createObjectURL(blob);
                    window.open(imageUrl, '_blank');
                } catch (e) {
                    console.error('Error decoding base64 string:', e);
                }
            } else {
                console.error('Failed to fetch image:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching the image:', error);
        }
    };

    if (isLoading) {
        return <LoadingIcon />;
    }


    // Handle form submission
    const handleSubmit = async () => {
        const orderItems = checkedOrders.map((order) => {

            return {
                _id: order._id,
                confirmId: userInfo?.userId,
                status: 1,

            };

        });


        // Make a PUT request to update the order items
        try {
            setIsLoading(true);
            const response = await axios.put(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/deposit/multiupdate`, orderItems, { headers: authHeader() });
            if (response.data.status || response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Orders have been successfully updated.",
                });
                setCheckedOrders([]);
                setCount(count + 1);
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (date) => {
        return date ? new Date(date).toLocaleDateString('en-US') : ''
    }


    return (
        <>
            <PageHeader
                title={`Pending Deposits`}
                className="mb-3"
            ></PageHeader>

            <Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader isNew={false} isExport={false} isSearch={true} setSearchText={setSearchText} />
                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            {
                                depositList?.length > 0 ? <table className="table table-bordered table-striped fs--1 mb-0">
                                    <thead >
                                        <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                            <th className="align-middle white-space-nowrap text-center " ><input
                                                type="checkbox"
                                                checked={checkedOrders?.length === depositList?.length}
                                                onChange={(e) => handleCheckAll(e)}
                                            /></th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Payment Method</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Payment Method Name</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Bank Name</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Bank Account No</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Zone</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Region</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Area</th>

                                            <th className="align-middle white-space-nowrap text-center ">
                                                DB Point</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Dealer ID</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Dealer Name</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Amount </th>


                                            <th className="align-middle white-space-nowrap text-center ">
                                                Depositors Bank Name</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Depositors Branch Name</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Depositor Phone</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Status</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Date</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Note</th>


                                        </tr>
                                    </thead>
                                    <tbody className="list" id="table-purchase-body">
                                        {
                                            depositList?.map((item, index) => {
                                                return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                    <td className="align-middle text-center"><input
                                                        type="checkbox"
                                                        checked={isChecked(item)}
                                                        onChange={() => handleCheckboxChange(item)}
                                                    /></td>
                                                    <td className="align-middle text-center">{item?.paymentMethod}</td>
                                                    <td className="align-middle text-center">{item?.mobileMethodName || "bank"}</td>
                                                    <td className="align-middle text-center">{item?.bankNames || "--"}</td>
                                                    <td className="align-middle text-center">{item?.bankAccountNo || "--"}</td>
                                                    <td className="align-middle text-center">{item?.zone}</td>
                                                    <td className="align-middle text-center">{item?.region}</td>
                                                    <td className="align-middle text-center">{item?.area}</td>
                                                    <td className="align-middle text-center">{item?.dbPoint}</td>

                                                    <td className="align-middle text-center">{item?.dealerId}</td>
                                                    <td className="align-middle text-center">{item?.dealerName}</td>
                                                    <td className="align-middle text-center">{formatNumber(item?.drBDT)}</td>


                                                    <td className="align-middle text-center">{item?.depositorsBankName || "--"}</td>
                                                    <td className="align-middle text-center">{item?.depositorsBranchName || "--"}</td>
                                                    <td className="align-middle text-center">{item?.depositorPhone}</td>
                                                    <td className="align-middle text-center">{item?.status === 0 ? "Pending..." : item?.status === 1 ? "Forwarded by accounts" : item?.status === 2 ? "Forwarded by Finance" : ""}</td>
                                                    {/* <td className="align-middle text-center">Approved</td> */}
                                                    <td className="align-middle text-center">{formatDate(item?.paymentDate)}</td>
                                                    <td className="align-middle text-center">{item?.paymentNote}</td>
                                                    <Dropdown className="d-inline mx-2">
                                                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                                                            ...
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {item?.trId && <Dropdown.Item onClick={() => viewImage(item?.trId)}>
                                                                <IconButton
                                                                    variant="falcon-default"
                                                                    size="sm"
                                                                    icon="show"
                                                                    transform="shrink-2"
                                                                    iconAlign="middle"
                                                                    className="me-2"
                                                                >
                                                                    <span className="d-none d-xl-inline-block ms-1">View Attachment</span>
                                                                </IconButton>

                                                            </Dropdown.Item>}
                                                            {item?.status === 0 && <>
                                                                <Dropdown.Item href="">

                                                                    <Link to={`/master/deposit/update/${item?._id}`}>
                                                                        <IconButton
                                                                            variant="falcon-default"
                                                                            size="sm"
                                                                            icon="edit"
                                                                            transform="shrink-2"
                                                                            iconAlign="middle"
                                                                            className="me-2"

                                                                        >
                                                                            <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                                                        </IconButton>
                                                                    </Link>
                                                                </Dropdown.Item>

                                                            </>}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </tr>

                                            })
                                        }
                                    </tbody>
                                </table> : <div className="text-center my-4">No Pending Deposit</div>
                            }

                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                </Card.Footer>
            </Card >


            <Button className="mt-2 px-5" variant="primary" type="submit" disabled={checkedOrders?.length === 0} onClick={() => { handleSubmit(); }}>
                Confirm
            </Button>
        </>
    );

};

export default ConfirmDeposit;
