import { actions } from "react-table"

const localStorageData = JSON.parse(localStorage.getItem('user'))
const userRole = localStorageData?.data?.userRole

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      // children: [
      //   {
      //     name: 'Default',
      //     to: '/',
      //     exact: true,
      //     active: true
      //   }
      // ]
    },
  ],
}

export const modulesRoutes = {
  label: 'Modules',
  children: [
    // ADMIN ROUTES START
    {
      name: 'User Management',
      active: true,
      icon: 'user',
      role: 'Admin',
      children: [
        {
          name: 'System Users',
          to: 'user/systemUser',
          active: true,
        },
        {
          name: 'Distributor',
          to: 'user/distributor',
          active: true,
        },
        {
          name: 'Sr',
          to: 'user/sr',
          active: true
        }
      ],
    },
    {
      name: 'Master Register',
      icon: 'table',
      active: true,
      role: 'Admin',
      children: [
        {
          name: 'Sales Organogram',
          active: true,
          role: 'Admin',
          children: [
            {
              name: 'Zone',
              to: '/master/zone',
              active: true,
            },
            {
              name: 'Region',
              to: '/master/region',
              active: true,
            },
            {
              name: 'Area',
              to: 'master/area',
              active: true,
            },
            {
              name: 'DB Point',
              to: 'master/dbPoint',
              active: true,
            },

            {
              name: 'Sales Organization',
              to: '/master/salesorg',
              active: true,
            },
            {
              name: 'Bank',
              to: '/master/bank',
              active: true,
            },
            {
              name: 'Designation',
              to: '/master/designation',
              active: true,
            },
            {
              name: 'User Type',
              to: '/master/userType',
              active: true,
            },
            {
              name: 'Vendor',
              to: '/master/vendor',
              active: true,
            },
            /* {
  name: 'Route',
  active: true,
  children: [

    {
      name: 'Sales Organogram',
      active: true,
      children: [
        {
          name: 'Zone',
          to: '/master/zone',
          active: true,
        },
        {
          name: 'Region',
          to: '/master/region',
          active: true,
        },
        {
          name: 'Area',
          to: 'master/area',
          active: true,
        },
        {
          name: 'DB Point',
          to: 'master/dbPoint',
          active: true,
        },

        {
          name: 'Sales Organization',
          to: '/master/salesorg',
          active: true,
        },
        {
          name: 'Bank',
          to: '/master/bank',
          active: true,
        },
        {
          name: 'Designation',
          to: '/master/designation',
          active: true,
        },
        {
          name: 'User Type',
          to: '/master/userType',
          active: true,
        },
        {
          name: 'Route',
          active: true,
          children: [
            {
              name: 'Route',
              to: '/master/route',
              active: true,
            },
            {
              name: 'Assigned Sales Officer',
              to: '/assignedSalesOfficer',
              active: true,
            },
          ],
        },
        {
          name: 'Outlet',
          active: true,
          children: [
            {
              name: 'Outlet Type',
              to: '/outletTypes',
              active: true,
            },
            {
              name: 'Outlet Channel',
              to: '/outletChannel',
              active: true,
            },
            {
              name: 'Outlet Target',
              to: '/master/outletTarget',
              active: true,
            },
            {
              name: 'Outlet',
              to: '/master/outlet',
              active: true,
            },
          ],
        },
        {
          name: 'Distributor',
          active: true,
          children: [
            {
              name: 'Distributor Type',
              to: '/master/distributorType',
              active: true,
            },
            {
              name: 'Distributor',
              to: '/master/distributor',
              active: true,
            },
            {
              name: 'DO Create',
              to: '/master/distributor-orders-create',
              active: true,
            },
            {
              name: 'DO List',
              to: '/master/distributor-orders',
              active: true,
            },

            {
              name: 'Deposit',
              to: '/master/deposit',
              active: true,
            },
          ],
        },
        {
          name: 'Employee',
          to: '/master/employee',
          active: true,
        },
      ],
    },
    {
      name: 'Product Setup',
      active: true,
      children: [
        {
          name: 'Brand',
          to: '/master/brand',
          active: true,
        },
        {
          name: 'Category',
          to: '/master/productCategory',
          active: true,
        },
        {
          name: 'Product',
          to: '/master/product',
          active: true,
        },
      ],
    },
    {
      name: 'Offer',
      active: true,
      children: [
        {
          name: 'Offer Type',
          to: '/master/offerType',
          active: true,
        },
        {
          name: 'Create Offer',
          to: '/master/offer',
          active: true,
        },
      ],
    },
    {
      name: 'Discount',
      active: true,
      children: [
        {
          name: 'Discount Type',
          to: '/master/discountType',
          active: true,
        },
        {
          name: 'Create Discount',
          to: '/master/discount',
          active: true,
        },
      ],
    },
    {
      name: 'Outlet',
      to: '/master/outlet',
      active: true
    },
  ]
}, 
{
  name: 'Employee',
  to: '/master/employee',
  active: true
},
*/
          ],
        },
        {
          name: 'Product Setup',
          active: true,
          role: 'Admin',
          children: [
            {
              name: 'Brand',
              to: '/master/brand',
              active: true,
            },
            {
              name: 'Category',
              to: '/master/productCategory',
              active: true,
            },
            {
              name: 'Sub Category',
              to: '/master/sub-category',
              active: true,
            },
            {
              name: 'Product',
              to: '/master/product',
              active: true,
            },
            {
              name: 'Offer Products',
              to: '/master/offer-product',
              active: true,
            },
          ],
        },
        {
          name: 'Offer',
          active: true,
          role: 'Admin',
          children: [
            {
              name: 'Offer Type',
              to: '/master/offerType',
              active: true,
            },
            {
              name: 'Create Offer',
              to: '/master/offer',
              active: true,
            },
          ],
        },
        {
          name: 'Discount',
          active: true,
          role: 'Admin',
          children: [
            {
              name: 'Discount Type',
              to: '/master/discountType',
              active: true,
            },
            {
              name: 'Create Discount',
              to: '/master/discount',
              active: true,
            },
          ],
        },
        {
          name: 'Route',
          active: true,
          role: 'Admin',
          children: [
            {
              name: 'Create Route to SR',
              to: '/master/create-route',
              active: true,
            },
            {
              name: 'Route Assign ',
              to: '/master/assign-route',
              active: true,
            },

          ],
        },


        /* {
  name: 'Manufacturing Unit',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'Inventory',
      to: '/master/inventory',
      active: true
    },



  ]

}, */
        /* {
  name: 'Depot Management',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'Depot Management',
      to: '/manipulate',
      active: true
    },

  ]

},
{
  name: 'Office Notice',
  to: '/master/officenotice',
  active: true,
  role: "Admin",
},
{
  name: 'SR',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'SR',
      to: 'master/sr',
      active: true
    },
  ]
}, */
      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'Admin',
      children: [
        /* {
  name: 'Distributor Type',
  to: '/master/distributorType',
  active: true
}, */
        /* {
  name: 'DO Create',
  to: '/master/distributor-orders-create',
  active: true
}, */
        {
          name: 'DO Search',
          to: '/finance/orders-filter',
          active: true,
        },
        {
          name: 'Delivery Search',
          to: '/inventory/delivery/search',
          active: true,
        },
        {
          name: 'DB Reconciliation',
          to: '/db-reconciliation',
          active: true,
        },


        /* {
  name: 'Deposit',
  to: '/master/deposit',
  active: true
} */
      ],
    },
    {
      name: 'Finance & Account',
      icon: 'flag',
      role: 'Admin',
      active: true,
      type: 'success',
      text: 'New',
      children: [
        {
          name: 'Due Payments',
          to: '/accounts/due-payment-list',
          role: "Admin",
          active: true,
        },
        /*  {
   name: 'Inventory',
   icon: 'calendar-day',
   active: true,
   role: "Admin",
   children: [
     {
       name: 'Received Pannel',
       to: '/receivedPanel',
       active: true,
     }, 
     {
       name: 'Products Delivery',
       to: '/distribution/productdelivery',
       active: true,
     },
   ],
 }, */
        /*  {
   name: 'Upazila',
   to: '/upazila',
   active: true
 },
 
 {
   name: 'Distribution Assigned Area',
   to: '/distributionAssignedArea',
   active: true
 }, */
      ],
    },
    {
      name: 'Distribution',
      icon: 'book',
      active: true,
      role: 'Admin',
      children: [
        {
          name: 'Offers Scheduling',
          to: '/distribution/offers',
          active: true,
        },


        /* {
  name: 'Supplier Type',
  to: '/supplierType',
  active: true
}, */
        /* {
  name: 'HR Management',
  icon: 'graduation-cap',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'Time Pass',
      to: '/target/timePass/form',
      active: true,
    },
  ],
}, */
        {
          name: 'Payroll',
          icon: 'fire',
          active: true,
          children: [
            /* {
  name: 'Approval',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'Approval Committee',
      to: '/master/approvalCommittee',
      active: true,
    },
    {
      name: 'Raise Approval',
      to: '/primaryOrderProcess',
      active: true,
    },
    {
      name: 'Delivery Approval',
      to: '/deliveryApproval',
      active: true,
    },
  ],
}, */
          ],
        },
        {
          name: 'Settings',
          icon: ['fab', 'trello'],
          active: true,
          children: [
            /* {
  name: 'Offer Type',
  to: 'offerType',
  active: true,
}, */
          ],
        },
        {
          name: 'Reports',
          icon: 'shapes',
          active: true,
          children: [
            /* {
  name: 'Product Packaging Unit',
  to: 'productPackagingUnit',
  active: true,
},
{
  name: 'Product Settings',
  to: 'productSettings',
  active: true,
},
{
  name: 'Distributor Product Settings',
  to: 'distributorProductSettings',
  active: true,
},
{
  name: 'Distributor Storage Settings',
  to: 'distributorStorageSettings',
  active: true,
},
{
  name: 'Commission Audience',
  to: 'commissionAudience',
  active: true,
}, */
          ],
        },
      ],
    },
    {
      name: 'Inventory',
      icon: 'calendar-day',
      role: 'Admin',
      active: true,
      children: [
        /*  {
   name: 'Received Pannel',
   to: '/receivedPanel',
   active: true
 }, */

        {
          name: 'Stock List',
          to: '/inventory/stockList-admin',
          active: true,
        },
        {
          name: 'Stock List Search',
          to: '/inventory/stockListFilter-admin',
          active: true,
        },

        {
          name: 'Offer Receive List Admin Pending',
          to: '/inventory/offer-receiveList-admin',
          active: true,
        },

        {
          name: 'Offer Receive List Admin Got',
          to: '/inventory/offer-receiveList-admin-got',
          active: true,
        },
        {
          name: 'Production Search List',
          to: '/production/production-list-admin',
          active: true,
        },
        {
          name: 'Purchase List',
          to: '/inventory/purchaseList-admin',
          active: true,
        },
      ],
    },
    {
      name: 'Sales Management',
      icon: 'chart-pie',
      active: true,
      role: 'Admin',
      children: [
        /* {
  name: 'Holiday Information',
  to: '/holiday_info',
  active: true
},
{
  name: 'Target Audience Type',
  to: '/targetAudienceType',
  active: true
},
*/
        {
          name: 'Target Upload',
          to: '/target-upload',
          active: true,
        },
        {
          name: 'Target Delete',
          to: '/admin/target-delete',
          active: true,
        },
        {
          name: 'DB Special Credit Upload',
          to: '/special-credit-db-upload',
          active: true,
        },

      ],
    },
    {
      name: 'HR Management',
      icon: 'graduation-cap',
      role: 'Admin',
      active: true,
      children: [
        /* {
  name: 'Time Pass',
  to: '/target/timePass/form',
  active: true
}, */
      ],
    },
    {
      name: 'Payroll',
      icon: 'fire',
      active: true,
      role: 'Admin',
      children: [
        /* {
  name: 'Approval',
  active: true,
  children: [
    {
      name: 'Approval Committee',
      to: '/master/approvalCommittee',
      active: true
    },
    {
      name: 'Raise Approval',
      to: '/primaryOrderProcess',
      active: true
    },
    {
      name: 'Delivery Approval',
      to: '/deliveryApproval',
      active: true
    }
  ]
}, */
      ],
    },
    {
      name: 'Settings',
      icon: ['fab', 'trello'],
      role: 'Admin',
      active: true,
      children: [
        {
          name: 'Generate Formate (.csv)',
          to: 'generate-format',
          active: true,
        },
        {
          name: 'Control Panel',
          role: 'Admin',
          active: true,
          children: [
            {
              name: 'Report/Component Control',
              to: '/report-component-control-list',
              active: true,
            },
            {
              name: 'Report/Component Control Entry Form',
              to: '/report-component-control-form',
              active: true,
            },
          ],

        }
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      role: 'Admin',
      active: true,
      children: [
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/target-vs-achievement-monthly',
          active: true,
        },
        {
          name: 'Super DB Target Vs Achievement Monthly Report',
          to: '/reports/super-db/target-vs-achievement-monthly',
          active: true,
        },
        /* {
  name: 'Product Packaging Unit',
  to: 'productPackagingUnit',
  active: true
},
{
  name: 'Product Settings',
  to: 'productSettings',
  active: true
},
{
  name: 'Distributor Product Settings',
  to: 'distributorProductSettings',
  active: true
},
{
  name: 'Distributor Storage Settings',
  to: 'distributorStorageSettings',
  active: true
},
{
  name: 'Commission Audience',
  to: 'commissionAudience',
  active: true
} */
      ],
    },

    // ADMIN ROUTES END

    // MIS ROUTES START
    {
      name: 'User Management',
      active: true,
      icon: 'user',
      role: 'MIS',
      children: [
        {
          name: 'System Users',
          to: 'user/systemUser',
          active: true,
        },
        {
          name: 'Distributor',
          to: 'user/distributor',
          active: true,
        },
      ],
    },
    {
      name: 'Master Register',
      icon: 'table',
      active: true,
      role: 'MIS',
      children: [
        {
          name: 'Sales Organogram',
          active: true,
          role: 'MIS',
          children: [
            {
              name: 'Zone',
              to: '/master/zone',
              active: true,
            },
            {
              name: 'Region',
              to: '/master/region',
              active: true,
            },
            {
              name: 'Area',
              to: 'master/area',
              active: true,
            },
            {
              name: 'DB Point',
              to: 'master/dbPoint',
              active: true,
            },

            {
              name: 'Sales Organization',
              to: '/master/salesorg',
              active: true,
            },
            {
              name: 'Bank',
              to: '/master/bank',
              active: true,
            },
            {
              name: 'Designation',
              to: '/master/designation',
              active: true,
            },
            {
              name: 'User Type',
              to: '/master/userType',
              active: true,
            },
            /* {
  name: 'Route',
  active: true,
  children: [

    {
      name: 'Sales Organogram',
      active: true,
      children: [
        {
          name: 'Zone',
          to: '/master/zone',
          active: true,
        },
        {
          name: 'Region',
          to: '/master/region',
          active: true,
        },
        {
          name: 'Area',
          to: 'master/area',
          active: true,
        },
        {
          name: 'DB Point',
          to: 'master/dbPoint',
          active: true,
        },

        {
          name: 'Sales Organization',
          to: '/master/salesorg',
          active: true,
        },
        {
          name: 'Bank',
          to: '/master/bank',
          active: true,
        },
        {
          name: 'Designation',
          to: '/master/designation',
          active: true,
        },
        {
          name: 'User Type',
          to: '/master/userType',
          active: true,
        },
        {
          name: 'Route',
          active: true,
          children: [
            {
              name: 'Route',
              to: '/master/route',
              active: true,
            },
            {
              name: 'Assigned Sales Officer',
              to: '/assignedSalesOfficer',
              active: true,
            },
          ],
        },
        {
          name: 'Outlet',
          active: true,
          children: [
            {
              name: 'Outlet Type',
              to: '/outletTypes',
              active: true,
            },
            {
              name: 'Outlet Channel',
              to: '/outletChannel',
              active: true,
            },
            {
              name: 'Outlet Target',
              to: '/master/outletTarget',
              active: true,
            },
            {
              name: 'Outlet',
              to: '/master/outlet',
              active: true,
            },
          ],
        },
        {
          name: 'Distributor',
          active: true,
          children: [
            {
              name: 'Distributor Type',
              to: '/master/distributorType',
              active: true,
            },
            {
              name: 'Distributor',
              to: '/master/distributor',
              active: true,
            },
            {
              name: 'DO Create',
              to: '/master/distributor-orders-create',
              active: true,
            },
            {
              name: 'DO List',
              to: '/master/distributor-orders',
              active: true,
            },

            {
              name: 'Deposit',
              to: '/master/deposit',
              active: true,
            },
          ],
        },
        {
          name: 'Employee',
          to: '/master/employee',
          active: true,
        },
      ],
    },
    {
      name: 'Product Setup',
      active: true,
      children: [
        {
          name: 'Brand',
          to: '/master/brand',
          active: true,
        },
        {
          name: 'Category',
          to: '/master/productCategory',
          active: true,
        },
        {
          name: 'Product',
          to: '/master/product',
          active: true,
        },
      ],
    },
    {
      name: 'Offer',
      active: true,
      children: [
        {
          name: 'Offer Type',
          to: '/master/offerType',
          active: true,
        },
        {
          name: 'Create Offer',
          to: '/master/offer',
          active: true,
        },
      ],
    },
    {
      name: 'Discount',
      active: true,
      children: [
        {
          name: 'Discount Type',
          to: '/master/discountType',
          active: true,
        },
        {
          name: 'Create Discount',
          to: '/master/discount',
          active: true,
        },
      ],
    },
    {
      name: 'Outlet',
      to: '/master/outlet',
      active: true
    },
  ]
}, 
{
  name: 'Employee',
  to: '/master/employee',
  active: true
},
*/
          ],
        },
        {
          name: 'Product Setup',
          active: true,
          role: 'MIS',
          children: [
            {
              name: 'Brand',
              to: '/master/brand',
              active: true,
            },
            {
              name: 'Category',
              to: '/master/productCategory',
              active: true,
            },
            {
              name: 'Product',
              to: '/master/product',
              active: true,
            },
            {
              name: 'Offer Products',
              to: '/master/offer-product',
              active: true,
            },
          ],
        },
        {
          name: 'Offer',
          active: true,
          role: 'MIS',
          children: [
            {
              name: 'Offer Type',
              to: '/master/offerType',
              active: true,
            },
            {
              name: 'Create Offer',
              to: '/master/offer',
              active: true,
            },
          ],
        },
        {
          name: 'Discount',
          active: true,
          role: 'MIS',
          children: [
            {
              name: 'Discount Type',
              to: '/master/discountType',
              active: true,
            },
            {
              name: 'Create Discount',
              to: '/master/discount',
              active: true,
            },
          ],
        },
        /* {
  name: 'Manufacturing Unit',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'Inventory',
      to: '/master/inventory',
      active: true
    },



  ]

}, */
        /* {
  name: 'Depot Management',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'Depot Management',
      to: '/manipulate',
      active: true
    },

  ]

},
{
  name: 'Office Notice',
  to: '/master/officenotice',
  active: true,
  role: "Admin",
},
{
  name: 'SR',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'SR',
      to: 'master/sr',
      active: true
    },
  ]
}, */
      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'MIS',
      children: [
        /* {
  name: 'Distributor Type',
  to: '/master/distributorType',
  active: true
}, */
        /* {
  name: 'DO Create',
  to: '/master/distributor-orders-create',
  active: true
}, */
        {
          name: 'DO Search',
          to: '/finance/orders-filter',
          active: true,
        },

        /* {
  name: 'Deposit',
  to: '/master/deposit',
  active: true
} */
      ],
    },
    {
      name: 'Finance & Account',
      icon: 'flag',
      role: 'MIS',
      active: true,
      type: 'success',
      text: 'New',
      children: [
        /* {
  name: 'Administrative Division',
  to: '/administrativeDivision',
  active: true
}, */
        /*  {
   name: 'Inventory',
   icon: 'calendar-day',
   active: true,
   role: "Admin",
   children: [
     {
       name: 'Received Pannel',
       to: '/receivedPanel',
       active: true,
     }, 
     {
       name: 'Products Delivery',
       to: '/distribution/productdelivery',
       active: true,
     },
   ],
 }, */
        /*  {
   name: 'Upazila',
   to: '/upazila',
   active: true
 },
 
 {
   name: 'Distribution Assigned Area',
   to: '/distributionAssignedArea',
   active: true
 }, */
      ],
    },
    {
      name: 'Distribution',
      icon: 'book',
      active: true,
      role: 'MIS',
      children: [
        {
          name: 'Offers Scheduling',
          to: '/distribution/offers',
          active: true,
        },
        /* {
  name: 'Supplier Type',
  to: '/supplierType',
  active: true
}, */
        /* {
  name: 'HR Management',
  icon: 'graduation-cap',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'Time Pass',
      to: '/target/timePass/form',
      active: true,
    },
  ],
}, */
        {
          name: 'Payroll',
          icon: 'fire',
          active: true,
          children: [
            /* {
  name: 'Approval',
  active: true,
  role: "Admin",
  children: [
    {
      name: 'Approval Committee',
      to: '/master/approvalCommittee',
      active: true,
    },
    {
      name: 'Raise Approval',
      to: '/primaryOrderProcess',
      active: true,
    },
    {
      name: 'Delivery Approval',
      to: '/deliveryApproval',
      active: true,
    },
  ],
}, */
          ],
        },
        {
          name: 'Settings',
          icon: ['fab', 'trello'],
          active: true,
          children: [
            /* {
  name: 'Offer Type',
  to: 'offerType',
  active: true,
}, */
          ],
        },
        {
          name: 'Reports',
          icon: 'shapes',
          active: true,
          children: [
            /* {
  name: 'Product Packaging Unit',
  to: 'productPackagingUnit',
  active: true,
},
{
  name: 'Product Settings',
  to: 'productSettings',
  active: true,
},
{
  name: 'Distributor Product Settings',
  to: 'distributorProductSettings',
  active: true,
},
{
  name: 'Distributor Storage Settings',
  to: 'distributorStorageSettings',
  active: true,
},
{
  name: 'Commission Audience',
  to: 'commissionAudience',
  active: true,
}, */
          ],
        },
      ],
    },
    {
      name: 'Inventory',
      icon: 'calendar-day',
      role: 'MIS',
      active: true,
      children: [
        /*  {
   name: 'Received Pannel',
   to: '/receivedPanel',
   active: true
 }, */

        {
          name: 'Stock List',
          to: '/inventory/stockList-admin',
          active: true,
        },
        {
          name: 'Stock Search List',
          to: '/inventory/stockList-filter-admin',
          active: true,
        },

        {
          name: 'Offer Stock List',
          to: '/inventory/offer-stockList-admin',
          active: true,
        },
      ],
    },
    {
      name: 'Sales Management',
      icon: 'chart-pie',
      active: true,
      role: 'MIS',
      children: [
        /* {
  name: 'Holiday Information',
  to: '/holiday_info',
  active: true
},
{
  name: 'Target Audience Type',
  to: '/targetAudienceType',
  active: true
},
*/
        {
          name: 'Target Upload',
          to: '/target-upload',
          active: true,
        },
      ],
    },
    {
      name: 'HR Management',
      icon: 'graduation-cap',
      role: 'MIS',
      active: true,
      children: [],
    },
    {
      name: 'Payroll',
      icon: 'fire',
      active: true,
      role: 'MIS',
      children: [],
    },
    {
      name: 'Settings',
      icon: ['fab', 'trello'],
      role: 'MIS',
      active: true,
      children: [
        /* {
  name: 'Offer Type',
  to: 'offerType',
  active: true
} */
        {
          name: 'Generate Formate (.csv)',
          to: 'generate-format',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      role: 'MIS',
      active: true,
      children: [
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/target-vs-achievement-monthly',
          active: true,
        },
        {
          name: 'Super DB Target Vs Achievement Monthly Report',
          to: '/reports/super-db/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    // MIS ROUTES END

    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'SUPER DISTRIBUTOR',
      children: [
        {
          name: 'Create Order',
          to: '/master/super-distributor-orders-create',
          active: true,
        },
        {
          name: 'Orders List (Current Month)',
          to: '/master/super-distributor-orders',
          active: true,
        },

        {
          name: 'Own Orders List (Search)',
          to: '/master/distributor-orders',
          active: true,
        },

        {
          name: 'Other DB Orders List (Search)',
          to: '/finance/orders-filter',
          active: true,
        },

        {
          name: 'Other DB Orders Pending List',
          to: '/master/super-distributor-orders-others-pending',
          active: true,
        },

        {
          name: 'Other DB Orders Approved List',
          to: '/master/super-distributor-orders-others',
          active: true,
        },

        {
          name: 'Deposit',
          to: '/master/deposit',
          active: true,
        },
      ],
    },

    {
      name: 'User Management',
      active: true,
      icon: 'user',
      role: 'SUPER DISTRIBUTOR',
      children: [
        {
          name: 'Distributor',
          to: 'user/distributor-under-super',
          active: true,
        },
      ],
    },

    {
      name: 'Finance & Account',
      icon: 'flag',
      active: true,
      role: 'SUPER DISTRIBUTOR',
      children: [
        // {
        //     name: 'Confirm Orders SDB',
        //     to: '/accounts/confirm-orders-under-sdb',
        //     active: true,
        // },
        // {
        //     name: 'Orders List SDB',
        //     to: '/accounts/orders-junior',
        //     active: true,
        // },
        {
          name: 'Due Payments SDB',
          to: '/super/due-payment-list-under-sdb',
          active: true,
        },
        // {
        //     name: 'Adjustment Payment SDB',
        //     to: '/super/adjustment-under-sdb',
        //     active: true,
        // },
        {
          name: 'Pending Deposit SDB',
          to: '/super/confirm-deposit-under-sdb',
          active: true,
        },

        {
          name: 'Approved Deposit List SDB',
          to: '/super/approved-deposit-list-under-sdb',
          active: true,
        },
      ],
    },

    // {
    //   name: 'Distribution SDB',
    //   icon: 'book',
    //   active: true,
    //   role: 'SUPER DISTRIBUTOR',
    //   children: [
    //     {
    //       name: 'Scheduling SDB',
    //       to: '/distribution/scheduling-under-sdb',
    //       active: true,
    //     },
    //     {
    //       name: 'Unscheduled DO SDB',
    //       to: '/distribution/productscheduling-under-sdb/pending',
    //       active: true,
    //     },
    //     {
    //       name: 'Complete Scheduling SDB',
    //       to: '/distribution/productscheduling-under-sdb/done',
    //       active: true,
    //     },
    //   ],
    // },

    {
      name: 'Inventory SDB',
      icon: 'calendar-day',
      active: true,
      role: 'SUPER DISTRIBUTOR',
      children: [
        // {
        //     name: 'Received Panel SDB',
        //     to: '/inventory/receivedPanel-db',
        //     active: true,
        // },
        // {
        //     name: 'Received List SDB',
        //     to: '/inventory/receivedList-db',
        //     active: true,
        // },
        {
          name: 'Stock List SDB',
          to: '/inventory/stockList-sdb',
          active: true,
        },
        {
          name: 'Order Delivery SDB',
          to: '/distribution/productdelivery-sdb',
          active: true,
        },

        {
          name: 'Delivery List SDB',
          to: '/inventory/deliveryList-sdb',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'SUPER DISTRIBUTOR',
      children: [
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/db/target-vs-achievement-monthly',
          active: true,
        },
        {
          name: 'Under DB Target Vs Achievement Monthly Report',
          to: '/reports/under-db/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    /////////////////////////////////////////////////////
    ////////////////Super DB Menus End/////////////////
    ///////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
    /////////////Distributors Under Super DB Starts/////////////
    /////////////////////////////////////////////////////////////

    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'UNDER DISTRIBUTOR',
      children: [
        {
          name: 'Order Create',
          to: '/master/under-super-distributor-orders-create',
          active: true,
        },
        {
          name: 'Orders List(Current Month)',
          to: '/master/under-super-distributor-orders',
          active: true,
        },

        {
          name: 'Orders List (Search)',
          to: '/master/distributor-orders',
          active: true,
        },

        {
          name: 'Deposit',
          to: '/master/deposit',
          active: true,
        },
      ],
    },

    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'UNDER DISTRIBUTOR',
      children: [
        // {
        //   name: 'All Factory Stock List',
        //   to: '/master/distributor-stock-list',
        //   active: true,
        // },
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/db/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    /////////////////////////////////////////////////////////////
    /////////////Distributors Under Super DB Ends/////////////
    /////////////////////////////////////////////////////////////

    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'GENERAL DISTRIBUTOR',
      children: [
        {
          name: 'Order Create',
          to: '/master/distributor-orders-create',
          active: true,
        },
        {
          name: 'Orders List',
          to: '/master/distributor-orders-list',
          active: true,
        },
        {
          name: 'Orders List (Search)',
          to: '/master/distributor-orders',
          active: true,
        },

        {
          name: 'Deposit',
          to: '/master/deposit',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'GENERAL DISTRIBUTOR',
      children: [
        // {
        //   name: 'All Factory Stock List',
        //   to: '/master/distributor-stock-list',
        //   active: true,
        // },
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/db/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },
    /* {
  name: 'Outlet Management',
  icon: 'shopping-cart',
  active: true,
  role: "GENERAL DISTRIBUTOR",
  children: [
    {
      name: 'Delevary to outlet',
      to: '/master/distributor-delevary-create',
      active: true
    },
    {
      name: 'Delevary List',
      to: '/master/distributor-delevary-list',
      active: true
    },

  ]
},
*/
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'SPECIAL DISTRIBUTOR',
      children: [
        {
          name: 'Order Create',
          to: '/master/special-distributor-orders-create',
          active: true,
        },
        {
          name: 'Orders List',
          to: '/master/special-distributor-orders',
          active: true,
        },

        {
          name: 'Deposit',
          to: '/master/deposit',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'SPECIAL DISTRIBUTOR',
      children: [
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/sp-db/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'COMMISSION DISTRIBUTOR',
      children: [
        {
          name: 'Order Create',
          to: '/master/distributor-orders-create',
          active: true,
        },
        {
          name: 'Orders List',
          to: '/master/distributor-orders',
          active: true,
        },
        {
          name: 'Deposit',
          to: '/master/deposit',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'COMMISSION DISTRIBUTOR',
      children: [
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/db/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'WHOLESALE DISTRIBUTOR',
      children: [
        {
          name: 'Order Create',
          to: '/master/distributor-orders-create',
          active: true,
        },
        {
          name: 'Orders List (Current Month)',
          to: '/master/distributor-orders-list',
          active: true,
        },
        {
          name: 'Orders List (Search)',
          to: '/master/distributor-orders',
          active: true,
        },

        {
          name: 'Deposit',
          to: '/master/deposit',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'WHOLESALE DISTRIBUTOR',
      children: [
        {
          name: 'All Factory Stock List',
          to: '/master/distributor-stock-list',
          active: true,
        },
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/db/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'SPOT DISTRIBUTOR',
      children: [
        {
          name: 'Order Create',
          to: '/master/distributor-orders-create',
          active: true,
        },
        {
          name: 'Orders List',
          to: '/master/distributor-orders',
          active: true,
        },
        {
          name: 'Deposit',
          to: '/master/deposit',
          active: true,
        },
      ],
    },

    /////////////SUPER DB' || userRole === 'GENERAL DB' || userRole === 'COMMISSION DB' || userRole === 'WHOLESALE DB' || userRole === 'SPOT DB' routes end

    ///Sales ASM Start//////////
    {
      name: 'User Management',
      active: true,
      icon: 'user',
      role: 'ASM',
      children: [
        {
          name: 'Distributor',
          to: 'user/distributor',
          active: true,
        },
      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'ASM',

      children: [
        {
          name: 'Pending Orders',
          to: '/master/distributor-orders-pending',
          active: true,
        },
        {
          name: 'Orders Search',
          to: '/master/distributor-all-orders',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'ASM',
      children: [
        {
          name: 'All Factory Stock List',
          to: '/all-factory-stock-list',
          active: true,
        },
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/asm/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    ///Sales ASM End//////////

    ////////////////Sales RSM Start//////////////////
    {
      name: 'User Management',
      active: true,
      icon: 'user',
      role: 'RSM',
      children: [
        {
          name: 'Distributor',
          to: 'user/distributor',
          active: true,
        },
      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'RSM',

      children: [
        {
          name: 'Pending Orders',
          to: '/master/db-orders-authorized',
          active: true,
        },
        {
          name: 'Orders Search',
          to: '/master/db-all-orders',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'RSM',
      children: [
        {
          name: 'All Factory Stock List',
          to: '/factory-stock-list',
          active: true,
        },
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/rsm/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    ////////////////DO Process Start//////////////////
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'Sales Admin',

      children: [
        {
          name: 'Pending Orders',
          to: '/master/do-pending-orders',
          active: true,
        },
        {
          name: 'All Orders',
          to: '/master/do-all-orders',
          active: true,
        },
        {
          name: 'Orders Search',
          to: '/master/do-all-orders-filter',
          active: true,
        },

        {
          name: 'Deposits Search',
          to: '/master/deposit-search',
          active: true,
        },
        {
          name: 'Stock Search',
          to: '/master/do-all-stock-filter',
          active: true,
        },

      ],
    },

    ////////////////Sales RSM End//////////////////

    // DSM
    {
      name: 'User Management',
      active: true,
      icon: 'user',
      role: 'DSM',
      children: [
        {
          name: 'Distributor',
          to: 'user/distributor/dsm',
          active: true,
        },
      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'DSM',

      children: [
        {
          name: 'Orders Search',
          to: '/dsm/orders',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'DSM',
      children: [
        {
          name: 'Factory Stock List Search',
          to: '/dsm/stock-list',
          active: true,
        },
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/dsm/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    // HOS
    {
      name: 'User Management',
      active: true,
      icon: 'user',
      role: 'HOS',
      children: [
        {
          name: 'Distributor',
          to: 'user/distributor',
          active: true,
        },
      ],
    },
    {
      name: 'Master Register',
      icon: 'table',
      active: true,
      role: 'HOS',
      children: [
        {
          name: 'Offers',
          to: '/master/offer',
          active: true,
        },
        {
          name: 'Discounts',
          to: '/master/discount',
          active: true,
        },
      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'HOS',
      children: [
        {
          name: 'Orders List',
          to: '/master/distributor-orders',
          active: true,
        },
      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'HOS',
      children: [
        {
          name: 'All Factory Stock List',
          to: '/factory-stock-list',
          active: true,
        },
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    ///////////Inventory Start/////////////////////
    {
      name: 'Inventory',
      icon: 'calendar-day',
      active: true,
      role: 'Inventory',
      children: [
        {
          name: 'Shipment Receive Pending',
          to: '/inventory/receivedList',
          active: true,
        },
        {
          name: 'Received Search List',
          to: '/production/receivedList',
          active: true,
        },
        {
          name: 'Recived Confirm List',
          to: '/production/received-confirm',
          active: true,
        },
        {
          name: 'Pending Transfer Received List (Incoming)',
          to: '/inventory/transfer/receivedList',
          active: true,
        },
        {
          name: 'Confirmed Transfer Received List (Incoming)',
          to: '/inventory/transfer/receivedList-confirmed',
          active: true,
        },

        {
          name: 'Transfer Panel',
          to: '/inventory/transferPanel',
          active: true,
        },

        {
          name: 'Pending Purchase List',
          to: '/inventory/purchasePanel',
          active: true,
        },

        {
          name: 'Purchase List',
          to: '/inventory/purchaseList',
          active: true,
        },


        {
          name: 'Return Panel',
          to: '/inventory/returnPanel',
          active: true,
        },

        {
          name: 'Return List',
          to: '/inventory/returnList',
          active: true,
        },

        {
          name: 'Transfer Pending List (Outgoing)',
          to: '/inventory/transfer/pendingList',
          active: true,
        },
        {
          name: 'Transfer Confirmed List (Outgoing)',
          to: '/inventory/transfer/confirmedList',
          active: true,
        },

        {
          name: 'General Stock List',
          to: '/inventory/stockList',
          active: true,
        },
        {
          name: 'Stock List Search (Date Wise)',
          to: '/inventory/stockListFilter-date-wise',
          active: true,
        },
        {
          name: 'Stock List Search (Month Wise)',
          to: '/inventory/stockListFilter',
          active: true,
        },

        {
          name: 'Offer Product Received Panel',
          to: '/inventory/offer-product-receivedPanel',
          active: true,
        },
        {
          name: 'Offer Product Received List',
          to: '/inventory/offer-product-receivedList',
          active: true,
        },
        {
          name: 'Offer Stock List',
          to: '/inventory/offer-stockList',
          active: true,
        },


      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'Inventory',

      children: [
        {
          name: 'Order Delivery',
          to: '/distribution/productdelivery-todo',
          active: true,
        },

        {
          name: 'Order Delivery Pending',
          to: '/distribution/productdelivery/pending',
          active: true,
        },

        {
          name: 'Delivery Load Sheet',
          to: '/inventory/delivery/load-sheet',
          active: true,
        },
        {
          name: 'Delivery List',
          to: '/inventory/delivery/total',
          active: true,
        },
        {
          name: 'Delivery Search',
          to: '/inventory/delivery/search',
          active: true,
        },
      ],
    },
    {
      name: 'Purchase',
      icon: 'calendar-day',
      active: true,
      role: 'Inventory',
      children: [
        {
          name: 'Create Purchase',
          to: '/scm/purchase-create',
          active: true,
        },
        {
          name: 'Purchase Pending',
          to: '/scm/purchase-pending',
          active: true,
        },
        {
          name: 'Purchase Confirm List',
          to: '/scm/purchase-confirm',
          active: true,
        },
      ],
    },

    ////////////Inventory End/////////////////////

    ///////////Production Start/////////////////////
    {
      name: 'Production',
      icon: 'calendar-day',
      active: true,
      role: 'Production',
      children: [
        {
          name: 'Shipment Received Form',
          to: '/production/receivedPanel',
          active: true,
        },
        {
          name: 'Pending Shipment List',
          to: '/production/pendingList',
          active: true,
        },
        {
          name: 'Shipment Filter List',
          to: '/production/receivedList',
          active: true,
        },
        {
          name: 'Shipment Recived Confirm List',
          to: '/production/received-confirm',
          active: true,
        },

      ],
    },


    ////////////Production End/////////////////////

    /////////////Distribution Start///////////////
    {
      name: 'Distribution',
      icon: 'book',
      active: true,
      role: 'Distribution',
      children: [
        {
          name: 'Scheduling',
          to: '/distribution/scheduling',
          active: true,
        },
        {
          name: 'Unscheduled DO',
          to: '/distribution/productscheduling/pending',
          active: true,
        },
        {
          name: 'Pending Schedules',
          to: '/distribution/productscheduling/not-confirmed',
          active: true,
        },
        {
          name: 'Approved Schedules',
          to: '/distribution/productscheduling/done',
          active: true,
        },
      ],
    },
    ////////////Distribution End/////////////////

    //////////////////Order Management Start////////////////
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'Order Management',
      children: [
        {
          name: 'Pending Orders',
          to: '/accounts/confirm-orders',
          active: true,
        },
        {
          name: 'All Orders List',
          to: '/accounts/orders',
          active: true,
        },
        {
          name: 'DO Search',
          to: '/finance/orders-filter',
          active: true,
        },


      ],
    },
    {
      name: 'Finance & Account',
      icon: 'flag',
      active: true,
      role: 'Order Management',
      children: [

        {
          name: 'Due Payments',
          to: '/accounts/due-payment-list',
          active: true,
        },
        {
          name: 'Pending Deposit',
          to: '/accounts/confirm-deposit',
          active: true,
        },
        {
          name: 'Confirmed Deposit List',
          to: '/master/deposits',
          active: true,
        },

        {
          name: 'All Deposit List',
          to: '/master/deposits-all',
          active: true,
        },

      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'Order Management',
      children: [
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    /////////////////////Accounts End/////////////

    //////////////////Accounts Start////////////////
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'Accounts',
      children: [
        {
          name: 'DO Search',
          to: '/finance/orders-filter',
          active: true,
        },


      ],
    },
    {
      name: 'Finance & Account',
      icon: 'flag',
      active: true,
      role: 'Accounts',
      children: [
        {
          name: 'Due Payments',
          to: '/accounts/due-payment-list',
          active: true,
        },
        {
          name: 'Adjustment Payment',
          to: '/accounts/adjustment',
          active: true,
        },
        {
          name: 'Pending Deposit',
          to: '/accounts/confirm-deposit',
          active: true,
        },
        {
          name: 'Confirmed Deposit List',
          to: '/master/deposits',
          active: true,
        },

        {
          name: 'All Deposit List',
          to: '/master/deposits-all',
          active: true,
        },

      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'Accounts',
      children: [
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    /////////////////////Accounts End/////////////

    //////////////Finance ////////////////

    {
      name: 'Finance & Account',
      icon: 'flag',
      active: true,
      role: 'Finance',
      children: [
        // {
        //   name: 'Pending Orders',
        //   to: '/finance/orders',
        //   active: true,
        // },
        {
          name: 'Orders List',
          to: '/finance/orders-list',
          active: true,
        },

        {
          name: 'Order & Payment Approve',
          to: '/finance/orders-and-payment',
          active: true,
        },

        {
          name: 'Schedule Approve',
          to: '/finance/schedule-and-payment',
          active: true,
        },

        // {
        //   name: 'Pending Schedule List for Approval',
        //   to: '/finance/productscheduling/done',
        //   active: true,
        // },


        {
          name: 'Approved Scheduled Order List',
          to: '/finance/productscheduling/approved',
          active: true,
        },
        // {
        //   name: 'Reconciliation List',
        //   to: '/finance/reconciliation/pending',
        //   active: true,
        // },

        {
          name: 'Reconciliation',
          to: '/master/db-order-reconcile-finance',
          active: true,
        },

        {
          name: 'Due Payments',
          to: '/accounts/due-payment-list',
          active: true,
        },
        // {
        //   name: 'Pending Deposit',
        //   to: '/accounts/approved-deposit',
        //   active: true,
        // },
        {
          name: 'Approved Deposit List',
          to: '/master/deposits',
          active: true,
        },

        {
          name: 'All Deposit List',
          to: '/master/deposits-all',
          active: true,
        },


      ],
    },
    {
      name: 'Order Management',
      icon: 'shopping-cart',
      active: true,
      role: 'Finance',
      children: [
        {
          name: 'Do Search',
          to: '/finance/orders-filter',
          active: true,
        },


      ],
    },
    {
      name: 'Reports',
      icon: 'shapes',
      active: true,
      role: 'Finance',
      children: [
        {
          name: 'Factory Stock List',
          to: '/finance/stock-filter',
          active: true,
        },
        {
          name: 'Target Vs Achievement Monthly Report',
          to: '/reports/target-vs-achievement-monthly',
          active: true,
        },
      ],
    },

    //////////////Finance /////////////////
  ],
}

export default [
  dashboardRoutes,
  // appRoutes,
  // pagesRoutes,
  modulesRoutes,
  // documentationRoutes
]
