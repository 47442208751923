import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import PageHeader from 'components/common/PageHeader'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import IconButton from "components/common/IconButton";
import Select from 'react-select'
import { useQuery } from 'react-query'
import * as XLSX from 'xlsx';
import { connect } from 'react-redux'
import { getAllOrders, getAllSchedules } from 'services/api'
import { formatNumber } from 'helpers/utils'
import { authHeader } from "utils";
import Swal from 'sweetalert2'
import axios from 'axios'
import ExcelDownload from 'helpers/ExcelDownload'



import CollapseOrdersUnscheduled from './CollapseOrdersUnscheduled'

import {
    getOrdersByDealerIdFactory
} from 'services/api'

const OrdersUnscheduled = ({ user }) => {


    const [excelData, setExcelData] = useState([]);

    const [pendingGroupedOrders, setPendingGroupedOrders] = useState([])

    const [distributorOptions, setDistributorOptions] = useState([])

    const [distributorValue, setDistributorValue] = useState([])

    const [searchText, setSearchText] = useState([])

    const [expand, setExpand] = useState(false)


    const navigate = useNavigate()

    const fetchOrdersData = () => {
        // getOrdersByDealerIds({ dealerIds: distributorValue.map(item => item.userId), factoryName: myFactory.value })

        getOrdersByDealerIdFactory({ dealerIds: distributorValue.map(item => item.dealerId) })
            .then((res) => {
                if (res.data?.success) {
                    console.log(res.data?.message)
                    const groupedOrders = res.data?.payload?.groupedOrders;

                    //extract scOrders arrays from the above groupedOrders then flatten them

                    const scOrders = Object.values(groupedOrders).map((order) => {
                        return order.scOrders
                    }).flat()


                    // const modifiedOrders = res.data?.payload?.moreDBOrders?.map((order) => {
                    const modifiedOrders = scOrders?.map((order) => {
                        return {
                            ...order,
                            deliveryQty: user.data.userRole == "SPECIAL DISTRIBUTOR" ? order.totalOrderQtyPCS : order.orderQtyCtn
                        }
                    })

                    setPendingGroupedOrders(groupedOrders)

                    setDistributorOptions(groupedOrders.map((order) => {
                        return {
                            ...order,
                            value: order.dealerId,
                            label: order.dealerId + " (" + order.distributorName + ")"
                        }
                    }))



                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    toast.error(e.response?.data?.errors)
                }
            })
    }



    useEffect(() => {

        fetchOrdersData();

    }, [distributorValue.length])


    const expandHandler = (order) => {
        const orders = pendingGroupedOrders
        orders.forEach((element) => {
            element.open = false
        })
        const index = orders.findIndex((item) => item._id == order._id)
        orders[index].open = true
        setPendingGroupedOrders(orders)
        setExpand(!expand)
    }




    return (
        <>
            <PageHeader
                title={`Uncheduled DO list`}
                className="mb-3"
            ></PageHeader>

            <Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">

                        <Select
                            closeMenuOnSelect={true}
                            options={distributorOptions}
                            placeholder="Select Distributor IDs"
                            isMulti
                            classNamePrefix="react-select"
                            value={distributorValue}
                            onChange={(value) =>
                                setDistributorValue(value)
                            }

                        
                        />
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            {pendingGroupedOrders?.length > 0 ? (
                                <table className="table table-bordered table-striped fs--1 mb-0">
                                    <thead>
                                        <tr
                                            style={{
                                                fontSize: 'medium',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#354151',
                                            }}
                                        >
                                            <th className="align-middle white-space-nowrap text-center ">
                                                ...
                                            </th>



                                            <th className="align-middle white-space-nowrap text-center ">
                                                DB ID
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                DB Name
                                            </th>


                                            <th className="align-middle white-space-nowrap text-center ">
                                                Order Qty Ctn
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Order Qty PCS
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Discount BDT
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Price
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Status
                                            </th>
                                            {/* <th className="align-middle white-space-nowrap text-center ">
                                                Action
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody
                                        className="list"
                                        id="table-purchase-body"
                                    >
                                        {pendingGroupedOrders?.map(
                                            (order, index) => {
                                                return (
                                                    <>
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                fontSize:
                                                                    'medium',
                                                                fontWeight:
                                                                    '500',
                                                                fontFamily:
                                                                    'Poppins',
                                                                color: '#354151',
                                                            }}
                                                        >
                                                            <td className="align-middle text-center border-0">
                                                                <Button
                                                                    onClick={() =>
                                                                        expandHandler(
                                                                            order
                                                                        )
                                                                    }
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={
                                                                        order.open
                                                                    }
                                                                    variant="falcon-primary"
                                                                    className="custom-button" // Apply the custom class
                                                                >
                                                                    {order.open &&
                                                                        expand
                                                                        ? '-'
                                                                        : '+'}
                                                                </Button>
                                                            </td>


                                                            <td className="align-middle text-center">
                                                                {
                                                                    order?.dealerId
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    order?.distributorName
                                                                }
                                                            </td>


                                                            <td className="align-middle text-center">
                                                                {
                                                                    formatNumber(order?.orderQtyCtn)
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    formatNumber(order?.totalOrderQtyPCS)
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    formatNumber(order?.discountBDT)
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    formatNumber(order?.totalPrice)
                                                                }
                                                            </td>
                                                            <td
                                                                className="align-middle text-center"
                                                                style={{
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                Unscheduled
                                                            </td>

                                                            {/* <td>
                                                                <Dropdown className="d-inline mx-2">
                                                                    <Dropdown.Toggle
                                                                        id="dropdown-autoclose-true"
                                                                        className=" bg-none"
                                                                    >
                                                                        ...
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item>
                                                                            <Link
                                                                                to={`/master/distributor-orders-view/${order?.doNo}`}
                                                                            >
                                                                                <IconButton
                                                                                    variant="falcon-default"
                                                                                    size="sm"
                                                                                    icon="edit"
                                                                                    transform="shrink-2"
                                                                                    iconAlign="middle"
                                                                                    className="me-2"
                                                                                >
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        View
                                                                                        details
                                                                                    </span>
                                                                                </IconButton>
                                                                            </Link>
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item>
                                                                            <IconButton
                                                                                variant="falcon-default"
                                                                                size="sm"
                                                                                icon="edit"
                                                                                transform="shrink-2"
                                                                                iconAlign="middle"
                                                                                className="me-2"
                                                                                onClick={() =>
                                                                                    handleEditOrder(
                                                                                        order
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    Edit
                                                                                </span>
                                                                            </IconButton>
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item>
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handleCancelOrder(
                                                                                        order
                                                                                    )
                                                                                }
                                                                                variant="falcon-default"
                                                                                size="sm"
                                                                                icon="trash-alt"
                                                                                iconAlign="middle"
                                                                                className="d-none d-sm-block me-2"
                                                                            >
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    Cancel
                                                                                </span>
                                                                            </IconButton>
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td> */}
                                                        </tr>
                                                        <tr
                                                            style={{
                                                                display:
                                                                    order.open &&
                                                                        expand
                                                                        ? 'table-row'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            <td colSpan={15}>
                                                                <div className="p-0">
                                                                    <CollapseOrdersUnscheduled ordersInfo={order.scOrders}></CollapseOrdersUnscheduled>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center p-4">
                                    <h5>No Orders Found</h5>
                                </div>
                            )}
                        </div>
                    </div>
                </Card.Body>
                {/* <Card.Footer>
                    <AdvanceTablePagination
                        table
                        paginationPageCount={paginationPageCount}
                        setPaginationPageCount={setPaginationPageCount}
                        pageDataCount={pageDataCount}
                        setPageDataCount={setPageDataCount}
                        totalPages={totalPages}
                    />
                </Card.Footer> */}
            </Card>



        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersUnscheduled)
