import Calendar from 'components/app/calendar/Calendar'
import Chat from 'components/app/chat/Chat'
import Invoice from 'components/app/e-commerce/Invoice'
import Billing from 'components/app/e-commerce/billing/Billing'
import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart'
import Checkout from 'components/app/e-commerce/checkout/Checkout'
import CustomersDetails from 'components/app/e-commerce/customers-details/CustomersDetails'
import Customers from 'components/app/e-commerce/customers/Customers'
import OrderDetails from 'components/app/e-commerce/orders/order-details/OrderDetails'
import Orders from 'components/app/e-commerce/orders/order-list/Orders'
import Products from 'components/app/e-commerce/product/Products'
import ProductDetails from 'components/app/e-commerce/product/product-details/ProductDetails'
import Courses from 'components/app/e-learning/course/Courses'
import CourseDetails from 'components/app/e-learning/course/course-details'
import CreateCourse from 'components/app/e-learning/course/create-a-course'
import StudentOverview from 'components/app/e-learning/student-overview'
import TrainerProfile from 'components/app/e-learning/trainer-profile'
import Compose from 'components/app/email/compose/Compose'
import EmailDetail from 'components/app/email/email-detail/EmailDetail'
import Inbox from 'components/app/email/inbox/Inbox'
import CreateEvent from 'components/app/events/create-an-event/CreateEvent'
import EventDetail from 'components/app/events/event-detail/EventDetail'
import EventList from 'components/app/events/event-list/EventList'
import Kanban from 'components/app/kanban/Kanban'
import ActivityLog from 'components/app/social/activity-log/ActivityLog'
import Feed from 'components/app/social/feed/Feed'
import Followers from 'components/app/social/followers/Followers'
import Notifications from 'components/app/social/notifications/Notifications'
import ModalAuth from 'components/authentication/modal/ModalAuth'
import WizardAuth from 'components/authentication/wizard/WizardAuth'
import Analytics from 'components/dashboards/analytics'
import Crm from 'components/dashboards/crm'
import Ecommerce from 'components/dashboards/e-commerce'
import Lms from 'components/dashboards/lms'
import ProjectManagement from 'components/dashboards/project-management'
import Saas from 'components/dashboards/saas'
import Accordion from 'components/doc-components/Accordion'
import AdvanceSelect from 'components/doc-components/AdvanceSelect'
import AdvanceTableExamples from 'components/doc-components/AdvanceTableExamples'
import Alerts from 'components/doc-components/Alerts'
import AnimatedIcons from 'components/doc-components/AnimatedIcons'
import Avatar from 'components/doc-components/Avatar'
import Background from 'components/doc-components/Backgrounds'
import Badges from 'components/doc-components/Badges'
import BootstrapCarousel from 'components/doc-components/BootstrapCarousel'
import Breadcrumbs from 'components/doc-components/Breadcrumb'
import Buttons from 'components/doc-components/Buttons'
import CalendarExample from 'components/doc-components/CalendarExample'
import Cards from 'components/doc-components/Cards'
import Checks from 'components/doc-components/Checks'
import Collapse from 'components/doc-components/Collapse'
import ComboNavbar from 'components/doc-components/ComboNavbar'
import CookieNoticeExample from 'components/doc-components/CookieNoticeExample'
import CountUp from 'components/doc-components/CountUp'
import DatePicker from 'components/doc-components/DatePicker'
import DraggableExample from 'components/doc-components/DraggableExample'
import Dropdowns from 'components/doc-components/Dropdowns'
import Editor from 'components/doc-components/Editor'
import Embed from 'components/doc-components/Embed'
import EmojiMartExample from 'components/doc-components/EmojiMart'
import Figures from 'components/doc-components/Figures'
import FileUploader from 'components/doc-components/FileUploader'
import FloatingLabels from 'components/doc-components/FloatingLabels'
import FontAwesome from 'components/doc-components/FontAwesome'
import FormControl from 'components/doc-components/FormControl'
import FormLayout from 'components/doc-components/FormLayout'
import FormValidation from 'components/doc-components/FormValidation'
import GoogleMapExample from 'components/doc-components/GoogleMapExample'
import Hoverbox from 'components/doc-components/Hoverbox'
import Image from 'components/doc-components/Image'
import InputGroup from 'components/doc-components/InputGroup'
import LeafletMapExample from 'components/doc-components/LeafletMapExample'
import Lightbox from 'components/doc-components/Lightbox'
import ListGroups from 'components/doc-components/ListGroups'
import Modals from 'components/doc-components/Modals'
import NavBarTop from 'components/doc-components/NavBarTop'
import NavbarDoubleTop from 'components/doc-components/NavbarDoubleTop'
import Navbars from 'components/doc-components/Navbars'
import Navs from 'components/doc-components/Navs'
import Offcanvas from 'components/doc-components/Offcanvas'
import Pagination from 'components/doc-components/Pagination'
import Placeholder from 'components/doc-components/Placeholder'
import Popovers from 'components/doc-components/Popovers'
import BasicProgressBar from 'components/doc-components/ProgressBar'
import Range from 'components/doc-components/Range'
import Rating from 'components/doc-components/Rating'
import ReactIcons from 'components/doc-components/ReactIcons'
import ReactPlayerExample from 'components/doc-components/ReactPlayerExample'
import Scrollbar from 'components/doc-components/Scrollbar'
import Scrollspy from 'components/doc-components/Scrollspy'
import Search from 'components/doc-components/Search'
import Select from 'components/doc-components/Select'
import SlickCarousel from 'components/doc-components/SlickCarousel'
import Spinners from 'components/doc-components/Spinners'
import Tables from 'components/doc-components/Tables'
import Tabs from 'components/doc-components/Tabs'
import Timeline from 'components/doc-components/Timeline'
import Toasts from 'components/doc-components/Toasts'
import Tooltips from 'components/doc-components/Tooltips'
import TreeviewExample from 'components/doc-components/TreeviewExample'
import TypedText from 'components/doc-components/TypedText'
import VerticalNavbar from 'components/doc-components/VerticalNavbar'
import WizardForms from 'components/doc-components/WizardForms'
import Chartjs from 'components/doc-components/charts-example/chartjs'
import D3js from 'components/doc-components/charts-example/d3'
import HowToUse from 'components/doc-components/charts-example/echarts/HowToUse'
import BarCharts from 'components/doc-components/charts-example/echarts/bar-charts'
import CandlestickCharts from 'components/doc-components/charts-example/echarts/candlestick-charts'
import GeoMaps from 'components/doc-components/charts-example/echarts/geo-map'
import HeatmapCharts from 'components/doc-components/charts-example/echarts/heatmap-chart'
import LineCharts from 'components/doc-components/charts-example/echarts/line-charts'
import PieCharts from 'components/doc-components/charts-example/echarts/pie-charts'
import RadarCharts from 'components/doc-components/charts-example/echarts/radar-charts/Index'
import ScatterCharts from 'components/doc-components/charts-example/echarts/scatter-charts'
import Configuration from 'components/documentation/Configuration'
import DarkMode from 'components/documentation/DarkMode'
import DesignFile from 'components/documentation/DesignFile'
import GettingStarted from 'components/documentation/GettingStarted'
import Plugins from 'components/documentation/Plugins'
import Styling from 'components/documentation/Styling'
import Changelog from 'components/documentation/change-log/ChangeLog'
import Landing from 'components/pages/landing/Landing'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import AuthSimpleLayout from '../layouts/AuthSimpleLayout'
import ErrorLayout from '../layouts/ErrorLayout'
import MainLayout from '../layouts/MainLayout'
// import Starter from 'components/pages/Starter';
import ContactDetails from 'components/app/support-desk/contact-details/ContactDetails'
import Contacts from 'components/app/support-desk/contacts/Contacts'
import QuickLinks from 'components/app/support-desk/quick-links/QuickLinks'
import Reports from 'components/app/support-desk/reports/Reports'
import CardView from 'components/app/support-desk/tickets-layout/CardView'
import TableView from 'components/app/support-desk/tickets-layout/TableView'
import TicketsPreview from 'components/app/support-desk/tickets-preview/TicketsPreview'
import Logout from 'components/authentication/Logout'
import CardConfirmMail from 'components/authentication/card/ConfirmMail'
import CardForgetPassword from 'components/authentication/card/ForgetPassword'
import CardLockScreen from 'components/authentication/card/LockScreen'
import CardLogin from 'components/authentication/card/Login'
import CardPasswordReset from 'components/authentication/card/PasswordReset'
import CardRegistration from 'components/authentication/card/Registration'
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail'
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword'
import SimpleLockScreen from 'components/authentication/simple/LockScreen'
import SimpleLogin from 'components/authentication/simple/Login'
import SimpleLogout from 'components/authentication/simple/Logout'
import SimplePasswordReset from 'components/authentication/simple/PasswordReset'
import SimpleRegistration from 'components/authentication/simple/Registration'
import SplitConfirmMail from 'components/authentication/split/ConfirmMail'
import SplitForgetPassword from 'components/authentication/split/ForgetPassword'
import SplitLockScreen from 'components/authentication/split/LockScreen'
import SplitLogin from 'components/authentication/split/Login'
import SplitLogout from 'components/authentication/split/Logout'
import SplitPasswordReset from 'components/authentication/split/PasswordReset'
import SplitRegistration from 'components/authentication/split/Registration'
import Dashboard from 'components/dashboards/default'
import Inventory from 'components/dashboards/inventory/Inventory'
import SchedulingDashboard from 'components/dashboards/scheduling/SchedulingDashboard'
import SupportDesk from 'components/dashboards/support-desk'
import InputMaskExample from 'components/doc-components/InputMaskExample'
import RangeSlider from 'components/doc-components/RangeSlider'
import Faq from 'components/documentation/Faq'
import Error404 from 'components/errors/Error404'
import Error500 from 'components/errors/Error500'
import DistributorAllOrdersList from 'components/pages/ASM/DistributorOrdersASM/DistributorAllOrdersList'
import DistributorOrdersPending from 'components/pages/ASM/DistributorOrdersASM/DistributorOrdersPending'
import ApprovedDeposit from 'components/pages/AccountAndFinance/Deposit/ApprovedDeposit'
import ConfirmDeposit from 'components/pages/AccountAndFinance/Deposit/ConfirmDeposit'
import ConfirmOrders from 'components/pages/AccountAndFinance/Accounts/ConfirmOrders'
import JrAccountsOrdersList from 'components/pages/AccountAndFinance/Accounts/OrdersList'
import AdjustmentOrderDetails from 'components/pages/AccountAndFinance/PaymentAdjustment/AdjustmentOrderDetails'
import DuePaymentList from 'components/pages/AccountAndFinance/PaymentAdjustment/DuePaymentList'
import AdjustmentOrdersList from 'components/pages/AccountAndFinance/PaymentAdjustment/OrdersList'
import PaymentAdjustment2 from 'components/pages/AccountAndFinance/PaymentAdjustment2'
import ApprovedOrders from 'components/pages/AccountAndFinance/Finance/ApprovedOrders'
import SrAccountsOrdersList from 'components/pages/AccountAndFinance/Finance/OrdersList'
import ApprovalCommittee from 'components/pages/ApprovalCommittee/index'
import Delivery from 'components/pages/Delivery/Delivery'
import DeliveryDetails from 'components/pages/Delivery/DeliveryDetails'
import DeliveryUpdate from 'components/pages/Delivery/DeliveryUpdate'
import ApprovalActivity from 'components/pages/Delivery_Approval_Service/ApprovalActivity'
import ApprovalActivityForm from 'components/pages/Delivery_Approval_Service/ApprovalActivity/ApprovalActivityForm'
import CustomerAdvancePayment from 'components/pages/Delivery_Approval_Service/CustomerAdvancePayment'
import CustomerAdvancePaymentForm from 'components/pages/Delivery_Approval_Service/CustomerAdvancePayment/CustomerAdvancePaymentForm'
import CustomerCredit from 'components/pages/Delivery_Approval_Service/CustomerCredit'
import CustomerCreditForm from 'components/pages/Delivery_Approval_Service/CustomerCredit/CustomerCreditForm'
import DeliveryApproval from 'components/pages/Delivery_Approval_Service/DeliveryApproval'
import DeliveryApprovalForm from 'components/pages/Delivery_Approval_Service/DeliveryApproval/DeliveryApprovalForm'
import PaymentsHistoryForm from 'components/pages/Delivery_Approval_Service/PaymentsHistory/PaymentsHistoryForm'
import PaymentsHistory from 'components/pages/Delivery_Approval_Service/PaymentsHistory'
import Deposits from 'components/pages/Deposits'
import DepositForm from 'components/pages/Deposits/DepositForm'
import OrdersUnscheduled from 'components/pages/Distribution/Scheduling/OrdersUnscheduled'
import ScheduleDetails from 'components/pages/Distribution/Scheduling/ScheduleDetails'
import ScheduledOrdersList from 'components/pages/Distribution/Scheduling/ScheduledOrdersList'
import Scheduling from 'components/pages/Distribution/Scheduling/Scheduling'
import SchedulingUpdate from 'components/pages/Distribution/Scheduling/SchedulingUpdate'
import DistributorSalesResentative from 'components/pages/DistributorSalesResentative/index'
import DivisionalHead from 'components/pages/DivisionalHead'
import Employee from 'components/pages/Employee'
import ReceivedList from 'components/pages/InventoryManagement/ReceivedList'
import PurchaseList from 'components/pages/InventoryManagement/PurchaseList'
import ReturnList from 'components/pages/InventoryManagement/ReturnList'
import ReceivedPanel from 'components/pages/InventoryManagement/ReceivedPanel'
import TransferPanel from 'components/pages/InventoryManagement/TransferPanel'
import PurchasePanel from 'components/pages/InventoryManagement/PurchasePanel'
import ReturnPanel from 'components/pages/InventoryManagement/ReturnPanel'
import TransferReceivedList from 'components/pages/InventoryManagement/TransferReceivedList'
import TransferReceivedListConfirmed from 'components/pages/InventoryManagement/TransferReceivedListConfirmed'
import TransferReceivedPanel from 'components/pages/InventoryManagement/TransferReceivedPanel'
import TransferConfirmedList from 'components/pages/InventoryManagement/TransferConfirmedList'
import DetailsPanel from 'components/pages/InventoryManagement/TransferConfirmedList/DetailsPanel'
import TransferPendingList from 'components/pages/InventoryManagement/TransferPendingList'
import AvailableStocksList from 'components/pages/InventoryManagement/ReceivedPanel/AvailableStocksList'
import PendingDeliveries from 'components/pages/InventoryManagement/ReceivedPanel/PendingDeliveries'
import ReceivedStocks from 'components/pages/InventoryManagement/ReceivedPanel/ReceivedStocks'
import ReceivedStocksDetails from 'components/pages/InventoryManagement/ReceivedPanel/ReceivedStocksDetails'
import TotalDeliveries from 'components/pages/InventoryManagement/ReceivedPanel/TotalDeliveries'
import NoticeForm from 'components/pages/Notice/NoticeForm'
import OfficeNotice from 'components/pages/Notice/index'
import AdministrativeDivision from 'components/pages/Organization_Service/AdministrativeDivision'
import AdministrativeDivisionForm from 'components/pages/Organization_Service/AdministrativeDivision/AdministrativeDivisionForm'
import DistributionAssignedAreaForm from 'components/pages/Organization_Service/DistributionAssignedArea/DistributionAssignedAreaForm'
import District from 'components/pages/Organization_Service/District'
import DistrictForm from 'components/pages/Organization_Service/District/DistrictForm'
import Upazila from 'components/pages/Organization_Service/Upazila'
import UpazilaForm from 'components/pages/Organization_Service/Upazila/UpazilaForm'
import AssignedSalesOfficer from 'components/pages/Pos_Service/AssignedSalesOfficer'
import AssignedSalesOfficerForm from 'components/pages/Pos_Service/AssignedSalesOfficer/AssignedSalesOfficerForm'
import OutletChannel from 'components/pages/Pos_Service/OutletChannel'
import OutletChannelForm from 'components/pages/Pos_Service/OutletChannel/OutletChannelForm'
import OutletTarget from 'components/pages/Pos_Service/OutletTarget'
import OutletTargetForm from 'components/pages/Pos_Service/OutletTarget/OutletTargetForm'
import OutletType from 'components/pages/Pos_Service/OutletType'
import OutletTypeForm from 'components/pages/Pos_Service/OutletType/OutletTypeForm'
import PustiRoutes from 'components/pages/Pos_Service/PustiRoutes'
import RouteForm from 'components/pages/Pos_Service/PustiRoutes/RouteForm'
import RouteMap from 'components/pages/Pos_Service/PustiRoutes/RouteMap'
import OutletForm from 'components/pages/Pos_Service/outlets/OutletForm'
import OutletMap from 'components/pages/Pos_Service/outlets/OutletMap'
import Outlets from 'components/pages/Pos_Service/outlets/index'
import ProductDelivery from 'components/pages/ProductDelivery'
import AuthorizedOrders from 'components/pages/RSM/Orders/AuthorizedOrders'
import OrdersList from 'components/pages/RSM/Orders/OrdersList'
import OrdersUpdate from 'components/pages/RSM/Orders/UpdateOrders'
import SalesOfficer from 'components/pages/SalesOfficer'
import CommissionAudience from 'components/pages/Settings_Service/CommissionAudience'
import CommissionAudienceForm from 'components/pages/Settings_Service/CommissionAudience/CommissionAudienceForm'
import DistributorProductSettings from 'components/pages/Settings_Service/DistributorProductSettings'
import DistributorProductSettingsForm from 'components/pages/Settings_Service/DistributorProductSettings/DistributorProductSettingsForm'
import DistributorStorageSettings from 'components/pages/Settings_Service/DistributorStorageSettings'
import DistributorStorageSettingsForm from 'components/pages/Settings_Service/DistributorStorageSettings/DistributorStorageSettingsForm'
import ProductPackagingUnit from 'components/pages/Settings_Service/ProductPackagingUnit'
import ProductPackagingUnitForm from 'components/pages/Settings_Service/ProductPackagingUnit/ProductPackagingUnitForm'
import ProductSettings from 'components/pages/Settings_Service/ProductSettings'
import ProductSettingsForm from 'components/pages/Settings_Service/ProductSettings/ProductSettingsForm'
import StorageForm from 'components/pages/StorageManagement/Storage/StorageForm'
import Storage from 'components/pages/StorageManagement/Storage/index'
import StorageTypes from 'components/pages/StorageManagement/StorageTypes'
import StorageTypesForm from 'components/pages/StorageManagement/StorageTypes/storageTypesForm'
import FactoryManagementForm from 'components/pages/StorageManagement/factoryManagement/FactoryManagementForm'
import TimePassForm from 'components/pages/TimePass/TimePassForm'
import Distributor from 'components/pages/UserManagement/Distributor'
import DistributorForm from 'components/pages/UserManagement/Distributor/DistributorForm'
import SystemUser from 'components/pages/UserManagement/SystemUser'
import SystemUserForm from 'components/pages/UserManagement/SystemUser/SystemUserForm'
import AddEmployee from 'components/pages/addEmployee'
import Associations from 'components/pages/asscociations/Associations'
import DistributorOrderUpdate from 'components/pages/distributors/DistributorOrderUpdate'
import DistributorOrders from 'components/pages/distributors/DistributorOrders'
import DistributorOrderList from 'components/pages/distributors/DistributorOrdersList'
import DistributorOrdersViewDetails from 'components/pages/distributors/DistributorOrdersViewDetails'
import FaqAccordion from 'components/pages/faq/faq-accordion/FaqAccordion'
import FaqAlt from 'components/pages/faq/faq-alt/FaqAlt'
import FaqBasic from 'components/pages/faq/faq-basic/FaqBasic'
import DepotForm from 'components/pages/manipulateDepos/DepoForm'
import Area from 'components/pages/master-register/Area'
import AreaForm from 'components/pages/master-register/Area/AreaForm'
import DbPoint from 'components/pages/master-register/DbPoint'
import DbPointForm from 'components/pages/master-register/DbPoint/DbPointForm'
import Designation from 'components/pages/master-register/Designation'
import DesignationForm from 'components/pages/master-register/Designation/DesignationForm'
import Discount from 'components/pages/master-register/Discounts/Discount'
import DiscountForm from 'components/pages/master-register/Discounts/Discount/DiscountForm'
import DiscountType from 'components/pages/master-register/Discounts/DiscountType'
import DiscountTypeForm from 'components/pages/master-register/Discounts/DiscountType/DiscountTypeForm'
import DistributorType from 'components/pages/master-register/DistributorType'
import DistributorTypeForm from 'components/pages/master-register/DistributorType/DistributorTypeForm'
import Offer from 'components/pages/master-register/Offers/Offer'
import OfferForm from 'components/pages/master-register/Offers/Offer/OfferForm'
import OfferType from 'components/pages/master-register/Offers/OfferType'
import OfferTypeForm from 'components/pages/master-register/Offers/OfferType/OfferTypeForm'
import Product from 'components/pages/master-register/Products/Product'
import ProductForm from 'components/pages/master-register/Products/Product/ProductForm'
import BrandForm from 'components/pages/master-register/Products/brand/brandForm'
import Brand from 'components/pages/master-register/Products/brand/index'
import ProductCategory from 'components/pages/master-register/Products/productCategory'
import AddProductCategory from 'components/pages/master-register/Products/productCategory/AddProductCategory'
import Sosr from 'components/pages/master-register/So-sr/Sosr'
import SrFrom from 'components/pages/master-register/So-sr/SrFrom'
import UserType from 'components/pages/master-register/UserType'
import UserTypeForm from 'components/pages/master-register/UserType/UserTypeForm'
import Banks from 'components/pages/master-register/banks'
import Vendors from 'components/pages/master-register/vendors'
import BankForm from 'components/pages/master-register/banks/bankForm'
import VendorForm from 'components/pages/master-register/vendors/VendorForm'
import Regions from 'components/pages/master-register/region'
import SalesOrgs from 'components/pages/master-register/salesorgs'
import SalesOrgForm from 'components/pages/master-register/salesorgs/salesorgForm'
import Zone from 'components/pages/master-register/zone'
import ZoneForm from 'components/pages/master-register/zone/zoneForm'
import InvitePeople from 'components/pages/miscellaneous/invite-people/InvitePeople'
import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy'
import PricingAlt from 'components/pages/pricing/pricing-alt/PricingAlt'
import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault'
import AddPrimaryOrderProcess from 'components/pages/primaryOrderProcess/AddPrimaryOrderProcess'
import PrimaryOrderProcess from 'components/pages/primaryOrderProcess/primaryOrderProcess'
import Profile from 'components/pages/user/profile/Profile'
import Settings from 'components/pages/user/settings/Settings'
import Borders from 'components/utilities/Borders'
import ColoredLinks from 'components/utilities/ColoredLinks'
import Colors from 'components/utilities/Colors'
import Display from 'components/utilities/Display'
import Flex from 'components/utilities/Flex'
import Float from 'components/utilities/Float'
import Grid from 'components/utilities/Grid'
import Position from 'components/utilities/Position'
import Sizing from 'components/utilities/Sizing'
import Spacing from 'components/utilities/Spacing'
import StretchedLink from 'components/utilities/StretchedLink'
import TextTruncation from 'components/utilities/TextTruncation'
import Typography from 'components/utilities/Typography'
import VerticalAlign from 'components/utilities/VerticalAlign'
import Visibility from 'components/utilities/Visibility'
import Wizard from 'components/wizard/Wizard'
import PropTypes from 'prop-types'
import Widgets from 'widgets/Widgets'
import FactoryMangement from '../components/pages/StorageManagement/factoryManagement'
import Manipulated from '../components/pages/manipulateDepos/index'
import RegionForm from '../components/pages/master-register/region/RegionForm'
import OfferProducts from 'components/pages/master-register/Products/OfferProducts'
import OfferProductsForm from 'components/pages/master-register/Products/OfferProducts/OfferProductsForm'
import SuperDistributorOrders from 'components/pages/superdistributors/SuperDistributorOrders'
import SuperDistributorOrderUpdate from 'components/pages/superdistributors/SuperDistributorOrderUpdate'
import SuperDistributorOrderList from 'components/pages/superdistributors/SuperDistributorOrdersList'
import SuperDistributorOrdersViewDetails from 'components/pages/superdistributors/SuperDistributorOrdersViewDetails'
import SuperDBDistributorForm from 'components/pages/UserManagement/SuperDBDistributor/SuperDBDistributorForm'
import SuperDBDistributor from 'components/pages/UserManagement/SuperDBDistributor'
import UnderDistributorOrders from 'components/pages/underdistributors/UnderDistributorOrders'
import UnderDistributorOrderUpdate from 'components/pages/underdistributors/UnderDistributorOrderUpdate'
import UnderDistributorOrderList from 'components/pages/underdistributors/UnderDistributorOrdersList'
import UnderDistributorOrdersViewDetails from 'components/pages/underdistributors/UnderDistributorOrdersViewDetails'
import AsmUpdateOrders from 'components/pages/ASM/updateOrders'
import SpecialDistributorOrders from 'components/pages/specialdistributors/SpecialDistributorOrders'
import SpecialDistributorOrderUpdate from 'components/pages/specialdistributors/SpecialDistributorOrderUpdate'
import SpecialDistributorOrdersList from 'components/pages/specialdistributors/SpecialDistributorOrdersList'
import SpecialDistributorOrdersViewDetails from 'components/pages/specialdistributors/SpecialDistributorOrdersViewDetails'
import DoPendingOrders from 'components/pages/DoProcess/PendingOrders'
import OrdersListDo from 'components/pages/DoProcess/AllOrders'
import DoUpdateOrders from 'components/pages/DoProcess/UpdateOrders'
import AccountsUpdateOrders from 'components/pages/AccountAndFinance/Accounts/UpdateOrders'
import FinanceUpdateOrders from 'components/pages/AccountAndFinance/Finance/UpdateOrders'
import DsmDistributor from 'components/pages/DSM/Distributor'
import DsmOrdersList from 'components/pages/DSM/Orders'
import DistributorViewDetails from 'components/pages/DistributorViewDetails'
import SuperDistributorOrderListOthers from 'components/pages/superdistributors/SuperDistributorOrdersList/others'
import SuperDistributorOrderUpdateOthers from 'components/pages/superdistributors/SuperDistributorOrderUpdateOthers'
import SchedulingSuperDistributor from 'components/pages/superdistributors/SchedulingSuperDistributor'
import DeliverySuperDistributor from 'components/pages/superdistributors/DeliverySuperDistributor'
import UpdateDeposit from 'components/pages/AccountAndFinance/Deposit/UpdateDeposit/UpdateDeposit'
import DepositsList from 'components/pages/AccountAndFinance/Deposit/DepositList'
import OfferReceivedPanel from 'components/pages/InventoryManagement/OfferReceivedPanel'
import OfferReceivedList from 'components/pages/InventoryManagement/OfferReceivedList'
import StockList from 'components/pages/InventoryManagement/StockList'
import InventoryStockListFilter from 'components/pages/InventoryManagement/InventoryStockListFilter'
import OfferStockList from 'components/pages/InventoryManagement/OfferStockList'
import DistributorOfferReceivedList from 'components/pages/DistributorInventoryManagement/DistributorOfferReceivedList'
import DistributorOfferReceivedPanel from 'components/pages/DistributorInventoryManagement/DistributorOfferReceivedPanel'
import DistributorReceivedList from 'components/pages/DistributorInventoryManagement/DistributorReceivedList'
import DistributorReceivedPanel from 'components/pages/DistributorInventoryManagement/DistributorReceivedPanel'
import SuperDistributorOrdersPendingOthers from 'components/pages/superdistributors/SuperDistributorOrdersList/othersPending'
import OtherDistributorsOrdersUnscheduled from 'components/pages/superdistributors/OtherDistributorsOrdersUnscheduled'
import OthersDistributorsScheduledOrdersList from 'components/pages/superdistributors/OthersDistributorsScheduledOrdersList'
import TotalDeliveriesSDB from 'components/pages/superdistributors/TotalDeliveriesSDB'
import AdminStockList from 'components/pages/AdminInventoryManagement/AdminStockList'
import AdminOfferStockList from 'components/pages/AdminInventoryManagement/AdminOfferStockList'
import DistributorStockList from 'components/pages/DistributorInventoryManagement/DistributorStockList'
import ScheduledOrdersListForApprove from 'components/pages/AccountAndFinance/Finance/ScheduledOrdersListForApprove'
import ApprovedScheduledOrdersList from 'components/pages/AccountAndFinance/Finance/ApprovedScheduledOrdersList'
import GenerateFormat from 'components/pages/GenerateFormat/Index'
import FormatCsv from 'components/pages/Target/FormatCsv'
import UploadTarget from 'components/pages/Target/UploadTarget'
import OrderFilterList from 'components/pages/AccountAndFinance/Finance/OrdersList/OrderFilterList'
import SalesOrderFilterAll from 'components/pages/DoProcess/UpdateOrders/SalesOrderFilter'
import TargetvsAchivementMonthly from 'components/pages/Reports/TargetvsAchivementMonthly/index'
import SuperDBTargetvsAchivementMonthly from 'components/pages/Reports/SuperDbTargetvsAchivementMonthly/index'
import OffersScheduling from 'components/pages/AdminDistribution/OffersScheduling'
import AsmTargetvsAchivementMonthly from 'components/pages/ASM/TargetvsAchivementMonthly/index'
import RsmTargetvsAchivementMonthly from 'components/pages/RSM/TargetvsAchivementMonthly/index'
import DsmTargetvsAchivementMonthly from 'components/pages/DSM/TargetvsAchivementMonthly/index'
import DbTargetvsAchivementMonthly from 'components/pages/distributors/TargetvsAchivementMonthly/index'
import StockListFilter from 'components/pages/AdminInventoryManagement/AdminStockList/StockListFilter'
import FinanceStockList from 'components/pages/AccountAndFinance/Finance/OrdersList/FinanceStockList'
import DistrbutorStockFilter from 'components/pages/distributors/DistributorOrdersList/DistrbutorStockFilter'
import DivisionalStockList from 'components/pages/DSM/DivisionalStockList'
import SalesStockFilter from 'components/pages/DoProcess/UpdateOrders/SalesStockFilter'
import AsmStockList from 'components/pages/ASM/TargetvsAchivementMonthly/TargetFiles/AsmStockList'
import RsmStockList from 'components/pages/RSM/TargetvsAchivementMonthly/RsmStockList'
import SpDbTargetvsAchivementMonthly from 'components/pages/specialdistributors/TargetvsAchivementMonthly/index'
import UnderDbTargetvsAchivementMonthly from 'components/pages/superdistributors/UnderDbTargetvsAchivementMonthly/index'
import MisStockList from 'components/pages/AdminInventoryManagement/AdminStockList/MisStockList'
import HosStockList from 'components/pages/Reports/TargetvsAchivementMonthly/HosStockList'
import DelevarySearch from 'components/pages/InventoryManagement/ReceivedPanel/DelevarySearch'
import AdminOfferReceivedList from 'components/pages/AdminInventoryManagement/AdminOfferReceivedList'
import TargetDelete from 'components/pages/Target/TargetDelete'
import AdminOfferReceivedListConfirmed from 'components/pages/AdminInventoryManagement/AdminOfferReceivedListConfirmed'
import SubCategory from '../components/pages/master-register/Products/SubCategory'
import AddSubCategory from '../components/pages/master-register/Products/SubCategory/AddSubCategory'
import DistributorList from 'components/pages/distributors/DistributorOrdersList/DistrbutorList'
import OrdersUnconfirmed from 'components/pages/Distribution/Scheduling/OrdersUnconfirmed'
import DepositFormForUpdate from 'components/pages/AccountAndFinance/Deposit/DepositFormForUpdate'
import SpecialDIstributorOrderUpdateOther from 'components/pages/specialdistributors/OrdersUpdateOthers/index.js'
import ApprovedDepositFromSuper from 'components/pages/superdistributors/ApprovedDepositFromSuper'
import ApprovedDepositListFromSuper from 'components/pages/superdistributors/ApprovedDepositListFromSuper'
import DuePaymentListSuper from 'components/pages/superdistributors/PaymentAdjustmentSuper/DuePaymentListSuper'
import AdjustmentOrdersListSuper from 'components/pages/superdistributors/PaymentAdjustmentSuper/AdjustmentOrdersListSuper'
import AdjustmentOrderDetailsSuper from 'components/pages/superdistributors/PaymentAdjustmentSuper/AdjustmentOrderDetailsSuper'
import SalesRepresentative from '../components/pages/UserManagement/Sr'
import SalesRepresentativeForm from '../components/pages/UserManagement/Sr/Form'
import ReportComponentControlForm from 'components/pages/ControlPanel/ReportComponentControlForm/index'
import ReportComponentControlList from 'components/pages/ControlPanel/ReportComponentControlList/index'
import AllDepositListforFinanceAndAccounts from 'components/pages/AccountAndFinance/Deposit/AllDepositListforFinanceAndAccounts'
import DepositSearch from 'components/pages/Deposits/DepositSearch'
import PendingOrderReconciliationList from 'components/pages/AccountAndFinance/Finance/Reconciliation/PendingOrderReconciliationList'
import OrderReconcile from 'components/pages/AccountAndFinance/Finance/Reconciliation/OrderReconcile'
import DBReconciliation from 'components/pages/DBReconciliation/index'
import OrderPaymentApprove from 'components/pages/AccountAndFinance/Finance/OrderPaymentApprove';
import SchedulePaymentApprove from 'components/pages/AccountAndFinance/Finance/SchedulePaymentApprove';
import DbReconcilicationReport from 'components/pages/DBReconciliation/Report'
import SpecialCreditDB from 'components/component/ReportFormat/SpecialCreditDB/index';
import SpecialCreditDbUpload from 'components/pages/SpecialCreditDB/Upload';
import ProductionRecived from "components/pages/Production/ProductionPanel/index";
import ProductionRecivedList from "components/pages/Production/ProductionList/index";
import PurchaseListAdmin from '../components/pages/InventoryManagement/PurchaseList/PurchaseListAdmin'
import DepositFormSuperDB from '../components/pages/Deposits/DepositFormSuperDB'
import SrRoutes from 'components/pages/master-register/SrRoutes'
import SalesRepresentativeRoutesForm from 'components/pages/master-register/SrRoutes/From'
import SalesRepresentativeRoutes from 'components/pages/master-register/SrRoutes'
import ScmPurchaseCreate from 'components/pages/SCM/PurchaseCreate/Index'
import ScmPurchaseList from 'components/pages/SCM/PurchaseList/Index'
import PurchaseDetails from 'components/pages/InventoryManagement/PurchasePanel/PurchaseDetails'
import PurchaseUpdate from 'components/pages/SCM/PurchaseUpdate/PurchaseDetails'
import ScmPurchaseConfirmList from 'components/pages/SCM/PurchaseList/PurchaseConfirmList'
import ProductionConfirmList from 'components/pages/InventoryManagement/ProductionConfirmList'
import AdminReceivedList from 'components/pages/Production/ProductionList/AdminList'
import InventoryStockListFilterDateWise from 'components/pages/InventoryManagement/InventoryStockListFilter/InventoryStockDateWise'
import TransferDetails from 'components/pages/InventoryManagement/TransferDetails/index'
import ProductionSlip from 'components/pages/Production/ProductionPanel/ProductionSlip'
import ProductionDetailsView from 'components/pages/Production/Details';
import ProductionPendingList from 'components/pages/Production/ProductionList/PendingList'
import DeliveryLoadSheet from '../components/pages/InventoryManagement/DeliveryLoadSheet'
import LoadSheetPrint from '../components/pages/InventoryManagement/DeliveryLoadSheet/LoadSheetPrint'
import RoutesCreateForm from 'components/pages/master-register/RouteCreate/CreateForm';
import RoutesCreate from 'components/pages/master-register/RouteCreate/index';

const FalconRoutes = ({ isAuthenticated, user }) => {
    const userType = localStorage.getItem('userType') || 'user'
    const userRole = user?.data?.userRole || ''
    const superDBId = user?.data?.superDBId

    let routes = (

        <Routes>
            {/*- ------------- system user ---------------------------  */}
            <Route path="authentication/user" element={<CardLogin />} />
            {/*- ------------- sales representative ---------------------------  */}
            <Route path="authentication/sr" element={<SimpleLogin />} />
            {/*- ------------- distributor ---------------------------  */}
            <Route path="authentication/db" element={<SplitLogin />} />

            // Login Route for different user types

            <Route
                path="*"
                element={<Navigate to="authentication/user" replace />}
            />

        </Routes>
    )

    if (isAuthenticated) {
        routes = (
            <Routes>
                <Route path="landing" element={<Landing />} />
                <Route element={<ErrorLayout />}>
                    <Route path="errors/404" element={<Error404 />} />
                    <Route path="errors/500" element={<Error500 />} />
                </Route>
                {/*- ------------- Authentication ---------------------------  */}

                {/*- ------------- simple ---------------------------  */}
                <Route path="authentication/sr" element={<SimpleLogin />} />
                <Route element={<AuthSimpleLayout />}>
                    <Route
                        path="authentication/simple/register"
                        element={<SimpleRegistration />}
                    />
                    <Route
                        path="authentication/simple/logout"
                        element={<SimpleLogout />}
                    />
                    <Route
                        path="authentication/simple/forgot-password"
                        element={<SimpleForgetPassword />}
                    />
                    <Route
                        path="authentication/simple/reset-password"
                        element={<SimplePasswordReset />}
                    />
                    <Route
                        path="authentication/simple/confirm-mail"
                        element={<SimpleConfirmMail />}
                    />
                    <Route
                        path="authentication/simple/lock-screen"
                        element={<SimpleLockScreen />}
                    />
                </Route>

                {/*- ------------- Card ---------------------------  */}
                <Route path="authentication/user" element={<CardLogin />} />
                <Route
                    path="authentication/card/register"
                    element={<CardRegistration />}
                />
                <Route path="authentication/card/logout" element={<Logout />} />
                <Route
                    path="authentication/card/forgot-password"
                    element={<CardForgetPassword />}
                />
                <Route
                    path="authentication/card/reset-password"
                    element={<CardPasswordReset />}
                />
                <Route
                    path="authentication/card/confirm-mail"
                    element={<CardConfirmMail />}
                />
                <Route
                    path="authentication/card/lock-screen"
                    element={<CardLockScreen />}
                />

                {/*- ------------- Split ---------------------------  */}

                <Route path="authentication/db" element={<SplitLogin />} />
                <Route
                    path="authentication/split/logout"
                    element={<SplitLogout />}
                />
                <Route
                    path="authentication/split/register"
                    element={<SplitRegistration />}
                />
                <Route
                    path="authentication/split/forgot-password"
                    element={<SplitForgetPassword />}
                />
                <Route
                    path="authentication/split/reset-password"
                    element={<SplitPasswordReset />}
                />
                <Route
                    path="authentication/split/confirm-mail"
                    element={<SplitConfirmMail />}
                />
                <Route
                    path="authentication/split/lock-screen"
                    element={<SplitLockScreen />}
                />

                <Route element={<WizardAuth />}>
                    <Route
                        path="authentication/wizard"
                        element={<Wizard validation={true} />}
                    />
                </Route>

                {/* //--- MainLayout Starts  */}

                <Route element={<MainLayout />}>
                    {/*Dashboard*/}
                    <Route
                        path="/"
                        element={
                            userRole == 'Inventory' ? (
                                <Inventory />
                            ) : userRole == 'Distribution' ? (
                                <SchedulingDashboard />
                            ) : (
                                <Dashboard />
                            )
                        }
                    />
                    <Route path="dashboard/analytics" element={<Analytics />} />
                    <Route path="dashboard/crm" element={<Crm />} />
                    <Route path="dashboard/saas" element={<Saas />} />
                    <Route
                        path="dashboard/e-commerce"
                        element={<Ecommerce />}
                    />
                    <Route path="dashboard/lms" element={<Lms />} />
                    <Route
                        path="dashboard/project-management"
                        element={<ProjectManagement />}
                    />
                    <Route
                        path="dashboard/support-desk"
                        element={<SupportDesk />}
                    />
                    {/* E Commerce */}
                    <Route
                        path="e-commerce/orders/order-details"
                        element={<OrderDetails />}
                    />
                    <Route
                        path="e-commerce/orders/order-list"
                        element={<Orders />}
                    />
                    <Route path="e-commerce/invoice" element={<Invoice />} />
                    <Route path="e-commerce/billing" element={<Billing />} />
                    <Route path="e-commerce/checkout" element={<Checkout />} />
                    <Route
                        path="e-commerce/shopping-cart"
                        element={<ShoppingCart />}
                    />
                    <Route
                        path="e-commerce/customers"
                        element={<Customers />}
                    />
                    <Route path="master/salesorg" element={<SalesOrgs />} />
                    <Route
                        path="master/salesorg/add/:updateID?"
                        element={<SalesOrgForm />}
                    />
                    <Route
                        path="master/salesOfficer"
                        element={<SalesOfficer />}
                    ></Route>
                    <Route path="master/bank" element={<Banks />} />
                    <Route path="master/vendor" element={<Vendors />} />
                    <Route
                        path="master/bank/add/:updateID?"
                        element={<BankForm />}
                    />
                    \<Route
                        path="master/vendor/add/:updateID?"
                        element={<VendorForm />}
                    />
                    <Route path="master/route" element={<PustiRoutes />} />
                    <Route
                        path="master/route/add/:updateID?"
                        element={<RouteForm />}
                    />
                    <Route path="master/route/map" element={<RouteMap />} />
                    <Route path="authentication/logout" element={<Logout />} />
                    <Route
                        path="organogram/divisionlhead"
                        element={<DivisionalHead />}
                    />
                    <Route
                        path="/master/approvalCommittee"
                        element={<ApprovalCommittee />}
                    />
                    <Route
                        path="master/officenotice"
                        element={<OfficeNotice />}
                    />
                    <Route
                        path="master/officenotice/add/:updateID?"
                        element={<NoticeForm />}
                    />
                    <Route
                        path="master/distributorsalesrepresentative"
                        element={<DistributorSalesResentative />}
                    />
                    <Route
                        path="target/timePass/form/:updateID?"
                        element={<TimePassForm />}
                    />
                    <Route path="master/employee" element={<Employee />} />
                    <Route
                        path="master/employee/add/:updateID?"
                        element={<AddEmployee />}
                    />
                    <Route
                        path="primaryOrderProcess"
                        element={<PrimaryOrderProcess />}
                    />
                    <Route
                        path="primaryOrderProcess/add/:updateID?"
                        element={<AddPrimaryOrderProcess />}
                    />
                    <Route path="manipulate" element={<Manipulated />} />
                    <Route
                        path="depot/add/:updateID?"
                        element={<DepotForm />}
                    ></Route>

                    {/* ............................Distribution and Inventory Routes: START........................... */}

                    {userRole === 'Distribution' && (
                        <>

                            <Route
                                path="distribution/productscheduling/pending"
                                element={<OrdersUnscheduled />}
                            />
                            <Route
                                path="distribution/productscheduling/not-confirmed"
                                element={<OrdersUnconfirmed />}
                            />
                            <Route
                                path="distribution/productscheduling/done"
                                element={<ScheduledOrdersList />}
                            />
                            <Route
                                path="distribution/productscheduling/update"
                                element={<SchedulingUpdate />}
                            />
                            <Route
                                path="distribution/productscheduling/details/:scheduleNo/:dealerId"
                                element={<ScheduleDetails />}
                            />
                            <Route
                                path="distribution/scheduling"
                                element={<Scheduling />}
                            />

                        </>
                    )}

                    {userRole === 'Inventory' && (
                        <>
                            <Route
                                path="distribution/productdelivery-todo"
                                element={<Delivery />}
                            />
                            <Route
                                path="distribution/productdelivery/pending"
                                element={<PendingDeliveries />}
                            />
                            <Route
                                path="distribution/productdelivery/update"
                                element={<DeliveryUpdate />}
                            />
                            <Route
                                path="distribution/delivery/load-sheet/:challanNo/:dealerId"
                                element={<LoadSheetPrint />}
                            />
                            <Route
                                path="distribution/productdelivery/details/:challanNo/:dealerId"
                                element={<DeliveryDetails />}
                            />
                            {/* <Route path="distribution/productdelivery/details/:challanNo" element={<DeliveryDetails />} /> */}
                            <Route
                                path="inventory/product/stock"
                                element={<ReceivedStocks />}
                            />
                            <Route
                                path="inventory/product/stock/available"
                                element={<AvailableStocksList />}
                            />
                            <Route
                                path="inventory/delivery/total"
                                element={<TotalDeliveries />}
                            />
                            <Route
                                path="/inventory/delivery/load-sheet"
                                element={<DeliveryLoadSheet />}
                            />
                            <Route
                                path="inventory/delivery/search"
                                element={<DelevarySearch />}
                            />

                            <Route
                                path="inventory/products/received/sku"
                                element={<ReceivedStocksDetails />}
                            />

                        </>

                    )}



                    {/* .....................Distribution and Inventory Routes: END.................... */}




                    {/* .......................Super DB Routes Start.............................. */}
                    {userRole === 'SUPER DISTRIBUTOR' && (
                        <>
                            // Super Distributor Order
                            <Route
                                path="master/super-distributor-orders-create"
                                element={<SuperDistributorOrders />}
                            />
                            <Route
                                path="master/super-distributor-orders-update/:doNo"
                                element={<SuperDistributorOrderUpdate />}
                            />
                            <Route
                                path="master/super-distributor-orders-update-others/:doNo"
                                element={<SuperDistributorOrderUpdateOthers />}
                            />
                            <Route
                                path="master/super-distributor-orders"
                                element={<SuperDistributorOrderList />}
                            />
                            <Route
                                path="master/distributor-orders"
                                element={<DistributorOrderList />}
                            />
                            <Route
                                path="/finance/orders-filter"
                                element={<OrderFilterList />}
                            />
                            <Route
                                path="master/super-distributor-orders-others"
                                element={<SuperDistributorOrderListOthers />}
                            />
                            <Route
                                path="master/super-distributor-orders-others-pending"
                                element={
                                    <SuperDistributorOrdersPendingOthers />
                                }
                            />
                            <Route
                                path="master/super-distributor-orders-view/:doNo"
                                element={<SuperDistributorOrdersViewDetails />}
                            />
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            <Route
                                path="super/due-payment-list-under-sdb"
                                element={<DuePaymentListSuper />}
                            />
                            <Route
                                path="/super/orders/:id"
                                element={<AdjustmentOrdersListSuper />}
                            />
                            <Route
                                path="/super/orders/details/:id"
                                element={<AdjustmentOrderDetailsSuper />}
                            />
                            // Super Distributor Deposit Route
                            <Route
                                path="master/deposit"
                                element={<Deposits />}
                            />
                            <Route
                                path="master/deposit/update/:updateID?"
                                element={<UpdateDeposit />}
                            />
                            <Route
                                path="master/deposit/update-super-db/:updateID?"
                                element={<DepositFormSuperDB />}
                            />
                            <Route
                                path="super/confirm-deposit-under-sdb"
                                element={<ApprovedDepositFromSuper />}
                            />
                            <Route
                                path="super/approved-deposit-list-under-sdb"
                                element={<ApprovedDepositListFromSuper />}
                            />
                            <Route
                                path="master/deposit/add/:updateID?"
                                element={<DepositForm />}
                            />
                            <Route
                                path="user/distributor-under-super"
                                element={<SuperDBDistributor />}
                            />
                            // Super Distributor Inventory Route
                            <Route
                                path="inventory/receivedList-db"
                                element={<DistributorReceivedList />}
                            />
                            <Route
                                path="inventory/receivedPanel-db"
                                element={<DistributorReceivedPanel />}
                            />
                            <Route
                                path="inventory/deliveryList-sdb"
                                element={<TotalDeliveriesSDB />}
                            />
                            <Route
                                path="/inventory/stockList-sdb"
                                element={<DistributorStockList />}
                            />
                            // Super Distributor Scheduling and Delivery
                            <Route
                                path="distribution/scheduling-under-sdb"
                                element={<SchedulingSuperDistributor />}
                            />
                            <Route
                                path="distribution/productscheduling-under-sdb/pending"
                                element={<OtherDistributorsOrdersUnscheduled />}
                            />
                            <Route
                                path="distribution/productscheduling-under-sdb/done"
                                element={
                                    <OthersDistributorsScheduledOrdersList />
                                }
                            />
                            <Route
                                path="distribution/productdelivery-sdb"
                                element={<DeliverySuperDistributor />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/db/target-vs-achievement-monthly"
                                element={<DbTargetvsAchivementMonthly />}
                            />
                            <Route
                                path="reports/under-db/target-vs-achievement-monthly"
                                element={<UnderDbTargetvsAchivementMonthly />}
                            />

                            <Route
                                path="distribution/productscheduling/details/:scheduleNo/:dealerId"
                                element={<ScheduleDetails />}
                            />

                            <Route
                                path="distribution/productdelivery/details/:challanNo/:dealerId"
                                element={<DeliveryDetails />}
                            />
                        </>
                    )}
                    {/* ..........................Super DB Routes End................................ */}




                    {/* ..........................Under Super DB Routes Start.............................. */}
                    {superDBId && (
                        <>
                            // Under Super Distributor Order
                            <Route
                                path="master/under-super-distributor-orders-create"
                                element={<UnderDistributorOrders />}
                            />
                            <Route
                                path="master/under-super-distributor-orders-update/:doNo"
                                element={<UnderDistributorOrderUpdate />}
                            />
                            <Route
                                path="master/under-super-distributor-orders"
                                element={<UnderDistributorOrderList />}
                            />
                            <Route
                                path="master/under-super-distributor-orders-view/:doNo"
                                element={<UnderDistributorOrdersViewDetails />}
                            />
                            // Under Super Distributor Deposit Route
                            <Route
                                path="master/deposit"
                                element={<Deposits />}
                            />
                            <Route
                                path="master/deposit/add/:updateID?"
                                element={<DepositForm />}
                            />
                        </>
                    )}
                    {/* ................................Under Super DB Routes End............................ */}



                    {/* ---------------------------------ADMIN ROUTES START ----------------------------*/}
                    {userRole === 'Admin' && (
                        <>
                            // User Management Routes
                            <Route
                                path="user/systemUser"
                                element={<SystemUser />}
                            />
                            <Route
                                path="user/systemUser/add/:updateID?"
                                element={<SystemUserForm />}
                            />
                            <Route
                                path="user/distributor"
                                element={<Distributor />}
                            />
                            <Route
                                path="user/distributor/add/:updateID?"
                                element={<DistributorForm />}
                            />
                            <Route path='user/sr' element={<SalesRepresentative />} />
                            <Route path='user/sr/add/:updateID?' element={<SalesRepresentativeForm />} />

                            //Master Sr Route
                            <Route path='master/create-route' element={<RoutesCreate />} />
                            <Route path='master/create-route/add/:updateID?' element={<RoutesCreateForm />} />
                            <Route path='master/assign-route' element={<SalesRepresentativeRoutes />} />
                            <Route path='master/assign-route/add/:updateID?' element={<SalesRepresentativeRoutesForm />} />

                            // Master Register Route
                            <Route path="master/zone" element={<Zone />} />
                            <Route
                                path="master/zone/add/:updateID?"
                                element={<ZoneForm />}
                            />
                            <Route path="master/region" element={<Regions />} />
                            <Route
                                path="master/region/add/:updateID?"
                                element={<RegionForm />}
                            />
                            <Route path="master/area" element={<Area />} />
                            <Route
                                path="master/area/add/:updateID?"
                                element={<AreaForm />}
                            />
                            <Route
                                path="master/dbPoint"
                                element={<DbPoint />}
                            />
                            <Route
                                path="master/dbPoint/add/:updateID?"
                                element={<DbPointForm />}
                            />
                            <Route
                                path="master/designation"
                                element={<Designation />}
                            />
                            <Route
                                path="master/designation/add/:updateID?"
                                element={<DesignationForm />}
                            />
                            <Route
                                path="master/userType"
                                element={<UserType />}
                            />
                            <Route
                                path="master/userType/add/:updateID?"
                                element={<UserTypeForm />}
                            />
                            <Route
                                path="master/discountType"
                                element={<DiscountType />}
                            />
                            <Route
                                path="master/discountType/add/:updateID?"
                                element={<DiscountTypeForm />}
                            />
                            <Route
                                path="master/discount"
                                element={<Discount />}
                            />
                            <Route
                                path="master/discount/add/:updateID?"
                                element={<DiscountForm />}
                            />
                            <Route
                                path="master/offerType"
                                element={<OfferType />}
                            />
                            <Route
                                path="master/offerType/add/:updateID?"
                                element={<OfferTypeForm />}
                            />
                            <Route path="master/offer" element={<Offer />} />
                            <Route
                                path="master/offer/add/:updateID?"
                                element={<OfferForm />}
                            />
                            <Route
                                path="master/distributorType"
                                element={<DistributorType />}
                            />
                            <Route
                                path="master/distributorType/add/:updateID?"
                                element={<DistributorTypeForm />}
                            />
                            // Product Route
                            <Route
                                path="master/brand"
                                element={<Brand />}
                            ></Route>
                            <Route
                                path="master/brand/add/:updateID?"
                                element={<BrandForm />}
                            />
                            <Route
                                path="master/productCategory"
                                element={<ProductCategory />}
                            ></Route>
                            <Route
                                path="master/productCategory/add/:updateID?"
                                element={<AddProductCategory />}
                            />
                            <Route
                                path="master/sub-category"
                                element={<SubCategory />}
                            ></Route>
                            <Route
                                path="master/sub-category/add/:updateID?"
                                element={<AddSubCategory />}
                            />
                            <Route
                                path="master/productCategory"
                                element={<ProductCategory />}
                            ></Route>
                            <Route
                                path="master/product"
                                element={<Product />}
                            ></Route>
                            <Route
                                path="master/product/add/:updateID?"
                                element={<ProductForm />}
                            />
                            <Route
                                path="master/offer-product"
                                element={<OfferProducts />}
                            ></Route>
                            <Route
                                path="master/offer-product/add/:updateID?"
                                element={<OfferProductsForm />}
                            />
                            //order management Search
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            {/* <Route
                                path="master/distributor-orders-filter"
                                element={<DistributorOrderList />}
                            /> */}
                            <Route
                                path="/finance/orders-filter"
                                element={<OrderFilterList />}
                            />
                            <Route
                                path="inventory/delivery/search"
                                element={<DelevarySearch />}
                            />
                            <Route
                                path="db-reconciliation"
                                element={<DBReconciliation />}
                            />
                            // Inventory Route
                            <Route
                                path="/inventory/stockList-admin"
                                element={<AdminStockList />}
                            />
                            <Route
                                path="/inventory/stockListFilter-admin"
                                element={<StockListFilter />}
                            />
                            <Route
                                path="/inventory/offer-stockList-admin"
                                element={<AdminOfferStockList />}
                            />
                            <Route
                                path="/inventory/offer-receiveList-admin"
                                element={<AdminOfferReceivedList />}
                            />
                            <Route
                                path="/inventory/offer-receiveList-admin-got"
                                element={<AdminOfferReceivedListConfirmed />}
                            />
                            <Route
                                path="/inventory/purchaseList"
                                element={<PurchaseList />}
                            />
                            <Route
                                path="/inventory/purchaseList-admin"
                                element={<PurchaseListAdmin />}
                            />
                            // Formate
                            <Route
                                path="/generate-format"
                                element={<GenerateFormat />}
                            />
                            <Route
                                path="/accounts/adjustment"
                                element={<PaymentAdjustment2 />}
                            />
                            <Route
                                path="distribution/offers"
                                element={<OffersScheduling />}
                            />

                            // Due Payment
                            <Route
                                path="/accounts/due-payment-list"
                                element={<DuePaymentList />}
                            />
                            <Route
                                path="/accounts/orders/:id"
                                element={<AdjustmentOrdersList />}
                            />
                            <Route
                                path="/accounts/orders/details/:id"
                                element={<AdjustmentOrderDetails />}
                            />

                             // Production
                            <Route
                                path="/production/production-list-admin"
                                element={<AdminReceivedList />}
                            />
                            // Target Delete
                            <Route
                                path="admin/target-delete"
                                element={<TargetDelete />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/target-vs-achievement-monthly"
                                element={<TargetvsAchivementMonthly />}
                            />
                            <Route
                                path="reports/super-db/target-vs-achievement-monthly"
                                element={<SuperDBTargetvsAchivementMonthly />}
                            />

                             //DB Reconciliation
                            <Route path='db-reconciliation-report' element={<DbReconcilicationReport />} />

                            // Control Panel
                            <Route path='report-component-control-form/:updateID?' element={<ReportComponentControlForm />} />
                            <Route path='report-component-control-list' element={<ReportComponentControlList />} />

                            // Report
                            <Route path='/special-credit-db-format' element={<SpecialCreditDB />} />
                            <Route path='/special-credit-db-upload' element={<SpecialCreditDbUpload />} />

                        </>
                    )}
                    {/* ---------------------------------ADMIN ROUTES END ----------------------------*/}


                    {/* ---------------------------------MIS ROUTES START ----------------------------*/}
                    {userRole === 'MIS' && (
                        <>
                            // User Management Route
                            <Route
                                path="user/systemUser"
                                element={<SystemUser />}
                            />
                            <Route
                                path="user/systemUser/add/:updateID?"
                                element={<SystemUserForm />}
                            />
                            <Route
                                path="user/distributor"
                                element={<Distributor />}
                            />
                            <Route
                                path="user/distributor/add/:updateID?"
                                element={<DistributorForm />}
                            />
                            // Master Register Route
                            <Route path="master/zone" element={<Zone />} />
                            <Route
                                path="master/zone/add/:updateID?"
                                element={<ZoneForm />}
                            />
                            <Route path="master/region" element={<Regions />} />
                            <Route
                                path="master/region/add/:updateID?"
                                element={<RegionForm />}
                            />
                            <Route path="master/area" element={<Area />} />
                            <Route
                                path="master/area/add/:updateID?"
                                element={<AreaForm />}
                            />
                            <Route
                                path="master/dbPoint"
                                element={<DbPoint />}
                            />
                            <Route
                                path="master/dbPoint/add/:updateID?"
                                element={<DbPointForm />}
                            />
                            <Route
                                path="master/designation"
                                element={<Designation />}
                            />
                            <Route
                                path="master/designation/add/:updateID?"
                                element={<DesignationForm />}
                            />
                            <Route
                                path="master/userType"
                                element={<UserType />}
                            />
                            <Route
                                path="master/userType/add/:updateID?"
                                element={<UserTypeForm />}
                            />
                            <Route
                                path="master/discountType"
                                element={<DiscountType />}
                            />
                            <Route
                                path="master/discountType/add/:updateID?"
                                element={<DiscountTypeForm />}
                            />
                            <Route
                                path="master/discount"
                                element={<Discount />}
                            />
                            <Route
                                path="master/discount/add/:updateID?"
                                element={<DiscountForm />}
                            />
                            <Route
                                path="master/offerType"
                                element={<OfferType />}
                            />
                            <Route
                                path="master/offerType/add/:updateID?"
                                element={<OfferTypeForm />}
                            />
                            <Route path="master/offer" element={<Offer />} />
                            <Route
                                path="master/offer/add/:updateID?"
                                element={<OfferForm />}
                            />
                            <Route
                                path="master/distributorType"
                                element={<DistributorType />}
                            />
                            <Route
                                path="master/distributorType/add/:updateID?"
                                element={<DistributorTypeForm />}
                            />
                            // Product Route
                            <Route
                                path="master/brand"
                                element={<Brand />}
                            ></Route>
                            <Route
                                path="master/brand/add/:updateID?"
                                element={<BrandForm />}
                            />
                            <Route
                                path="master/productCategory"
                                element={<ProductCategory />}
                            ></Route>
                            <Route
                                path="master/productCategory/add/:updateID?"
                                element={<AddProductCategory />}
                            />
                            <Route
                                path="master/productCategory"
                                element={<ProductCategory />}
                            ></Route>
                            <Route
                                path="master/productCategory/add/:updateID?"
                                element={<AddProductCategory />}
                            />
                            <Route
                                path="master/product"
                                element={<Product />}
                            ></Route>
                            <Route
                                path="master/product/add/:updateID?"
                                element={<ProductForm />}
                            />
                            <Route
                                path="master/offer-product"
                                element={<OfferProducts />}
                            ></Route>
                            <Route
                                path="master/offer-product/add/:updateID?"
                                element={<OfferProductsForm />}
                            />
                            //order management Search
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            {/* <Route
                                path="master/distributor-orders-filter"
                                element={<DistributorOrderList />}
                            /> */}
                            <Route
                                path="/finance/orders-filter"
                                element={<OrderFilterList />}
                            />
                            // Inventory Route
                            <Route
                                path="/inventory/stockList-admin"
                                element={<AdminStockList />}
                            />
                            <Route
                                path="/inventory/offer-stockList-admin"
                                element={<AdminOfferStockList />}
                            />
                            //MIS
                            <Route
                                path="/inventory/stockList-filter-admin"
                                element={<MisStockList />}
                            />
                            // Formate
                            <Route
                                path="/generate-format"
                                element={<GenerateFormat />}
                            />
                            <Route
                                path="/accounts/adjustment"
                                element={<PaymentAdjustment2 />}
                            />
                            <Route
                                path="distribution/offers"
                                element={<OffersScheduling />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/target-vs-achievement-monthly"
                                element={<TargetvsAchivementMonthly />}
                            />
                            <Route
                                path="reports/super-db/target-vs-achievement-monthly"
                                element={<SuperDBTargetvsAchivementMonthly />}
                            />
                        </>
                    )}
                    {/* ---------------------------------MIS ROUTES END ----------------------------*/}


                    {/* ....................... GENERAL,COMMISSION,WHOLESALE,SPOT DISTRIBUTOR START...................... */}
                    {(userRole === 'GENERAL DISTRIBUTOR' ||
                        userRole === 'COMMISSION DISTRIBUTOR' ||
                        userRole === 'WHOLESALE DISTRIBUTOR' ||
                        userRole === 'SPOT DISTRIBUTOR') && (
                            <>
                            // Distributor Order
                                <Route
                                    path="master/distributor-orders-create"
                                    element={<DistributorOrders />}
                                />
                                <Route
                                    path="master/distributor-orders-update/:doNo"
                                    element={<DistributorOrderUpdate />}
                                />
                                <Route
                                    path="master/distributor-orders"
                                    element={<DistributorOrderList />}
                                />
                                <Route
                                    path="master/distributor-orders-list"
                                    element={<DistributorList />}
                                />
                                <Route
                                    path="master/distributor-stock-list"
                                    element={<DistrbutorStockFilter />}
                                />
                                <Route
                                    path="master/distributor-orders-view/:doNo"
                                    element={<DistributorOrdersViewDetails />}
                                />
                            // Deposit Route
                                <Route
                                    path="master/deposit"
                                    element={<Deposits />}
                                />
                                <Route
                                    path="master/deposit/add/:updateID?"
                                    element={<DepositForm />}
                                />
                            //outlate delevary
                                {/* <Route path="master/distributor-delevary-create" element={<DelevaryOutlet />} />
                <Route path='master/delevary/add/:updateID?' element={<DelevaryForm />} />
                <Route path="master/distributor-delevary-list" element={<DelevaryList />} /> */}
                            // Target Vs Achievement Monthly Report
                                <Route
                                    path="reports/db/target-vs-achievement-monthly"
                                    element={<DbTargetvsAchivementMonthly />}
                                />
                            </>
                        )}
                    {/* ....................... GENERAL,COMMISSION,WHOLESALE,SPOT DISTRIBUTOR END........................ */}


                    {/* ......................... SPECIAL DISTRIBUTOR START..................... */}
                    {userRole === 'SPECIAL DISTRIBUTOR' && (
                        <>
                            // Distributor Order
                            <Route
                                path="master/special-distributor-orders-create"
                                element={<SpecialDistributorOrders />}
                            />
                            <Route
                                path="master/special-distributor-orders-update/:doNo"
                                element={<SpecialDistributorOrderUpdate />}
                            />
                            <Route
                                path="master/special-distributor-orders"
                                element={<SpecialDistributorOrdersList />}
                            />
                            <Route
                                path="master/special-distributor-orders-view/:doNo"
                                element={
                                    <SpecialDistributorOrdersViewDetails />
                                }
                            />
                            // Deposit Route
                            <Route
                                path="master/deposit"
                                element={<Deposits />}
                            />
                            <Route
                                path="master/deposit/add/:updateID?"
                                element={<DepositForm />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/sp-db/target-vs-achievement-monthly"
                                element={<SpDbTargetvsAchivementMonthly />}
                            />
                        </>
                    )}
                    {/* ......................... SPECIAL DISTRIBUTOR END..................... */}

                    {/* .............................  ASM START ................................... */}
                    {userRole === 'ASM' && (
                        <>
                            // ASM
                            <Route
                                path="user/distributor"
                                element={<Distributor />}
                            />
                            <Route
                                path="master/distributor-orders-pending"
                                element={<DistributorOrdersPending />}
                            />
                            <Route
                                path="master/db-order-update-asm/:doNo"
                                element={<AsmUpdateOrders />}
                            />
                            <Route
                                path="master/distributor-all-orders"
                                element={<DistributorAllOrdersList />}
                            />
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            <Route
                                path="master/special-distributor-orders-update/:doNo"
                                element={<SpecialDistributorOrderUpdate />}
                            />
                            <Route
                                path="master/special-distributor-orders-update-other/:doNo"
                                element={<SpecialDIstributorOrderUpdateOther />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/asm/target-vs-achievement-monthly"
                                element={<AsmTargetvsAchivementMonthly />}
                            />
                            <Route
                                path="all-factory-stock-list"
                                element={<AsmStockList />}
                            />
                        </>
                    )}
                    {/* .............................  ASM END ................................... */}

                    {/* .............................  RSM START ................................... */}
                    {userRole === 'RSM' && (
                        <>
                            // RSM
                            <Route
                                path="user/distributor"
                                element={<Distributor />}
                            />
                            <Route
                                path="master/db-orders-authorized"
                                element={<AuthorizedOrders />}
                            />
                            <Route
                                path="master/db-all-orders"
                                element={<OrdersList />}
                            />
                            <Route
                                path="master/db-order-update-rsm/:doNo"
                                element={<OrdersUpdate />}
                            />
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            <Route
                                path="master/special-distributor-orders-update/:doNo"
                                element={<SpecialDistributorOrderUpdate />}
                            />
                            <Route
                                path="master/special-distributor-orders-update-other/:doNo"
                                element={<SpecialDIstributorOrderUpdateOther />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/rsm/target-vs-achievement-monthly"
                                element={<RsmTargetvsAchivementMonthly />}
                            />
                            <Route
                                path="factory-stock-list"
                                element={<RsmStockList />}
                            />
                        </>
                    )}
                    {/* .............................  RSM END ................................... */}

                    {/* .............................  SALES ADMIN START ................................... */}
                    {userRole === 'Sales Admin' && (
                        <>
                            // Sales Admin
                            <Route
                                path="master/deposit/add/:updateID?"
                                element={<DepositForm />}
                            />
                            <Route
                                path="master/do-pending-orders"
                                element={<DoPendingOrders />}
                            />
                            <Route
                                path="master/do-all-orders"
                                element={<OrdersListDo />}
                            />
                            <Route
                                path="master/do-all-orders-filter"
                                element={<SalesOrderFilterAll />}
                            />
                            <Route
                                path="master/do-all-stock-filter"
                                element={<SalesStockFilter />}
                            />
                            <Route
                                path="master/db-order-update-do/:doNo"
                                element={<DoUpdateOrders />}
                            />
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            <Route
                                path="master/special-distributor-orders-update/:doNo"
                                element={<SpecialDistributorOrderUpdate />}
                            />
                            <Route
                                path="master/special-distributor-orders-update-other/:doNo"
                                element={<SpecialDIstributorOrderUpdateOther />}
                            />
                            <Route
                                path="master/deposit-search"
                                element={<DepositSearch />}
                            />

                        </>
                    )}
                    {/* .............................  SALES ADMIN END ................................... */}

                    {/* .............................  DISTRIBUTION START ................................... */}
                    {userRole === 'Distribution' && (
                        <>
                            <Route
                                path="/productdelivery"
                                element={<ProductDelivery />}
                            />
                        </>
                    )}
                    {/* .............................  DISTRIBUTION END ................................... */}

                    {/* .............................  INVENTORY START ................................... */}
                    {userRole === 'Inventory' && (
                        <>
                            // Inventory Management Route
                            <Route
                                path="/production/receivedList"
                                element={<ProductionRecivedList />}
                            />

                            <Route
                                path="/inventory/receivedPanel/:slipNo"
                                element={<ReceivedPanel />}
                            />
                            <Route
                                path="/inventory/transfer/receivedPanel/:challanNo"
                                element={<TransferReceivedPanel />}
                            />
                            <Route
                                path="/production/received-confirm"
                                element={<ProductionConfirmList />}
                            />
                            <Route
                                path="/inventory/transfer/detailsPanel/:factory/:date/:status/:InOut"
                                element={<DetailsPanel />}
                            />
                            <Route
                                path="/inventory/transfer/details/:challanNo"
                                element={<TransferDetails />}
                            />
                            <Route
                                path="/inventory/transferPanel"
                                element={<TransferPanel />}
                            />
                            <Route
                                path="/inventory/purchasePanel"
                                element={<PurchasePanel />}
                            />
                            <Route
                                path="/inventory/purchase/:poNo"
                                element={<PurchaseDetails />}
                            />
                            <Route
                                path="/inventory/receivedList"
                                element={<ReceivedList />}
                            />
                            <Route
                                path="/inventory/purchaseList"
                                element={<PurchaseList />}
                            />

                            <Route
                                path="/inventory/returnPanel"
                                element={<ReturnPanel />}
                            />

                            <Route
                                path="/inventory/returnList"
                                element={<ReturnList />}
                            />
                            <Route
                                path="/inventory/transfer/receivedList"
                                element={<TransferReceivedList />}
                            />

                            <Route
                                path="/inventory/transfer/receivedList-confirmed"
                                element={<TransferReceivedListConfirmed />}
                            />


                            <Route
                                path="/inventory/transfer/confirmedList"
                                element={<TransferConfirmedList />}
                            />

                            <Route
                                path="/inventory/transfer/pendingList"
                                element={<TransferPendingList />}
                            />

                            <Route
                                path="/inventory/stockList"
                                element={<StockList />}
                            />

                            <Route
                                path="/inventory/stockListFilter"
                                element={<InventoryStockListFilter />}
                            />
                            <Route
                                path="/inventory/stockListFilter-date-wise"
                                element={<InventoryStockListFilterDateWise />}
                            />

                            <Route
                                path="/inventory/offer-stockList"
                                element={<OfferStockList />}
                            />
                            <Route
                                path="/inventory/offer-product-receivedPanel"
                                element={<OfferReceivedPanel />}
                            />
                            <Route
                                path="/inventory/offer-product-receivedList"
                                element={<OfferReceivedList />}
                            />

                            // SCM Management Route
                            <Route
                                path="/scm/purchase-create"
                                element={<ScmPurchaseCreate />}
                            />
                            <Route
                                path="/scm/purchase-pending"
                                element={<ScmPurchaseList />}
                            />
                            <Route
                                path="/scm/purchase-confirm"
                                element={<ScmPurchaseConfirmList />}
                            />
                            <Route
                                path="/scm/purchase-update/:poNo"
                                element={<PurchaseUpdate />}
                            />
                        </>
                    )}

                    {/* .............................  INVENTORY END ................................... */}


                    {/* .............................  Production START ................................... */}
                    {userRole === 'Production' && (
                        <>
                            // Production Management Route

                            <Route
                                path="/production/receivedPanel"
                                element={<ProductionRecived />}
                            />
                            <Route
                                path="/production/pendingList"
                                element={<ProductionPendingList />}
                            />
                            <Route
                                path="/production/receivedList"
                                element={<ProductionRecivedList />}
                            />

                            <Route
                                path="/inventory/receivedList"
                                element={<ReceivedList />}
                            />

                            <Route
                                path="/shipment/details/:slipNo"
                                element={<ProductionDetailsView />}
                            />
                            <Route
                                path="/inventory/slip/:slipNo"
                                element={<ProductionSlip />}
                            />
                            <Route
                                path="/production/received-confirm"
                                element={<ProductionConfirmList />}
                            />

                        </>
                    )}

                    {/* .............................  Production END ................................... */}

                    {/* .............................  ACCOUNTS START ................................... */}
                    {userRole === 'Accounts' && (
                        <>
                            // Accounts & Finance Route
                            <Route
                                path="/accounts/due-payment-list"
                                element={<DuePaymentList />}
                            />
                            <Route
                                path="/accounts/orders/:id"
                                element={<AdjustmentOrdersList />}
                            />
                            <Route
                                path="/accounts/orders/details/:id"
                                element={<AdjustmentOrderDetails />}
                            />
                            <Route
                                path="/accounts/adjustment"
                                element={<PaymentAdjustment2 />}
                            />
                            <Route
                                path="/accounts/confirm-deposit"
                                element={<ConfirmDeposit />}
                            />
                            // junior Accounts Route
                            {/* <Route
                                path="/accounts/confirm-orders"
                                element={<ConfirmOrders />}
                            /> */}
                            {/* <Route
                                path="/accounts/orders"
                                element={<JrAccountsOrdersList />}
                            /> */}
                            <Route
                                path="master/db-order-update-accounts/:doNo"
                                element={<AccountsUpdateOrders />}
                            />
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            <Route
                                path="master/special-distributor-orders-update/:doNo"
                                element={<SpecialDistributorOrderUpdate />}
                            />
                            <Route
                                path="master/special-distributor-orders-update-other/:doNo"
                                element={<SpecialDIstributorOrderUpdateOther />}
                            />
                            //order management Search
                            {/* <Route
                                path="master/distributor-orders-filter"
                                element={<DistributorOrderList />}
                            /> */}
                            <Route
                                path="/finance/orders-filter"
                                element={<OrderFilterList />}
                            />
                            // deposit update
                            <Route
                                path="master/deposit/update/:updateID?"
                                element={<UpdateDeposit />}
                            />
                            <Route
                                path="master/deposits"
                                element={<DepositsList />}
                            />
                            <Route
                                path="master/deposits-all"
                                element={<AllDepositListforFinanceAndAccounts />}
                            />
                            {/* for updating from other than distributor Panel */}
                            <Route
                                path="master/deposit/edit/:updateID?"
                                element={<DepositFormForUpdate />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/target-vs-achievement-monthly"
                                element={<TargetvsAchivementMonthly />}
                            />
                        </>
                    )}
                    {/* .............................  ACCOUNTS END ................................... */}

                    {/* .............................  Order Management START ................................... */}
                    {userRole === 'Order Management' && (
                        <>
                            // Accounts & Finance Route
                            <Route
                                path="/accounts/due-payment-list"
                                element={<DuePaymentList />}
                            />
                            <Route
                                path="/accounts/orders/:id"
                                element={<AdjustmentOrdersList />}
                            />
                            <Route
                                path="/accounts/orders/details/:id"
                                element={<AdjustmentOrderDetails />}
                            />
                            <Route
                                path="/accounts/confirm-deposit"
                                element={<ConfirmDeposit />}
                            />
                            <Route
                                path="master/deposits"
                                element={<DepositsList />}
                            />
                            <Route
                                path="master/deposits-all"
                                element={<AllDepositListforFinanceAndAccounts />}
                            />
                            // junior Accounts Route
                            <Route
                                path="/accounts/confirm-orders"
                                element={<ConfirmOrders />}
                            />
                            <Route
                                path="/accounts/orders"
                                element={<JrAccountsOrdersList />}
                            />
                            <Route
                                path="master/db-order-update-accounts/:doNo"
                                element={<AccountsUpdateOrders />}
                            />
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            <Route
                                path="master/special-distributor-orders-update/:doNo"
                                element={<SpecialDistributorOrderUpdate />}
                            />
                            <Route
                                path="master/special-distributor-orders-update-other/:doNo"
                                element={<SpecialDIstributorOrderUpdateOther />}
                            />
                            //order management Search

                            <Route
                                path="/finance/orders-filter"
                                element={<OrderFilterList />}
                            />
                            // deposit update
                            <Route
                                path="master/deposit/update/:updateID?"
                                element={<UpdateDeposit />}
                            />
                            <Route
                                path="master/deposits"
                                element={<DepositsList />}
                            />
                            <Route
                                path="master/deposits-all"
                                element={<AllDepositListforFinanceAndAccounts />}
                            />
                            {/* for updating from other than distributor Panel */}
                            <Route
                                path="master/deposit/edit/:updateID?"
                                element={<DepositFormForUpdate />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/target-vs-achievement-monthly"
                                element={<TargetvsAchivementMonthly />}
                            />
                        </>
                    )}
                    {/* .............................  ACCOUNTS END ................................... */}

                    {/* .............................  FINANCE START ................................... */}
                    {userRole === 'Finance' && (
                        <>
                            <Route
                                path="master/deposit/add/:updateID?"
                                element={<DepositForm />}
                            />
                            <Route
                                path="distribution/productscheduling/details/:scheduleNo/:dealerId"
                                element={<ScheduleDetails />}
                            />
                            // Accounts & Finance Route
                            <Route
                                path="/accounts/due-payment-list"
                                element={<DuePaymentList />}
                            />
                            <Route
                                path="/accounts/orders/:id"
                                element={<AdjustmentOrdersList />}
                            />
                            <Route
                                path="/accounts/orders/details/:id"
                                element={<AdjustmentOrderDetails />}
                            />
                            <Route
                                path="/accounts/adjustment"
                                element={<PaymentAdjustment2 />}
                            />
                            <Route
                                path="/accounts/approved-deposit"
                                element={<ApprovedDeposit />}
                            />
                            <Route
                                path="master/special-distributor-orders-update-other/:doNo"
                                element={<SpecialDIstributorOrderUpdateOther />}
                            />
                            // junior Accounts Route
                            <Route
                                path="/accounts/confirm-orders"
                                element={<ConfirmOrders />}
                            />
                            <Route
                                path="/accounts/orders-junior"
                                element={<JrAccountsOrdersList />}
                            />
                            // Senior Accounts Route
                            <Route
                                path="/finance/orders"
                                element={<ApprovedOrders />}
                            />
                            <Route
                                path="/finance/orders-list"
                                element={<SrAccountsOrdersList />}
                            />
                            <Route
                                path="/finance/orders-and-payment"
                                element={<OrderPaymentApprove />}
                            />
                            <Route
                                path="/finance/schedule-and-payment"
                                element={<SchedulePaymentApprove />}
                            />
                            <Route
                                path="/finance/orders-filter"
                                element={<OrderFilterList />}
                            />
                            <Route
                                path="/finance/stock-filter"
                                element={<FinanceStockList />}
                            />
                            <Route
                                path="master/db-order-update-finance/:doNo"
                                element={<FinanceUpdateOrders />}
                            />
                            <Route
                                path="master/db-order-reconcile-finance/:doNo?"
                                element={<OrderReconcile />}
                            />
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            <Route
                                path="master/special-distributor-orders-update/:doNo"
                                element={<SpecialDistributorOrderUpdate />}
                            />
                            // deposit update
                            <Route
                                path="master/deposit/update/:updateID?/:orderspayment?"
                                element={<UpdateDeposit />}
                            />
                            <Route
                                path="master/deposits"
                                element={<DepositsList />}
                            />
                            <Route
                                path="master/deposits-all"
                                element={<AllDepositListforFinanceAndAccounts />}
                            />
                            {/* for updating from other than distributor Panel */}
                            <Route
                                path="master/deposit/edit/:updateID?"
                                element={<DepositFormForUpdate />}
                            />
                            <Route
                                path="finance/productscheduling/done"
                                element={<ScheduledOrdersListForApprove />}
                            />
                            <Route
                                path="finance/productscheduling/approved"
                                element={<ApprovedScheduledOrdersList />}
                            />
                            <Route
                                path="finance/reconciliation/pending"
                                element={<PendingOrderReconciliationList />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/target-vs-achievement-monthly"
                                element={<TargetvsAchivementMonthly />}
                            />
                        </>
                    )}
                    {/* .............................  FINANCE END ................................... */}

                    {/* .............................  DSM START ................................... */}
                    {userRole === 'DSM' && (
                        <>
                            // DSM
                            <Route
                                path="user/distributor/dsm"
                                element={<DsmDistributor />}
                            />
                            <Route
                                path="dsm/orders"
                                element={<DsmOrdersList />}
                            />
                            <Route
                                path="dsm/stock-list"
                                element={<DivisionalStockList />}
                            />
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/dsm/target-vs-achievement-monthly"
                                element={<DsmTargetvsAchivementMonthly />}
                            />
                        </>
                    )}
                    {/* .............................  DSM END ................................... */}

                    {/* .............................  HOS START ................................... */}
                    {userRole === 'HOS' && (
                        <>
                            // DSM
                            <Route
                                path="user/distributor"
                                element={<Distributor />}
                            />
                            <Route
                                path="master/discount"
                                element={<Discount />}
                            />
                            <Route path="master/offer" element={<Offer />} />
                            <Route
                                path="master/distributor-orders"
                                element={<DistributorOrderList />}
                            />
                            <Route
                                path="master/distributor-orders-view/:doNo"
                                element={<DistributorOrdersViewDetails />}
                            />
                            // Target Vs Achievement Monthly Report
                            <Route
                                path="reports/target-vs-achievement-monthly"
                                element={<TargetvsAchivementMonthly />}
                            />
                            <Route
                                path="factory-stock-list"
                                element={<HosStockList />}
                            />
                        </>
                    )}
                    {/* .............................  HOS END ................................... */}

                    {/* .............................  COMMON ROUTE START ................................... */}
                    // common routes
                    <Route
                        path="db/profile/:id"
                        element={<DistributorViewDetails />}
                    />
                    //SO/SR
                    <Route path="master/sr" element={<Sosr />} />
                    <Route
                        path="master/sr/add/:updateID?"
                        element={<SrFrom />}
                    />
                    // Pos Service Route
                    <Route
                        path="assignedSalesOfficer"
                        element={<AssignedSalesOfficer />}
                    />
                    <Route
                        path="assignedSalesOfficer/add/:updateID?"
                        element={<AssignedSalesOfficerForm />}
                    />
                    <Route path="outletTypes" element={<OutletType />} />
                    <Route
                        path="outletTypes/add/:updateID?"
                        element={<OutletTypeForm />}
                    />
                    <Route
                        path="master/outletTarget"
                        element={<OutletTarget />}
                    />
                    <Route
                        path="master/outletTarget/add/:updateID?"
                        element={<OutletTargetForm />}
                    />
                    <Route path="outletChannel" element={<OutletChannel />} />
                    <Route
                        path="outletChannel/add/:updateID?"
                        element={<OutletChannelForm />}
                    />
                    <Route path="master/outlet" element={<Outlets />} />
                    <Route
                        path="master/outlet/add/:updateID?"
                        element={<OutletForm />}
                    ></Route>
                    <Route path="master/outlet/map" element={<OutletMap />} />
                    // Storage Service Route
                    <Route path="storageTypes" element={<StorageTypes />} />
                    <Route
                        path="storageTypes/add/:updateID?"
                        element={<StorageTypesForm />}
                    />
                    <Route path="storage" element={<Storage />} />
                    <Route
                        path="storage/add/:updateID?"
                        element={<StorageForm />}
                    />
                    <Route
                        path="factoryManagements"
                        element={<FactoryMangement />}
                    />
                    <Route
                        path="factoryManagement/add/:updateID?"
                        element={<FactoryManagementForm />}
                    ></Route>
                    // Organization Route
                    <Route
                        path="/administrativeDivision"
                        element={<AdministrativeDivision />}
                    />
                    <Route
                        path="/administrativeDivision/add/:updateID?"
                        element={<AdministrativeDivisionForm />}
                    />
                    <Route path="/district" element={<District />} />
                    <Route
                        path="/district/add/:updateID?"
                        element={<DistrictForm />}
                    />
                    <Route path="/upazila" element={<Upazila />} />
                    <Route
                        path="/upazila/add/:updateID?"
                        element={<UpazilaForm />}
                    />
                    <Route
                        path="distributionAssignedArea/add/:updateID?"
                        element={<DistributionAssignedAreaForm />}
                    />
                    // Target Management Route
                    <Route
                        path="/target-format-dowanload"
                        element={<FormatCsv />}
                    />
                    <Route path="/target-upload" element={<UploadTarget />} />
                    // Delivery Approval Routes
                    <Route
                        path="/deliveryApproval"
                        element={<DeliveryApproval />}
                    />
                    <Route
                        path="/deliveryApproval/add/:updateID?"
                        element={<DeliveryApprovalForm />}
                    />
                    <Route
                        path="/approvalActivity"
                        element={<ApprovalActivity />}
                    />
                    <Route
                        path="/approvalActivity/add/:updateID?"
                        element={<ApprovalActivityForm />}
                    />
                    <Route
                        path="/customerCredit"
                        element={<CustomerCredit />}
                    />
                    <Route
                        path="/customerCredit/add/:updateID?"
                        element={<CustomerCreditForm />}
                    />
                    <Route
                        path="/customerAdvancePayment"
                        element={<CustomerAdvancePayment />}
                    />
                    <Route
                        path="/customerAdvancePayment/add/:updateID?"
                        element={<CustomerAdvancePaymentForm />}
                    />
                    <Route
                        path="/paymentsHistory"
                        element={<PaymentsHistory />}
                    />
                    <Route
                        path="/paymentsHistory/add/:updateID?"
                        element={<PaymentsHistoryForm />}
                    />
                    // Settings Routes
                    <Route
                        path="/productPackagingUnit"
                        element={<ProductPackagingUnit />}
                    />
                    <Route
                        path="/productPackagingUnit/add/:updateID?"
                        element={<ProductPackagingUnitForm />}
                    />
                    <Route
                        path="/productSettings"
                        element={<ProductSettings />}
                    />
                    <Route
                        path="/productSettings/add/:updateID?"
                        element={<ProductSettingsForm />}
                    />
                    <Route
                        path="/distributorProductSettings"
                        element={<DistributorProductSettings />}
                    />
                    <Route
                        path="/distributorProductSettings/add/:updateID?"
                        element={<DistributorProductSettingsForm />}
                    />
                    <Route
                        path="/distributorStorageSettings"
                        element={<DistributorStorageSettings />}
                    />
                    <Route
                        path="/distributorStorageSettings/add/:updateID?"
                        element={<DistributorStorageSettingsForm />}
                    />
                    <Route
                        path="/commissionAudience"
                        element={<CommissionAudience />}
                    />
                    <Route
                        path="/commissionAudience/add/:updateID?"
                        element={<CommissionAudienceForm />}
                    />
                    <Route
                        path="e-commerce/customer-details"
                        element={<CustomersDetails />}
                    />
                    <Route
                        path="e-commerce/product/product-details"
                        element={<ProductDetails />}
                    />
                    <Route
                        path="e-commerce/product/product-details/:productId"
                        element={<ProductDetails />}
                    />
                    <Route
                        path="e-commerce/product/:productLayout"
                        element={<Products />}
                    />
                    <Route path="e-commerce/invoice" element={<Invoice />} />
                    {/* E Learning */}
                    <Route
                        path="e-learning/course/:courseLayout"
                        element={<Courses />}
                    />
                    <Route
                        path="e-learning/course/course-details"
                        element={<CourseDetails />}
                    />
                    <Route
                        path="e-learning/course/course-details/:courseId"
                        element={<CourseDetails />}
                    />
                    <Route
                        path="e-learning/course/create-a-course"
                        element={<CreateCourse />}
                    />
                    <Route
                        path="e-learning/trainer-profile"
                        element={<TrainerProfile />}
                    />
                    <Route
                        path="e-learning/student-overview"
                        element={<StudentOverview />}
                    />
                    {/*icons*/}
                    <Route
                        path="icons/font-awesome"
                        element={<FontAwesome />}
                    />
                    <Route path="icons/react-icons" element={<ReactIcons />} />
                    {/* maps */}
                    <Route
                        path="maps/google-map"
                        element={<GoogleMapExample />}
                    />
                    <Route
                        path="maps/leaflet-map"
                        element={<LeafletMapExample />}
                    />
                    {/*App*/}
                    <Route path="app/calendar" element={<Calendar />} />
                    <Route path="app/chat" element={<Chat />} />
                    <Route path="app/kanban" element={<Kanban />} />
                    <Route path="social/feed" element={<Feed />} />
                    <Route
                        path="social/activity-log"
                        element={<ActivityLog />}
                    />
                    <Route
                        path="social/notifications"
                        element={<Notifications />}
                    />
                    <Route path="social/followers" element={<Followers />} />
                    <Route
                        path="events/event-detail"
                        element={<EventDetail />}
                    />
                    <Route
                        path="events/create-an-event"
                        element={<CreateEvent />}
                    />
                    <Route path="events/event-list" element={<EventList />} />
                    {/* Email */}
                    <Route
                        path="email/email-detail"
                        element={<EmailDetail />}
                    />
                    <Route path="email/inbox" element={<Inbox />} />
                    <Route path="email/compose" element={<Compose />} />
                    {/* support desk */}
                    <Route
                        path="/support-desk/table-view"
                        element={<TableView />}
                    />
                    <Route
                        path="/support-desk/card-view"
                        element={<CardView />}
                    />
                    <Route
                        path="/support-desk/contacts"
                        element={<Contacts />}
                    />
                    <Route
                        path="/support-desk/contact-details"
                        element={<ContactDetails />}
                    />
                    <Route
                        path="/support-desk/tickets-preview"
                        element={<TicketsPreview />}
                    />
                    <Route
                        path="/support-desk/quick-links"
                        element={<QuickLinks />}
                    />
                    <Route path="/support-desk/reports" element={<Reports />} />
                    {/*Pages*/}
                    {/* <Route path="pages/starter" element={<Starter />} /> */}
                    <Route path="user/profile" element={<Profile />} />
                    <Route path="user/settings" element={<Settings />} />
                    <Route
                        path="miscellaneous/associations"
                        element={<Associations />}
                    />
                    <Route path="faq/faq-alt" element={<FaqAlt />} />
                    <Route path="faq/faq-basic" element={<FaqBasic />} />
                    <Route
                        path="faq/faq-accordion"
                        element={<FaqAccordion />}
                    />
                    <Route
                        path="pricing/pricing-default"
                        element={<PricingDefault />}
                    />
                    <Route
                        path="pricing/pricing-alt"
                        element={<PricingAlt />}
                    />
                    <Route
                        path="miscellaneous/privacy-policy"
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        path="miscellaneous/invite-people"
                        element={<InvitePeople />}
                    />
                    {/* charts-example */}
                    <Route path="charts/chartjs" element={<Chartjs />} />
                    <Route path="charts/d3js" element={<D3js />} />
                    <Route
                        path="charts/echarts/line-charts"
                        element={<LineCharts />}
                    />
                    <Route
                        path="charts/echarts/bar-charts"
                        element={<BarCharts />}
                    />
                    <Route
                        path="charts/echarts/candlestick-charts"
                        element={<CandlestickCharts />}
                    />
                    <Route
                        path="charts/echarts/geo-map"
                        element={<GeoMaps />}
                    />
                    <Route
                        path="charts/echarts/scatter-charts"
                        element={<ScatterCharts />}
                    />
                    <Route
                        path="charts/echarts/pie-charts"
                        element={<PieCharts />}
                    />
                    <Route
                        path="charts/echarts/radar-charts"
                        element={<RadarCharts />}
                    />
                    <Route
                        path="charts/echarts/heatmap-charts"
                        element={<HeatmapCharts />}
                    />
                    <Route
                        path="charts/echarts/how-to-use"
                        element={<HowToUse />}
                    />
                    {/*Components*/}
                    <Route path="components/alerts" element={<Alerts />} />
                    <Route
                        path="components/accordion"
                        element={<Accordion />}
                    />
                    <Route
                        path="components/animated-icons"
                        element={<AnimatedIcons />}
                    />
                    <Route path="components/badges" element={<Badges />} />
                    <Route
                        path="components/breadcrumb"
                        element={<Breadcrumbs />}
                    />
                    <Route path="components/buttons" element={<Buttons />} />
                    <Route
                        path="components/calendar"
                        element={<CalendarExample />}
                    />
                    <Route path="components/cards" element={<Cards />} />
                    <Route
                        path="components/dropdowns"
                        element={<Dropdowns />}
                    />
                    <Route
                        path="components/list-group"
                        element={<ListGroups />}
                    />
                    <Route path="components/modals" element={<Modals />} />
                    <Route
                        path="components/offcanvas"
                        element={<Offcanvas />}
                    />
                    <Route
                        path="components/pagination"
                        element={<Pagination />}
                    />
                    <Route
                        path="components/progress-bar"
                        element={<BasicProgressBar />}
                    />
                    <Route
                        path="components/placeholder"
                        element={<Placeholder />}
                    />
                    <Route path="components/spinners" element={<Spinners />} />
                    <Route path="components/toasts" element={<Toasts />} />
                    <Route
                        path="components/pictures/avatar"
                        element={<Avatar />}
                    />
                    <Route
                        path="components/pictures/images"
                        element={<Image />}
                    />
                    <Route
                        path="components/pictures/figures"
                        element={<Figures />}
                    />
                    <Route
                        path="components/pictures/hoverbox"
                        element={<Hoverbox />}
                    />
                    <Route
                        path="components/pictures/lightbox"
                        element={<Lightbox />}
                    />
                    <Route path="components/tooltips" element={<Tooltips />} />
                    <Route path="components/popovers" element={<Popovers />} />
                    <Route
                        path="components/draggable"
                        element={<DraggableExample />}
                    />
                    <Route
                        path="components/scrollspy"
                        element={<Scrollspy />}
                    />
                    <Route path="components/timeline" element={<Timeline />} />
                    <Route
                        path="components/treeview"
                        element={<TreeviewExample />}
                    />
                    <Route
                        path="components/carousel/bootstrap"
                        element={<BootstrapCarousel />}
                    />
                    <Route
                        path="components/carousel/slick"
                        element={<SlickCarousel />}
                    />
                    <Route
                        path="components/navs-and-tabs/navs"
                        element={<Navs />}
                    />
                    <Route path="tables/basic-tables" element={<Tables />} />
                    <Route
                        path="tables/advance-tables"
                        element={<AdvanceTableExamples />}
                    />
                    <Route
                        path="forms/basic/form-control"
                        element={<FormControl />}
                    />
                    <Route
                        path="forms/basic/input-group"
                        element={<InputGroup />}
                    />
                    <Route path="forms/basic/select" element={<Select />} />
                    <Route path="forms/basic/checks" element={<Checks />} />
                    <Route path="forms/basic/range" element={<Range />} />
                    <Route path="forms/basic/layout" element={<FormLayout />} />
                    <Route
                        path="forms/advance/date-picker"
                        element={<DatePicker />}
                    />
                    <Route path="forms/advance/editor" element={<Editor />} />
                    <Route
                        path="forms/advance/emoji-button"
                        element={<EmojiMartExample />}
                    />
                    <Route
                        path="forms/advance/advance-select"
                        element={<AdvanceSelect />}
                    />
                    <Route
                        path="forms/advance/input-mask"
                        element={<InputMaskExample />}
                    />
                    <Route
                        path="forms/advance/range-slider"
                        element={<RangeSlider />}
                    />
                    <Route
                        path="forms/advance/file-uploader"
                        element={<FileUploader />}
                    />
                    <Route path="forms/advance/rating" element={<Rating />} />
                    <Route
                        path="forms/floating-labels"
                        element={<FloatingLabels />}
                    />
                    <Route
                        path="forms/validation"
                        element={<FormValidation />}
                    />
                    <Route path="forms/wizard" element={<WizardForms />} />
                    <Route
                        path="components/navs-and-tabs/navbar"
                        element={<Navbars />}
                    />
                    <Route
                        path="components/navs-and-tabs/tabs"
                        element={<Tabs />}
                    />
                    <Route path="components/collapse" element={<Collapse />} />
                    <Route
                        path="components/cookie-notice"
                        element={<CookieNoticeExample />}
                    />
                    <Route path="components/countup" element={<CountUp />} />
                    <Route path="components/videos/embed" element={<Embed />} />
                    <Route
                        path="components/videos/react-player"
                        element={<ReactPlayerExample />}
                    />
                    <Route
                        path="components/background"
                        element={<Background />}
                    />
                    <Route path="components/search" element={<Search />} />
                    <Route
                        path="components/typed-text"
                        element={<TypedText />}
                    />
                    <Route
                        path="components/navs-and-tabs/vertical-navbar"
                        element={<VerticalNavbar />}
                    />
                    <Route
                        path="components/navs-and-tabs/top-navbar"
                        element={<NavBarTop />}
                    />
                    <Route
                        path="components/navs-and-tabs/double-top-navbar"
                        element={<NavbarDoubleTop />}
                    />
                    <Route
                        path="components/navs-and-tabs/combo-navbar"
                        element={<ComboNavbar />}
                    />
                    {/*Utilities*/}
                    <Route path="utilities/borders" element={<Borders />} />
                    <Route path="utilities/colors" element={<Colors />} />
                    <Route
                        path="utilities/colored-links"
                        element={<ColoredLinks />}
                    />
                    <Route path="utilities/display" element={<Display />} />
                    <Route
                        path="utilities/visibility"
                        element={<Visibility />}
                    />
                    <Route
                        path="utilities/stretched-link"
                        element={<StretchedLink />}
                    />
                    <Route
                        path="utilities/stretched-link"
                        element={<StretchedLink />}
                    />
                    <Route path="utilities/float" element={<Float />} />
                    <Route path="utilities/position" element={<Position />} />
                    <Route path="utilities/spacing" element={<Spacing />} />
                    <Route path="utilities/sizing" element={<Sizing />} />
                    <Route
                        path="utilities/text-truncation"
                        element={<TextTruncation />}
                    />
                    <Route
                        path="utilities/typography"
                        element={<Typography />}
                    />
                    <Route
                        path="utilities/vertical-align"
                        element={<VerticalAlign />}
                    />
                    <Route path="utilities/flex" element={<Flex />} />
                    <Route path="utilities/grid" element={<Grid />} />
                    <Route
                        path="utilities/scroll-bar"
                        element={<Scrollbar />}
                    />
                    <Route path="widgets" element={<Widgets />} />
                    {/*Documentation*/}
                    <Route
                        path="documentation/getting-started"
                        element={<GettingStarted />}
                    />
                    <Route
                        path="documentation/configuration"
                        element={<Configuration />}
                    />
                    <Route path="documentation/styling" element={<Styling />} />
                    <Route
                        path="documentation/dark-mode"
                        element={<DarkMode />}
                    />
                    <Route path="documentation/plugin" element={<Plugins />} />
                    <Route path="documentation/faq" element={<Faq />} />
                    <Route
                        path="documentation/design-file"
                        element={<DesignFile />}
                    />
                    <Route path="changelog" element={<Changelog />} />
                    <Route
                        path="authentication-modal"
                        element={<ModalAuth />}
                    />
                </Route>

                {/* //--- MainLayout end  */}

                {/* <Navigate to="/errors/404" /> */}
                <Route
                    path="*"
                    element={<Navigate to="/errors/404" replace />}
                />
            </Routes>
        )
    }

    return routes
}

FalconRoutes.propTypes = {
    isAuthenticated: PropTypes.bool,
    user: PropTypes.object,
}

export default FalconRoutes
