import handleApiResponseErrors from "helpers/handleApiResponse";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { authHeader } from "utils";
import UpdateOrdersStep1 from "./UpdateOrderStep1";
import UpdateOrderStep2 from "./UpdateOrderStep2";



const DoUpdateOrders = () => {
    const { doNo } = useParams();
    const [step, setStep] = useState(2);
    const [checkedProducts, setCheckedProducts] = useState([]);
    const [distributorOrderNumber, setDistributorOrderNumber] = useState(doNo);

    const handleNext = () => {
        setStep(step + 1);
    }

    const handleBack = () => {
        setStep(step - 1);
    }


    return (
        <>
            {step === 1 && (
                <UpdateOrdersStep1 handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
            )}
            {step === 2 && (
                <UpdateOrderStep2 step={step} handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
            )}
            {/* {step === 3 && (
                <UpdateOrderStep3 setStep={setStep} distributorOrderNumber={distributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} setDistributorOrderNumber={setDistributorOrderNumber} />
            )} */}
        </>
    )
}

export default DoUpdateOrders;