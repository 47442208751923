import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const PaymentsHistoryForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [paymentHistoryById, setPaymentHistoryById] = useState({});
    const [primaryOrderList, setPrimaryOrderList] = useState([]);
    const [paymentModeList, setPaymentModeList] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + `payment-histories/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setPaymentHistoryById(response.data.data);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
    }, [])


    // Primary Order list
    useEffect(() => {
        setIsLoading(true)
        const url = "https://order.pusti-microservices.ahmedul.com/api/" + "primary_order";
        axios.get(url, { headers: authHeader() })
            .then(response => {

                if (response.data.success || response.data.statusCode === 200) {
                    const modifyPrimaryOrders = response?.data?.data?.map(item => {
                        return {
                            id: item.id,
                            label: item.title,
                            value: item.id

                        }
                    })

                    setPrimaryOrderList(modifyPrimaryOrders);

                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response)
            }).finally(
                setIsLoading(false)
            )
    }, [])

    // payment Mode List
    useEffect(() => {
        setIsLoading(true)
        const url = "https://order.pusti-microservices.ahmedul.com/api/" + "payment_mode";
        axios.get(url, { headers: authHeader() })
            .then(response => {

                if (response.data.success || response.data.statusCode === 200) {
                    const modifyPaymentMode = response?.data?.data?.map(item => {
                        return {
                            id: item.id,
                            label: item.name,
                            value: item.id

                        }
                    })

                    setPaymentModeList(modifyPaymentMode);

                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response)
            }).finally(
                setIsLoading(false)
            )
    }, [])

    const handleFormSubmit = (values, actions) => {

        const handleSubmitValue = {
            primary_order_id: values.primary_order_id.value,
            payment_mode_id: values.payment_mode_id.value,
            amount: values.amount,
            comment: values.comment

        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + 'payment-histories';
            axios.post(url, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/paymentsHistory")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + `payment-histories/${updateID}`;
            axios.put(updateUrl, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/paymentsHistory")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({
        primary_order_id: Yup.object().required("Please select a value for the primary order field"),
        payment_mode_id: Yup.object().required("Please select a value for the Payment Mode field"),
        amount: Yup.string().required("Please Enter a value for the amount field"),
        comment: Yup.string().required("Please Enter a value for the comment field"),
    })


    return (
        <>
            <PageHeader
                title={updateID ? "Update Payment History" : "Add New Payment History"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            primary_order_id: primaryOrderList.find(item => item.value === paymentHistoryById.primary_order_id) || null,
                            payment_mode_id: paymentModeList.find(item => item.value === paymentHistoryById.payment_mode_id) || null,
                            amount: paymentHistoryById.amount || "",
                            comment: paymentHistoryById.comment || ""


                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Primary Order</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={primaryOrderList}
                                                placeholder="Select Primary Order"
                                                classNamePrefix="react-select"
                                                name="primary_order_id"
                                                value={values.primary_order_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "primary_order_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.primary_order_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.primary_order_id}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Payment Mode</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={paymentModeList}
                                                placeholder="Select Payment Mode"
                                                classNamePrefix="react-select"
                                                name="payment_mode_id"
                                                value={values.payment_mode_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "payment_mode_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.payment_mode_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.payment_mode_id}
                                                    </div>
                                                )}
                                        </Form.Group>


                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control
                                                name="amount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Amount"
                                                required
                                                value={values.amount}
                                            />
                                            {touched.amount && errors.amount && (
                                                <div style={{ color: "red" }}>
                                                    {errors.amount}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Comment</Form.Label>
                                            <Form.Control
                                                name="comment"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                as={"textarea"}
                                                placeholder="Enter Comment"
                                                required
                                                value={values.comment}
                                            />
                                            {touched.comment && errors.comment && (
                                                <div style={{ color: "red" }}>
                                                    {errors.comment}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <div className="mt-3">
                                        <IconButton
                                            variant="primary"
                                            className="me-2"
                                            type="submit"

                                        >
                                            {updateID ? "Save" : "Submit"}
                                        </IconButton>
                                        <Button
                                            onClick={() => navigate("/paymentsHistory")}
                                            variant="danger" type="Cancel"
                                        >
                                            Cancel
                                        </Button>
                                    </div>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default PaymentsHistoryForm;


