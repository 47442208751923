import { Button, Card, Form } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { authHeader } from 'utils'
import PageHeader from 'components/common/PageHeader'
import LoadingIcon from 'helpers/LoadingIcon'
import Flex from 'components/common/Flex'
import { formatNumber } from 'helpers/utils'
import { useNavigate, useParams } from 'react-router-dom'
import {
    getDeliveriesByDOOnly,
    getScheduleByDOOnly,
    updateSchedules,
} from 'services/api'

const ReconcileOrderStep2 = ({
    distributorOrderNumber,
    checkedProducts,
    setCheckedProducts,
}) => {
    const [isLoading, setIsLoading] = useState({
        general: false,
        deposit: false,
        credit: false,
        payment: false,
        paymentUpdate: false,
        delivery: false,
        schedule: false,
    })
    const [count, setCount] = useState(0)
    const [quantityUpdated, setQuantityUpdated] = useState(false)
    const [productsWithOutSorting, setProductsWithOutSorting] = useState([])
    const [depositList, setDepositList] = useState([])
    const [depositDataByDoNo, setDepositDataByDoNo] = useState([])
    const [deliveriesByDoNo, setDeliveriesByDoNo] = useState([])
    const [schedulesByDoNo, setSchedulesByDoNo] = useState([])
    const [checkedDeposit, setCheckedDeposit] = useState([])
    const [creditList, setCreditList] = useState([])
    const [totalCredit, setTotalCredit] = useState(0)
    const [remainingBalance, setRemainingBalance] = useState(0)
    const [paymentInfo, setPaymentInfo] = useState([])
    const [paymentData, setPaymentData] = useState([])
    const [totalPayAmount, setTotalPayAmount] = useState(0)
    const [totalDueAmount, setTotalDueAmount] = useState(0)
    const [paymentUpdateId, setPaymentUpdateId] = useState(null)
    const [totalPayableAmount, setTotalPayableAmount] = useState(0)
    const [areProductQuantitiesValid, setAreProductQuantitiesValid] =
        useState(false)
    const [offerAccepted, setofferAccepted] = useState('product')
    const [comboOffer, setComboOffer] = useState([])
    const [userInfo, setUserInfo] = useState({})
    const hasFetched = useRef(false)
    const { doNo } = useParams()
    const [totals, setTotals] = useState({
        totalOrderQuantityCtn: 0,
        totalOrderQtyPcs: 0,
        totalPriceCtn: 0,
        totalDiscount: 0,
        totalPayable: 0,
        perCartoonPriceTotal: 0,
        allCartoonPriceTotal: 0,
        totalOfSubtotals: 0,
    })
    const navigate = useNavigate()

    const isSubset = (subset, set, key) => {
        return subset.every((subItem) =>
            set.some((setItem) => setItem[key] === subItem)
        )
    }

    const isAvailable = (item, array) => {
        return array.some((arrayItem) => arrayItem === item)
    }

    const handleOffersForDeleteProduct = async (index) => {
        const updatedProducts = [...checkedProducts]
        const product = updatedProducts[index]
        const existingOffers = [...comboOffer]
        const skippedOffers = existingOffers.filter(
            (item) => !item.skuName.includes(product.skuName)
        )
        const toBeDeletedOffer = existingOffers.find((item) =>
            item.skuName.includes(product.skuName)
        )

        if (toBeDeletedOffer) {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/deleteOfferByOfferNo/${toBeDeletedOffer.offerNo}/${distributorOrderNumber}`
                const response = await axios.delete(url, {
                    headers: authHeader(),
                })
                if (response.data.success || response.status === 200) {
                    setComboOffer(skippedOffers)
                    return true
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, general: false }))
            }
        } else {
            setComboOffer(skippedOffers)
            return true
        }
    }

    /* 
     Load all products
    */
    useEffect(() => {
        if (hasFetched.current) return
        hasFetched.current = true

        const fetchData = async () => {
            setIsLoading((prevState) => ({ ...prevState, general: true }))
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.status === 200) {
                    setProductsWithOutSorting(response.data.payload.products)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, general: false }))
            }
        }

        fetchData()
    }, [])

    /* 
    Get Payment Data by distributor order number
    */
    useEffect(() => {
        if (!distributorOrderNumber) return

        const paymentUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank?doNo=${distributorOrderNumber}`
        const depositInfoUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositByDoNo/${distributorOrderNumber}`

        const fetchPaymentData = async () => {
            setIsLoading((prevState) => ({ ...prevState, payment: true }))
            try {
                const [PaymentResponse, depositResponse] = await Promise.all([
                    axios.get(paymentUrl, { headers: authHeader() }),
                    axios.get(depositInfoUrl, { headers: authHeader() }),
                ])
                if (
                    PaymentResponse.data.success ||
                    PaymentResponse.status === 200
                ) {
                    const data = PaymentResponse.data.payload.result
                    const paymentInfo = data[0]
                    setPaymentInfo(paymentInfo)
                    setPaymentUpdateId(paymentInfo?._id || null)
                    const userInfo = {
                        userId: paymentInfo?.dealerId,
                        userRole: paymentInfo?.dbType,
                        name:
                            paymentInfo?.distributorName ||
                            paymentInfo?.dealerName,
                        phoneNumber: paymentInfo?.phone,
                        address:
                            paymentInfo?.zone +
                            ', ' +
                            paymentInfo?.region +
                            ', ' +
                            paymentInfo?.area,
                    }
                    setUserInfo(userInfo)
                    setPaymentData(data)

                    // Fetch dealer info based on paymentInfo
                    if (paymentInfo?.dealerId) {
                        await fetchDealerData(paymentInfo.dealerId)
                    }
                }
                if (
                    depositResponse.data.success ||
                    depositResponse.status === 200
                ) {
                    const data = depositResponse.data.payload.depositDoData
                    setDepositDataByDoNo(data)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, payment: false }))
            }
        }

        // Get dealer info
        const fetchDealerData = async (dealerId) => {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dealer/${dealerId}`
                const response = await axios.get(url, { headers: authHeader() })
                if (response.status === 200) {
                    setUserInfo(response.data.payload.distributorData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            }
        }

        fetchPaymentData()
    }, [distributorOrderNumber, count])

    useEffect(() => {
        const fetchDeliveryInfo = async () => {
            try {
                setIsLoading((prevState) => ({ ...prevState, delivery: true }))
                const response = await getDeliveriesByDOOnly(
                    distributorOrderNumber
                )
                if (response.data.success || response.status === 200) {
                    setDeliveriesByDoNo(response.data.payload.result)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, delivery: false }))
            }
        }
        fetchDeliveryInfo()
    }, [distributorOrderNumber, count])

    useEffect(() => {
        const fetchScheduleInfo = async () => {
            try {
                setIsLoading((prevState) => ({ ...prevState, schedule: true }))
                const response = await getScheduleByDOOnly(
                    distributorOrderNumber
                )
                if (response.data.success || response.status === 200) {
                    setSchedulesByDoNo(response.data.payload.result)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, schedule: false }))
            }
        }
        fetchScheduleInfo()
    }, [distributorOrderNumber, count])

    // Function to update order quantities for a specific product
    const handleOrderQuantities = async (event, index, product) => {
        const { value } = event.target
        const updatedProducts = [...checkedProducts]
        const currentDate = new Date().toISOString() // Get current date in ISO format
        // Offer API call
        try {
            setIsLoading((prevState) => ({ ...prevState, general: true }))
            const response = await axios.get(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/allOffers`,
                { headers: authHeader() }
            )
            if (response.data.success || response.status === 200) {
                const offersData = response.data.payload.offers

                if (offersData?.length > 0) {
                    const currentDateOffers = offersData.filter((offer) => {
                        return (
                            offer.offerUnit == 'CTN' &&
                            offer.offerStartDate <= currentDate &&
                            offer.offerEndDate >= currentDate
                        )
                    })

                    if (currentDateOffers?.length > 0) {
                        let myIndividualProductOffers = null
                        const individualProductOffers = currentDateOffers.find(
                            (offer) =>
                                offer.offerUnit == 'CTN' &&
                                offer.offerType === 'Individual' &&
                                offer.skuName.length == 1 &&
                                offer.skuName[0] === product.skuName &&
                                offer.buyQty <= parseFloat(value)
                        )

                        if (
                            individualProductOffers?.dealerId.length &&
                            isAvailable(
                                userInfo?.userId,
                                individualProductOffers?.dealerId
                            )
                        ) {
                            myIndividualProductOffers = individualProductOffers
                        } else if (
                            individualProductOffers?.zoneName.length &&
                            isAvailable(
                                userInfo?.zoneName,
                                individualProductOffers?.zoneName
                            ) &&
                            !individualProductOffers?.regionName.length &&
                            !individualProductOffers?.areaName.length &&
                            !individualProductOffers?.dbPoint.length &&
                            !individualProductOffers?.dealerId.length
                        ) {
                            myIndividualProductOffers = individualProductOffers
                        } else if (
                            individualProductOffers?.regionName.length &&
                            isAvailable(
                                userInfo?.regionName,
                                individualProductOffers?.regionName
                            ) &&
                            !individualProductOffers?.areaName.length &&
                            !individualProductOffers?.dbPoint.length &&
                            !individualProductOffers?.dealerId.length
                        ) {
                            myIndividualProductOffers = individualProductOffers
                        } else if (
                            individualProductOffers?.areaName.length &&
                            isAvailable(
                                userInfo?.areaName,
                                individualProductOffers?.areaName
                            ) &&
                            !individualProductOffers?.dbPoint.length &&
                            !individualProductOffers?.dealerId.length
                        ) {
                            myIndividualProductOffers = individualProductOffers
                        } else if (
                            individualProductOffers?.dbPoint.length &&
                            isAvailable(
                                userInfo?.dbPoint,
                                individualProductOffers?.dbPoint
                            ) &&
                            !individualProductOffers?.dealerId.length
                        ) {
                            myIndividualProductOffers = individualProductOffers
                        } else {
                            myIndividualProductOffers = null
                            updatedProducts[index] = {
                                ...updatedProducts[index],
                                productOffer: null,
                            }
                        }

                        if (myIndividualProductOffers) {
                            axios
                                .post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/OfferQtyValueSum`,
                                    {
                                        skuName: product.skuName,
                                        offerStartDate:
                                            myIndividualProductOffers.offerStartDate,
                                        offerEndDate:
                                            myIndividualProductOffers.offerEndDate,
                                    },
                                    { headers: authHeader() }
                                )
                                .then((response) => {
                                    if (response.data.success) {
                                        const available =
                                            myIndividualProductOffers.totalBudgetQty -
                                            response.data.payload.offerSumData
                                        if (available > 0) {
                                            const offerQtyPcs =
                                                available >=
                                                    myIndividualProductOffers.freeQty
                                                    ? myIndividualProductOffers.freeQty
                                                    : available

                                            const multiple =
                                                value /
                                                myIndividualProductOffers.buyQty

                                            const offerObj = {
                                                ...myIndividualProductOffers,
                                                offerQtyPcs:
                                                    offerQtyPcs * multiple >
                                                        available
                                                        ? available
                                                        : offerQtyPcs *
                                                        multiple,
                                            }

                                            updatedProducts[index] = {
                                                ...updatedProducts[index],
                                                productOffer: offerObj,
                                            }
                                        }
                                    }
                                })
                                .catch((error) => {
                                    handleApiResponseErrors(error.response)
                                })
                        } else {
                            const myResult = currentDateOffers.find(
                                (offer) =>
                                    offer.offerType === 'National' &&
                                    offer.skuName.length == 1 &&
                                    offer.skuName[0] === product.skuName &&
                                    offer.buyQty <= parseFloat(value) // Check if buyQty is less than or equal to the specified value
                            )

                            if (myResult) {
                                axios
                                    .post(
                                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/OfferQtyValueSum`,
                                        {
                                            skuName: product.skuName,
                                            offerStartDate:
                                                myResult.offerStartDate,
                                            offerEndDate: myResult.offerEndDate,
                                        },
                                        { headers: authHeader() }
                                    )
                                    .then((response) => {
                                        if (response.data.success) {
                                            const available =
                                                myResult.totalBudgetQty -
                                                response.data.payload
                                                    .offerSumData
                                            if (available > 0) {
                                                const offerQtyPcs =
                                                    available >=
                                                        myResult.freeQty
                                                        ? myResult.freeQty
                                                        : available

                                                const multiple =
                                                    value / myResult.buyQty

                                                const offerObj = {
                                                    ...myResult,
                                                    offerQtyPcs:
                                                        offerQtyPcs * multiple >
                                                            available
                                                            ? available
                                                            : offerQtyPcs *
                                                            multiple,
                                                }

                                                updatedProducts[index] = {
                                                    ...updatedProducts[index],
                                                    productOffer: offerObj,
                                                }
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        handleApiResponseErrors(error.response)
                                    })
                            }
                        }

                        const getPerCtnPriceForUserRole = (
                            userRole,
                            product
                        ) => {
                            switch (userRole) {
                                case 'COMMISSION DISTRIBUTOR':
                                    return (
                                        parseFloat(product?.commPrice) *
                                        parseFloat(product?.pcsCTN)
                                    )
                                case 'WHOLESALE DISTRIBUTOR':
                                    return (
                                        parseFloat(product?.wholesellPrice) *
                                        parseFloat(product?.pcsCTN)
                                    )
                                case 'SPOT DISTRIBUTOR':
                                    return (
                                        parseFloat(product?.spotPrice) *
                                        parseFloat(product?.pcsCTN)
                                    )
                                default:
                                    return (
                                        parseFloat(product?.distributorPrice) *
                                        parseFloat(product?.pcsCTN)
                                    )
                            }
                        }

                        ///////////for national offer//////////

                        //copy the cart products in temp array
                        const myUpdatedProducts = updatedProducts

                        // update the quantity of the product first
                        myUpdatedProducts[index].orderQuantity = value

                        //update the price of the product
                        myUpdatedProducts[index].totalPrice =
                            getPerCtnPriceForUserRole(
                                userInfo?.userRole,
                                updatedProducts[index]
                            ) * value

                        //filter nationally, CTN offers if they contain the cart products
                        const nationalComboOffer = currentDateOffers.filter(
                            (offer) =>
                                offer.offerUnit == 'CTN' &&
                                offer.offerType === 'National' &&
                                offer.skuName.length > 1 &&
                                isSubset(
                                    offer.skuName,
                                    myUpdatedProducts,
                                    'skuName'
                                )
                            // && offer.buyQty <= parseFloat(value)
                        )

                        let nationalComboOffersForState = []

                        if (nationalComboOffer.length) {
                            let deletableOffers = []
                            let quantityUpdateableOffers = []

                            //check whether price offers
                            const priceOffers = nationalComboOffer.filter(
                                (item) => item.purchaseBDT
                            )

                            //check whether quantity offers
                            const qtyOffers = nationalComboOffer.filter(
                                (item) => item.buyQty
                            )

                            let PCOffers = []
                            //for price based offers
                            if (priceOffers.length) {
                                const response = await axios.post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/MultiSKUOfferQtyValueSum`,
                                    {
                                        offers: priceOffers.map((item) => ({
                                            offerNo: item._id,
                                            offerStartDate: item.offerStartDate,
                                            offerEndDate: item.offerEndDate,
                                        })),
                                    },
                                    { headers: authHeader() }
                                )

                                if (
                                    response.data.success ||
                                    response.status === 200
                                ) {
                                    const budgets = response.data.payload

                                    for (
                                        let index = 0;
                                        index < priceOffers.length;
                                        index++
                                    ) {
                                        const element = priceOffers[index]

                                        // sum offer matched cart products total price
                                        const totalOrderPrice =
                                            myUpdatedProducts
                                                .filter((item) =>
                                                    element.skuName.includes(
                                                        item.skuName
                                                    )
                                                )
                                                .reduce(
                                                    (
                                                        accumulator,
                                                        currentValue
                                                    ) => {
                                                        return (
                                                            accumulator +
                                                            parseInt(
                                                                currentValue.totalPrice
                                                            )
                                                        )
                                                    },
                                                    0
                                                )

                                        if (
                                            totalOrderPrice >=
                                            element.purchaseBDT
                                        ) {
                                            //get the remaining budget of the desired offer

                                            const available =
                                                element.totalBudgetQty -
                                                (budgets[element._id] || 0) //- response.data.payload.offerSumData

                                            if (available > 0) {
                                                const offerQtyPcs =
                                                    available >= element.freeQty
                                                        ? element.freeQty
                                                        : available

                                                const multiple =
                                                    totalOrderPrice /
                                                    element.purchaseBDT

                                                const offerObj = {
                                                    ...element,
                                                    offerQtyPcs:
                                                        offerQtyPcs * multiple >
                                                            available
                                                            ? available
                                                            : offerQtyPcs *
                                                            multiple,
                                                    // offerQtyPcs: offerQtyPcs * multiple
                                                }

                                                const existingPCOffers = [
                                                    ...comboOffer.filter(
                                                        (item) =>
                                                            item.offerType ==
                                                            'National'
                                                    ),
                                                ]
                                                let updatablePCOffer = true

                                                for (
                                                    let index = 0;
                                                    index <
                                                    existingPCOffers.length;
                                                    index++
                                                ) {
                                                    const element1 =
                                                        existingPCOffers[index]
                                                    const skuS =
                                                        offerObj.skuName

                                                    let counterPC = 0
                                                    for (
                                                        let k = 0;
                                                        k < skuS.length;
                                                        k++
                                                    ) {
                                                        const sku = skuS[k]
                                                        if (
                                                            element1.skuName.includes(
                                                                sku
                                                            )
                                                        ) {
                                                            counterPC++
                                                        } else {
                                                            break
                                                        }
                                                    }

                                                    // updatablePCOffer = element1.skuName.length != counterPC;

                                                    if (
                                                        element1.skuName
                                                            .length == counterPC
                                                    ) {
                                                        updatablePCOffer = false
                                                        break
                                                    }
                                                }

                                                if (updatablePCOffer) {
                                                    // setComboOffer([...comboOffer, offerObj]);
                                                    PCOffers.push(offerObj)
                                                } else {
                                                    // setComboOffer([...comboOffer]);
                                                    PCOffers = []
                                                    quantityUpdateableOffers.push(
                                                        offerObj
                                                    )
                                                }
                                            }
                                        } else {
                                            // setComboOffer([...comboOffer]);
                                            PCOffers = []
                                            deletableOffers.push(element)
                                        }
                                    }
                                }
                            } else {
                                // setComboOffer([...comboOffer]);
                            }

                            let QTYOffers = []
                            //for quantity based offers
                            if (qtyOffers.length) {
                                const response = await axios.post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/MultiSKUOfferQtyValueSum`,
                                    {
                                        offers: qtyOffers.map((item) => ({
                                            offerNo: item._id,
                                            offerStartDate: item.offerStartDate,
                                            offerEndDate: item.offerEndDate,
                                        })),
                                    },
                                    { headers: authHeader() }
                                )

                                if (
                                    response.data.success ||
                                    response.status === 200
                                ) {
                                    const budgets = response.data.payload

                                    for (
                                        let index = 0;
                                        index < qtyOffers.length;
                                        index++
                                    ) {
                                        const element = qtyOffers[index]

                                        //sum offer matched cart products quantity
                                        const totalOrderQty = myUpdatedProducts
                                            .filter((item) =>
                                                element.skuName.includes(
                                                    item.skuName
                                                )
                                            )
                                            .reduce(
                                                (accumulator, currentValue) => {
                                                    return (
                                                        accumulator +
                                                        parseInt(
                                                            currentValue.orderQuantity
                                                        )
                                                    )
                                                },
                                                0
                                            )

                                        if (totalOrderQty >= element.buyQty) {
                                            //calculate the remaining budget qty of the desired offer
                                            const available =
                                                element.totalBudgetQty -
                                                (budgets[element._id] || 0) //- response.data.payload.offerSumData

                                            if (available > 0) {
                                                const offerQtyPcs =
                                                    available >= element.freeQty
                                                        ? element.freeQty
                                                        : available
                                                const multiple =
                                                    totalOrderQty /
                                                    element.buyQty

                                                const offerObj = {
                                                    ...element,
                                                    offerQtyPcs:
                                                        offerQtyPcs * multiple >
                                                            available
                                                            ? available
                                                            : offerQtyPcs *
                                                            multiple,
                                                    // offerQtyPcs: offerQtyPcs * multiple
                                                }

                                                const existingOffers = [
                                                    ...comboOffer.filter(
                                                        (item) =>
                                                            item.offerType ==
                                                            'National'
                                                    ),
                                                ]
                                                let updatable = true

                                                for (
                                                    let index = 0;
                                                    index <
                                                    existingOffers.length;
                                                    index++
                                                ) {
                                                    const element2 =
                                                        existingOffers[index]
                                                    const skuS =
                                                        offerObj.skuName

                                                    let counter = 0
                                                    for (
                                                        let k = 0;
                                                        k < skuS.length;
                                                        k++
                                                    ) {
                                                        const sku = skuS[k]
                                                        if (
                                                            element2.skuName.includes(
                                                                sku
                                                            )
                                                        ) {
                                                            counter++
                                                        } else {
                                                            break
                                                        }
                                                    }

                                                    // updatable = element2.skuName.length != counter;
                                                    if (
                                                        element2.skuName
                                                            .length == counter
                                                    ) {
                                                        updatable = false
                                                        break
                                                    }
                                                }

                                                if (updatable) {
                                                    // setComboOffer([...comboOffer, offerObj]);
                                                    QTYOffers.push(offerObj)
                                                } else {
                                                    // setComboOffer([...comboOffer]);
                                                    QTYOffers = []
                                                    quantityUpdateableOffers.push(
                                                        offerObj
                                                    )
                                                }
                                            }
                                        } else {
                                            // setComboOffer([...comboOffer]);
                                            QTYOffers = []
                                            deletableOffers.push(element)
                                        }
                                    }
                                }
                            } else {
                                // setComboOffer([...comboOffer]);
                            }

                            // setComboOffer([...comboOffer, ...PCOffers])

                            //filter out the state offers if the quantity decreased to not applicable for offer
                            if (deletableOffers.length) {
                                const existingOffersonState = [
                                    ...comboOffer.filter(
                                        (item) => item.offerType == 'National'
                                    ),
                                ]

                                let stayableOffers = []

                                for (
                                    let index = 0;
                                    index < existingOffersonState.length;
                                    index++
                                ) {
                                    // Loop through existingOffersonState
                                    const stateItem =
                                        existingOffersonState[index]
                                    let notExistingItem = true

                                    for (
                                        let ind = 0;
                                        ind < deletableOffers.length;
                                        ind++
                                    ) {
                                        // Loop through deletableOffers
                                        const myItem = deletableOffers[ind]
                                        const skuS = myItem.skuName

                                        let counter = 0
                                        for (let k = 0; k < skuS.length; k++) {
                                            // Matching all the SKU names of the item
                                            const sku = skuS[k]
                                            if (
                                                stateItem.skuName.includes(sku)
                                            ) {
                                                counter++
                                            } else {
                                                break
                                            }
                                        }

                                        if (
                                            stateItem.skuName.length == counter
                                        ) {
                                            // If exists in the state
                                            notExistingItem = false
                                            break
                                        }
                                    }

                                    if (notExistingItem) {
                                        stayableOffers.push(stateItem)
                                    }
                                }

                                // setComboOffer([...stayableOffers, ...QTYOffers, ...PCOffers]);
                                nationalComboOffersForState = [
                                    ...stayableOffers,
                                    ...QTYOffers,
                                    ...PCOffers,
                                ]
                            } else {
                                // setComboOffer([...comboOffer, ...QTYOffers, ...PCOffers])
                                nationalComboOffersForState = [
                                    ...comboOffer.filter(
                                        (item) => item.offerType == 'National'
                                    ),
                                    ...QTYOffers,
                                    ...PCOffers,
                                ]

                                //for quantity update of the offer
                                if (quantityUpdateableOffers.length) {
                                    for (
                                        let index = 0;
                                        index <
                                        nationalComboOffersForState.length;
                                        index++
                                    ) {
                                        // Loop through nationalComboOffersForState
                                        const stateItem =
                                            nationalComboOffersForState[index]

                                        for (
                                            let ind = 0;
                                            ind <
                                            quantityUpdateableOffers.length;
                                            ind++
                                        ) {
                                            // Loop through quantityUpdateableOffers
                                            const myItem =
                                                quantityUpdateableOffers[ind]
                                            const skuS = myItem.skuName

                                            let counter = 0
                                            for (
                                                let k = 0;
                                                k < skuS.length;
                                                k++
                                            ) {
                                                // Matching all the SKU names of the item
                                                const sku = skuS[k]
                                                if (
                                                    stateItem.skuName.includes(
                                                        sku
                                                    )
                                                ) {
                                                    counter++
                                                } else {
                                                    break
                                                }
                                            }

                                            if (
                                                stateItem.skuName.length ==
                                                counter
                                            ) {
                                                // If exists in the state
                                                //update the quantity
                                                stateItem.offerQtyPcs =
                                                    myItem.offerQtyPcs
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            // setComboOffer([...comboOffer]);
                        }

                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////////////////////////This Section for Nation wide Combo Offer: END///////////////////////////////
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////This section for Individual Combo Offer: START/////////////////////////////////////////
                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                        let myIndividualComboOffers = []

                        const individualComboOffers = currentDateOffers.filter(
                            (offer) =>
                                offer.offerUnit == 'CTN' &&
                                offer.offerType === 'Individual' &&
                                offer.skuName.length > 1 &&
                                isSubset(
                                    offer.skuName,
                                    myUpdatedProducts,
                                    'skuName'
                                )
                            // && offer.buyQty <= parseFloat(value)
                        )

                        if (individualComboOffers.length) {
                            for (
                                let of = 0;
                                of < individualComboOffers.length;
                                of++
                            ) {
                                const individualComboObj =
                                    individualComboOffers[of]

                                if (
                                    individualComboObj?.dealerId.length &&
                                    isAvailable(
                                        userInfo?.userId,
                                        individualComboObj?.dealerId
                                    )
                                ) {
                                    myIndividualComboOffers.push(
                                        individualComboObj
                                    )
                                } else if (
                                    individualComboObj?.zoneName.length &&
                                    isAvailable(
                                        userInfo?.zoneName,
                                        individualComboObj?.zoneName
                                    ) &&
                                    !individualComboObj?.regionName.length &&
                                    !individualComboObj?.areaName.length &&
                                    !individualComboObj?.dbPoint.length &&
                                    !individualComboObj?.dealerId.length
                                ) {
                                    myIndividualComboOffers.push(
                                        individualComboObj
                                    )
                                } else if (
                                    individualComboObj?.regionName.length &&
                                    isAvailable(
                                        userInfo?.regionName,
                                        individualComboObj?.regionName
                                    ) &&
                                    !individualComboObj?.areaName.length &&
                                    !individualComboObj?.dbPoint.length &&
                                    !individualComboObj?.dealerId.length
                                ) {
                                    myIndividualComboOffers.push(
                                        individualComboObj
                                    )
                                } else if (
                                    individualComboObj?.areaName.length &&
                                    isAvailable(
                                        userInfo?.areaName,
                                        individualComboObj?.areaName
                                    ) &&
                                    !individualComboObj?.dbPoint.length &&
                                    !individualComboObj?.dealerId.length
                                ) {
                                    myIndividualComboOffers.push(
                                        individualComboObj
                                    )
                                } else if (
                                    individualComboObj?.dbPoint.length &&
                                    isAvailable(
                                        userInfo?.dbPoint,
                                        individualComboObj?.dbPoint
                                    ) &&
                                    !individualComboObj?.dealerId
                                ) {
                                    myIndividualComboOffers.push(
                                        individualComboObj
                                    )
                                } else {
                                    myIndividualComboOffers = []
                                }
                            }
                        }

                        let myIndividualComboOffersForState = []

                        if (myIndividualComboOffers.length) {
                            let deletableOffers = []

                            let quantityUpdateableOffers = []

                            //check whether price offers
                            const priceOffers = myIndividualComboOffers.filter(
                                (item) => item.purchaseBDT
                            )

                            //check whether quantity offers
                            const qtyOffers = myIndividualComboOffers.filter(
                                (item) => item.buyQty
                            )

                            let PCOffers = []
                            //for price based offers
                            if (priceOffers.length) {
                                const response = await axios.post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/MultiSKUOfferQtyValueSum`,
                                    {
                                        offers: priceOffers.map((item) => ({
                                            offerNo: item._id,
                                            offerStartDate: item.offerStartDate,
                                            offerEndDate: item.offerEndDate,
                                        })),
                                    },
                                    { headers: authHeader() }
                                )

                                if (
                                    response.data.success ||
                                    response.status === 200
                                ) {
                                    const budgets = response.data.payload

                                    for (
                                        let index = 0;
                                        index < priceOffers.length;
                                        index++
                                    ) {
                                        const element = priceOffers[index]

                                        // sum offer matched cart products total price
                                        const totalOrderPrice =
                                            myUpdatedProducts
                                                .filter((item) =>
                                                    element.skuName.includes(
                                                        item.skuName
                                                    )
                                                )
                                                .reduce(
                                                    (
                                                        accumulator,
                                                        currentValue
                                                    ) => {
                                                        return (
                                                            accumulator +
                                                            parseInt(
                                                                currentValue.totalPrice
                                                            )
                                                        )
                                                    },
                                                    0
                                                )

                                        if (
                                            totalOrderPrice >=
                                            element.purchaseBDT
                                        ) {
                                            //get the remaining budget of the desired offer

                                            const available =
                                                element.totalBudgetQty -
                                                (budgets[element._id] || 0) //- response.data.payload.offerSumData

                                            if (available > 0) {
                                                const offerQtyPcs =
                                                    available >= element.freeQty
                                                        ? element.freeQty
                                                        : available

                                                const multiple =
                                                    totalOrderPrice /
                                                    element.purchaseBDT

                                                const offerObj = {
                                                    ...element,
                                                    offerQtyPcs:
                                                        offerQtyPcs * multiple >
                                                            available
                                                            ? available
                                                            : offerQtyPcs *
                                                            multiple,
                                                    // offerQtyPcs: offerQtyPcs * multiple
                                                }

                                                const existingPCOffers = [
                                                    ...comboOffer.filter(
                                                        (item) =>
                                                            item.offerType ==
                                                            'Individual'
                                                    ),
                                                ]
                                                let updatablePCOffer = true

                                                for (
                                                    let index = 0;
                                                    index <
                                                    existingPCOffers.length;
                                                    index++
                                                ) {
                                                    const element1 =
                                                        existingPCOffers[index]
                                                    const skuS =
                                                        offerObj.skuName

                                                    let counterPC = 0
                                                    for (
                                                        let k = 0;
                                                        k < skuS.length;
                                                        k++
                                                    ) {
                                                        const sku = skuS[k]
                                                        if (
                                                            element1.skuName.includes(
                                                                sku
                                                            )
                                                        ) {
                                                            counterPC++
                                                        } else {
                                                            break
                                                        }
                                                    }

                                                    // updatablePCOffer = element1.skuName.length != counterPC;

                                                    if (
                                                        element1.skuName
                                                            .length == counterPC
                                                    ) {
                                                        updatablePCOffer = false
                                                        break
                                                    }
                                                }

                                                if (updatablePCOffer) {
                                                    // setComboOffer([...comboOffer, offerObj]);
                                                    PCOffers.push(offerObj)
                                                } else {
                                                    // setComboOffer([...comboOffer]);
                                                    PCOffers = []
                                                    quantityUpdateableOffers.push(
                                                        offerObj
                                                    )
                                                }
                                            }
                                        } else {
                                            // setComboOffer([...comboOffer]);
                                            PCOffers = []
                                            deletableOffers.push(element)
                                        }
                                    }
                                }
                            } else {
                                // setComboOffer([...comboOffer]);
                            }
                            let QTYOffers = []
                            //for quantity based offers
                            if (qtyOffers.length) {
                                const response = await axios.post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/MultiSKUOfferQtyValueSum`,
                                    {
                                        offers: qtyOffers.map((item) => ({
                                            offerNo: item._id,
                                            offerStartDate: item.offerStartDate,
                                            offerEndDate: item.offerEndDate,
                                        })),
                                    },
                                    { headers: authHeader() }
                                )

                                if (
                                    response.data.success ||
                                    response.status === 200
                                ) {
                                    const budgets = response.data.payload

                                    for (
                                        let index = 0;
                                        index < qtyOffers.length;
                                        index++
                                    ) {
                                        const element = qtyOffers[index]

                                        //sum offer matched cart products quantity
                                        const totalOrderQty = myUpdatedProducts
                                            .filter((item) =>
                                                element.skuName.includes(
                                                    item.skuName
                                                )
                                            )
                                            .reduce(
                                                (accumulator, currentValue) => {
                                                    return (
                                                        accumulator +
                                                        parseInt(
                                                            currentValue.orderQuantity
                                                        )
                                                    )
                                                },
                                                0
                                            )

                                        if (totalOrderQty >= element.buyQty) {
                                            //calculate the remaining budget qty of the desired offer

                                            const available =
                                                element.totalBudgetQty -
                                                (budgets[element._id] || 0) //- response.data.payload.offerSumData

                                            if (available > 0) {
                                                const offerQtyPcs =
                                                    available >= element.freeQty
                                                        ? element.freeQty
                                                        : available

                                                //
                                                const multiple =
                                                    totalOrderQty /
                                                    element.buyQty

                                                const offerObj = {
                                                    ...element,
                                                    offerQtyPcs:
                                                        offerQtyPcs * multiple >
                                                            available
                                                            ? available
                                                            : offerQtyPcs *
                                                            multiple,
                                                    // offerQtyPcs: offerQtyPcs * multiple
                                                }

                                                //check if the same offer already exists in the state, if yes then dont push to the state
                                                const existingOffers = [
                                                    ...comboOffer.filter(
                                                        (item) =>
                                                            item.offerType ==
                                                            'Individual'
                                                    ),
                                                ]
                                                let updatable = true

                                                //get all the existing offers from the state and loop through and check if all the skus matches with the desired offer to set
                                                for (
                                                    let index = 0;
                                                    index <
                                                    existingOffers.length;
                                                    index++
                                                ) {
                                                    const element2 =
                                                        existingOffers[index]
                                                    const skuS =
                                                        offerObj.skuName

                                                    let counter = 0
                                                    for (
                                                        let k = 0;
                                                        k < skuS.length;
                                                        k++
                                                    ) {
                                                        const sku = skuS[k]
                                                        if (
                                                            element2.skuName.includes(
                                                                sku
                                                            )
                                                        ) {
                                                            counter++
                                                        } else {
                                                            break
                                                        }
                                                    }

                                                    // updatable = element2.skuName.length != counter;

                                                    if (
                                                        element2.skuName
                                                            .length == counter
                                                    ) {
                                                        updatable = false
                                                        break
                                                    }
                                                }

                                                //if not all the skus matches, that means it not exists in the state, then it can be pushed to the state

                                                if (updatable) {
                                                    // setComboOffer([...comboOffer, offerObj]);
                                                    QTYOffers.push(offerObj)

                                                    //otherwise that means it already on the state, no need to set on the state,

                                                    // but here is the thing, QUANTITY need to be updated
                                                } else {
                                                    // setComboOffer([...comboOffer]);
                                                    QTYOffers = []
                                                    quantityUpdateableOffers.push(
                                                        offerObj
                                                    )
                                                }
                                            }
                                        } else {
                                            // setComboOffer([...comboOffer]);
                                            QTYOffers = []
                                            deletableOffers.push(element)
                                        }
                                    }
                                }
                            } else {
                                // setComboOffer([...comboOffer]);
                            }

                            // setComboOffer([...comboOffer, ...PCOffers])

                            if (deletableOffers.length) {
                                const existingOffersonState = [
                                    ...comboOffer.filter(
                                        (item) => item.offerType == 'Individual'
                                    ),
                                ]

                                let stayableOffers = []

                                for (
                                    let index = 0;
                                    index < existingOffersonState.length;
                                    index++
                                ) {
                                    // Loop through existingOffersonState
                                    const stateItem =
                                        existingOffersonState[index]

                                    let notExistingItem = true

                                    for (
                                        let ind = 0;
                                        ind < deletableOffers.length;
                                        ind++
                                    ) {
                                        // Loop through deletableOffers
                                        const myItem = deletableOffers[ind]
                                        const skuS = myItem.skuName

                                        let counter = 0
                                        for (let k = 0; k < skuS.length; k++) {
                                            // Matching all the SKU names of the item
                                            const sku = skuS[k]
                                            if (
                                                stateItem.skuName.includes(sku)
                                            ) {
                                                counter++
                                            } else {
                                                break
                                            }
                                        }

                                        if (
                                            stateItem.skuName.length == counter
                                        ) {
                                            // If exists in the state
                                            notExistingItem = false
                                            break
                                        }
                                    }

                                    if (notExistingItem) {
                                        stayableOffers.push(stateItem)
                                    }
                                }

                                // setComboOffer([...stayableOffers, ...QTYOffers, ...PCOffers]);
                                myIndividualComboOffersForState = [
                                    ...stayableOffers,
                                    ...QTYOffers,
                                    ...PCOffers,
                                ]
                            } else {
                                // setComboOffer([...comboOffer, ...QTYOffers, ...PCOffers])
                                myIndividualComboOffersForState = [
                                    ...comboOffer.filter(
                                        (item) => item.offerType == 'Individual'
                                    ),
                                    ...QTYOffers,
                                    ...PCOffers,
                                ]

                                //for quantity update of the offer
                                if (quantityUpdateableOffers.length) {
                                    for (
                                        let index = 0;
                                        index <
                                        myIndividualComboOffersForState.length;
                                        index++
                                    ) {
                                        // Loop through myIndividualComboOffersForState
                                        const stateItem =
                                            myIndividualComboOffersForState[
                                            index
                                            ]

                                        for (
                                            let ind = 0;
                                            ind <
                                            quantityUpdateableOffers.length;
                                            ind++
                                        ) {
                                            // Loop through quantityUpdateableOffers
                                            const myItem =
                                                quantityUpdateableOffers[ind]
                                            const skuS = myItem.skuName

                                            let counter = 0
                                            for (
                                                let k = 0;
                                                k < skuS.length;
                                                k++
                                            ) {
                                                // Matching all the SKU names of the item
                                                const sku = skuS[k]
                                                if (
                                                    stateItem.skuName.includes(
                                                        sku
                                                    )
                                                ) {
                                                    counter++
                                                } else {
                                                    break
                                                }
                                            }

                                            if (
                                                stateItem.skuName.length ==
                                                counter
                                            ) {
                                                // If exists in the state
                                                //update the quantity
                                                stateItem.offerQtyPcs =
                                                    myItem.offerQtyPcs
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            // setComboOffer([...comboOffer]);
                        }

                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////This section for Individual Combo Offer: END/////////////////////////////////////////
                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                        setComboOffer([
                            ...nationalComboOffersForState,
                            ...myIndividualComboOffersForState,
                        ])
                    }
                }
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        }

        // Discount API call
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/allDiscounts`,
                { headers: authHeader() }
            )
            if (response.data.success || response.status === 200) {
                const discountsData = response?.data?.payload?.discounts

                if (discountsData?.length > 0) {
                    const currentDateDiscounts = discountsData?.filter(
                        (discount) => {
                            return (
                                discount?.discountUnit == 'CTN' &&
                                discount?.discountStartDate <= currentDate &&
                                discount?.discountEndDate >= currentDate
                            )
                        }
                    )

                    if (currentDateDiscounts?.length > 0) {
                        let myindividualProductDiscount = null
                        const individualProductDiscount =
                            currentDateDiscounts.find(
                                (discount) =>
                                    discount.discountUnit == 'CTN' &&
                                    discount.discountType === 'Individual' &&
                                    discount.skuName === product.skuName &&
                                    discount.buyQty <= parseFloat(value)
                            )
                        if (
                            individualProductDiscount?.dealerId &&
                            individualProductDiscount?.dealerId ===
                            userInfo?.userId
                        ) {
                            myindividualProductDiscount =
                                individualProductDiscount
                        } else if (
                            individualProductDiscount?.zoneName &&
                            individualProductDiscount?.zoneName ===
                            userInfo?.zoneName &&
                            !individualProductDiscount?.regionName &&
                            !individualProductDiscount?.areaName &&
                            !individualProductDiscount?.dbPoint &&
                            !individualProductDiscount?.dealerId
                        ) {
                            myindividualProductDiscount =
                                individualProductDiscount
                        } else if (
                            individualProductDiscount?.regionName &&
                            individualProductDiscount?.regionName ===
                            userInfo?.regionName &&
                            !individualProductDiscount?.areaName &&
                            !individualProductDiscount?.dbPoint &&
                            !individualProductDiscount?.dealerId
                        ) {
                            myindividualProductDiscount =
                                individualProductDiscount
                        } else if (
                            individualProductDiscount?.areaName &&
                            individualProductDiscount?.areaName ===
                            userInfo?.areaName &&
                            !individualProductDiscount?.dbPoint &&
                            !individualProductDiscount?.dealerId
                        ) {
                            myindividualProductDiscount =
                                individualProductDiscount
                        } else if (
                            individualProductDiscount?.dbPoint &&
                            individualProductDiscount?.dbPoint ===
                            userInfo?.dbPoint &&
                            !individualProductDiscount?.dealerId
                        ) {
                            myindividualProductDiscount =
                                individualProductDiscount
                        } else {
                            myindividualProductDiscount = null
                            updatedProducts[index] = {
                                ...updatedProducts[index],
                                productDiscount: null,
                            }
                        }

                        if (myindividualProductDiscount) {
                            const response = await axios.post(
                                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/discountBDTSumCTN`,
                                {
                                    skuName: product.skuName,
                                    discountStartDate:
                                        myindividualProductDiscount.discountStartDate,
                                    discountEndDate:
                                        myindividualProductDiscount.discountEndDate,
                                },
                                { headers: authHeader() }
                            )

                            if (response.data.success) {
                                const available =
                                    myindividualProductDiscount.totalBudgetDiscount -
                                    response.data.payload.discountSumData
                                if (available > 0) {
                                    const discountBDT =
                                        available >=
                                            myindividualProductDiscount.discountBDT
                                            ? myindividualProductDiscount.discountBDT
                                            : available

                                    const multiple =
                                        value /
                                        myindividualProductDiscount.buyQty

                                    const discountObj = {
                                        ...myindividualProductDiscount,
                                        discountBDT:
                                            discountBDT * parseInt(multiple) >
                                                available
                                                ? available
                                                : discountBDT *
                                                parseInt(multiple),
                                    }

                                    updatedProducts[index] = {
                                        ...updatedProducts[index],
                                        productDiscount: discountObj,
                                    }
                                }
                            }
                        } else {
                            const myResult = currentDateDiscounts?.find(
                                (discount) =>
                                    discount?.discountType === 'National' && // Check if offerType is "National"
                                    discount?.skuName === product?.skuName && // Check if skuName matches product.skuName
                                    discount?.buyQty <= parseFloat(value) // Check if buyQty is less than or equal to the specified value
                            )

                            if (myResult) {
                                const response = await axios.post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/discountBDTSumCTN`,
                                    {
                                        skuName: product.skuName,
                                        discountStartDate:
                                            myResult.discountStartDate,
                                        discountEndDate:
                                            myResult.discountEndDate,
                                    },
                                    { headers: authHeader() }
                                )

                                if (response.data.success) {
                                    const available =
                                        myResult.totalBudgetDiscount -
                                        response.data.payload.discountSumData
                                    if (available > 0) {
                                        const discountBDT =
                                            available >= myResult.discountBDT
                                                ? myResult.discountBDT
                                                : available

                                        const multiple = value / myResult.buyQty

                                        const discountObj = {
                                            ...myResult,
                                            discountBDT:
                                                discountBDT *
                                                    parseInt(multiple) >
                                                    available
                                                    ? available
                                                    : discountBDT *
                                                    parseInt(multiple),
                                        }

                                        updatedProducts[index] = {
                                            ...updatedProducts[index],
                                            productDiscount: discountObj,
                                        }
                                    }
                                }
                            } else {
                                updatedProducts[index] = {
                                    ...updatedProducts[index],
                                    productDiscount: null,
                                }
                            }
                        }
                    }
                }
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        }

        setIsLoading((prevState) => ({ ...prevState, general: false }))

        updatedProducts[index] = {
            ...updatedProducts[index],
            orderQtyCtn: value,
        }

        const calculateTotalQuantityPcs =
            parseFloat(value) * parseFloat(product.pcsCTN)

        const totalQuantityPcsWithOutOffer = value * product.pcsCTN

        updatedProducts[index] = {
            ...updatedProducts[index],
            totalQuantityPcsWithOutOffer: totalQuantityPcsWithOutOffer,
        }
        updatedProducts[index] = {
            ...updatedProducts[index],
            totalOrderQtyPCS: calculateTotalQuantityPcs,
        }

        // Calculate total price
        const getPriceForUserRole = (userRole, product) => {
            switch (userRole) {
                case 'COMMISSION DISTRIBUTOR':
                    return parseFloat(product?.commPrice)
                case 'WHOLESALE DISTRIBUTOR':
                    return parseFloat(product?.wholesellPrice)
                case 'SPOT DISTRIBUTOR':
                    return parseFloat(product?.spotPrice)
                default:
                    return parseFloat(product?.distributorPrice)
            }
        }

        const totalQuantity = parseFloat(
            updatedProducts[index]?.totalQuantityPcsWithOutOffer
        )
        const price = getPriceForUserRole(
            userInfo.userRole,
            updatedProducts[index]
        )
        const totalPrice = totalQuantity * price
        updatedProducts[index] = { ...updatedProducts[index], totalPrice }
        let totalPriceWithOutDiscount = totalPrice
        if (updatedProducts[index]?.productDiscount) {
            const discount =
                parseFloat(
                    updatedProducts[index]?.productDiscount?.discountBDT
                ) || 0
            totalPriceWithOutDiscount -= discount
        }
        updatedProducts[index] = {
            ...updatedProducts[index],
            totalPriceWithOutDiscount,
        }

        const getPerCtnPriceForUserRole = (userRole, product) => {
            switch (userRole) {
                case 'COMMISSION DISTRIBUTOR':
                    return (
                        parseFloat(product?.commPrice) *
                        parseFloat(product?.pcsCTN)
                    )
                case 'WHOLESALE DISTRIBUTOR':
                    return (
                        parseFloat(product?.wholesellPrice) *
                        parseFloat(product?.pcsCTN)
                    )
                case 'SPOT DISTRIBUTOR':
                    return (
                        parseFloat(product?.spotPrice) *
                        parseFloat(product?.pcsCTN)
                    )
                default:
                    return (
                        parseFloat(product?.distributorPrice) *
                        parseFloat(product?.pcsCTN)
                    )
            }
        }

        const perCtnPrice = getPerCtnPriceForUserRole(
            userInfo?.userRole,
            updatedProducts[index]
        )
        updatedProducts[index] = { ...updatedProducts[index], perCtnPrice }

        setQuantityUpdated(true)
        setCheckedProducts(updatedProducts)
    }

    // handle remove product
    const handleRemoveProduct = async (id) => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure?',
            text: "You can't return back after this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4c8118',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
        })

        if (confirmResult.isConfirmed) {
            setIsLoading((prevState) => ({ ...prevState, general: true }))
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/${id}`
                const response = await axios.delete(url, {
                    headers: authHeader(),
                })
                if (response.data.success || response.status === 200) {
                    const result = checkedProducts.filter(
                        (product) => product.productId !== id
                    )
                    //delete the product here
                    let index = checkedProducts.findIndex(
                        (obj) => obj.productId === id
                    )

                    const done = await handleOffersForDeleteProduct(index)

                    if (done) {
                        setCheckedProducts(result)

                        Swal.fire({
                            title: 'Product has been deleted.!',
                            text: 'successfully deleted!',
                            icon: 'success',
                        })
                    }
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, general: false }))
            }
        }
    }

    // Check if all product quantities are properly provided
    useEffect(() => {
        const allQuantitiesValid = checkedProducts.every(
            (product) => parseFloat(product.orderQtyCtn) > 0
        )
        setAreProductQuantitiesValid(allQuantitiesValid)
    }, [checkedProducts])

    /* 
    Load Deposit Data Base on User ID
    Load Credit Data Base on User ID
    */
    useEffect(() => {
        if (!userInfo?.userId) return

        const depositUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositsByDealer/${userInfo?.userId}`
        const creditUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/getAllOrdersWithBankByDB/${userInfo?.userId}`

        const fetchData = async () => {
            setIsLoading((prevState) => ({
                ...prevState,
                deposit: true,
                credit: true,
            }))
            try {
                const [depositResponse, creditResponse] = await Promise.all([
                    axios.get(depositUrl, { headers: authHeader() }),
                    axios.get(creditUrl, { headers: authHeader() }),
                ])

                if (
                    depositResponse?.data?.success ||
                    depositResponse?.status === 200
                ) {
                    const result =
                        depositResponse?.data?.payload?.result?.filter(
                            (data) => data.status === 2
                        )

                    setDepositList(result)
                }

                if (creditResponse?.data?.success) {
                    const data = creditResponse?.data?.payload?.result
                    let totalCredit = 0
                    data?.forEach((item) => {
                        if (item?.status === 2 && item.crBDT) {
                            totalCredit += item.crBDT
                        }
                    })
                    setTotalCredit(parseFloat(totalCredit))
                    setCreditList(data)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({
                    ...prevState,
                    deposit: false,
                    credit: false,
                }))
            }
        }

        fetchData()
    }, [userInfo?.userId, count])


    // Calculate total deposit
    const calculateTotalDeposit = (deposit, credit) => {
        if (isLoading.deposit || isLoading.credit) return 0
        const sumData1 = deposit?.reduce((total, item) => total + item.drBDT, 0)
        const sumData2 = credit?.reduce((total, item) => total + item.drBDT, 0)
        return sumData1 - sumData2
    }

    // Handle checkbox change offer accepted
    const handleDepositCheckboxChange = (e) => {
        return
        const { value } = e.target
        setofferAccepted(value)
    }

    // handle total payable price
    const handleTotalPayablePrice = (product) => {
        if (product?.productDiscount) {
            const discountBDT = product?.productDiscount?.discountBDT ?? 0
            return product?.totalPrice - discountBDT
        } else {
            return parseFloat(product?.totalPrice)
        }
    }

    const handleDiscountPriceTotal = (product) => {
        if (product?.productDiscount?.discountBDT) {
            return parseFloat(product?.productDiscount?.discountBDT)
        } else if (product?.discountBDT) {
            return parseFloat(product?.discountBDT)
        } else {
            return 0
        }
    }

    const handleOfferProductQtyTotal = (product) => {
        if (product?.productOffer?.offerQtyPcs) {
            return parseFloat(product?.productOffer?.offerQtyPcs)
        } else if (product?.offerPc) {
            return parseFloat(product?.offerPc)
        } else {
            return 0
        }
    }

    const handleOfferProductPrice = (product) => {
        if (product?.productOffer?.offerSkuPrice) {
            return parseFloat(product?.productOffer?.offerSkuPrice)
        } else if (product?.offerSkuPrice) {
            return parseFloat(product?.offerSkuPrice)
        }
    }

    // handle total offer price
    const handleTotalOfferPrice = (product) => {
        if (
            !Object.hasOwn(product, 'productOffer') &&
            product?.offerPc &&
            product?.offerSkuPrice
        ) {
            const calculatePrice =
                Math.round(
                    product?.productOffer?.offerQtyPcs || product?.offerPc
                ) *
                Number(
                    product?.productOffer?.offerSkuPrice ||
                    product?.offerSkuPrice
                )
            return calculatePrice
        } else {
            const calculatePrice =
                Math.round(product?.productOffer?.offerQtyPcs || 0) *
                Number(product?.productOffer?.offerSkuPrice || 0)
            return calculatePrice
        }
    }

    const handleTotalComboOfferPrice = () => {
        const comboPriceTotal = comboOffer.reduce((accumulator, currentObj) => {
            return (
                accumulator +
                parseInt(currentObj.offerQtyPcs || currentObj.offerPc) *
                currentObj.offerSkuPrice
            )
        }, 0)
        return comboPriceTotal
    }

    // Calculate total payable amount
    useEffect(() => {
        let totalOrderQuantityCtn = 0
        let totalPriceCtn = 0
        let totalOrderQuantityPcs = 0
        let totalOfferPrice = 0
        let totalDiscountPrice = 0
        let totalPayablePrice = 0
        let totalOfferProductQty = 0
        checkedProducts?.forEach((product) => {
            totalOrderQuantityCtn += parseFloat(product?.orderQtyCtn)
            totalOrderQuantityPcs += parseFloat(product?.totalOrderQtyPCS)
            totalPriceCtn += product?.totalPrice
                ? parseFloat(product?.totalPrice)
                : 0
            totalOfferPrice += handleTotalOfferPrice(product) || 0
            totalDiscountPrice += handleDiscountPriceTotal(product)
            totalOfferProductQty += handleOfferProductQtyTotal(product)
            totalPayablePrice += handleTotalPayablePrice(product)
        })

        checkedProducts.total = {
            totalOrderQuantityCtn,
            totalOrderQuantityPcs,
            totalPriceCtn,
            totalOfferPrice: totalOfferPrice + handleTotalComboOfferPrice(),
            totalDiscountPrice,
            totalOfferProductQty,
            totalPayablePrice,
        }
        setTotals({
            totalOrderQuantityCtn: totalOrderQuantityCtn,
            totalOrderQuantityPcs,
            totalPriceCtn: totalPriceCtn,
            totalOfferPrice: totalOfferPrice + handleTotalComboOfferPrice(),
            totalDiscountPrice: totalDiscountPrice,
            totalOfferProductQty,
            totalPayablePrice: totalPayablePrice,
        })

        // handle total payable amount
        const handleTotalPayable = () => {
            if (offerAccepted === 'price') {
                const totalPayable =
                    parseFloat(totalPayablePrice) -
                    parseFloat(checkedProducts?.total?.totalOfferPrice)
                setTotalPayableAmount(totalPayable)
            } else {
                setTotalPayableAmount(parseFloat(totalPayablePrice))
            }
        }

        handleTotalPayable()
    }, [checkedProducts])

    // Calculate total payment and due amount
    useEffect(() => {
        const totalPayment = paymentData?.reduce((accumulator, data) => {
            // Check if BDTAmount exists and is a valid number
            if (data?.drBDT && !isNaN(parseFloat(data?.drBDT))) {
                accumulator += parseFloat(data?.drBDT)
            }
            return accumulator
        }, 0)

        // Check if checkedProducts is properly initialized and totalPayable exists and is a valid number
        const totalPayable = checkedProducts?.total?.totalPayable ?? 0
        const calculateTotalDue = paymentData[0]?.crBDT

        setTotalPayAmount(totalPayment)
        setTotalDueAmount(parseFloat(calculateTotalDue))
    }, [checkedProducts, count, paymentData])

    // Fetch checked products
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading((prevState) => ({ ...prevState, general: true }))
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders?doNo=${distributorOrderNumber}`
                const response = await axios.get(url, { headers: authHeader() })

                if (
                    response.status === 200 &&
                    response.data.payload.result.length > 0
                ) {
                    const checkProductsData = response.data.payload.result
                    let mergedProducts = []

                    setofferAccepted(checkProductsData[0]?.offerAccepted)

                    checkProductsData?.forEach((checkProduct) => {
                        let correspondingProduct = productsWithOutSorting?.find(
                            (product) =>
                                product?.skuName === checkProduct?.skuName
                        )
                        let findCheckProduct = checkedProducts?.find(
                            (product) =>
                                product?.skuName === checkProduct?.skuName
                        )

                        if (correspondingProduct && !findCheckProduct) {
                            let mergedProduct = {
                                ...checkProduct, // Include properties from checkProductsData product
                                productId: checkProduct._id, // Set _id explicitly from checkProduct
                                ...correspondingProduct, // Include properties from productsWithOutSorting product
                            }

                            // Iterate through each property of checkProduct
                            Object.keys(checkProduct).forEach((key) => {
                                // If the property exists in correspondingProduct and its value is non-zero, use the value from correspondingProduct
                                if (
                                    correspondingProduct.hasOwnProperty(key) &&
                                    correspondingProduct[key] !== 0
                                ) {
                                    mergedProduct[key] =
                                        correspondingProduct[key]
                                }
                            })

                            mergedProducts.push(mergedProduct)
                        }
                    })

                    // setCheckedProducts([...checkedProducts, ...mergedProducts]);

                    const settableProducts = [
                        ...checkedProducts,
                        ...mergedProducts,
                    ].map((item) => ({
                        ...item,
                        orderQuantity: item.orderQtyCtn,
                    }))

                    setCheckedProducts(settableProducts)

                    const databaseComboOffers = [
                        ...checkProductsData.filter(
                            (item) =>
                                item.offerNo &&
                                item.doNo == distributorOrderNumber
                        ),
                    ].map((item) => ({
                        ...item,
                        skuName: item.againstSkuName,
                        categoryName: item.againstCategoryName,
                    }))

                    setComboOffer(databaseComboOffers)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, general: false }))
            }
        }

        fetchData()
    }, [productsWithOutSorting, distributorOrderNumber])

    const checkOrderStatus = async (doNo) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/singleOrder/${doNo}`,
                { headers: authHeader() }
            )
            let status = 0
            if (response.data.success || response.status === 200) {
                status = response.data.payload.DoData[0]?.status
                return status
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        }
    }

    // Calculate remaining balance
    /*     useEffect(() => {
            if (isLoading.deposit || isLoading.credit || isLoading.payment) return;
            let totalPayableAmount = checkedProducts?.total?.totalPayablePrice || 0;
            const availableCredit = parseFloat(userInfo?.crBDTLimit) - parseFloat(totalCredit) || 0;
            const totalAvilableBalance = calculateTotalDeposit(depositList, creditList) + availableCredit || 0;
            if (offerAccepted === 'price') {
                totalPayableAmount = checkedProducts?.total?.totalPayablePrice - checkedProducts?.total?.totalOfferPrice;
            }
            //const remainingBalance = totalAvilableBalance - totalPayableAmount;
            const remainingBalance = totalAvilableBalance - totalDueAmount;
            setRemainingBalance(remainingBalance);
    
            // Cleanup function (if needed) can be placed here
            return () => {
                // Optional cleanup logic here, if needed
            };
    
        }, [depositList, creditList, totalPayableAmount, offerAccepted, count,totalDueAmount]); */

    useEffect(() => {
        if (isLoading.deposit || isLoading.credit || isLoading.payment) return

        const { totalPayablePrice = 0, totalOfferPrice = 0 } =
            checkedProducts?.total || {}
        const availableCredit =
            parseFloat(userInfo?.crBDTLimit || 0) - parseFloat(totalCredit || 0)
        const totalAvailableBalance =
            calculateTotalDeposit(depositList, creditList) + availableCredit

        const finalTotalPayableAmount =
            offerAccepted === 'price'
                ? totalPayablePrice - totalOfferPrice
                : totalPayablePrice
        const remainingBalance = totalAvailableBalance - totalDueAmount

        setRemainingBalance(remainingBalance)

        // Optional cleanup logic here, if needed
        return () => {
            // Cleanup code if necessary
        }
    }, [
        depositList,
        creditList,
        checkedProducts,
        userInfo,
        totalCredit,
        offerAccepted,
        totalDueAmount,
        isLoading.deposit,
        isLoading.credit,
        isLoading.payment,
    ])

    // Handle form submission
    const handleApproveOrder = async () => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure?',
            text: 'Reconcile the order!',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#4c8118',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Reconcile!',
        })
        if (confirmResult.isConfirmed) {
            // if (checkedDeposit?.length === 0) {
            //     await handleSubmit2();
            // } else {

            //     const deports = checkedDeposit.map((data) => {
            //         return { ...data, status: 2, _id: data._id }
            //     })

            //     try {
            //         setIsLoading(prevState => ({ ...prevState, paymentUpload: true }));
            //         const response = await axios.post(
            //             `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/updateDepositPaymentByFinnace`,
            //             deports,
            //             { headers: authHeader() }
            //         );

            //         // Check if the update was successful
            //         if (response.data.status || response.status === 200) {
            //             setCount((prevCount) => prevCount + 1); // Update count
            //             await handleSubmit2();
            //         } else {
            //             // Handle unexpected response
            //             throw new Error("Deposit payment update failed");
            //         }
            //     } catch (error) {
            //         handleApiResponseErrors(error.response);
            //     } finally {
            //         setIsLoading(prevState => ({ ...prevState, paymentUpload: true }));
            //     }

            // }

            await handleSubmit2()
        }
    }

    // PAYMENT HANDLER
    const handleSubmit2 = async () => {
        const availableBalance =
            calculateTotalDeposit(depositList, creditList) + totalPayAmount
        if (!(availableBalance >= totalPayableAmount)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have enough balance to pay this amount!',
            })
            return
        }

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/${paymentUpdateId}`,
                {
                    orderNumber: distributorOrderNumber,
                    doNo: distributorOrderNumber,
                    recordDate: new Date(),
                    // paymentMethod: "Cash",
                    // drBDT: totalDrBDT,
                    drBDT: totalPayAmount,
                    // crBDT: totalCrBDT,
                    crBDT: totalDueAmount,
                    // status: 2,
                    paymentDate: new Date(),
                },
                { headers: authHeader() }
            )
            if (response.data.success || response.status === 200) {
                setCount(count + 1)
                await handleSubmit()

                if (comboOffer.length) {
                    handleOffersSubmit()
                } else {
                    setQuantityUpdated(false)
                }
            } else {
                throw new Error('Failed to update payment data')
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading((prevState) => ({ ...prevState, general: false }))
        }
    }

    const handleOffersSubmit = async () => {
        const offers = comboOffer.map((item) => ({
            doNo: distributorOrderNumber,
            offerNo: item.offerNo || item._id,
            doDate: item.doDate || new Date(),
            zone: userInfo?.zoneName,
            region: userInfo?.regionName,
            area: userInfo?.areaName,
            dbPoint: userInfo?.dbPoint,
            dealerId: userInfo?.userId,
            distributorName: userInfo?.name || userInfo?.distributorName,
            dbType: userInfo?.userRole,
            companyName: userInfo?.companyName,
            // factoryName: product.factoryName[0],
            // categoryName: item.productCategory,
            // skuName: item.skuName,

            factoryName: '',
            categoryName: '',
            skuName: '',

            againstCategoryName:
                item.productCategory || item.againstCategoryName,
            againstSkuName: item.skuName || item.againstSkuName,

            offerName: item.conditionName || item.offerName,
            offerType: item.offerType,
            offerPc: parseInt(item.offerQtyPcs || item.offerPc),
            offerSkuPrice: item.offerSkuPrice, //* parseInt(item.offerQtyPcs || item.offerPc),
            offerSkuName: item.offerSkuName,
            offerCategoryName:
                item.offerProductCategory || item.offerCategoryName,
            offerNote: item.offerNote,
            offerStartDate: item.offerStartDate,
            offerEndDate: item.offerEndDate,
            offerAccepted: item.offerAccepted || offerAccepted,
            unitName: 'CTN',
            // status: 2
        }))

        try {
            //// first get all the orders

            setIsLoading((prevState) => ({ ...prevState, general: true }))
            const response = await axios.get(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/singleOrder/${distributorOrderNumber}`,
                { headers: authHeader() }
            )

            if (response.data.success || response.status === 200) {
                const ordersData = (response.data.payload?.DoData || []).filter(
                    (item) => item.offerNo
                )

                // then filter insertables
                const insertables = offers.filter(
                    (item) =>
                        !ordersData.some((dat) => dat.offerNo == item.offerNo)
                )

                // then filter updatables
                const updatables = offers
                    .filter((item) =>
                        ordersData.some((dat) => dat.offerNo == item.offerNo)
                    )
                    .map((its) => ({
                        ...its,
                        _id: ordersData.find((yo) => yo.offerNo == its.offerNo)
                            ._id,
                    }))

                // then filter deletables
                const deletables = ordersData
                    .filter(
                        (item) =>
                            !offers.some((dat) => dat.offerNo == item.offerNo)
                    )
                    .map((del) => ({ doNo: del.doNo, offerNo: del.offerNo }))

                if (
                    insertables.length &&
                    updatables.length &&
                    deletables.length
                ) {
                    //insert, update and delete

                    // then post request with insertables
                    const createResponse = await axios.post(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/create`,
                        insertables,
                        { headers: authHeader() }
                    )

                    if (
                        createResponse.data.status ||
                        createResponse.status === 200
                    ) {
                        // then put request with updatables
                        const updateResponse = await axios.put(
                            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                            updatables,
                            { headers: authHeader() }
                        )

                        if (
                            updateResponse.data.success ||
                            updateResponse.status === 200
                        ) {
                            //delete request here

                            const deleteResponse = await axios.post(
                                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/deleteMultiOffers`,
                                deletables,
                                { headers: authHeader() }
                            )

                            if (
                                deleteResponse.data.success ||
                                deleteResponse.status === 200
                            ) {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: 'Order Successfully Updated!',
                                    showConfirmButton: false,
                                    timer: 1500,
                                })
                            }
                        }
                    }
                } else if (
                    insertables.length &&
                    updatables.length &&
                    !deletables.length
                ) {
                    //insert and update
                    // then post request with insertables
                    const createResponse = await axios.post(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/create`,
                        insertables,
                        { headers: authHeader() }
                    )

                    if (
                        createResponse.data.success ||
                        createResponse.status === 200
                    ) {
                        const updateResponse = await axios.put(
                            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                            updatables,
                            { headers: authHeader() }
                        )

                        if (
                            updateResponse.data.success ||
                            updateResponse.status === 200
                        ) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Order Successfully Updated!',
                                showConfirmButton: false,
                                timer: 1500,
                            })
                        }
                    }
                } else if (
                    insertables.length &&
                    !updatables.length &&
                    deletables.length
                ) {
                    //insert and delete
                    // then put request with updatables
                    const createResponse = await axios.post(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/create`,
                        insertables,
                        { headers: authHeader() }
                    )

                    if (
                        createResponse.data.success ||
                        createResponse.status === 200
                    ) {
                        const deleteResponse = await axios.post(
                            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/deleteMultiOffers`,
                            deletables,
                            { headers: authHeader() }
                        )

                        if (
                            deleteResponse.data.success ||
                            deleteResponse.status === 200
                        ) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Order Successfully Updated!',
                                showConfirmButton: false,
                                timer: 1500,
                            })
                        }
                    }
                } else if (
                    insertables.length &&
                    !updatables.length &&
                    !deletables.length
                ) {
                    //insert only
                    const createResponse = await axios.post(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/create`,
                        insertables,
                        { headers: authHeader() }
                    )

                    if (
                        createResponse.data.success ||
                        createResponse.status === 200
                    ) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Order Successfully Updated!',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                } else if (
                    !insertables.length &&
                    updatables.length &&
                    deletables.length
                ) {
                    //update and delete
                    const updateResponse = await axios.put(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                        updatables,
                        { headers: authHeader() }
                    )

                    if (
                        updateResponse.data.success ||
                        updateResponse.status === 200
                    ) {
                        //delete request here

                        const deleteResponse = await axios.post(
                            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/deleteMultiOffers`,
                            deletables,
                            { headers: authHeader() }
                        )

                        if (
                            deleteResponse.data.success ||
                            deleteResponse.status === 200
                        ) {
                            Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Order Successfully Updated!',
                                showConfirmButton: false,
                                timer: 1500,
                            })
                        }
                    }
                } else if (
                    !insertables.length &&
                    updatables.length &&
                    !deletables.length
                ) {
                    //update only
                    const updateResponse = await axios.put(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                        updatables,
                        { headers: authHeader() }
                    )

                    if (
                        updateResponse.data.success ||
                        updateResponse.status === 200
                    ) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Order Successfully Updated!',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                } else if (
                    !insertables.length &&
                    !updatables.length &&
                    deletables.length
                ) {
                    //delete only
                    const deleteResponse = await axios.post(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/deleteMultiOffers`,
                        deletables,
                        { headers: authHeader() }
                    )

                    if (
                        deleteResponse.data.success ||
                        deleteResponse.status === 200
                    ) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Order Successfully Updated!',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    }
                } else {
                    //nothing to do
                }
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading((prevState) => ({ ...prevState, general: false }))
        }

        setQuantityUpdated(false)

        // return true;
    }

    // Handle form submission
    const handleSubmit = async () => {
        // Prepare order items
        const orderItems = checkedProducts.map((product) => {
            const orderItem = {
                _id: product.productId,
                productId: product.productId,
                dpCTN: product.perCtnPrice,
                pcsCTN: product.pcsCTN,
                orderQtyCtn: parseFloat(product.orderQtyCtn),
                totalOrderQtyPCS: product.totalOrderQtyPCS,
                totalPrice: product.totalPriceWithOutDiscount,
                // status: 7,
                cancelNote: product.note,
            }

            // Optional chaining used to safely access properties
            if (product.productOffer) {
                orderItem.offerPc = product?.productOffer.offerQtyPcs
                orderItem.offerSkuName = product?.productOffer?.offerSkuName
                orderItem.offerCategoryName =
                    product?.productOffer?.offerProductCategory
                orderItem.offerNote = product?.productOffer?.offerNote
                orderItem.offerStartDate = product?.productOffer?.offerStartDate
                orderItem.offerEndDate = product?.productOffer?.offerEndDate
            }

            // Optional chaining used to safely access properties
            if (product?.productDiscount) {
                orderItem.discountBDT = product?.productDiscount?.discountBDT
                orderItem.discountNote = product?.productDiscount?.discountNote
                orderItem.discountStartDate =
                    product?.productDiscount?.discountStartDate
                orderItem.discountEndDate =
                    product?.productDiscount?.discountEndDate
            }

            return orderItem
        })

        // Make a PUT request to update the order items
        try {
            setIsLoading((prevState) => ({ ...prevState, general: true }))
            const response = await axios.put(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                orderItems,
                { headers: authHeader() }
            )
            if (response.data.status || response.status === 200) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Order Successfully Reconcile!',
                    showConfirmButton: false,
                    timer: 1500,
                })
                // navigate('/finance/orders-list')
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading((prevState) => ({ ...prevState, general: false }))
        }
    }

    // Handle Total Payable Amount
    useEffect(() => {
        const dueAmount =
            parseFloat(totalPayableAmount) - parseFloat(totalPayAmount)
        setTotalDueAmount(dueAmount)
    }, [totalPayableAmount])

    // handle paid change
    const handlePaidChange = (e) => {
        const newPaid = parseFloat(e.target.value)

        if (isNaN(newPaid)) return

        // const availableBalance = calculateTotalDeposit(depositList, creditList) + totalPayAmount;
        // if (!(availableBalance >= newPaid)) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'You do not have enough balance to pay this amount!',
        //     });
        //     return
        // }

        if (newPaid > totalPayableAmount) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Paid amount cannot be greater than total payable amount!',
            })

            // Update the state to reflect totalPayableAmount and set totalDueAmount to 0
            setTotalPayAmount(totalPayableAmount)
            setTotalDueAmount(0)
            return
        }

        setTotalPayAmount(newPaid)
        const newDue = totalPayableAmount - newPaid
        setTotalDueAmount(newDue)
    }

    // VIEW IMAGE
    const viewImage = async (imageId) => {
        try {
            const response = await axios.get(
                `https://khoz.net:3015/api/v1/image/${imageId}`,
                { headers: authHeader() }
            )
            if (response.data.success && response.data.payload) {
                const data = response.data.payload
                if (!data.imageData) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No image found!',
                    })
                    return
                }

                const base64Image = response.data.payload.imageData.image

                // Check if the base64 string contains the data URL prefix and remove it if present
                const base64Prefix = 'data:image/jpeg;base64,'
                let base64String
                if (base64Image.startsWith(base64Prefix)) {
                    base64String = base64Image.substring(base64Prefix.length)
                } else {
                    base64String = base64Image
                }

                // Convert base64 to Blob
                try {
                    const byteCharacters = atob(base64String)
                    const byteNumbers = new Array(byteCharacters.length)
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i)
                    }
                    const byteArray = new Uint8Array(byteNumbers)
                    const blob = new Blob([byteArray], { type: 'image/jpeg' })

                    // Create a URL for the Blob and open it in a new tab
                    const imageUrl = URL.createObjectURL(blob)
                    window.open(imageUrl, '_blank')
                } catch (e) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Failed to open image!',
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No image found!',
                })
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        }
    }

    // handle deposit edit
    const handleDepositEdit = (id) => {
        const path = location.pathname
        sessionStorage.setItem('depositUrl', path)
        navigate(`/master/deposit/add/${id}`)
    }

    const handleDepositRemove = async (id) => {
        try {
            const { value: paymentNote } = await Swal.fire({
                title: 'Reason for cancellation',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                preConfirm: async (paymentNote) => {
                    try {
                        const response = await axios.put(
                            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/deposit/${id}`,
                            { status: 3, paymentNote: paymentNote },
                            { headers: authHeader() }
                        )

                        if (response.data.success) {
                            return true
                        } else {
                            throw new Error(response.data.message)
                        }
                    } catch (error) {
                        throw new Error(`Request failed: ${error}`)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })

            if (paymentNote) {
                setCount(count + 1)
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Order has been successfully cancelled.',
                })
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.message,
            })
        }
    }

    // Handle checkbox change
    const handleCheckboxChange = (data) => {
        if (isChecked(data)) {
            setCheckedDeposit(checkedDeposit.filter((p) => p._id !== data._id))
        } else {
            setCheckedDeposit([...checkedDeposit, data])
        }
    }

    const handleCheckAll = (event) => {
        const isChecked = event.target.checked
        if (isChecked) {
            const result = depositDataByDoNo.filter((data) => data.status !== 3)
            setCheckedDeposit(result)
        } else {
            setCheckedDeposit([])
        }
    }

    // Check if a product is checked
    const isChecked = (data) => {
        return checkedDeposit.some((p) => p._id === data._id)
    }

    // This function will handle changes in the input field
    const handleInputChange = (e, id) => {
        const { value } = e.target;
        setSchedulesByDoNo((prevData) =>
            prevData.map((item) =>
                item._id === id ? { ...item, delQtyCtn: value } : item
            )
        );
    };

    const handleReconcileQty = async (event, _id) => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure?',
            text: "You can't return back after this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4c8118',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Update',
        })

        if (confirmResult.isConfirmed) {
            try {
                setIsLoading((prevState) => ({ ...prevState, schedule: true }))
                // const response = await axios.put(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/deposit/${checkedDeposit[0]._id}`, { status: 2 }, { headers: authHeader() });
                const updatableOne = schedulesByDoNo.find(
                    (data) => data._id === _id
                )
                const delQtyCtn = updatableOne.delQtyCtn
                updatableOne.delQtyCtn = event.target.value
                updatableOne.delQtyPcs =
                    event.target.value *
                    (updatableOne.delQtyPcs / delQtyCtn || 0)
                const response = await updateSchedules([updatableOne])
                if (response.data.success) {
                    setCount(count + 1)
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Schedule has been successfully reconciled.',
                    })
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, schedule: false }))
            }
        }
    }

    const handleReconcileNote = async (event, _id) => {
        const checkedOnes = checkedProducts
        const index = checkedOnes.findIndex((data) => data._id === _id)
        checkedOnes[index].note = event.target.value
        setCheckedProducts(checkedOnes)
    }

    if (isLoading.general) return <LoadingIcon />
    if (isLoading.deposit || isLoading.payment || isLoading.credit)
        return (
            <div>
                <h1>Loading.....</h1>
            </div>
        )
    if (isLoading.paymentUpdate)
        return (
            <div>
                <h1>Loading.....</h1>
            </div>
        )
    if (isLoading.delivery)
        return (
            <div>
                <h1>Loading.....</h1>
            </div>
        )

    return (
        <>
            <PageHeader
                title={`Order No: ${distributorOrderNumber}`}
                description2={
                    <div>
                        <p
                            style={{
                                color: '#354151',
                                fontWeight: '500',
                                fontFamily: 'Poppins',
                            }}
                        >
                            DB ID: {userInfo?.userId}, Distributor Name:{' '}
                            {userInfo?.distributorName}, Mobile No:{' '}
                            {userInfo?.phoneNumber}, Address:{' '}
                            {paymentInfo?.zone +
                                ', ' +
                                paymentInfo?.region +
                                ', ' +
                                paymentInfo?.area}
                        </p>
                    </div>
                }
                className="mb-3"
            />

            <div className="bg-white">
                <div className="table-responsive scrollbar">
                    <table className="table table-bordered table-striped fs--1 mb-0">
                        <thead>
                            <tr
                                style={{
                                    fontSize: 'medium',
                                    fontWeight: '500',
                                    fontFamily: 'Poppins',
                                    color: '#354151',
                                }}
                            >
                                <th className="align-middle white-space-nowrap text-center ">
                                    Sl. No.
                                </th>
                                <th className="align-middle white-space-nowrap text-start ">
                                    SKU
                                </th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Pcs/Ctn
                                </th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Order(Ctn)
                                </th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Order(Pcs)
                                </th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    DP/Pcs
                                </th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    DP/Ctn
                                </th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Total DP(Ctn)
                                </th>
                                <th
                                    colSpan="1"
                                    className="align-middle white-space-nowrap text-center "
                                >
                                    Discount/ctn
                                </th>
                                <th
                                    colSpan="1"
                                    className="align-middle white-space-nowrap text-center"
                                >
                                    Total EDP(Ctn)
                                </th>
                                <th
                                    colSpan="1"
                                    className="align-middle white-space-nowrap text-center "
                                >
                                    Offer Product
                                </th>
                                <th
                                    colSpan="1"
                                    className="align-middle white-space-nowrap text-center "
                                >
                                    Offer Product(Pcs)
                                </th>
                                <th
                                    colSpan="1"
                                    className="align-middle white-space-nowrap text-center "
                                >
                                    Reconcile Note
                                </th>
                                {/* <th colSpan="1" className="align-middle white-space-nowrap text-center ">Action</th> */}
                            </tr>
                        </thead>
                        <tbody className="list" id="table-purchase-body">
                            {checkedProducts?.map((product, index) => {
                                const perPcPrice =
                                    userInfo?.userRole ===
                                        'SUPER DISTRIBUTOR' ||
                                        userInfo?.userRole === 'GENERAL DISTRIBUTOR'
                                        ? product.distributorPrice
                                        : userInfo?.userRole ===
                                            'COMMISSION DISTRIBUTOR'
                                            ? product.commPrice
                                            : userInfo?.userRole ===
                                                'WHOLESALE DISTRIBUTOR'
                                                ? product.wholesellPrice
                                                : userInfo?.userRole ===
                                                    'SPOT DISTRIBUTOR'
                                                    ? product.spotPrice
                                                    : ''
                                return (
                                    <tr
                                        key={index}
                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#354151',
                                        }}
                                    >
                                        <td className="align-middle text-center">
                                            {index + 1}
                                        </td>
                                        <td className="align-middle text-start">
                                            {product?.skuName}
                                        </td>
                                        <td className="align-middle text-center">
                                            {product?.pcsCTN}
                                        </td>
                                        <td className="align-middle text-center">
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                // onChange={(event) => handleOrderQuantities(event, index, product)}
                                                onBlur={(event) =>
                                                    handleOrderQuantities(
                                                        event,
                                                        index,
                                                        product
                                                    )
                                                }
                                                // defaultValue={product?.orderQtyCtn} readOnly />
                                                defaultValue={
                                                    product?.orderQtyCtn
                                                }
                                            />
                                        </td>
                                        <td className="align-middle text-center">
                                            {product?.totalOrderQtyPCS}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(perPcPrice)}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                perPcPrice * product?.pcsCTN
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {product.totalPriceWithOutDiscount
                                                ? formatNumber(
                                                    product.totalPriceWithOutDiscount
                                                )
                                                : formatNumber(
                                                    product.totalPrice +
                                                    product?.discountBDT
                                                )}
                                        </td>
                                        {/* {
                                        product?.productDiscount && product?.productDiscount?.length > 0 ? product?.productDiscount?.map((discount, index) => <td key={index} className="align-middle text-center">{discount?.discountBDT}</td>) : <td className="align-middle text-center">{product?.discountBDT && product?.discountBDT}</td>
                                    } */}
                                        <td
                                            colSpan="1"
                                            className="align-middle white-space-nowrap text-end "
                                        >
                                            {product.productDiscount
                                                ? product.productDiscount
                                                    .discountBDT
                                                : !product.orderQuantity
                                                    ? product.discountBDT
                                                    : ''}
                                        </td>

                                        <th
                                            colSpan="1"
                                            className="align-middle white-space-nowrap text-center"
                                        >
                                            {formatNumber(product?.totalPrice)}
                                        </th>
                                        {Object.hasOwn(
                                            product,
                                            'productOffer'
                                        ) || product.productOffer ? (
                                            <th
                                                colSpan="1"
                                                className="align-middle white-space-nowrap text-center "
                                            >
                                                {product.productOffer
                                                    ? product.productOffer
                                                        .offerSkuName
                                                    : ''}
                                            </th>
                                        ) : (
                                            <th
                                                colSpan="1"
                                                className="align-middle white-space-nowrap text-center "
                                            >
                                                {product.offerSkuName}
                                            </th>
                                        )}
                                        {Object.hasOwn(
                                            product,
                                            'productOffer'
                                        ) || product.productOffer ? (
                                            <th
                                                colSpan="1"
                                                className="align-middle white-space-nowrap text-center "
                                            >
                                                {product.productOffer
                                                    ? formatNumber(
                                                        product.productOffer
                                                            .offerQtyPcs
                                                    )
                                                    : ''}
                                            </th>
                                        ) : (
                                            <th
                                                colSpan="1"
                                                className="align-middle white-space-nowrap text-center"
                                            >
                                                {formatNumber(product.offerPc)}
                                            </th>
                                        )}
                                        {/* <td className="align-middle text-center">
                                        <Button variant="danger" onClick={() => handleRemoveProduct(product.productId)}>Delete</Button>
                                    </td> */}
                                        <td className="align-middle text-center">
                                            <Form.Group>
                                                <Form.Control
                                                    name="reconcileNote"
                                                    type="text"
                                                    placeholder="Reconcile Note"
                                                    value={product?.cancelNote}
                                                    onChange={(e) =>
                                                        handleReconcileNote(
                                                            e,
                                                            product?._id
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                )
                            })}

                            {comboOffer?.length ? (
                                <>
                                    {comboOffer.map((itr) => (
                                        <tr
                                            style={{
                                                fontSize: 'medium',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#354151',
                                            }}
                                        >
                                            <td className="align-middle text-center">
                                                {checkedProducts?.length + 1}
                                            </td>
                                            <td className="align-middle text-center">
                                                {itr?.conditionName ||
                                                    itr?.offerName}
                                            </td>
                                            <td className="align-middle text-center">
                                                {/* {comboOffer?.freeQty} */}
                                                {'--'}
                                            </td>
                                            <td className="align-middle text-center">
                                                {/* {comboOffer?.freeQty} */}
                                                {'--'}
                                            </td>
                                            <td className="align-middle text-center">
                                                {/* {comboOffer?.freeQty} */}
                                                {'--'}
                                            </td>
                                            <td className="align-middle text-center">
                                                {/* {comboOffer?.offerSkuPrice} */}
                                                {'--'}
                                            </td>
                                            <td className="align-middle text-center">
                                                {/* {comboOffer?.offerSkuPrice} */}
                                                {'--'}
                                            </td>
                                            <td className="align-middle text-center">
                                                {/* {formatNumber(product?.totalPrice)} */}
                                                {'--'}
                                            </td>

                                            <td
                                                colSpan="1"
                                                className="align-middle white-space-nowrap text-end "
                                            >
                                                {'--'}
                                            </td>
                                            <th
                                                colSpan="1"
                                                className="align-middle white-space-nowrap text-center "
                                            >
                                                {'--'}
                                            </th>
                                            <th
                                                colSpan="1"
                                                className="align-middle white-space-nowrap text-center "
                                            >
                                                {itr?.offerSkuName}
                                            </th>
                                            <th
                                                colSpan="1"
                                                className="align-middle white-space-nowrap text-center "
                                            >
                                                {parseInt(
                                                    itr?.offerQtyPcs ||
                                                    itr?.offerPc
                                                )}
                                            </th>
                                            <td className="align-middle text-center">
                                                {'--'}
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : null}

                            <tr
                                style={{
                                    fontSize: 'medium',
                                    fontWeight: '500',
                                    fontFamily: 'Poppins',
                                    color: '#354151',
                                    backgroundColor: '#98c1d9',
                                }}
                            >
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="2"
                                >
                                    Total
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    -
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    {
                                        checkedProducts?.total
                                            ?.totalOrderQuantityCtn
                                    }
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    {
                                        checkedProducts?.total
                                            ?.totalOrderQuantityPcs
                                    }
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    -
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    -
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    {checkedProducts?.total?.totalPriceCtn}
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    {checkedProducts?.total?.totalDiscountPrice}
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    {Math.round(
                                        checkedProducts?.total
                                            ?.totalPayablePrice
                                    )}
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    -
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    {formatNumber(
                                        checkedProducts?.total
                                            ?.totalOfferProductQty
                                    )}
                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                ></td>
                            </tr>

                            {offerAccepted === 'price' && (
                                <>
                                    <tr
                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#354151',
                                        }}
                                    >
                                        <td
                                            className="align-middle white-space-nowrap text-center"
                                            colSpan="8"
                                        ></td>
                                        <td
                                            className="align-middle white-space-nowrap text-center"
                                            colSpan="1"
                                        >
                                            অফার মূল্য
                                        </td>
                                        <td className="align-middle white-space-nowrap text-center">
                                            {formatNumber(
                                                checkedProducts?.total
                                                    ?.totalOfferPrice
                                            )}
                                        </td>
                                        <td
                                            className="align-middle white-space-nowrap text-center"
                                            colSpan="2"
                                        ></td>
                                    </tr>
                                </>
                            )}
                            <>
                                <tr
                                    style={{
                                        fontSize: 'medium',
                                        fontWeight: '500',
                                        fontFamily: 'Poppins',
                                        color: '#354151',
                                    }}
                                >
                                    <td
                                        className="align-middle white-space-nowrap text-center"
                                        colSpan="8"
                                    ></td>
                                    <td
                                        className="align-middle white-space-nowrap text-center text-black fw-bold"
                                        colSpan="1"
                                    >
                                        মোট টাকা
                                    </td>
                                    <td className="align-middle text-black fw-bold white-space-nowrap text-center">
                                        {isLoading.payment ||
                                            isLoading.deposit ||
                                            isLoading.credit
                                            ? 'Loading...'
                                            : formatNumber(
                                                totalPayableAmount
                                            ) || 0}
                                    </td>
                                    <td
                                        className="align-middle white-space-nowrap text-center"
                                        colSpan="3"
                                    ></td>
                                </tr>
                            </>
                            <>
                                <tr
                                    style={{
                                        fontSize: 'medium',
                                        fontWeight: '500',
                                        fontFamily: 'Poppins',
                                        color: '#354151',
                                    }}
                                >
                                    <td
                                        className="align-middle white-space-nowrap text-center"
                                        colSpan="8"
                                    ></td>
                                    <td
                                        className="align-middle white-space-nowrap text-center text-black fw-bold"
                                        colSpan="1"
                                    >
                                        নগদ টাকা
                                    </td>
                                    <td className="align-middle text-black fw-bold white-space-nowrap text-center">
                                        {isLoading.payment ||
                                            isLoading.deposit ||
                                            isLoading.credit ? (
                                            'Loading...'
                                        ) : (
                                            <Form.Group>
                                                <Form.Control
                                                    className="text-center"
                                                    name=""
                                                    onChange={handlePaidChange}
                                                    type="number"
                                                    placeholder=""
                                                    value={totalPayAmount} // Controlled input field
                                                />
                                            </Form.Group>
                                        )}
                                    </td>
                                    <td
                                        className="align-middle white-space-nowrap text-center"
                                        colSpan="3"
                                    ></td>
                                </tr>
                            </>
                            <>
                                <tr
                                    style={{
                                        fontSize: 'medium',
                                        fontWeight: '500',
                                        fontFamily: 'Poppins',
                                        color: '#354151',
                                    }}
                                >
                                    <td
                                        className="align-middle white-space-nowrap text-center"
                                        colSpan="8"
                                    ></td>
                                    <td
                                        className="align-middle white-space-nowrap text-center text-black fw-bold"
                                        colSpan="1"
                                    >
                                        অবশিষ্ট টাকা
                                    </td>
                                    <td className="align-middle text-black fw-bold white-space-nowrap text-center">
                                        {isLoading.payment ||
                                            isLoading.deposit ||
                                            isLoading.credit
                                            ? 'Loading...'
                                            : (
                                                <Form.Group>
                                                    <Form.Control
                                                        name=""
                                                        type="number"
                                                        placeholder=""
                                                        value={
                                                            totalDueAmount ||
                                                            0
                                                        }
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            ) || 0}
                                    </td>
                                    <td
                                        className="align-middle white-space-nowrap text-center"
                                        colSpan="3"
                                    ></td>
                                </tr>
                            </>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* <Button className="mt-3 px-5 " variant="primary" onClick={handleBack} >
            Add Products
        </Button> */}

            {/* <Card className="mb-3 mb-lg-0 mt-3">
            <Card.Header as="h6" className="bg-light">
                <div className='d-flex align-items-center justify-content-start'>
                    <h5>Do you want to receive offer</h5>
                    <div className='ms-3'>
                        <input
                            type='checkbox'
                            id='product'
                            value='product'
                            checked={offerAccepted === 'product'}
                            onChange={handleCheckboxChange}
                        />
                        <label className='me-3 ms-2 fs-1' htmlFor="product">Product</label>

                        <input
                            type='checkbox'
                            id='price'
                            value='price'
                            checked={offerAccepted === 'price'}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="price" className='ms-2 fs-1'>Price</label>
                    </div>
                </div>
            </Card.Header>
        </Card> */}

            <Card className="mb-3 mb-lg-0 mt-3">
                <Card.Header as="h6" className="bg-light">
                    <Flex justifyContent="between" alignItems="center">
                        <h5>
                            Your available balance:{' '}
                            {isLoading.payment ||
                                isLoading.deposit ||
                                isLoading.credit ? (
                                'Loading...'
                            ) : (
                                <span className="bg-success">
                                    {calculateTotalDeposit(
                                        depositList,
                                        creditList
                                    )
                                        ? formatNumber(
                                            calculateTotalDeposit(
                                                depositList,
                                                creditList
                                            )
                                        )
                                        : 0}{' '}
                                    BDT
                                </span>
                            )}
                        </h5>

                        <h5>
                            Available credit limit:{' '}
                            <span className="bg-success">
                                {/*  {(parseFloat(userInfo.crBDTLimit) - parseFloat(totalCredit)) > 0 ? formatNumber((parseFloat(userInfo.crBDTLimit) - parseFloat(totalCredit))) : 0} BDT */}
                                {isLoading.payment
                                    ? 'Loading...'
                                    : parseFloat(userInfo?.crBDTLimit) -
                                    parseFloat(totalCredit)}
                            </span>
                        </h5>

                        {isLoading.payment ||
                            isLoading.deposit ||
                            isLoading.credit
                            ? 'Loading...'
                            : remainingBalance !== 0 &&
                            quantityUpdated && (
                                <h5>
                                    Remaining balance:{' '}
                                    <span className="bg-success">
                                        {formatNumber(remainingBalance)} BDT
                                    </span>
                                </h5>
                            )}
                    </Flex>
                </Card.Header>
            </Card>

            {isLoading?.general ? (
                <Button className="mt-3 px-5 " variant="info">
                    Loading...
                </Button>
            ) : (
                <>
                    {/* disabled={!areProductQuantitiesValid || totalPayAmount > totalPayableAmount} */}
                    <Button
                        className="mt-3  px-5 mx-3"
                        variant="primary"
                        type="submit"
                        onClick={() => {
                            handleApproveOrder()
                        }}
                    >
                        Reconcile
                    </Button>
                    <Button
                        className="mt-3 px-5"
                        variant="primary"
                        onClick={() => navigate('/finance/orders')}
                    >
                        Go to List
                    </Button>
                </>
            )}

            {/* Delivery Information */}
            <Card className="bg-white mt-4">
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            {deliveriesByDoNo?.length > 0 ? (
                                <table className="table table-bordered table-striped fs--1 mb-0">
                                    <thead>
                                        <tr
                                            style={{
                                                fontSize: 'medium',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#354151',
                                            }}
                                        >
                                            {/* <th className="align-middle white-space-nowrap text-center " >
                                            <th className="align-middle white-space-nowrap text-center " ><input
                                                type="checkbox"
                                                checked={checkedDeposit?.filter(data => data.status !== 3)?.length === deliveriesByDoNo?.filter(data => data.status !== 3)?.length}
                                                onChange={(e) => handleCheckAll(e)}
                                            /></th>
                                        </th> */}
                                            <th className="align-middle white-space-nowrap text-center ">
                                                No.
                                            </th>
                                            <th className="align-middle white-space-nowrap text-start ">
                                                Do No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-start ">
                                                Challan No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-start ">
                                                Schedule No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                SKU Name
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Delivery Qty CTN
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Delivery Qty PCS
                                            </th>
                                            {/* <th className="align-middle white-space-nowrap text-center ">Amount</th>
                                        <th className="align-middle white-space-nowrap text-center ">Tran. Id/Ref. No</th>
                                        <th className="align-middle white-space-nowrap text-center ">Payment Date</th>
                                        <th className="align-middle white-space-nowrap text-center ">Depositor Mobile No</th>
                                        <th className="align-middle white-space-nowrap text-center ">Status</th>
                                        <th className="align-middle white-space-nowrap text-center ">Note</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Action
                                        </th> */}
                                        </tr>
                                    </thead>
                                    <tbody
                                        className="list"
                                        id="table-purchase-body"
                                    >
                                        {deliveriesByDoNo?.map(
                                            (data, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        style={{
                                                            fontSize: 'medium',
                                                            fontWeight: '500',
                                                            fontFamily:
                                                                'Poppins',
                                                            color: '#354151',
                                                        }}
                                                    >
                                                        {/* <td className="align-middle text-center"><input
                                                    type="checkbox"
                                                    checked={isChecked(data)}
                                                    onChange={() => handleCheckboxChange(data)}
                                                    disabled={data?.status === 3}
                                                /></td> */}
                                                        <td className="align-middle text-center">
                                                            {index + 1}
                                                        </td>
                                                        <td
                                                            className="align-middle text-center"
                                                            style={{
                                                                minWidth:
                                                                    '200px',
                                                            }}
                                                        >
                                                            {data?.doNo}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {data?.challanNo}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {data?.scheduleNo}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {data?.skuName}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {data?.delQtyCtn}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {data?.delQtyPcs}
                                                        </td>
                                                        {/* <td className="align-middle text-center">{data?.trId}</td>
                                                <td className="align-middle text-center">{
                                                    data?.paymentDate ? new Date(data?.paymentDate).toLocaleDateString() : ""
                                                }</td>
                                                <td className="align-middle text-center">{data?.depositorPhone}</td>
                                                <td className="align-middle text-center">
                                                    {data?.status === 2 && (
                                                        <h6 style={{ backgroundColor: "green", color: "white", display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
                                                            <i className="fas fa-check-circle" style={{ marginRight: "5px" }}></i>
                                                            Approved
                                                        </h6>
                                                    )}
                                                    {data?.status === 1 && (
                                                        <h6 style={{ backgroundColor: "blue", color: "white", display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
                                                            <i className="fas fa-thumbs-up" style={{ marginRight: "5px" }}></i>
                                                            Approved By Account
                                                        </h6>
                                                    )}
                                                    {data?.status === 0 && (
                                                        <h6 style={{ backgroundColor: "orange", color: "white", display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
                                                            <i className="fas fa-hourglass-half" style={{ marginRight: "5px" }}></i>
                                                            Pending..
                                                        </h6>
                                                    )}
                                                    {data?.status === 3 && (
                                                        <h6 style={{ backgroundColor: "red", color: "white", display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
                                                            <i className="fas fa-times-circle" style={{ marginRight: "5px" }}></i>
                                                            Rejected
                                                        </h6>
                                                    )}
                                                </td>


                                                <td className="align-middle text-center" style={{ minWidth: "200px" }}>{data?.paymentNote}</td>

                                                <td>
                                                    <Dropdown className="d-inline mx-2">
                                                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                                                            ...
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item >
                                                                <>
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        size="sm"
                                                                        icon="edit"
                                                                        transform="shrink-2"
                                                                        iconAlign="middle"
                                                                        className="me-2"
                                                                        onClick={() => handleDepositEdit(data?._id)}
                                                                    >
                                                                        <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                                                    </IconButton>
                                                                </>
                                                            </Dropdown.Item>
                                                            {
                                                                data?.trId ? <Dropdown.Item onClick={() => viewImage(data?.trId)}>
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        size="sm"
                                                                        icon="show"
                                                                        transform="shrink-2"
                                                                        iconAlign="middle"
                                                                        className="me-2"
                                                                    >
                                                                        <span className="d-none d-xl-inline-block ms-1">View Attachment</span>
                                                                    </IconButton>
                                                                </Dropdown.Item> : <Dropdown.Item>
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        size="sm"
                                                                        icon="show"
                                                                        transform="shrink-2"
                                                                        iconAlign="middle"
                                                                        className="me-2"
                                                                    >
                                                                        <span className="d-none d-xl-inline-block ms-1">No Attachment</span>
                                                                    </IconButton>
                                                                </Dropdown.Item>
                                                            }
                                                            {data?.status !== 3 && <Dropdown.Item >
                                                                <IconButton
                                                                    onClick={() => handleDepositRemove(data?._id)}
                                                                    variant="falcon-default"
                                                                    size="sm"
                                                                    icon="trash-alt"
                                                                    iconAlign="middle"
                                                                    className="d-none d-sm-block me-2"

                                                                >
                                                                    <span className="d-none d-xl-inline-block ms-1">Reject</span>
                                                                </IconButton>
                                                            </Dropdown.Item>}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td> */}
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center p-3 fs-2">
                                    No Delivery Found This Orders
                                </div>
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Card>

            {/* Schedule Information */}
            <Card className="bg-white mt-4">
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            {schedulesByDoNo?.length > 0 ? (
                                <table className="table table-bordered table-striped fs--1 mb-0">
                                    <thead>
                                        <tr
                                            style={{
                                                fontSize: 'medium',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#354151',
                                            }}
                                        >
                                            <th className="align-middle white-space-nowrap text-center ">
                                                No.
                                            </th>
                                            <th className="align-middle white-space-nowrap text-start ">
                                                Do No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-start ">
                                                Schedule No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                SKU Name
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Delivery Qty CTN
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Delivery Qty PCS
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Reconcile
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        className="list"
                                        id="table-purchase-body"
                                    >
                                        {schedulesByDoNo?.map((data, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    style={{
                                                        fontSize: 'medium',
                                                        fontWeight: '500',
                                                        fontFamily: 'Poppins',
                                                        color: '#354151',
                                                    }}
                                                >
                                                    <td className="align-middle text-center">
                                                        {index + 1}
                                                    </td>
                                                    <td
                                                        className="align-middle text-center"
                                                        style={{
                                                            minWidth: '200px',
                                                        }}
                                                    >
                                                        {data?.doNo}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {data?.scheduleNo}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {data?.skuName}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {data?.delQtyCtn}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {data?.delQtyPcs}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {/* <input type="text" value={data?.delQtyCtn} onChange={(e) => handleReconcileQty(e, data?._id)} /> */}

                                                        <Form.Group>
                                                            <Form.Control
                                                                name="reconcileQty"
                                                                type="number"
                                                                placeholder=""
                                                                value={
                                                                    data?.delQtyCtn

                                                                }
                                                                onChange={(e) => handleInputChange(e, data._id)}
                                                                onBlur={(e) =>
                                                                    handleReconcileQty(
                                                                        e,
                                                                        data?._id
                                                                    )
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center p-3 fs-2">
                                    No Schedule Found This Orders
                                </div>
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default ReconcileOrderStep2
