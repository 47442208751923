import axios from 'axios'
import { Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Target from "./TargetFiles/index";
import { useState } from "react";
import { useSelector } from 'react-redux'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import Swal from 'sweetalert2'
import useUserRole from '../../../../hooks/useUserRole';

const index = () => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([]);
    const [targetAndAchievement, setTargetAndAchievement] = useState({});
    const { userId, zoneName, regionName, areaName } = useSelector(state => state.auth.user.data);
    const userRole = useUserRole();

    const handleSearch = () => {
        if (!selectedMonth) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a month!',
            });
        }
        if (!selectedYear) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a year!',
            });
        }
        if (!selectedYear) return alert("Please select a year");
        if (selectedMonth && selectedYear) {
            const month = selectedMonth.getMonth() + 1;
            const year = selectedYear.getFullYear();

            setIsLoading(true);
            const fetchData = async () => {
                let targetUrl;
                let ordersUrl;

                if (userRole === "GENERAL DISTRIBUTOR" || userRole === "COMMISSION DISTRIBUTOR" || userRole === "WHOLESALE DISTRIBUTOR" || userRole === "SPOT DISTRIBUTOR") {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrderTarget/${userId}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrdersVsTarget/${userId}/${year}/${month}`;
                } else if (userRole === "ASM") {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/AreaWiseOrderTarget/${areaName}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/AreaWiseOrdersVsTarget/${areaName}/${year}/${month}`;
                } else if (userRole === "RSM") {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/RegioniseOrderTarget/${regionName}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/RegionWiseOrdersVsTarget/${regionName}/${year}/${month}`;
                }
                else if (userRole === "DSM") {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/ZonewiseOrderTarget/${zoneName}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/ZoneWiseOrdersVsTarget/${zoneName}/${year}/${month}`;
                }

                try {
                    const [targetResponse, ordersResponse] = await Promise.all([
                        axios.get(targetUrl, { headers: authHeader() }),
                        axios.get(ordersUrl, { headers: authHeader() })
                    ]);
                    console.log("Target", targetResponse, "Order", ordersResponse);
                    if ((targetResponse.data.success || targetResponse.status === 200) &&
                        (ordersResponse.data.success || ordersResponse.status === 200)) {

                        const targetData = targetResponse.data.payload.targetInfo;
                        const orderVsTarget = ordersResponse.data.payload.orderVsTarget;
                        let totalTargetCnt = 0;
                        let totalAchievementCnt = 0;
                        let totalAchievementAmount = 0;
                        const combinedData = targetData.map(targetItem => {
                            totalTargetCnt += targetItem.targetQuantityCtn;
                            const order = orderVsTarget.find(orderItem => orderItem.skuName === targetItem.skuName);
                            if (order) {
                                totalAchievementCnt += order.orderQtyCtn;
                                totalAchievementAmount += parseFloat(order.orderQtyCtn) * parseFloat(targetItem.ctnPrice);
                            }
                            return order ? { ...targetItem, ...order } : targetItem;
                        });

                        setOrders(combinedData);
                        setTargetAndAchievement({
                            totalTargetCnt,
                            totalAchievementCnt,
                            totalAchievementAmount
                        });
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }

    if (isLoading) {
        return <LoadingIcon />
    }
    return (
        <>
            {/* <div className="d-flex align-items-center justify-content-start "> */}
            <Container fluid>
            <Row xs={1} md={3} className="justify-content-center">
                <Col  className="text-md-start text-center">
                    <label className="form-label me-2">Select Month: </label>
                    <Form.Group className="md-mb-3 me-2">
                        <DatePicker
                            selected={selectedMonth}
                            onChange={(date) => setSelectedMonth(date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText="Select Month"
                            className="form-control"
                        />
                    </Form.Group>
                </Col>
                <Col  className="text-md-start text-center">
                    <label className="form-label me-2">Select Year: </label>
                    <Form.Group className="md-mb-3">
                        <DatePicker
                            selected={selectedYear}
                            onChange={(date) => setSelectedYear(date)}
                            dateFormat="yyyy"
                            showYearPicker
                            placeholderText="Select Year"
                            className="form-control"
                        />
                    </Form.Group>
                </Col>
                <Col className='mt-4 text-md-start text-center'>
                    <Form.Group className="mb-3 ms-3">
                        <button className="btn btn-primary px-5" onClick={() => handleSearch()}>Search</button>
                    </Form.Group>
                </Col>
            </Row>
            {/* </div> */}
            </Container>

            <Row className="g-3 mb-3">
                <Target orders={orders} targetAndAchievement={targetAndAchievement} />
            </Row>
        </>
    )
}
export default index;