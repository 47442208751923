import axios from "axios";
import handleApiResponseErrors from "helpers/handleApiResponse";
import useUserData from "hooks/useUserData";
import SimpleBarReact from 'simplebar-react'
import { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { authHeader } from "utils";
import { formatNumber } from "helpers/utils";
import CollapseOrder from "./CollapseOrder";
import PageHeader from "components/common/PageHeader";
import LoadingIcon from "helpers/LoadingIcon";
import PendingDeliveriesPrint from "./PendingDeliveriesPrint";


const PendingDeliveries = () => {
    const [step, setStep] = useState({
        step: 1,
        reload: false
    });
    const [isLoading, setIsLoading] = useState(false);
    const [expand, setExpand] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [pendingDeliveries, setPendingDeliveries] = useState([]);
    const { factoryName } = useUserData();


    // Get Pending Deliveries Data
    useEffect(() => {
        if (!factoryName) return;
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_DISTRIBUTION_MANAGEMENT_BASE_URL}orders-distribution/TrackLoadPendingDel`, { factoryName: factoryName }, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const pendingDeliveriesData = response.data.payload.TrackLoading;
                    setPendingDeliveries(pendingDeliveriesData);
                }
            } catch (error) {
                handleApiResponseErrors(error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [factoryName])


    // Checkbox Handler
    const expandHandler = (data) => {
        const pendingData = pendingDeliveries;
        pendingData.forEach((element) => {
            element.open = false
        })
        const index = pendingData.findIndex((item) => item._id == data._id)
        pendingData[index].open = true
        setPendingDeliveries(pendingData);
        setExpand(!expand);
    }



    // Check All Handler
    const handleCheckAll = (event) => {
        const isChecked = event.target.checked
        if (isChecked) {
            const mainData = [];
            const data = [];
            pendingDeliveries.forEach((item) => {
                item.trackLoading.forEach((order) => {
                    data.push({ ...order, isChecked: true })
                });

                mainData.push({ ...item, isChecked: true, trackLoading: data })

            });
            setPendingDeliveries(mainData);
        } else {
            const mainData = [];
            const data = [];
            pendingDeliveries.forEach((item) => {
                item.trackLoading.forEach((order) => {
                    data.push({ ...order, isChecked: false })
                });

                mainData.push({ ...item, isChecked: false, trackLoading: data })

            });
            setPendingDeliveries(mainData);
        }
    }

    // Checkbox Change Handler
    const handleCheckboxChange = (order) => {
        const mainData = [];
        const data = [];
        const item = pendingDeliveries.forEach((item) => item._id == order._id);
        if (order?.isChecked) {
            pendingDeliveries.forEach((item) => {
                item.trackLoading.forEach((order) => {
                    data.push({ ...order, isChecked: false })
                });

                mainData.push({ ...item, isChecked: false, trackLoading: data })

            });
            setPendingDeliveries(mainData);
        } else {
            pendingDeliveries.forEach((item) => {
                item.trackLoading.forEach((order) => {
                    data.push({ ...order, isChecked: true })
                });

                mainData.push({ ...item, isChecked: true, trackLoading: data })

            });
            setPendingDeliveries(mainData);
        }

    }

    // Select Collapse All Handler
    const handleSelectCollapseAll = (event, datas) => {
        const isChecked = event.target.checked;

        const data = [];
        if (isChecked) {
            datas?.trackLoading?.forEach((order) => {
                data.push({ ...order, isChecked: true })
            });
        } else {
            datas?.trackLoading?.forEach((order) => {
                data.push({ ...order, isChecked: false })
            });
        }

        const mainData = [];
        pendingDeliveries.forEach((item) => {
            if (item._id == datas?._id) {
                mainData.push({ ...item, trackLoading: data })
            } else {
                mainData.push(item)
            }
        });

        setPendingDeliveries(mainData);
    }


    // Select Collapse Single Handler
    const handleSelectCollapseSingle = (event, datas, id) => {
        const isChecked = event.target.checked;

        const data = [];
        datas?.trackLoading?.forEach((order) => {
            if (order._id == id) {
                if (isChecked) {
                    data.push({ ...order, isChecked: true });
                } else {
                    data.push({ ...order, isChecked: false });
                }
            } else {
                data.push(order);
            }
        });

        const mainData = [];
        pendingDeliveries.forEach((item) => {
            if (item._id == datas?._id) {
                mainData.push({ ...item, trackLoading: data })
            } else {
                mainData.push(item)
            }
        });

        setPendingDeliveries(mainData);
    }


    // Get Checked Orders
    const getCheckedOrders = () => {
        const checkedOrders = [];
        pendingDeliveries.forEach((item) => {
            item.trackLoading.forEach((order) => {
                if (order.isChecked) {
                    checkedOrders.push(order);
                }
            });
        });

        return checkedOrders;
    }



    // Set Selected Data
    useEffect(() => {
        const checkedOrders = getCheckedOrders();
        setSelectedData(checkedOrders);
    }, [pendingDeliveries])




    // Print Orders
    const handleOrderPrint = () => {
        setStep(prevState => ({ ...prevState, step: 2 }));
    }

    if (isLoading) return <LoadingIcon />

    return (
        <>

            {step.step === 1 && <>
                <PageHeader title="Pending Deliveries" className="mb-3" />
                <Card className="p-0" style={{ maxWidth: '100%' }}>
                    <Card.Body>
                        <div className="fs--1">
                            <SimpleBarReact>

                                {pendingDeliveries?.length > 0 ? (
                                    <table className="table table-bordered table-striped fs--1 mb-0">
                                        <thead className="bg-light">
                                            <tr
                                                style={{
                                                    fontSize: 'medium',
                                                    fontWeight: '500',
                                                    fontFamily: 'Poppins',
                                                    color: '#354151',
                                                }}
                                            >
                                                <th className="align-middle white-space-nowrap text-center ">
                                                    ...
                                                </th>
                                                <th className="align-middle white-space-nowrap text-center ">
                                                    <input
                                                        type="checkbox"
                                                        checked={pendingDeliveries?.every(order => order.isChecked)}
                                                        onChange={(e) =>
                                                            handleCheckAll(e)
                                                        }
                                                    />
                                                </th>

                                                <th className="align-middle white-space-nowrap text-center ">
                                                    SL
                                                </th>

                                                <th className="align-middle white-space-nowrap text-center ">
                                                    Distributor Name
                                                </th>

                                                <th className="align-middle white-space-nowrap text-center ">
                                                    Del Qty Ctn
                                                </th>
                                                <th className="align-middle white-space-nowrap text-center ">
                                                    Del Qty Pcs
                                                </th>
                                                <th className="align-middle white-space-nowrap text-center ">
                                                    Weight Per PC
                                                </th>
                                                <th className="align-middle white-space-nowrap text-center ">
                                                    Offer Qty
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody
                                            className="list"
                                            id="table-purchase-body"
                                        >
                                            {pendingDeliveries?.map(
                                                (item, index) => {
                                                    return (
                                                        <>
                                                            <tr
                                                                key={index}
                                                                style={{
                                                                    fontSize:
                                                                        'medium',
                                                                    fontWeight:
                                                                        '500',
                                                                    fontFamily:
                                                                        'Poppins',
                                                                    color: '#354151',
                                                                }}
                                                            >
                                                                <td className="align-middle text-center border-0">
                                                                    <Button
                                                                        onClick={() =>
                                                                            expandHandler(
                                                                                item
                                                                            )
                                                                        }
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={
                                                                            item.open
                                                                        }
                                                                        variant="falcon-primary"
                                                                        className="custom-button" // Apply the custom class
                                                                    >
                                                                        {item.open &&
                                                                            expand
                                                                            ? '-'
                                                                            : '+'}
                                                                    </Button>
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        // checked={isChecked(
                                                                        //     order
                                                                        // )}
                                                                        onChange={() =>
                                                                            handleCheckboxChange(
                                                                                item
                                                                            )
                                                                        }
                                                                        checked={item?.trackLoading?.every(order => order.isChecked)}
                                                                    />
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {index + 1}
                                                                </td>

                                                                <td className="align-middle text-center">
                                                                    {
                                                                        item?.distributorName
                                                                    }
                                                                </td>


                                                                <td className="align-middle text-center">
                                                                    {
                                                                        formatNumber(item?.delQtyCtn)
                                                                    }
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {
                                                                        formatNumber(item?.delQtyPcs)
                                                                    }
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {
                                                                        formatNumber(item?.weightPerPC)
                                                                    }
                                                                </td>

                                                                <td className="align-middle text-center">
                                                                    {
                                                                        formatNumber(item?.offerQtyPCS)
                                                                    }
                                                                </td>

                                                            </tr>
                                                            <tr
                                                                style={{
                                                                    display:
                                                                        item.open &&
                                                                            expand
                                                                            ? 'table-row'
                                                                            : 'none',
                                                                }}
                                                            >
                                                                <td colSpan={15}>
                                                                    <div className="p-0">
                                                                        <CollapseOrder
                                                                            data={item}
                                                                            handleSelectCollapseAll={handleSelectCollapseAll}
                                                                            handleSelectCollapseSingle={
                                                                                handleSelectCollapseSingle
                                                                            }

                                                                        ></CollapseOrder>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="text-center p-4">
                                        <h5>No Pending Devliveries.</h5>
                                    </div>
                                )}

                            </SimpleBarReact>

                        </div>


                    </Card.Body>
                </Card>

                <div className="d-flex justify-content-end">
                    <Button className="mt-3" variant="primary" disabled={selectedData?.length === 0} onClick={() => handleOrderPrint()}>
                        Print Selected Orders
                    </Button>
                </div>
            </>}
            {
                step.step === 2 && <PendingDeliveriesPrint setStep={setStep} data={selectedData} />
            }

        </>
    )
}

export default PendingDeliveries;