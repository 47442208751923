import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { authHeader } from "utils";
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoadingIcon from "helpers/LoadingIcon";
import educations from "data/educations";
import handleApiResponseErrors from "helpers/handleApiResponse";

const AssignedSalesOfficerForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [distributorList, setDistributorList] = useState([]);
    const [routesList, setRoutesList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [salesOfficer, setSalesOfficer] = useState([]);
    const [salesOfficerList, setSalesOfficerList] = useState([]);
    const [assignedSalesOfficerInfo, setAssignedSalesOfficerInfo] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();


    // Load Single Data
    useEffect(() => {
        if (updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `assigned-sales-officers/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    const apiResponse = response.data;
                    if (apiResponse.success || apiResponse.statusCode === 200) {
                        setAssignedSalesOfficerInfo(apiResponse.data);
                    }
                })
                .catch((error) => handleApiResponseErrors(error.response))
                .finally(
                    setIsLoading(false)
                )
        }

    }, [updateID])




    // Load All Routes 
    useEffect(() => {
        setIsLoading(true);
        const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + 'routes';
        axios.get(url, { headers: authHeader() })
            .then(response => {
                const apiResponse = response.data;
                const modifyRoutes = apiResponse.data.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                        value: item.id

                    }
                })
                setRoutesList(modifyRoutes);

            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(
                setIsLoading(false)
            )
    }, [])

    // Load All Distributor
    useEffect(() => {
        setIsLoading(true);
        const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + 'distributors';
        axios.get(url, { headers: authHeader() })
            .then(response => {
                setIsLoading(false);
                const modifyDistributor = response.data?.map(item => {
                    return {
                        id: item.id,
                        label: item.distributor_name,
                        value: item.id

                    }
                })
                setDistributorList(modifyDistributor);

            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error.message)
            })
    }, [])

    // Load All Employee
    useEffect(() => {
        setIsLoading(true);
        const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + 'employees';
        axios.get(url, { headers: authHeader() })
            .then(response => {

                const modifyEmployee = response.data?.data?.map(item => {
                    return {
                        id: item.id,
                        label: `${item.first_name} ${item.middle_name} ${item.last_name}`,
                        value: item.id

                    }
                })
                setEmployeeList(modifyEmployee);

            })
            .catch(error => {
                toast.error(error.message)
            }).finally(
                setIsLoading(false)
            )
    }, [])



    const handleSubmit = (values, actions) => {


        const handleInfo = {
            route_id: values.route_id.value,
            employee_id: values.employee_id.value,
            scheduled_outlet: values.scheduled_outlet,
            visited_outlet: values.visited_outlet,
            visiting_frequency: values.visiting_frequency,
            distributor_id: values.distributor_id.value,
            work_on_sat: values.work_on_sat,
            work_on_sun: values.work_on_sun,
            work_on_mon: values.work_on_mon,
            work_on_tue: values.work_on_tue,
            work_on_wed: values.work_on_wed,
            work_on_thu: values.work_on_thu,
            work_on_fri: values.work_on_fri,
            status: true
        }





        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + 'assigned-sales-officers';
            axios.post(url, handleInfo, { headers: authHeader() })
                .then(response => {

                    if (response.data.success || response.data.statusCode === 200) {
                        navigate("/assignedSalesOfficer")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `assigned-sales-officers/${updateID}`;
            axios.put(updateUrl, handleInfo, { headers: authHeader() })
                .then(response => {

                    if (response.data.success || response.data.statusCode === 200) {
                        navigate("/assignedSalesOfficer")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response);

                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const productSchema = Yup.object().shape({

        route_id: Yup.object().required("Please enter a value for the Route field"),
        employee_id: Yup.object().required("Please enter a value for the Employee field"),
        scheduled_outlet: Yup.string().required("Please enter a value for the Scheduled field"),
        visiting_frequency: Yup.string().required("Please enter a value for the Visiting Frequency field"),
        distributor_id: Yup.object().required("Please enter a value for the Distributor field"),
    });


    return (
        <>
            <PageHeader
                title={updateID ? 'Update Assigned Sales Officer' : 'Add New Assigned Sales Officer'}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            route_id: routesList?.find(item => item.id == assignedSalesOfficerInfo?.route_id) || null,
                            employee_id: employeeList?.find(item => item.id == assignedSalesOfficerInfo?.employee_id) || null,
                            scheduled_outlet: assignedSalesOfficerInfo.scheduled_outlet || "",
                            visited_outlet: assignedSalesOfficerInfo.visited_outlet || false,
                            visiting_frequency: assignedSalesOfficerInfo.visiting_frequency || "",
                            distributor_id: distributorList?.find(item => item.id == assignedSalesOfficerInfo?.distributor_id) || null,
                            work_on_sat: assignedSalesOfficerInfo.work_on_sat || false,
                            work_on_sun: assignedSalesOfficerInfo.work_on_sun || false,
                            work_on_mon: assignedSalesOfficerInfo.work_on_mon || false,
                            work_on_tue: assignedSalesOfficerInfo.work_on_tue || false,
                            work_on_wed: assignedSalesOfficerInfo.work_on_wed || false,
                            work_on_thu: assignedSalesOfficerInfo.work_on_thu || false,
                            work_on_fri: assignedSalesOfficerInfo.work_on_fri || false,


                        }}
                        validationSchema={productSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            resetForm
                        }) => {
                            console.log(values)
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Route</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={routesList}
                                                placeholder="Select Route"
                                                classNamePrefix="react-select"
                                                name="route_id"
                                                value={values.route_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "route_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {
                                                errors.route_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.route_id}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Employee</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={employeeList}
                                                placeholder="Select Employee"
                                                classNamePrefix="react-select"
                                                name="employee_id"
                                                value={values.employee_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "employee_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {
                                                errors.employee_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.employee_id}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>

                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Scheduled Outlet</Form.Label>
                                            <Form.Control
                                                name="scheduled_outlet"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Scheduled Outlet"
                                                required
                                                value={values.scheduled_outlet}
                                            />
                                            {touched.scheduled_outlet && errors.scheduled_outlet && (
                                                <div style={{ color: "red" }}>
                                                    {errors.scheduled_outlet}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Visiting Frequency</Form.Label>
                                            <Form.Control
                                                name="visiting_frequency"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Visiting Frequency"
                                                required
                                                value={values.visiting_frequency}
                                            />
                                            {touched.visiting_frequency && errors.visiting_frequency && (
                                                <div style={{ color: "red" }}>
                                                    {errors.visiting_frequency}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Distributor</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributorList}
                                                placeholder="Select Distributor"
                                                classNamePrefix="react-select"
                                                name="distributorId"
                                                value={values.distributor_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "distributor_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {
                                                errors.distributor_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.distributor_id}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group className="mt-4" as={Col} md="6" controlId="exampleState">
                                            {/* <Form.Label>Add Permission</Form.Label> */}
                                            <Form.Check
                                                name="visited_outlet"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.visited_outlet}
                                                type="checkbox"
                                                label="Visited Outlet"
                                                value={values.visited_outlet}
                                            />
                                            {touched.visited_outlet && errors.visited_outlet && (
                                                <div style={{ color: "red" }}>
                                                    {errors.visited_outlet}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row >
                                        <Form.Label>Select Day :</Form.Label>
                                        <div className="my-3 ">
                                            <Form.Check
                                                inline
                                                name="work_on_sat"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.work_on_sat}
                                                type="checkbox"
                                                label="SAT"
                                                value={values.work_on_sat}

                                            />
                                            <Form.Check
                                                inline
                                                label="SUN"
                                                name="work_on_sun"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.work_on_sun}
                                                type="checkbox"
                                                value={values.work_on_sun}
                                            />
                                            <Form.Check
                                                inline
                                                label="MON"
                                                name="work_on_mon"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.work_on_mon}
                                                type="checkbox"
                                                value={values.work_on_mon}
                                            />
                                            <Form.Check
                                                inline
                                                label="TUE"
                                                name="work_on_tue"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.work_on_tue}
                                                type="checkbox"
                                                value={values.work_on_tue}
                                            />
                                            <Form.Check
                                                inline
                                                label="WED"
                                                name="work_on_wed"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.work_on_wed}
                                                type="checkbox"
                                                value={values.work_on_wed}
                                            />
                                            <Form.Check
                                                inline
                                                label="THU"
                                                name="work_on_thu"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.work_on_thu}
                                                type="checkbox"
                                                value={values.work_on_thu}
                                            />
                                            <Form.Check
                                                inline
                                                label="FRI"
                                                name="work_on_fri"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.work_on_fri}
                                                type="checkbox"
                                                value={values.work_on_fri}
                                            />

                                        </div>
                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"

                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/route")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>

    );
};

export default AssignedSalesOfficerForm;


