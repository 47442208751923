import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import OfferReceivedPanelConfirm from "./OfferReceivedPanelConfirm";
import Flex from 'components/common/Flex';


const OfferReceivedPanel = () => {
    const [step, setStep] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedExpiryDate, setSelecteExpitydDate] = useState(null);
    const [count, setCount] = useState(0);
    const [batchNo, setBatchNo] = useState(null);
    const [productsData, setProductsData] = useState([]);
    const [checkedProducts, setCheckProducts] = useState([]);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);



    console.log("selectedDate", selectedDate);
    console.log("selectedExpiryDate", selectedExpiryDate);
    console.log("productsData", productsData);

    // DATE
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // DATE
    const handleExpityDateChange = (date) => {
        setSelecteExpitydDate(date);
    };



    const handleReceivedProducts = (event, index, product, fieldName) => {
        const { value } = event.target;
        const updatedProducts = [...productsData];

        // Depending on the fieldName, perform different actions
        // switch (fieldName) {
        //     case 'ctn':
        //         const calculatedPcs = value * product?.pcsCTN;
        //         updatedProducts[index].ctn = value;
        //         updatedProducts[index].pcs = calculatedPcs;
        //         break;
        //     case 'pcs':
        //         updatedProducts[index].pcs = value;
        //         break;
        //     default:
        //         break;
        // }

        if (value > updatedProducts[index].OfferRcvQty) {

            updatedProducts[index].updatedQuantity = updatedProducts[index].OfferRcvQty

        } else if (value <= 0) {
            updatedProducts[index].updatedQuantity = 0
        } else {
            updatedProducts[index].updatedQuantity = value

        }






        setProductsData(updatedProducts);
    };


    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            // try {
            //     const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?search=${searchText}`;
            //     const response = await axios.get(url, { headers: authHeader() });
            //     console.log(response)
            //     if (response.data.success || response.status === 200) {
            //         const exactMatchProducts = await response?.data?.payload?.products
            //             ?.filter(product =>
            //                 product?.factoryName?.every(factory =>
            //                     factory === distributorInfo?.factoryName
            //                 )
            //             )

            //         // setProductsData(exactMatchProducts);

            //         // const url2 = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers`;

            //         const response2 = await axios.get(url2, { headers: authHeader() });
            //         console.log(response2)
            //         if (response2.data.success || response2.status === 200) {
            //             const offers = response2?.data?.payload?.offers?.filter(it=> it.offerUnit=="CTN");

            //             const mapped= []

            //             for (let i = 0; i < offers.length; i++) {
            //                 const element = offers[i];
            //                 const found= exactMatchProducts.find(item=> item.skuName== element.skuName);
            //                 if (found) {
            //                     mapped.push(element);
            //                 }

            //             }
            //             setProductsData(mapped);
            //             // setProductsData(offers);
            //         }

            //         //////////////////////////////////
            //     }
            // } catch (error) {
            //     handleApiResponseErrors(error.response);
            // } finally {
            //     setIsLoading(false);
            // }

            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/OfferRcvList/${distributorInfo?.factoryName}`;
                const response = await axios.get(url, { headers: authHeader() });
                console.log(response)
                if (response.data.success || response.status === 200) {
                    const exactMatchProducts = await response?.data?.payload?.offerRcv


                    setProductsData(exactMatchProducts.map(item => ({ ...item, updatedQuantity: item.OfferRcvQty, checked: false })));

                    //////////////////////////////////
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }



        };

        fetchData();
    }, [searchText, count, distributorInfo?.factoryName]);

    console.log(productsData)

    const handleNext = () => {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select a date",
                icon: "warning"
            });
            return;
        }
        const checkedProducts = productsData.filter((product) => product.pcs);
        if (checkedProducts?.length > 0) {
            setCheckProducts(checkedProducts);
            setStep(2);

        } else {
            Swal.fire({
                title: "Please fill the quantity",
                icon: "warning"
            });
            return;
        }
    }


    const handleSubmit= async ()=> {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select a date",
                icon: "warning"
            });
            return;
        }


        const checkedProducts = productsData.filter((product) => product.checked);


        const receivedProduct = checkedProducts.map((item) => ({
            _id: item._id,
            companyName: item.companyName,
            factoryName: item.factoryName,
            OfferRcvQty: item.updatedQuantity, //item.OfferRcvQty,
            offerType: item.offerType,
            conditionName: item.conditionName,
            offerProductCategory: item.offerProductCategory,
            offerSkuName: item.offerSkuName,
            offerNote: item.offerNote,
            offerStartDate: item.offerStartDate,
            offerEndDate: item.offerEndDate,
            offerUnit: item.offerUnit,
            note: item.note,
            offerRcvDate: selectedDate,
            status: 1
        }));

        try {
            setIsLoading(true);

            const response = await axios.put(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/updateOfferRCV`, receivedProduct, { headers: authHeader() });
            if (response.data.status || response.status === 200) {
                Swal.fire({
                    title: "Offer Products Received!",
                    text: "Offer Products Received!",
                    icon: "success"
                });

                // setStep(1)
                // setCheckProducts([]);
                // setCount(prevState => prevState + 1);

                setProductsData([...productsData.filter((product) => !product.checked)]);
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    }


    const handleCheckboxChange = (event, product, index) => {

        const { value } = event.target;
        const updatedProducts = [...productsData];

        updatedProducts[index].checked= !updatedProducts[index].checked

        setProductsData(updatedProducts);

    }

    const handleNote = (event, index, product, unit)=> {

        const { value } = event.target;
        const updatedProducts = [...productsData];

        updatedProducts[index].note= value

        setProductsData(updatedProducts);
    }



    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>
            <PageHeader
                title="Offer Product Received Panel"
                className="mb-3"
            ></PageHeader>

            {step === 1 && <><Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader isNew={false} isExport={false} isSearch={true} setSearchText={setSearchText} />
                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                            <Col xs="auto">
                                <h6 className="text-700 mb-0 text-nowrap">Received Date :</h6>
                            </Col>
                            <Col md="auto">
                                <GetSingleDate onDateChange={handleDateChange} />
                            </Col>

                            {/* <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap">Expiry Date :</h6>
                                <GetSingleDate onDateChange={handleExpityDateChange} />

                                <h6 className="text-700 mb-0 text-nowrap">Batch No :</h6>
                                <Form.Control type="text" placeholder="" onChange={(event) => setBatchNo(event.target.value)} />

                            </Col> */}


                        </Row>

                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Offer SKU Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">Offer SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Budget QTY</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Received QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Note</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center "></th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        productsData?.map((product, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                {/* <td className="align-middle text-center">{product?.categoryName}</td> */}
                                                <td className="align-middle text-center">{product?.offerProductCategory}</td>
                                                <td className="align-middle text-center">{product?.offerSkuName}</td>

                                                {/* <td className="align-middle text-center">
                                                    <Form.Control type="text" placeholder="" onChange={(event) => handleReceivedProducts(event, index, product, "ctn")} />
                                                </td> */}
                                                <th className="align-middle white-space-nowrap text-center ">{product?.OfferRcvQty}</th>

                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        // value={product && product.pcs !== undefined ? product.pcs : ""}
                                                        value={product?.updatedQuantity}
                                                        onChange={(event) => handleReceivedProducts(event, index, product, "pcs")}
                                                    />

                                                </td>

                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        name="note"
                                                        style={{width: "150px"}}
                                                        // value={product && product.pcs !== undefined ? product.pcs : ""}
                                                        value={product?.note}
                                                        onChange={(event) => handleNote(event, index, product, "pcs")}
                                                    />

                                                </td>

                                                <td className="align-middle text-center">
                                                    <Flex alignItems="center">

                                                        <input
                                                            type='checkbox'
                                                            id='product'
                                                            value={product}
                                                            // checked={selectedChoice && product._id === selectedChoice._id}
                                                            checked={product.checked}
                                                            onChange={(event) => handleCheckboxChange(event, product, index)}
                                                        />
                                                    </Flex>

                                                </td>


                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
                {/* <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleNext(); }}> */}
                <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleSubmit(); }}>
                    Received
                </Button></>}

            {
                step === 2 && <OfferReceivedPanelConfirm setStep={setStep} selectedDate={selectedDate} selectedExpiryDate={selectedExpiryDate} batchNo={batchNo} checkedProducts={checkedProducts} setCheckProducts={setCheckProducts} setCount={setCount} />
            }

        </>
    );

};

export default OfferReceivedPanel;
