import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link } from 'react-router-dom'
import OrdersFilter from 'components/common/ordersFilter/OrdersFilter'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
    faCheckCircle,
    faClock,
    faThumbsUp,
} from '@fortawesome/free-regular-svg-icons'
import useUserRole from 'hooks/useUserRole'
import StatusCell from 'components/common/StatusCell'

const DistributorList = () => {
    const [distributorOrderList, setDistributorOrderList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)
    const [pageDataCount, setPageDataCount] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [count, setCount] = useState(0)
    const distributorInfo = useSelector((state) => state?.auth?.user?.data)
    const userRole = useUserRole()

    const formateExcelData = (data) => {
        const statusLookup = {
            1: 'Canceled',
            2: 'Pending',
            3: 'Forwarded by ASM',
            4: 'Forwarded by RSM',
            5: 'Forwarded by SIO',
            6: 'Forwarded by Order Management',
            7: 'Finance',
        }

        const formatDate = (date) => {
            return date ? new Date(date).toLocaleDateString('en-US') : ''
        }

        const formateData = data?.map((item, index) => ({
            Sl: index + 1,
            'Do No': item.doNo,
            'Zone Name': item.zone,
            'Region Name': item.region,
            'Area Name': item.area,
            'Db Point': item.dbPoint,
            'Order Qty Ctn': item.totalOrderQtyCtn,
            'Order Qty Pcs': item.totalOrderQtyPCS,
            'Discount BDT': item.totalDiscountBDT,
            'Total Price': item.totalPrice,
            'Delivery Qty CTN': item.deliveryQtyCTN,
            'Delivery Qty Pcs': item.deliveryQtyPcs,
            Status: statusLookup[item.status] || 'Incomplete',
            'Do Date': formatDate(item.doDate),
        }))

        return formateData
    }

    // DISTRIBUTOR ORDER LIST
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1
        const fetchData = async () => {
            let url
            if (userRole === 'Admin' || userRole === 'HOS') {
                url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/currentMonthOrders?orderYear=${year}&orderMonth=${month}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
            } else {
                url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/currentMonthOrdersByDB/${distributorInfo?.userId}?limit=${pageDataCount}&page=${paginationPageCount}`
            }
            try {
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const totalPages =
                        response?.data?.payload?.pagination.totalPages
                    const result = response?.data?.payload?.result

                    const deliveriesResponse = await axios.get(
                        'https://khoz.net:3013/api/v1/orderDelivery/OrderDeliveryByDB/' +
                            distributorInfo.userId,
                        { headers: authHeader() }
                    )

                    if (
                        deliveriesResponse.data.success ||
                        deliveriesResponse.status === 200
                    ) {
                        const result2 =
                            deliveriesResponse?.data?.payload?.orderDeliveries

                        setTotalPages(totalPages)
                        setDistributorOrderList(
                            result.map((itsd) => ({
                                ...itsd,
                                deliveryQtyCTN:
                                    result2
                                        ?.filter((ng) => ng.doNo == itsd.doNo)
                                        .reduce((accumulator, currentValue) => {
                                            return (
                                                accumulator +
                                                currentValue.delQtyCtn
                                            )
                                        }, 0) || 0,

                                deliveryQtyPcs:
                                    result2
                                        ?.filter((ng) => ng.doNo == itsd.doNo)
                                        .reduce((accumulator, currentValue) => {
                                            return (
                                                accumulator +
                                                currentValue.delQtyPcs
                                            )
                                        }, 0) || 0,
                            }))
                        )
                    } else {
                        setTotalPages(totalPages)

                        setDistributorOrderList(result)
                    }
                }
            } catch (error) {
                handleApiResponseErrors({})
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [count, paginationPageCount, pageDataCount, searchText])

    // DELETE DISTRIBUTOR ORDER
    const handleDelete = async (doNo) => {
        try {
            const confirmResult = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4c8118',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete',
            })

            if (confirmResult.isConfirmed) {
                // urls for delete
                const balanceUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/deleteOrderBankByDo/${doNo}`
                const orderUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/delete/${doNo}`

                const balanceResponse = await axios.delete(balanceUrl, {
                    headers: authHeader(),
                })

                // if balance delete success then delete order
                if (!balanceResponse.data.success) {
                    Swal.fire({
                        title: 'Error!',
                        text: `There was an error deleting your order: ${balanceResponse.data.message}`,
                        icon: 'error',
                    })
                    return
                }

                const orderResponse = await axios.delete(orderUrl, {
                    headers: authHeader(),
                })

                if (!orderResponse.data.success) {
                    Swal.fire({
                        title: 'Error!',
                        text: `There was an error deleting your order: ${orderResponse.data.message}`,
                        icon: 'error',
                    })
                }

                // update the state
                setDistributorOrderList((prevState) =>
                    prevState.filter((element) => element.doNo !== doNo)
                )

                Swal.fire({
                    title: 'Deleted!',
                    text: 'Your order has been deleted.',
                    icon: 'success',
                })
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: `There was an error deleting your order: ${error.message}`,
                icon: 'error',
            })
        }
    }

    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { row } = rowData
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {row.index + 1}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'doNo',
            Header: 'DO No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{doNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'zoneName',
            Header: 'Zone Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { zone } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{zone}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'regionName',
            Header: 'Region Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { region } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{region}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'areaName',
            Header: 'Area Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { area } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{area}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'dbPoint',
            Header: 'DB Point',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dbPoint } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {dbPoint}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'orderQtyCtn',
            Header: 'Order Qty Ctn',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { totalOrderQtyCtn } = rowData.row.original
                const formattedPrice = totalOrderQtyCtn.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formattedPrice}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'orderQtyPcs',
            Header: 'Order Qty Pcs',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { totalOrderQtyPCS } = rowData.row.original
                const formattedPrice = totalOrderQtyPCS.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formattedPrice}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'discountBDT',
            Header: 'Discount BDT',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { totalDiscountBDT } = rowData.row.original
                const formattedPrice = totalDiscountBDT.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formattedPrice}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'totalPrice',
            Header: 'Total Price',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { totalPrice } = rowData.row.original
                const formattedPrice = totalPrice.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formattedPrice}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'deliveryQtyCTN',
            Header: 'Delivery Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { deliveryQtyCTN } = rowData.row.original
                const formattedPrice = deliveryQtyCTN.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formattedPrice ? formattedPrice : '-'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'deliveryQtyPcs',
            Header: 'Delivery Qty Pcs',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { deliveryQtyPcs } = rowData.row.original
                const formattedPrice = deliveryQtyPcs.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formattedPrice ? formattedPrice : '-'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'Status',
            Header: 'Status',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { status, deliveryQtyCTN, totalOrderQtyCtn } =
                    rowData.row.original
                if (deliveryQtyCTN == totalOrderQtyCtn) {
                    return (
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1 text-center">
                                    Fully Delivered
                                </h5>
                            </div>
                        </Flex>
                    )
                } else if (
                    deliveryQtyCTN &&
                    deliveryQtyCTN != totalOrderQtyCtn
                ) {
                    return (
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1 text-center">
                                    Partially Delivered
                                </h5>
                            </div>
                        </Flex>
                    )
                }
                return <StatusCell status={status} />
            },
        },

        {
            accessor: 'doDate',
            Header: 'Do Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doDate } = rowData.row.original
                const date = new Date(doDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formattedDate}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doNo, status } = rowData.row.original
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle
                            id="dropdown-autoclose-true"
                            className=" bg-none"
                        >
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {(status === 1 || status === 2) &&
                                userRole !== 'Admin' &&
                                userRole !== 'HOS' && (
                                    <>
                                        <Dropdown.Item href="">
                                            <Link
                                                to={`/master/distributor-orders-update/${doNo}`}
                                            >
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="edit"
                                                    transform="shrink-2"
                                                    iconAlign="middle"
                                                    className="me-2"
                                                >
                                                    <span className="d-none d-xl-inline-block ms-1">
                                                        Edit
                                                    </span>
                                                </IconButton>
                                            </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="">
                                            <IconButton
                                                onClick={() =>
                                                    handleDelete(doNo)
                                                }
                                                variant="falcon-default"
                                                size="sm"
                                                icon="trash-alt"
                                                iconAlign="middle"
                                                className="d-none d-sm-block me-2"
                                                // disabled={true}
                                            >
                                                <span className="d-none d-xl-inline-block ms-1">
                                                    Delete
                                                </span>
                                            </IconButton>
                                        </Dropdown.Item>
                                    </>
                                )}
                            <Dropdown.Item>
                                <Link
                                    to={`/master/distributor-orders-view/${doNo}`}
                                    state={{
                                        doNo: doNo,
                                    }}
                                >
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="edit"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">
                                            View Order
                                        </span>
                                    </IconButton>
                                </Link>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
        },
    ]

    /*******************
    Columns End.
    *******************/
    if (isLoading) {
        return <LoadingIcon />
    }

    return (
        <>
            <PageHeader
                title="Distributor Order List"
                className="mb-3"
            ></PageHeader>

            <AdvanceTableWrapper
                columns={columns}
                data={distributorOrderList}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3 p-2">
                    <Card.Header>
                        <CustomersTableHeader
                            // title='Distributor Order List'
                            isNew={false}
                            //isSearch={true}
                            setSearchText={setSearchText}
                            excelFileName="Distributor List (Current Month)"
                            excelData={formateExcelData(distributorOrderList)}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination
                            table
                            paginationPageCount={paginationPageCount}
                            setPaginationPageCount={setPaginationPageCount}
                            pageDataCount={pageDataCount}
                            setPageDataCount={setPageDataCount}
                            totalPages={totalPages}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}

export default DistributorList
