import RsmTarget from "./Target/index";
const { Row } = require("react-bootstrap");

const RsmDashboard = ({ setIsLoading }) => {
    return (
        <div>
            <Row className="g-3 mb-3">
                <RsmTarget setIsLoading={setIsLoading} />
            </Row>
        </div>
    );
};

export default RsmDashboard;