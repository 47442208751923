import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useUserRole from "hooks/useUserRole";
import ReceivedPanelConfirm from "./ReceivedPanelConfirm";
import { formatNumber } from 'helpers/utils';

const ReceivedPanel = () => {
    const [step, setStep] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedExpiryDate, setSelecteExpitydDate] = useState(null);
    const [count, setCount] = useState(0);
    const [batchNo, setBatchNo] = useState(null);
    const [allProducts, setAllProducts] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [checkedProducts, setCheckProducts] = useState([]);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const { slipNo } = useParams();
    const userRole = useUserRole();
    const navigate = useNavigate();

    // DATE
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // DATE
    const handleExpityDateChange = (date) => {
        setSelecteExpitydDate(date);
    };

    // Add Note
    const handleAddNote = (event, product) => {
        const { value } = event.target;

        setProductsData((prevProducts) =>
            prevProducts.map((productItem) =>
                productItem.skuName === product.skuName
                    ? { ...productItem, note: value, noteError: !value.trim() ? true : false }
                    : productItem
            )
        );
    };


    const handleReceivedProducts = (event, index, product, fieldName) => {
        const { value } = event.target;
        const updatedProducts = [...productsData];
        const findProduct = allProducts.find((item) => item.skuName === product.skuName);

        // Depending on the fieldName, perform different actions
        switch (fieldName) {
            case 'ctn':
                const calculatedPcs = parseFloat(value) * findProduct?.pcsCTN;
                const totalWeight = calculatedPcs * findProduct?.weightPCS || 0;
                const calculateWeight = totalWeight / 1000000;
                updatedProducts[index].ctn = parseFloat(value);
                updatedProducts[index].pcs = calculatedPcs;
                updatedProducts[index].weightMT = calculateWeight;
                updatedProducts[index].erpId = findProduct?.erpId;
                updatedProducts[index].pcsCTN = findProduct?.pcsCTN;
                updatedProducts[index].noteError = true
                break;
            case 'pcs':
                updatedProducts[index].pcs = parseFloat(value);
                break;
            default:
                break;
        }

        setProductsData(updatedProducts);
    };





    // Fetch products
    useEffect(() => {
        const fetchData = async () => {
            // setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.status === 200) {
                    const responseData = response.data.payload.products;
                    setAllProducts(responseData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            if (!slipNo) return;
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductionRcvListDetailsByRcvNo/${slipNo}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const responseData = response.data.payload.prdInfoDetails;

                    setProductsData(responseData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [slipNo]);


    const handleNext = () => {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select a date",
                icon: "warning"
            });
            return;
        }

        const findEmptyProduct = productsData.filter((product) => !product.ctn || !product.pcs);
        const findProduct = productsData.filter((product) => product.ctn && product.pcs);
        if (findEmptyProduct?.length > 0) {
            const updateProducts = findEmptyProduct.map(product => {
                const findProduct = allProducts.find((item) => item.skuName === product.skuName);
                return {
                    ...product,
                    ctn: product?.rcvQtyCTN,
                    pcs: product?.rcvQtyPCS,
                    weightMT: product?.rcvQtyMt,
                    erpId: product?.erpId,
                    pcsCTN: product?.pcsCTN,
                    erpId: findProduct?.erpId
                }
            })

            setProductsData([...findProduct, ...updateProducts]);

        }

        setStep(2);
    }




    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>
            <PageHeader
                title="Pending Received List"
                className="mb-3"
            ></PageHeader>

            {step === 1 && <><Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader isNew={false} isExport={false} isSearch={false} />
                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Batch No. :</h6>
                                <Form.Control type="text" placeholder="Enter Batch No." value={productsData[0]?.batchNo} disabled />
                            </Col>
                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Production Date :</h6>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder=""
                                    value={productsData[0]?.productionDate ? new Date(productsData[0].productionDate).toISOString().split('T')[0] : ''}
                                    disabled
                                />
                            </Col>
                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Expire Date :</h6>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder=""
                                    value={productsData[0]?.expireDate ? new Date(productsData[0].expireDate).toISOString().split('T')[0] : ''}
                                    disabled
                                />
                            </Col>

                            {userRole === "Inventory" && <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Receive Date :</h6>
                                <input type="date" className="form-control" onChange={(event) => handleDateChange(event.target.value)}
                                    value={selectedDate}
                                />
                            </Col>}
                        </Row>

                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Prod QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Prod QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Prod Weight(MT)`}</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>
                                        <th colSpan="2" className="align-middle white-space-nowrap text-center ">Note</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        productsData?.map((product, index) => {

                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.productCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">{formatNumber(product?.rcvQtyCTN)}</td>
                                                <td className="align-middle text-center">{formatNumber(product?.rcvQtyPCS)}</td>
                                                <td className="align-middle text-center">{formatNumber(product?.rcvQtyMt)}</td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        onChange={(event) => handleReceivedProducts(event, index, product, "ctn")}
                                                        value={formatNumber(productsData[index]?.ctn !== undefined ? productsData[index]?.ctn : product?.rcvQtyCTN || '')} 
                                                    />
                                                </td>

                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={formatNumber(product?.pcs ? product?.pcs : product?.rcvQtyPCS)}
                                                        // onChange={(event) => handleReceivedProducts(event, index, product, "pcs")}
                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={formatNumber(product.weightMT ? product.weightMT : product?.rcvQtyMt)}
                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        className={product?.noteError ? 'is-invalid' : ''}
                                                        as="textarea"
                                                        placeholder="Enter Note"
                                                        value={product && product.note !== undefined ? product.note : ""}
                                                        onChange={(event) => handleAddNote(event, product)}
                                                        style={{ width: "150px" }}
                                                    />
                                                    {product?.noteError && (
                                                        <div className="invalid-feedback">
                                                            Note is required when changing the quantity.
                                                        </div>
                                                    )}
                                                </td>

                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
                {userRole === "Inventory" && <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleNext(); }}>
                    Received
                </Button>}
            </>}


            {step === 2 && <ReceivedPanelConfirm setStep={setStep} selectedDate={selectedDate} productsData={productsData} setProductsData={setProductsData} setCount={setCount} />}

            {userRole === "Production" && <Button className="mt-2 px-5" variant="primary" style={{ float: "inline-end" }} onClick={() => navigate(`/inventory/slip/${productsData[0]?.rcvNo}`)} >
                Print Slip
            </Button>}

        </>
    );

};

export default ReceivedPanel;
