import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Card, Dropdown, Modal, Button } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { date } from "is_js";
import { formatNumber } from 'helpers/utils'
import useUserRole from "../../../hooks/useUserRole";

const Deposits = () => {
    const [depositList, setDepositList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState(0);
    const [show, setShow] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const userRole = useUserRole();




    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const formateExcelData = (data) => {
        const statusLookup = {
            2: 'Approved',
            0: 'Pending',

        }

        const formatDate = (date) => {
            return date ? new Date(date).toLocaleDateString('en-US') : ''
        }

        const formateData = data?.map((item, index) => ({
            Sl: index + 1,
            'Bank Names': item.bankNames,
            'Bank Account No': item.bankAccountNo,
            'Depositors Bank Name': item.depositorsBankName,
            'Depositors Branch Name': item.depositorsBranchName,
            'Amount BDT': item.drBDT,
            'Depositor Phone': item.depositorPhone,
            'Transaction ID': item.trId,
            'Payment Note': item.paymentNote,
            'Payment Date': formatDate(item.paymentDate),
            'Status': statusLookup[item.status],
        }))

        return formateData
    }

    // deposit list

    useEffect(() => {
        // setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/dealer/deposit/${distributorInfo?.userId}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.dealerDeposit?.map((element, index) => ({
                        ...element,
                        index: index + 1,
                    }));
                    setDepositList(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count]);

    // DELETE DISTRIBUTOR
    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/deposit/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };

    // VIEW IMAGE
    const viewImage = async (imageId) => {
        try {
            const response = await axios.get(`https://khoz.net:3015/api/v1/image/${imageId}`, { headers: authHeader() });
            if (response.data.success && response.data.payload) {
                const data = response.data.payload;
                if (!data.imageData) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No image found!',
                    });
                    return;
                }

                const base64Image = data.imageData.image;
                const base64Prefix = 'data:image/jpeg;base64,';
                const base64String = base64Image.startsWith(base64Prefix) ? base64Image : `${base64Prefix}${base64Image}`;

                setImageSrc(base64String);  // Set the base64 image source to display in the modal
                handleShow(); // Show the modal
            } else {
                console.error('Failed to fetch image:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching the image:', error);
        }
    };


    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "SL",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "bankNames",
            Header: "Bank Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { bankNames } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{bankNames || "--"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "bankAccountNo",
            Header: "Account No",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { bankAccountNo } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{bankAccountNo || "--"}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "trId",
            Header: "Transaction ID",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { trId } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{trId ? trId : "-"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "drBDT",
            Header: "Amount BDT",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { drBDT } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{formatNumber(drBDT)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "doNo",
            Header: "Do No",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { doNo } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{doNo || "--"}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "depositorsBankName",
            Header: "Depositor Bank Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { depositorsBankName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{depositorsBankName || "--"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "depositorsBranchName",
            Header: "Depositor Branch Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { depositorsBranchName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{depositorsBranchName || "--"}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "depositorPhone",
            Header: "Depositor Phone",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { depositorPhone } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{depositorPhone}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "paymentMethod",
            Header: "Payment Method",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { paymentMethod } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{paymentMethod}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "mobileMethodName",
            Header: "Payment Method Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { mobileMethodName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{mobileMethodName || "bank"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "paymentNote",
            Header: "Payment Note",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { paymentNote } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{paymentNote}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "paymentDate",
            Header: "Payment Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { paymentDate } = rowData.row.original;
                const date = new Date(paymentDate);
                const formateDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{formateDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "status",
            Header: "Status",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{status === 0 ? "Pending..." : status === 1 ? "Confirm" : status === 2 ? "Approved" : ""}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "action",
            Header: "Action",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { _id, trId, status } = rowData.row.original;
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {trId && <Dropdown.Item onClick={() => viewImage(trId)}>

                                {/* <Link to={image} target="_blank"> */}
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="show"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="me-2"
                                >
                                    <span className="">View Attachment</span>
                                </IconButton>
                                {/* </Link> */}
                            </Dropdown.Item>}
                            {status === 0 && <>
                                <Dropdown.Item href="">

                                    {
                                        userRole === "SUPER DISTRIBUTOR" ? <Link to={`/master/deposit/update-super-db/${_id}`}>
                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon="edit"
                                                transform="shrink-2"
                                                iconAlign="middle"
                                                className="me-2"

                                            >
                                                <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                            </IconButton>
                                        </Link> : <Link to={`/master/deposit/add/${_id}`}>
                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon="edit"
                                                transform="shrink-2"
                                                iconAlign="middle"
                                                className="me-2"

                                            >
                                                <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                            </IconButton>
                                        </Link>
                                    }


                                </Dropdown.Item>
                                <Dropdown.Item href="">
                                    <IconButton
                                        onClick={() => handleDelete(_id)}
                                        variant="falcon-default"
                                        size="sm"
                                        icon="trash-alt"
                                        iconAlign="middle"
                                        className="d-none d-sm-block me-2"
                                     
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                    </IconButton>
                                </Dropdown.Item>
                            </>}
                        </Dropdown.Menu>
                    </Dropdown>
                );
            },
        },
    ];

    /*******************
    Columns End.
    *******************/

    const url = userRole === "SUPER DISTRIBUTOR" ? "/master/deposit/update-super-db" : "/master/deposit/add";

    return (
        <>
            <PageHeader
                title="Deposits"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={depositList}
                sortable
                pagination
                perPage={100}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            newUrl={url}
                            isSearch={true}
                            setSearchText={setSearchText}
                            isExport={false}
                            table
                        // excelFileName="Deposit"
                        // excelData={formateExcelData(depositList)}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            {/* Modal */}
            <Modal show={show} onHide={handleClose}  size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {imageSrc ? (
                        <img src={imageSrc} alt="Image Preview" style={{ width: '100%' }} />
                    ) : (
                        <p>Loading...</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default Deposits;
