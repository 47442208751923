import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import Swal from "sweetalert2";
import handleApiResponseErrors from "helpers/handleApiResponse";

const Area = () => {
  const [areasData, setAreasData] = useState([]);
  const [paginationPageCount, setPaginationPageCount] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);


  // AREA

  useEffect(() => {
    setIsLoading(true);
    const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SETTINGS_BASE_URL}area?limit=${pageDataCount}&page=${paginationPageCount}`,
          { headers: authHeader() }
        );

        if (response.data.success || response.status === 200) {
          const totalPages = response?.data?.payload?.pagination?.totalPages;

          const result = response?.data?.payload?.result?.map((element, index) => ({
            ...element,
            index: startIndex + index
          }));

          setTotalPages(totalPages);
          setAreasData(result);
        }
      } catch (error) {
        handleApiResponseErrors(error.response);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [count, paginationPageCount, pageDataCount]);



  // DELETE
  const handleDelete = async (id) => {
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4c8118",
      cancelButtonColor: "#d33",
      confirmButtonText: "delete"
    });

    if (confirmResult.isConfirmed) {
      try {
        const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}area/${id}`;
        const response = await axios.delete(url, { headers: authHeader() });

        if (response.data.success || response.status === 200) {
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });

          setCount((prevCount) => prevCount + 1);
        }
      } catch (error) {
        handleApiResponseErrors(error.response);
      } finally {
        setIsLoading(false);
      }
    }
  };


  if (isLoading) {
    return <LoadingIcon />;
  }


  /*******************
   Columns Start.
  *******************/

  const columns = [

    {
      accessor: "index",
      Header: "Sl",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { index } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{index}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "zoneName",
      Header: "Zone Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { zoneName } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{zoneName}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "regionName",
      Header: "Region Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { regionName } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{regionName}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "areaName",
      Header: "Area Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { areaName } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{areaName}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "action",
      Header: "Action",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { _id } = rowData.row.original;
        return (
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
              ...
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="">
                <Link to={`/master/area/add/${_id}`}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="edit"
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-2"
                  >
                    <span className="d-none d-xl-inline-block ms-1">Edit</span>
                  </IconButton>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item href="">
                <IconButton
                  onClick={() => handleDelete(_id)}
                  variant="falcon-default"
                  size="sm"
                  icon="trash-alt"
                  iconAlign="middle"
                  className="d-none d-sm-block me-2"
                // disabled={true}
                >
                  <span className="d-none d-xl-inline-block ms-1">Delete</span>
                </IconButton>

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];


  /*******************
Columns End.
*******************/


  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={areasData}
        sortable
        pagination
        perPage={50}
      >
        <Card className="mb-3">
          <Card.Header>
            <CustomersTableHeader title="Areas" newUrl="/master/area/add" data={areasData} isExport={false} table />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />

          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default Area;
