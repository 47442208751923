import FalconComponentCard from 'components/common/FalconComponentCard'
import AppContext from 'context/Context'
import React, { useContext, useEffect, useState } from 'react'
import {
    Col,
    Form,
    Row,
    InputGroup,
    FormControl,
    Button,
    Card,
    Table
} from 'react-bootstrap'
import SimpleBarReact from 'simplebar-react'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import SpaceWarning from 'components/dashboards/default/SpaceWarning';
import DatePicker from 'react-datepicker'

import axios from 'axios'


import {
    getAreas,
    getDBPoints,
    getDeliveriesByDO,
    getDistributors,
    getInventoryStocks,
    getRegions,
    getSchedulesDistributor,
    getSchedulesByDealerIds,
    getSchedulesByFactoryDealerIds,
    getZones,
    orderDeliverySubmit,
    getAllDeliveries,
    getOrdersByDealerIds,
    getDeliveryDataBySkuDo,
    getInventoryStocksDeliveredSum,
    getInventoryStocksSum,
    updateSchedules

} from 'services/api'
import BottomTab from './BottomTab'
import DeliverableProductsUpdate from './DeliverableProductsUpdate'
import TopTab from './TopTab'
import Swal from 'sweetalert2';
import CollapseDelivery from './CollapseDelivery'


export const formatNumber = (num) => {
    if (num) {
        return parseFloat(num?.toFixed(2))?.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        })
    } else {
        return 0
    }
}

const Delivery = ({ user }) => {
    const [validated, setValidated] = useState(false)
    const navigate = useNavigate()

    const [distributorValue, setDistributorValue] = useState([])
    const [transportName, setTransportName] = useState('')
    const [transportNo, setTransportNo] = useState('')
    const [driverName, setDriverName] = useState('')
    const [driverPhone, setDriverPhone] = useState('')
    const [selectedFactoryname, setSelectedFactoryName] = useState('')
    const [selectedSKUname, setSelectedSKUName] = useState('')
    const [selectedDoNo, setSelectedDoNo] = useState('')
    const [challan, setChallan] = useState(null)

    const [comboOffers, setComboOffers] = useState([])

    const [updatableCombos, setUpdatableCombos] = useState([])




    const [groupedSchedules, setGroupedSchedules] = useState([])

    const [deliveredOrders, setDeliveredOrders] = useState([])


    //for setting dealer id  when changing quantity
    const [dealerId, setDealerId] = useState(null)
    const [dealerName, setDealerName] = useState(null)
    const [selectedDbType, setSelectedDbType] = useState(null)

    const [distributorOptions, setDistributorOptions] = useState([])

    const [stocksList, setStocksList] = useState([]);

    const [expand, setExpand] = useState(false)

    var firstDay = new Date();


    const [deliveryDate, setDeliveryDate] = useState(firstDay);









    const fetchSchedulesData = () => {

        getSchedulesByFactoryDealerIds({
            dealerIds: distributorValue.map((item) => item.value),
            factoryName: user.data.factoryName
        })
            .then((res) => {
                if (res.data?.success) {
                    console.log(res.data?.message)
                    // setMySchedules(res.data.payload.moreDBOrders)
                    setGroupedSchedules(res.data.payload.groupedOrders);
                    setDistributorOptions(res.data.payload.groupedOrders.map((schedule) => {
                        return {
                            ...schedule,
                            value: schedule.dealerId,
                            label: schedule.distributorName + ' - ' + schedule.dealerId,
                        }
                    }))
                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    toast.error(e.response?.data?.errors)
                }
            })
    }




    useEffect(() => {
        fetchSchedulesData();
    }, [distributorValue.length, deliveredOrders.length])

    useEffect(() => {
        let prefix = ''

        if (user.data.factoryName === 'SORL') {
            prefix = 'SRL'
        } else if (user.data.factoryName === 'SVOIL') {
            prefix = 'SVL'
        } else if (user.data.factoryName === 'Narshingdi') {
            prefix = 'NAR'
        } else if (user.data.factoryName === 'Dhamrai') {
            prefix = 'DHM'
        }
        else if (user.data.factoryName === 'Dhaka Central Depot') {
            prefix = 'DCD'
        }
        else if (user.data.factoryName === 'Ctg Depot') {
            prefix = 'CTD'
        }
        else if (user.data.factoryName === 'Noakhali Depot') {
            prefix = 'NHD'
        }
        else if (user.data.factoryName === 'Sylhet Depot') {

            prefix = 'SLD'
        }
        else if (user.data.factoryName === 'Jessore Depot') {
            prefix = 'JSD'
        }
        else if (user.data.factoryName === 'Mymensingh Depot') {
            prefix = 'MSD'
        }
        else if (user.data.factoryName === 'Bogra Depot') {
            prefix = 'BGD'
        }
        else if (user.data.factoryName === 'Barisal Depot') {
            prefix = 'BSD'
        }
        else if (user.data.factoryName === 'Tekerhat Depot') {
            prefix = 'TKD'
        }
        else if (user.data.factoryName === 'Rangpur Depot') {
            prefix = 'RPD'
        }

        const challan = prefix + '-' + Math.floor(Math.random() * 1000000000)
        setChallan(challan)

    }, [])

    const handleSubmit = (event) => {
        console.log('distributorValue', distributorValue)
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }

        setValidated(true)
    }

    useEffect(() => {

        const allDatas = Object.values(groupedSchedules).map((order) => {
            return order.scOrders.filter((tr) => tr.isChecked)
        }).flat()


        allDatas.forEach((item) => {
            item.rcvQtyCTN = 0
            item.rcvQtyPcs = 0

            item.stockDeliveredQtyCTN = 0
            item.stockDeliveredQtyPcs = 0
        })


        const receivedResponse = axios.all(allDatas.map((item) => !item?.offerNo && getInventoryStocksSum(item.skuName, user.data.factoryName)))
            .then(axios.spread((...responses) => {
                responses.forEach((response) => {
                    const { data } = response;
                    if (data?.payload?.length > 0) {
                        allDatas.forEach((item) => {

                            // if (item.skuName === data?.payload[0]?.skuName && item.factoryName[0] === data?.payload[0]?.factoryName) {
                            if (item.skuName === data?.payload[0]?.skuName && item.factoryName === data?.payload[0]?.factoryName) {

                                item.rcvQtyCTN = data?.payload?.reduce((a, b) => a + b?.rcvQtyCTN, 0);
                                item.rcvQtyPcs = data?.payload?.reduce((a, b) => a + b?.rcvQtyPCS, 0);
                            }

                        })
                    }
                });


                // setStocksList([...allDatas]) 


                const deliveredResponse = axios.all(allDatas.map((item) => !item?.offerNo && getInventoryStocksDeliveredSum(item.skuName, user.data.factoryName)))
                    .then(axios.spread((...responses) => {
                        responses.forEach((response) => {
                            const { data } = response;
                            if (data?.payload?.length > 0) {
                                allDatas.forEach((item) => {

                                    // if (item.skuName === data?.payload[0]?.skuName && item.factoryName[0] === data?.payload[0]?.factoryName) {
                                    if (item.skuName === data?.payload[0]?.skuName && item.factoryName === data?.payload[0]?.factoryName) {

                                        item.stockDeliveredQtyCTN = data?.payload?.reduce((a, b) => a + b?.delQtyCtn, 0);
                                        item.stockDeliveredQtyPcs = data?.payload?.reduce((a, b) => a + b?.delQtyPcs, 0);
                                    }

                                })
                            }
                        });


                        setStocksList([...allDatas.filter((item) => !item?.offerNo)])




                    }))
                    .catch(errors => console.log(errors)).finally(() => {
                        console.log("done");

                    })




            }))
            .catch(errors => console.log(errors)).finally(() => {
                console.log("done");

            })
    }, [groupedSchedules])

    console.log("stocksList", stocksList);
    console.log("groupedSchedules", groupedSchedules);


    const handleReset = (ev) => {

        ev.preventDefault()

        const challan =
            Math.floor(Math.random() * 1000000000) + '-' + user.data.userId

        setChallan(challan)
        setDriverPhone('')
        setDriverName('')
        setTransportName('')
        setTransportNo('')
    }

    const {
        config: { isNavbarVerticalCollapsed, isFluid, isRTL },
        setConfig,
    } = useContext(AppContext)
    useEffect(() => {
        const handleClick = () => {
            document
                .getElementsByTagName('html')[0]
                .classList.toggle('navbar-vertical-collapsed')
            setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed)
        }

        handleClick()


    }, [])



    const handleScheduleSubmit = () => {
        debugger;
        if (!transportName || !transportNo || !driverName || !driverPhone) {
            // alert("Please enter necesssary fields");
            Swal.fire({
                title: 'Validation',
                text: 'Please enter necesssary fields',
                icon: 'warning',
            })
            return
        }

        const deliverableSchedules = Object.values(groupedSchedules).map((order) => {
            return order.scOrders.filter((tr) => tr.deliveryQty > 0 && tr.isChecked)
        }).flat()

        const deliverableOffers = Object.values(groupedSchedules).map((order) => {
            return order.scOrders.filter((tr) => tr.offerNo && tr.isChecked)
        }).flat()


        if (deliverableSchedules.length == 0) {
            Swal.fire({
                title: 'Validation',
                text: 'Please select the schedules',
                icon: 'warning',
            })

        }

        const postArr = deliverableSchedules
            // .filter(
            //     (it) => !it.offerNo 
            // )
            .map((item) => {

                // let offerDeliveryAlreadyDone = false
                // const existing = allDeliveries.filter(
                //     (mn) => mn.skuName == item.skuName && mn.doNo == item.doNo
                // )
                // for (let i = 0; i < existing.length; i++) {
                //     const element = existing[i]
                //     if (
                //         element.offerSkuName &&
                //         element.offerCategory &&
                //         element.offerQtyPCS
                //     ) {
                //         offerDeliveryAlreadyDone = true
                //         break
                //     }
                // }

                const pcsCTN = item.delQtyPcs / item.delQtyCtn

                return {
                    doNo: item.doNo,
                    dbPoint: item.dbPoint,
                    dealerId: item.dealerId,
                    companyName: item.companyName,
                    factoryName: item.factoryName,
                    skuName: item.skuName,
                    productId: item.productId,
                    userId: user.data.userId,
                    scheduleNo: item.scheduleNo,
                    challanNo: challan,
                    zoneName: item.zoneName,
                    regionName: item.regionName,
                    areaName: item.areaName,
                    delQtyCtn: item.deliveryQty,
                    delQtyPcs: item.dbType == "SPECIAL DISTRIBUTOR" ? item.deliveryQty : pcsCTN * item.deliveryQty,
                    // delQtyMt: (pcsCTN * item.deliveryQty * item.weightPerPC) / 1000000,
                    delQtyMt: item.dbType == "SPECIAL DISTRIBUTOR" ? (item.deliveryQty * item.weightPerPC) / 1000000 : (pcsCTN * item.deliveryQty * item.weightPerPC) / 1000000,
                    erpId: item.erpId,
                    orderDate: item.orderDate,
                    scheduleDate: item.scheduleDate,
                    deliveryDate: deliveryDate,
                    status: 10,
                    dbType: item.dbType,
                    productCategory: item.productCategory,
                    offerCategory: item.offerDeliveryAlreadyDone
                        ? ''
                        : item.offerCategory,
                    offerSkuName: item.offerDeliveryAlreadyDone
                        ? ''
                        : item.offerSkuName,
                    offerQtyPCS: item.offerDeliveryAlreadyDone
                        ? 0
                        : item.offerQtyPCS,
                    superDBId: item.superDBId,
                    transportName: transportName,
                    transportNo: transportNo,
                    driverName: driverName,
                    driverPhone: driverPhone,
                    distributorName: item.distributorName,
                    productSubCategory: item.productSubCategory || '',
                    perPCPrice: item.perPCPrice,
                    deliveryYear: new Date().getFullYear(),
                    deliveryMonth: new Date().getMonth() + 1,
                    offerUnit: item?.offerUnit || "",

                }
            })

        const comboOffersToSubmit = deliverableOffers
            .filter((mg) => mg.offerQtyPCS)
            .map((off) => ({
                // offerNo: off.offerNo,
                // offerName: off.offerName,
                // userId: user.data.userId,
                // dealerId: off.dealerId,
                // dbType: off.dbType,
                // distributorName: off.distributorName,
                // superDBId: off.superDBId,
                // doNo: off.doNo,
                // dbPoint: off.dbPoint,
                // challanNo: challan,
                // zoneName: off.zone,
                // regionName: off.region,
                // areaName: off.area,
                // offerCategory: off.offerCategoryName,
                // offerSkuName: off.offerSkuName,
                // offerQtyPCS: off.offerPc,
                // companyName: off.companyName,
                // factoryName: user.data.factoryName,
                // deliveryDate: off.deliveryDate,
                // status: 10,
                // dbType: off.dbType,

                /////////////////////////

                challanNo: challan,
                factoryName: off.factoryName,
                userId: user.data.userId,
                scheduleNo: off.scheduleNo,
                zoneName: off.zone,
                regionName: off.region,
                areaName: off.area,
                orderDate: off.doDate,
                scheduleDate: off.scheduleDate,
                skuName: off.offerName,
                superDBId: off.superDBId,
                dealerId: off.dealerId,
                dbType: off.dbType,
                distributorName: off.distributorName,
                doNo: off.doNo,
                deliveryDate: off.deliveryDate,
                deliveryDateEnd: off.deliveryDateEnd,
                offerSkuName: off.offerSkuName,
                offerQtyPCS: off.offerQtyPCS,
                offerStatus: 1,
                offerNo: off.offerNo,
                superDBId: off.superDBId || '',
                status: 10,
                unitName: 'PCS',
                offerUnit: off?.offerUnit || '',
                // delQtyCtn: off.offerQtyPCS
            }))

        orderDeliverySubmit([...postArr, ...comboOffersToSubmit])
            .then((res) => {
                if (res.data?.success) {

                    // setSelectedFactoryName('')
                    // setSelectedSKUName('')
                    // setSelectedDoNo('')
                    // setDeliveredOrders([
                    //     ...deliveredOrders,
                    //     res.data ? res.data?.insertedDeliveryOrders : [],
                    // ])
                    // toast.success(res.data?.message)



                    // navigate('/distribution/productdelivery/pending')


                    const postArrWithIdCompleted = deliverableSchedules.filter((item) => (user.data.userRole == "SPECIAL DISTRIBUTOR" ? item.delQtyPcs : item.delQtyCtn) == (+item.deliveryQty + item.alreadyDeliveredQty)).map((item) => {

                        return {
                            ...item,
                            _id: item._id
                        }
                    })

                    const comboOffersWithIdCompleted = deliverableOffers.filter((mg) => mg.offerQtyPCS).map((item) => {

                        return {
                            ...item,
                            _id: item._id
                        }
                    })


                    updateSchedules([...postArrWithIdCompleted, ...comboOffersWithIdCompleted].map((item) => {

                        return {
                            _id: item._id,
                            scheduleStatus: 1
                        }
                    })).then((res) => {
                        if (res.data?.success) {

                            setSelectedFactoryName('')
                            setSelectedSKUName('')
                            setSelectedDoNo('')
                            setDeliveredOrders([
                                ...deliveredOrders,
                                res.data ? res.data?.insertedDeliveryOrders : [],
                            ])
                            toast.success(res.data?.message)

                        }
                    }).catch((e) => {
                        if (e.response?.data?.errors) {
                            console.log(e)
                            toast.error(e.response?.data?.errors)
                        }
                    }).finally((e) => {
                        console.log(e)

                    })
                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    console.log(e)
                    toast.error(e.response?.data?.errors)
                }
            })
    }

    const getUniqueValues = (arr, property) => {
        const values = new Set()

        arr.forEach((obj) => {
            if (obj[property]) {
                values.add(obj[property])
            }
        })

        return [...values]
    }

    const getDeliveredQuantityBySKUShD = (skuName, scheduleNo, doNo) => {
        const totalQty = allDeliveries
            .filter(
                (item) =>
                    item.skuName == skuName &&
                    item.scheduleNo == scheduleNo &&
                    item.doNo == doNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    const getDeliveredQuantityBySKUShDInPieces = (
        skuName,
        scheduleNo,
        doNo
    ) => {
        const totalQty = allDeliveries
            .filter(
                (item) =>
                    item.skuName == skuName &&
                    item.scheduleNo == scheduleNo &&
                    item.doNo == doNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyPcs
            }, 0)

        return totalQty
    }

    const getDeliveredQuantityBySKUOnly = (skuName, factoryName) => {
        const totalQty = allDeliveries
            .filter(
                (item) =>
                    item.skuName == skuName && item.factoryName == factoryName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    const getDeliveredQuantityBySKUOnlyInPieces = (skuName, factoryName) => {
        const totalQty = allDeliveries
            .filter(
                (item) =>
                    item.skuName == skuName && item.factoryName == factoryName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyPcs
            }, 0)

        return totalQty
    }


    const handleCheckAll = (event) => {
        // const isChecked = event.target.checked
        // if (isChecked) {
        //     setCheckedOrders(groupedOrders)
        // } else {
        //     setCheckedOrders([])
        // }

        groupedSchedules.forEach((element, index) => {
            handleParentCheck(index, event.target.checked)
        })



    }
    const isChecked = (order) => {
        return checkedOrders.some((p) => p._id === order._id)
    }

    const handleCheckboxChange = (order) => {
        if (isChecked(order)) {
            setCheckedOrders(checkedOrders.filter((p) => p._id !== order._id))
        } else {
            setCheckedOrders([...checkedOrders, order])
        }

        handleParentCheck(0, true);
    }

    const expandHandler = (order) => {
        const orders = groupedSchedules
        orders.forEach((element) => {
            element.open = false
        })
        const index = orders.findIndex((item) => item._id == order._id)
        orders[index].open = true
        setGroupedSchedules(orders)
        setExpand(!expand)
    }

    const comboShowable = (comboOffer) => {
        const deliveries = [...allDeliveries, ...deliveredOrders]
        return !deliveries.some(
            (item) =>
                item.offerNo == comboOffer.offerNo &&
                item.scheduleNo == comboOffer.scheduleNo
        )
    }



    // Function to check/uncheck all children when parent is checked/unchecked
    const handleParentCheck = (parentIndex, isChecked) => {
        // Set the parent as checked/unchecked
        groupedSchedules[parentIndex].isChecked = isChecked;
        // Loop through all children (scOrders) and check/uncheck them based on the parent
        groupedSchedules[parentIndex].scOrders.forEach(child => {
            child.isChecked = isChecked;
            child.deliveryQty = isChecked ? (user.data.userRole == "SPECIAL DISTRIBUTOR" ? child.delQtyPcs : child.delQtyCtn) : 0;
            child.alreadyDeliveredQty = 0;
            child.offerDeliveryAlreadyDone = false;
            child.rcvQtyCTN = 0
            child.rcvQtyPcs = 0
            child.stockDeliveredQtyCTN = 0
            child.stockDeliveredQtyPcs = 0
        });



        const schedulesResponse = axios.all(groupedSchedules[parentIndex].scOrders.map((item) => !item?.offerNo && getDeliveryDataBySkuDo(item.doNo, item.skuName)))
            .then(axios.spread((...responses) => {
                responses.forEach((response) => {
                    const { data } = response;
                    if (data?.payload?.length > 0) {
                        groupedSchedules[parentIndex].scOrders.forEach((item) => {
                            if (item.doNo === data?.payload[0]?.doNo && item.skuName === data?.payload[0]?.skuName) {
                                const alreadydelQtyCtn = data?.payload[0]?.delQtyCtn || 0;
                                const alreadydelQtyPcs = data?.payload[0]?.delQtyPcs || 0;
                                const offerDeliveryAlreadyDone = data?.payload[0]?.offerQtyPCS || false;
                                item.deliveryQty = isChecked ? ((user.data.userRole == "SPECIAL DISTRIBUTOR" ? item.delQtyPcs : item.delQtyCtn) - (user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn)) : 0;
                                item.alreadyDeliveredQty = user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn;
                                item.offerDeliveryAlreadyDone = offerDeliveryAlreadyDone;
                            }

                        })
                    }
                });


                /////////////////////////////////////////////////////////////////////
                ////////////////////////Code for Stock Check//////////////////////////
                /////////////////////////////////////////////////////////////////////    


                const allChilds = groupedSchedules[parentIndex].scOrders;

                const receivedResponse = axios.all(allChilds.map((item) => !item?.offerNo && getInventoryStocksSum(item.skuName, user.data.factoryName)))
                    .then(axios.spread((...responses) => {
                        responses.forEach((response) => {
                            const { data } = response;
                            if (data?.payload?.length > 0) {
                                allChilds.forEach((item) => {

                                    // if (item.skuName === data?.payload[0]?.skuName && item.factoryName[0] === data?.payload[0]?.factoryName) {
                                    if (item.skuName === data?.payload[0]?.skuName && item.factoryName === data?.payload[0]?.factoryName) {

                                        item.rcvQtyCTN = data?.payload?.reduce((a, b) => a + b?.rcvQtyCTN, 0);
                                        item.rcvQtyPcs = data?.payload?.reduce((a, b) => a + b?.rcvQtyPCS, 0);
                                    }

                                })
                            }
                        });


                        // setStocksList([...allChilds]) 


                        const deliveredResponse = axios.all(allChilds.map((item) => !item?.offerNo && getInventoryStocksDeliveredSum(item.skuName, user.data.factoryName)))
                            .then(axios.spread((...responses) => {
                                responses.forEach((response) => {
                                    const { data } = response;
                                    if (data?.payload?.length > 0) {
                                        allChilds.forEach((item) => {

                                            // if (item.skuName === data?.payload[0]?.skuName && item.factoryName[0] === data?.payload[0]?.factoryName) {
                                            if (item.skuName === data?.payload[0]?.skuName && item.factoryName === data?.payload[0]?.factoryName) {

                                                item.stockDeliveredQtyCTN = data?.payload?.reduce((a, b) => a + b?.delQtyCtn, 0);
                                                item.stockDeliveredQtyPcs = data?.payload?.reduce((a, b) => a + b?.delQtyPcs, 0);
                                            }

                                        })
                                    }
                                });


                                console.log("allChildsBefore", allChilds);

                                if (isChecked) {

                                    allChilds.forEach((item) => {

                                        if ((item.deliveryQty > (item.rcvQtyCTN - item.stockDeliveredQtyCTN)) || (item.deliveryQty > (item.rcvQtyPcs - item.stockDeliveredQtyPcs))) {

                                            item.deliveryQty = 0;
                                            item.offerDeliveryAlreadyDone = false;
                                            item.alreadyDeliveredQty = 0;
                                            // alert("Please check the stock for " + item.skuName);

                                            const stockAlert = Swal.fire({
                                                title: 'Stock Alert',
                                                text: "Please check the stock for selected items",
                                                icon: 'warning',
                                                confirmButtonColor: '#4c8118',
                                                confirmButtonText: 'Ok',
                                            })

                                        }
                                    })

                                }




                                groupedSchedules[parentIndex].scOrders = allChilds;
                                // setGroupedSchedules([...groupedSchedules]);
                                setGroupedSchedules([...groupedSchedules]);
                                setDealerId(groupedSchedules[parentIndex]?.dealerId);
                                setSelectedDbType(groupedSchedules[parentIndex]?.scOrders[0]?.dbType)



                            }))
                            .catch(errors => console.log(errors)).finally(() => {
                                console.log("done");

                            })




                    }))
                    .catch(errors => console.log(errors)).finally(() => {
                        console.log("done");

                    })



                /////////////////////////////////////////////////////////////////////
                ////////////////////////Code for Stock Check: End//////////////////////////
                /////////////////////////////////////////////////////////////////////    

            }))
            .catch(errors => console.log(errors)).finally(() => {
                console.log("done");

            });



    }

    // Function to handle individual child check/uncheck
    const handleChildCheck = async (parentIndex, childIndex, isChecked) => {
        // Set the specific child as checked/unchecked
        groupedSchedules[parentIndex].scOrders[childIndex].isChecked = isChecked;


        // const res = await getDeliveryDataBySkuDo(groupedSchedules[parentIndex].scOrders[childIndex].doNo, groupedSchedules[parentIndex].scOrders[childIndex].skuName);

        let res = null;
        if (!groupedSchedules[parentIndex].scOrders[childIndex].offerNo) {
            res = await getDeliveryDataBySkuDo(groupedSchedules[parentIndex].scOrders[childIndex].doNo, groupedSchedules[parentIndex].scOrders[childIndex].skuName);
        }

        const alreadydelQtyCtn = res?.data?.payload[0]?.delQtyCtn || 0;
        const alreadydelQtyPcs = res?.data?.payload[0]?.delQtyPcs || 0;
        const offerDeliveryAlreadyDone = res?.data.payload[0]?.offerQtyPCS || false;

        groupedSchedules[parentIndex].scOrders[childIndex].deliveryQty = isChecked ? (user.data.userRole == "SPECIAL DISTRIBUTOR" ? groupedSchedules[parentIndex].scOrders[childIndex].delQtyPcs : groupedSchedules[parentIndex].scOrders[childIndex].delQtyCtn) - (user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn) : 0;
        groupedSchedules[parentIndex].scOrders[childIndex].alreadyDeliveredQty = user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn;
        groupedSchedules[parentIndex].scOrders[childIndex].offerDeliveryAlreadyDone = offerDeliveryAlreadyDone;


        // Check if all children are checked
        const allChecked = groupedSchedules[parentIndex].scOrders.every(child => child.isChecked);

        // Set the parent check based on the status of children
        groupedSchedules[parentIndex].isChecked = allChecked;

        ////////////////////////////////////////////////////////////////////
        //////////////////////Code for Stock Check//////////////////////////
        /////////////////////////////////////////////////////////////////////


        if (isChecked) {

            const receivedResponse = await getInventoryStocksSum(groupedSchedules[parentIndex].scOrders[childIndex].skuName, user.data.factoryName);
            const totalRrcvQtyCTN = receivedResponse?.data?.payload?.reduce((a, b) => a + b?.rcvQtyCTN, 0) || 0;
            const totalRrcvQtyPcs = receivedResponse?.data?.payload?.reduce((a, b) => a + b?.rcvQtyPCS, 0) || 0;

            const deliveryResponse = await getInventoryStocksDeliveredSum(groupedSchedules[parentIndex].scOrders[childIndex].skuName, user.data.factoryName)

            const totalStockDeliveredQtyCTN = deliveryResponse?.data?.payload?.reduce((a, b) => a + b?.delQtyCtn, 0);
            const totalStockDeliveredQtyPcs = deliveryResponse?.data?.payload?.reduce((a, b) => a + b?.delQtyPcs, 0);

            const availableStockQtyCTN = totalRrcvQtyCTN - totalStockDeliveredQtyCTN;
            const availableStockQtyPcs = totalRrcvQtyPcs - totalStockDeliveredQtyPcs;

            const desiredQuantity = (user.data.userRole == "SPECIAL DISTRIBUTOR" ? groupedSchedules[parentIndex].scOrders[childIndex].delQtyPcs : groupedSchedules[parentIndex].scOrders[childIndex].delQtyCtn) - (user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn)


            if (desiredQuantity > availableStockQtyCTN || desiredQuantity > availableStockQtyPcs) {


                groupedSchedules[parentIndex].scOrders[childIndex].deliveryQty = 0
                groupedSchedules[parentIndex].scOrders[childIndex].alreadyDeliveredQty = 0;
                groupedSchedules[parentIndex].scOrders[childIndex].offerDeliveryAlreadyDone = false;

                // alert("Not Enough Stock of " + groupedSchedules[parentIndex].scOrders[childIndex].skuName + " to be delivered.");
                const stockAlert = Swal.fire({
                    title: 'Stock Alert',
                    text: "Not Enough Stock of " + groupedSchedules[parentIndex].scOrders[childIndex].skuName + " to be delivered.",
                    icon: 'warning',
                    confirmButtonColor: '#4c8118',
                    confirmButtonText: 'Ok',
                })

            } else {

                // groupedSchedules[parentIndex].scOrders[childIndex].deliveryQty = 0
                // groupedSchedules[parentIndex].scOrders[childIndex].alreadyDeliveredQty = 0;
                // groupedSchedules[parentIndex].scOrders[childIndex].offerDeliveryAlreadyDone = false;

            }
        }




        /////////////////////////////////////////////////////////////////////
        //////////////////////Code for Stock Check: End///////////////////////
        /////////////////////////////////////////////////////////////////////


        setGroupedSchedules([...groupedSchedules]);
        setDealerId(groupedSchedules[parentIndex]?.scOrders[childIndex]?.dealerId);
        setSelectedDbType(groupedSchedules[parentIndex]?.scOrders[childIndex]?.dbType);
        if (isChecked) {
            setSelectedSKUName(groupedSchedules[parentIndex].scOrders[childIndex].skuName)
            setSelectedFactoryName(user.data.factoryName)
        }

    }


    const quanityChangeHandler = async (parentIndex, index, ev) => {

        const eventValue = +ev.target.value;

        // const res = await getDeliveryDataBySkuDo(groupedSchedules[parentIndex].scOrders[index].doNo, groupedSchedules[parentIndex].scOrders[index].skuName);

        let res = null;
        if (!groupedSchedules[parentIndex].scOrders[index].offerNo) {
            res = await getDeliveryDataBySkuDo(groupedSchedules[parentIndex].scOrders[index].doNo, groupedSchedules[parentIndex].scOrders[index].skuName);
        }

        const alreadydelQtyCtn = res?.data?.payload[0]?.delQtyCtn || 0;
        const alreadydelQtyPcs = res?.data?.payload[0]?.delQtyPcs || 0;
        const deliveredQty = user.data.userRole == "SPECIAL DISTRIBUTOR" ? alreadydelQtyPcs : alreadydelQtyCtn;

        // groupedSchedules[parentIndex].scOrders[index].deliveryQty = +ev.target.value;

        if (eventValue > (user.data.userRole == "SPECIAL DISTRIBUTOR" ? groupedSchedules[parentIndex].scOrders[index].delQtyPcs : groupedSchedules[parentIndex].scOrders[index].delQtyCtn)) {
            groupedSchedules[parentIndex].scOrders[index].deliveryQty = user.data.userRole == "SPECIAL DISTRIBUTOR" ? groupedSchedules[parentIndex].scOrders[index].delQtyPcs : groupedSchedules[parentIndex].scOrders[index].delQtyCtn;
        } else if (deliveredQty && (eventValue >= deliveredQty - 1)) {
            groupedSchedules[parentIndex].scOrders[index].deliveryQty = (user.data.userRole == "SPECIAL DISTRIBUTOR" ? groupedSchedules[parentIndex].scOrders[index].delQtyPcs : groupedSchedules[parentIndex].scOrders[index].delQtyCtn) - deliveredQty;

        } else if (eventValue < 0) {
            groupedSchedules[parentIndex].scOrders[index].deliveryQty = 0
        } else {
            groupedSchedules[parentIndex].scOrders[index].deliveryQty = eventValue;
        }

        ////////////////////////////////////////////////////////////////////
        //////////////////////Code for Stock Check//////////////////////////
        /////////////////////////////////////////////////////////////////////


        const receivedResponse = await getInventoryStocksSum(groupedSchedules[parentIndex].scOrders[index].skuName, user.data.factoryName);
        const totalRrcvQtyCTN = receivedResponse?.data?.payload?.reduce((a, b) => a + b?.rcvQtyCTN, 0) || 0;
        const totalRrcvQtyPcs = receivedResponse?.data?.payload?.reduce((a, b) => a + b?.rcvQtyPCS, 0) || 0;

        const deliveryResponse = await getInventoryStocksDeliveredSum(groupedSchedules[parentIndex].scOrders[index].skuName, user.data.factoryName)

        const totalStockDeliveredQtyCTN = deliveryResponse?.data?.payload?.reduce((a, b) => a + b?.delQtyCtn, 0);
        const totalStockDeliveredQtyPcs = deliveryResponse?.data?.payload?.reduce((a, b) => a + b?.delQtyPcs, 0);

        const availableStockQtyCTN = totalRrcvQtyCTN - totalStockDeliveredQtyCTN;
        const availableStockQtyPcs = totalRrcvQtyPcs - totalStockDeliveredQtyPcs;

        const desiredQuantity =  groupedSchedules[parentIndex].scOrders[index].deliveryQty


        if (desiredQuantity > availableStockQtyCTN || desiredQuantity > availableStockQtyPcs) {


            groupedSchedules[parentIndex].scOrders[index].deliveryQty = 0

            // alert("Not Enough Stock of " + groupedSchedules[parentIndex].scOrders[index].skuName + " to be delivered.");
            const stockAlert = Swal.fire({
                title: 'Stock Alert',
                text: "Not Enough Stock of " + groupedSchedules[parentIndex].scOrders[index].skuName + " to be delivered.",
                icon: 'warning',
                confirmButtonColor: '#4c8118',
                confirmButtonText: 'Ok',
            })

        } else {

            // groupedSchedules[parentIndex].scOrders[index].deliveryQty = 0
            // groupedSchedules[parentIndex].scOrders[index].alreadyDeliveredQty = 0;
            // groupedSchedules[parentIndex].scOrders[index].offerDeliveryAlreadyDone = false;

        }




        /////////////////////////////////////////////////////////////////////
        //////////////////////Code for Stock Check: End///////////////////////
        /////////////////////////////////////////////////////////////////////

        groupedSchedules[parentIndex].scOrders[index].alreadyDeliveredQty = deliveredQty;

        


        setGroupedSchedules([...groupedSchedules]);
    }

    

    return (
        <>
            <Row className="g-3 mb-3">
                <Col lg={9} xl={9}>
                    <FalconComponentCard noPreview>
                        <FalconComponentCard.Header light={false} noPreview>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>Delivery</span>
                                <span>
                                    Challan No: <strong>{challan}</strong>
                                </span>
                            </div>
                        </FalconComponentCard.Header>
                        <FalconComponentCard.Body language="jsx">
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="mb-3">
                                    {/* <Form.Group
                                        as={Col}
                                        md="6"
                                        controlId="exampleCity"
                                    >
                                        <Form.Label>Zone</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={zoneOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={zoneValue}
                                            onChange={(value) =>
                                                setZoneValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid city.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleState"
                                    >
                                        <Form.Label>Region</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={regionOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={regionValue}
                                            onChange={(value) =>
                                                setRegionValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid state.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Area</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={areaOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={areaValue}
                                            onChange={(value) =>
                                                setAreaValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>DB Point</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={dbPointOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={dbPointValue}
                                            onChange={(value) =>
                                                setDBPointValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group> */}

                                    <Form.Group
                                        as={Col}
                                        md="9"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Distributor IDs</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={distributorOptions}
                                            placeholder="Select Distributor IDs"
                                            isMulti
                                            classNamePrefix="react-select"
                                            value={distributorValue}
                                            onChange={(value) =>
                                                setDistributorValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Transport Name</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                size="sm"
                                                type="text"
                                                className="shadow-none"
                                                placeholder="Transport Name"
                                                value={transportName}
                                                onChange={(ev) =>
                                                    setTransportName(
                                                        ev.target.value
                                                    )
                                                }
                                                required
                                            />
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Vehicle No</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                size="sm"
                                                type="text"
                                                className="shadow-none"
                                                placeholder="Vehicle No"
                                                value={transportNo}
                                                onChange={(ev) =>
                                                    setTransportNo(
                                                        ev.target.value
                                                    )
                                                }
                                                required
                                            />
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Driver Name</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                size="sm"
                                                type="text"
                                                className="shadow-none"
                                                placeholder="Driver Name"
                                                value={driverName}
                                                onChange={(ev) =>
                                                    setDriverName(
                                                        ev.target.value
                                                    )
                                                }
                                                required
                                            />
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Driver Phone</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                size="sm"
                                                type="text"
                                                className="shadow-none"
                                                placeholder="Driver Phone"
                                                value={driverPhone}
                                                onChange={(ev) =>
                                                    setDriverPhone(
                                                        ev.target.value
                                                    )
                                                }
                                                required
                                            />
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Delivery Date</Form.Label>

                                        <div className="datePickerContainer">
                                            <DatePicker
                                                selected={deliveryDate}
                                                onChange={(date) => setDeliveryDate(date)}
                                                formatWeekDay={(day) => day.slice(0, 3)}
                                                className="form-control"
                                                placeholderText="Delivery Start Date"
                                                // disabled={deliveryQty <= 0}
                                                popperPlacement="top-start"
                                                // style={{ width: '100%' }}
                                                required

                                            />
                                        </div>

                                    </Form.Group>
                                </Row>

                                {/* <Button disabled={isLoadingDelivery} onClick={handleReset} type="submit">
                                    {isLoadingDelivery ? 'Loading...' : "Reset"}
                                </Button> */}

                                <Button className='btn btn-secondary' onClick={handleReset} type="button">
                                    Reset
                                </Button>

                                <Button className='ms-2 btn btn-primary' onClick={handleScheduleSubmit} type="button">
                                    Submit
                                </Button>
                            </Form>
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
                <Col lg={3} xl={3}>

                    <div style={{ height: "300px", overflowY: "scroll" }}>
                        <TopTab
                            title={'Available Stock'}
                            className="h-lg-60"
                            // files={
                            //     selectedSKUname &&
                            //         selectedFactoryname &&
                            //         deliverableSchedules.length
                            //         ? [
                            //             {
                            //                 skuName: selectedSKUname,
                            //                 rcvQtyCTN:
                            //                     allStocksData.reduce(
                            //                         (
                            //                             accumulator,
                            //                             currentValue
                            //                         ) => {
                            //                             return (
                            //                                 accumulator +
                            //                                 currentValue.rcvQtyCTN
                            //                             )
                            //                         },
                            //                         0
                            //                     ) -
                            //                     getDeliveredQuantityBySKUOnly(
                            //                         selectedSKUname,
                            //                         selectedFactoryname
                            //                     ),

                            //                 rcvQtyPCS:
                            //                     allStocksData.reduce(
                            //                         (
                            //                             accumulator,
                            //                             currentValue
                            //                         ) => {
                            //                             return (
                            //                                 accumulator +
                            //                                 currentValue.rcvQtyPCS
                            //                             )
                            //                         },
                            //                         0
                            //                     ) -
                            //                     getDeliveredQuantityBySKUOnlyInPieces(
                            //                         selectedSKUname,
                            //                         selectedFactoryname
                            //                     ),

                            //                 selectedDbType: selectedDbType,
                            //             },
                            //         ]
                            //         : []
                            // }


                            files={stocksList}



                        />
                    </div>

                    <div className="my-3">
                        <SpaceWarning
                            dealerId={dealerId}
                            dealerName={dealerName}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col lg={9} xl={9}>
                    {groupedSchedules.length == 0 ? (
                        null
                    ) : (

                        <div className="bg-white" style={{ maxWidth: '100%' }}>
                            <Card className="p-0" style={{ maxWidth: '100%' }}>
                                <Card.Body>
                                    <div className="fs--1">
                                        <SimpleBarReact>

                                            {groupedSchedules?.length > 0 ? (
                                                <Table borderless className="mb-10 fs--1">
                                                    <thead className="bg-light">
                                                        <tr
                                                            style={{
                                                                fontSize: 'medium',
                                                                fontWeight: '500',
                                                                fontFamily: 'Poppins',
                                                                color: '#354151',
                                                            }}
                                                        >
                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                ...
                                                            </th>
                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                <input
                                                                    type="checkbox"


                                                                    onChange={(e) =>
                                                                        handleCheckAll(e)
                                                                    }

                                                                    checked={groupedSchedules?.every(order => order.isChecked)}
                                                                />
                                                            </th>

                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                SL
                                                            </th>


                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Distributor
                                                            </th>


                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Scheduled Qty Ctn
                                                            </th>
                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Scheduled Qty PCS
                                                            </th>
                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Scheduled Qty MT
                                                            </th>


                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Offer Qty PCS
                                                            </th>

                                                            <th className="align-middle white-space-nowrap text-center ">
                                                                Total Price
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody
                                                        className="list"
                                                        id="table-purchase-body"
                                                    >
                                                        {groupedSchedules?.map(
                                                            (order, index) => {
                                                                return (
                                                                    <>
                                                                        <tr
                                                                            key={index}
                                                                            style={{
                                                                                fontSize:
                                                                                    'medium',
                                                                                fontWeight:
                                                                                    '500',
                                                                                fontFamily:
                                                                                    'Poppins',
                                                                                color: '#354151',
                                                                            }}
                                                                        >
                                                                            <td className="align-middle text-center border-0">
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        expandHandler(
                                                                                            order
                                                                                        )
                                                                                    }
                                                                                    aria-controls="example-collapse-text"
                                                                                    aria-expanded={
                                                                                        order.open
                                                                                    }
                                                                                    variant="falcon-primary"
                                                                                    className="custom-button" // Apply the custom class
                                                                                >
                                                                                    {order.open &&
                                                                                        expand
                                                                                        ? '-'
                                                                                        : '+'}
                                                                                </Button>
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                <input
                                                                                    type="checkbox"

                                                                                    checked={order.isChecked}
                                                                                    onChange={() => handleParentCheck(index, !order.isChecked)}
                                                                                />
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {index + 1}
                                                                            </td>

                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    order?.distributorName
                                                                                }
                                                                            </td>


                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber(order?.delQtyCtn)
                                                                                }
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber(order?.delQtyPcs)
                                                                                }
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber((order?.delQtyPcs * order?.weightPerPC) / 1000000)
                                                                                }
                                                                            </td>

                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber(order?.offerQtyPCS)
                                                                                }
                                                                            </td>
                                                                            <td className="align-middle text-center">
                                                                                {
                                                                                    formatNumber(order?.totalPrice)
                                                                                }
                                                                            </td>



                                                                        </tr>
                                                                        <tr
                                                                            style={{
                                                                                display:
                                                                                    order.open &&
                                                                                        expand
                                                                                        ? 'table-row'
                                                                                        : 'none',
                                                                            }}
                                                                        >
                                                                            <td colSpan={15}>
                                                                                <div className="p-0">
                                                                                    <CollapseDelivery

                                                                                        ordersInfo={order.scOrders}
                                                                                        setSelectedSKUName={setSelectedSKUName}
                                                                                        setSelectedDoNo={setSelectedDoNo}
                                                                                        setDealerId={setDealerId}
                                                                                        setDealerName={setDealerName}
                                                                                        setSelectedDbType={setSelectedDbType}
                                                                                        // qtyChangeHandler={qtyChangeHandler}
                                                                                        // qtyChangeHandlerForPieces={qtyChangeHandlerForPieces}
                                                                                        // deliveryDateChangeHandler={deliveryDateChangeHandler}
                                                                                        // deliveryEndDateChangeHandler={deliveryEndDateChangeHandler}
                                                                                        // comboOffers={order.scOrders.filter(item => !item.superDBId && item.offerNo && item.offerName)}
                                                                                        comboOffers={[]}
                                                                                        updatableCombos={updatableCombos}
                                                                                        setUpdatableCombos={setUpdatableCombos}
                                                                                        handleChildCheck={handleChildCheck}
                                                                                        parentIndex={index}
                                                                                        quanityChangeHandler={quanityChangeHandler}
                                                                                        handleParentCheck={handleParentCheck}

                                                                                    ></CollapseDelivery>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }
                                                        )}
                                                    </tbody>
                                                </Table>
                                            ) : (
                                                <div className="text-center p-4">
                                                    <h5>No Orders Found</h5>
                                                </div>
                                            )}

                                        </SimpleBarReact>

                                    </div>


                                </Card.Body>
                            </Card>
                        </div>
                    )}
                </Col>
                <Col lg={3} xl={3}>
                    <BottomTab
                        title={'Delivery Confirmed Info'}
                        files={
                            // selectedSKUname &&
                            // selectedFactoryname &&
                            // selectedDoNo &&
                            // deliverableSchedules.length
                            //     ? allDeliveries
                            //     : []

                            // selectedSKUname &&
                            //     selectedFactoryname &&
                            //     selectedDoNo &&
                            //     deliverableSchedules.length
                            //     ? allDeliveries.filter(
                            //         (tom) =>
                            //             tom.doNo == selectedDoNo &&
                            //             tom.skuName == selectedSKUname
                            //     )
                            //     : []

                            Object.values(groupedSchedules).map((order) => {
                                return order.scOrders.filter((tr) => tr.alreadyDeliveredQty > 0 && tr.isChecked)
                            }).flat()
                        }


                        className="h-lg-100"
                    />
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Delivery)
