import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { useSelector } from "react-redux";
import { PHONE_NUMBER_REGEX } from "utils/validationRegex";

const DistributorForm = () => {
    const [selectedZone, setSelectedZone] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [zoneList, setZoneList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [dbPointList, setDbPointList] = useState([]);
    const [superDBList, setSuperDBList] = useState([]);
    const [distributorById, setDistributorById] = useState([]);
    const [isLoading, setIsLoading] = useState({
        general: false,
        zone: false,
        region: false,
        area: false,
        dbPoint: false,
        superDB: false,
    });
    const { updateID } = useParams();
    const navigate = useNavigate();
    const { companyName } = useSelector((state) => state.auth.user.data);


    //  Date Format
    function formatDateToYYYYMMDD(isoDateString) {
        const date = new Date(isoDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }


    const distributorTypeList = [
        {
            label: "SUPER DISTRIBUTOR",
            value: "SUPER DISTRIBUTOR"
        },
        {
            label: "GENERAL DISTRIBUTOR",
            value: "GENERAL DISTRIBUTOR"
        },
        {
            label: "WHOLESALE DISTRIBUTOR",
            value: "WHOLESALE DISTRIBUTOR"
        },
        {
            label: "COMMISSION DISTRIBUTOR",
            value: "COMMISSION DISTRIBUTOR"
        },
        {
            label: "SPOT DISTRIBUTOR",
            value: "SPOT DISTRIBUTOR"
        },
        {
            label: "SPECIAL DISTRIBUTOR",
            value: "SPECIAL DISTRIBUTOR"
        },

    ]

    const hasPC = [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        },

    ]

    const hasPrinter = [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        },

    ]

    const unit = [
        {
            label: "CTN",
            value: "CTN"
        },
        {
            label: "PCS",
            value: "PCS"
        },

    ]

    const hasApp = [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        },

    ]

    const hasStorageList = [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        },

    ]

    const offer = [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        },

    ]

    const depotList = [

        {
            label: "Dhaka Central Depot",
            value: "Dhaka Central Depot"
        },
        {
            label: "Ctg Depot",
            value: "Ctg Depot"
        },
        {
            label: "Noakhali Depot",
            value: "Noakhali Depot"
        },
        {
            label: "Sylhet Depot",
            value: "Sylhet Depot"
        },
        {
            label: "Jessore Depot",
            value: "Jessore Depot"
        },
        {
            label: "Mymensingh Depot",
            value: "Mymensingh Depot"
        },
        {
            label: "Bogra Depot",
            value: "Bogra Depot"
        },
        {
            label: "Barisal Depot",
            value: "Barisal Depot"
        },
        {
            label: "Tekerhat Depot",
            value: "Tekerhat Depot"
        },
        {
            label: "Rangpur Depot",
            value: "Rangpur Depot"
        },
    ]

    const discount = [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        },

    ]

    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(prevState => ({ ...prevState, general: true }));
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success) {
                        setDistributorById(response?.data?.payload?.distributor);
                        setSelectedZone(response?.data?.payload?.distributor?.zoneName)
                        setSelectedRegion(response?.data?.payload?.distributor?.regionName)
                        setSelectedArea(response?.data?.payload?.distributor?.areaName)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, general: false }));
                }
            }
        };

        fetchData();
    }, [updateID]);

    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, zone: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setZoneList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, zone: false }));
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedZone) {
            setIsLoading(prevState => ({ ...prevState, region: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.regionName,
                            value: data.regionName
                        }));

                        setRegionList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, region: false }));
                }
            };

            fetchData();
        }
    }, [selectedZone]);

    useEffect(() => {
        if (selectedRegion) {
            setIsLoading(prevState => ({ ...prevState, area: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}areas/${selectedRegion}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.areaName,
                            value: data.areaName
                        }));

                        setAreaList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, area: false }));
                }
            };

            fetchData();
        }
    }, [selectedRegion]);


    useEffect(() => {
        if (selectedArea) {
            setIsLoading(prevState => ({ ...prevState, dbPoint: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`;
                    const response = await axios.get(url, { headers: authHeader() });
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.dbPointName,
                            value: data.dbPointName
                        }));
                        setDbPointList(modifyData);
                    }
                }
                catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, dbPoint: false }));
                }
            }
            fetchData();
        }
    }, [selectedArea])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?userRole=SUPER DISTRIBUTOR&zoneName=${selectedZone}&regionName=${selectedRegion}&areaName=${selectedArea}`
                const response = await axios.get(url, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.distributors.map(data => ({
                        id: data.userId,
                        label: data.distributorName,
                        value: data.distributorName
                    }));
                    setSuperDBList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, general: false }));
            }
        }
        fetchData();
    }, [])


    const handleSubmitForm = async (values, actions) => {


        const handleSubmitData = {
            distributorName: values.distributorName,
            userId: values.userId,
            password: values.password,
            userRole: values.distributorType.value,
            erpId: values.erpId,
            crBDTLimit: values.crBDTLimit,
            bgAmount: values.bgAmount,
            proprietor: values.proprietor,
            address: values.address,
            zoneName: values.zoneName.value,
            regionName: values.regionName.value,
            areaName: values.areaName.value,
            dbPoint: values.dbPointName.value,
            companyName: companyName,
            phoneNumber: values.phoneNumber,
            unitName: values.unitName.value,
            note: values.note,

        };
        if (values.dbDepo) {
            handleSubmitData.dbDepo = values.dbDepo.value;
        }
        if (values.email) {
            handleSubmitData.email = values.email
        }
        if (values.hasStorage) {
            handleSubmitData.hasStorage = values.hasStorage.value
        }
        if (values.proprietorDOB) {
            handleSubmitData.proprietorDOB = values.proprietorDOB;
        }
        if (values.dbOpeningDate) {
            handleSubmitData.dbOpeningDate = values.dbOpeningDate;
        }

        if (values.hasPC) {
            handleSubmitData.hasPC = values.hasPC.value;
        }
        if (values.offer) {
            handleSubmitData.offer = values.offer.value;
        }
        if (values.discount) {
            handleSubmitData.discount = values.discount.value;
        }
        if (values.hasPrinter) {
            handleSubmitData.hasPrinter = values.hasPrinter.value;
        }
        if (values.hasApp) {
            handleSubmitData.hasApp = values.hasApp.value;
        }
        if (values.superDBId) {
            handleSubmitData.superDBId = values.superDBId;
        }
        if (values.emergencyContactName) {
            handleSubmitData.emergencyContactName = values.emergencyContactName;
        }
        if (values.emergencyMobileNumber) {
            handleSubmitData.emergencyMobileNumber = values.emergencyMobileNumber;
        }
        if (values.emergencyContactRelation) {
            handleSubmitData.emergencyContactRelation = values.emergencyContactRelation;
        }
        if (!updateID) {
            handleSubmitData.userId = values.userId;
        }
        setIsLoading(prevState => ({ ...prevState, general: true }));
        const url = updateID
            ? `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${updateID}`
            : `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/create-db`;

        try {
            const axiosMethod = updateID ? axios.put : axios.post;
            const response = await axiosMethod(url, handleSubmitData, { headers: authHeader() });

            if (response.status === 200 || response.data.status) {
                navigate("/user/distributor");
                toast.success(response.data.message);
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(prevState => ({ ...prevState, general: false }));
        }
    };


    // Yup validation schema
    const handleSchema = Yup.object().shape({
        userId: Yup.string().required("please enter a value for the dealer id field"),
        password: Yup.string().required("please enter a value for the password field"),
        distributorType: Yup.object().required("please select a value for the distributor type field"),
        superDBId: Yup.string().nullable(),
        zoneName: Yup.object().required("please select a value for the zone field"),
        regionName: Yup.object().required("please select a value for the region field"),
        areaName: Yup.object().required("please select a value for the area field"),
        dbPointName: Yup.object().required("please select a value for the db point field"),
        hasStorage: Yup.object().nullable(),
        note: Yup.string().nullable(),
        dbDepo: Yup.object().nullable(),
        email: Yup.string().email("Invalid email").nullable(),
        distributorName: Yup.string()
            .min(2, "Too Short!")
            .max(150, "Too Long!")
            .required("Please enter a value for the distributor name field")
            .test(
                "starts with a number",
                "Field input should not start with a number",
                (value) => {
                    return !/^\d/.test(value);
                }
            )
            .test(
                "no special characters",
                "Field input should not contain special characters",
                (value) => {
                    return /^[a-zA-Z0-9\s]*$/.test(value);
                }
            ),

        erpId: Yup.string().required("please enter a value for the erp field"),
        address: Yup.string().min(5, "Too Short").required("please enter a value for the address field"),
        hasApp: Yup.object().required("please enter a value for the secondary app field"),
        hasPrinter: Yup.object().nullable(),
        hasPC: Yup.object().nullable(),
        offer: Yup.object().nullable(),
        discount: Yup.object().nullable(),
        bgAmount: Yup.number().nullable(),
        proprietor: Yup.string()
            .min(2, "Too Short!")
            .max(150, "Too Long!")
            .required("Please enter a value for the distributor name field")
            .test(
                "starts with a number",
                "Field input should not start with a number",
                (value) => {
                    return !/^\d/.test(value);
                }
            )
            .test(
                "no special characters",
                "Field input should not contain special characters",
                (value) => {
                    return /^[a-zA-Z0-9\s]*$/.test(value);
                }
            ),
        phoneNumber: Yup.string().required("please enter a value for the mobile number field"),
        dbOpeningDate: Yup.string().required("please select a value for the opening date field"),
        emergencyContactName: Yup.string()
            .min(2, "Too Short!")
            .max(80, "Too Long!")
            .test(
                "starts with a number",
                "Filed input should not start with a number",
                (value) => {
                    return !/^\d/.test(value);
                }
            ),
        emergencyMobileNumber: Yup.string().nullable(),
        emergencyContactRelation: Yup.string().min(2, "Too Short!")
            .max(80, "Too Long!")
            .test(
                "starts with a number",
                "Filed input should not start with a number",
                (value) => {
                    return !/^\d/.test(value);
                }
            ),
    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Distributor" : "Add Distributor"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    {isLoading.general && <LoadingIcon />}
                    <Formik
                        initialValues={{
                            userId: distributorById.userId || "",
                            password: distributorById.password || "",
                            distributorName: distributorById.distributorName || "",
                            distributorType: distributorTypeList.find(item => item.value === distributorById?.userRole) || null,
                            // superDBId: superDBList.find(item => item.id === distributorById?.superDBId) || null,
                            superDBId: distributorById.superDBId || 0,
                            hasStorage: hasStorageList.find(item => item.value === distributorById?.hasStorage) || null,
                            erpId: distributorById.erpId || "",
                            proprietor: distributorById.proprietor || "",
                            proprietorDOB: distributorById?.proprietorDOB ? formatDateToYYYYMMDD(distributorById.proprietorDOB) : "",
                            dbOpeningDate: distributorById.dbOpeningDate ? formatDateToYYYYMMDD(distributorById.dbOpeningDate) : "",
                            address: distributorById.address || "",
                            hasPC: hasPC.find(item => item.value === distributorById?.hasPC) || null,
                            offer: offer.find(item => item.value === distributorById?.offer) || null,
                            discount: discount.find(item => item.value === distributorById?.discount) || null,
                            hasPrinter: hasPrinter.find(item => item.value === distributorById?.hasPrinter) || null,
                            hasApp: hasApp.find(item => item.value === distributorById?.hasApp) || null,
                            zoneName: zoneList.find(item => item.value === distributorById.zoneName) || null,
                            regionName: regionList.find(item => item.value === distributorById?.regionName) || null,
                            areaName: areaList.find(item => item.value === distributorById?.areaName) || null,
                            dbPointName: dbPointList.find(item => item.value === distributorById?.dbPoint) || null,
                            phoneNumber: distributorById.phoneNumber || "",
                            emergencyContactName: distributorById.emergencyContactName || "",
                            emergencyMobileNumber: distributorById.emergencyMobileNumber || "",
                            emergencyContactRelation: distributorById.emergencyContactRelation || "",
                            email: distributorById.email || null,
                            crBDTLimit: distributorById.crBDTLimit || 0,
                            bgAmount: distributorById.bgAmount || 0,
                            note: distributorById.note || "",
                            unitName: { value: distributorById.unitName || 'CTN', label: distributorById.unitName || 'CTN' },
                            dbDepo: depotList.find(item => item.value === distributorById?.dbDepo) || null,

                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched

                        }) => {
                         
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Zone</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={zoneList}
                                                placeholder="Select Zone"
                                                classNamePrefix="react-select"
                                                name="zoneName"
                                                value={values.zoneName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("zoneName", selectedOption);
                                                    setSelectedZone(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("zoneName", true, true) }}
                                                isLoading={isLoading.zone}
                                            />
                                            {touched.zoneName && errors.zoneName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.zoneName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Region</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={regionList}
                                                placeholder="Select Region"
                                                classNamePrefix="react-select"
                                                name="regionName"
                                                value={values.regionName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("regionName", selectedOption);
                                                    setSelectedRegion(selectedOption.value)

                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("regionName", true, true) }}
                                                isLoading={isLoading.region}
                                            />
                                            {touched.regionName && errors.regionName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.regionName}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Area</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={areaList}
                                                placeholder="Select Area"
                                                classNamePrefix="react-select"
                                                name="areaName"
                                                value={values.areaName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("areaName", selectedOption);
                                                    setSelectedArea(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("areaName", true, true); }}
                                                isLoading={isLoading.area}
                                            />
                                            {touched.areaName && errors.areaName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.areaName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select DB Point</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={dbPointList}
                                                placeholder="Select DB Point"
                                                classNamePrefix="react-select"
                                                name="dbPointName"
                                                value={values.dbPointName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("dbPointName", selectedOption);
                                                    setSelectedArea(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("dbPointName", true, true); }}
                                                isLoading={isLoading.dbPoint}
                                            />
                                            {touched.dbPointName && errors.dbPointName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.dbPointName}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Distributor ID</Form.Label>
                                            <Form.Control
                                                name="userId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Distributor Id"
                                                required
                                                value={values.userId}
                                                disabled={updateID}
                                            />
                                            {touched.userId && errors.userId && (
                                                <div style={{ color: "red" }}>
                                                    {errors.userId}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Distributor password</Form.Label>
                                            <Form.Control
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Distributor password"
                                                required
                                                value={values.password}
                                            />
                                            {touched.password && errors.password && (
                                                <div style={{ color: "red" }}>
                                                    {errors.password}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Distributor Name</Form.Label>
                                            <Form.Control
                                                name="distributorName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Distributor Name"
                                                required
                                                value={values.distributorName}
                                            />
                                            {touched.distributorName && errors.distributorName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.distributorName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Distributor Types</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributorTypeList}
                                                placeholder="Select Distributor Types"
                                                classNamePrefix="react-select"
                                                name="distributorType"
                                                value={values.distributorType}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "distributorType",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("distributorType", true, true) }}
                                            />

                                            {
                                                touched.distributorType && errors.distributorType && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.distributorType}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        {/*   <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Super Distributor</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={superDBList}
                                                placeholder="Select Super Distributor"
                                                classNamePrefix="react-select"
                                                name="superDBId"
                                                value={values.superDBId}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "superDBId",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("superDBId", true, true) }}
                                                isDisabled={(values.distributorType?.value === "Super Distributor" ||
                                                    values.distributorType?.value === "super distributor" ||
                                                    values.distributorType?.value === "SUPER DISTRIBUTOR" ||
                                                    values.distributorType?.value === "super DB" ||
                                                    values.distributorType?.value === "SUPER DB" ||
                                                    values.distributorType?.value === "Super DB")}
                                            />

                                            {
                                                touched.superDBId && errors.superDBId && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.superDBId}
                                                    </div>
                                                )}
                                        </Form.Group> */}
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Super DB ID</Form.Label>
                                            <Form.Control
                                                name="superDBId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Super DB ID"
                                                value={values.superDBId}
                                            />
                                            {touched.superDBId && errors.superDBId && (
                                                <div style={{ color: "red" }}>
                                                    {errors.superDBId}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                name="phoneNumber"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                                value={values.phoneNumber}
                                            />
                                            {touched.phoneNumber && errors.phoneNumber && (
                                                <div style={{ color: "red" }}>
                                                    {errors.phoneNumber}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>ERP ID</Form.Label>
                                            <Form.Control
                                                name="erpId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter ERP ID"
                                                required
                                                value={values.erpId}
                                            />
                                            {touched.erpId && errors.erpId && (
                                                <div style={{ color: "red" }}>
                                                    {errors.erpId}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Credit Limit</Form.Label>
                                            <Form.Control
                                                name="crBDTLimit"

                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Credit Limit"

                                                value={values.crBDTLimit}
                                            />
                                            {touched.crBDTLimit && errors.crBDTLimit && (
                                                <div style={{ color: "red" }}>
                                                    {errors.crBDTLimit}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>BG Amount</Form.Label>
                                            <Form.Control
                                                name="bgAmount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter BG Amount"
                                                value={values.bgAmount}
                                            />
                                            {touched.bgAmount && errors.bgAmount && (
                                                <div style={{ color: "red" }}>
                                                    {errors.bgAmount}
                                                </div>
                                            )}
                                        </Form.Group>
                                       
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Depot</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={depotList}
                                                placeholder="Select Depot"
                                                classNamePrefix="react-select"
                                                name="dbDepo"
                                                value={values.dbDepo}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "dbDepo",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("dbDepo", true, true) }}
                                            />

                                            {
                                                touched.dbDepo && errors.dbDepo && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.dbDepo}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Proprietor</Form.Label>
                                            <Form.Control
                                                name="proprietor"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter proprietor Name"
                                                value={values.proprietor}
                                            />
                                            {touched.proprietor && errors.proprietor && (
                                                <div style={{ color: "red" }}>
                                                    {errors.proprietor}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Proprietor DOB</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    // selected={new Date(values.dateOfJoining)}
                                                    // formatWeekDay={(day) => day.slice(0, 3)}
                                                    // dateFormat="MMMM d, yyyy"
                                                    // minDate={new Date()}
                                                    className="form-control"
                                                    placeholderText="Select Date Of Birth"
                                                    type="date"
                                                    name="proprietorDOB"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.proprietorDOB}
                                                />
                                            </InputGroup>


                                            {touched.proprietorDOB &&
                                                errors.proprietorDOB && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.proprietorDOB}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>DB Opening Date</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    // selected={new Date(values.dateOfJoining)}
                                                    // formatWeekDay={(day) => day.slice(0, 3)}
                                                    // dateFormat="MMMM d, yyyy"
                                                    // minDate={new Date()}
                                                    className="form-control"
                                                    placeholderText="Select Date Of Birth"
                                                    type="date"
                                                    name="dbOpeningDate"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.dbOpeningDate}
                                                />
                                            </InputGroup>
                                            {touched.dbOpeningDate &&
                                                errors.dbOpeningDate && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.dbOpeningDate}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                name="address"
                                                as="textarea"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter  address"
                                                value={values.address}
                                            />
                                            {touched.address && errors.address && (
                                                <div style={{ color: "red" }}>
                                                    {errors.address}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Computer</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={hasPC}
                                                placeholder="Select Computer"
                                                classNamePrefix="react-select"
                                                name="hasPC"
                                                value={values.hasPC}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "hasPC",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("hasPC", true, true) }}
                                            />

                                            {
                                                touched.hasPC && errors.hasPC && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.hasPC}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Printer</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={hasPrinter}
                                                placeholder="Select Printer"
                                                classNamePrefix="react-select"
                                                name="hasPrinter"
                                                value={values.hasPrinter}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "hasPrinter",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("hasPrinter", true, true) }}
                                            />

                                            {
                                                touched.hasPrinter && errors.hasPrinter && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.hasPrinter}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Secondary App</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={hasApp}
                                                placeholder="Select Secondary App"
                                                classNamePrefix="react-select"
                                                name="hasApp"
                                                value={values.hasApp}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "hasApp",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("hasApp", true, true) }}
                                            />

                                            {
                                                touched.hasApp && errors.hasApp && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.hasApp}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Storage</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={hasStorageList}
                                                placeholder="Select storage"
                                                classNamePrefix="react-select"
                                                name="hasStorage"
                                                value={values.hasStorage}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "hasStorage",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("hasStorage", true, true) }}
                                            />

                                            {
                                                touched.hasStorage && errors.hasStorage && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.hasStorage}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Emergency Contact Name</Form.Label>
                                            <Form.Control
                                                name="emergencyContactName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Emergency Contact Name"
                                                value={values.emergencyContactName}
                                            />
                                            {touched.emergencyContactName && errors.emergencyContactName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.emergencyContactName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Emergency Mobile Number</Form.Label>
                                            <Form.Control
                                                name="emergencyMobileNumber"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Emergency Mobile Number"
                                                value={values.emergencyMobileNumber}
                                            />
                                            {touched.emergencyMobileNumber && errors.emergencyMobileNumber && (
                                                <div style={{ color: "red" }}>
                                                    {errors.emergencyMobileNumber}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Purchase Unit Name</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={unit}
                                                placeholder="Select Unit"
                                                classNamePrefix="react-select"
                                                name="unitName"
                                                value={values.unitName}
                                                defaultValue={{ value: 'CTN', label: 'CTN' }}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "unitName",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("unitName", true, true) }}
                                            />

                                            {
                                                touched.hasPrinter && errors.hasPrinter && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.hasPrinter}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Offer</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={offer}
                                                placeholder="Select Offer"
                                                classNamePrefix="react-select"
                                                name="offer"
                                                value={values.offer}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "offer",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("offer", true, true) }}
                                            />

                                            {
                                                touched.offer && errors.offer && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.offer}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Discount</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={discount}
                                                placeholder="Select Discount"
                                                classNamePrefix="react-select"
                                                name="discount"
                                                value={values.discount}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "discount",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("discount", true, true) }}
                                            />

                                            {
                                                touched.discount && errors.discount && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.discount}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Emergency Contact Relation</Form.Label>
                                            <Form.Control
                                                name="emergencyContactRelation"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Emergency Contact Relation"
                                                value={values.emergencyContactRelation}
                                            />
                                            {touched.emergencyContactRelation && errors.emergencyContactRelation && (
                                                <div style={{ color: "red" }}>
                                                    {errors.emergencyContactRelation}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Email"
                                                value={values.email}
                                            />
                                            {touched.email && errors.email && (
                                                <div style={{ color: "red" }}>
                                                    {errors.email}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Note</Form.Label>
                                            <Form.Control
                                                as={"textarea"}
                                                name="note"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Note"
                                                value={values.note}
                                            />
                                            {touched.note && errors.note && (
                                                <div style={{ color: "red" }}>
                                                    {errors.note}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/user/distributor")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default DistributorForm;
