import FalconComponentCard from 'components/common/FalconComponentCard'
import SharedFiles from 'components/dashboards/default/SharedFiles'
import SharedFiles2 from 'components/dashboards/default/SharedFiles2'
import SpaceWarning from 'components/dashboards/default/SpaceWarning'
import BestSellingProducts from 'components/dashboards/e-commerce/BestSellingProducts'
import AppContext from 'context/Context'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import Swal from 'sweetalert2'

import {
    getAllSchedules,
    getAreas,
    getDBPoints,
    getDistributors,
    getInventoryStocks,
    getOrdersByDealerIds,
    getOrdersByDealerIdSuper,
    getRegions,
    getZones,
    scheduleSubmit,
    getInventoryStocksSuperDistributorReceived,
    getInventoryStocksSuperDistributorDelivered,
    getOrdersByDealerIdsSDB
} from 'services/api'

const SchedulingSuperDistributor = ({ user }) => {
    const [first, setfirst] = useState('')
    const [validated, setValidated] = useState(false)
    const navigate = useNavigate()
    const [zoneValue, setZoneValue] = useState(null)
    const [regionValue, setRegionValue] = useState(null)
    const [areaValue, setAreaValue] = useState(null)
    const [dbPointValue, setDBPointValue] = useState(null)
    const [distributorValue, setDistributorValue] = useState([])
    const [schedulableOrders, setSchedulableOrders] = useState([])
    const [selectedFactoryname, setSelectedFactoryName] = useState('')
    const [selectedSKUname, setSelectedSKUName] = useState('')
    const [selectedDoNo, setSelectedDoNo] = useState('')
    const [allOrdersData, setAllOrdersData] = useState([])
    const [updatableCombos, setUpdatableCombos] = useState([])

    const [sc, setSc] = useState(Math.floor(Math.random() * 1000000000) + '-' + user.data.userId)



    //for setting dealer id  when changing quantity
    const [dealerId, setDealerId] = useState(null)
    const [dealerName, setDealerName] = useState(null)
    const [selectedDbType, setSelectedDbType] = useState(null)

    var firstDay = new Date();
    var nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);

    const [deliveryDate, setDeliveryDate] = useState(firstDay);
    const [deliveryDateEnd, setDeliveryDateEnd] = useState(nextWeek);







    const [submittedOrders, setSubmittedOrders] = useState([])

    const { data: zoneData, isLoading: isLoadingZone } = useQuery({
        queryKey: ['zones'],
        queryFn: getZones,
    })

    const { data: regionData, isLoading: isLoadingRegion } = useQuery({
        queryKey: ['regions'],
        queryFn: getRegions,
    })

    const { data: areaData, isLoading: isLoadingArea } = useQuery({
        queryKey: ['areas'],
        queryFn: getAreas,
    })

    const { data: dbPointData, isLoading: isLoadingDBPoint } = useQuery({
        queryKey: ['dbPoints'],
        queryFn: getDBPoints,
    })

    const { data: distributorData, isLoading: isLoadingDistributor } = useQuery(
        {
            queryKey: ['distributors'],
            queryFn: getDistributors,
        }
    )

    // const {
    //     data: orderData,
    //     isLoading: isLoadingOrder,
    //     isError: orderDataError,
    //     refetch: orderDataRefetch,
    // } = useQuery({
    //     queryKey: ['orders'],
    //     queryFn: () => getOrdersByDealerId(distributorValue?.userId || ''),
    // })

    const fetchOrdersData = () => {
        getOrdersByDealerIdsSDB({ dealerIds: distributorValue.map(item => item.userId) })
            // getOrdersByDealerIdSuper(user.data.userId)
            .then((res) => {
                if (res.data?.success) {
                    console.log(res.data?.message)

                    // setAllOrdersData(res.data.payload.moreDBOrders)
                    const modifiedOrders = res.data?.payload?.moreDBOrders?.map((order) => {
                        return {
                            ...order,
                            deliveryQty: user.data.userRole == "SPECIAL DISTRIBUTOR" ? order.totalOrderQtyPCS : order.orderQtyCtn
                        }
                    })

                    setAllOrdersData(modifiedOrders)

                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    toast.error(e.response?.data?.errors)
                }
            })
    }


    const {
        data: stockData,
        isLoading: isLoadingStock,
        isError,
        refetch,
    } = useQuery({
        queryKey: ['stocks'],
        // queryFn: () => getInventoryStocks(selectedSKUname, selectedFactoryname),
        queryFn: () => getInventoryStocksSuperDistributorReceived(selectedSKUname, user.data.userId),

    })

    const {
        data: stockDataDelivered,
        isLoading: isLoadingStockDelivered,
        isError: isErrorDelivered,
        refetch: refetchDelivered,
    } = useQuery({
        queryKey: ['stocksDelivered'],
        // queryFn: () => getInventoryStocks(selectedSKUname, selectedFactoryname),
        queryFn: () => getInventoryStocksSuperDistributorDelivered(selectedSKUname, user.data.userId),

    })

    const {
        data: scheduleData,
        isLoading: isLoadingSchedule,
        isError: scheduleError,
        refetch: scheduleRefetch,
    } = useQuery({
        queryKey: ['schedule'],
        queryFn: () => getAllSchedules(),
    })

    useEffect(() => {

        if (distributorValue.length) {
            fetchOrdersData();
        }

    }, [distributorValue.length, submittedOrders.length])

    useEffect(() => {
        refetch();
        refetchDelivered();
    }, [selectedSKUname, selectedFactoryname])

    // useEffect(() => {
    //     scheduleRefetch()
    // }, [selectedDoNo, selectedSKUname])

    useEffect(() => {
        refetch()
        scheduleRefetch()
    }, [submittedOrders.length])

    const handleSubmit = (event) => {
        console.log('distributorValue', distributorValue)
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }

        setValidated(true)
    }

    const {
        config: { isNavbarVerticalCollapsed, isFluid, isRTL },
        setConfig,
    } = useContext(AppContext)
    useEffect(() => {
        const handleClick = () => {
            document
                .getElementsByTagName('html')[0]
                .classList.toggle('navbar-vertical-collapsed')
            setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed)
        }

        handleClick()
    }, [])

    // const zoneOptions = zoneData?.data?.payload?.result.map((zone) => ({
    //     value: zone._id,
    //     label: zone.name,
    //     ...zone,
    // }))

    let zoneOptions = []
    if (zoneData && zoneData.data.payload.result.length) {
        zoneOptions = zoneData.data.payload.result.map((zone) => ({
            value: zone._id,
            label: zone.name,
            ...zone,
        }))
    }

    // const regionOptions = regionData?.data?.payload?.result.map((region) => ({
    //     value: region._id,
    //     label: region.regionName,
    //     ...region,
    // }))

    let regionOptions = []
    if (regionData && regionData.data.payload.result.length && zoneValue) {
        regionOptions = regionData.data.payload.result
            .filter((item) => item.zoneName == zoneValue.label)
            .map((region) => ({
                value: region._id,
                label: region.regionName,
                ...region,
            }))
    }

    // const areaOptions = areaData?.data?.payload?.result.map((area) => ({
    //     value: area._id,
    //     label: area.areaName,
    //     ...area,
    // }))

    let areaOptions = []
    if (areaData && areaData.data.payload.result.length && regionValue) {
        areaOptions = areaData.data.payload.result
            .filter((item) => item.regionName == regionValue.label)
            .map((area) => ({
                value: area._id,
                label: area.areaName,
                ...area,
            }))
    }

    // const dbPointOptions = dbPointData?.data?.payload?.result.map(
    //     (dbPoint) => ({
    //         value: dbPoint._id,
    //         label: dbPoint.dbPointName,
    //         ...dbPoint,
    //     })
    // )

    let dbPointOptions = []
    if (dbPointData && dbPointData.data.payload.result.length && areaValue) {
        dbPointOptions = dbPointData.data.payload.result
            .filter((item) => item.areaName == areaValue.label)
            .map((dbPoint) => ({
                value: dbPoint._id,
                label: dbPoint.dbPointName,
                ...dbPoint,
            }))
    }

    // const distributorOptions = distributorData?.data?.payload?.distributors.map(
    //     (distributor) => ({
    //         value: distributor._id,
    //         label: distributor.distributorName,
    //         ...distributor,
    //     })
    // )

    let distributorOptions = []
    if (
        distributorData &&
        distributorData.data.payload.distributors.length
        // && dbPointValue
    ) {
        distributorOptions = distributorData.data.payload.distributors
            .filter((item) => item.superDBId && item.superDBId == user.data.userId)
            .map((distributor) => ({
                // value: distributor._id,
                // label: distributor.distributorName,

                value: distributor.userId,
                label: distributor.userId + " (" + distributor.distributorName + ")",
                ...distributor,
            }))
    }

    // const allOrdersData = orderData?.data?.payload?.result || []

    // let allOrdersData = []
    // if (orderData && orderData.data.payload.result.length) {
    //     allOrdersData = orderData.data.payload.result.filter(
    //         (item) => item.status == 6
    //     )
    // }

    // const allStocksData = stockData?.data?.payload?.result || []

    // let allStocksData = []
    // if (stockData && stockData.data.payload.result.length) {
    //     allStocksData = stockData.data.payload.result
    // }

    let totalReceiveQtyCTN = 0;
    let totalDeliveredQtyCTN = 0;

    if (stockData && stockData.data.payload) {
        totalReceiveQtyCTN = stockData.data.payload.totalReceiveQtyCTN
    }

    if (stockDataDelivered && stockDataDelivered.data.payload) {
        totalDeliveredQtyCTN = stockDataDelivered.data.payload.totalDeliveryQty
    }


    // const allScheduleData = scheduleData?.data?.payload?.result || []

    let allScheduleData = []
    if (scheduleData && scheduleData.data.payload.result.length) {
        allScheduleData = scheduleData.data.payload.result
    }


    const getFormattedDate = (data) => {
        const originalDateString = data
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`

        return formattedDateString
    }

    const getFormattedDate2 = (dt) => {
        const deliveryDate = new Date(dt)

        const year = deliveryDate.getFullYear()
        const month = String(deliveryDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(deliveryDate.getDate()).padStart(2, '0')

        const formattedDate = `${year}-${month}-${day}`

        return formattedDate
    }

    const handleScheduleSubmit = () => {
        if (!deliveryDate || !deliveryDateEnd) {
            // alert("Please select the dates");
            Swal.fire({
                title: 'Validation',
                text: 'Please select the dates',
                icon: 'warning',
            })
            return;
        }

        // const sc =
        //     Math.floor(Math.random() * 1000000000) + '-' + user.data.userId

        const postArr = schedulableOrders
            .filter((tr) => tr.deliveryQty > 0)
            .map((item) => {
                let offerScheduleAlreadyDone = false;
                const existing = allScheduleData.filter(mn => mn.skuName == item.skuName && mn.doNo == item.doNo);
                for (let i = 0; i < existing.length; i++) {
                    const element = existing[i];
                    if (element.offerSkuName && element.offerCategory && element.offerQtyPCS) {
                        offerScheduleAlreadyDone = true;
                        break;
                    }
                }
                delete item._id
                return {
                    ...item,
                    // factoryName: item.factoryName[0],
                    factoryName: "",
                    userId: user.data.userId,
                    scheduleNo: sc,
                    zoneName: item.zone,
                    regionName: item.region,
                    areaName: item.area,
                    delQtyCtn: item.dbType == "SPECIAL DISTRIBUTOR" ? 0 : item.deliveryQty,
                    delQtyPcs: item.dbType == "SPECIAL DISTRIBUTOR" ? item.deliveryQty : item.pcsCTN * item.deliveryQty,
                    weightPerPC: item.weightPerPC,
                    erpId: item.erpId,
                    orderDate: item.doDate,
                    scheduleDate: getFormattedDate(new Date()),
                    deliveryDate: getFormattedDate2(item.deliveryDate),
                    status: 9,
                    dbType: item.dbType,
                    productCategory: item.categoryName,
                    offerCategory: offerScheduleAlreadyDone ? "" : item.offerCategoryName,
                    offerSkuName: offerScheduleAlreadyDone ? "" : item.offerSkuName,
                    offerQtyPCS: offerScheduleAlreadyDone ? 0 : item.offerPc,
                    superDBId: item.superDBId,
                    dealerId: item.dealerId,
                    distributorName: item.distributorName,
                    deliveryDate: deliveryDate,
                    deliveryDateEnd: deliveryDateEnd,
                    productSubCategory: item.subCategory || "",
                    perPCPrice: item.perPCPrice


                }
            })

        console.log("updatableCombos", updatableCombos);


        const offerPostArr = updatableCombos.filter(item => item.offerPc).map(yap => {

            delete yap._id;

            return {

                // ...yap,
                // factoryName: yap.factoryName,
                userId: user.data.userId,
                scheduleNo: sc,
                zoneName: yap.zone,
                regionName: yap.region,
                areaName: yap.area,
                orderDate: yap.doDate,
                scheduleDate: getFormattedDate(new Date()),
                skuName: yap.offerName,
                superDBId: yap.superDBId,
                dealerId: yap.dealerId,
                dbType: yap.dbType,
                distributorName: yap.distributorName,
                doNo: yap.doNo,
                deliveryDate: deliveryDate,
                deliveryDateEnd: deliveryDateEnd,
                offerSkuName: yap.offerSkuName,
                offerQtyPCS: yap.offerPc,
                offerStatus: 1,
                offerNo: yap.offerNo,
                superDBId: yap.superDBId || "",
                status: 9,
                unitName: "PCS",
                perPCPrice: yap.perPCPrice,
                // delQtyCtn: yap.offerPc


            }
        })

        scheduleSubmit([...postArr, ...offerPostArr])
            .then((res) => {
                if (res.data?.success) {
                    console.log(res.data?.message)
                    // alert(res.data?.message)
                    setSelectedFactoryName('')
                    setSelectedSKUName('')
                    setSelectedDoNo('')
                    // window.location.reload()
                    setSubmittedOrders([
                        ...submittedOrders,
                        res.data ? res.data?.insertedDistributionSkus : [],
                    ])

                    setSc(Math.floor(Math.random() * 1000000000) + '-' + user.data.userId)
                    setUpdatableCombos([])

                    toast.success(res.data?.message)
                    // navigate('/distribution/productscheduling/pending')
                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    toast.error(e.response?.data?.errors)
                }
            })
    }

    const getScheduledQuantityBySKU = (skuName, doNo) => {
        const totalQty = allScheduleData
            .filter((item) => item.skuName == skuName && item.doNo == doNo)
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    const getScheduledQuantityBySKUInPieces = (skuName, doNo) => {
        const totalQty = allScheduleData
            .filter((item) => item.skuName == skuName && item.doNo == doNo)
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyPcs
            }, 0)

        return totalQty
    }

    const getScheduledQuantityBySKUOnly = (skuName, factoryName) => {
        const totalQty = allScheduleData
            .filter(
                (item) =>
                    item.skuName == skuName && item.factoryName == factoryName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    const getScheduledQuantityBySKUOnlyInPieces = (skuName, factoryName) => {
        const totalQty = allScheduleData
            .filter(
                (item) =>
                    item.skuName == skuName && item.factoryName == factoryName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyPcs
            }, 0)

        return totalQty
    }

    console.log("allScheduleData", allScheduleData);

    return (
        <>
            <Row className="g-3 mb-3">
                <Col lg={7} xl={8}>
                    <FalconComponentCard noPreview>
                        <FalconComponentCard.Header
                            title="Scheduling"
                            light={false}
                            noPreview
                        />
                        <FalconComponentCard.Body language="jsx">
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="mb-3">


                                    <Form.Group
                                        as={Col}
                                        md="12"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Distributor IDs</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={distributorOptions}
                                            placeholder="Select Distributor IDs"
                                            isMulti
                                            classNamePrefix="react-select"
                                            value={distributorValue}
                                            onChange={(value) =>
                                                setDistributorValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        controlId="exampleZip"
                                    >
                                        <div className="datePickerContainer">

                                            <DatePicker
                                                selected={deliveryDate}
                                                onChange={(date) => setDeliveryDate(date)}
                                                formatWeekDay={(day) => day.slice(0, 3)}
                                                className="form-control my-3"
                                                placeholderText="Delivery Start Date"
                                                // disabled={deliveryQty <= 0}
                                                popperPlacement="top-start"
                                                // style={{ width: '100%' }}
                                                required


                                            />
                                        </div>


                                    </Form.Group>




                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        controlId="exampleZip"
                                    >

                                        <div className="datePickerContainer">
                                            <DatePicker
                                                selected={deliveryDateEnd}
                                                onChange={(date) => setDeliveryDateEnd(date)}
                                                formatWeekDay={(day) => day.slice(0, 3)}
                                                className="form-control my-3"
                                                placeholderText="Delivery End Date"
                                                // disabled={deliveryQty <= 0}
                                                popperPlacement="top-start"
                                                // style={{ width: '100%' }}
                                                required


                                            />

                                        </div>
                                    </Form.Group>



                                </Row>

                                {/* <Button type="submit">View Undelivered</Button> */}
                            </Form>
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
                <Col lg={5} xl={4}>
                    <SharedFiles
                        title={'Available Stock'}
                        className="h-lg-60"
                        files={
                            selectedSKUname && selectedFactoryname
                                ? [
                                    {
                                        skuName: selectedSKUname,
                                        rcvQtyCTN:
                                            // allStocksData.reduce(
                                            //     (
                                            //         accumulator,
                                            //         currentValue
                                            //     ) => {
                                            //         return (
                                            //             accumulator +
                                            //             currentValue.rcvQtyCTN
                                            //         )
                                            //     },
                                            //     0
                                            // ) 

                                            totalReceiveQtyCTN
                                            -
                                            // getScheduledQuantityBySKUOnly(
                                            //     selectedSKUname,
                                            //     selectedFactoryname
                                            // ),
                                            totalDeliveredQtyCTN,

                                        rcvQtyPCS:
                                            // allStocksData.reduce(
                                            //     (
                                            //         accumulator,
                                            //         currentValue
                                            //     ) => {
                                            //         return (
                                            //             accumulator +
                                            //             currentValue.rcvQtyPCS
                                            //         )
                                            //     },
                                            //     0
                                            // ) 
                                            totalReceiveQtyCTN
                                            -
                                            // getScheduledQuantityBySKUOnlyInPieces(
                                            //     selectedSKUname,
                                            //     selectedFactoryname
                                            // ),

                                            totalDeliveredQtyCTN,

                                        selectedDbType: selectedDbType
                                    },
                                ]
                                : []
                        }
                    />
                    <div className='my-3'>
                        <SpaceWarning dealerId={dealerId} dealerName={dealerName} />
                    </div>

                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col lg={7} xl={8}>
                    {isLoadingSchedule || allOrdersData.length == 0 ? (
                        // <h1>Loading...</h1>
                        null
                    ) : (
                        <BestSellingProducts
                            orders={
                                // distributorValue
                                //     ? allOrdersData.filter(
                                //         (item) =>
                                //             item.distributorName ==
                                //             distributorValue.label
                                //     )
                                //     : []
                                allOrdersData
                            }
                            allScheduleData={allScheduleData}
                            setSelectedFactoryName={setSelectedFactoryName}
                            setSelectedSKUName={setSelectedSKUName}
                            setDealerId={setDealerId}
                            setDealerName={setDealerName}
                            setSelectedDoNo={setSelectedDoNo}
                            setSchedulableOrders={setSchedulableOrders}
                            schedulableOrders={schedulableOrders}
                            handleScheduleSubmit={handleScheduleSubmit}
                            getScheduledQuantityBySKU={
                                getScheduledQuantityBySKU
                            }
                            getScheduledQuantityBySKUInPieces={getScheduledQuantityBySKUInPieces}
                            scheduledOrders={submittedOrders}
                            setSelectedDbType={setSelectedDbType}
                            isSuper
                            comboOffers={allOrdersData.filter(item => item.superDBId && item.offerNo && item.offerName)}
                            setUpdatableCombos={setUpdatableCombos}
                            updatableCombos={updatableCombos}

                        />
                    )}
                </Col>

                <Col lg={5} xl={4}>
                    {isLoadingSchedule && allOrdersData.length == 0 ? (
                        <h1>Loading...</h1>
                    ) : (
                        <SharedFiles2
                            title={'Delivery Info'}
                            files={
                                selectedSKUname &&
                                    selectedFactoryname &&
                                    selectedDoNo
                                    ? allScheduleData.filter(
                                        (item) =>
                                            item.skuName == selectedSKUname &&
                                            // item.factoryName ==
                                            // selectedFactoryname &&
                                            item.superDBId == user.data.userId &&
                                            item.doNo == selectedDoNo
                                    )
                                    : []
                            }
                            className="h-lg-100"
                        />
                    )}
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingSuperDistributor)
