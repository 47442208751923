import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import useUserRole from "hooks/useUserRole";
import { useDropzone } from "react-dropzone";
import FalconCloseButton from "components/common/FalconCloseButton";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { authHeaderForm } from "state/ducs/auth/utils";
import { formatNumber } from 'helpers/utils'

const TransferPendingList = () => {
    const [recivedDate, setRecivedDate] = useState([]);
    const [receivedList, setReceivedList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [showUploadCSV, setShowUploadCSV] = useState(false);
    const [count, setCount] = useState(0);
    const userInfo = useSelector((state) => state.auth.user?.data);
    const userRole = useUserRole();
    const navigate = useNavigate();


    // 
    /*     useEffect(() => {
            // setIsLoading(true);
            const startIndex = (paginationPageCount - 1) * pageDataCount + 1;
    
            const fetchData = async () => {
                let url;
                if (userInfo?.userRole === "Admin") {
                    url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                } if (userInfo?.userRole === "Inventory") {
                    url = searchText !== "" ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                }
    
                try {
                    const response = await axios.get(
                        url,
                        { headers: authHeader() }
                    );
    
                    if (response.data.success || response.status === 200) {
                        const totalPages = response?.data?.payload?.pagination?.totalPages;
                        const result = response?.data?.payload?.result?.map((element, index) => ({
                            ...element,
                            index: startIndex + index
                        }));
    
                        setTotalPages(totalPages)
                        setReceivedList(result);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };
    
            fetchData();
        }, [count, paginationPageCount, pageDataCount, searchText]); */


    // Load Received List Data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductionRcvListTransOutgoingPending/${userInfo?.factoryName}`;
                const response = await axios.get(url, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const responseData = response.data.payload.prdInfo;
                    const result = responseData.map((element, index) => ({
                        ...element,
                        index: index + 1
                    }));

                    setReceivedList(result);
                }

            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }
        fetchData();
    }, [])

    // DELETE DISTRIBUTOR

    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };



    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "SL",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "challanNo",
            Header: "Challan No",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { challanNo } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1" style={{ cursor: "pointer" }} onClick={() => navigate(`/inventory/transfer/details/${challanNo}`)}>{challanNo}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "batchNo",
            Header: "Batch No",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { batchNo } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{batchNo}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "transDate",
            Header: "Transaction Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { transDate, fromFactory, transStatus } = rowData.row.original;
                const date = new Date(transDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            {/* <Link to={`/inventory/transfer/detailsPanel/${fromFactory}/${transDate}/${1}/Out`}> */}
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                            {/* </Link> */}
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "transQtyCTN",
            Header: "Trans Qty CTN",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { transQtyCTN } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{transQtyCTN}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "transQtyPCS",
            Header: "Trans Qty PCS",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { transQtyPCS } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(transQtyPCS)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "transQtyMt",
            Header: "Weight(MT)",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { transQtyMt } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(transQtyMt)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "factoryName",
            Header: "Factory Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { factoryName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{factoryName}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "expireDate",
            Header: "Expire Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { expireDate } = rowData.row.original;
                const date = new Date(expireDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { challanNo } = rowData.row.original
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle
                            id="dropdown-autoclose-true"
                            className=" bg-none"
                        >
                            ...
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="">
                                <Button
                                    variant="light"
                                    size="sm"
                                    // onClick={() => navigate("/distribution/productdelivery/details/" + challanNo + "/" + scheduleNo)}
                                    onClick={() => navigate(`/inventory/transfer/details/${challanNo}`)}
                                >
                                    View Details
                                </Button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
        },
    ];

    /*******************
    Columns End.
    *******************/
    const formatData = () => {
        const dataFormat = receivedList.map((item, i) => {
            const formatDate = (date) => {
                const parsedDate = new Date(date)
                return !isNaN(parsedDate.getTime())
                    ? parsedDate.toISOString().split('T')[0]
                    : 'Invalid Date'
            }
            return {
                SL: i + 1,
                'Batch No': item.batchNo,
                'Trans Date': formatDate(item.transDate),
                'Trans Qty Ctn': item.transQtyCTN,
                'Trans Qty Pcs': item.transQtyPCS,
                'Qty Mt': item.transQtyMt,
                'Factory Name': item.factoryName,
                'Expire Date': formatDate(item.expireDate),
            }
        })
        return dataFormat
    }

    return (
        <>
            <PageHeader
                title="Transfer Pending List (Outgoing)"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={receivedList}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            isSearch={true}
                            setSearchText={setSearchText}
                            table
                            excelFileName="Transfer Pending List"
                            excelData={formatData()}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

        </>
    );

};

export default TransferPendingList;
