import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import axios from 'axios'
import IconButton from 'components/common/IconButton'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleBarReact from 'simplebar-react'
import { authHeader } from 'utils'
import { usePDF } from 'react-to-pdf'
import StatusCell from 'components/common/StatusCell'
import useUserRole from 'hooks/useUserRole'
import { formatNumber } from 'helpers/utils'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import LoadingIcon from 'helpers/LoadingIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CollapseOrder = ({ doNo }) => {
    const [isLoading, setIsLoading] = React.useState({
        payment: false,
        orders: false,
        user: false,
        deposit: false,
        credit: false,
    })
    const [totalAmount, setTotalAmount] = useState(0)
    const [paymentInfo, setPaymentInfo] = React.useState({})
    const [depositDataByDoNo, setDepositDataByDoNo] = useState([])
    const [ordersInfo, setOrdersInfo] = React.useState([])
    const [dealerInfo, setDealerInfo] = React.useState({})
    const [totalCredit, setTotalCredit] = React.useState(0)
    // const { doNo } = useParams()
    const userInfo = useSelector((state) => state?.auth?.user?.data)
    const userRole = useUserRole()
    const navigate = useNavigate()

    const organizeOrdersByDoNo = (orders) => {
        const ordersByDoNo = {}

        orders
            ?.filter((order) => order?.status !== 0)
            ?.forEach((order) => {
                const {
                    doNo,
                    dbPoint,
                    region,
                    zone,
                    area,
                    dealerId,
                    distributorName,
                    orderQtyCtn,
                    totalOrderQtyPCS,
                    offerPc,
                    totalPrice,
                    discountBDT,
                    status,
                    doDate,
                } = order
                if (!ordersByDoNo[doNo]) {
                    ordersByDoNo[doNo] = {
                        doNo: doNo,
                        doDate: doDate,
                        dbPoint: dbPoint,
                        region: region,
                        zone: zone,
                        area: area,
                        dealerId: dealerId,
                        distributorName: distributorName,
                        status: status,
                        totalOrderQtyCtn: 0, // Initialize total order quantity for each doNo
                        totalOrderQtyPCS: 0,
                        totalDiscountBDT: 0,
                        totalOfferPcs: 0,
                        totalPrice: 0,
                        totalOfferPrice: 0,
                        data: [],
                    }
                }
                ordersByDoNo[doNo].totalOrderQtyCtn += orderQtyCtn // Add orderQtyCtn to total for the respective doNo
                ordersByDoNo[doNo].totalOrderQtyPCS +=
                    parseFloat(totalOrderQtyPCS)
                ordersByDoNo[doNo].totalDiscountBDT += parseFloat(discountBDT)
                ordersByDoNo[doNo].totalPrice += parseFloat(order.totalPrice)
                ordersByDoNo[doNo].totalOfferPcs += parseFloat(order.offerPc)
                ordersByDoNo[doNo].totalOfferPrice += parseFloat(
                    order.offerSkuPrice * order.offerPc
                )
                ordersByDoNo[doNo].data.push(order)
            })

        const result = Object.values(ordersByDoNo)

        return result
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading({ ...isLoading, payment: true })
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/payInfo/${doNo}`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.success || response.status === 200) {
                    const data = response?.data?.payload?.payDoData[0]
                    setPaymentInfo(data)
                    if (data?.dealerId) {
                        await fetchUserData(data?.dealerId)
                        await fetchDealerCreditData(data?.dealerId)
                    }
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading({ ...isLoading, payment: false })
            }
        }

        fetchData()
    }, [doNo])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading({ ...isLoading, orders: true })
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/singleOrder/${doNo}`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.success || response.status === 200) {
                    const organizedOrders = organizeOrdersByDoNo(
                        response?.data?.payload?.DoData
                    )
                    setOrdersInfo(organizedOrders[0])
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading({ ...isLoading, orders: false })
            }
        }

        fetchData()
    }, [doNo])

    const fetchUserData = async (dealerId) => {
        try {
            const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dealer/${dealerId}`
            const response = await axios.get(url, { headers: authHeader() })

            if (response.success || response.status === 200) {
                const data = response?.data?.payload?.distributorData
                setDealerInfo(data)
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        }
    }

    /* 
    Load Deposit Data Base on User ID
    Load Credit Data Base on User ID
    */
    const fetchDealerCreditData = async (userId) => {
        const creditUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/getAllOrdersWithBankByDB/${userId}`
        try {
            const [creditResponse] = await Promise.all([
                axios.get(creditUrl, { headers: authHeader() }),
            ])

            if (creditResponse?.data?.success) {
                const data = creditResponse?.data?.payload?.result
                let totalCredit = 0
                data?.forEach((item) => {
                    if (item?.status === 2 && item.crBDT) {
                        totalCredit += item.crBDT
                    }
                })
                setTotalCredit(parseFloat(totalCredit))
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading((prevState) => ({
                ...prevState,
                deposit: false,
                credit: false,
            }))
        }
    }

    const handleForwardToSIO = async (order, userInfo) => {
        const orderItems =
            order?.data?.map((or) => {
                return {
                    _id: or._id,
                    status: 4,
                    checkedId: userInfo?.userId,
                    cancelNote: '', // Placeholder for the reason, you can modify this as needed
                }
            }) ?? []

        try {
            const { value: cancelNote } = await Swal.fire({
                title: 'Reason For Back Forward to SIO',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Forward',
                showLoaderOnConfirm: true,
                preConfirm: async (cancelNote) => {
                    try {
                        const updatedOrderItems = orderItems.map((item) => ({
                            ...item,
                            cancelNote,
                        }))
                        const response = await axios.put(
                            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                            updatedOrderItems,
                            { headers: authHeader() }
                        )

                        if (response.data.success) {
                            return true
                        } else {
                            throw new Error(response.data.message)
                        }
                    } catch (error) {
                        throw new Error(`Request failed: ${error}`)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })

            if (cancelNote) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'successfully Forward By SIO.',
                })
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.message,
            })
        }
    }

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositByDoNo/${doNo}`
                const response = await axios.get(url, { headers: authHeader() })
                if (response.data.success || response.status === 200) {
                    const data = response.data.payload.depositDoData
                    const totalAmount = data.reduce(
                        (acc, item) => acc + item.drBDT,
                        0
                    )
                    setTotalAmount(totalAmount)
                    setDepositDataByDoNo(data)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [doNo])

    // VIEW IMAGE
    const viewImage = async (imageId) => {
        try {
            const response = await axios.get(
                `https://khoz.net:3015/api/v1/image/${imageId}`,
                { headers: authHeader() }
            )
            if (response.data.success && response.data.payload) {
                const data = response.data.payload
                if (!data.imageData) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No image found!',
                    })
                    return
                }

                const base64Image = response.data.payload.imageData.image

                // Check if the base64 string contains the data URL prefix and remove it if present
                const base64Prefix = 'data:image/jpeg;base64,'
                let base64String
                if (base64Image.startsWith(base64Prefix)) {
                    base64String = base64Image.substring(base64Prefix.length)
                } else {
                    base64String = base64Image
                }

                // Convert base64 to Blob
                try {
                    const byteCharacters = atob(base64String)
                    const byteNumbers = new Array(byteCharacters.length)
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i)
                    }
                    const byteArray = new Uint8Array(byteNumbers)
                    const blob = new Blob([byteArray], { type: 'image/jpeg' })

                    // Create a URL for the Blob and open it in a new tab
                    const imageUrl = URL.createObjectURL(blob)
                    window.open(imageUrl, '_blank')
                } catch (e) {
                    console.error('Error decoding base64 string:', e)
                }
            } else {
                console.error('Failed to fetch image:', response.data.message)
            }
        } catch (error) {
            console.error('Error fetching the image:', error)
        }
    }

    if (isLoading.payment || isLoading.orders) return <LoadingIcon />
    return (
        <div style={{ maxWidth: '100%' }}>
            <Card className="p-0" style={{ maxWidth: '100%' }}>
                <Card.Body>
                    <div className="fs--1">
                        <SimpleBarReact>
                            <Table striped className="border-bottom">
                                <thead className="light">
                                    <tr className="bg-primary text-white dark__bg-1000">
                                        <th className="border-0 text-center">
                                            Product
                                        </th>
                                        {ordersInfo?.data?.[0]?.dbType !==
                                            'SPECIAL DISTRIBUTOR' && (
                                            <th className="border-0 text-center">
                                                Qty CTN
                                            </th>
                                        )}
                                        {ordersInfo?.data?.[0]?.dbType !==
                                            'SPECIAL DISTRIBUTOR' && (
                                            <th className="border-0 text-center">
                                                CTN Price
                                            </th>
                                        )}
                                        <th className="border-0 text-center">
                                            Qty PCS
                                        </th>
                                        <th className="border-0 text-center">
                                            PC Price
                                        </th>
                                        <th className="border-0 text-center">
                                            Discount
                                        </th>
                                        <th className="border-0 text-center">
                                            Total Price
                                        </th>
                                        <th className="border-0 text-center">
                                            Offer Sku
                                        </th>
                                        <th className="border-0 text-center">
                                            Offer PCS
                                        </th>
                                        <th className="border-0 text-center">
                                            Note
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ordersInfo?.data?.map((order, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="align-middle text-center">
                                                    <h6 className="mb-0 text-nowrap">
                                                        {order?.categoryName ||
                                                            order?.offerName}
                                                    </h6>
                                                    <p className="mb-0">
                                                        {order?.skuName}
                                                    </p>
                                                </td>
                                                {order?.dbType !==
                                                    'SPECIAL DISTRIBUTOR' && (
                                                    <td className="align-middle text-center">
                                                        {formatNumber(
                                                            order?.orderQtyCtn
                                                        ) || '-'}
                                                    </td>
                                                )}
                                                {order?.dbType !==
                                                    'SPECIAL DISTRIBUTOR' && (
                                                    <td className="align-middle text-center">
                                                        {formatNumber(
                                                            order?.dpCTN
                                                        ) || '-'}
                                                    </td>
                                                )}

                                                <td className="align-middle text-center">
                                                    {formatNumber(
                                                        order?.totalOrderQtyPCS
                                                    ) || '-'}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {formatNumber(
                                                        order?.dpCTN /
                                                            order?.pcsCTN
                                                    ) || '-'}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {formatNumber(
                                                        order?.discountBDT
                                                    ) || '-'}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {formatNumber(
                                                        order?.totalPrice
                                                    ) || '-'}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {order?.offerSkuName
                                                        ? order?.offerSkuName
                                                        : '-'}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {order?.offerPc
                                                        ? formatNumber(
                                                              order?.offerPc
                                                          )
                                                        : '-'}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {order?.cancelNote
                                                        ? order?.cancelNote
                                                        : '-'}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr
                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: 'black',
                                            backgroundColor: '#98c1d9',
                                        }}
                                    >
                                        <td className="align-middle text-center">
                                            <h5 className="mb-0 text-nowrap ">
                                                Total
                                            </h5>
                                        </td>
                                        {ordersInfo?.data?.[0]?.dbType !==
                                            'SPECIAL DISTRIBUTOR' && (
                                            <td className="align-middle text-center">
                                                {formatNumber(
                                                    ordersInfo?.totalOrderQtyCtn
                                                )}
                                            </td>
                                        )}

                                        {ordersInfo?.data?.[0]?.dbType !==
                                            'SPECIAL DISTRIBUTOR' && (
                                            <td className="align-middle text-center"></td>
                                        )}

                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                ordersInfo?.totalOrderQtyPCS
                                            )}
                                        </td>
                                        <td className="align-middle text-center"></td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                ordersInfo?.totalDiscountBDT
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                ordersInfo?.totalPrice
                                            )}
                                        </td>
                                        <td className="align-middle text-center"></td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                ordersInfo?.totalOfferPcs
                                            )}
                                        </td>
                                        <td className="align-middle text-center"></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </SimpleBarReact>
                    </div>

                    <Row className="d-flex justify-content-between align-items-center">
                        <Col xs="auto">
                            <h6 className="text-900">
                                Available credit limit:{' '}
                                <span className="">
                                    {isLoading.user ||
                                    isLoading.credit ||
                                    isLoading.orders ||
                                    isLoading.payment
                                        ? 'Loading...'
                                        : formatNumber(
                                              parseFloat(
                                                  dealerInfo?.crBDTLimit
                                              ) - parseFloat(totalCredit)
                                          )}
                                </span>
                            </h6>
                        </Col>
                        <Col xs="auto">
                            <Table
                                borderless
                                size="sm"
                                className="fs--1 text-end"
                            >
                                <tbody>
                                    <tr>
                                        <th className="text-900">
                                            Total Price:
                                        </th>
                                        {
                                            <td className="fw-semi-bold">
                                                {formatNumber(
                                                    ordersInfo?.totalPrice
                                                )}
                                            </td>
                                        }
                                    </tr>
                                    {ordersInfo?.data?.[0]?.offerAccepted ==
                                        'price' && (
                                        <tr>
                                            <th className="text-900">
                                                Total Offer Price:
                                            </th>
                                            {
                                                <td className="fw-semi-bold">
                                                    {formatNumber(
                                                        ordersInfo?.totalOfferPrice
                                                    )}
                                                </td>
                                            }
                                        </tr>
                                    )}

                                    {paymentInfo?.status === 2 && (
                                        <>
                                            <tr className="border-top">
                                                <th className="text-900">
                                                    Total Pay:
                                                </th>
                                                <td className="fw-semi-bold">
                                                    {formatNumber(
                                                        paymentInfo?.drBDT
                                                    )}
                                                </td>
                                            </tr>
                                            <tr className="border-top border-top-2 fw-bolder text-900">
                                                <th>Amount Due:</th>
                                                <td>
                                                    {formatNumber(
                                                        paymentInfo?.crBDT
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <div className="bg-white" style={{ minWidth: '100%' }}>
                        <div className="table-responsive scrollbar">
                            {depositDataByDoNo?.length > 0 ? (
                                <table className="table table-bordered table-striped fs--1 mb-0">
                                    <thead>
                                        <tr
                                            style={{
                                                fontSize: 'medium',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#354151',
                                            }}
                                        >
                                            <th className="align-middle white-space-nowrap text-center ">
                                                No.
                                            </th>
                                            <th className="align-middle white-space-nowrap text-start ">
                                                Bank Name
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Branch Name
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Account No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Amount
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Tran. Id/Ref. No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Payment Date
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Depositor Mobile No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Status
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Note
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        className="list"
                                        id="table-purchase-body"
                                        style={{ maxWidth: '100%' }}
                                    >
                                        {depositDataByDoNo?.map(
                                            (data, index) => {
                                                return (
                                                    <>
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                fontSize:
                                                                    'medium',
                                                                fontWeight:
                                                                    '500',
                                                                fontFamily:
                                                                    'Poppins',
                                                                color: '#354151',
                                                            }}
                                                        >
                                                            <td className="align-middle text-center">
                                                                {index + 1}
                                                            </td>
                                                            <td
                                                                className="align-middle text-center"
                                                                style={{
                                                                    minWidth:
                                                                        '200px',
                                                                }}
                                                            >
                                                                {data?.bankNames
                                                                    ? data?.bankNames
                                                                    : '-'}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {data?.depositorsBranchName
                                                                    ? data?.depositorsBranchName
                                                                    : '-'}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    data?.bankAccountNo
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {formatNumber(
                                                                    data?.drBDT
                                                                )}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {data?.trId
                                                                    ? data?.trId
                                                                    : '-'}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {data?.paymentDate
                                                                    ? new Date(
                                                                          data?.paymentDate
                                                                      ).toLocaleDateString()
                                                                    : '-'}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {data?.depositorPhone
                                                                    ? data?.depositorPhone
                                                                    : '-'}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {data?.status ===
                                                                    0 && (
                                                                    <h6
                                                                        style={{
                                                                            backgroundColor:
                                                                                'orange',
                                                                            color: 'white',
                                                                            display:
                                                                                'flex',
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            padding:
                                                                                '5px',
                                                                            borderRadius:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fas fa-hourglass-half"
                                                                            style={{
                                                                                marginRight:
                                                                                    '5px',
                                                                            }}
                                                                        ></i>
                                                                        Pending..
                                                                    </h6>
                                                                )}
                                                                {data?.status ===
                                                                    1 && (
                                                                    <h6
                                                                        style={{
                                                                            backgroundColor:
                                                                                'blue',
                                                                            color: 'white',
                                                                            display:
                                                                                'flex',
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            padding:
                                                                                '5px',
                                                                            borderRadius:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fas fa-thumbs-up"
                                                                            style={{
                                                                                marginRight:
                                                                                    '5px',
                                                                            }}
                                                                        ></i>
                                                                        Approved
                                                                        By
                                                                        Account
                                                                    </h6>
                                                                )}
                                                                {data?.status ===
                                                                    2 && (
                                                                    <h6
                                                                        style={{
                                                                            backgroundColor:
                                                                                'green',
                                                                            color: 'white',
                                                                            display:
                                                                                'flex',
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            padding:
                                                                                '5px',
                                                                            borderRadius:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fas fa-check-circle"
                                                                            style={{
                                                                                marginRight:
                                                                                    '5px',
                                                                            }}
                                                                        ></i>
                                                                        Approved
                                                                    </h6>
                                                                )}
                                                                {data?.status ===
                                                                    3 && (
                                                                    <h6
                                                                        style={{
                                                                            backgroundColor:
                                                                                'red',
                                                                            color: 'white',
                                                                            display:
                                                                                'flex',
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            padding:
                                                                                '5px',
                                                                            borderRadius:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fas fa-times-circle"
                                                                            style={{
                                                                                marginRight:
                                                                                    '5px',
                                                                            }}
                                                                        ></i>
                                                                        Rejected
                                                                    </h6>
                                                                )}
                                                            </td>

                                                            <td
                                                                className="align-middle text-center"
                                                                style={{
                                                                    minWidth:
                                                                        '200px',
                                                                }}
                                                            >
                                                                {
                                                                    data?.paymentNote
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <Button
                                                                    icon="fas fa-eye"
                                                                    onClick={() =>
                                                                        viewImage(
                                                                            data?.trId
                                                                        )
                                                                    }
                                                                >
                                                                    View{' '}
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        )}

                                        <tr>
                                            <td></td>
                                            <td
                                                colSpan={3}
                                                className="align-middle text-center"
                                                style={{
                                                    fontSize: 'medium',
                                                    fontWeight: '500',
                                                    fontFamily: 'Poppins',
                                                    color: '#354151',
                                                }}
                                            >
                                                Total
                                            </td>
                                            <td
                                                style={{
                                                    fontSize: 'medium',
                                                    fontWeight: '500',
                                                    fontFamily: 'Poppins',
                                                    color: '#354151',
                                                }}
                                            >
                                                {formatNumber(totalAmount || 0)}
                                            </td>
                                            <td colSpan={6}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default CollapseOrder
