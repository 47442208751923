import logo from 'assets/img/logos/PustiLogo/pusti_logo.png';
import axios from 'axios';
import IconButton from 'components/common/IconButton';
import handleApiResponseErrors from 'helpers/handleApiResponse';
import jsPDF from 'jspdf';
import React, { useEffect } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import { authHeader } from 'utils';
import { usePDF } from 'react-to-pdf';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { formatNumber } from 'helpers/utils';
import StatusCell from 'components/common/StatusCell';



const SpecialDistributorOrdersViewDetails = () => {
    const [paymentInfo, setPaymentInfo] = React.useState({});
    const [ordersInfo, setOrdersInfo] = React.useState([]);
    const { doNo } = useParams();
    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });



    //
    const organizeOrdersByDoNo = (orders) => {
        const ordersByDoNo = {};

        orders?.filter(order => order?.status !== 0)?.forEach((order) => {
            const { doNo, dbPoint, region, zone, area, dealerId, distributorName, orderQtyCtn, totalOrderQtyPCS, totalPrice, discountBDT, status } = order;
            if (!ordersByDoNo[doNo]) {
                ordersByDoNo[doNo] = {
                    doNo: doNo,
                    dbPoint: dbPoint,
                    region: region,
                    zone: zone,
                    area: area,
                    dealerId: dealerId,
                    distributorName: distributorName,
                    status: status,
                    totalOrderQtyCtn: 0, // Initialize total order quantity for each doNo
                    totalOrderQtyPCS: 0,
                    totalDiscountBDT: 0,
                    totalPrice: 0,
                    totalOfferPcs: 0,
                    totalOfferPrice: 0,



                    data: [],
                };
            }
            ordersByDoNo[doNo].totalOrderQtyCtn += orderQtyCtn; // Add orderQtyCtn to total for the respective doNo
            ordersByDoNo[doNo].totalOrderQtyPCS += parseFloat(totalOrderQtyPCS);
            ordersByDoNo[doNo].totalDiscountBDT += parseFloat(discountBDT);
            ordersByDoNo[doNo].totalPrice += parseFloat(order.totalPrice);
            ordersByDoNo[doNo].totalOfferPcs += parseFloat(order.offerPc);
            ordersByDoNo[doNo].totalOfferPrice += parseFloat(order.offerSkuPrice * order.offerPc);
            ordersByDoNo[doNo].data.push(order);
        });

        const result = Object.values(ordersByDoNo);

        return result;
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/payInfo/${doNo}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.success || response.status === 200) {
                    setPaymentInfo(response?.data?.payload?.payDoData[0]);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            }
        };

        fetchData();
    }, [doNo]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/singleOrder/${doNo}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.success || response.status === 200) {
                    const organizedOrders = organizeOrdersByDoNo(response?.data?.payload?.DoData);
                    setOrdersInfo(organizedOrders[0]);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            }
        };

        fetchData();
    }, [doNo]);



    console.log('ordersInfo', ordersInfo);

    return (
        <div >
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">Order No {ordersInfo?.doNo}</h5>
                        </Col>
                        <Col xs="auto">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="arrow-down"
                                className="me-1 mb-2 mb-sm-0"
                                iconClassName="me-1"
                                onClick={() => toPDF()}
                            >
                                Download (.pdf)
                            </IconButton>

                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="mb-3" ref={targetRef}>
                <Card.Body>
                    {<Row className="align-items-center text-center mb-3">
                        <Col sm={6} className="text-sm-start">
                            <img src={logo} alt="invoice" width={150} />
                        </Col>
                        <Col className="text-sm-end mt-3 mt-sm-0">
                            <h5 className="">{`${ordersInfo?.distributorName}`}</h5>
                            <h5>Order No: {ordersInfo?.doNo}</h5>
                            <p className="fs--1 mb-0">
                                {ordersInfo?.zone},{ordersInfo?.region},{ordersInfo?.area} <br /> Order Quantity CTN: {ordersInfo?.totalOrderQtyCtn} <br /> Order Quantity PCS: {ordersInfo?.totalOrderQtyPCS}
                            </p>
                        </Col>
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>}
                    {/* <Row className="align-items-center">
                        <Col>
                            <h6 className="text-500">Invoice to</h6>
                            <h5>Antonio Banderas</h5>
                            <p className="fs--1">
                                1954 Bloor Street West
                                <br />
                                Torronto ON, M6P 3K9
                                <br />
                                Canada
                            </p>
                            <p className="fs--1">
                                <a href="mailto:example@gmail.com">example@gmail.com</a>
                                <br />
                                <a href="tel:444466667777">+4444-6666-7777</a>
                            </p>
                        </Col>
                        <Col sm="auto" className="ms-auto">
                            <div className="table-responsive">
                                <Table borderless size="sm" className="fs--1">
                                    <tbody>
                                        <tr>
                                            <th className="text-sm-end">Invoice No:</th>
                                            <td>14</td>
                                        </tr>
                                        <tr>
                                            <th className="text-sm-end">Order Number:</th>
                                            <td>AD20294</td>
                                        </tr>
                                        <tr>
                                            <th className="text-sm-end">Invoice Date:</th>
                                            <td>2018-09-25</td>
                                        </tr>
                                        <tr>
                                            <th className="text-sm-end">Payment Due:</th>
                                            <td>Upon receipt</td>
                                        </tr>
                                        <tr className="alert alert-success fw-bold">
                                            <th className="text-sm-end">Amount Due:</th>
                                            <td>$19688.40</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row> */}

                    <div className="mt-4 fs--1">
                        <SimpleBarReact>
                            <Table striped className="border-bottom">
                                <thead className="light">
                                    <tr className="bg-primary text-white dark__bg-1000">
                                        <th className="border-0 text-center">Product</th>
                                        {((ordersInfo?.data?.[0]?.dbType !== "SPECIAL DISTRIBUTOR")) && (
                                            <th className="border-0 text-center">Order Quantity CTN</th>
                                        )}
                                        {((ordersInfo?.data?.[0]?.dbType !== "SPECIAL DISTRIBUTOR")) && (
                                            <th className="border-0 text-center">CTN Price</th>
                                        )}
                                        <th className="border-0 text-center">Order Quantity PCS</th>
                                        <th className="border-0 text-center">Per PC Price</th>
                                        <th className="border-0 text-center">Discount BDT</th>
                                        <th className="border-0 text-center">Total Price</th>
                                        <th className="border-0 text-center">Offer Sku Name</th>
                                        <th className="border-0 text-center">Offer PCS</th>
                                        <th className="border-0 text-center">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        ordersInfo?.data?.map((order, index) => {
                                            return (
                                                <tr key={index}>
                                                    {/* <td className="align-middle text-center">
                                                        <h6 className="mb-0 text-nowrap">
                                                            {order?.categoryName}
                                                        </h6>
                                                        <p className="mb-0">{order?.skuName}</p>
                                                    </td> */}
                                                    <td className="align-middle text-center">
                                                        <h6 className="mb-0 text-nowrap">
                                                            {order?.categoryName || order?.offerName}
                                                        </h6>
                                                        <p className="mb-0">{order?.skuName}</p>
                                                    </td>
                                                    {(order?.dbType !== "SPECIAL DISTRIBUTOR") &&
                                                        <td className="align-middle text-center">{formatNumber(order?.orderQtyCtn) || "-"}</td>
                                                    }
                                                    {(order?.dbType !== "SPECIAL DISTRIBUTOR") &&
                                                        <td className="align-middle text-center">{formatNumber(order?.dpCTN) || "-"}</td>
                                                    }

                                                    <td className="align-middle text-center">{formatNumber(order?.totalOrderQtyPCS) || "-"}</td>
                                                    <td className="align-middle text-center">{formatNumber(order?.dpCTN) || "-"}</td>
                                                    <td className="align-middle text-center">{formatNumber(order?.discountBDT) || "-"}</td>
                                                    <td className="align-middle text-center">{formatNumber(order?.totalPrice) || "-"}</td>
                                                    <td className="align-middle text-center">{order?.offerSkuName ? order?.offerSkuName : "-"}</td>
                                                    <td className="align-middle text-center">{order?.offerPc ? formatNumber(order?.offerPc) : "-"}</td>
                                                    <td className="align-middle text-center">
                                                        <StatusCell status={order?.status} />

                                                    </td>

                                                </tr>
                                            );
                                        })
                                    }
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "black", backgroundColor: "#98c1d9" }}>
                                        <td className="align-middle text-center">
                                            <h5 className="mb-0 text-nowrap ">
                                                Total
                                            </h5>
                                        </td>
                                        {(ordersInfo?.data?.[0]?.dbType !== "SPECIAL DISTRIBUTOR") && (
                                            <td className="align-middle text-center">{formatNumber(ordersInfo?.totalOrderQtyCtn)}</td>
                                        )}
                                        {/* <td className="align-middle text-center"></td> */}

                                        <td className="align-middle text-center">{formatNumber(ordersInfo?.totalOrderQtyPCS)}</td>
                                        <td className="align-middle text-center"></td>
                                        <td className="align-middle text-center">{formatNumber(ordersInfo?.totalDiscountBDT) || null}</td>
                                        <td className="align-middle text-center">{formatNumber(ordersInfo?.totalPrice)}</td>
                                        <td className="align-middle text-center"></td>
                                        <td className="align-middle text-center">{formatNumber(ordersInfo?.totalOfferPcs)}</td>
                                        <td className="align-middle text-center"></td>

                                    </tr>
                                </tbody>
                            </Table>
                        </SimpleBarReact>
                    </div>



                    <Row className="justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                    <tr>
                                        <th className="text-900">Total Price:</th>
                                        {<td className="fw-semi-bold">{formatNumber(ordersInfo?.totalPrice)}</td>}
                                    </tr>
                                    {ordersInfo?.data?.[0]?.offerAccepted == "price" && (
                                        <tr>
                                            <th className="text-900">Total Offer Price:</th>
                                            {<td className="fw-semi-bold">{formatNumber(ordersInfo?.totalOfferPrice)}</td>}
                                        </tr>
                                    )}
                                    <tr className="border-top">
                                        <th className="text-900">Total Pay:</th>
                                        <td className="fw-semi-bold">{formatNumber(paymentInfo?.drBDT)}</td>
                                    </tr>
                                    <tr className="border-top border-top-2 fw-bolder text-900">
                                        <th>Amount Due:</th>
                                        <td>{formatNumber(paymentInfo?.crBDT)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="bg-light">

                </Card.Footer>
            </Card>
        </div>
    );
};

export default SpecialDistributorOrdersViewDetails;
