import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import BasicECharts from 'components/common/BasicEChart'
import Flex from 'components/common/Flex'
import SoftBadge from 'components/common/SoftBadge'
import { set } from 'date-fns'
import { BarChart } from 'echarts/charts'
import {
    GridComponent,
    TitleComponent,
    TooltipComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { getColor } from 'helpers/utils'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import image from 'assets/img/icons/spot-illustrations/corner-1.png'
import Background from 'components/common/Background'
import { Link } from 'react-router-dom'
import LoadingIcon from 'helpers/LoadingIcon'
import axios from 'axios'
import { authHeader } from 'utils'
import { formatNumber } from 'helpers/utils'
const MonthlyConfirmTk = ({ data, selectedDate }) => {
    // Formtting the date   
    const month = selectedDate?.toLocaleString('default', { month: 'long' });
    const year = selectedDate?.getFullYear();

    const formattedDate = `${month} ${year}`;
    const navigate = useNavigate()

    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="warning" pill className="">
                    Monthly
                </SoftBadge>
                <h5 style={{ fontSize: '1rem' }}>Confirm DO</h5>
                <div className="display-4 fs-1 mb-2 fw-normal font-sans-serif">
                    Tk.{formatNumber(data?.confirmDoAmount) || 0}
                </div>
                <span className="fw-semi-bold fs--1 text-nowrap">{formattedDate}</span>
            </Card.Body>
        </Card>
    )
}

export default MonthlyConfirmTk
