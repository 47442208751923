import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Table } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader';
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { IoIosCheckboxOutline } from "react-icons/io";
import { BiSolidSelectMultiple } from "react-icons/bi";


const UpdateOrdersStep1 = ({ handleNext, distributorOrderNumber, checkedProducts, setCheckedProducts }) => {
    const [searchText, setSearchText] = useState("");
    const [productsData, setProductsData] = useState([]);
    const [paymentInfo, setPaymentInfo] = useState(null);
    const [userInfo, setUserInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { doNo } = useParams();


    // Organize products by category
    const organizeProductsByCategory = (products) => {
        const categories = {};

        products.forEach((product) => {
            const categoryId = product.categoryName;
            if (!categories[categoryId]) {
                categories[categoryId] = {
                    categoryId: categoryId,
                    categoryName: product.categoryName,
                    data: [],
                };
            }
            categories[categoryId].data.push(product);
        });

        const result = Object.keys(categories).map((categoryId) => categories[categoryId]);
        return result;
    };


    // Fetch products
    useEffect(() => {
        const fetchData = async () => {
            // setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?search=${searchText}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.status === 200) {
                    const productsByCategory = organizeProductsByCategory(response.data.payload.products);
                    setProductsData(productsByCategory);

                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                // setIsLoading(false);
            }
        };

        fetchData();
    }, [searchText]);



    // Handle checkbox change
    const handleCheckboxChange = (product) => {
        const perCartoonPrice = (userInfo.userRole == "SUPER DISTRIBUTOR" || userInfo.userRole == "GENERAL DISTRIBUTOR") ?
            product.distributorPrice : userInfo.userRole == "COMMISSION DISTRIBUTOR" ? product.commPrice : userInfo.userRole == "WHOLESALE DISTRIBUTOR" ? product.wholesellPrice : userInfo.userRole == "SPOT DISTRIBUTOR" ? product.spotPrice : ""
        if (isChecked(product)) {
            setCheckedProducts(checkedProducts.filter(p => p._id !== product._id).map(item => ({ ...item, orderQtyCtn: 0, totalOrderQtyPCS: item.pcsCTN, perCartoonPrice: perCartoonPrice, totalPriceCtn: 0, totalPrice: 0 })));
        } else {
            setCheckedProducts([...checkedProducts, { ...product, orderQtyCtn: 0, totalOrderQtyPCS: product.pcsCTN, perCartoonPrice: perCartoonPrice, totalPriceCtn: 0, totalPrice: 0 }]);
        }
    };


    // Check if a product is checked
    const isChecked = (product) => {
        return checkedProducts.some(p => p?.skuName === product?.skuName);
    };

    // Payment Info
    useEffect(() => {
        // setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/payInfo/${doNo}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const paymentInfo = response?.data?.payload?.payDoData[0];
                    setPaymentInfo(paymentInfo);
                    const userInfo = {
                        userId: paymentInfo?.dealerId,
                        name: paymentInfo?.distributorName || paymentInfo?.dealerName,
                        phoneNumber: paymentInfo?.phone,
                        address: paymentInfo?.zone + ", " + paymentInfo?.region + ", " + paymentInfo?.area
                    }
                    setUserInfo(userInfo);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    if (isLoading) return <LoadingIcon />

    // Handle form submission
    const handleSubmit = async () => {
        const isNewProduct = checkedProducts?.filter((product) => product?.doNo !== distributorOrderNumber);
        if (isNewProduct?.length === 0) {
            handleNext();
            return;
        }
        else {
            try {
                setIsLoading(true);
                const orderItems = isNewProduct?.map((product) => ({
                    doNo: distributorOrderNumber,
                    doDate: new Date(),
                    zone: paymentInfo?.zone,
                    region: paymentInfo?.region,
                    area: paymentInfo?.area,
                    dbPoint: paymentInfo?.dbPoint,
                    dealerId: paymentInfo?.dealerId,
                    distributorName: paymentInfo?.dealerName,
                    dbType: paymentInfo?.dbType,
                    companyName: paymentInfo?.companyName,
                    factoryName: product.factoryName[0],
                    categoryName: product.categoryName,
                    skuName: product.skuName,
                    status:3,
                    productId: product._id,
                }));

                const response = await axios.post(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/create`, orderItems, { headers: authHeader() });

                if (response.success === true || response.status === 200) {

                    // Update the product ID and DO number
                    response.data?.insertedOrders?.forEach((item) => {

                        // Update the product ID and DO number
                        checkedProducts?.forEach((product) => {
                            if (item.skuName === product.skuName) {
                                product._id = item._id;
                                product.productId = item._id;
                                product.doNo = item.doNo;
                            }
                        });

                    });
                    handleNext();

                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally { setIsLoading(false) }
        }
    };


    return (
        <>
            <PageHeader
                title={`Order No: ${doNo}`}
                description2={
                    <div>
                        <p style={{ color: "#354151", fontWeight: "500", fontFamily: 'Poppins' }}>DB ID: {userInfo?.userId}, Distributor Name: {userInfo?.name || userInfo?.distributorName}, Mobile No: {userInfo?.phoneNumber}, Address: {userInfo?.address}</p>
                    </div>
                }
                className="mb-3"
            />

            <Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader isNew={false} isExport={false} isSearch={true} setSearchText={setSearchText} />
                        <p style={{ marginRight: "20px", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}><BiSolidSelectMultiple /> Select : {checkedProducts?.length > 0 ? checkedProducts?.length : 0}</p>
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div>
                        {productsData.length === 0 ? (
                            <p style={{ textAlign: "center", fontSize: "18px", color: "#354151", padding: "20px" }}>No product Category found</p>
                        ) : (
                            <Accordion>
                                {productsData.map((category) => (
                                    <Accordion.Item key={category.categoryId} eventKey={category.categoryId}>
                                        <Accordion.Header ><span style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>{category.categoryName}</span></Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th><IoIosCheckboxOutline /></th>
                                                        <th style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>Product Name</th>
                                                        <th style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>Factory Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {category.data.map((product) => (
                                                        <tr key={product.id}>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isChecked(product)}
                                                                    onChange={() => handleCheckboxChange(product)}
                                                                />
                                                            </td>
                                                            <td style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>{product.skuName}</td>
                                                            <td style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>{product.factoryName?.join(', ')}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )}
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Button
                        variant="primary"
                        className="me-2 px-5"
                        type="submit"
                        disabled={checkedProducts.length === 0}
                        onClick={() => {
                            handleSubmit();

                        }}
                    >
                        Next
                    </Button>
                </Card.Footer>
            </Card>
        </>
    );

};

export default UpdateOrdersStep1;
