import handleApiResponseErrors from "helpers/handleApiResponse";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { authHeader } from "utils";
import ReconcileOrderStep2 from "./ReconcileOrderStep2";
import PageHeader from "components/common/PageHeader";
import { FormGroup, FormControl, Button } from "react-bootstrap";




const OrderReconcile = () => {
    // const { doNo } = useParams();
    const [step, setStep] = useState(2);
    const [checkedProducts, setCheckedProducts] = useState([]);
    const [distributorOrderNumber, setDistributorOrderNumber] = useState("");
    const [showChild, setShowChild] = useState(false);

    const handleSearchClick = () => {
        if (setDistributorOrderNumber) {
          setShowChild(true);  // Set to true to render the child component
        } else {
          setShowChild(false);  // Set to false if the search term is empty
        }
      };


    const handleNext = () => {
        setStep(step + 1);
    }

    const handleBack = () => {
        setStep(step - 1);
    }


    return (
        <>
            {/* {step === 1 && (
                <UpdateOrdersStep1 handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
            )} */}

            {step === 2 && (
                <>
                    <PageHeader
                        title={`Search By DO No`}
                        description2={
                            <div className="py-3 d-flex justify-content-start align-items-center">
                                <FormGroup>
                                    <FormControl type="text" placeholder="Enter DO No" onChange={(e) => {setCheckedProducts([]);setDistributorOrderNumber(e.target.value)}} value={distributorOrderNumber} />
                                </FormGroup>

                                <Button className="ms-3" variant="primary" onClick={handleSearchClick}>Search</Button>

                            </div>
                        }
                        className="mb-3"
                    />
                    {distributorOrderNumber.length ? (
                        <ReconcileOrderStep2 step={step} handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
                    ) : null}
                </>)}
            {/* {step === 3 && (
                <UpdateOrderStep3 setStep={setStep} distributorOrderNumber={distributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} setDistributorOrderNumber={setDistributorOrderNumber} />
            )} */}
        </>
    )
}

export default OrderReconcile;