import FalconComponentCard from 'components/common/FalconComponentCard'
import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Papa from 'papaparse'
import PageHeader from 'components/common/PageHeader'
import IconButton from 'components/common/IconButton'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Form,
    Row,
    InputGroup,
    Dropdown,
    Card,
    Modal,
} from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from 'utils'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Select from 'react-select'
import LoadingIcon from 'helpers/LoadingIcon'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import Flex from 'components/common/Flex'
import FalconCloseButton from 'components/common/FalconCloseButton'
import cloudUpload from 'assets/img/icons/cloud-upload.svg'
import { authHeaderForm } from 'state/ducs/auth/utils'
import useUserRole from 'hooks/useUserRole'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const PaymentAdjustment2 = () => {
    const [imageFile, setImageFile] = useState(null)
    const [files, setFiles] = useState([])
    const [selectedZone, setSelectedZone] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('')
    const [selectedArea, setSelectedArea] = useState('')
    const [selectedDbPoint, setSelectedDbPoint] = useState('')
    const [selectedDistributor, setSelectedDistributor] = useState('')
    const [zoneList, setZoneList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [areaList, setAreaList] = useState([])
    const [excelData, setExcelData] = useState([])
    const [dbPointList, setDbPointList] = useState([])
    const [distributorList, setDistributorList] = useState([])
    const [isLoading, setIsLoading] = useState({
        zone: false,
        area: false,
        region: false,
    })
    const [showUploadCSV, setShowUploadCSV] = useState(false)
    const navigate = useNavigate()
    const { companyName } = useSelector((state) => state.auth.user.data)
    const userRole = useUserRole()

    // csv Modal
    const handleShowCSV = () => {
        setShowUploadCSV(true)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading((prevState) => ({ ...prevState, zone: true }))
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(
                        (data) => ({
                            id: data._id,
                            label: data.name,
                            value: data.name,
                        })
                    )

                    setZoneList(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, zone: false }))
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (selectedZone) {

            const fetchData = async () => {
                setIsLoading((prevState) => ({ ...prevState, region: true }))
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.regionName,
                                value: data.regionName,
                            })
                        )

                        setRegionList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({ ...prevState, region: false }))
                }
            }

            fetchData()
        }
    }, [selectedZone])

    useEffect(() => {
        if (selectedRegion) {
            setIsLoading(true)
            const fetchData = async () => {
                setIsLoading((prevState) => ({ ...prevState, area: true }))
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}area?regionName=${selectedRegion}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.areaName,
                                value: data.areaName,
                            })
                        )

                        setAreaList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({ ...prevState, area: false }))
                }
            }

            fetchData()
        }
    }, [selectedRegion])

    useEffect(() => {
        if (selectedArea) {
            setIsLoading(true)
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.dbPointName,
                                value: data.dbPointName,
                            })
                        )
                        setDbPointList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading(false)
                }
            }
            fetchData()
        }
    }, [selectedArea])

    useEffect(() => {
        if (selectedZone) {
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?zoneName=${selectedZone}&regionName=${selectedRegion}&areaName=${selectedArea}&dbPoint=${selectedDbPoint}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })
                    if (response.data.success || response.status === 200) {
                        const modifyData =
                            response?.data?.payload?.distributors.map(
                                (data) => ({
                                    id: data.userId,
                                    label: data.distributorName,
                                    value: data.userId,
                                    phoneNumber: data.phoneNumber,
                                    userRole: data.userRole,
                                })
                            )
                        setDistributorList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading(false)
                }
            }
            fetchData()
        }
    }, [selectedDbPoint])

    // EXCEL FILE DOWNLOAD
    useEffect(() => {
        const requestData = {
            zoneName: selectedZone,
            regionName: selectedRegion,
            areaName: selectedArea,
            dbPoint: selectedDbPoint,
            userId: selectedDistributor,
        }
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/zoneRegionAreaDB`,
                    requestData,
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.DBInfo
                    const formatingData = result?.map((data, index) => ({
                        SL: index + 1,
                        dealerId: data.userId,
                        dealerName: data.distributorName,
                        superDBId: data.superDBId,
                        zone: data.zoneName,
                        region: data.regionName,
                        area: data.areaName,
                        dbPoint: data.dbPoint,
                        dbType: data.userRole,
                        paymentMethod: '',
                        bankNames: '',
                        DealerPhone: '',
                        bankAccountNo: '',
                        mobileMethodName: '',
                        depositorsBankName: '',
                        depositorsBranchName: '',
                        depositorPhone: '',
                        drBDT: '',
                        paymentNote: '',
                        paymentDate: '',
                    }))
                    setExcelData(formatingData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            }
        }
        fetchData()
    }, [
        selectedZone,
        selectedRegion,
        selectedArea,
        selectedDbPoint,
        selectedDistributor,
    ])

    // Excel Download
    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const csv = XLSX.utils.sheet_to_csv(ws);
        const data = new Blob([csv], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    /* CSV FILE UPLOAD */
    const {
        acceptedFiles,
        getRootProps: getCsvRootProps,
        getInputProps: getCsvInputProps,
    } = useDropzone()

    const getFiles = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ))

    const handleCSVUpload = () => {
        const csvFile = acceptedFiles[0]

        // Define required fields
        const requiredFields = [
            'SL',
            'dealerId',
            'dealerName',
            'zone',
            'region',
            'area',
            'dbPoint',
            'dbType',
            'drBDT',
            'paymentNote',
            'paymentDate',
        ]

        const validateCSV = (data) => {
            for (let i = 0; i < data.length; i++) {
                const row = data[i]
                row.trId = i
                console.log(`Row ${i + 1}:`, row) // Log each row to inspect values
                for (let field of requiredFields) {
                    const value = row[field] ? row[field].trim() : ''
                    console.log(
                        `Validating field "${field}" with value "${value}" in row ${i + 1}`
                    )
                    if (value === '') {
                        return {
                            valid: false,
                            message: `The field "${field}" in row ${i + 1} is required and cannot be empty.`,
                        }
                    }
                }
            }
            return { valid: true }
        }

        Papa.parse(csvFile, {
            complete: function (results) {
                console.log('Parsed results:', results) // Log parsed results to check structure
                const validationResult = validateCSV(results.data)
                console.log('Validation result:', validationResult) // Log validation result
                if (!validationResult.valid) {
                    toast.error(validationResult.message)
                    return
                }


                const formData = new FormData()
                formData.append('csvFile', csvFile)
                formData.append('image', '')
                formData.append('deleteImageUrl', '')
                formData.append('confirmId', '')
                formData.append('approveId', '')
                formData.append('status', 1)


                const regUrl =
                    process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL +
                    'accounts/multiDBPaymentUpload'

                setIsLoading(true)
                axios
                    .post(regUrl, formData, { headers: authHeaderForm() })
                    .then((response) => {
                        if (
                            response.data.success === true ||
                            response.status === 200
                        ) {
                            toast.success('CSV File Upload Success')
                            setShowUploadCSV(false)
                            setCount((prevState) => prevState + 1)
                        }
                    })
                    .catch((error) => {
                        handleApiResponseErrors(error.response)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            },
            header: true, // If your CSV file has headers
            skipEmptyLines: true, // Skip empty lines
        })
    }

    //Image Upload

    // Dropzone
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles([
                ...files,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                ),
            ])
            // Set the first dropped file as the image file
            setImageFile(acceptedFiles[0])
        },
    })

    // Remove file
    const handleRemove = (path) => {
        setFiles(files.filter((file) => file.path !== path))
    }

    // Convert file to base64
    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result.split(',')[1])
            reader.onerror = (error) => reject(error)
        })
    }

    const handleSubmitForm = async (values, actions) => {
        const handleSubmitData = {
            zone: selectedZone,
            region: selectedRegion,
            area: selectedArea,
            dbPoint: selectedDbPoint,
            drBDT: values.amount,
            paymentNote: values.note,
            distributorId: values.distributorName.value,
            companyName: companyName,
            dealerId: values.distributorName.value,
            dbType: values.distributorName.userRole,
            dealerName: values.distributorName.label,
            DealerPhone: values.distributorName.phoneNumber,
            paymentDate: values.paymentDate,
            status: 1,
        }

        const imageFileName = imageFile?.name // Get the name of the image file
        if (imageFileName) {
            const imageBase64Value = await fileToBase64(imageFile) // Convert image file to base64
            handleSubmitData.image = imageBase64Value
        }

        setIsLoading(true)
        const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/create`

        try {
            const response = await axios.post(url, handleSubmitData, {
                headers: authHeader(),
            })

            if (response.status === 200 || response.data.status) {
                actions.resetForm()
                setFiles([])
                toast.success('Payment Adjustment created successfully')
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading(false)
        }
    }

    // Yup validation schema
    const handleSchema = Yup.object().shape({
        zoneName: Yup.object().required(
            'please select a value for the zone field'
        ),
        regionName: Yup.object().required(
            'please select a value for the region field'
        ),
        areaName: Yup.object().required(
            'please select a value for the area field'
        ),
        dbPointName: Yup.object().required(
            'please select a value for the db point field'
        ),
        distributorName: Yup.object().required(
            'please select a value for the distributor field'
        ),
        amount: Yup.string().required('Amount is required'),
        paymentDate: Yup.string().required('Payment Date is required'),
    })

    // if (isLoading) {
    //     return <LoadingIcon />
    // }
    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    {userRole === 'Admin' && (
                        <CustomersTableHeader
                            isNew={false}
                            title="Payment Adjustment Format"
                           // buttonTitle="Upload CSV"
                            handleShowCSV={handleShowCSV}
                            // excelFileName="Payment Adjustment"
                            // excelData={excelData}
                            isExport={false}
                            table
                        />
                    )}
                    {/* <CustomersTableHeader
                        isNew={false}
                        title="Payment Adjustment"
                        excelFileName="Payment Adjustment"
                        excelData={excelData}
                        table
                    /> */}
                </Card.Header>
            </Card>
            <FalconComponentCard>
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            zoneName: null,
                            regionName: null,
                            areaName: null,
                            dbPointName: null,
                            distributorName: null,
                            amount: '',
                            paymentDate: '',
                            note: '',
                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched,
                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Select Zone</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={zoneList}
                                                placeholder="Select Zone"
                                                classNamePrefix="react-select"
                                                name="zoneName"
                                                value={values.zoneName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'zoneName',
                                                        selectedOption
                                                    )
                                                    setSelectedZone(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'zoneName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.zone}
                                            />
                                            {touched.zoneName &&
                                                errors.zoneName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.zoneName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select Region
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={regionList}
                                                placeholder="Select Region"
                                                classNamePrefix="react-select"
                                                name="regionName"
                                                value={values.regionName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'regionName',
                                                        selectedOption
                                                    )
                                                    setSelectedRegion(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'regionName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.region}
                                            />
                                            {touched.regionName &&
                                                errors.regionName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.regionName}
                                                    </div>
                                                )}

                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Select Area</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={areaList}
                                                placeholder="Select Area"
                                                classNamePrefix="react-select"
                                                name="areaName"
                                                value={values.areaName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'areaName',
                                                        selectedOption
                                                    )
                                                    setSelectedArea(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'areaName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.area}
                                            />
                                            {touched.areaName &&
                                                errors.areaName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.areaName}
                                                    </div>
                                                )}

                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select DB Point
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={dbPointList}
                                                placeholder="Select DB Point"
                                                classNamePrefix="react-select"
                                                name="dbPointName"
                                                value={values.dbPointName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'dbPointName',
                                                        selectedOption
                                                    )
                                                    setSelectedDbPoint(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'dbPointName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                            />
                                            {touched.dbPointName &&
                                                errors.dbPointName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.dbPointName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select Distributor
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributorList}
                                                placeholder="Select Distributor"
                                                classNamePrefix="react-select"
                                                name="distributorName"
                                                value={values.distributorName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'distributorName',
                                                        selectedOption
                                                    )
                                                    setSelectedDistributor(
                                                        selectedOption.value
                                                    )
                                                }}
                                            />
                                            {touched.distributorName &&
                                                errors.distributorName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.distributorName}
                                                    </div>
                                                )}
                                        </Form.Group>


                                        {userRole === 'Accounts' && (
                                            <Form.Group
                                                as={Col}
                                                md="6"
                                                controlId="exampleFirstName"
                                            >
                                                <Form.Label>Amount</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Amount"
                                                    name="amount"
                                                    value={values.amount}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {touched.amount &&
                                                    errors.amount && (
                                                        <div
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {errors.amount}
                                                        </div>
                                                    )}
                                            </Form.Group>
                                        )}
                                    </Row>
                                    {userRole === 'Accounts' && (
                                        <Row className="mb-3">
                                            <Form.Group
                                                as={Col}
                                                md="6"
                                                controlId="exampleFirstName"
                                            >
                                                <Form.Label>
                                                    Payment Date
                                                </Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="note"
                                                    name="paymentDate"
                                                    value={values.paymentDate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {touched.paymentDate &&
                                                    errors.paymentDate && (
                                                        <div
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {errors.paymentDate}
                                                        </div>
                                                    )}
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                md="6"
                                                controlId="exampleFirstName"
                                            >
                                                <Form.Label>Note</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    as={'textarea'}
                                                    placeholder="note"
                                                    name="note"
                                                    value={values.note}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {touched.note &&
                                                    errors.note && (
                                                        <div
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {errors.note}
                                                        </div>
                                                    )}
                                            </Form.Group>
                                        </Row>
                                    )}

                                    {userRole === 'Accounts' && (
                                        <>
                                            <div
                                                {...getRootProps({
                                                    className:
                                                        'dropzone-area py-6',
                                                })}
                                            >
                                                <input
                                                    name="uploadedFiles"
                                                    {...getInputProps()}
                                                />
                                                <div className="fs--1">
                                                    <span className="d-none d-lg-inline">
                                                        Drag your image here
                                                        <br />
                                                        or,Please upload the
                                                        image less then 8MB{' '}
                                                    </span>
                                                    <Button
                                                        variant="link"
                                                        size="sm"
                                                        className="p-0 fs--1"
                                                    >
                                                        Browse
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                {files.map((file) => (
                                                    <Flex
                                                        alignItems="center"
                                                        className="py-3 border-bottom btn-reveal-trigger"
                                                        key={file.path}
                                                    >
                                                        <img
                                                            className="rounded"
                                                            width={40}
                                                            height={40}
                                                            src={file.preview}
                                                            alt={file.path}
                                                        />
                                                        <Flex
                                                            justifyContent="between"
                                                            alignItems="center"
                                                            className="ms-3 flex-1"
                                                        >
                                                            <div>
                                                                <h6>
                                                                    {file.path}
                                                                </h6>
                                                                <Flex
                                                                    className="position-relative"
                                                                    alignItems="center"
                                                                >
                                                                    <p className="mb-0 fs--1 text-400 line-height-1">
                                                                        <strong>
                                                                            {
                                                                                file.size
                                                                            }{' '}
                                                                            bytes
                                                                        </strong>
                                                                    </p>
                                                                </Flex>
                                                            </div>
                                                        </Flex>
                                                        <Dropdown>
                                                            <div className="py-2">
                                                                <Dropdown.Item
                                                                    className="text-danger"
                                                                    onClick={() =>
                                                                        handleRemove(
                                                                            file.path
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </Dropdown.Item>
                                                            </div>
                                                        </Dropdown>
                                                    </Flex>
                                                ))}
                                            </div>
                                        </>
                                    )}

                                    {userRole === 'Accounts' && (
                                        <IconButton
                                            variant="primary"
                                            className="me-2"
                                            type="submit"
                                        >
                                            Submit
                                        </IconButton>
                                    )}

                                    {userRole === 'Admin' && (<Button onClick={() => exportToCSV(excelData, 'Payment Adjustment Format')}> Dowanload</Button>)}
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {/* CSV File Upload Modal */}
            <Modal
                size="lg"
                show={showUploadCSV}
                onHide={() => setShowUploadCSV(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Upload Payment Adjustment CSV File
                    </Modal.Title>
                    <FalconCloseButton
                        onClick={() => setShowUploadCSV(false)}
                    />
                </Modal.Header>
                <Modal.Body>
                    <div
                        {...getCsvRootProps({
                            className: 'dropzone-area py-6',
                        })}
                    >
                        <input {...getCsvInputProps({ multiple: false })} />
                        <Flex justifyContent="center">
                            <img
                                src={cloudUpload}
                                alt=""
                                width={25}
                                className="me-2"
                            />
                            <p className="fs-0 mb-0 text-700">
                                Drop your file here
                            </p>
                        </Flex>
                    </div>
                    <div className="mt-3">
                        {acceptedFiles.length > 0 && (
                            <>
                                <h6>File</h6>
                                <ul>{files}</ul>
                            </>
                        )}
                    </div>
                    <Button variant="primary" onClick={handleCSVUpload}>
                        Submit
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PaymentAdjustment2
