import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import axios from 'axios'
import IconButton from 'components/common/IconButton'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table, Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleBarReact from 'simplebar-react'
import { authHeader } from 'utils'
import { usePDF } from 'react-to-pdf'
import StatusCell from 'components/common/StatusCell'
import useUserRole from 'hooks/useUserRole'
import { formatNumber } from 'helpers/utils'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import LoadingIcon from 'helpers/LoadingIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from "react";
import debounce from "lodash/debounce";



const CollapseOrdersUnscheduled = ({ ordersInfo }) => {

    const formatDate = (dt) => {
        const originalDateString = dt
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`

        return formattedDateString
    }


    return (
        <div style={{ maxWidth: '100%' }}>
            <Card className="p-0" style={{ maxWidth: '100%' }}>
                <Card.Body>
                    <div className="fs--1">
                        <SimpleBarReact>
                            <Table borderless className="mb-10 fs--1">
                                <thead className="bg-light">
                                    <tr className="text-900">

                                        <th>DO No</th>
                                        <th>DB ID</th>
                                        <th>DB Name</th>
                                        <th className="text-start">SKU Name</th>
                                        <th className="text-start">Unit</th>
                                        <th className="text-end">{(ordersInfo[0]?.delQtyCtn || ordersInfo[0]?.delQtyPcs) ? "Schedule" : "Order"} Qty</th>
                                        {/* {!isSuper && (<th className="text-start">Factory Name</th>)} */}
                                        <th className="text-start">Offer SKU</th>
                                        <th className="text-start">Offer Qty</th>
                                        <th className="text-start">Offer UOM</th>
                                        {/* <th className="text-start">Order Date</th> */}
                                        <th className="text-end">{(ordersInfo[0]?.delQtyCtn || ordersInfo[0]?.delQtyPcs) ? "Schedule" : "Order"} Date</th>
                                        <th className="text-start"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ordersInfo?.map((order, index) => {
                                        return (
                                            <tr className="border-top border-200">

                                                <td className="align-middle text-start fw-semi-bold">{order?.doNo}</td>
                                                <td className="align-middle text-start fw-semi-bold">{order?.dealerId}</td>
                                                <td className="align-middle text-start fw-semi-bold">{order?.distributorName}</td>
                                                <td className="align-middle text-start fw-semi-bold">{order?.skuName || (order?.offerNo && order?.offerSkuName)}</td>
                                                <td className="text-start">{order?.dbType == "SPECIAL DISTRIBUTOR" || order?.offerNo ? "PCS" : "CTN"}</td>
                                                {!order?.offerNo ? (
                                                    <td className="align-middle text-end fw-semi-bold">
                                                        {order?.dbType == "SPECIAL DISTRIBUTOR" ? (order.totalOrderQtyPCS || order?.delQtyPcs) || (order?.offerNo && (order?.offerPc || order?.offerQtyPCS)) : (order.orderQtyCtn || order.delQtyCtn) || (order?.offerNo && (order?.offerPc || order?.offerQtyPCS))}
                                                    </td>
                                                ) : <td className="align-middle text-end fw-semi-bold">--</td>}




                                                <td className="text-start">{order.offerSkuName || "--"}</td>
                                                <td className="text-start">{formatNumber(order.offerPc || order.offerQtyPCS) || "--"}</td>

                                                <td className="text-start">{order.offerPc || order.offerUnit || "--"}</td>

                                                <td>{formatDate(order.doDate || order.scheduleDate || "--")}</td>

                                            </tr>
                                        )
                                    })}



                                </tbody>
                            </Table>
                        </SimpleBarReact>
                    </div>


                </Card.Body>
            </Card>
        </div>
    )
}

export default CollapseOrdersUnscheduled
