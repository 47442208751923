import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from '../Flex';

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  totalPages,
  pageIndex,
  gotoPage,
  paginationPageCount,
  setPaginationPageCount,
  pageDataCount,
  setPageDataCount
}) => {
  const visiblePages = 5;

  const getDisplayedPages = () => {
    const startPage = Math.max(1, paginationPageCount - Math.floor(visiblePages / 2));
    const endPage = Math.min(totalPages, startPage + visiblePages - 1);
    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => {
          if (paginationPageCount > 1) {
            previousPage();
            setPaginationPageCount(paginationPageCount - 1);
          }
        }}
        className={classNames({ disabled: paginationPageCount === 1 })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-2">
        {getDisplayedPages().map((page) => (
          <li key={page} className={classNames({ active: page === paginationPageCount })}>
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-2': page !== getDisplayedPages()[getDisplayedPages().length - 1]
              })}
              onClick={() => setPaginationPageCount(page)}
            >
              {page}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => {
          if (paginationPageCount < totalPages) {
            nextPage();
            setPaginationPageCount(paginationPageCount + 1);
          }
        }}
        className={classNames({ disabled: paginationPageCount === totalPages })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>

      <Row className="ms-1 flex-between-center">
        <Col xs="auto">
          <Form.Select size="sm" defaultValue={pageDataCount.toString()} onChange={(e) => setPageDataCount(parseInt(e.target.value))}>
            <option value={10}>
              10
            </option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={30}>40</option>
            <option value={30}>50</option>
          </Form.Select>
        </Col>
      </Row>
    </Flex>
  );
};

export default AdvanceTablePagination;
