import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import SimpleBarReact from 'simplebar-react'
import { formatNumber } from 'helpers/utils';
import Swal from 'sweetalert2'


const formatDate = (dt) => {
    const originalDateString = dt
    const originalDate = new Date(originalDateString)

    const year = originalDate.getFullYear()
    const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
    const day = String(originalDate.getDate()).padStart(2, '0')

    const formattedDateString = `${year}-${month}-${day}`

    return formattedDateString
}

const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '180', // Set your desired width here
    }),
    control: (provided) => ({
        ...provided,
        width: '180px', // Ensure control element also respects the width
    }),
};


const getTotalPrice = (items) =>
    items
        .map(({ unit, price }) => unit * price)
        .reduce((total, currentValue) => total + currentValue, 0)

const getTotalOrder = (items) =>
    items
        .map(({ unit }) => unit)
        .reduce((total, currentValue) => total + currentValue, 0)

const BestSellingTableRow = ({
    order,
    setSelectedSKUName,
    setSelectedFactoryName,
    setSelectedDoNo,
    factoryNameChangeHandler,
    qtyChangeHandler,
    deliveryDateChangeHandler,
    deliveryEndDateChangeHandler,
    setDealerId,
    setDealerName,
    setSelectedDbType,
    isSuper,
    myFactory
}) => {
    const {
        doNo,
        doDate,
        totalOrderQtyPCS,
        orderQtyCtn,
        factoryName,
        skuName,
        deliveryQty,
        _id,
        deliveryDate,
        deliveryDateEnd,
        dealerId,
        distributorName,
        dbType,
        offerSkuName,
        offerPc

    } = order

    const factories = factoryName.map((factory) => ({
        label: factory,
        value: factory,
    }))

    console.log("factories", factories);
    
   

    const factoryValue= factories.find((factory) => factory.value === myFactory?.value) || null;

    console.log("factoryValue", factoryValue);



    return (
        <tr className="border-top border-200">
            <td className="align-middle text-start fw-semi-bold">{doNo}</td>
            <td className="align-middle text-start fw-semi-bold">{dealerId}</td>
            <td className="align-middle text-start fw-semi-bold">{distributorName}</td>
            <td className="align-middle text-start fw-semi-bold">{skuName}</td>
            <td className="text-start">{dbType == "SPECIAL DISTRIBUTOR" ? "PCS" : "CTN"}</td>
            <td className="align-middle text-end fw-semi-bold">
                {dbType == "SPECIAL DISTRIBUTOR" ? totalOrderQtyPCS : orderQtyCtn}
            </td>

            <td className="align-middle pe-x1 text-end">
                <Form.Control
                    style={{ width: "86px" }}
                    required
                    type="number"
                    placeholder="Qty"
                    value={deliveryQty}
                    onChange={(event) => {
                        setSelectedSKUName(skuName)
                        setSelectedDoNo(doNo)
                        setDealerId(dealerId)
                        setDealerName(distributorName)
                        setSelectedDbType(dbType)
                        qtyChangeHandler(_id, event)
                    }}
                />
            </td>
            {!isSuper && (
                <td
                    className="align-middle text-start fw-semi-bold"
                >
                    <Select
                        isMulti
                        closeMenuOnSelect={true}
                        styles={customStyles}
                        options={factories}
                        placeholder="Select"
                        classNamePrefix="react-select"
                        // isDisabled={deliveryQty <= 0}
                        value={
                            // factories.length == 1 && deliveryQty > 0
                            //     ? {
                            //         label: factories[0].label,
                            //         value: factories[0].value,
                            //     }
                            //     : null

                            factoryValue ?
                            {
                                label: factoryValue.label,
                                value: factoryValue.value,
                            } : null

                        }
                        onChange={(value) => {
                            setSelectedFactoryName(value.label)
                            setSelectedSKUName(skuName)
                            setSelectedDoNo(doNo)
                            factoryNameChangeHandler(_id, value.label)
                        }}
                    />
                </td>
            )}

            <td className="text-start">{offerSkuName || "--"}</td>
            <td className="text-start">{formatNumber(offerPc) || "--"}</td>
            {/* <td>
                {' '}
                <div style={{ width: '150px' }}>
                    <DatePicker
                        selected={deliveryDate}
                        onChange={(date) => deliveryDateChangeHandler(_id, date)}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        className="form-control"
                        placeholderText="Select Date"
                        disabled={deliveryQty <= 0}
                        popperPlacement="top-start"
                        style={{ width: '100%' }}

                    />

                </div>
            </td>
            <td>
                {' '}
                <div style={{ width: '150px' }}>
                    <DatePicker
                        selected={deliveryDateEnd}
                        onChange={(date) => deliveryEndDateChangeHandler(_id, date)}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        className="form-control"
                        placeholderText="Select Date"
                        disabled={deliveryQty <= 0}
                        popperPlacement="top-start"
                        style={{ width: '100%' }}

                    />
                </div>
            </td> */}
            <td>{formatDate(doDate)}</td>
            <td>
                <Button
                    onClick={() => {
                        setSelectedSKUName(skuName)
                        setSelectedDoNo(doNo)
                    }}
                    size="sm"
                >
                    View
                </Button>
            </td>
        </tr>
    )
}

const BestSellingProducts = ({
    orders,
    allScheduleData,
    setSelectedFactoryName,
    setSelectedSKUName,
    setSelectedDoNo,
    setSchedulableOrders,
    schedulableOrders,
    handleScheduleSubmit,
    getScheduledQuantityBySKU,
    getScheduledQuantityBySKUInPieces,
    scheduledOrders,
    setDealerId,
    setDealerName,
    setSelectedDbType,
    isSuper,
    comboOffers,
    updatableCombos,
    setUpdatableCombos,
    myFactory
}) => {
    useEffect(() => {
        /////////////////////////////////////////////////////
        //////for filtering out completed orders: START//////
        ///////////////////////////////////////////////////
        const IsShowable = (order, allScheduleData) => {
            let show = true
            const qtySum = (allScheduleData || [])
                .filter(
                    (item) =>
                        item.doNo == order.doNo && item.skuName == order.skuName
                )
                .reduce((accumulator, obj) => {
                    return accumulator + (obj.dbType == "SPECIAL DISTRIBUTOR" ? obj.delQtyPcs : obj.delQtyCtn)
                }, 0)
            const items = (allScheduleData || []).filter(
                (item) =>
                    item.doNo == order.doNo && item.skuName == order.skuName
            )

            // for (let i = 0; i < items.length; i++) {
            //     const element = items[i]
            //     if (element.status == 7) {
            //         show = false
            //         break
            //     }
            // }

            return show && qtySum == (order.dbType == "SPECIAL DISTRIBUTOR" ? order.totalOrderQtyPCS : order.orderQtyCtn)
        }

        // const filteredOrders = (orders || []).filter(
        //     (order) => !IsShowable(order, allScheduleData)
        // )

        let filteredOrders = []
        if (orders && orders.length) {
            filteredOrders = orders.filter(
                // (order) => !IsShowable(order, [...allScheduleData, ...scheduledOrders])
                (order) => !IsShowable(order, [...allScheduleData])
            )
        }

        console.log("orders", orders);
        console.log("scheduledOrders", scheduledOrders);
        console.log("allScheduleDataOrders", allScheduleData);
        console.log("filteredOrders", filteredOrders);



        ////////////////////////////////////////////////////
        //////for filtering out completed orders: END//////
        //////////////////////////////////////////////////

        // setSchedulableOrders(orders)
        setSchedulableOrders(filteredOrders)
    }, [orders.length, scheduledOrders.length, allScheduleData.length])

    const factoryNameChangeHandler = (id, factoryName) => {
        const existing = schedulableOrders
        const returned = existing.map((item) => {

            if (item._id == id) {
                //if factory already not in the changed items
                // const sameFactoryExists = schedulableOrders.filter(mine => mine.deliveryQty).find(gj => gj.factoryName[0] == factoryName);

                // if (!isSuper && schedulableOrders.filter(mine => mine.deliveryQty).length && !sameFactoryExists) {
                //     Swal.fire({
                //         title: 'Cant Schedule multiple factory orders at a time',
                //         text: 'Cant Schedule multiple factory orders at a time',
                //         icon: 'warning',
                //     })
                //     return item;
                // }
                return {
                    ...item,
                    factoryName: [factoryName],
                }
            }
            return item
        })

        setSchedulableOrders(returned)
    }

    const qtyChangeHandler = (id, ev) => {

        const existing = schedulableOrders



        const returned = existing.map((item) => {
            if (item._id == id) {




                setSelectedFactoryName(
                    item.factoryName.length == 1 ? item.factoryName[0] : ''
                )

                //if factory already not in the changed items
                // const sameFactoryExists = schedulableOrders.filter(mine => mine.deliveryQty).find(gj => gj.factoryName[0] == item?.factoryName[0]);

                // if (!isSuper && schedulableOrders.filter(mine => mine.deliveryQty).length && !sameFactoryExists && item.factoryName.length == 1) {
                //     // alert("Cant Schedule multiple factory orders at a time");
                //     Swal.fire({
                //         title: 'Cant Schedule multiple factory orders at a time',
                //         text: 'Cant Schedule multiple factory orders at a time',
                //         icon: 'warning',
                //     })
                //     return item;
                // }

                const schQty = getScheduledQuantityBySKU(item.skuName, item.doNo) || 0

                let des = 0
                const inputValue = ev.target.value
                if (inputValue > item.orderQtyCtn - schQty) {
                    des = item.orderQtyCtn - schQty
                } else if (inputValue < 0) {
                    des = 0
                } else {
                    des = inputValue
                }

                return {
                    ...item,
                    deliveryQty: +des,
                }
            }
            return item
        })

        setSchedulableOrders(returned)
    }

    const qtyChangeHandlerForPieces = (id, ev) => {
        const existing = schedulableOrders
        const returned = existing.map((item) => {
            if (item._id == id) {
                setSelectedFactoryName(
                    item.factoryName.length == 1 ? item.factoryName[0] : ''
                )

                //if factory already not in the changed items
                // const sameFactoryExists = schedulableOrders.filter(mine => mine.deliveryQty).find(gj => gj.factoryName[0] == item?.factoryName[0]);

                // if (!isSuper && schedulableOrders.filter(mine => mine.deliveryQty).length && !sameFactoryExists && item.factoryName.length == 1) {
                //     Swal.fire({
                //         title: 'Cant Schedule multiple factory orders at a time',
                //         text: 'Cant Schedule multiple factory orders at a time',
                //         icon: 'warning',
                //     })
                //     return item;
                // }

                const schQtyPieces = getScheduledQuantityBySKUInPieces(item.skuName, item.doNo) || 0

                let des = 0
                const inputValue = ev.target.value
                if (inputValue > item.totalOrderQtyPCS - schQtyPieces) {
                    des = item.totalOrderQtyPCS - schQtyPieces
                } else if (inputValue < 0) {
                    des = 0
                } else {
                    des = inputValue
                }

                return {
                    ...item,
                    deliveryQty: +des,
                }
            }
            return item
        })

        setSchedulableOrders(returned)
    }

    const deliveryDateChangeHandler = (id, date) => {
        const existing = schedulableOrders
        const returned = existing.map((item) => {
            if (item._id == id) {
                return {
                    ...item,
                    deliveryDate: date,
                }
            }
            return item
        })

        setSchedulableOrders(returned)
    }

    const deliveryEndDateChangeHandler = (id, date) => {
        const existing = schedulableOrders
        const returned = existing.map((item) => {
            if (item._id == id) {
                return {
                    ...item,
                    deliveryDateEnd: date,
                }
            }
            return item
        })

        setSchedulableOrders(returned)
    }

    const isSubset = (subset, set, key) => {
        console.log("subset", subset);
        console.log("set", set);
        console.log("setTruth", subset.every(subItem => set.some(setItem => setItem[key] === subItem)));

        return subset.every(subItem => set.some(setItem => setItem[key] === subItem));
    }

    console.log("combo", comboOffers);
    console.log("schedulableOrders", schedulableOrders.filter(item=> item.factoryName[0]));

    const comboShowable = (comboOffer) => {

        const allSchedules = [...allScheduleData, ...scheduledOrders];
        return !allSchedules.some(item => item.offerNo == comboOffer.offerNo && item.doNo == comboOffer.doNo)
    }

    return (
        <Card className="h-lg-100 overflow-hidden">
            <Card.Body className="p-0">
                <SimpleBarReact>
                    <Table borderless className="mb-10 fs--1">
                        <thead className="bg-light">
                            <tr className="text-900">
                                <th>DO No</th>
                                <th>DB ID</th>
                                <th>DB Name</th>
                                <th className="text-start">SKU Name</th>
                                <th className="text-start">Unit</th>
                                <th className="text-end">Order Qty</th>
                                <th className="text-end">Delivery Qty</th>
                                {!isSuper && (<th className="text-start">Factory Name</th>)}
                                <th className="text-start">Offer SKU</th>
                                <th className="text-start">Offer Qty (PC)</th>
                                {/* <th className="text-start">Delivery Start Date</th>
                                <th className="text-start">Delivery End Date</th> */}
                                <th className="text-start">Order Date</th>
                                <th className="text-start"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedulableOrders.map((order) => (
                                <BestSellingTableRow
                                    order={order}
                                    key={order._id}
                                    setSelectedFactoryName={
                                        setSelectedFactoryName
                                    }
                                    setSelectedSKUName={setSelectedSKUName}
                                    setSelectedDoNo={setSelectedDoNo}
                                    factoryNameChangeHandler={
                                        factoryNameChangeHandler
                                    }
                                    qtyChangeHandler={order.dbType == "SPECIAL DISTRIBUTOR" ? qtyChangeHandlerForPieces : qtyChangeHandler}
                                    deliveryDateChangeHandler={
                                        deliveryDateChangeHandler
                                    }

                                    deliveryEndDateChangeHandler={deliveryEndDateChangeHandler}
                                    setDealerId={setDealerId}
                                    setDealerName={setDealerName}
                                    setSelectedDbType={setSelectedDbType}
                                    isSuper={isSuper}
                                    myFactory={myFactory}
                                />
                            ))}


                            {comboOffers.filter(offer => comboShowable(offer) && offer.offerAccepted == "product" && offer.againstSkuName.length > 1 && isSubset([offer.doNo], schedulableOrders, "doNo")).map(item => (
                                <tr>
                                    <td>{item.doNo}</td>
                                    <td>{item.dealerId}</td>
                                    <td>{item.distributorName}</td>
                                    <td>{item.offerName}</td>
                                    <td>PCS</td>
                                    <td className="text-end">{formatNumber(item.offerPc) || "--"}</td>

                                    <td className='text-start'>
                                        <Form.Control
                                            style={{ width: "86px" }}
                                            required
                                            type="number"
                                            placeholder="Qty"
                                            // value={item?.offerQtyPCS}
                                            onChange={(event) => {
                                                if (!updatableCombos.length) {
                                                    // setUpdatableCombos([...updatableCombos, { ...item, offerQtyPCS: +event.target.value>item.offerPc ? item.offerPc:  +event.target.value}])
                                                    setUpdatableCombos([...updatableCombos, { ...item, offerPc: item.offerPc }])
                                                } else {

                                                    const existing = [...updatableCombos];
                                                    const index = existing.findIndex(my => my._id == item._id);
                                                    if (index >= 0) {
                                                        // existing[index].offerQtyPCS = +event.target.value > item.offerPc ? item.offerPc:  +event.target.value;
                                                        existing[index].offerPc = item.offerPc;
                                                        setUpdatableCombos([...existing])
                                                    } else {
                                                        setUpdatableCombos([...updatableCombos, { ...item, offerPc: item.offerPc }])
                                                        // setUpdatableCombos([...updatableCombos, { ...item, offerQtyPCS: +event.target.value > item.offerPc ? item.offerPc:  +event.target.value }])

                                                    }

                                                }
                                            }}
                                        />
                                    </td>

                                    {!isSuper &&
                                        <td className="align-middle text-start fw-semi-bold">

                                            <Select
                                                closeMenuOnSelect={true}
                                                options={[

                                                    {

                                                        label: "SORL",
                                                        value: "SORL"
                                                    },
                                                    {

                                                        label: "SVOIL",
                                                        value: "SVOIL"
                                                    },
                                                    {

                                                        label: "Narshingdi",
                                                        value: "Narshingdi"
                                                    },
                                                    {

                                                        label: "Dhamrai",
                                                        value: "Dhamrai"
                                                    }
                                                ]}
                                                placeholder="Select"
                                                classNamePrefix="react-select"
                                                name="factoryName"
                                                // value={{ id: item._id, label: offerFactoryName, value: offerFactoryName }}
                                                onChange={(selectedOption) => {
                                                    // setFieldValue(
                                                    //     "factoryName",
                                                    //     selectedOption
                                                    // );

                                                    if (!updatableCombos.length) {
                                                        setUpdatableCombos([...updatableCombos, { ...item, factoryName: selectedOption.value }])
                                                    } else {

                                                        const existing = [...updatableCombos];
                                                        const index = existing.findIndex(my => my._id == item._id);
                                                        if (index >= 0) {
                                                            existing[index].factoryName = selectedOption.value;
                                                            setUpdatableCombos([...existing])
                                                        } else {
                                                            setUpdatableCombos([...updatableCombos, { ...item, factoryName: selectedOption.value }])

                                                        }

                                                    }
                                                }}
                                            // onBlur={handleBlur}

                                            />
                                        </td>
                                    }
                                    <td className="text-start">{item.offerSkuName || "--"}</td>
                                    <td className="text-start">{formatNumber(item.offerPc) || "--"}</td>

                                    <td>{formatDate(item.doDate)}</td>
                                    <td>--</td>
                                </tr>
                            ))}


                        </tbody>
                    </Table>
                </SimpleBarReact>
            </Card.Body>
            <Card.Footer className="bg-light py-2">
                <Row className="flex-between-center">
                    <Col xs="auto"></Col>
                    <Col xs="auto">
                        <Button
                            onClick={handleScheduleSubmit}
                            variant="falcon-default"
                            size="sm"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}

BestSellingTableRow.propTypes = {
    totalPrice: PropTypes.number.isRequired,
    totalOrder: PropTypes.number.isRequired,
    product: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        img: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        unit: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
    }).isRequired,
}

BestSellingProducts.propTypes = {
    products: PropTypes.arrayOf(BestSellingTableRow.propTypes.product)
        .isRequired,
}

export default BestSellingProducts
