import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const ProductForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [brandsList, setBrandsList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subCategorys, setSubCategorys] = useState([]);
    const [productById, setProductById] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();


    const factoryList = [

        {
            label: "SORL",
            value: "SORL"
        },
        {
            label: "SVOIL",
            value: "SVOIL"
        },
        {
            label: "Narshingdi",
            value: "Narshingdi"
        },
        {
            label: "Dhamrai",
            value: "Dhamrai"
        }
    ]

    const unitsList = [
        // {
        //     label: "Box",
        //     value: "Box"
        // },
        // {
        //     label: "Jar",
        //     value: "Jar"
        // },
        {
            label: "Pcs",
            value: "Pcs"
        },
        // {
        //     label: "Pouch",
        //     value: "Pouch"
        // },
    ]

    const isNewProductList = [
        {
            id: true,
            label: "Yes",
            value: true
        }, {
            id: false,
            label: "No",
            value: false
        }
    ]

    const statusList = [
        {
            id: true,
            label: "Active",
            value: true

        },
        {
            id: false,
            label: "InActive",
            value: false
        }
    ]

    // GET BY ID
    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const data = response?.data?.payload?.product;
                        setProductById(data);
                        setSelectedCategory(data?.categoryName);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);

    // PRODUCT BRAND
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productBrand`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.status || response.status === 200 || response.status === 201) {
                    const modifyData = response?.data?.payload?.result.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name
                    }));

                    setBrandsList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // PRODUCT CATEGORY
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productCategory/all/prods`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.proCatData.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name
                    }));

                    setCategoryList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // PRODUCT SUB CATEGORY
    useEffect(() => {
        if (selectedCategory) {
            const fetchData = async () => {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}ProductSubCategory/all/prods?productCatName=MUSTARD OIL`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const data = response?.data?.payload?.proCatData;
                        const filterData = data?.filter(item => item.productCatName === selectedCategory);
                        const modifyData = filterData?.map(data => ({
                            id: data._id,
                            label: data.productSubCatName,
                            value: data.productSubCatName
                        }));

                        setSubCategorys(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchData();
        }


    }, [selectedCategory]);



    const handleSubmitForm = (values, actions) => {
        const handleSubmitData = {
            companyName: values.companyName,
            factoryName: values.factoryName.map(element => element.value),
            categoryName: values.categoryName.value,
            subCategory: values.subCategory?.value,
            skuName: values.skuName,
            skuBengaliName: values.skuBengaliName,
            productShortName: values.productShortName,
            distributorPrice: values.distributorPrice,
            commPrice: values.commPrice,
            wholesellPrice: values.wholesellPrice,
            spotPrice: values.spotPrice,
            tradePrice: values.tradePrice,
            specialPCPrice: values.specialPCPrice,
            erpId: values.erpId,
            unit: values.unit.value,
            weightPCS: values.weightPCS,
            pcsCTN: values.pcsCTN,
            productOpenDate: values.productOpenDate,
            closingDate: values.closingDate,
            imageUrl: values.imageUrl,
            statusNew: values.statusNew.value,
        };
        if (values.productStatus) {
            handleSubmitData.status = values.productStatus.value
        }

        setIsLoading(true);
        const url = updateID
            ? `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products/${updateID}`
            : `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products/create`;
        const axiosMethod = updateID ? axios.put : axios.post;

        axiosMethod(url, handleSubmitData, { headers: authHeader() })
            .then(response => {
                if (response.data.status || response.status === 200) {
                    navigate("/master/product");
                    updateID ? toast.success("Product Update Successfully") : toast.success("Product Added Successfully");
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required("Please select a value for the brand field"),
        categoryName: Yup.object().required("Please select a value for the category field"),
        subCategory: Yup.object().nullable(),
        factoryName: Yup.array().required("Please select a value for the factory name field"),
        skuName: Yup.string().required("Please enter a value for the product english name field"),
        skuBengaliName: Yup.string().nullable(),
        distributorPrice: Yup.number().required("Please enter a value for the distributor price field"),
        commPrice: Yup.number().required("Please enter a value for the commission price field"),
        wholesellPrice: Yup.number().required("Please enter a value for the wholesale price field"),
        spotPrice: Yup.number().required("Please enter a value for the spot price field"),
        specialPCPrice: Yup.number().required("Please enter a value for the spot price field"),
        tradePrice: Yup.number().required("Please enter a value for the trade price field"),
        erpId: Yup.string().required("Please enter a value for the erp id field"),
        unit: Yup.object().required("Please select a value for the unit field"),
        weightPCS: Yup.string().required("Please enter a value for the weight pcs field"),
        pcsCTN: Yup.string().required("Please enter a value for the pcs ctn field"),
        productOpenDate: Yup.string(),
        closingDate: Yup.string(),
        imageUrl: Yup.string(),
        statusNew: Yup.object().required("Please select a value for the is new product field"),
    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Product" : "Add Product"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            companyName: "Pusti Happy Time",
                            factoryName: factoryList.filter(obj => productById?.factoryName?.includes(obj.value)),
                            categoryName: categoryList.find(item => item.value === productById?.categoryName) || null,
                            subCategory: subCategorys.find(item => item.value === productById?.subCategory) || null,
                            skuName: productById?.skuName || "",
                            skuBengaliName: productById?.skuBengaliName || "",
                            productShortName: productById?.productShortName || "",
                            distributorPrice: productById?.distributorPrice || "",
                            commPrice: productById?.commPrice || "",
                            wholesellPrice: productById?.wholesellPrice || "",
                            spotPrice: productById?.spotPrice || "",
                            specialPCPrice: productById?.specialPCPrice || "",
                            tradePrice: productById?.tradePrice || "",
                            erpId: productById?.erpId || "",
                            unit: unitsList.find(item => item.value === productById?.unit) || null,
                            weightPCS: productById?.weightPCS || "",
                            pcsCTN: productById?.pcsCTN || "",
                            productOpenDate: productById?.productOpenDate || "",
                            closingDate: productById?.closingDate || "",
                            imageUrl: productById?.imageUrl || "",
                            statusNew: isNewProductList.find(item => item.value === productById?.statusNew) || null,
                            productStatus: statusList.find(item => item.value === productById?.status) || null
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched

                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control
                                                name="companyName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                required
                                                value="Pusti Happy Time"
                                                defaultValue="Pusti Happy Time"
                                            />
                                            {touched.companyName && errors.companyName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.companyName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Category</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={categoryList}
                                                placeholder="Select Category"
                                                classNamePrefix="react-select"
                                                name="categoryName"
                                                value={values.categoryName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "categoryName",
                                                        selectedOption
                                                    ), setSelectedCategory(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("categoryName", true, true) }}

                                            />

                                            {
                                                touched.categoryName && errors.categoryName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.categoryName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Sub Category</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={subCategorys}
                                                placeholder="Select Sub Category"
                                                classNamePrefix="react-select"
                                                name="subCategory"
                                                value={values.subCategory}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "subCategory",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("subCategory", true, true) }}

                                            />

                                            {
                                                touched.subCategory && errors.subCategory && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.subCategory}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Factory</Form.Label>
                                            <Select
                                                isMulti={true}
                                                closeMenuOnSelect={true}
                                                options={factoryList}
                                                placeholder="Select Factory"
                                                classNamePrefix="react-select"
                                                name="factoryName"
                                                value={values.factoryName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "factoryName",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.factoryName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.factoryName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Product Name (Sku)</Form.Label>
                                            <Form.Control
                                                name="skuName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Product English Name"
                                                required
                                                value={values.skuName}
                                            />
                                            {touched.skuName && errors.skuName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.skuName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Product Bengali Name</Form.Label>
                                            <Form.Control
                                                name="skuBengaliName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Product Bengali Name"

                                                value={values.skuBengaliName}
                                            />
                                            {touched.skuBengaliName && errors.skuBengaliName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.skuBengaliName}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Product Short Name</Form.Label>
                                            <Form.Control
                                                name="productShortName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Product Short Name"


                                                value={values.productShortName}
                                            />
                                            {touched.productShortName && errors.productShortName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.productShortName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Per PC DB Price</Form.Label>
                                            <Form.Control
                                                name="distributorPrice"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Distribution Price"
                                                required
                                                value={values.distributorPrice}
                                            />
                                            {touched.distributorPrice && errors.distributorPrice && (
                                                <div style={{ color: "red" }}>
                                                    {errors.distributorPrice}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Per PC Commission Price</Form.Label>
                                            <Form.Control
                                                name="commPrice"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Commission Price"
                                                required
                                                value={values.commPrice}
                                            />
                                            {touched.commPrice && errors.commPrice && (
                                                <div style={{ color: "red" }}>
                                                    {errors.commPrice}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Per PC Wholesale Price</Form.Label>
                                            <Form.Control
                                                name="wholesellPrice"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Wholesale Price"
                                                required
                                                value={values.wholesellPrice}
                                            />
                                            {touched.wholesellPrice && errors.wholesellPrice && (
                                                <div style={{ color: "red" }}>
                                                    {errors.wholesellPrice}
                                                </div>
                                            )}
                                        </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Per PC Spot Price</Form.Label>
                                            <Form.Control
                                                name="spotPrice"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Spot Price"
                                                required
                                                value={values.spotPrice}
                                            />
                                            {touched.spotPrice && errors.spotPrice && (
                                                <div style={{ color: "red" }}>
                                                    {errors.spotPrice}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Per PC Special Price</Form.Label>
                                            <Form.Control
                                                name="specialPCPrice"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Spot Price"
                                                required
                                                value={values.specialPCPrice}
                                            />
                                            {touched.specialPCPrice && errors.specialPCPrice && (
                                                <div style={{ color: "red" }}>
                                                    {errors.specialPCPrice}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Per PC Trade Price</Form.Label>
                                            <Form.Control
                                                name="tradePrice"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Trade Price"
                                                required
                                                value={values.tradePrice}
                                            />
                                            {touched.tradePrice && errors.tradePrice && (
                                                <div style={{ color: "red" }}>
                                                    {errors.tradePrice}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Erp ID</Form.Label>
                                            <Form.Control
                                                name="erpId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Erp ID"
                                                required
                                                value={values.erpId}
                                            />
                                            {touched.erpId && errors.erpId && (
                                                <div style={{ color: "red" }}>
                                                    {errors.erpId}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Unit</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={unitsList}
                                                placeholder="Select Unit"
                                                classNamePrefix="react-select"
                                                name="unit"
                                                value={values.unit}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "unit",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("unit", true, true) }}

                                            />

                                            {
                                                touched.unit && errors.unit && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.unit}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Weight/pcs(Gram)</Form.Label>
                                            <Form.Control
                                                name="weightPCS"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Weight/pcs"
                                                required
                                                value={values.weightPCS}
                                            />
                                            {touched.weightPCS && errors.weightPCS && (
                                                <div style={{ color: "red" }}>
                                                    {errors.weightPCS}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Per CTN/Bag PCS</Form.Label>
                                            <Form.Control
                                                name="pcsCTN"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Pcs/Sales Pack"
                                                required
                                                value={values.pcsCTN}
                                            />
                                            {touched.pcsCTN && errors.pcsCTN && (
                                                <div style={{ color: "red" }}>
                                                    {errors.pcsCTN}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Is New Product</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={isNewProductList}
                                                placeholder="Select Is New Product"
                                                classNamePrefix="react-select"
                                                name="statusNew"
                                                value={values.statusNew}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "statusNew",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("statusNew", true, true) }}

                                            />

                                            {
                                                touched.hasStorage && errors.hasStorage && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.hasStorage}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Product Open Date</Form.Label>
                                            <Form.Control
                                                name="productOpenDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter Product Open Date"
                                                value={values.productOpenDate}
                                            />
                                            {touched.productOpenDate && errors.productOpenDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.productOpenDate}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Product Closing Date</Form.Label>
                                            <Form.Control
                                                name="closingDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter Product Closing Date"
                                                value={values.closingDate}
                                            />
                                            {touched.closingDate && errors.closingDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.closingDate}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Image url</Form.Label>
                                            <Form.Control
                                                name="imageUrl"
                                                as="textarea"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Image Url"
                                                value={values.imageUrl}
                                            />
                                            {touched.imageUrl && errors.imageUrl && (
                                                <div style={{ color: "red" }}>
                                                    {errors.imageUrl}
                                                </div>
                                            )}
                                        </Form.Group>
                                        {updateID && <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Status</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={statusList}
                                                placeholder="Select Product Status"
                                                classNamePrefix="react-select"
                                                name="productStatus"
                                                value={values.productStatus}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "productStatus",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.productStatus && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.productStatus}
                                                    </div>
                                                )}
                                        </Form.Group>}
                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"


                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/product")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default ProductForm;


