import 'helpers/initFA'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import App from './App'
import Main from './Main'
import "./assets/custom/index.css";

// import store
import { store } from './state/store'
const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
const container = document.getElementById('main')
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ReduxProvider store={store}>
                <Main>
                    <App />
                </Main>
            </ReduxProvider>
        </QueryClientProvider>
    </React.StrictMode>
)
