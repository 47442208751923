import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import IconButton from 'components/common/IconButton'
import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { usePDF } from 'react-to-pdf'
import { useReactToPrint } from 'react-to-print'
import {
    getDeliveriesChallanNo,
    getSingleDistributorInfo,
    getOrdersByDealerId,
} from 'services/api'
import { useQuery } from 'react-query'
import { formatNumber } from 'helpers/utils'
import useUserData from '../../../hooks/useUserData'
import { format } from 'd3'

const DeliveryheaderData = () => {
    const [headerData, setHeaderData] = useState({})
    const [challanDataWithOutOffer, setChallanDataWithOutOffer] = useState([]);
    const [challanDataWithOffer, setChallanDataWithOffer] = useState([]);
    const { challanNo, dealerId } = useParams();
    const userData = useUserData();
    const navigate = useNavigate()

    // Get Delivery Data
    const {
        data: deliveryData,
        isLoading: isLoadingDeliveryByFactory,
        isError: deliveryByFactoryError,
        refetch: deliveryByFactoryRefetch,
    } = useQuery({
        queryKey: ['deliveriesChallanNo'],
        queryFn: () => getDeliveriesChallanNo(challanNo, dealerId),
    })

    // Get Single Distributor Info
    const {
        data: singleDistributorData,
        isLoading: isLoadingSingleDistributor,
        isError: singleDistributorError,
        refetch: singleDistributorRefetch,
    } = useQuery({
        queryKey: ['singleDistributor'],
        queryFn: () => getSingleDistributorInfo(dealerId),
    })


    // 
    useEffect(() => {

        if (singleDistributorData?.data?.payload && deliveryData?.data?.payload) {
            const distributorData = singleDistributorData.data.payload.distributorData;
            const challanDatas = deliveryData.data.payload.challanData;
            const getSingleChallanData = deliveryData.data.payload.challanData[0];
            if (distributorData && getSingleChallanData) {
                setHeaderData({
                    distributorName: distributorData?.distributorName,
                    dealerId: distributorData?.userId,
                    dbPoint: distributorData?.dbPoint,
                    areaName: distributorData?.areaName,
                    regionName: distributorData?.regionName,
                    zoneName: distributorData?.zoneName,
                    factoryName: getSingleChallanData?.factoryName,
                    transportName: getSingleChallanData?.transportName,
                    transportNo: getSingleChallanData?.transportNo,
                    driverName: getSingleChallanData?.driverName,
                    driverPhone: getSingleChallanData?.driverPhone,
                    deliveryDate: getSingleChallanData?.deliveryDate,
                    dbType: distributorData?.dbType,
                });
            }
            if (challanDatas) {
                const challanDataWithOutOffer = challanDatas.filter((item) => item?.offerQtyPCS === 0);
                const challanDataWithOffer = challanDatas.filter((item) => item?.offerQtyPCS > 0);
                setChallanDataWithOutOffer(challanDataWithOutOffer);
                setChallanDataWithOffer(challanDataWithOffer);
            }
        }
    }, [singleDistributorData, deliveryData]); // Add dependencies


    console.log('singleDistributorData', singleDistributorData)
    console.log('deliveryData', deliveryData)
    console.log('headerData', headerData)

    const { toPDF, targetRef } = usePDF({
        filename: 'challan No' + challanNo + '.pdf',
    })

    // Function to trigger the print
    const handlePrint = useReactToPrint({
        content: () => targetRef.current,
    })


    if (isLoadingDeliveryByFactory || isLoadingSingleDistributor) {
        return <div>Loading...</div>
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">
                                Challan No: {challanNo}
                            </h5>
                        </Col>

                        <Col xs="auto">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="print"
                                className="me-1 mb-2 mb-sm-0"
                                iconClassName="me-1"
                                onClick={() => handlePrint()}
                            >
                                Print
                            </IconButton>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card ref={targetRef} className="h-lg-100 overflow-hidden">
                <Card.Header>
                    <Row className="align-items-center text-center">
                        <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center text-center">
                                <div className="text-sm-start">
                                    <img src={logo} alt="invoice" width={100} />
                                </div>
                                <div className="text-center">
                                    <h3 >
                                        T.K Food Products Ltd.
                                    </h3>
                                    <p className="">
                                        T. K. Bhaban (2nd Floor), 13,
                                        Kawranbazar Dhaka-1215, Bangladesh
                                    </p>
                                </div>
                                <div></div>
                            </div>
                        </Col>


                        <Col xs={12}>
                            <hr />
                        </Col>

                        <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center text-center">
                                <div></div>
                                <div className='text-center' style={{ marginLeft: '160px' }}>
                                    <h3 >Delivery Challan</h3>
                                    <h5>{'Challan No: ' + challanNo}</h5>
                                </div>
                                <div
                                    className="mb-2"
                                    style={{
                                        marginRight: '33px',
                                        paddingBottom: '24px',
                                    }}
                                >
                                    Date:{' '}
                                    {(() => {
                                        const date = new Date(
                                            headerData?.deliveryDate
                                        )
                                        const day = date.getDate()
                                        const monthNames = [
                                            'Jan',
                                            'Feb',
                                            'Mar',
                                            'Apr',
                                            'May',
                                            'Jun',
                                            'Jul',
                                            'Aug',
                                            'Sep',
                                            'Oct',
                                            'Nov',
                                            'Dec',
                                        ]
                                        const month =
                                            monthNames[date.getMonth()]
                                        const year = date.getFullYear()
                                        return `${day}-${month}-${year}`
                                    })()}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>To :</div>
                                <div style={{ marginRight: '117px' }}>
                                    From :
                                </div>
                            </div>
                            <div className="d-flex justify-content-between my-3">
                                <div>
                                    <p className="mb-0 text-start">
                                        {headerData?.distributorName || ''} (
                                        {headerData?.dealerId})
                                    </p>
                                    <p
                                        className="mb-0 text-start "
                                        style={{ fontSize: '10px' }}
                                    >
                                        Address: {headerData?.dbPoint},{' '}
                                        {headerData?.areaName},{' '}
                                        {headerData?.regionName},{' '}
                                        {headerData?.zoneName}{' '}
                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Phone: ' +
                                            singleDistributorData?.data?.payload
                                                ?.distributorData
                                                ?.phoneNumber || ''}
                                    </p>
                                </div>

                                <div>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        Factory/Depot Name :{' '}
                                        {headerData?.factoryName?.length
                                            ? headerData?.factoryName
                                            : userData.distributorName}
                                    </p>

                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Transport Name: ' +
                                            headerData?.transportName}
                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Vehicle No: ' + headerData?.transportNo}
                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Driver Name: ' + headerData?.driverName}
                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Driver Phone: ' +
                                            headerData?.driverPhone}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body className="p-0">
                    {/* Showing Without offer data */}

                    <Table borderless className="fs--1">

                        <thead className="bg-light">
                            <tr className="text-900">
                                <th className="text-center">No.</th>
                                <th className="text-center">DO No</th>
                                <th className="text-center">Sku Name</th>
                                <th className="text-center">UOM</th>
                                <th className="text-center">Qty CTN</th>
                                <th className="text-center">Qty PCS</th>
                                <th className="text-center">Unit Price</th>
                                <th className="text-center">Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {challanDataWithOutOffer?.map((item, index) => {

                                return (
                                    <tr className="border-top border-200">

                                        <td className="text-center">
                                            {index + 1}
                                        </td>

                                        <td className="text-center fw-semi-bold">
                                            {
                                                item.doNo
                                            }
                                        </td>
                                        <td className="text-center fw-semi-bold">

                                            <span className="fw-bold">
                                                {item.skuName}
                                            </span>
                                        </td>



                                        <td className="text-center fw-semi-bold">
                                            {headerData?.dbType ==
                                                'SPECIAL DISTRIBUTOR'
                                                ? 'PCS'
                                                : 'CTN'}
                                        </td>

                                        <td className="text-center fw-semi-bold">
                                            {
                                                formatNumber(item?.delQtyCtn)
                                            }
                                        </td>

                                        <td className="text-center fw-semi-bold">
                                            {
                                                formatNumber(item?.delQtyPcs)
                                            }
                                        </td>
                                        <td className="text-center fw-semi-bold">
                                            {
                                                formatNumber((item?.delQtyPcs * item?.perPCPrice) / item?.delQtyCtn)
                                            }
                                        </td>
                                        <td className="text-center fw-semi-bold">
                                            {
                                                formatNumber((item?.delQtyPcs * item?.perPCPrice))
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                        <tfoot className="bg-light">
                            <tr className="text-900">

                                <th className="text-start fw-bold"></th>
                                <th className="text-start fw-bold"></th>
                                <th className="text-start fw-bold">Total</th>
                                <th className="text-start fw-bold">
                                    { }
                                </th>
                                <th className="text-center fw-bold">
                                    {formatNumber(
                                        deliveryData?.data?.payload?.challanData?.reduce(
                                            (accumulator, currentObj) => {
                                                return (
                                                    accumulator + currentObj.delQtyCtn
                                                )
                                            },
                                            0
                                        )
                                    )}
                                </th>
                                <th className="text-center fw-bold">
                                    {formatNumber(deliveryData?.data?.payload?.challanData?.reduce(
                                        (accumulator, currentObj) => {
                                            return (
                                                accumulator + currentObj.delQtyPcs
                                            )
                                        },
                                        0
                                    ))}
                                </th>
                                <th className="text-center fw-bold">
                                    {

                                        formatNumber(
                                            deliveryData?.data?.payload?.challanData?.reduce(
                                                (accumulator, currentObj) => {
                                                    return (
                                                        accumulator +
                                                        (currentObj.delQtyPcs * currentObj.perPCPrice) / currentObj.delQtyCtn
                                                    )
                                                },
                                                0
                                            )
                                        )
                                    }
                                </th>
                                <th className="text-center fw-bold">
                                    {
                                        formatNumber(
                                            deliveryData?.data?.payload?.challanData?.reduce(

                                                (accumulator, currentObj) => {
                                                    return (
                                                        accumulator +
                                                        (currentObj.delQtyPcs * currentObj.perPCPrice)
                                                    )
                                                },
                                                0
                                            )
                                        )
                                    }
                                </th>
                            </tr>
                        </tfoot>
                    </Table>

                    {/* with offer data */}
                    {challanDataWithOffer?.length > 0 && <Table borderless className="fs--1">
                        <thead className="bg-light">
                            <tr className="text-900">
                                <th className="text-center">No.</th>
                                <th className="text-center">DO No</th>
                                <th className="text-center">Offer Sku Name</th>
                                <th className="text-center">Offer Qty</th>
                                <th className="text-center">Offer UOM</th>

                            </tr>
                        </thead>
                        <tbody>
                            {challanDataWithOffer?.map((item, index) => {

                                return (
                                    <tr className="border-top border-200">

                                        <td className="text-center">
                                            {index + 1}
                                        </td>

                                        <td className="text-center fw-semi-bold">
                                            {
                                                item.doNo
                                            }
                                        </td>
                                        <td className="text-center fw-semi-bold">

                                            <span className="fw-bold">
                                                {item.offerSkuName}
                                            </span>
                                        </td>



                                        
                                        <td className="text-center fw-semi-bold">
                                            {
                                                item?.offerQtyPCS
                                            }
                                        </td>
                                        <td className="text-center fw-semi-bold">

                                            <span className="fw-bold">
                                                {item.offerUnit}
                                            </span>


                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>

                        <tfoot className="bg-light">
                            <tr className="text-900">

                                <th className="text-start fw-bold"></th>
                                <th className="text-start fw-bold"></th>
                                <th className="text-start fw-bold">Total</th>
                               
                                <th className="text-center fw-bold">
                                    {challanDataWithOffer.reduce(
                                        (accumulator, currentObj) => {
                                            return (
                                                accumulator + currentObj.offerQtyPCS
                                            )
                                        },
                                        0
                                    )}
                                </th>

                            </tr>
                        </tfoot>
                    </Table>}

                    <div className="d-flex justify-content-between px-3">
                        <p className="fw-bold">Remarks :</p>
                    </div>
                    <div className="d-flex justify-content-between px-3 mt-5 mb-3">
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            Receiver Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            Driver Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            SIC Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            DIC Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                                marginRight: '20px',
                            }}
                        >
                            HOD Signature
                        </p>
                    </div>
                </Card.Body>

            </Card >

            <div>
                <Button
                    variant="primary"
                    className="me-2 px-5 mt-3"
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </div>
        </>
    )
}

export default DeliveryheaderData
