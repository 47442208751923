import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import TargetTable from './TargetTable'
import TargetGraph from './TargetGraph'
import { useSelector } from 'react-redux'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'

export const index = ({ }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([]);
    const [targetAndAchievement, setTargetAndAchievement] = useState({});
    const { areaName } = useSelector(state => state.auth.user.data);
    const date = new Date();


    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/AreaWiseOrderTarget/${areaName}/${date.getFullYear()}/${date.getMonth() + 1}`;
                const ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/AreaWiseOrdersVsTarget/${areaName}/${date.getFullYear()}/${date.getMonth() + 1}`;

                const [targetResponse, ordersResponse] = await Promise.all([
                    axios.get(targetUrl, { headers: authHeader() }),
                    axios.get(ordersUrl, { headers: authHeader() })
                ]);

                if ((targetResponse.data.success || targetResponse.status === 200) &&
                    (ordersResponse.data.success || ordersResponse.status === 200)) {

                    const targetData = targetResponse.data.payload.targetInfo;
                    const orderVsTarget = ordersResponse.data.payload.orderVsTarget;
                    let totalTargetCnt = 0;
                    let totalAchievementCnt = 0;
                    let totalTargetAmount = 0;
                    let totalAchievementAmount = 0;
                    const combinedData = targetData.map(targetItem => {
                        totalTargetCnt += targetItem.targetQuantityCtn;
                        totalTargetAmount += parseFloat(targetItem.targetQuantityCtn) * parseFloat(targetItem.ctnPrice);
                        const order = orderVsTarget.find(orderItem => orderItem.skuName === targetItem.skuName);
                        if (order) {
                            totalAchievementCnt += order.orderQtyCtn;
                            totalAchievementAmount += parseFloat(order.orderQtyCtn) * parseFloat(targetItem.ctnPrice);
                        }
                        return order ? { ...targetItem, ...order } : targetItem;
                    });

                    setOrders(combinedData);
                    setTargetAndAchievement({
                        totalTargetCnt,
                        totalAchievementCnt,
                        totalTargetAmount,
                        totalAchievementAmount
                    });
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    if (isLoading) {
        return <LoadingIcon />
    }
    return (
        <>
            <Col lg={7} xl={8}>
                <TargetTable data={orders} />
            </Col>
            <Col lg={5} xl={4}>
                <TargetGraph data={targetAndAchievement} orders={orders} />
            </Col>
        </>
    )
}
export default index;
