import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import { IoIosCheckboxOutline } from 'react-icons/io'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
    faCheckCircle,
    faClock,
    faThumbsUp,
} from '@fortawesome/free-regular-svg-icons'
import StatusCell from 'components/common/StatusCell'
import { inDepthItems } from 'data/support-desk/reportsData'
import {
    Card,
    Col,
    Form,
    Nav,
    ProgressBar,
    Row,
    Tab,
    Button,
    Dropdown,
    InputGroup,
    FormControl,
} from 'react-bootstrap'
import SimpleBarReact from 'simplebar-react'
import PropTypes from 'prop-types'
import FalconLink from 'components/common/FalconLink'
import FalconComponentCard from 'components/common/FalconComponentCard'
import TabContentTable from 'components/pages/AccountAndFinance/Finance/OrderPaymentApprove/TabContentTable'
import TabContentTableDeposit from 'components/pages/AccountAndFinance/Finance/OrderPaymentApprove/TabContentTableDeposit'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import {
    getBalance,
    getSingleDistributorInfo,
    getAllOrdersWithBankByDB,
} from 'services/api'

const TabItem = ({ status }) => {
    return (
        <>
            <Row className="mx-0 border-bottom border-dashed">
                <Col
                    md={6}
                    className="p-x1 border-md-end border-bottom border-md-bottom-0 border-dashed"
                >
                    <h6 className="fs--1 mb-3">
                        Tickets {status} Split by Source
                    </h6>
                    sdnkfjdjdbj
                </Col>
                <Col md={6} className="p-x1">
                    <h6 className="fs--1 mb-3">
                        Tickets {status} Split by Priority
                    </h6>
                    kjcbnksdjbcjb
                </Col>
            </Row>
            <Row className="mx-0">
                <Col
                    md={6}
                    className="p-x1 border-md-end border-bottom border-md-bottom-0 border-dashed"
                >
                    <h6 className="fs--1 mb-3">
                        Tickets {status} Split by Status
                    </h6>
                    jhbcdjs
                </Col>
                <Col md={6} className="p-x1">
                    <h6 className="fs--1 mb-3">
                        Tickets {status} Split by Category
                    </h6>
                    dkjbcbcbcbcdsgvcj
                </Col>
            </Row>
        </>
    )
}

const OrderPaymentApprove = () => {
    const [validated, setValidated] = useState(false)

    const [distributorValue, setDistributorValue] = useState(null)

    const [myBank, setMyBank] = useState(null)

    const [transactionNumber, setTransactionNumber] = useState(null)

    const [transactionStartDate, setTransactionStartDate] = useState(null)

    const [transactionEndDate, setTransactionEndDate] = useState(null)

    const [totalOrder, setTotalOrder] = useState(null)

    const [totalDeposit, setTotalDeposit] = useState(null)

    const [balance, setBalance] = useState(null)

    const [myStatus, setMyStatus] = useState({
        value: 'pending',
        label: 'Pending',
    })

    const [searchText, setSearchText] = useState('')

    const [count, setCount] = useState(1)

    const [isLoading, setIsLoading] = useState(false)

    const [isLoadingDistributorOrderList, setIsLoadingDistributorOrderList] =
        useState(false)

    const [distributorOrderList, setDistributorOrderList] = useState([])

    const [allOrderList, setAllOrderList] = useState([])

    const [distributorList, setDistributorList] = useState([])

    const [depositList, setDepositList] = useState([])

    const [filteredDeposits, setFilteredDeposits] = useState([])

    const [checkedOrders, setCheckedOrders] = useState([])

    const [checkedOrderWithBanks, setCheckedOrderWithBanks] = useState([])

    const [checkedDeposits, setCheckedDeposits] = useState([])

    const [selectedDoNo, setSelectedDoNo] = useState(null)

    const [availableBalance, setAvailableBalance] = useState(null)

    const [creditLimit, setCreditLimit] = useState(null)

    const [bgAmount, setBgAmount] = useState(null)

    const [specialCredit, setSpecialCredit] = useState(null)

    const [availableCredit, setAvailableCredit] = useState(null)

    const [creditExpense, setCreditExpense] = useState(null)

    const [banksList, setBanksList] = useState([])

    const [detailsArray, setDetailsArray] = useState([])
    const [paymentUpdateId, setPaymentUpdateId] = useState(null)

    const [paymentData, setPaymentData] = useState([])

    console.log('detailsArray', detailsArray)

    const fetchPaymentData = async (doNo) => {
        try {
            const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank?doNo=${doNo}`
            const response = await axios.get(url, { headers: authHeader() })

            if (response.data.success || response.status === 200) {
                setPaymentData(
                    response.data.payload.result.map((item) => {
                        return {
                            ...item,
                            cpCRBDT: item.crBDT,
                            cpDRBDT: item.drBDT,
                            drBDT: item.crBDT + item.drBDT,
                            crBDT: 0,
                        }
                    })
                )
                setPaymentUpdateId(
                    response?.data?.payload?.result[0]?._id || null
                )
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading(false)
        }
    }

    const fetchPaymentDataMulti = async (doNos) => {
        try {
            const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/MultiDosOrderWithBank`
            const response = await axios.post(
                url,
                { DoNos: doNos },
                { headers: authHeader() }
            )

            if (response.data.success || response.status === 200) {
                setPaymentData(
                    response.data.payload.moreOrderWithBank.map((item) => {
                        return {
                            ...item,
                            cpCRBDT: item.crBDT,
                            cpDRBDT: item.drBDT,
                            drBDT: item.drBDT,
                            crBDT: item.crBDT,
                        }
                    })
                )
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading(false)
        }
    }

    const viewDetails = (order) => {
        if (order.doNo == detailsArray[0]?.doNo) {
            setDetailsArray([])
        } else {
            setDetailsArray(order?.data || [])
            setTotalOrder(
                (order?.data || [])
                    ?.filter((item) => item.orderQtyCtn)
                    ?.reduce((a, b) => a + b.totalPrice, 0)
            )
            setSelectedDoNo(order?.doNo)
            // fetchPaymentData(order?.doNo);
            fetchPaymentDataMulti([order?.doNo])
        }
    }

    const viewDetailsByCollection = (doNo) => {
        setSelectedDoNo(doNo)
        fetchPaymentDataMulti([doNo])
        setTotalOrder(
            (allOrderList || [])
                ?.filter((item) => item.doNo == doNo)
                ?.reduce((a, b) => a + b.totalPrice, 0)
        )
    }

    const viewDetailsByCollections = (doNos) => {
        setSelectedDoNo(doNos[0])
        setTotalOrder(
            (allOrderList || [])
                ?.filter((item) => doNos.includes(item.doNo))
                ?.reduce((a, b) => a + b.totalPrice, 0)
        )
        fetchPaymentDataMulti(doNos)
    }

    const resetAllStates = () => {
        setSearchText('')
        setCount(count + 1)
        setCheckedOrders([])

        setCheckedOrderWithBanks([])

        setTransactionNumber('')
        setTransactionStartDate(null)
        setTransactionEndDate(null)

        setMyStatus({ value: 'pending', label: 'Pending' })
        setDistributorValue(null)

        setCheckedDeposits([])

        setMyBank(null)

        setAvailableBalance(0)
        setCreditLimit(0)
        setBgAmount(0)
        setSpecialCredit(0)
        setAvailableCredit(0)
        setCreditExpense(0)

        setDetailsArray([])

        setPaymentUpdateId(null)

        setPaymentData([])

        setTotalOrder(0)

        setTotalDeposit(0)

        setBalance(0)

        setCheckedDeposits([])
        setFilteredDeposits([])
        setDepositList([])
        setDistributorOrderList([])

        setSelectedDoNo(0)
    }

    const statusList = [
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
    ]

    const handleSubmit = (event) => {
        console.log('distributorValue', distributorValue)
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.s
            topPropagation()
        }

        setValidated(true)
    }

    const fetchAllData = async () => {
        setIsLoadingDistributorOrderList(true)

        try {
            let response = await axios.get(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/group?search=${searchText}`, //all
                { headers: authHeader() }
            )
            // if (myStatus.value === "pending") {
            //     response = await axios.get(
            //         `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/approved?search=${searchText}`, //pending
            //         { headers: authHeader() }
            //     );

            // } else {
            //     response = await axios.get(
            //         `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/group?search=${searchText}`, //all
            //         { headers: authHeader() }
            //     );
            // }

            if (response.data.success || response.status === 200) {
                let result = response?.data?.payload?.result
                setAllOrderList(
                    result.filter((item) => !item?.data[0]?.superDBId)
                )

                if (myStatus.value === 'approved') {


                    // setDistributorOrderList(result.filter(item => !item?.data[0]?.superDBId && item.status === 7));

                    if (distributorValue && distributorValue.value) {
                        setDistributorOrderList(
                            result.filter(
                                (item) =>
                                    item?.dealerId === distributorValue.value &&
                                    !item?.data[0]?.superDBId &&
                                    item.status === 7
                            )
                        )
                    } else {
                        setDistributorOrderList(
                            result.filter(
                                (item) =>
                                    !item?.data[0]?.superDBId &&
                                    item.status === 7
                            )
                        )
                    }
                } else if (myStatus.value === 'pending') {
                    if (distributorValue && distributorValue.value) {
                        setDistributorOrderList(
                            result.filter(
                                (item) =>
                                    item?.dealerId === distributorValue.value &&
                                    !item?.data[0]?.superDBId &&
                                    item.status === 6
                            )
                        )
                    } else {
                        setDistributorOrderList(
                            result.filter(
                                (item) =>
                                    !item?.data[0]?.superDBId &&
                                    item.status === 6
                            )
                        )
                    }
                } else {
                    setDistributorOrderList(
                        result.filter(
                            (item) =>
                                !item?.data[0]?.superDBId && item.status === 6
                        )
                    )
                }
            }
        } catch (error) {
            // handleApiResponseErrors(error.response);
        } finally {
            setIsLoadingDistributorOrderList(false)
        }
    }

    useEffect(() => {
        const fetchAllDistributors = async () => {
            setIsLoading(true)

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?search=${searchText}`, //all
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.distributors
                    setDistributorList(
                        result
                            .filter((item) => !item?.superDBId)
                            .map((item) => ({
                                value: item.userId,
                                label:
                                    item.distributorName +
                                    ' (' +
                                    item.userId +
                                    ')',
                            }))
                    )
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }

        fetchAllDistributors()
    }, [count, searchText])

    const formatDate = (date) => {
        let date1 = new Date(date)
        let formattedDate1 = date1.toISOString()

        console.log(formattedDate1) // Output: 2024-08-13T18:00:00.000Z
        return formattedDate1
    }

    const fetchAllDepositData = async () => {
        setIsLoading(true)
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/getDepositsStatusAll?search=${searchText}`,
                { headers: authHeader() }
            )

            if (response.data.success || response.status === 200) {
                console.log('response', response)

                const data = response?.data?.payload?.result
                // const result = data.map((item, index) => { return { ...item, index: startIndex + index } })
                let result = data

                if (myStatus.value === 'approved') {
                    result = result.filter(
                        (item) => item?.status === 2 && !item?.superDBId
                    )

                    if (distributorValue && distributorValue.value) {
                        result = result.filter(
                            (item) =>
                                item?.dealerId === distributorValue.value &&
                                !item?.superDBId
                        )
                    } else {
                        result = result.filter((item) => !item?.superDBId)
                    }

                    if (transactionNumber) {
                        result = result.filter(
                            (item) => item?.trId === transactionNumber
                        )
                    }

                    if (transactionStartDate && transactionEndDate) {
                        result = result.filter(
                            (item) =>
                                item?.paymentDate >=
                                formatDate(transactionStartDate) &&
                                item?.paymentDate <=
                                formatDate(transactionEndDate)
                        )
                    }
                } else if (myStatus.value === 'pending') {
                    result = result.filter(
                        (item) => item?.status === 1 && !item?.superDBId
                    )

                    if (distributorValue && distributorValue.value) {
                        result = result.filter(
                            (item) =>
                                item?.dealerId === distributorValue.value &&
                                !item?.superDBId
                        )
                    } else {
                        result = result.filter((item) => !item?.superDBId)
                    }

                    if (transactionNumber) {
                        result = result.filter(
                            (item) => item?.trId === transactionNumber
                        )
                    }

                    if (transactionStartDate && transactionEndDate) {
                        result = result.filter(
                            (item) =>
                                item?.paymentDate >=
                                formatDate(transactionStartDate) &&
                                item?.paymentDate <=
                                formatDate(transactionEndDate)
                        )
                    }
                }

                setDepositList(result)
                setFilteredDeposits(result)
            }
        } catch (error) {
            // handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false)
        }
    }

    const handleSearch = (event) => {
        event.preventDefault()
        fetchAllDepositData()
        fetchAllData()
    }

    const orders = [
        { value: 1, label: 'Order 1' },
        { value: 2, label: 'Order 2' },
    ]

    useEffect(() => {
        console.log('Run', distributorOrderList)

        const fetchData = async () => {
            setIsLoadingDistributorOrderList(true)

            try {
                const response = await axios.get(
                    // `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/approved?search=${searchText}`, //pending
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/group?search=${searchText}`, //all
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.result
                    if (myStatus.value === 'approved') {
                        setDistributorOrderList(
                            result.filter(
                                (item) =>
                                    !item?.data[0]?.superDBId &&
                                    item?.status === 7
                            )
                        )
                    } else if (myStatus.value === 'pending') {
                        setDistributorOrderList(
                            result.filter(
                                (item) =>
                                    !item?.data[0]?.superDBId &&
                                    item?.status === 6
                            )
                        )
                    }
                    // setDistributorOrderList(result.filter(item => !item?.data[0]?.superDBId));
                    setAllOrderList(
                        result.filter((item) => !item?.data[0]?.superDBId)
                    )
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoadingDistributorOrderList(false)
            }
        }

        fetchData()
    }, [count, searchText])

    console.log('distributorOrderList', distributorOrderList)

    // deposit list
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            try {
                let response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositsApproved?search=${searchText}`,
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    console.log('response', response)

                    const data = response?.data?.payload?.result
                    // const result = data.map((item, index) => { return { ...item, index: startIndex + index } })
                    const result = data

                    setDepositList(result.filter((item) => !item?.superDBId))
                    setFilteredDeposits(
                        result.filter((item) => !item?.superDBId)
                    )
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [count, searchText])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                let url = `${process.env.REACT_APP_SETTINGS_BASE_URL}bank/bankList`

                let response = null

                response = await axios.post(url, {}, { headers: authHeader() })

                if (response.data.success) {
                    const data = response.data.payload?.companyBanks
                    console.log('data', data)

                    let result = []

                    result = data?.reduce((acc, currentItem) => {
                        const existingBank = acc.find(
                            (item) => item.bankName === currentItem.bankName
                        )
                        if (existingBank) {
                            existingBank.bankAccounts.push(
                                currentItem.bankAccountNo
                            )
                        } else {
                            acc.push({
                                bankName: currentItem?.bankName,
                                bankAccounts: [currentItem?.bankAccountNo],
                            })
                        }
                        return acc
                    }, [])

                    const bankList = result.map((item) => ({
                        value: item.bankName,
                        label: item.bankName,
                        bankAccounts: item?.bankAccounts?.map((account) => ({
                            value: account,
                            label: account,
                        })),
                    }))

                    setBanksList(bankList)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [count, searchText])

    console.log('depositList', depositList)

    const getBalance = async (dealerId) => {
        return await axios.get(
            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositsByDealer/${dealerId}`,
            { headers: authHeader() }
        )
    }

    const getAllOrdersWithBankByDB = async (dealerId) => {
        return await axios.get(
            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/getAllOrdersWithBankByDB/${dealerId}`,
            { headers: authHeader() }
        )
    }

    const getSingleDistributorInfo = async (dealerId) => {
        return await axios.get(
            `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dealer/${dealerId}`,
            { headers: authHeader() }
        )
    }

    const getSpecialCredit = async (dealerId, year, month) => {
        return await axios.get(
            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/TotalSCrByDb/${dealerId}/${month}/${year}`,
            { headers: authHeader() }
        )
    }

    useEffect(() => {
        const asyncCalls = async (dealerId) => {
            console.log('Called', dealerId)

            try {
                setIsLoading(true)
                let allDepositSum = 0
                let allExpensesSum = 0
                let crdLmt = 0
                let bgAmt = 0
                let crdExpense = 0
                let specialCrdt = 0

                const response1 = await getBalance(dealerId)
                const response2 = await getAllOrdersWithBankByDB(dealerId)
                const response3 = await getSingleDistributorInfo(dealerId)

                const year = new Date().getFullYear()
                const month = new Date().getMonth() + 1
                const response4 = await getSpecialCredit(dealerId, year, month)

                if (response1.data.success || response1.status === 200) {
                    const result = response1?.data?.payload?.result
                    // setAvailableBalance(result.reduce((a, b) => a + b.drBDT, 0));
                    allDepositSum = result
                        ?.filter((item) => item.status === 2)
                        ?.reduce((a, b) => a + b.drBDT, 0)
                }

                if (response2.data.success || response2.status === 200) {
                    const result = response2?.data?.payload?.result
                    // setAvailableBalance(result.reduce((a, b) => a + b.drBDT, 0));
                    allExpensesSum = result
                        ?.filter((item) => item.status === 2)
                        ?.reduce((a, b) => a + b.drBDT, 0)
                    crdExpense = result
                        ?.filter((item) => item.status === 2)
                        ?.reduce((a, b) => a + b.crBDT, 0)
                }

                if (response3.data.success || response3.status === 200) {
                    const result = response3?.data?.payload?.distributorData
                    crdLmt = result?.crBDTLimit
                    bgAmt = result?.bgAmount
                }

                if (response4.data.success || response4.status === 200) {
                    specialCrdt = response4?.data?.payload?.sCrBDT
                }

                setCreditLimit(crdLmt)
                setBgAmount(bgAmt)
                setSpecialCredit(specialCrdt)
                setCreditExpense(crdExpense)
                setAvailableCredit(crdLmt - crdExpense || 0)
                setAvailableBalance(allDepositSum - allExpensesSum)
            } catch (error) {
                console.log('error', error)
            } finally {
                setIsLoading(false)
            }
        }

        if (selectedDoNo) {
            // const deposits = depositList.filter(
            //     (deposit) => deposit.doNo === selectedDoNo
            // );

            const deposits = depositList
            console.log('deposits', deposits)

            setFilteredDeposits(deposits)
            // const totalDepositAmount = deposits.filter((deposit) => deposit.doNo === selectedDoNo).reduce((a, b) => a + b.drBDT, 0);
            const totalDepositAmount = checkedDeposits?.reduce(
                (a, b) => a + b.drBDT,
                0
            )
            setTotalDeposit(totalDepositAmount)
            setBalance(totalOrder - totalDepositAmount)

            // const selectedDealerId = distributorOrderList?.find(item => item.doNo === selectedDoNo)?.dealerId
            const selectedDealerId = allOrderList?.find(
                (item) => item.doNo === selectedDoNo
            )?.dealerId

            asyncCalls(selectedDealerId)
        }
    }, [selectedDoNo])

    useEffect(() => {
        console.log('availableCredit', availableCredit)
        console.log('availableBalance', availableBalance)
        console.log('creditLimit', creditLimit)
        console.log('creditExpense', creditExpense)
    }, [creditLimit, creditExpense, availableCredit, availableBalance])

    const handleSubmit3 = async (approval) => {
        // Prepare order items
        //flatten all the data array for checked orders in one array
        let orderItems = checkedOrders.map((order) => order.data).flat()

        orderItems = orderItems?.map((product) => {
            const orderItem = {
                // _id: product.productId,
                _id: product._id,
                status: approval === 'approved' ? 7 : 1,
            }

            return orderItem
        })

        // Make a PUT request to update the order items
        try {
            setIsLoading(true)

            const response = await axios.put(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                orderItems,
                { headers: authHeader() }
            )

            if (response.data.status || response.status === 200) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Order Successfully Approved!',
                    showConfirmButton: false,
                    timer: 1500,
                })

                // navigate("/finance/orders-list")
                // window.location.reload();
                resetAllStates()
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmit2 = async (approval) => {
        let totalPayableAmount = 0
        let upArr = []


        /* 
        
        Rejection Modal Implementation

        */
        // If the approval is rejected, ask the user for a reason for the rejection and update the order status to cancelled with the reason for rejection note.
        if (approval === 'rejected') {
            try {
                const { value: cancelNote } = await Swal.fire({
                    title: 'Reason for cancellation',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off',
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    showLoaderOnConfirm: true,
                    preConfirm: async (cancelNote) => {
                        try {
                            // Make a request to update the order status to cancelled with the reason for cancellation note provided by the user in the input field.
                        } catch (error) {
                            throw new Error(`Request failed: ${error}`)
                        }
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                })

                if (cancelNote) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Order has been successfully cancelled.',
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                })
            }
        }

        for (let i = 0; i < paymentData.length; i++) {
            const pay = paymentData[i]

            const totalPayAmount = pay.drBDT
            const totalDueAmount = pay.crBDT

            totalPayableAmount = totalPayAmount + totalDueAmount

            upArr.push(
                approval === 'approved'
                    ? {
                        _id: pay._id,
                        orderNumber: pay && pay?.doNo,
                        doNo: pay && pay?.doNo,
                        recordDate: new Date(),
                        crBDT: totalDueAmount,
                        drBDT: totalPayAmount,
                        status: 2,
                        paymentDate: new Date(),
                    }
                    : {
                        _id: pay._id,
                        orderNumber: pay && pay?.doNo,
                        doNo: pay && pay?.doNo,
                        recordDate: new Date(),
                        status: 3,
                        paymentDate: new Date(),
                    }
            )
        }

        // if (!(availableBalance >= totalPayableAmount)) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'You do not have enough balance to pay this amount!',
        //     });
        //     return
        // }

        if (checkedOrders.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select at least one order!',
            })
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/updateOrdersWithBank`,
                upArr,
                { headers: authHeader() }
            )
            if (response.data.success || response.status === 200) {
                await handleSubmit3(approval)
            } else {
                throw new Error('Failed to update payment data')
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading((prevState) => ({ ...prevState, general: false }))
        }
    }

    const handleApproveOrder = async (approval) => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure?',
            text: 'Approve the collection!',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#4c8118',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes,Approved it',
        })
        if (checkedDeposits.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select at least one deposit!',
            })
        }
        if (confirmResult.isConfirmed) {
            const deports = checkedDeposits.map((data) => {
                return {
                    ...data,
                    status: approval === 'approved' ? 2 : 3,
                    _id: data._id,
                }
            })

            try {
                setIsLoading(true)
                const response = await axios.post(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/updateDepositPaymentByFinnace`,
                    deports,
                    { headers: authHeader() }
                )

                // Check if the update was successful
                if (response.data.status || response.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Collection Successfully Approved!',
                        showConfirmButton: false,
                        timer: 1500,
                    })

                    // window.location.reload();
                    // resetAllStates();
                    handleSubmit2(approval)
                } else {
                    // Handle unexpected response
                    throw new Error('Deposit payment update failed')
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const isChecked = (order) => {
        return checkedOrders.some((p) => p.doNo === order.doNo)
    }

    useEffect(() => {
        if (checkedDeposits.length && allOrderList?.length) {
            const checkableOrders = allOrderList?.filter((item) =>
                checkedDeposits.find((item2) => item2.doNo === item.doNo)
            )

            console.log('checkedDeposits', checkedDeposits)

            console.log('checkableOrders', checkableOrders)

            setCheckedOrders(checkableOrders)

            setTotalOrder(
                (checkableOrders || [])
                    ?.filter((item) => item.totalOrderQtyCtn)
                    ?.reduce((a, b) => a + b.totalPrice, 0)
            )
        } else if (!checkedDeposits.length && allOrderList?.length) {
            setCheckedOrders([])
            setTotalOrder(0)
            setTotalDeposit(0)
            setBalance(0)
            setAvailableBalance(0)
            setCreditLimit(0)
            setCreditExpense(0)
            setBgAmount(0)
            setSelectedDoNo(null)
        }
    }, [checkedDeposits.length])

    return (
        <>
            <PageHeader
                title={`Order and Payment Approve`}
                className="mb-3"
            ></PageHeader>

            <Row className="g-3 mb-3">
                <Col lg={12} xl={12}>
                    <FalconComponentCard noPreview>
                        <FalconComponentCard.Header
                            // title="Form"
                            light={false}
                            noPreview
                        />
                        <FalconComponentCard.Body language="jsx">
                            {count && (
                                <Form
                                    noValidate
                                    validated={validated}
                                    // onSubmit={handleSubmit}
                                    onSubmit={handleSearch}
                                >
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleZip"
                                        >
                                            <Form.Label>
                                                Distributor Name
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributorList}
                                                placeholder="Select Distributor"
                                                // isMulti
                                                classNamePrefix="react-select"
                                                value={distributorValue}
                                                onChange={(value) =>
                                                    setDistributorValue(value)
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid zip.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Bank Name</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={banksList}
                                                placeholder="Select Bank"
                                                classNamePrefix="react-select"
                                                name="bankName"
                                                value={myBank}
                                                onChange={(selectedOption) => {
                                                    setMyBank(selectedOption)
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleZip"
                                        >
                                            <Form.Label>
                                                Transaction Number
                                            </Form.Label>
                                            <InputGroup>
                                                <FormControl
                                                    size="sm"
                                                    type="text"
                                                    className="shadow-none"
                                                    placeholder="Transaction number"
                                                    value={transactionNumber}
                                                    onChange={(ev) =>
                                                        setTransactionNumber(
                                                            ev.target.value
                                                        )
                                                    }
                                                    required
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid zip.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleZip"
                                        >
                                            <Form.Label>
                                                Transaction Start Date
                                            </Form.Label>

                                            <div className="datePickerContainer">
                                                <DatePicker
                                                    selected={
                                                        transactionStartDate
                                                    }
                                                    onChange={(date) =>
                                                        setTransactionStartDate(
                                                            date
                                                        )
                                                    }
                                                    formatWeekDay={(day) =>
                                                        day.slice(0, 3)
                                                    }
                                                    className="form-control"
                                                    placeholderText="Transaction Start Date"
                                                    popperPlacement="top-start"
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleZip"
                                        >
                                            <Form.Label>
                                                Transaction End Date
                                            </Form.Label>

                                            <div className="datePickerContainer">
                                                <DatePicker
                                                    selected={
                                                        transactionEndDate
                                                    }
                                                    onChange={(date) =>
                                                        setTransactionEndDate(
                                                            date
                                                        )
                                                    }
                                                    formatWeekDay={(day) =>
                                                        day.slice(0, 3)
                                                    }
                                                    className="form-control"
                                                    placeholderText="Transaction End Date"
                                                    // disabled={deliveryQty <= 0}
                                                    style={{
                                                        width: '100%!important',
                                                    }}
                                                    popperPlacement="top-start"
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleZip"
                                        >
                                            <Form.Label>Status</Form.Label>

                                            <Select
                                                closeMenuOnSelect={true}
                                                options={statusList}
                                                placeholder="Select Status"
                                                classNamePrefix="react-select"
                                                name="status"
                                                value={myStatus}
                                                onChange={(selectedOption) => {
                                                    setMyStatus(selectedOption)
                                                }}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid zip.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    {myStatus &&
                                        myStatus.value === 'pending' && (
                                            <>
                                                <Row className="mb-3">
                                                    <Form.Group
                                                        as={Col}
                                                        md="4"
                                                        controlId="exampleZip"
                                                    >
                                                        <Form.Label>
                                                            Total Order
                                                        </Form.Label>
                                                        <InputGroup>
                                                            <FormControl
                                                                size="sm"
                                                                type="text"
                                                                className="shadow-none"
                                                                placeholder="Total Order"
                                                                value={
                                                                    totalOrder
                                                                }
                                                                onChange={(
                                                                    ev
                                                                ) =>
                                                                    setTotalOrder(
                                                                        ev
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                                disabled
                                                            />
                                                        </InputGroup>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            valid zip.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group
                                                        as={Col}
                                                        md="4"
                                                        controlId="exampleZip"
                                                    >
                                                        <Form.Label>
                                                            Collection
                                                        </Form.Label>
                                                        <InputGroup>
                                                            <FormControl
                                                                size="sm"
                                                                type="text"
                                                                className="shadow-none"
                                                                placeholder="Collection"
                                                                value={
                                                                    totalDeposit
                                                                }
                                                                onChange={(
                                                                    ev
                                                                ) =>
                                                                    setTotalDeposit(
                                                                        ev
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                                disabled
                                                            />
                                                        </InputGroup>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            valid zip.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group
                                                        as={Col}
                                                        md="4"
                                                        controlId="exampleZip"
                                                    >
                                                        <Form.Label>
                                                            Difference
                                                        </Form.Label>
                                                        <InputGroup>
                                                            <FormControl
                                                                size="sm"
                                                                type="text"
                                                                className="shadow-none"
                                                                placeholder="Balance"
                                                                value={balance}
                                                                onChange={(
                                                                    ev
                                                                ) =>
                                                                    setBalance(
                                                                        ev
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                                disabled
                                                            />
                                                        </InputGroup>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            valid zip.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Form.Group
                                                        as={Col}
                                                        md="4"
                                                        controlId="exampleZip"
                                                    >
                                                        <Form.Label>
                                                            Available Balance
                                                        </Form.Label>
                                                        <InputGroup>
                                                            <FormControl
                                                                size="sm"
                                                                type="text"
                                                                className="shadow-none"
                                                                placeholder="Available Balance"
                                                                value={
                                                                    availableBalance
                                                                }
                                                                onChange={(
                                                                    ev
                                                                ) =>
                                                                    setAvailableBalance(
                                                                        ev
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                                disabled
                                                            />
                                                        </InputGroup>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            valid zip.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    {creditLimit ? (
                                                        <>
                                                            {' '}
                                                            <Form.Group
                                                                as={Col}
                                                                md="4"
                                                                controlId="exampleZip"
                                                            >
                                                                <Form.Label>
                                                                    Credit Limit
                                                                    (Chk+BG)
                                                                </Form.Label>
                                                                <InputGroup>
                                                                    <FormControl
                                                                        size="sm"
                                                                        type="text"
                                                                        className="shadow-none"
                                                                        placeholder="Credit Limit"
                                                                        value={
                                                                            creditLimit
                                                                        }
                                                                        onChange={(
                                                                            ev
                                                                        ) =>
                                                                            setCreditLimit(
                                                                                ev
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        required
                                                                        disabled
                                                                    />
                                                                </InputGroup>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please
                                                                    provide a
                                                                    valid zip.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group
                                                                as={Col}
                                                                md="4"
                                                                controlId="exampleZip"
                                                            >
                                                                <Form.Label>
                                                                    Credit
                                                                    Expense
                                                                </Form.Label>
                                                                <InputGroup>
                                                                    <FormControl
                                                                        size="sm"
                                                                        type="text"
                                                                        className="shadow-none"
                                                                        placeholder="Credit Expense"
                                                                        value={
                                                                            creditExpense
                                                                        }
                                                                        onChange={(
                                                                            ev
                                                                        ) =>
                                                                            setCreditExpense(
                                                                                ev
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        required
                                                                        disabled
                                                                    />
                                                                </InputGroup>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please
                                                                    provide a
                                                                    valid zip.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group
                                                                as={Col}
                                                                md="4"
                                                                controlId="exampleZip"
                                                            >
                                                                <Form.Label>
                                                                    Available
                                                                    Credit
                                                                </Form.Label>
                                                                <InputGroup>
                                                                    <FormControl
                                                                        size="sm"
                                                                        type="text"
                                                                        className="shadow-none"
                                                                        placeholder="Available Credit"
                                                                        value={
                                                                            availableCredit
                                                                        }
                                                                        onChange={(
                                                                            ev
                                                                        ) =>
                                                                            setAvailableCredit(
                                                                                ev
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        required
                                                                        disabled
                                                                    />
                                                                </InputGroup>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please
                                                                    provide a
                                                                    valid zip.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </>
                                                    ) : null}

                                                    <Form.Group
                                                        as={Col}
                                                        md="4"
                                                        controlId="exampleZip"
                                                    >
                                                        <Form.Label>
                                                            Special Credit
                                                        </Form.Label>
                                                        <InputGroup>
                                                            <FormControl
                                                                size="sm"
                                                                type="text"
                                                                className="shadow-none"
                                                                placeholder="Special Credit"
                                                                value={
                                                                    specialCredit
                                                                }
                                                                onChange={(ev) =>
                                                                    setSpecialCredit(ev.target.value)
                                                                }
                                                                required
                                                                disabled
                                                            />
                                                        </InputGroup>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            valid zip.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group
                                                        as={Col}
                                                        md="4"
                                                        controlId="exampleZip"
                                                    >
                                                        <Form.Label>
                                                            BG Amount
                                                        </Form.Label>
                                                        <InputGroup>
                                                            <FormControl
                                                                size="sm"
                                                                type="text"
                                                                className="shadow-none"
                                                                placeholder="BG Amount"
                                                                value={bgAmount}
                                                                onChange={(
                                                                    ev
                                                                ) =>
                                                                    setBgAmount(
                                                                        ev
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                                disabled
                                                            />
                                                        </InputGroup>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            valid zip.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                            </>
                                        )}

                                    <Button type="submit">Find</Button>
                                </Form>
                            )}
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
            </Row>

            <Card className="mt-3">
                <Tab.Container defaultActiveKey="collection">
                    <Card.Header className="p-0 bg-light overflow-hidden">
                        <SimpleBarReact>
                            <Nav className="nav-tabs tab-tickets-status flex-nowrap border-0">
                                <Nav.Item className="text-nowrap">
                                    <Nav.Link
                                        eventKey="order"
                                        className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                                    >
                                        <FontAwesomeIcon
                                            icon="shopping-cart"
                                            className="text-600"
                                        />
                                        <h6 className="mb-0 text-600">Order</h6>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="text-nowrap">
                                    <Nav.Link
                                        eventKey="collection"
                                        className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                                    >
                                        <FontAwesomeIcon
                                            icon="dollar-sign"
                                            className="text-600"
                                        />
                                        <h6 className="mb-0 text-600">
                                            Collection
                                        </h6>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </SimpleBarReact>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <Tab.Content>
                            <Tab.Pane eventKey="order">
                                {isLoadingDistributorOrderList ? (
                                    <h3>Loading...</h3>
                                ) : (
                                    <Row className="g-3 mb-3">
                                        <Col lg={12} xl={12}>
                                            {distributorOrderList.length ===
                                                0 ? (
                                                <div className="p-3">
                                                    {' '}
                                                    <h4>No Data Found</h4>{' '}
                                                </div>
                                            ) : (
                                                <TabContentTable
                                                    distributorValue={
                                                        distributorValue
                                                    }
                                                    myStatus={myStatus}
                                                    handleSubmit2={
                                                        handleSubmit2
                                                    }
                                                    paymentData={paymentData}
                                                    setPaymentData={
                                                        setPaymentData
                                                    }
                                                    viewDetails={viewDetails}
                                                    detailsArray={detailsArray}
                                                    setSelectedDoNo={
                                                        setSelectedDoNo
                                                    }
                                                    selectedDoNo={selectedDoNo}
                                                    setTotalOrder={
                                                        setTotalOrder
                                                    }
                                                    setCheckedOrders={
                                                        setCheckedOrders
                                                    }
                                                    checkedOrders={
                                                        checkedOrders
                                                    }
                                                    orders={
                                                        distributorOrderList
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="collection">
                                {isLoading ? (
                                    <h3>Loading...</h3>
                                ) : (
                                    <Row className="g-3 mb-3">
                                        <Col lg={12} xl={12}>
                                            {filteredDeposits.length === 0 ? (
                                                <div className="p-3">
                                                    {' '}
                                                    <h4>No Data Found</h4>{' '}
                                                </div>
                                            ) : (
                                                <TabContentTableDeposit
                                                    distributorValue={
                                                        distributorValue
                                                    }
                                                    selectedDoNo={selectedDoNo}
                                                    viewDetailsByCollection={
                                                        viewDetailsByCollection
                                                    }
                                                    viewDetailsByCollections={
                                                        viewDetailsByCollections
                                                    }
                                                    myStatus={myStatus}
                                                    handleApproveOrder={
                                                        handleApproveOrder
                                                    }
                                                    checkedDeposits={
                                                        checkedDeposits
                                                    }
                                                    setCheckedDeposits={
                                                        setCheckedDeposits
                                                    }
                                                    deposits={filteredDeposits}
                                                    orders={
                                                        distributorOrderList
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            </Tab.Pane>
                        </Tab.Content>
                    </Card.Body>
                </Tab.Container>
            </Card>
        </>
    )
}

export default OrderPaymentApprove
