import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const BrandForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [brandByID, setBrandById] = useState({});
    const { updateID } = useParams();
    const navigate = useNavigate();


    // GET BY ID
    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productBrand/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success) {
                        setBrandById(response?.data?.payload.result);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);


    const handleSubmitForm = (values, actions) => {
        const handleSubmitData = {
            name: values.name
        };

        setIsLoading(true);
        const url = updateID
            ? `${process.env.REACT_APP_SETTINGS_BASE_URL}productBrand/${updateID}`
            : `${process.env.REACT_APP_SETTINGS_BASE_URL}productBrand`;

        const axiosMethod = updateID ? axios.patch : axios.post;

        axiosMethod(url, handleSubmitData, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    navigate("/master/brand");
                    toast.success(response.data.message);
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };



    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({

        name: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please Enter a value for the Name field").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            })



    });



    return (
        <>
            <PageHeader
                title={updateID ? "Update Brand" : "Add New Brand"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{

                            name: brandByID?.name || '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">


                                        <Form.Group as={Col} md="12" controlId="exampleState">
                                            <Form.Label>Brand Name</Form.Label>
                                            <Form.Control
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Brand Name"
                                                required
                                                value={values.name}
                                            />
                                            {touched.name && errors.name && (
                                                <div style={{ color: "red" }}>
                                                    {errors.name}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"

                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/brand")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default BrandForm;


