import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card, Dropdown, Modal, Row, Col, Form } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import useUserRole from 'hooks/useUserRole'
import { useDropzone } from 'react-dropzone'
import FalconCloseButton from 'components/common/FalconCloseButton'
import cloudUpload from 'assets/img/icons/cloud-upload.svg'
import { authHeaderForm } from 'state/ducs/auth/utils'
import { formatNumber } from 'helpers/utils';
import FalconComponentCard from 'components/common/FalconComponentCard';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';




const InventoryStockListFilterDateWise = () => {
    const [selectedFromDate, setSelectedFromDate] = useState(new Date());
    const [selectedToDate, setSelectedToDate] = useState(new Date());
    const [receivedList, setReceivedList] = useState([])
    const [receivedListCurrent, setReceivedListCurrent] = useState([])
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)


    const [month, setMonth] = useState(null);

    const currentYear = new Date().getFullYear();


    const [pageDataCount, setPageDataCount] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [showUploadCSV, setShowUploadCSV] = useState(false)
    const [count, setCount] = useState(0)
    const userInfo = useSelector((state) => state.auth.user?.data)
    const userRole = useUserRole()

    const date = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(date.setDate(date.getDate() + 7));
    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };


    // useEffect(() => {
    //     const startIndex = (paginationPageCount - 1) * pageDataCount + 1

    //     const fetchData = async () => {
    //         let url
    //         let url2
    //         let url3
    //         if (userInfo?.userRole === 'Admin') {
    //             url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
    //             url2 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`
    //             url3 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${userInfo.factoryName}`
    //         }
    //         if (userInfo?.userRole === 'Inventory') {
    //             url =
    //                 searchText !== ''
    //                     ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
    //                     : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
    //             url2 =
    //                 searchText !== ''
    //                     ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`
    //                     : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`

    //             url3 =
    //                 searchText !== ''
    //                     ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${userInfo.factoryName}`
    //                     : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${userInfo.factoryName}`
    //         }

    //         try {
    //             const response = await axios.get(url, { headers: authHeader() })

    //             if (response.data.success || response.status === 200) {
    //                 const totalPages =
    //                     response?.data?.payload?.pagination?.totalPages
    //                 const result = response?.data?.payload?.result?.map(
    //                     (element, index) => ({
    //                         ...element,
    //                         index: startIndex + index,
    //                     })
    //                 )

    //                 setTotalPages(totalPages)
    //                 setReceivedList(result)

    //                 const response2 = await axios.get(url2, {
    //                     headers: authHeader(),
    //                 })

    //                 if (response2.data.success || response2.status === 200) {
    //                     const totalPages2 =
    //                         response2?.data?.payload?.pagination?.totalPages
    //                     const result2 = response2?.data?.payload?.result?.map(
    //                         (element, index) => ({
    //                             ...element,
    //                             index: startIndex + index,
    //                         })
    //                     )



    //                     const modifiedResult = result.map((item) => ({
    //                         ...item,
    //                         delivery: result2?.find(
    //                             (my) => my.skuName == item.skuName
    //                         ),
    //                     }))

    //                     setTotalPages(totalPages)
    //                     console.log("modifiedResult", modifiedResult)
    //                     setReceivedList(modifiedResult)

    //                 }


    //             }
    //         } catch (error) {
    //         } finally {
    //             setIsLoading(false)
    //         }
    //     }

    //     fetchData()
    // }, [count, paginationPageCount, pageDataCount, searchText])


    if (isLoading) {
        return <LoadingIcon />
    }

    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'productCategory',
            Header: 'Category',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { productCategory } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{productCategory}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'skuName',
            Header: 'skuName',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { skuName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{skuName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'erpId',
            Header: 'ERP ID',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { erpId } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{erpId}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyCTN',
            Header: 'Rcv Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyCTN } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{rcvQtyCTN}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyPCS',
            Header: 'Rcv Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyPCS } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyPCS)}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyMt',
            Header: 'Rcv Qty MT',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyMt } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyMt)}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'delQtyCTN',
            Header: 'Del Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delivery } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {delivery?.delQtyCtn || 0}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'delQtyPCS',
            Header: 'Del Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delivery } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {delivery?.delQtyPcs || 0}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'delQtyMt',
            Header: 'Del Qty MT',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delivery } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {formatNumber(delivery?.delQtyMt || 0)}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },


        {
            accessor: 'balQtyCTN',
            Header: 'Balance Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                // const { delivery, rcvQtyCTN, transfer } = rowData.row.original
                const { delivery, rcvQtyCTN } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {/* {rcvQtyCTN - (delivery?.delQtyCtn || 0) - (transfer?.rcvQtyCTN || 0)} */}
                                {rcvQtyCTN - (delivery?.delQtyCtn || 0)}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'balQtyPCS',
            Header: 'Balance Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delivery, rcvQtyPCS } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {/* {rcvQtyPCS - (delivery?.delQtyPcs || 0) - (transfer?.rcvQtyPCS || 0)} */}
                                {rcvQtyPCS - (delivery?.delQtyPcs || 0)}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'balQtyMT',
            Header: 'Balance Qty MT',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                // const { delivery, rcvQtyMt, transfer } = rowData.row.original
                const { delivery, rcvQtyMt } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {/* {rcvQtyMt - (delivery?.delQtyMt || 0) - (transfer?.rcvQtyMt || 0)} */}
                                {formatNumber(rcvQtyMt - (delivery?.delQtyMt || 0))}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
     
    ]

    /*******************
    Columns End.
    *******************/

    const handleSearch = async () => {

        const startIndex = (paginationPageCount - 1) * pageDataCount + 1

        const fromDate = `${selectedFromDate.getFullYear()}-${selectedFromDate.getMonth() + 1}-${selectedFromDate.getDate()}`;
        const toDate = `${selectedToDate.getFullYear()}-${selectedToDate.getMonth() + 1}-${selectedToDate.getDate()}`;

        let url
        let url2
        url =
            searchText !== ''
                ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProdRcvFilterDateRange/${userInfo.factoryName}/${fromDate}/${toDate}`
                : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProdRcvFilterDateRange/${userInfo.factoryName}/${fromDate}/${toDate}`

        url2 =
            searchText !== ''
                ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProdDeliveryFilterDateRange/${userInfo.factoryName}/${fromDate}/${toDate}`
                : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProdDeliveryFilterDateRange/${userInfo.factoryName}/${fromDate}/${toDate}`


        try {
            const response = await axios.get(url, { headers: authHeader() })

            if (response.data.success || response.status === 200) {

                const totalPages =
                    response?.data?.payload?.pagination?.totalPages
                const result = response?.data?.payload?.productionRcv?.map(
                    (element, index) => ({
                        ...element,
                        index: startIndex + index,
                    })
                )

                setTotalPages(totalPages)

                setReceivedList(result)

                const response2 = await axios.get(url2, {
                    headers: authHeader(),
                })

                if (response2.data.success || response2.status === 200) {
                    const totalPages2 =
                        response2?.data?.payload?.pagination?.totalPages
                    const result2 = response2?.data?.payload?.deliveries?.map(
                        (element, index) => ({
                            ...element,
                            index: startIndex + index,
                        })
                    )



                    const modifiedResult = result.map((item) => ({
                        ...item,
                        delivery: result2?.find(
                            (my) => my.skuName == item.skuName
                        ),
                    }));


                    setTotalPages(totalPages)
                    console.log("modifiedResult", modifiedResult)
                    setReceivedList(modifiedResult)
                }
            }
        } catch (error) {
            // handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false)
        }


        const url3 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProdRcvFilterDateRange/${userInfo.factoryName}/${fromDate}/${toDate}`
        const url4 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProdRcvFilterDateRange/${userInfo.factoryName}/${fromDate}/${toDate}`


        try {

            const response = await axios.get(url3, { headers: authHeader() })

            if (response.data.success || response.status === 200) {

                const totalPages =
                    response?.data?.payload?.pagination?.totalPages
                const result = response?.data?.payload?.productionRcv?.map(
                    (element, index) => ({
                        ...element,
                        index: startIndex + index,
                    })
                )

                setTotalPages(totalPages)

                setReceivedListCurrent(result)

                const response2 = await axios.get(url4, {
                    headers: authHeader(),
                })

                if (response2.data.success || response2.status === 200) {
                    const totalPages2 =
                        response2?.data?.payload?.pagination?.totalPages
                    const result2 = response2?.data?.payload?.deliveries?.map(
                        (element, index) => ({
                            ...element,
                            index: startIndex + index,
                        })
                    )



                    const modifiedResult = result.map((item) => ({
                        ...item,
                        delivery: result2?.find(
                            (my) => my.skuName == item.skuName
                        ),
                    }));


                    setTotalPages(totalPages)
                    console.log("modifiedResult", modifiedResult)
                    setReceivedListCurrent(modifiedResult)



                }


            }


        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }

    const months = [
        {
            value: 1,
            label: 'January'
        },
        {
            value: 2,
            label: 'February'
        },
        {
            value: 3,
            label: 'March'
        },
        {
            value: 4,
            label: 'April'
        },
        {
            value: 5,
            label: 'May'
        },
        {
            value: 6,
            label: 'June'
        },
        {
            value: 7,
            label: 'July'
        },
        {
            value: 8,
            label: 'August'
        },
        {
            value: 9,
            label: 'September'
        },
        {
            value: 10,
            label: 'October'
        },
        {
            value: 11,
            label: 'November'
        },
        {
            value: 12,
            label: 'December'
        }
    ]


    const openingBalance = receivedList.reduce((acc, item) => {
        return acc + (item.rcvQtyCTN - (item.delivery?.delQtyCtn || 0))
    }, 0);

    const openingBalancePCS = receivedList.reduce((acc, item) => {
        return acc + (item.rcvQtyPCS - (item.delivery?.delQtyPcs || 0))
    }, 0);

    const openingBalanceMT = receivedList.reduce((acc, item) => {
        return acc + (item.rcvQtyMt - (item.delivery?.delQtyMt || 0))
    }, 0);


    const closingBalance = receivedListCurrent.reduce((acc, item) => {
        return acc + (item.rcvQtyCTN - (item.delivery?.delQtyCtn || 0))
    }, 0);

    const closingBalancePCS = receivedListCurrent.reduce((acc, item) => {
        return acc + (item.rcvQtyPCS - (item.delivery?.delQtyPcs || 0))
    }, 0);

    const closingBalanceMT = receivedListCurrent.reduce((acc, item) => {
        return acc + (item.rcvQtyMt - (item.delivery?.delQtyMt || 0))
    }, 0);

    const formatData = () => {
        const dataFormat = receivedListCurrent.map((item, i) => {
            const formatDate = (date) => {
                const parsedDate = new Date(date)
                return !isNaN(parsedDate.getTime())
                    ? parsedDate.toISOString().split('T')[0]
                    : 'Invalid Date'
            }
            return {
                SL: i + 1,
                'Category': item.productCategory,
                'Sub Category':item.subCategory||0,
                'SKU Name': item.skuName,
                'ERP ID': item.erpId,
                'Rcv Qty CTN': item.rcvQtyCTN,
                'Rcv Qty PCS': item.rcvQtyPCS,
                'Rcv Qty MT': item.rcvQtyMt,
                'Del Qty CTN': item?.delivery?.delQtyCtn || 0,
                'Del Qty PCS': item?.delivery?.delQtyPcs || 0,
                'Del Qty MT': item?.delivery?.delQtyMt || 0,
                'Bal Qty CTN': item?.rcvQtyCTN - (item?.delivery?.delQtyCtn || 0),
                'Bal Qty PCS': item?.rcvQtyPCS - (item?.delivery?.delQtyPcs || 0),
                'Bal Qty MT': item?.rcvQtyMt - (item?.delivery?.delQtyMt || 0),
            }
        })
        return dataFormat
    }


    return (
        <>
            {/* <PageHeader
                title="Stock List Filter"
                className="mb-3"
            ></PageHeader> */}
            <Row className="mb-3 g-3">
                <Col lg={12}>
                    <FalconComponentCard noGuttersBottom>
                        <FalconComponentCard.Header noPreview title="Stock List Filter (Date Wise)" />
                        <FalconComponentCard.Body

                        >
                            {/* <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                formatWeekDay={day => day.slice(0, 3)}
                                endDate={endDate}
                                selectsRange
                                dateFormat="MMM dd"
                                className='form-control'
                            /> */}

                            <div className='d-flex align-items-center '>

                                <Col md="4" className='d-flex justify-content-center align-items-center gap-2'>
                                    <Form.Label>From Date:</Form.Label>
                                    <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                        <DatePicker
                                            selected={selectedFromDate}
                                            onChange={(date) => setSelectedFromDate(date)}
                                            // dateFormat=""
                                            // showMonthYearPicker
                                            placeholderText="Select From Date"
                                            className="form-control w-100"
                                            width="100%"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="4" className='d-flex justify-content-center align-items-center gap-2'>
                                    <Form.Label>To Date:</Form.Label>
                                    <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                        <DatePicker
                                            selected={selectedToDate}
                                            onChange={(date) => setSelectedToDate(date)}
                                            // dateFormat=""
                                            // showMonthYearPicker
                                            placeholderText="Select To Date"
                                            className="form-control w-100"
                                            width="100%"
                                        />
                                    </Form.Group>
                                </Col>

                                <Button
                                    onClick={handleSearch}
                                    variant="primary"
                                    className="ms-2"
                                    style={{ width: '188px' }}

                                >
                                    Search
                                </Button>

                            </div>


                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>

            </Row>

            <AdvanceTableWrapper
                columns={columns}
                data={receivedListCurrent}
                sortable
                pagination
                perPage={1500050}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            // isSearch={true}
                            // setSearchText={setSearchText}
                            title={"Opening Balance (CTN): " + openingBalance + ", Opening Balance (PCS): " + openingBalancePCS + ", Opening Balance (MT): " + formatNumber(openingBalanceMT)}
                            table
                            excelFileName=" Stock List Date Wish"
                            excelData={formatData()}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        {/* show Data in table */}
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead>
                                    <tr
                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#354151',
                                        }}
                                    >
                                        <th className="align-middle white-space-nowrap text-center ">
                                            No.
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Sub Category
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            SKU Name
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            ERP ID
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Rcv Qty CTN
                                        </th>

                                        <th className="align-middle white-space-nowrap text-center ">
                                            Rcv Qty PCS
                                        </th>

                                        <th className="align-middle white-space-nowrap text-center ">
                                            Rcv Qty MT
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Del Qty CTN
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Del Qty PCS
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Del Qty MT
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Bal Qty CTN
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Bal Qty PCS
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Bal Qty MT
                                        </th>

                                    </tr>
                                </thead>
                                <tbody
                                    className="list"
                                    id="table-purchase-body"
                                >
                                    {receivedListCurrent?.map(
                                        (order, index) => {
                                            return (
                                                <>
                                                    <tr
                                                        key={index}
                                                        style={{
                                                            fontSize: 'medium',
                                                            fontWeight: '500',
                                                            fontFamily: 'Poppins',
                                                            color: '#354151',
                                                        }}
                                                    >
                                                        <td className="align-middle text-center">
                                                            {index + 1}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {order?.productCategory}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {order?.subCategory}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {order?.skuName}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {order?.erpId}
                                                        </td>

                                                        <td className="align-middle text-center">
                                                            {order?.rcvQtyCTN}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {formatNumber(order?.rcvQtyPCS)}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {formatNumber(order?.rcvQtyMt)}
                                                        </td>

                                                        <td className="align-middle text-center">
                                                            {order?.delivery?.delQtyCtn || 0}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {formatNumber(order?.delivery?.delQtyPcs || 0)}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {order?.delivery?.delQtyMt || 0}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {order?.rcvQtyCTN - (order?.delivery?.delQtyCtn || 0)}
                                                        </td>

                                                        <td className="align-middle text-center">
                                                            {formatNumber(order?.rcvQtyPCS - (order?.delivery?.delQtyPcs || 0))}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {formatNumber(order?.rcvQtyMt - (order?.delivery?.delQtyMt || 0))}
                                                        </td>


                                                    </tr>


                                                </>
                                            )
                                        }
                                    )}
                                    {/* Totals Row */}
                                    <tr
                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '700',
                                            fontFamily: 'Poppins',
                                            color: '#354151',
                                        }}
                                    >
                                        <td className="align-middle text-center" colSpan={5}>Total</td>
                                        <td className="align-middle text-center">
                                            {receivedListCurrent?.reduce((acc, order) => acc + (order?.rcvQtyCTN || 0), 0)}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(receivedListCurrent?.reduce((acc, order) => acc + (order?.rcvQtyPCS || 0), 0))}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(receivedListCurrent?.reduce((acc, order) => acc + (order?.rcvQtyMt || 0), 0))}
                                        </td>
                                        <td className="align-middle text-center">
                                            {receivedListCurrent?.reduce((acc, order) => acc + (order?.delivery?.delQtyCtn || 0), 0)}
                                        </td>
                                        <td className="align-middle text-center">
                                            {receivedListCurrent?.reduce((acc, order) => acc + (order?.delivery?.delQtyPcs || 0), 0)}
                                        </td>
                                        <td className="align-middle text-center">
                                            {receivedListCurrent?.reduce((acc, order) => acc + (order?.delivery?.delQtyMt || 0), 0)}
                                        </td>
                                        <td className="align-middle text-center">
                                            {receivedListCurrent?.reduce((acc, order) => acc + (order?.rcvQtyCTN - (order?.delivery?.delQtyCtn || 0)), 0)}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(receivedListCurrent?.reduce((acc, order) => acc + (order?.rcvQtyPCS - (order?.delivery?.delQtyPcs || 0)), 0))}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(receivedListCurrent?.reduce((acc, order) => acc + (order?.rcvQtyMt - (order?.delivery?.delQtyMt || 0)), 0))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        {/* <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} /> */}
                        Closing Balance (CTN): {closingBalance}, Closing Balance (PCS): {closingBalancePCS}, Closing Balance (MT): {formatNumber(closingBalanceMT)}

                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>


        </>
    )
}

export default InventoryStockListFilterDateWise
