import handleApiResponseErrors from 'helpers/handleApiResponse'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { Formik } from 'formik'
import axios from 'axios'
import { authHeader } from 'utils'
import { useSelector } from 'react-redux'

const SalesOrderFilters = ({
    selectedZone,
    selectedRegion,
    selectedArea,
    selectedDbPoint,
    setSelectedZone,
    setSelectedRegion,
    setSelectedArea,
    setSelectedDbPoint,
    setSelectedDb,
    selectedDb,
    setSelectedDoNo,
    selectedDoNo,
    selectedDateTo,
    selectedDateFrom,
    setSelectedDateFrom,
    setSelectedDateTo,
    selectedStatus,
    setSelectedStatus,
    hoseSearch,
    handleSearch,
}) => {
    const [allZone, setAllZone] = useState([])
    const [allRegion, setAllRegion] = useState([])
    const [allArea, setAllArea] = useState([])
    const [allDbPoint, setAllDbPoint] = useState([])
    const [distributors, setDistributors] = useState([])
    const [disableDates, setDisableDates] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const userInfo = useSelector((state) => state?.auth?.user?.data)

    const handleClear = () => {
        setSelectedZone('')
        setSelectedRegion('')
        setSelectedArea('')
        setSelectedDbPoint('')
        setSelectedDb('')
        setSelectedDoNo('')
        setSelectedDateFrom('')
        setSelectedDateTo('')
        setSelectedStatus('')
    }

    const statusLookup = [
        {
            id: 1,
            label: 'Canceled',
            value: 'Canceled',
        },
        { id: 2, label: 'Pending', value: 'Pending' },
        { id: 3, label: 'Forwarded by ASM', value: 'Forwarded by ASM' },
        { id: 4, label: 'Forwarded by RSM', value: 'Forwarded by RSM' },
        { id: 5, label: 'Forwarded by SIO', value: 'Forwarded by SIO' },
        {
            id: 6,
            label: 'Forwarded by Order Management',
            value: 'Forwarded by Order Management',
        },
        { id: 7, label: 'Forwarded by Finance', value: 'Forwarded by Finance' },
    ]
    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(
                        (data) => ({
                            id: data._id,
                            label: data.name,
                            value: data.name,
                        })
                    )

                    setAllZone(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (selectedZone) {
            setIsLoading(true)
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.regionName,
                                value: data.regionName,
                            })
                        )

                        setAllRegion(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading(false)
                }
            }

            fetchData()
        }
    }, [selectedZone])

    useEffect(() => {
        if (selectedRegion) {
            setIsLoading(true)
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}area?regionName=${selectedRegion}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.areaName,
                                value: data.areaName,
                            })
                        )

                        setAllArea(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading(false)
                }
            }

            fetchData()
        }
    }, [selectedRegion])

    useEffect(() => {
        if (selectedArea) {
            setIsLoading(true)
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.dbPointName,
                                value: data.dbPointName,
                            })
                        )
                        setAllDbPoint(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading(false)
                }
            }
            fetchData()
        }
    }, [selectedArea])

    useEffect(() => {
        if (selectedDbPoint) {
            const fetchData = async () => {
                setIsLoading(true)
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?zoneName=${selectedZone}&regionName=${selectedRegion}&areaName=${selectedArea}&dbPoint=${selectedDbPoint}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })
                    if (response.data.success || response.status === 200) {
                        const modifyData =
                            response?.data?.payload?.distributors.map(
                                (data) => ({
                                    id: data.userId,
                                    label: data.distributorName,
                                    value: data.userId,
                                    phoneNumber: data.phoneNumber,
                                    userRole: data.userRole,
                                })
                            )
                        setDistributors(modifyData)
                    }
                } catch (error) {
                    // handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading(false)
                }
            }
            fetchData()
        }
    }, [selectedDbPoint])

    return (
        <>
            <Card className="mb-3 p-2">
                <Formik initialValues={{}} validationSchema={{}} onSubmit={{}}>
                    {({
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        setFieldValue,
                        setFieldTouched,
                    }) => (
                        <Form>
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleFirstName"
                                >
                                    <Form.Label>Select Zone</Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={allZone}
                                        placeholder={
                                            isLoading
                                                ? 'loading...'
                                                : 'Select Zone'
                                        }
                                        classNamePrefix="react-select"
                                        name="zoneName"
                                        value={
                                            selectedZone
                                                ? {
                                                      label: selectedZone,
                                                      value: selectedZone,
                                                  }
                                                : ''
                                        }
                                        onChange={(selectedOption) => {
                                            setSelectedZone(
                                                selectedOption.value
                                            )
                                        }}
                                        isLoading={isLoading}
                                    />
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleFirstName"
                                >
                                    <Form.Label>Select Region</Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={allRegion}
                                        placeholder={
                                            isLoading
                                                ? 'loading...'
                                                : 'Select Region'
                                        }
                                        classNamePrefix="react-select"
                                        name="regionName"
                                        value={
                                            selectedRegion
                                                ? {
                                                      label: selectedRegion,
                                                      value: selectedRegion,
                                                  }
                                                : ''
                                        }
                                        onChange={(selectedOption) => {
                                            setSelectedRegion(
                                                selectedOption.value
                                            )
                                        }}
                                        isLoading={isLoading}
                                    />
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleFirstName"
                                >
                                    <Form.Label>Select Area</Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={allArea}
                                        placeholder={
                                            isLoading
                                                ? 'loading...'
                                                : 'Select Area'
                                        }
                                        classNamePrefix="react-select"
                                        name="areaName"
                                        value={
                                            selectedArea
                                                ? {
                                                      label: selectedArea,
                                                      value: selectedArea,
                                                  }
                                                : ''
                                        }
                                        onChange={(selectedOption) => {
                                            setSelectedArea(
                                                selectedOption.value
                                            )
                                        }}
                                        isLoading={isLoading}
                                    />
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleFirstName"
                                >
                                    <Form.Label>Select DB Point</Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={allDbPoint}
                                        placeholder={
                                            isLoading
                                                ? 'loading...'
                                                : 'Select DB Point'
                                        }
                                        classNamePrefix="react-select"
                                        name="dbPointName"
                                        value={
                                            selectedDbPoint
                                                ? {
                                                      label: selectedDbPoint,
                                                      value: selectedDbPoint,
                                                  }
                                                : ''
                                        }
                                        onChange={(selectedOption) => {
                                            setSelectedDbPoint(
                                                selectedOption.value
                                            )
                                        }}
                                        isLoading={isLoading}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleFirstName"
                                >
                                    <Form.Label>Select Distributor</Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={distributors}
                                        placeholder={
                                            isLoading
                                                ? 'loading...'
                                                : 'Select Distributor'
                                        }
                                        classNamePrefix="react-select"
                                        name="distributorName"
                                        value={
                                            selectedDb
                                                ? {
                                                      label: selectedDb.label,
                                                      value: selectedDb.value,
                                                  }
                                                : ''
                                        }
                                        onChange={(selectedOption) => {
                                            setSelectedDb(selectedOption)
                                        }}
                                        isLoading={isLoading}
                                        disabled={disableDates}
                                    />
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleFirstName"
                                >
                                    <Form.Label>DO NO</Form.Label>
                                    <Form.Control
                                        placeholder={
                                            isLoading ? 'loading...' : 'DO NO'
                                        }
                                        type="text"
                                        name="doNo"
                                        value={selectedDoNo}
                                        onChange={(e) =>
                                            setSelectedDoNo(e.target.value)
                                        }
                                        isLoading={isLoading}
                                    />
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleState"
                                >
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        name="doDateFrom"
                                        type="date"
                                        required
                                        value={selectedDateFrom}
                                        onChange={(e) =>
                                            setSelectedDateFrom(e.target.value)
                                        }
                                        isLoading={isLoading}
                                        disabled={selectedDoNo}
                                    />
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleState"
                                >
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control
                                        name="doDateTo"
                                        type="date"
                                        required
                                        value={selectedDateTo}
                                        onChange={(e) =>
                                            setSelectedDateTo(e.target.value)
                                        }
                                        isLoading={isLoading}
                                        disabled={selectedDoNo}
                                    />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleFirstName"
                                >
                                    <Form.Label>
                                        Select Delivery Status
                                    </Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={statusLookup}
                                        placeholder={
                                            isLoading
                                                ? 'loading...'
                                                : 'Select Delivery Status'
                                        }
                                        classNamePrefix="react-select"
                                        name="deliveryList"
                                        value={
                                            selectedStatus
                                                ? statusLookup.find(
                                                      (option) =>
                                                          option.id ===
                                                          selectedStatus
                                                  )
                                                : null
                                        }
                                        onChange={(selectedOption) => {
                                            setSelectedStatus(selectedOption.id)
                                        }}
                                        isLoading={isLoading}
                                    />
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md="2"
                                    controlId="exampleState"
                                >
                                    <Button
                                        onClick={handleSearch}
                                        variant="primary"
                                        className="mt-4"
                                        style={{ width: '188px' }}
                                        disabled={
                                            selectedZone == '' &&
                                            selectedDoNo == '' &&
                                            selectedDateFrom == '' &&
                                            selectedDateTo == '' &&
                                            selectedStatus == ''
                                        }
                                    >
                                        Search
                                    </Button>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleState"
                                >
                                    <Button
                                        onClick={handleClear}
                                        variant="danger"
                                        className="mt-4"
                                        style={{ width: '188px' }}
                                    >
                                        Clear
                                    </Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Card>
        </>
    )
}

export default SalesOrderFilters
