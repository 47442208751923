import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { useSelector } from "react-redux";

const DiscountForm = () => {
    const [selectedZone, setSelectedZone] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedArea, setSelectedArea] = useState('')
    const [selectedDBPoint, setSelectedDBPoint] = useState('')
    const [zoneList, setZoneList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [dbPointList, setDbPointList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [discountById, setDiscountById] = useState([]);
    const [discountTypeList, setDiscountTypeList] = useState([]);
    const [isLoading, setIsLoading] = useState({
        general: false,
        zone: false,
        region: false,
        area: false,
        dbPoint: false,
        distributor: false,
        category: false,
        product: false,

    });  // for loading icon
    const { updateID } = useParams();
    const navigate = useNavigate();
    const { companyName } = useSelector((state) => state.auth.user.data);


    //  Date Format
    function formatDateToYYYYMMDD(isoDateString) {
        const date = new Date(isoDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }


    const statusOptions = [
        {
            id: true,
            label: "Active",
            value: "true"
        }, {
            id: false,
            label: "Inactive",
            value: "false"
        }
    ]

    const specialOptions = [
        {
            label: "Special",
            value: "PCS"
        }, {
            label: "Non Special",
            value: "CTN"
        }
    ]



    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(prevState => ({ ...prevState, general: true }));
                try {
                    const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success) {
                        setDiscountById(response?.data?.payload?.discount);
                        setSelectedZone(response?.data?.payload?.discount?.zoneName)
                        setSelectedRegion(response?.data?.payload?.discount?.regionName)
                        setSelectedArea(response?.data?.payload?.discount?.areaName)
                        setSelectedCategory(response?.data?.payload?.discount?.productCategory)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, general: false}));
                }
            }
        };

        fetchData();
    }, [updateID]);




    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, general: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}discountType`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setDiscountTypeList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, general: false}));
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, zone: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setZoneList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, zone: false}));
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedZone) {
            setIsLoading(prevState => ({ ...prevState, region: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.regionName,
                            value: data.regionName
                        }));

                        setRegionList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, region: false}));
                }
            };

            fetchData();
        }
    }, [selectedZone]);

    useEffect(() => {
        if (selectedRegion) {
            setIsLoading(prevState => ({ ...prevState, area: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}area?regionName=${selectedRegion}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.areaName,
                            value: data.areaName
                        }));

                        setAreaList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, area: false}));
                }
            };

            fetchData();
        }
    }, [selectedRegion]);


    useEffect(() => {
        if (selectedArea) {
            setIsLoading(prevState => ({ ...prevState, dbPoint: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`;
                    const response = await axios.get(url, { headers: authHeader() });
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.dbPointName,
                            value: data.dbPointName
                        }));
                        setDbPointList(modifyData);
                    }
                }
                catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, dbPoint: false}));
                }
            }
            fetchData();
        }
    }, [selectedArea])

    useEffect(() => {

        if (selectedDBPoint) {
            setIsLoading(prevState => ({ ...prevState, distributor: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?dbPoint=${selectedDBPoint}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.distributors?.map(data => ({
                            id: data.userId,
                            label: data.distributorName,
                            value: data.userId
                        }));

                        setDistributorList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, distributor: false}));
                }
            };

            fetchData();
        }


    }, [selectedDBPoint]);


    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, category: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productCategory/all/prods`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.proCatData?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setCategoryList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, category: false}));
            }
        };

        fetchData();
    }, []);

    // products
    useEffect(() => {
        if (selectedCategory) {
            setIsLoading(prevState => ({ ...prevState, product: true }));
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?categoryName=${selectedCategory}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.products?.map(data => ({
                            id: data._id,
                            label: data.skuName,
                            value: data.skuName
                        }));

                        setProductsList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, product: false}));
                }
            };

            fetchData();
        }
    }, [selectedCategory]);


    const handleSubmitForm = async (values, actions) => {
        const handleSubmitData = {
            discountType: values.discountType.value,
            // zoneName: values.zoneName.value,
            // regionName: values.regionName.value,
            // areaName: values.areaName.value,
            // dbPoint: values.dbPoint.value,
            companyName: companyName,
            // dealerId: values.dealerId.value,
            conditionName: values.conditionName,
            productCategory: values.productCategory.value,
            skuName: values.skuName.value,
            buyQty: values.buyQty,
            discountBDT: values.discountBDT,
            totalBudgetDiscount: values.totalBudgetDiscount,
            discountNote: values.discountNote,
            discountStartDate: values.discountStartDate,
            discountEndDate: values.discountEndDate,
            status: values.status.value,
            discountUnit: values.discountUnit.value
        };
        if (values.dealerId) {
            handleSubmitData.dealerId = values.dealerId.value;
        }

        if (values.zoneName) {
            handleSubmitData.zoneName = values.zoneName.value;
        }
        if (values.regionName) {
            handleSubmitData.regionName = values.regionName.value;
        }
        if (values.areaName) {
            handleSubmitData.areaName = values.areaName.value;
        }
        if (values.dbPoint) {
            handleSubmitData.dbPoint = values.dbPoint.value;
        }

        setIsLoading(prevState => ({ ...prevState, general: true }));
        const url = updateID
            ? `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/${updateID}`
            : `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/create`;

        try {
            const axiosMethod = updateID ? axios.put : axios.post;
            const response = await axiosMethod(url, handleSubmitData, { headers: authHeader() });

            if (response.status === 200 || response.data.status) {
                navigate("/master/discount");
                toast.success(response.data.message);
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(prevState => ({ ...prevState, general: false}));
        }
    };

    const handleSchema = Yup.object().shape({
        discountType: Yup.object().required("please select a value for the discount type field"),
        // zoneName: Yup.object().required("please select a value for the zone name field"),
        /*  regionName: Yup.object().required("please select a value for the region name field"),
         areaName: Yup.object().required("please select a value for the area name field"),
         dealerId: Yup.object().required("please select a value for the user role field"),
        // dbPoint: Yup.object().required("please select a value for the db point field"),

          */
        conditionName: Yup.string().required("please enter a value for the condition name field"),
        productCategory: Yup.object().required("please select a value for the product category field"),
        skuName: Yup.object().required("please select a value for the product field"),
        buyQty: Yup.string().required("please enter a value for the buy qty field"),
        discountBDT: Yup.string().required("please enter a value for the discount amount field"),
        totalBudgetDiscount: Yup.string().required("please enter a value for the budget field"),
        discountNote: Yup.string().nullable(),
        discountStartDate: Yup.string().required("please enter a value for the discount start date field"),
        discountEndDate: Yup.string().required("please enter a value for the discount end date field"),
        discountUnit: Yup.object().required("please select discount speciality")


    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Discount" : "Add Discount"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    {isLoading.general && <LoadingIcon />}
                    <Formik
                        initialValues={{
                            discountType: discountTypeList?.find(item => item.value === discountById.discountType) || null,
                            zoneName: zoneList?.find(item => item.value === discountById.zoneName) || null,
                            regionName: regionList?.find(item => item.value === discountById?.regionName) || null,
                            dbPoint: dbPointList?.find(item => item.value === discountById?.dbPoint) || null,
                            areaName: areaList?.find(item => item.value === discountById?.areaName) || null,
                            dealerId: distributorList?.find(item => item.value === discountById?.dealerId) || null,
                            conditionName: discountById?.conditionName || "",
                            productCategory: categoryList?.find(item => item.value === discountById?.productCategory) || null,
                            skuName: productsList?.find(item => item.value === discountById?.skuName) || null,
                            buyQty: discountById?.buyQty || "",
                            discountBDT: discountById?.discountBDT || "",
                            totalBudgetDiscount: discountById?.totalBudgetDiscount || "",
                            discountNote: discountById?.discountNote || "",
                            discountStartDate: formatDateToYYYYMMDD(discountById?.discountStartDate) || "",
                            discountEndDate: formatDateToYYYYMMDD(discountById?.discountEndDate) || "",
                            status: statusOptions?.find(item => item.value == discountById?.status) || null,
                            discountUnit: specialOptions?.find(item => item.value == discountById?.discountUnit) || {
                                label: "Non Special",
                                value: "CTN"
                            },
                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={updateID ? true : false}

                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched

                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>

                                        <Form.Group className="mb-3" as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Discount Speciality</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={specialOptions}
                                                placeholder="Select Discount Speciality"
                                                classNamePrefix="react-select"
                                                name="discountUnit"
                                                value={values.discountUnit}
                                                defaultValue={{
                                                    label: "Non Special",
                                                    value: "CTN"
                                                }}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "discountUnit",
                                                        selectedOption
                                                    );

                                                    setDiscountById({ ...discountById, discountUnit: selectedOption.value })

                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.discountUnit && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.discountUnit}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Discount Type</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={discountTypeList}
                                                placeholder="Select Discount Type"
                                                classNamePrefix="react-select"
                                                name="discountType"
                                                value={values.discountType}
                                                onChange={(selectedOption) => {

                                                    setFieldValue("discountType", selectedOption);

                                                    setDiscountById({ ...discountById, discountType: selectedOption.value })


                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("discountType", true, true) }}
                                            />
                                            {touched.discountType && errors.discountType && (
                                                <div style={{ color: "red" }}>
                                                    {errors.discountType}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                       
                                        {values?.discountType?.value !== "National" && (
                                            <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                <Form.Label>Select Zone</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={zoneList}
                                                    placeholder="Select Zone"
                                                    classNamePrefix="react-select"
                                                    name="zoneName"
                                                    value={values.zoneName}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue("zoneName", selectedOption);
                                                        setSelectedZone(selectedOption.value);
                                                        setDiscountById({ ...discountById, zoneName: selectedOption.value })


                                                    }}
                                                    onBlur={event => { handleBlur(event); setFieldTouched("zoneName", true, true) }}
                                                    isLoading={isLoading.zone}
                                                />
                                                {touched.zoneName && errors.zoneName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.zoneName}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        )}


                                    </Row>
                                    {values?.discountType?.value !== "National" && (
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                <Form.Label>Select Region</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={regionList}
                                                    placeholder="Select Region"
                                                    classNamePrefix="react-select"
                                                    name="regionName"
                                                    value={values.regionName}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue("regionName", selectedOption);
                                                        setSelectedRegion(selectedOption.value)
                                                        setDiscountById({ ...discountById, regionName: selectedOption.value })


                                                    }}
                                                    onBlur={event => { handleBlur(event); setFieldTouched("regionName", true, true) }}
                                                    isLoading={isLoading.region}
                                                />
                                                {touched.regionName && errors.regionName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.regionName}
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                <Form.Label>Select Area</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={areaList}
                                                    placeholder="Select Area"
                                                    classNamePrefix="react-select"
                                                    name="areaName"
                                                    value={values.areaName}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue("areaName", selectedOption);
                                                        setSelectedArea(selectedOption.value);
                                                        setDiscountById({ ...discountById, areaName: selectedOption.value })

                                                    }}
                                                    onBlur={event => { handleBlur(event); setFieldTouched("areaName", true, true); }}
                                                    isLoading={isLoading.area}
                                                />
                                                {touched.areaName && errors.areaName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.areaName}
                                                    </div>
                                                )}
                                            </Form.Group>


                                        </Row>

                                    )}

                                    {values?.discountType?.value !== "National" && (
                                        <Row className="mb-3"><Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select DB Point</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={dbPointList}
                                                placeholder="Select DB Point"
                                                classNamePrefix="react-select"
                                                name="dbPoint"
                                                value={values.dbPoint}
                                                onChange={(selectedOption) => {

                                                    setFieldValue("dbPoint", selectedOption);
                                                    setDiscountById({ ...discountById, dbPoint: selectedOption.value })
                                                    setSelectedDBPoint(selectedOption.value)


                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("dbPoint", true, true); }}
                                                isLoading={isLoading.dbPoint}
                                            />
                                            {touched.dbPoint && errors.dbPoint && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.dbPoint}
                                                </div>
                                            )}
                                        </Form.Group>
                                            {values?.discountType?.value === "Individual" && <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                <Form.Label>Select Distributor</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={distributorList}
                                                    placeholder="Select Distributor"
                                                    classNamePrefix="react-select"
                                                    name="dealerId"
                                                    value={values.dealerId}
                                                    onChange={(selectedOption) => {

                                                        setFieldValue("dealerId", selectedOption);

                                                        setDiscountById({ ...discountById, dealerId: selectedOption.value })


                                                    }}

                                                    onBlur={event => { handleBlur(event); setFieldTouched("dealerId", true, true); }}
                                                    isLoading={isLoading.distributor}
                                                />
                                                {touched.dealerId && errors.dealerId && (
                                                    <div style={{ color: "red", fontSize: "1rem" }}>
                                                        {errors.dealerId}
                                                    </div>
                                                )}
                                            </Form.Group>}
                                        </Row>
                                    )}
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Discount Name</Form.Label>
                                            <Form.Control
                                                name="conditionName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Discount Name"
                                                required
                                                value={values.conditionName}
                                            />
                                            {touched.conditionName && errors.conditionName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.conditionName}
                                                </div>
                                            )}

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Product Category</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={categoryList}
                                                placeholder="Select Product Category"
                                                classNamePrefix="react-select"
                                                name="productCategory"
                                                value={values.productCategory}
                                                onChange={(selectedOption) => {

                                                    setFieldValue("productCategory", selectedOption);
                                                    setSelectedCategory(selectedOption.value);
                                                    setDiscountById({ ...discountById, productCategory: selectedOption.value })

                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("productCategory", true, true); }}
                                                isLoading={isLoading.category}
                                            />
                                            {touched.productCategory && errors.productCategory && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.productCategory}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Product</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={productsList}
                                                placeholder="Select Product"
                                                classNamePrefix="react-select"
                                                name="skuName"
                                                value={values.skuName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("skuName", selectedOption);
                                                    // setSelectedCategory(selectedOption.value);
                                                    setDiscountById({ ...discountById, skuName: selectedOption.value })


                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("skuName", true, true); }}
                                                isLoading={isLoading.product}
                                            />
                                            {touched.skuName && errors.skuName && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.skuName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Purchase Qty ({values.discountUnit.value || ""})</Form.Label>
                                            <Form.Control
                                                name="buyQty"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter buy Qty"
                                                value={values.buyQty}
                                            />
                                            {touched.buyQty && errors.buyQty && (
                                                <div style={{ color: "red" }}>
                                                    {errors.buyQty}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Discount BDT</Form.Label>
                                            <Form.Control
                                                name="discountBDT"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Discount BDT"
                                                required
                                                value={values.discountBDT}
                                            />
                                            {touched.discountBDT && errors.discountBDT && (
                                                <div style={{ color: "red" }}>
                                                    {errors.discountBDT}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Discount Note</Form.Label>
                                            <Form.Control
                                                name="discountNote"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                as="textarea"
                                                type="text"
                                                placeholder="Enter Discount Note"
                                                // required
                                                value={values.discountNote}
                                            />
                                            {touched.discountNote && errors.discountNote && (
                                                <div style={{ color: "red" }}>
                                                    {errors.discountNote}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Discount Start Date</Form.Label>
                                            <Form.Control
                                                name="discountStartDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter Discount Start Date"
                                                required
                                                value={values.discountStartDate}
                                            />
                                            {touched.discountStartDate && errors.discountStartDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.discountStartDate}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Discount End Date</Form.Label>
                                            <Form.Control
                                                name="discountEndDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter Discount End Date"
                                                required
                                                value={values.discountEndDate}
                                            />
                                            {touched.discountEndDate && errors.discountEndDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.discountEndDate}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Discount Budget BDT</Form.Label>
                                            <Form.Control
                                                name="totalBudgetDiscount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Discount Budget BDT"
                                                required
                                                value={values.totalBudgetDiscount}
                                            />
                                            {touched.totalBudgetDiscount && errors.totalBudgetDiscount && (
                                                <div style={{ color: "red" }}>
                                                    {errors.totalBudgetDiscount}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Status</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={statusOptions}
                                                placeholder="Select Offer Status"
                                                classNamePrefix="react-select"
                                                name="status"
                                                value={values.status}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "status",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.status && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.status}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/discount")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default DiscountForm;
