import styles from './style.module.css';
const ToggleButton = ({ status,id,handleToggle }) => {


    return (<>

        <label className={styles.switch}>
            <input type="checkbox" checked={status === true} onChange={() => handleToggle(id,!status)} />
            <span className={`${styles.slider} ${styles.round}`}></span>
        </label>
    </>
    )
}

export default ToggleButton;