import React from "react";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const ExcelExportHelper = ({ data, selectedFromDate, selectedToDate }) => {
    const createDownLoadData = () => {
        handleExport().then((url) => {
            const downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", url);
            downloadAnchorNode.setAttribute("download", "order_report.xlsx");
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        });
    };

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
        return blob;
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    };



    const handleExport = () => {
        const formatedDate = (date) => {
            const day = date?.getDate();
            const month = date?.toLocaleString('default', { month: 'long' });
            const year = date?.getFullYear();
            return `${day} ${month} ${year}`;
        };

        const currentDate = new Date();
        const fromDate = new Date(selectedFromDate);
        const toDate = new Date(selectedToDate);
        const title = [
            ["T.K Food Products LTD", "", "", ""],
            ["Orders Summary Report", "", "", ""],
            ["Address: T. K. Bhaban (2nd Floor), 13, Karwan Bazar, Dhaka-1215", "", "", ""],
            [`Date: ${selectedFromDate && selectedToDate ? ` ${formatedDate(fromDate)} - ${formatedDate(toDate)}` : formatedDate(currentDate)}`, "", "", ""],
        ];

        console.log("Title rows:", title);

        const table1 = [
            ["Demand Order No.(Pending)", data.demandOrderNo],
            ["Today Processed DO No.", data.todayProcessedDo],
            ["Today Confirmed Orders No.", data.todayConfirmOrder],
            ["Confirmed DO No.(Monthly)", data.ConfirmDo],
            ["Cancelled DO No.(Monthly)", data.cancelDo],
            ["Till Date Confirm DO No.(Year)", data.TillDateConfirmDo],
        ];

        const table2 = [
            ["Demand Order Tk.(Pending)", data.demandOrderAmount],
            ["Today Processed Tk.", data.todayProcessedAmount],
            ["Today Confirmed Order Tk.", data.todayConfirmedOrderAmount],
            ["Confirmed DO Tk.(Monthly)", data.confirmDoAmount],
            ["Cancelled DO Tk.(Monthly)", data.cancelDoAmount],
            ["Till Date Confirm DO Tk.(Year)", data.TillDateConfirmDoAmount],
        ];



        const wb = XLSX.utils.book_new();
        const sheet = XLSX.utils.aoa_to_sheet([]);

        // Add title rows (including the date row)
        title.forEach((row, index) => {
            XLSX.utils.sheet_add_aoa(sheet, [row], { origin: `A${index + 1}` });
        });

        // Add headers for the tables
        XLSX.utils.sheet_add_aoa(sheet, [["Order Details", "Value", "Amount Details", "Value"]], { origin: "A5" });

        // Combine table1 and table2 data into one sheet row by row
        table1.forEach((row, index) => {
            XLSX.utils.sheet_add_aoa(sheet, [[row[0], row[1], table2[index][0], table2[index][1]]], { origin: `A${6 + index}` });
        });

        // Define the row for the signature
        const signatureStartRow = 6 + table1.length + 3; // Adjust as needed (adding 2 for spacing)

        // Append the signature label
        XLSX.utils.sheet_add_aoa(sheet, [["Signature:", "", "", ""]], { origin: `A${signatureStartRow}` });

        // XLSX.utils.sheet_add_aoa(sheet, [["____________________", "", "", ""]], { origin: `A${signatureStartRow + 1}` });

        XLSX.utils.book_append_sheet(wb, sheet, "Order_Report");

        const workbookBlob = workbook2blob(wb);

        const dataInfo = {
            titleRange1: "A1:D1",
            titleRange2: "A2:D2",
            titleRange3: "A3:D3",
            titleRange4: "A4:D4",
            headerRange: "A5:D5",
            tbodyRange: `A6:D${6 + table1.length}`,
            signatureRange: `A${signatureStartRow}:D${signatureStartRow + 1}`, // Define the signature range
            signatureStartRow // Add signatureStartRow to the dataInfo object
        };

        return addStyle(workbookBlob, dataInfo);
    };

    const addStyle = (workbookBlob, dataInfo) => {
        return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
            const sheet = workbook.sheet(0);

            // Manually merge each title row and apply style
            sheet.range(dataInfo.titleRange1).merged(true).style({
                bold: true,
                fontSize: 16,
                horizontalAlignment: "center",
                verticalAlignment: "center",
                fill: "FFFF00",
            });
            sheet.range(dataInfo.titleRange2).merged(true).style({
                bold: true,
                fontSize: 16,
                horizontalAlignment: "center",
                verticalAlignment: "center",
                fill: "FFFF00",
            });
            sheet.range(dataInfo.titleRange3).merged(true).style({
                bold: true,
                fontSize: 12,
                horizontalAlignment: "center",
                verticalAlignment: "center",
                fill: "FFFF00",
            });
            sheet.range(dataInfo.titleRange4).merged(true).style({ // Style for the date
                bold: true,
                fontSize: 12,
                horizontalAlignment: "center",
                verticalAlignment: "center",
                fill: "FFFF00",
            });

            // Style the headers
            sheet.range(dataInfo.headerRange).style({
                bold: true,
                fill: "00BFFF",
                fontColor: "FFFFFF",
                horizontalAlignment: "center",
                verticalAlignment: "center",
            });

            // Style the body
            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: "center",
                verticalAlignment: "center",
                border: {
                    top: { style: "thin", color: "000000" },
                    bottom: { style: "thin", color: "000000" },
                    left: { style: "thin", color: "000000" },
                    right: { style: "thin", color: "000000" },
                },
            });

            // Style the signature section
            sheet.range(dataInfo.signatureRange).style({
                bold: true,
                fontSize: 14, // Larger font for signature
                // fill: "E0E0E0", // Light gray background
                horizontalAlignment: "center",
                verticalAlignment: "center",
                underline: true,
                // border: {
                //     top: { style: "dashed", color: "000000" }, // Change here
                //     bottom: { style: "dashed", color: "000000" }, // Change here
                //     left: { style: "dashed", color: "000000" }, // Change here
                //     right: { style: "dashed", color: "000000" }, // Change here
                // },
            });

            // Additional specific styles for the signature label
            sheet.range(`C${dataInfo.signatureStartRow}:D${dataInfo.signatureStartRow}`).style({
                fontSize: 12, // Slightly smaller for the signature label
                // fill: "FFFFFF", // White background for the label
                bold: true, // Bold for emphasis
                horizontalAlignment: "center",
                verticalAlignment: "center",
                underline: true, // Underline for signature
                // border: {
                //     top: { style: "thin", color: "000000" },
                //     bottom: { style: "thin", color: "000000" },
                //     left: { style: "thin", color: "000000" },
                //     right: { style: "thin", color: "000000" },
                // },
            });

            // Set column widths for better presenta tion
            sheet.column("A").width(30);
            sheet.column("B").width(15);
            sheet.column("C").width(30);
            sheet.column("D").width(15);

            return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
        });
    };


    return (
        <Button onClick={createDownLoadData} className="btn btn-primary float-end" variant="secondary">
            Export Data
        </Button>
    );
};

export default ExcelExportHelper;
