import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const CommissionAudienceForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [commissionAudienceById, setCommissionAudienceById] = useState({});
    const { updateID } = useParams();
    const navigate = useNavigate();


    // Commission audience Get By ID
    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_SETTINGS_BASE_URL + `commission-audiences/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setCommissionAudienceById(response.data.data);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
    }, [])


    const handleFormSubmit = (values, actions) => {

        const handleSubmitValue = {
            name: values.commission_audience_name
        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_SETTINGS_BASE_URL + 'commission-audiences';
            axios.post(url, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/commissionAudience")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_SETTINGS_BASE_URL + `commission-audiences/${updateID}`;
            axios.put(updateUrl, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/commissionAudience")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({

        commission_audience_name: Yup.string().required("Please Enter a value for the Commission Audience Name field"),
    })


    return (
        <>
            <PageHeader
                title={updateID ? "Update Commission Audience" : "Add New Commission Audience"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{

                            commission_audience_name: commissionAudienceById?.name || '',


                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" controlId="exampleState">
                                            <Form.Label>Commission Audience Name</Form.Label>
                                            <Form.Control
                                                name="commission_audience_name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Commission Audience Name"
                                                required
                                                value={values.commission_audience_name}
                                            />
                                            {touched.commission_audience_name && errors.commission_audience_name && (
                                                <div style={{ color: "red" }}>
                                                    {errors.commission_audience_name}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"

                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/commissionAudience")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default CommissionAudienceForm;


