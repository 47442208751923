import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { IoIosCheckboxOutline } from "react-icons/io";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import StatusCell from "components/common/StatusCell";
import { useNavigate } from "react-router-dom";

const PendingOrderReconciliationList = () => {
    const [distributorOrderList, setDistributorOrderList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState(0);
    const [checkedOrders, setCheckedOrders] = useState([]);
    const userInfo = useSelector(state => state?.auth?.user?.data);


    const navigate = useNavigate();


    // DISTRIBUTOR LIST
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/group?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.result;
                    const totalPages = response?.data?.payload?.pagination?.totalPages;
                    setTotalPages(totalPages)
                    setDistributorOrderList(result);
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount, searchText]);



    if (isLoading) {
        return <LoadingIcon />;
    }

    const handleEditOrder = (order) => {
        const isPcs = order?.data?.[0]?.unitName === "PCS";
        if (isPcs) {
            navigate(`/master/special-distributor-orders-update-other/${order?.doNo}`);
        } else {
            navigate(`/master/db-order-reconcile-finance/${order?.doNo}`);
        }
    }


    return (
        <>
            <PageHeader
                title={`All Orders List`}
                className="mb-3"
            ></PageHeader>

            <Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader isNew={false} isExport={false} isSearch={true} setSearchText={setSearchText} />
                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial No.</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DO-No</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB ID</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB Name</th>

                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB Point</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Order Qty Ctn</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Order Qty PCS</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Discount BDT</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Price</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Status</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Note</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Action</th>

                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        distributorOrderList?.map((order, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{order?.index}</td>
                                                <td className="align-middle text-center">{order?.doNo}</td>
                                                <td className="align-middle text-center">{order?.dealerId}</td>
                                                <td className="align-middle text-center">{order?.distributorName}</td>


                                                <td className="align-middle text-center">{order?.dbPoint}</td>
                                                <td className="align-middle text-center">{order?.totalOrderQtyCtn}</td>
                                                <td className="align-middle text-center">{order?.totalOrderQtyPCS}</td>
                                                <td className="align-middle text-center">{order?.totalDiscountBDT}</td>
                                                <td className="align-middle text-center">{order?.totalPrice}</td>

                                                <td className="align-middle text-center">
                                                    <StatusCell status={order?.status} />
                                                </td>
                                                <td className="align-middle text-center">{order?.cancelNote}</td>
                                                <td>
                                                    <Dropdown className="d-inline mx-2">
                                                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                                                            ...
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item >

                                                                <IconButton
                                                                    variant="falcon-default"
                                                                    size="sm"
                                                                    icon="edit"
                                                                    transform="shrink-2"
                                                                    iconAlign="middle"
                                                                    className="me-2"
                                                                    onClick={() => handleEditOrder(order)}
                                                                >
                                                                    <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                                                </IconButton>

                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                <Link to={"/master/distributor-orders-view/" + order?.doNo}
                                                                // state={{ doNo: order?.doNo }}
                                                                >
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        size="sm"
                                                                        icon="edit"
                                                                        transform="shrink-2"
                                                                        iconAlign="middle"
                                                                        className="me-2"
                                                                    >
                                                                        <span className="d-none d-xl-inline-block ms-1">View details</span>
                                                                    </IconButton>
                                                                </Link>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>


                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                </Card.Footer>
            </Card>

        </>
    );

};

export default PendingOrderReconciliationList;
