import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { formatNumber } from "helpers/utils";
import { useState } from "react";
const { Card, Table, Row, Col, ProgressBar, Accordion, Dropdown } = require("react-bootstrap")


const TableItem = ({ item }) => {
    const { skuName,
        categoryName,
        subCategory,
        skuPrice,
        targetQuantityCtn,
        targetQuantityPcs, orderQtyCtn, ctnPrice } = item;
    const percentage = parseInt((parseFloat(orderQtyCtn || 0) / parseFloat(targetQuantityCtn || 0)) * 100);
    return (
        <tr >
            <td>
                <Flex alignItems="center" className="position-relative">
                    <div className="">
                        <h6 className="mb-1 fw-semi-bold text-start">
                            {skuName}
                        </h6>
                        <p className="fw-semi-bold mb-0 text-500 text-start">{categoryName}</p>
                    </div>
                </Flex>
            </td>
            <td className="align-middle text-start fw-semi-bold">
                {targetQuantityCtn}
            </td>
            <td className="align-middle text-start fw-semi-bold">
                {orderQtyCtn || 0}
            </td>
            <td className="align-middle text-start fw-semi-bold">
                {formatNumber(parseInt(parseFloat(targetQuantityCtn) * parseFloat(ctnPrice)))}
            </td>
            <td className="align-middle text-start fw-semi-bold">
                {formatNumber(parseInt(parseFloat(orderQtyCtn) * parseFloat(ctnPrice)))}
            </td>
            <td className="align-middle pe-x1">
                <Flex alignItems="center">
                    <ProgressBar now={percentage} style={{ width: '80px', height: 5 }} />
                    <div className="fw-semi-bold ms-3">{percentage}%</div>
                </Flex>
            </td>
        </tr>
    );
};

// date
const getDate = (data) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = data[0]?.targets[0]?.targetMonth;
    const year = data[0]?.targets[0]?.targetYear;

    let date = "";

    if (month !== undefined && year !== undefined) {
        const monthName = monthNames[month - 1]; // Subtract 1 because array indices start at 0
        date = `${monthName} ${year}`;
    }
    return date;
}

const formatDataByCategory = (data) => {
    const groupedData = {};
    data.forEach(item => {
        if (!groupedData[item.categoryName]) {
            groupedData[item.categoryName] = {
                categoryId: item._id,
                categoryName: item.categoryName,
                totalTargetQty: 0,
                totalTargetAmount: 0,
                totalAchievementQty: 0,
                totalAchievementAmount: 0,
                percentage: 0,
                data: []
            }
        }
        groupedData[item.categoryName].totalTargetQty += item.targetQuantityCtn || 0;
        groupedData[item.categoryName].totalTargetAmount += item.targetQuantityCtn * item.ctnPrice || 0;
        groupedData[item.categoryName].totalAchievementQty += item.orderQtyCtn || 0;
        groupedData[item.categoryName].totalAchievementAmount += item.orderQtyCtn * item.ctnPrice || 0;
        groupedData[item.categoryName].percentage = parseInt((parseFloat(groupedData[item.categoryName].totalAchievementQty) / parseFloat(groupedData[item.categoryName].totalTargetQty)) * 100);
        groupedData[item.categoryName].data.push(item);
    })
    return Object.values(groupedData);
}

const TargetTable = ({ data }) => {
    const [targetDataShow, setTargetDataShow] = useState(true);
    const date = getDate(data);
    const formatData = formatDataByCategory(data);

    const handleTargetShow = () => {
        setTargetDataShow(!targetDataShow);
    }
    return (
        <>
            <h6 className="text-center fs-1 ">Target vs Achivement {date}</h6>

            <div style={{ overflowX: "scroll" }}>
                <Card className="h-100 " style={{ width: "750px" }}>
                    <Card.Header className="bg-light">
                        <Row className="w-100 align-items-center">
                            <Col><h6>Category</h6></Col>
                            <Col><h6>Target Qty</h6></Col>
                            <Col><h6>Sales Qty</h6></Col>
                            <Col><h6>Target Amount</h6></Col>
                            <Col><h6>Sales Amount</h6></Col>
                            <Col><h6>Achi %</h6></Col>
                            <Col className="text-end">
                                <Dropdown className="font-sans-serif btn-reveal-trigger">
                                    <Dropdown.Toggle variant="link" size="sm" data-boundary="viewport">
                                        <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="border py-0">
                                        <div className="py-2">
                                            {targetDataShow && <Dropdown.Item onClick={() => handleTargetShow()}>Hide</Dropdown.Item>}
                                            {!targetDataShow && <Dropdown.Item onClick={() => handleTargetShow()}>Show</Dropdown.Item>}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Card.Header>
                    {targetDataShow && <Card.Body className="p-0">
                        {formatData?.length === 0 ? (
                            <p style={{ textAlign: "center", fontSize: "18px", color: "#354151", padding: "20px" }}>No Target found</p>
                        ) : (
                            <Accordion>
                                {formatData?.map((category) => (
                                    <Accordion.Item key={category.categoryId} eventKey={category.categoryId}>
                                        <Accordion.Header style={{ position: 'relative', paddingRight: '30px' }}>
                                            <Row className="w-100 align-items-center">
                                                <Col>{category?.categoryName}</Col>
                                                <Col>{formatNumber(category?.totalTargetQty)}</Col>
                                                <Col>{formatNumber(category?.totalAchievementQty)}</Col>
                                                <Col>{formatNumber(category?.totalTargetAmount)}</Col>
                                                <Col>{formatNumber(category?.totalAchievementAmount)}</Col>
                                                <Col>
                                                    <ProgressBar now={category?.percentage} style={{ width: '80px', height: '5px' }} />
                                                    <div className="fw-semi-bold ms-3">{category?.percentage}%</div>
                                                </Col>
                                                <Col className="text-end">
                                                    <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', width: '10px', height: '10px', backgroundColor: 'transparent' }}></div>
                                                </Col>
                                            </Row>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Target Qty</th>
                                                        <th>Sales Qty</th>
                                                        <th>Target Amount</th>
                                                        <th>Sales Amount</th>
                                                        <th>Achi %</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {category?.data?.length ? (
                                                        category?.data.map((item, index) => (
                                                            <TableItem
                                                                item={item}
                                                                key={index}
                                                            />
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="100%" style={{ textAlign: 'center', height: '100px' }}>
                                                                <h5 style={{ margin: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                    No Target!
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )}
                    </Card.Body>}
                </Card>
            </div>


        </>
    )
}

export default TargetTable;