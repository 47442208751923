import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, CloseButton, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import PageHeader from "components/common/PageHeader";

const EmployeeEducationForm = ({ employeeId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [educationDegree, setEducationDegree] = useState([]);
    const [employeeEducationId, setEmployeeEducationId] = useState(null);
    const [employeeEducation, setEmployeeEducation] = useState({});
    const [handleEducationDegreeModal, setHandleEducationDegreeModal] = useState(false);
    const { updateID } = useParams();
    const navigate = useNavigate();



    //  Date Format
    function formatDateToYYYYMMDD(isoDateString) {
        const date = new Date(isoDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }

    const handleSubmit = (values) => {

        const educationInfo = {
            educational_degree_id: values.educational_degree_id.value,
            employee_id: employeeId,
            passing_year: values.passing_year,
            result: values.result
        }
        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + "employee-educations";
            axios.post(url, educationInfo, { headers: authHeader() })
                .then(response => {
                    if (response.status === 200) {
                        navigate("/master/employee")
                        toast.success("Employee Added Success");
                    }
                    setIsLoading(false);

                })
                .catch(error => {
                    toast.error(error.message)
                    setIsLoading(false);

                })


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employee-educations/${employeeEducationId}`;
            axios.put(updateUrl, educationInfo, { headers: authHeader() })
                .then(response => {
                    if (response.status === 200) {
                        navigate("/master/employee")
                        toast.success("Employee Update Success");
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    toast.error(error.message)

                })

        }

    }

    // get all degree
    useEffect(() => {
        setIsLoading(true);
        const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + "educational-degrees";
        axios.get(url, { headers: authHeader() })
            .then(response => {
                setIsLoading(false);

                if (response.status === 200) {
                    const modifyData = response.data?.data.map(item => {
                        return {
                            id: item.id,
                            label: item.name,
                            value: item.id,
                        }


                    })
                    setEducationDegree(modifyData);
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error.message)
                console.log(error)
            })
    }, [handleEducationDegreeModal])


    // Handle Education Degree
    const handleEducationDegreeFunc = (e) => {
        e.preventDefault();
        const degree_name = e.target.degree_name.value;

        const degreeInfo = {
            name: degree_name,
            status: true
        }

        setIsLoading(true);
        const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + "educational-degrees";
        axios.post(url, degreeInfo, { headers: authHeader() })
            .then(response => {
                setIsLoading(false);
                if (response.status === 200) {

                    setHandleEducationDegreeModal(false);
                    toast.success("New Degree Added");
                }

            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error.message)
                console.log(error)
            })


    }


    // load update employee field value
    useEffect(() => {
        if (updateID) {
            setIsLoading(true);
            axios.get(process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employee-educations`, { headers: authHeader() })
                .then(response => {
                    const employeeId = response.data?.data?.find(item => item.employee_id == updateID)
                    console.log("test em", employeeId)
                    setEmployeeEducationId(employeeId?.id);
                    if (employeeId) {
                        const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employee-educations/${employeeId?.id}`;
                        axios.get(url, { headers: authHeader() })
                            .then(response => {
                                if (response.status === 200) {
                                    setEmployeeEducation(response?.data?.data)
                                }
                                setIsLoading(false);
                            })
                            .catch(error => {
                                setIsLoading(false);
                                toast.error(error.message)

                            })
                    }
                })

        }
    }, [])

    isLoading && <LoadingIcon />


    const handleSchema = Yup.object().shape({
        educational_degree_id: Yup.object().required("Please select a value for the Degree field"),
        passing_year: Yup.string().matches(/^\d{4}$/, 'Invalid year format').required("Please select a value for the Passing Year field"),
        result: Yup.string().required("Please select a value for the result field")
    });

    return (
        <>
            <Formik
                initialValues={{

                    educational_degree_id: educationDegree.find(item => item.value == employeeEducation.educational_degree_id) || null,
                    employee_id: employeeEducation.employee_id || null,
                    passing_year: employeeEducation.passing_year || null,
                    result: employeeEducation.result || null

                }}
                validationSchema={handleSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {({
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    setFieldValue

                }) => {

                    return (
                        <Form onSubmit={handleSubmit}>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                    <Form.Label>Select Degree</Form.Label>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={educationDegree}
                                                placeholder="Select degree"
                                                classNamePrefix="react-select"
                                                name="educational_degree_id"
                                                value={values.educational_degree_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "educational_degree_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                            />
                                        </div>

                                    </div>

                                    <div><Form.Label style={{ float: "right", marginTop: "3px", cursor: "pointer" }} onClick={() => setHandleEducationDegreeModal(true)}>Add New</Form.Label></div>

                                    {
                                        errors.educational_degree_id && (
                                            <div style={{ color: "red" }}>
                                                {errors.educational_degree_id}
                                            </div>
                                        )}

                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="exampleState">
                                    <Form.Label>Passing Year</Form.Label>
                                    <Form.Control
                                        name="passing_year"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        placeholder="Enter Passing Year"
                                        required
                                        value={values.passing_year}
                                    />
                                    {touched.passing_year && errors.passing_year && (
                                        <div style={{ color: "red" }}>
                                            {errors.passing_year}
                                        </div>
                                    )}
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="exampleState">
                                    <Form.Label>Result</Form.Label>
                                    <Form.Control
                                        name="result"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        placeholder="Enter Result"
                                        required
                                        value={values.result}
                                    />
                                    {touched.result && errors.result && (
                                        <div style={{ color: "red" }}>
                                            {errors.result}
                                        </div>
                                    )}
                                </Form.Group>

                            </Row>

                            <IconButton
                                variant="primary"
                                className="me-2"
                                type="submit"
                            >
                                Submit
                            </IconButton>
                            <Button
                                className="px-5"
                                onClick={() => navigate("/master/employee")}
                                variant="danger" type="Cancel"
                            >
                                Cancel
                            </Button>


                        </Form>
                    );
                }}
            </Formik>

            <Modal
                show={handleEducationDegreeModal}
                size="lg"
                onHide={handleEducationDegreeModal}
                contentClassName="border-0"
                dialogClassName="mt-6"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Add New Degree</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => setHandleEducationDegreeModal(false)}
                    />
                </Modal.Header>
                <Modal.Body className="p-3">
                    <Form onSubmit={handleEducationDegreeFunc}>
                        <Form.Group as={Col} md="12" controlId="exampleState">
                            <Form.Label>Degree Name</Form.Label>
                            <Form.Control
                                name="degree_name"
                                type="text"
                                placeholder="Enter Degree Name"
                                required
                            />

                        </Form.Group>
                        <IconButton
                            variant="primary"
                            className="ms-auto px-5 mt-3"
                            type="submit"
                            iconAlign="right"
                            transform="down-1 shrink-4"

                        >
                            Submit
                        </IconButton>

                    </Form>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default EmployeeEducationForm;


