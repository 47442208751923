import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { CloseButton, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { authHeader } from "utils";
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoadingIcon from "helpers/LoadingIcon";
import EmployeeEducationForm from "./EmployeeEducationForm";
import EmployeeExperienceForm from "./EmployeeExperienceForm";
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "utils/validationRegex";
import Stepper from 'react-stepper-horizontal';
import CustomStepper from "helpers/customStepper";

const AddEmployee = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [employeeId, setEmployeeId] = useState(null);
    const [salesOrganization, setSalesOrganization] = useState([]);
    const [jobRoles, setJobRoles] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [handleRoleModal, setHandleRoleModal] = useState(false);
    const [handleDesignationModal, setHandleDesignationModal] = useState(false);
    const [bankAccounts, setBanks] = useState([]);
    const [employeeInfo, setEmployeeInfo] = useState({});
    const [step, setStep] = useState(0);
    const { updateID } = useParams();
    const navigate = useNavigate();


    // Blood Groups
    const blood_groups = [
        {
            label: 'A positive (A+)',
            value: 'A+',
        },
        {
            label: 'A negative (A-)',
            value: 'A-',
        },
        {
            label: 'B positive (B+)',
            value: 'B+',
        },
        {
            label: 'B negative (B-)',
            value: 'B-',
        },
        {
            label: 'AB positive (AB+)',
            value: 'AB+',
        },
        {
            label: 'AB negative (AB-)',
            value: 'AB-',
        },
        {
            label: 'O positive (O+)',
            value: 'O+',
        },
        {
            label: 'O negative (O-)',
            value: 'O-',
        }
    ]


    //  Date Format
    function formatDateToYYYYMMDD(isoDateString) {
        const date = new Date(isoDateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }

    // Load create employee field value
    useEffect(() => {
        setIsLoading(true);
        const urls = [
            process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + 'sales-organizations',
            process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + 'bank-accounts',

        ]

        Promise.all(urls?.map(url => axios.get(url, { headers: authHeader() })))
            .then(response => {
                setIsLoading(false);
                console.log(response)
                // salesOrganization
                const modifySalesOrganization = response[0]?.data?.sales_organizations?.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                        value: item.id

                    }
                })
                setSalesOrganization(modifySalesOrganization);

                const modifyBankAccounts = response[1]?.data?.data?.map(item => {
                    return {
                        id: item.id,
                        label: item.bank_account_number,
                        value: item.id

                    }
                })
                setBanks(modifyBankAccounts)

            })
    }, [])

    // get Employee Role 
    useEffect(() => {
        setIsLoading(true);
        const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + "job-roles";
        axios.get(url, { headers: authHeader() })
            .then(response => {
                setIsLoading(false);

                if (response.status === 200) {
                    const modifyData = response.data?.data.map(item => {
                        return {

                            id: item.id,
                            label: item.name,
                            value: item.id,
                        }


                    })
                    setJobRoles(modifyData);
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error.message)
                console.log(error)
            })
    }, [handleRoleModal])

    // get Employee Role 
    useEffect(() => {
        setIsLoading(true);
        const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + "designations";
        axios.get(url, { headers: authHeader() })
            .then(response => {
                setIsLoading(false);

                if (response.status === 200) {
                    const modifyData = response.data?.data.map(item => {
                        return {

                            id: item.id,
                            label: item.name,
                            value: item.id,
                        }


                    })

                    setDesignations(modifyData);
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error.message)

            })
    }, [handleDesignationModal])

    // load update employee field value
    useEffect(() => {
        if (updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employees/${updateID}`;
            axios.get(url, { headers: authHeader() })
                .then(response => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        setEmployeeInfo(response?.data?.data)
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    toast.error(error.message)

                })
        }
    }, [])


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };


    // Handle Employee Job Role
    const handleJobRoleFunc = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const job_role_name = e.target.job_role_name.value;

        const roleInfo = {
            name: job_role_name,
            status: true
        }

        const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + "job-roles";
        axios.post(url, roleInfo, { headers: authHeader() })
            .then(response => {
                setIsLoading(false);
                if (response.status === 200) {
                    setHandleRoleModal(false);
                    toast.success("New Role Added");
                }

            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error.message)
            })


    }

    // Handle Employee Designation
    const handleDesignationFunc = (e) => {
        e.preventDefault();
        const designation_name = e.target.designation_name.value;

        const designationInfo = {
            name: designation_name,
            status: true
        }

        setIsLoading(true);
        const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + "designations";
        axios.post(url, designationInfo, { headers: authHeader() })
            .then(response => {
                setIsLoading(false);
                if (response.status === 200) {
                    setHandleDesignationModal(false);
                    toast.success("New Designation Added");
                }


            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error.message)
            })
    }

    const handleProductSubmit = (values) => {

        const employeeInfo = {
            sales_organization_id: values.sales_organization_id.value,
            employer_provided_id: values.employeeId,
            job_role_id: values.job_role_id.value,
            designation_id: values.designation_id.value,
            bank_account_id: values.bank_account_id.value,
            first_name: values.first_name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            date_of_birth: formatDateToYYYYMMDD(values.date_of_birth),
            blood_group: values.blood_group.value,
            nid_number: values.nid_number,
            mobile_number: values.mobile_number,
            email: values.email,
            permanent_village: values.permanent_village,
            permanent_post_office: values.permanent_post_office,
            permanent_post_code: values.permanent_post_code,
            permanent_upazila_id: Number(values.permanent_upazila_id),
            present_village: values.present_village,
            present_post_office: values.present_post_office,
            present_post_code: values.present_post_code,
            present_upazila_id: Number(values.present_upazila_id),
            date_of_joining: formatDateToYYYYMMDD(values.date_of_joining),
            date_of_resignation: formatDateToYYYYMMDD(values.date_of_resignation),
            basic_salary: values.basic_salary,
            house_rent: values.house_rent,
            medical_allowance: values.medical_allowance,
            internet_bill: values.internet_bill,
            mobile_bill: values.mobile_bill,
            daily_allowance: values.daily_allowance,
            travelling_daily_allowance: values.travelling_daily_allowance,
            meeting_travelling_allowance: values.meeting_travelling_allowance,
            meeting_daily_allowance: values.meeting_daily_allowance,
            city_allowance: values.city_allowance,
            other_allowance: values.other_allowance,
            contribution_percentage: values.contribution_percentage,
            emergency_contact_name: values.emergency_contact_name,
            emergency_mobile_number: values.emergency_mobile_number,
            emergency_contact_relation: values.emergency_contact_relation,
            status: true
        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + 'employees';
            axios.post(url, employeeInfo, { headers: authHeader() })
                .then(response => {
                    if (response?.data?.data?.id) {
                        setEmployeeId(response?.data?.data?.id)
                        handleNext();
                        setIsLoading(false);
                    }

                })
                .catch(error => {
                    setIsLoading(false);
                    toast.error(error.response.data.message)

                })

        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employees/${updateID}`;
            axios.put(updateUrl, employeeInfo, { headers: authHeader() })
                .then(response => {
                    if (response?.data?.data?.id) {
                        setEmployeeId(response?.data?.data?.id)
                        handleNext();
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    toast.error(error.message)
                })

        }

    }

    const steps = [{ title: 'Employee Information' }, { title: 'Employee Experience' }, { title: 'Employee Education' }]

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrev = () => {
        setStep(step - 1);
    };


    isLoading && <LoadingIcon />


    const productSchema = Yup.object().shape({
        sales_organization_id: Yup.object().required("Please select a value for the sales organization field"),
        job_role_id: Yup.object().required("Please select a value for the job role field"),
        designation_id: Yup.object().required("Please select a value for the designation field"),
        bank_account_id: Yup.object().required("Please select a value for the bank account field"),
        first_name: Yup.string().required("Please select a value for the first name field").test('starts with a number', 'File input should not start with a number', value => {
            return !/^\d/.test(value);
        }),
        middle_name: Yup.string().required("Please select a value for the middle name field").test('starts with a number', 'File input should not start with a number', value => {
            return !/^\d/.test(value);
        }),
        last_name: Yup.string().required("Please select a value for the last name field").test('starts with a number', 'File input should not start with a number', value => {
            return !/^\d/.test(value);
        }),
        date_of_birth: Yup.date().required("Please select a value for the date of birth field"),
        blood_group: Yup.object().required("Please select a value for the blood group field."),
        nid_number: Yup.string().test("maxDigits", "Nid numbers should not have more than 10 digits", (value) => !/^\d{11,}$/.test(value)).required("Please enter a value for the nid number field"),
        mobile_number: Yup.string().matches(PHONE_NUMBER_REGEX, "Invalid phone number. Please enter a valid phone number").required("Please enter a value for the mobile number field"),
        email: Yup.string().email('Invalid Email. Please enter a valid email').matches(EMAIL_REGEX, "Invalid Email. Please enter a valid email").required('Please enter a value for the email field'),
        permanent_village: Yup.string().required("Please enter a value for the permanent village field"),
        permanent_post_office: Yup.string().required("Please enter a value for the permanent post office field"),
        permanent_post_code: Yup.string().required("Please enter a value for the permanent post code field"),
        permanent_upazila_id: Yup.string().required("Please enter a value for the permanent upazila field"),
        present_village: Yup.string().required("Please enter a value for the present village field"),
        present_post_office: Yup.string().required("Please enter a value for the present post office field"),
        present_post_code: Yup.string().required("Please enter a value for the present post code field"),
        present_upazila_id: Yup.string().required("Please enter a value for the present upazila field"),
        date_of_joining: Yup.date("Invalid Date. Please enter a valid date").required("Please enter a value for the date of joining field"),
        date_of_resignation: Yup.date().required(" Please enter a value for the date of resignation field"),
        basic_salary: Yup.number().required("Please enter a value for the basic salary field"),
        house_rent: Yup.number().required("Please enter a value for the house rent field"),
        medical_allowance: Yup.number().required("Please enter a value for the medical allowance field"),
        internet_bill: Yup.number().required("Please enter a value for the internet bill field"),
        mobile_bill: Yup.number().required("Please enter a value for the mobile bill field"),
        travelling_daily_allowance: Yup.number().required("Please enter a value for the travelling daily allowance field"),
        meeting_travelling_allowance: Yup.number().required("Please enter a value for the meeting travelling allowance field"),
        meeting_daily_allowance: Yup.number().required("Please enter a value for the meeting daily allowance field"),
        city_allowance: Yup.number().required("Please enter a value for the city allowance field"),
        other_allowance: Yup.number().required("Please enter a value for the other allowance field"),
        contribution_percentage: Yup.number().required("Please enter a value for the contribution percentage field"),
        emergency_contact_name: Yup.string().required("Please enter a value for the emergency contact name field"),
        emergency_mobile_number: Yup.string().matches(PHONE_NUMBER_REGEX, "Invalid phone number. Please enter a valid phone number").required("Please enter a value for the emergency mobile number field"),
        emergency_contact_relation: Yup.string().required("Please enter a value for the emergency contact relation field"),
    });


    return (
        <>
            <div className="my-2"><CustomStepper activeStep={step} steps={steps} /></div>
            <PageHeader
                title={updateID ? "Update Employee" : "Add New Employee"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{

                            sales_organization_id: salesOrganization.find(item => item.value == employeeInfo.sales_organization_id) || null,
                            job_role_id: jobRoles.find(item => item.value == employeeInfo.job_role_id) || null,
                            designation_id: designations.find(item => item.value == employeeInfo.designation_id) || null,
                            bank_account_id: bankAccounts.find(item => item.value == employeeInfo.bank_account_id) || null,
                            first_name: employeeInfo.first_name || "",
                            middle_name: employeeInfo.middle_name || "",
                            last_name: employeeInfo.last_name || "",
                            date_of_birth: formatDateToYYYYMMDD(employeeInfo.date_of_birth) || "",
                            blood_group: blood_groups.find(item => item.value == employeeInfo.blood_group) || null,
                            nid_number: employeeInfo.nid_number || null,
                            mobile_number: employeeInfo.mobile_number || null,
                            email: employeeInfo.email || "",
                            permanent_village: employeeInfo.permanent_village || "",
                            permanent_post_office: employeeInfo.permanent_post_office || "",
                            permanent_post_code: employeeInfo.permanent_post_code || null,
                            permanent_upazila_id: Number(employeeInfo.permanent_upazila_id) || null,
                            present_village: employeeInfo.present_village || "",
                            present_post_office: employeeInfo.present_post_office || "",
                            present_post_code: employeeInfo.present_post_code || null,
                            present_upazila_id: Number(employeeInfo.present_upazila_id) || null,
                            date_of_joining: formatDateToYYYYMMDD(employeeInfo.date_of_joining) || null,
                            date_of_resignation: formatDateToYYYYMMDD(employeeInfo.date_of_resignation) || null,
                            basic_salary: employeeInfo.basic_salary || null,
                            house_rent: employeeInfo.house_rent || null,
                            medical_allowance: employeeInfo.medical_allowance || null,
                            internet_bill: employeeInfo.internet_bill || null,
                            mobile_bill: employeeInfo.mobile_bill || null,
                            daily_allowance: employeeInfo.daily_allowance || null,
                            travelling_daily_allowance: employeeInfo.travelling_daily_allowance || null,
                            meeting_travelling_allowance: employeeInfo.meeting_travelling_allowance || null,
                            meeting_daily_allowance: employeeInfo.meeting_daily_allowance || null,
                            city_allowance: employeeInfo.city_allowance || null,
                            other_allowance: employeeInfo.other_allowance || null,
                            contribution_percentage: employeeInfo.contribution_percentage || null,
                            emergency_contact_name: employeeInfo.emergency_contact_name || "",
                            emergency_mobile_number: employeeInfo.emergency_mobile_number || null,
                            emergency_contact_relation: employeeInfo.emergency_contact_relation || ""
                        }}
                        validationSchema={productSchema}
                        onSubmit={handleProductSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            resetForm,
                            validateForm,
                            validateField
                        }) => {
                            console.log(errors)
                            return (
                                <>

                                    <Form onSubmit={handleSubmit}>
                                        {
                                            step === 0 && <>
                                                <Row >
                                                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                        <Form.Label>Sales Organization</Form.Label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={salesOrganization}
                                                            placeholder="Select salesOrganization"
                                                            classNamePrefix="react-select"
                                                            name="sales_organization_id"
                                                            value={values.sales_organization_id}
                                                            onChange={(selectedOption) => {
                                                                setFieldValue(
                                                                    "sales_organization_id",
                                                                    selectedOption
                                                                );
                                                            }}
                                                            onBlur={handleBlur}
                                                        />

                                                        {
                                                            errors.sales_organization_id && (
                                                                <div style={{ color: "red" }}>
                                                                    {errors.sales_organization_id}
                                                                </div>
                                                            )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                        <Form.Label>Job Role</Form.Label>
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <Select
                                                                    closeMenuOnSelect={true}
                                                                    options={jobRoles}
                                                                    placeholder="Select Job Role"
                                                                    classNamePrefix="react-select"
                                                                    name="job_role_id"
                                                                    value={values.job_role_id}
                                                                    onChange={(selectedOption) => {
                                                                        setFieldValue(
                                                                            "job_role_id",
                                                                            selectedOption
                                                                        );
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div><a className="text-warning" style={{ float: "right", marginTop: "3px", cursor: "pointer" }} onClick={() => setHandleRoleModal(true)}>Add New Role</a></div>

                                                        {
                                                            errors.job_role_id && (
                                                                <div style={{ color: "red" }}>
                                                                    {errors.job_role_id}
                                                                </div>
                                                            )}

                                                    </Form.Group>

                                                </Row>
                                                <Row >

                                                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                        <Form.Label>Designation</Form.Label>
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <Select
                                                                    closeMenuOnSelect={true}
                                                                    options={designations}
                                                                    placeholder="Select Designation"
                                                                    classNamePrefix="react-select"
                                                                    name="designation_id"
                                                                    value={values.designation_id}
                                                                    onChange={(selectedOption) => {
                                                                        setFieldValue(
                                                                            "designation_id",
                                                                            selectedOption
                                                                        );
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div><a className="text-warning" style={{ float: "right", marginTop: "3px", cursor: "pointer" }} onClick={() => setHandleDesignationModal(true)}>Add New Designation</a></div>

                                                        {
                                                            errors.designation_id && (
                                                                <div style={{ color: "red" }}>
                                                                    {errors.designation_id}
                                                                </div>
                                                            )}

                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                        <Form.Label>Select Bank</Form.Label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={bankAccounts}
                                                            placeholder="Select Bank Account"
                                                            classNamePrefix="react-select"
                                                            name="bank_account_id"
                                                            value={values.bank_account_id}
                                                            onChange={(selectedOption) => {
                                                                setFieldValue(
                                                                    "bank_account_id",
                                                                    selectedOption
                                                                );
                                                            }}
                                                            onBlur={handleBlur}
                                                        />

                                                        {
                                                            errors.bank_account_id && (
                                                                <div style={{ color: "red" }}>
                                                                    {errors.bank_account_id}
                                                                </div>
                                                            )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control
                                                            name="first_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter First Name"
                                                            required
                                                            value={values.first_name}
                                                        />
                                                        {touched.first_name && errors.first_name && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.first_name}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Middle Name</Form.Label>
                                                        <Form.Control
                                                            name="middle_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter Middle Name"
                                                            required
                                                            value={values.middle_name}
                                                        />
                                                        {touched.middle_name && errors.middle_name && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.middle_name}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control
                                                            name="last_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter Last Name"
                                                            required
                                                            value={values.last_name}
                                                        />
                                                        {touched.last_name && errors.last_name && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.last_name}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group
                                                        as={Col}
                                                        md="6"
                                                        controlId="exampleFirstName"
                                                    >
                                                        <Form.Label>Date Of Birth</Form.Label>

                                                        <Form.Control

                                                            className="form-control"
                                                            placeholderText="Select Date Of Birth"
                                                            type="date"
                                                            name="date_of_birth"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.date_of_birth}
                                                            required
                                                        />


                                                        {touched.date_of_birth &&
                                                            errors.date_of_birth && (
                                                                <div style={{ color: "red" }}>
                                                                    {errors.date_of_birth}
                                                                </div>
                                                            )}
                                                    </Form.Group>

                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                        <Form.Label>Blood Group</Form.Label>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            options={blood_groups}
                                                            placeholder="Select Blood Group"
                                                            classNamePrefix="react-select"
                                                            name="blood_group"
                                                            value={values.blood_group}
                                                            onChange={(selectedOption) => {
                                                                setFieldValue(
                                                                    "blood_group",
                                                                    selectedOption
                                                                );
                                                            }}
                                                            onBlur={handleBlur}
                                                        />

                                                        {
                                                            errors.blood_group && (
                                                                <div style={{ color: "red" }}>
                                                                    {errors.blood_group}
                                                                </div>
                                                            )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>NID Number</Form.Label>
                                                        <Form.Control
                                                            name="nid_number"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter NID Number"
                                                            required
                                                            value={values.nid_number}
                                                        />
                                                        {touched.nid_number && errors.nid_number && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.nid_number}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Mobile Number*</Form.Label>
                                                        <Form.Control
                                                            name="mobile_number"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter Mobile Number"
                                                            required
                                                            value={values.mobile_number}
                                                        />
                                                        {touched.mobile_number && errors.mobile_number && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.mobile_number}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            name="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="email"
                                                            placeholder="Enter Email"
                                                            required
                                                            value={values.email}
                                                        />
                                                        {errors.email && touched.email && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.email}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Permanent Village</Form.Label>
                                                        <Form.Control
                                                            name="permanent_village"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            as="textarea" rows={2}
                                                            placeholder="Enter Permanent Village Address"
                                                            required
                                                            value={values.permanent_village}
                                                        />
                                                        {touched.permanent_village && errors.permanent_village && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.permanent_village}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Permanent Post Office</Form.Label>
                                                        <Form.Control
                                                            name="permanent_post_office"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            as="textarea" rows={2}
                                                            placeholder="Enter Permanent Post Office"
                                                            required
                                                            value={values.permanent_post_office}
                                                        />
                                                        {touched.permanent_post_office && errors.permanent_post_office && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.permanent_post_office}
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Permanent Post Code</Form.Label>
                                                        <Form.Control
                                                            name="permanent_post_code"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            as="textarea" rows={1}
                                                            placeholder="Enter Permanent Post Code"
                                                            required
                                                            value={values.permanent_post_code}
                                                        />
                                                        {touched.permanent_post_code && errors.permanent_post_code && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.permanent_post_code}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Permanent Upazila</Form.Label>
                                                        <Form.Control
                                                            name="permanent_upazila_id"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            as="textarea" rows={1}
                                                            placeholder="Enter Permanent Upazila"
                                                            required
                                                            value={values.permanent_upazila_id}
                                                        />
                                                        {touched.permanent_upazila_id && errors.permanent_upazila_id && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.permanent_upazila_id}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Present Village</Form.Label>
                                                        <Form.Control
                                                            name="present_village"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            as="textarea" rows={2}
                                                            placeholder="Enter Present Village"
                                                            required
                                                            value={values.present_village}
                                                        />
                                                        {touched.present_village && errors.present_village && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.present_village}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Present Post Office</Form.Label>
                                                        <Form.Control
                                                            name="present_post_office"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            as="textarea" rows={2}
                                                            placeholder="Enter Present Post Office"
                                                            required
                                                            value={values.present_post_office}
                                                        />
                                                        {touched.present_post_office && errors.present_post_office && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.present_post_office}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Present Post Code</Form.Label>
                                                        <Form.Control
                                                            name="present_post_code"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            as="textarea" rows={2}
                                                            placeholder="Enter Present Post Code"
                                                            required
                                                            value={values.present_post_code}
                                                        />
                                                        {touched.present_post_code && errors.present_post_code && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.present_post_code}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Present Upazila</Form.Label>
                                                        <Form.Control
                                                            name="present_upazila_id"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            as="textarea" rows={2}
                                                            placeholder="Enter Present Upazila"
                                                            required
                                                            value={values.present_upazila_id}
                                                        />
                                                        {touched.present_upazila_id && errors.present_upazila_id && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.present_upazila_id}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group
                                                        as={Col}
                                                        md="6"
                                                        controlId="exampleFirstName"
                                                    >
                                                        <Form.Label>Date Of Joining*</Form.Label>

                                                        <InputGroup>
                                                            <Form.Control
                                                                // selected={new Date(values.date_of_joining)}
                                                                // formatWeekDay={(day) => day.slice(0, 3)}
                                                                // dateFormat="MMMM d, yyyy"
                                                                // minDate={new Date()}
                                                                className="form-control"
                                                                placeholderText="Select Date Of Joining"
                                                                type="date"
                                                                name="date_of_joining"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.date_of_joining}
                                                            />
                                                        </InputGroup>


                                                        {touched.date_of_joining &&
                                                            errors.date_of_joining && (
                                                                <div style={{ color: "red" }}>
                                                                    {errors.date_of_joining}
                                                                </div>
                                                            )}
                                                    </Form.Group>
                                                    <Form.Group
                                                        as={Col}
                                                        md="6"
                                                        controlId="exampleFirstName"
                                                    >
                                                        <Form.Label>Date Of Resignation*</Form.Label>
                                                        <InputGroup hasValidation>

                                                            <Form.Control
                                                                // selected={new Date(values.date_of_resignation)}
                                                                // formatWeekDay={(day) => day.slice(0, 3)}
                                                                // dateFormat="MMMM d, yyyy"
                                                                // minDate={new Date()}
                                                                className="form-control"
                                                                placeholderText="Select Date Of Resignation"
                                                                type="date"
                                                                name="date_of_resignation"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.date_of_resignation}
                                                            />
                                                        </InputGroup>

                                                        {touched.date_of_resignation &&
                                                            errors.date_of_resignation && (
                                                                <div style={{ color: "red" }}>
                                                                    {errors.date_of_resignation}
                                                                </div>
                                                            )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Basic Salary*</Form.Label>
                                                        <Form.Control
                                                            name="basic_salary"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter Basic Salary"
                                                            required
                                                            value={values.basic_salary}
                                                        />
                                                        {touched.basic_salary && errors.basic_salary && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.basic_salary}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>House Rent</Form.Label>
                                                        <Form.Control
                                                            name="house_rent"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter house_rent"
                                                            required
                                                            value={values.house_rent}
                                                        />
                                                        {touched.house_rent && errors.house_rent && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.house_rent}
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Medical Allowance*</Form.Label>
                                                        <Form.Control
                                                            name="medical_allowance"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter Medical Allowance"
                                                            required
                                                            value={values.medical_allowance}
                                                        />
                                                        {touched.medical_allowance && errors.medical_allowance && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.medical_allowance}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Mobile Bill*</Form.Label>
                                                        <Form.Control
                                                            name="mobile_bill"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter Mobile Bill"
                                                            required
                                                            value={values.mobile_bill}
                                                        />
                                                        {touched.mobile_bill && errors.mobile_bill && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.mobile_bill}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Internet Bill</Form.Label>
                                                        <Form.Control
                                                            name="internet_bill"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter Internet Bill"
                                                            required
                                                            value={values.internet_bill}
                                                        />
                                                        {touched.internet_bill && errors.internet_bill && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.internet_bill}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Daily Allowance</Form.Label>
                                                        <Form.Control
                                                            name="daily_allowance"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter Daily Allowance"
                                                            required
                                                            value={values.daily_allowance}
                                                        />
                                                        {touched.daily_allowance && errors.daily_allowance && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.daily_allowance}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Traveling Daily Allowance</Form.Label>
                                                        <Form.Control
                                                            name="travelling_daily_allowance"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter Travelling Daily Allowance"
                                                            required
                                                            value={values.travelling_daily_allowance}
                                                        />
                                                        {touched.travelling_daily_allowance && errors.travelling_daily_allowance && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.travelling_daily_allowance}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Meeting Traveling Allowance</Form.Label>
                                                        <Form.Control
                                                            name="meeting_travelling_allowance"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter Meeting Traveling Allowance"
                                                            required
                                                            value={values.meeting_travelling_allowance}
                                                        />
                                                        {touched.meeting_travelling_allowance && errors.meeting_travelling_allowance && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.meeting_travelling_allowance}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Meeting Daily Allowance</Form.Label>
                                                        <Form.Control
                                                            name="meeting_daily_allowance"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter Meeting Daily Allowance"
                                                            required
                                                            value={values.meeting_daily_allowance}
                                                        />
                                                        {touched.meeting_daily_allowance && errors.meeting_daily_allowance && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.meeting_daily_allowance}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>City Allowance</Form.Label>
                                                        <Form.Control
                                                            name="city_allowance"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter City Allowance"
                                                            required
                                                            value={values.city_allowance}
                                                        />
                                                        {touched.city_allowance && errors.city_allowance && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.city_allowance}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Contribution Percentage</Form.Label>
                                                        <Form.Control
                                                            name="contribution_percentage"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="number"
                                                            placeholder="Enter Contribution Percentage"
                                                            required
                                                            value={values.contribution_percentage}
                                                        />
                                                        {touched.contribution_percentage && errors.contribution_percentage && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.contribution_percentage}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Other Allowance</Form.Label>
                                                        <Form.Control
                                                            name="other_allowance"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter Other Allowance"
                                                            required
                                                            value={values.other_allowance}
                                                        />
                                                        {touched.other_allowance && errors.other_allowance && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.other_allowance}
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Emergency Contact Name</Form.Label>
                                                        <Form.Control
                                                            name="emergency_contact_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter Emergency Contact Name"
                                                            required
                                                            value={values.emergency_contact_name}
                                                        />
                                                        {touched.emergency_contact_name && errors.emergency_contact_name && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.emergency_contact_name}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Emergency Mobile Number</Form.Label>
                                                        <Form.Control
                                                            name="emergency_mobile_number"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter Emergency Mobile Number"
                                                            required
                                                            value={values.emergency_mobile_number}
                                                        />
                                                        {touched.emergency_mobile_number && errors.emergency_mobile_number && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.emergency_mobile_number}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                                        <Form.Label>Emergency Contact Relation</Form.Label>
                                                        <Form.Control
                                                            name="emergency_contact_relation"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter Emergency Contact Relation"
                                                            required
                                                            value={values.emergency_contact_relation}
                                                        />
                                                        {touched.emergency_contact_relation && errors.emergency_contact_relation && (
                                                            <div style={{ color: "red" }}>
                                                                {errors.emergency_contact_relation}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>

                                                {/* disabled={!values.first_name || !values.middle_name || !values.last_name || !values.date_of_birth || !values.salesOrganizationId?.value || !values.employeeType?.value || !values.designationId?.value || !values.mobile_number || !values.address || !values.divisionId?.value || !values.regionId?.value || !values.zoneId?.value || !values.date_of_joining || !values.date_of_resignation || !values.basic_salary || !values.medical_allowance || !values.internet_bill || !values.mobile_bill || !values.travelling_daily_allowance || !values.meeting_travelling_allowance || !values.meeting_daily_allowance || !values.city_allowance || !values.contribution_percentage} */}
                                                <div className="mt-4">
                                                    <Button className="me-2  px-5" variant="primary" type="submit"  >Next</Button><Button
                                                        className="px-5"
                                                        onClick={() => navigate("/master/employee")}
                                                        variant="danger" type="Cancel"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    </Form>



                                    {
                                        step === 1 && <>

                                            {/* <Row className="mb-3">
                                                    <Card >
                                                        <Card.Header as="h6" className="bg-light">
                                                            Educational Qualification :
                                                        </Card.Header>
                                                        <Card.Body>
                                                            {educationList?.map((education, index) => (
                                                                <Row key={index} className="gx-2 flex-between-center mb-3">
                                                                    <Col sm={6}>
                                                                        <h6 className="mb-0 text-600">{education.degreeName}</h6>
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <h6 className="mb-0 text-600">{education.passingYear}</h6>
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <Flex justifyContent="between" alignItems="center">
                                                                            <h6 className="mb-0 text-700">{education.result}</h6>
                                                                            <Button
                                                                                variant="link"
                                                                                to="#!"
                                                                                type="button"
                                                                                className="text-danger"
                                                                                size="sm"
                                                                                onClick={() => removeEducation(education.id)}
                                                                            >
                                                                                <FontAwesomeIcon className="fs--1" icon="trash-alt" />
                                                                            </Button>
                                                                        </Flex>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            <Row className="gy-3 gx-2">

                                                                <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                                                    <Form.Label>Degree</Form.Label>
                                                                    <Select
                                                                        closeMenuOnSelect={true}
                                                                        options={education_degrees}
                                                                        placeholder="Select Degree"
                                                                        classNamePrefix="react-select"
                                                                        name="degreeId"
                                                                        value={values.degreeId}
                                                                        onChange={(selectedOption) => {
                                                                            setFieldValue(
                                                                                "degreeId",
                                                                                selectedOption
                                                                            );
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    />

                                                                    {
                                                                        errors.degreeId && (
                                                                            <div style={{ color: "red" }}>
                                                                                {errors.degreeId}
                                                                            </div>
                                                                        )}
                                                                </Form.Group>

                                                                <Form.Group
                                                                    as={Col}
                                                                    md="3"
                                                                    controlId="exampleFirstName"
                                                                >
                                                                    <Form.Label>Passing Year</Form.Label>
                                                                    <InputGroup hasValidation>

                                                                        <DatePicker
                                                                            selected={values.passingYear}
                                                                            showYearPicker
                                                                            dateFormat="yyyy"
                                                                            className="form-control"
                                                                            placeholderText="Select PassingYear"
                                                                            name="passingYear"
                                                                            onChange={(fieldValue) => setFieldValue('passingYear', fieldValue)}
                                                                            onBlur={handleBlur}
                                                                            value={values.passingYear}
                                                                        />
                                                                    </InputGroup>

                                                                    {touched.passingYear &&
                                                                        errors.passingYear && (
                                                                            <div style={{ color: "red" }}>
                                                                                {errors.passingYear}
                                                                            </div>
                                                                        )}
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="3" controlId="exampleState">
                                                                    <Form.Label>Result</Form.Label>
                                                                    <Form.Control
                                                                        name="result"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="text"
                                                                        placeholder="Enter Result"

                                                                        value={values.result}
                                                                    />
                                                                    {touched.result && errors.result && (
                                                                        <div style={{ color: "red" }}>
                                                                            {errors.result}
                                                                        </div>
                                                                    )}
                                                                </Form.Group>


                                                                <Form.Group as={Col} md="2" >

                                                                    <Button

                                                                        variant="falcon-default"
                                                                        className=" "
                                                                        type="button"
                                                                        style={{ marginTop: '30px' }}
                                                                        disabled={
                                                                            !values.degreeId || !values.passingYear || !values.result
                                                                        }
                                                                        onClick={() => {

                                                                            if (educationList.find((educationItem) => educationItem.id == values.degreeId.id)) {
                                                                                return toast.error("Already Added !")
                                                                            }
                                                                            else {
                                                                                setEducationList([...educationList, { id: values.degreeId.id, degreeName: values.degreeId.value, passingYear: values.passingYear.getFullYear(), result: values.result }]);
                                                                                setFieldValue('degreeId', 0);
                                                                                setFieldValue('passingYear', '');
                                                                                setFieldValue('result', '');
                                                                            }

                                                                        }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </Form.Group>

                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Row>

                                                <Button className="me-3" onClick={handlePrev}>Previous</Button>
                                                <IconButton
                                                    variant="primary"
                                                    className="ms-auto px-5"
                                                    type="submit"
                                                    iconAlign="right"
                                                    transform="down-1 shrink-4"

                                                >
                                                    Submit
                                                </IconButton> */}
                                            <EmployeeExperienceForm employeeId={employeeId} handleNext={handleNext} />

                                        </>
                                    }
                                    {
                                        step === 2 && <>
                                            <EmployeeEducationForm employeeId={employeeId} />
                                        </>
                                    }



                                </>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard >

            <Modal
                show={handleRoleModal}
                size="lg"
                onHide={handleRoleModal}
                contentClassName="border-0"
                dialogClassName="mt-6"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Add New Job Role</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => setHandleRoleModal(false)}
                    />
                </Modal.Header>
                <Modal.Body className="p-3">
                    <Form onSubmit={handleJobRoleFunc}>
                        <Form.Group as={Col} md="12" controlId="exampleState">
                            <Form.Label>Role Name</Form.Label>
                            <Form.Control
                                name="job_role_name"
                                type="text"
                                pattern="^[^0-9].*"
                                title="Please start with a non-numeric character."
                                placeholder="Enter Role Name"
                                required
                            />

                        </Form.Group>
                        <IconButton
                            variant="primary"
                            className="ms-auto px-5 mt-3"
                            type="submit"
                            iconAlign="right"
                            transform="down-1 shrink-4"

                        >
                            Submit
                        </IconButton>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal
                show={handleDesignationModal}
                size="lg"
                onHide={handleDesignationModal}
                contentClassName="border-0"
                dialogClassName="mt-6"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Add New Designation</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => setHandleDesignationModal(false)}
                    />
                </Modal.Header>
                <Modal.Body className="p-3">
                    <Form onSubmit={handleDesignationFunc}>
                        <Form.Group as={Col} md="12" controlId="exampleState">
                            <Form.Label>Designation</Form.Label>
                            <Form.Control
                                name="designation_name"
                                type="text"
                                placeholder="Enter Designation"
                                pattern="^[^0-9].*"
                                title="Please start with a non-numeric character."
                                required
                            />

                        </Form.Group>
                        <IconButton
                            variant="primary"
                            className="ms-auto px-5 mt-3"
                            type="submit"
                            iconAlign="right"
                            transform="down-1 shrink-4"

                        >
                            Submit
                        </IconButton>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddEmployee;


