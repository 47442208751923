import SalesAdminDashboardCards from '../Common/SalesAdminDashboardCards/Dashboard';
import SalesAdminTarget from './Target/index'
const { Row } = require('react-bootstrap')

const AdminDashboard = ({ setIsLoading }) => {
    return (
        <div>
            <Row className="g-3 mb-3">
                <SalesAdminDashboardCards setIsLoading={setIsLoading} />
                <SalesAdminTarget setIsLoading={setIsLoading} />
            </Row>
        </div>
    )
}

export default AdminDashboard
