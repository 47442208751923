import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import React, { useEffect, useState } from 'react'
import {  Card,  Table } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import { formatNumber } from 'helpers/utils'
import SimpleBarReact from 'simplebar-react'

const AdminStockList = () => {
    const [receivedList, setReceivedList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)
    const [pageDataCount, setPageDataCount] = useState(10)
    const userInfo = useSelector((state) => state.auth.user?.data)

    const formateExcelData = (data) => {
        const formatDate = (date) => {
            return date ? new Date(date).toLocaleDateString('en-US') : ''
        }

        const formateData = data?.map((item, index) => ({
            Sl: index + 1,
            'Product Category': item.productCategory,
            'Sku Name': item.skuName,
            'Rcv Qty CTN': item.rcvQtyCTN,
            'Rcv Qty PCS': item.rcvQtyPCS,
            'Delivery Qty CTN': item.delivery?.delQtyCtn || 0,
            'Delivery Qty PCS': item.delivery?.delQtyPcs || 0,
            'Balance Qty Ctn': item.rcvQtyCTN - (item.delivery?.delQtyCtn || 0),
            'Balance Qty Pcs': item.rcvQtyPCS - (item.delivery?.delQtyPcs || 0),
            // 'Do Date': formatDate(item.doDate),
        }))

        return formateData
    }

    //
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1

        const fetchData = async () => {
            let url
            let url2
            if (userInfo?.userRole === 'Admin') {
                url = `${'https://khoz.net:3013/api/v1/'}productsRcv/groupadmin`
                url2 = `${'https://khoz.net:3013/api/v1/'}productsRcv/deliveryadmin`
            }
            if (userInfo?.userRole === 'Inventory') {
            }

            try {
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const totalPages =
                        response?.data?.payload?.pagination?.totalPages
                    const result = response?.data?.payload?.result?.map(
                        (element, index) => ({
                            ...element,
                            index: startIndex + index,
                        })
                    )

                    
                    setReceivedList(result)

                    ///////////////////////////
                    const response2 = await axios.get(url2, {
                        headers: authHeader(),
                    })

                    if (response2.data.success || response2.status === 200) {
                        const totalPages2 =
                            response2?.data?.payload?.pagination?.totalPages
                        const result2 = response2?.data?.payload?.result?.map(
                            (element, index) => ({
                                ...element,
                                index: startIndex + index,
                            })
                        )

                        const modifiedResult = result.map((item) => ({
                            ...item,
                            delivery: result2.find(
                                (my) => my.skuName == item.skuName
                            ),
                        }))

                   
                        setReceivedList(modifiedResult)
                    }
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [ paginationPageCount, pageDataCount])

    if (isLoading) {
        return <LoadingIcon />
    }

    return (
        <>
            <PageHeader title="Admin Stock List" className="mb-3"></PageHeader>

            <Card>
                <Card.Body>
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            // isSearch={true}
                            // setSearchText={setSearchText}
                            table
                            excelFileName="Admin Stock List"
                            excelData={formateExcelData(receivedList)}
                        />
                    </Card.Header>
                    <div className="mt-4 fs--1">
                        <SimpleBarReact>
                            <Table className="table table-bordered table-striped fs--1 mb-0">
                                <thead>
                                    <tr
                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#354151',
                                        }}
                                    >
                                        <th className="align-middle white-space-nowrap text-center ">
                                            SL
                                        </th>

                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Sku Name
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Rcv Qty CTN
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Rcv Qty PCS
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Del Qty CTN
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Del Qty PCS
                                        </th>

                                        <th className="align-middle white-space-nowrap text-center ">
                                            Balance Qty CTN
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Balance Qty PCS
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    className="list"
                                    id="table-purchase-body"
                                >
                                    {receivedList.length
                                        ? receivedList?.map((order, index) => {
                                              return (
                                                  <tr
                                                      key={index}
                                                      style={{
                                                          fontSize: 'medium',
                                                          fontWeight: '400',
                                                          fontFamily: 'Poppins',
                                                          color: '#354151',
                                                      }}
                                                  >
                                                      <td className="align-middle text-center">
                                                          {index + 1}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {
                                                              order.productCategory
                                                          }
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {order.skuName}
                                                      </td>

                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyCTN
                                                          )}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyPCS
                                                          )}
                                                      </td>

                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order?.delivery
                                                                  ?.delQtyCtn
                                                          )}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order?.delivery
                                                                  ?.delQtyPcs
                                                          )}
                                                      </td>

                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyCTN -
                                                                  (order
                                                                      ?.delivery
                                                                      ?.delQtyCtn ||
                                                                      0)
                                                          )}
                                                      </td>

                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyPCS -
                                                                  (order
                                                                      ?.delivery
                                                                      ?.delQtyPcs ||
                                                                      0)
                                                          )}
                                                      </td>
                                                  </tr>
                                              )
                                          })
                                        : null}

                                    <tr
                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '800',
                                            fontFamily: 'Poppins',
                                            color: 'black',
                                            backgroundColor: '#98c1d9',
                                        }}
                                    >
                                        <td
                                            colSpan={3}
                                            className="align-middle text-center"
                                        >
                                            <h5 className="mb-0 text-nowrap ">
                                                Total
                                            </h5>
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc + obj.rcvQtyCTN
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc + obj.rcvQtyPCS
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>

                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj?.delivery
                                                                ?.delQtyCtn ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj?.delivery
                                                                ?.delQtyPcs ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>

                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj.rcvQtyCTN -
                                                                (obj?.delivery
                                                                    ?.delQtyCtn ||
                                                                    0))
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj.rcvQtyPCS -
                                                                (obj?.delivery
                                                                    ?.delQtyPcs ||
                                                                    0))
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </SimpleBarReact>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default AdminStockList
