import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import PageHeader from 'components/common/PageHeader'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import { index } from 'd3'
import React from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllOrders, getAllSchedules } from 'services/api'

const OtherDistributorsOrdersUnscheduled = ({ user }) => {
    const navigate = useNavigate()
    const {
        data: scheduleData,
        isLoading: isLoadingSchedule,
        isError: scheduleError,
        refetch: scheduleRefetch,
    } = useQuery({
        queryKey: ['allSchedules'],
        queryFn: () => getAllSchedules(),
    })

    const {
        data: orderData,
        isLoading: isLoadingOrders,
        isError: ordersError,
        refetch: ordersRefetch,
    } = useQuery({
        queryKey: ['allOrders'],
        queryFn: () => getAllOrders(),
    })

    const IsShowable = (order, allScheduleData) => {
        let show = true
        const qtySum = (allScheduleData || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo && item.skuName == order.skuName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)
        const items = (allScheduleData || []).filter(
            (item) => item.doNo == order.doNo && item.skuName == order.skuName
        )

        // for (let i = 0; i < items.length; i++) {
        //     const element = items[i]
        //     if (element.status == 7) {
        //         show = false
        //         break
        //     }
        // }

        return show && qtySum == order.orderQtyCtn
    }

    const getSum = (order, allScheduleData) => {
        let show = true
        const qtySum = (allScheduleData || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo && item.skuName == order.skuName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)
        const items = (allScheduleData || []).filter(
            (item) => item.doNo == order.doNo && item.skuName == order.skuName
        )

        return qtySum
    }

    let unscheduledOrders = []

    if (
        orderData?.data.payload.result.length &&
        scheduleData?.data.payload.result
    ) {
        const allOrders = orderData.data.payload.result
        const allSchedules = scheduleData.data.payload.result
        const filteredOrders = allOrders.filter(
            (item) =>
                item.superDBId == user.data.userId &&
                item.status == 7 &&
                !IsShowable(item, allSchedules)
        )

        unscheduledOrders = filteredOrders.map((item) => ({
            ...item,
            schQtyCtn: getSum(item, allSchedules)
                ? item.orderQtyCtn - getSum(item, allSchedules)
                : 0,
        }))
    }

    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'doNo',
            Header: 'DO No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{doNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'skuName',
            Header: 'Product',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { skuName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{skuName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'orderQtyCtn',
            Header: 'Order Qty Ctn',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { orderQtyCtn } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{orderQtyCtn}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'schQtyCtn',
            Header: 'Scheduled Qty Ctn',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { schQtyCtn } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{schQtyCtn}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'totalPrice',
            Header: 'Total Price',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { totalPrice } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{totalPrice}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'distributorName',
            Header: 'Distributor',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { distributorName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{distributorName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'dbPoint',
            Header: 'DB Point',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dbPoint } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{dbPoint}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'doDate',
            Header: 'DO Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doDate } = rowData.row.original
                const date = new Date(doDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'status',
            Header: 'Status',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original

                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {status == 7 ? 'Unscheduled' : ''}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        // {
        //     accessor: 'action',
        //     Header: 'Action',
        //     headerProps: { className: 'pe-1' },
        //     cellProps: {
        //         className: 'py-2',
        //     },
        //     Cell: (rowData) => {
        //         const { scheduleNo } = rowData.row.original
        //         return (
        //             <Dropdown className="d-inline mx-2">
        //                 <Dropdown.Toggle
        //                     id="dropdown-autoclose-true"
        //                     className=" bg-none"
        //                 >
        //                     ...
        //                 </Dropdown.Toggle>

        //                 <Dropdown.Menu>
        //                     <Dropdown.Item href="">
        //                         {' '}
        //                         <Button
        //                             variant="light"
        //                             size="sm"
        //                             onClick={() =>
        //                                 navigate(
        //                                     `/distribution/productscheduling/update`,
        //                                     {
        //                                         state: {
        //                                             doNav: rowData.row.original,
        //                                         },
        //                                     }
        //                                 )
        //                             }
        //                         >
        //                             Complete Scheduling
        //                         </Button>
        //                     </Dropdown.Item>
        //                     <Dropdown.Item href="">
        //                         {/* {' '}
        //                         <Button
        //                             variant="light"
        //                             size="sm"
        //                             onClick={() => navigate("/distribution/productscheduling/details/"+scheduleNo)}
        //                         >
        //                             View Details
        //                         </Button> */}
        //                     </Dropdown.Item>
        //                 </Dropdown.Menu>
        //             </Dropdown>
        //         )
        //     },
        // },
    ]

    /*******************
    Columns End.
    *******************/

    const formatData = () => {
        const dataFormat = unscheduledOrders.map((item, i) => {
            const statusLookup = {
                7: 'Unscheduled',
            }
            const deliveryDateFormatted = new Date(item.doDate).toISOString().split('T')[0];
            return {
                SL: i + 1,
                'do No': item.doNo,
                'Product Name': item.skuName,
                'Order Qty Ctn':item.orderQtyCtn,
                'Pcs CTN': item.pcsCTN,
                'Total Price ': item.totalPrice,
                'Distributor Name':item.distributorName,
                'Db Point':item.dbPoint,
                'Do Date':deliveryDateFormatted,
                'Status': statusLookup[item.status] ,
            }
        })
        return dataFormat
    }
    return (
        <>
            <PageHeader title="Unscheduled DO" className="mb-3"></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={unscheduledOrders.map((item, i) => ({
                    ...item,
                    index: i + 1,
                }))}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            newUrl="/distribution/scheduling"
                            isSearch={true}
                            setSearchText={'setSearchText'}
                            excelFileName="Unscheduled DO"
                            excelData={formatData()}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination
                            table
                            paginationPageCount={1}
                            setPaginationPageCount={() => ({})}
                            pageDataCount={1}
                            setPageDataCount={1}
                            totalPages={1}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OtherDistributorsOrdersUnscheduled)
