import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";


const FactoryManagementForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [factoryById, setFactoryById] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();


    // Load Factory by id
    useEffect(() => {
        if (updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + `factories/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setFactoryById(response.data.data);
                    }
                })
        }
    }, [])


    const handleFactorySubmit = (values, actions) => {

        const factoryInformation =

        {
            factory_name: values.factory_name,
        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + 'factories'
            axios.post(url, factoryInformation, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        navigate("/factoryManagements")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(setIsLoading(false))
        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + `factories/${updateID}`;
            axios.put(updateUrl, factoryInformation, { headers: authHeader() })
                .then(response => {

                    if (response.data.success || response.data.statusCode === 200) {
                        navigate("/factoryManagements")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    console.log("check factory", factoryById)
    isLoading && <LoadingIcon />


    const factorySchema = Yup.object().shape({
        factory_name: Yup.string().required("Please enter a value for the Factory Name field"),


    });


    return (
        <>
            <PageHeader
                title={updateID ? 'Update Factory' : 'Add New Factory'}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            factory_name: factoryById.factory_name || "",

                        }}


                        validationSchema={factorySchema}
                        onSubmit={handleFactorySubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            resetForm
                        }) => {
                            console.log(values.depotId)
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Factory Name</Form.Label>
                                            <Form.Control
                                                name="factory_name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Factory Name"
                                                required
                                                value={values.factory_name}
                                            />
                                            {touched.factory_name && errors.factory_name && (
                                                <div style={{ color: "red" }}>
                                                    {errors.factory_name}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"


                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/factoryManagements")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>

    );
};

export default FactoryManagementForm;


