import { useEffect, useState } from "react";
import DistributorOrderCreateStep1 from "./DistributorOrderCreateStep1";
import DistributorOrderCreateStep2 from "./DistributorOrderCreateStep2";
import { useSelector } from "react-redux";
import DistributorOrderCreateStep3 from "./DistributorOrderCreateStep3";
import Swal from "sweetalert2";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { authHeader } from "utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DistributorOrders = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [count, setCount] = useState(0);
    const [checkedProducts, setCheckedProducts] = useState([]);
    const [distributorOrderNumber, setDistributorOrderNumber] = useState(0);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const navigate = useNavigate();


    // fetch orders data from API
    const organizeOrdersByDoNo = (orders) => {
        const ordersByDoNo = {};

        orders?.filter(order => order?.status === 0)?.forEach((order) => {
            const { doNo, dbPoint, region, zone, area, dealerId, distributorName, orderQtyCtn, totalOrderQtyPCS, totalPrice, discountBDT, status } = order;
            if (!ordersByDoNo[doNo]) {
                ordersByDoNo[doNo] = {
                    doNo: doNo,
                    dbPoint: dbPoint,
                    region: region,
                    zone: zone,
                    area: area,
                    dealerId: dealerId,
                    distributorName: distributorName,
                    status: status,

                };
            }
        });

        const result = Object.values(ordersByDoNo);

        return result;
    };

    // fetch orders data from API
    useEffect(() => {
        // setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    // `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders?dealerId=${distributorInfo?.userId}`,
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/OrdersZeroStatus/${distributorInfo?.userId}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.result;
                    const ordersByDoNo = organizeOrdersByDoNo(result);
                    const doNo = ordersByDoNo?.length > 0 ? ordersByDoNo[0]?.doNo : 0;

                    if (ordersByDoNo?.length > 0) {
                        Swal.fire({
                            title: "You have incomplete orders!",
                            text: "So please complete your orders or delete them.",
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: "Go to dashboard",
                            denyButtonText: `Delete orders`,
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate(`/`);
                            } else if (result.isDenied) {

                                const fetchData = async () => {
                                    try {
                                        setIsLoading(true)
                                        const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/delete/${doNo}`;
                                        const response = await axios.delete(url, { headers: authHeader() });

                                        if (response.data.success || response.status === 200) {
                                            Swal.fire({
                                                title: "Deleted!",
                                                text: "Your orders has been deleted.",
                                                icon: "success"
                                            });

                                            setCount((prevCount) => prevCount + 1);
                                        }
                                    } catch (error) {
                                        handleApiResponseErrors(error.response);
                                    } finally {
                                        setIsLoading(false);
                                    }
                                };
                                fetchData();
                            }
                        });
                    }


                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                // setIsLoading(false);
            }
        };

        fetchData();
    }, [distributorInfo?.userId, count]);

    // Handle next step
    const handleNext = () => {
        setStep(step + 1);
    }

    // Handle back step
    const handleBack = () => {
        setStep(step - 1);
    }


    //write a function to generate a random number for large applications software to handle order number
    const result = Math.floor(Math.random() * 1000000000);
    if (distributorOrderNumber === 0) {
        setDistributorOrderNumber(prevState => `${result}-${distributorInfo?.userId}`);
    }

    return (
        <>
            {step === 1 && (
                <DistributorOrderCreateStep1 handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
            )}
            {step === 2 && (
                <DistributorOrderCreateStep2 setStep={setStep} handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} setDistributorOrderNumber={setDistributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
            )}
            {step === 3 && (
                <DistributorOrderCreateStep3 setStep={setStep} distributorOrderNumber={distributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} setDistributorOrderNumber={setDistributorOrderNumber} />
            )}
        </>
    )
}

export default DistributorOrders;