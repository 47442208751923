import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import useUserRole from "hooks/useUserRole";
import { useDropzone } from "react-dropzone";
import FalconCloseButton from "components/common/FalconCloseButton";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from "react-toastify";
import is from "is_js";
import { authHeaderForm } from "state/ducs/auth/utils";

const DsmDistributor = () => {
    const [distributorList, setDistributorList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [showUploadCSV, setShowUploadCSV] = useState(false);
    const [count, setCount] = useState(0);
    const userInfo = useSelector((state) => state.auth.user?.data);
    const userRole = useUserRole();

    // csv Modal 
    const handleShowCSV = () => {
        setShowUploadCSV(true);
    };


    // DISTRIBUTOR LIST
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

        const fetchData = async () => {

            let url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?zoneName=${userInfo?.zoneName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
            try {
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const totalPages = response?.data?.payload?.pagination?.totalPages;
                    const result = response?.data?.payload?.distributors?.map((element, index) => ({
                        ...element,
                        index: startIndex + index
                    }));

                    setTotalPages(totalPages);
                    setDistributorList(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount, searchText, userInfo]);

    // DELETE DISTRIBUTOR
    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };

    /* CSV FILE UPLOAD */
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const handleCSVUpload = () => {
        const csvFile = acceptedFiles[0];
        const formData = new FormData();
        formData.append("csvFile", csvFile);

        const regUrl = process.env.REACT_APP_USER_MANAGEMENT_BASE_URL + "users/dbs/upload";

        setIsLoading(true);
        axios
            .post(regUrl, formData, { headers: authHeaderForm() })
            .then((response) => {
                if (response.data.success === true || response.status === 200) {
                    toast.success("Csv File Upload Success");
                    setShowUploadCSV(false);
                    setCount((prevState) => prevState + 1);
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(() => {
                setIsLoading(false);
            });
    };

    // EXCEL FILE DOWNLOAD
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.distributors;
                    setExcelData(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            }
        };
        fetchData();
    }, []);

    const columns = [
        { accessor: "index", Header: "SL", cellProps: { className: "py-2" }, Cell: ({ row: { original: { index } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{index}</h5></div></Flex> },
        { accessor: "zoneName", Header: "Zone Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { zoneName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{zoneName}</h5></div></Flex> },
        { accessor: "regionName", Header: "Region Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { regionName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{regionName}</h5></div></Flex> },
        { accessor: "areaName", Header: "Area Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { areaName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{areaName}</h5></div></Flex> },
        { accessor: "dbPoint", Header: "DB Point", cellProps: { className: "py-2" }, Cell: ({ row: { original: { dbPoint } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{dbPoint}</h5></div></Flex> },
        { accessor: "userId", Header: "Distributor Id", cellProps: { className: "py-2" }, Cell: ({ row: { original: { userId } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{userId}</h5></div></Flex> },
        { accessor: "password", Header: "Password", cellProps: { className: "py-2" }, Cell: ({ row: { original: { password } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{password}</h5></div></Flex> },
        { accessor: "ERP ID", Header: "ERP ID", cellProps: { className: "py-2" }, Cell: ({ row: { original: { erpId } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{erpId}</h5></div></Flex> },
        { accessor: "DB Name", Header: "DB Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { distributorName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{distributorName}</h5></div></Flex> },
        { accessor: "userRole", Header: "DB Type", cellProps: { className: "py-2" }, Cell: ({ row: { original: { userRole } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{userRole}</h5></div></Flex> },
        { accessor: "superDBId", Header: "Super DB ID", cellProps: { className: "py-2" }, Cell: ({ row: { original: { superDBId } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{superDBId ? superDBId : "-"}</h5></div></Flex> },
        { accessor: "Secondary Apps Live", Header: "Apps Live", cellProps: { className: "py-2" }, Cell: ({ row: { original: { hasLiveApp } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{hasLiveApp === true ? "Yes" : "No"}</h5></div></Flex> },
        { accessor: "Direct Sales", Header: "Direct Sales", cellProps: { className: "py-2" }, Cell: ({ row: { original: { hasDirectSale } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{hasDirectSale === true ? "Yes" : "No"}</h5></div></Flex> },
        { accessor: "Status", Header: "Status", cellProps: { className: "py-2" }, Cell: ({ row: { original: { status } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{status === true ? "Yes" : "No"}</h5></div></Flex> },
        { accessor: "Proprietor Name", Header: "Proprietor Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { proprietor } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{proprietor}</h5></div></Flex> },
        { accessor: "DOB", Header: "DOB", cellProps: { className: "py-2" }, Cell: ({ row: { original: { proprietorDOB } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{new Date(proprietorDOB).toLocaleDateString()}</h5></div></Flex> },
        { accessor: "dbOpeningDate", Header: "DB Opening Date", cellProps: { className: "py-2" }, Cell: ({ row: { original: { dbOpeningDate } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{new Date(dbOpeningDate).toLocaleDateString()}</h5></div></Flex> },
        { accessor: "Address", Header: "Address", cellProps: { className: "py-2" }, Cell: ({ row: { original: { address } } }) => <Flex alignItems="center"><div className="flex-1" style={{ width: "200px" }}><h5 className="mb-0 fs--1">{address}</h5></div></Flex> },
        { accessor: "Mobile", Header: "Mobile", cellProps: { className: "py-2" }, Cell: ({ row: { original: { phoneNumber } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{phoneNumber}</h5></div></Flex> },
        { accessor: "Emergency Person", Header: "Emergency Person", cellProps: { className: "py-2" }, Cell: ({ row: { original: { emergencyContactName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{emergencyContactName ? emergencyContactName : "-"}</h5></div></Flex> },
        { accessor: "Emergency Mobile", Header: "Emergency Mobile", cellProps: { className: "py-2" }, Cell: ({ row: { original: { emergencyMobileNumber } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{emergencyMobileNumber ? emergencyMobileNumber : "-"}</h5></div></Flex> },
        { accessor: "Relation With Owner", Header: "Relation With Owner", cellProps: { className: "py-2" }, Cell: ({ row: { original: { emergencyContactRelation } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{emergencyContactRelation ? emergencyContactRelation : "-"}</h5></div></Flex> },
        { accessor: "PC Exist", Header: "PC Exist", cellProps: { className: "py-2" }, Cell: ({ row: { original: { hasPC } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{hasPC === true ? "Yes" : "No"}</h5></div></Flex> },
        { accessor: "Printer Exist", Header: "Printer Exist", cellProps: { className: "py-2" }, Cell: ({ row: { original: { hasPrinter } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{hasPrinter === true ? "Yes" : "No"}</h5></div></Flex> },
        {
            accessor: "action", Header: "Action", cellProps: { className: "py-2" }, Cell: ({ row: { original: { _id } } }) => (
                <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                        ...
                    </Dropdown.Toggle>
                    {userRole === "Admin" && (
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <Link to={`/user/distributor/add/${_id}`}>
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="edit"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                    </IconButton>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <IconButton
                                    onClick={() => handleDelete(_id)}
                                    variant="falcon-default"
                                    size="sm"
                                    icon="trash-alt"
                                    iconAlign="middle"
                                    className="d-none d-sm-block me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                </IconButton>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    )}
                </Dropdown>
            )
        }
    ];

    return (
        <>
            <PageHeader title="Distributor" className="mb-3" />
            {isLoading ? (
                <LoadingIcon />
            ) : (
                <AdvanceTableWrapper columns={columns} data={distributorList} sortable pagination perPage={50}>
                    <Card className="mb-3">
                        <Card.Header>
                            <CustomersTableHeader
                                newUrl="/user/distributor/add"
                                isSearch={true}
                                setSearchText={setSearchText}
                                buttonTitle="Upload CSV"
                                handleShowCSV={handleShowCSV}
                                excelFileName="Distributors"
                                excelData={excelData}
                                table
                            />
                        </Card.Header>
                        <Card.Body className="p-0">
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle"
                                tableProps={{
                                    size: "sm",
                                    striped: true,
                                    className: "fs--1 mb-0 overflow-hidden",
                                }}
                            />
                        </Card.Body>
                        <Card.Footer>
                            <AdvanceTablePagination
                                table
                                paginationPageCount={paginationPageCount}
                                setPaginationPageCount={setPaginationPageCount}
                                pageDataCount={pageDataCount}
                                setPageDataCount={setPageDataCount}
                                totalPages={totalPages}
                            />
                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>
            )}
            {/* CSV File Upload Modal */}
            <Modal
                size="lg"
                show={showUploadCSV}
                onHide={() => setShowUploadCSV(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Upload Distributor CSV File
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShowUploadCSV(false)} />
                </Modal.Header>
                <Modal.Body>
                    <div {...getRootProps({ className: "dropzone-area py-6" })}>
                        <input {...getInputProps({ multiple: false })} />
                        <Flex justifyContent="center">
                            <img src={cloudUpload} alt="" width={25} className="me-2" />
                            <p className="fs-0 mb-0 text-700">Drop your file here</p>
                        </Flex>
                    </div>
                    <div className="mt-3">
                        {acceptedFiles.length > 0 && (
                            <>
                                <h6>File</h6>
                                <ul>{files}</ul>
                            </>
                        )}
                    </div>
                    <Button variant="primary" onClick={handleCSVUpload}>
                        Submit
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DsmDistributor;
