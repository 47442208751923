import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import {
    Card,
    Dropdown
} from 'react-bootstrap';
import axios from 'axios';
import { authHeader } from 'utils';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import handleApiResponseErrors from 'helpers/handleApiResponse';
import LoadingIcon from 'helpers/LoadingIcon';

const DeliveryApproval = () => {
    const [deliveryApproval, setDeliveryApproval] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [paginationPageCount, setPaginationPageCount] = useState(0);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);



    useEffect(() => {

        setIsLoading(true);
        const url = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + "delivery-approvals"
        axios
            .get(url, { headers: authHeader() })
            .then((response) => {
                let index = 1;
                const result = [];
                if (response.data.success || response.data.statusCode === 200) {
                    response?.data?.data?.forEach((element) => {
                        const addIndex = { ...element, index };
                        result.push(addIndex);
                        index++;
                    });
                    setDeliveryApproval(result);
                }

            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(
                setIsLoading(false)
            )
    }, [count]);


    // Delete  Item
    const handleDelete = (id) => {

        if (confirm("Are You Sure ?")) {
            setIsLoading(true);
            axios.delete(process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + `delivery-approvals/${id}`, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        toast.success(response.data.message);
                        setCount(count + 1);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }

    if (isLoading) {
        return <LoadingIcon />
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{index}</h5>
                        </div>
                    </Flex>

                );
            }
        },

        {
            accessor: 'primary_order_id',
            Header: 'Primary Order',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { primary_order_id } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{primary_order_id}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'title',
            Header: 'Title',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { title } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{title}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'description',
            Header: 'Description',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { description } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{description}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { id } = rowData.row.original;
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true" className=' bg-none'>
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href=""> <Link to={`/deliveryApproval/add/${id}`}>
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="edit"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                </IconButton>
                            </Link></Dropdown.Item>
                            <Dropdown.Item href="">
                                <IconButton
                                    onClick={() => handleDelete(id)}
                                    variant="falcon-default"
                                    size="sm"
                                    icon="trash-alt"
                                    iconAlign="middle"
                                    className="d-none d-sm-block me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                </IconButton>

                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                );
            }
        }
    ];

    /*******************
Columns End.
*******************/


    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={deliveryApproval}
                sortable
                pagination
                perPage={100}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader title="Delivery Approval" newUrl="/deliveryApproval/add" isFilter={false} isExport={false} table />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        {/* <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} /> */}
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>



        </>
    );
};

export default DeliveryApproval;
