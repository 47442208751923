import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { useSelector } from "react-redux";

const OfferForm = () => {
    const [selectedZone, setSelectedZone] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    // const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState([]); //array of category names
    const [selectedOfferProductCategory, setSelectedOfferProductCategory] = useState('');
    const [selectedDBPoint, setSelectedDBPoint] = useState([]);
    const [selectedArea, setSelectedArea] = useState([])
    const [zoneList, setZoneList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [offerProductCategoryList, setOfferProductCategoryList] = useState([]);
    const [offerProducts, setOfferProducts] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [offerById, setOfferById] = useState(null);
    const [dbPointList, setDbPointList] = useState([]);
    const [offerTypesList, setOfferTypesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);  // for loading icon
    const { updateID } = useParams();
    const navigate = useNavigate();
    const { companyName } = useSelector((state) => state.auth.user.data);


    //  Date Format
    function formatDateToYYYYMMDD(isoDateString) {
        const date = new Date(isoDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }


    const statusOptions = [
        {
            id: 1,
            label: "Active",
            value: 1
        }, {
            id: 0,
            label: "Inactive",
            value: 0
        }
    ]


    const specialOptions = [
        {
            label: "Special",
            value: "PCS"
        }, {
            label: "Non Special",
            value: "CTN"
        }
    ]

    const unitList = [
        {
            label: "PCS",
            value: "PCS"
        }, {
            label: "CTN",
            value: "CTN"
        },
        {
            label: "KG",
            value: "KG"
        },
        {
            label: "LTR",
            value: "LTR"
        },
        {
            label: "GRM",
            value: "GRM"
        },
        {
            label: "ML",
            value: "ML"
        }

    ]


    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success) {
                        setOfferById(response?.data?.payload?.offer);
                        setSelectedZone(response?.data?.payload?.offer?.zoneName)
                        setSelectedRegion(response?.data?.payload?.offer?.regionName)
                        setSelectedArea(response?.data?.payload?.offer?.areaName);
                        setSelectedCategory(response?.data?.payload?.offer?.productCategory)
                        setSelectedDBPoint(response?.data?.payload?.offer?.dbPoint)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);



    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}offerType`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setOfferTypesList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setZoneList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedZone.length) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    // const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`;
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region/multiRegion`;
                    const response = await axios.post(url, { zoneNames: selectedZone }, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.RegionInfo?.map(data => ({
                            id: data._id,
                            label: data.regionName,
                            value: data.regionName
                        }));

                        setRegionList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [selectedZone.length]);

    console.log("selectedRegion", selectedRegion);


    useEffect(() => {
        if (selectedRegion.length) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}area/MultiArea`;
                    const response = await axios.post(url, { regionNames: selectedRegion }, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.areaInfo?.map(data => ({
                            id: data._id,
                            label: data.areaName,
                            value: data.areaName
                        }));

                        setAreaList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [selectedRegion.length]);

    useEffect(() => {
        if (selectedArea.length) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint/MultiDBPoints`;
                    const response = await axios.post(url, { areaNames: selectedArea }, { headers: authHeader() });
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.dbPointInfo?.map(data => ({
                            id: data._id,
                            label: data.dbPointName,
                            value: data.dbPointName
                        }));
                        setDbPointList(modifyData);
                    }
                }
                catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
            fetchData();
        }
    }, [selectedArea.length])


    useEffect(() => {

        if (selectedDBPoint.length) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    // const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors/MultiDBs`;
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/MultiDBs`;
                    const response = await axios.post(url, { dbPoints: selectedDBPoint }, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.dbtInfo?.map(data => ({
                            id: data.userId,
                            label: data.distributorName + " (" + data.userId + ")",
                            value: data.userId
                        }));

                        setDistributorList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }


    }, [selectedDBPoint.length]);


    // PRODUCT CATEGORY
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productCategory/all/prods`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.proCatData.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name
                    }));

                    setCategoryList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // OFFER PRODUCT CATEGORY
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productCategory/all/prods`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.proCatData.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name
                    }));

                    setOfferProductCategoryList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // products
    useEffect(() => {
        if (selectedCategory) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    // const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?categoryName=${selectedCategory}`;
                    const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products/multipleCategoryWiseProducts`;
                    const response = await axios.post(url, { categories: selectedCategory }, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        // const modifyData = response?.data?.payload?.products?.map(data => ({
                        const modifyData = response?.data?.payload?.proData?.map(data => ({
                            id: data._id,
                            label: data.skuName,
                            value: data.skuName
                        }));

                        setProductsList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [selectedCategory]);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offerproduct?categoryName=${selectedOfferProductCategory}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const totalPages = response.data.payload.
                        products;
                    const result = totalPages?.map(item => ({ label: item.skuName, value: item.skuName, price: item.offerPrice }))
                    setOfferProducts(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [selectedOfferProductCategory]);

    console.log("prod=", productsList?.find(item => item.value === offerById?.skuName));

    const handleSubmitForm = async (values, actions) => {

        const handleSubmitData = {
            offerType: values.offerType.value,
            companyName: companyName,
            conditionName: values.conditionName,
            // productCategory: values.productCategory.value,
            // skuName: values.skuName.value,

            productCategory: values.productCategory.map(its => its.value),
            skuName: values.skuName.map(mnd => mnd.value),
            offerProductCategory: values.offerProductCategory.value,
            offerSkuName: values.offerSkuName.value,
            offerSkuPrice: values.offerSkuName.price,
            buyQty: !values.purchaseBDT ? values.buyQty : 0,
            purchaseBDT: !values.buyQty ? values.purchaseBDT : 0,
            freeQty: values.freeQty,
            totalBudgetQty: values.totalBudgetQty,
            offerNote: values.offerNote,
            offerStartDate: values.offerStartDate,
            offerEndDate: values.offerEndDate,
            checkOffer: values.status.value,
            offerUnit: values.offerUnit.value
        };
        if (values.dealerId.length > 0) {
            handleSubmitData.dealerId = values.dealerId.map(its => its.value);

        }

        if (values.zoneName.length > 0) {
            handleSubmitData.zoneName = values.zoneName.map(its => its.value);
        }
        if (values.regionName.length > 0) {
            handleSubmitData.regionName = values.regionName.map(its => its.value);
        }
        if (values.areaName.length > 0) {
            handleSubmitData.areaName = values.areaName.map(its => its.value);
        }
        if (values.dbPoint.length > 0) {
            handleSubmitData.dbPoint = values.dbPoint.map(its => its.value);
        }
        setIsLoading(true);
        const url = updateID
            ? `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/${updateID}`
            : `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/create`;

        try {
            const axiosMethod = updateID ? axios.put : axios.post;
            const response = await axiosMethod(url, handleSubmitData, { headers: authHeader() });

            if (response.status === 200 || response.data.status) {
                navigate("/master/offer");
                toast.success(response.data.message);
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSchema = Yup.object().shape({
        offerType: Yup.object().required("please select a value for the offer type field"),
        // zoneName: Yup.object().required("please select a value for the zone name field"),
        // regionName: Yup.object().required("please select a value for the region name field"),
        // areaName: Yup.object().required("please select a value for the area name field"),
        // dbPoint: Yup.object().required("please select a value for the db point field"),
        // dealerId: Yup.object().required("please select a value for the user role field"),
        conditionName: Yup.string().required("please enter a value for the Offer name field"),
        // productCategory: Yup.object().required("please select a value for the product category field"),
        productCategory: Yup.array()
            .min(1, 'At least one option must be selected')
            .required('Required'),
        offerProductCategory: Yup.object().required("please select a value for the product category field"),
        // skuName: Yup.object().required("please select a value for the product field"),
        skuName: Yup.array()
            .min(1, 'At least one option must be selected')
            .required('Required'),
        // buyQty: Yup.string().required("please enter a value for the buy qty field"),
        // purchaseBDT: Yup.string().required("please enter a value for the purchase bdt field"),
        // freeQty: Yup.string().required("please enter a value for the free qty field"),
        offerNote: Yup.string().nullable(),
        offerStartDate: Yup.string().required("please enter a value for the offer start date field"),
        offerEndDate: Yup.string().required("please enter a value for the offer end date field"),
        status: Yup.object().required("please select a value for the status field"),
        offerUnit: Yup.object().required("please select offer speciality")

    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Offer" : "Add Offer"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    {isLoading && <LoadingIcon />}
                    <Formik
                        initialValues={{
                            offerType: offerTypesList?.find(item => item.value === offerById?.offerType) || null,

                            // zoneName: zoneList?.find(item => item.value === offerById?.zoneName) || null,
                            // regionName: regionList?.find(item => item.value === offerById?.regionName) || null,
                            // areaName: areaList?.find(item => item.value === offerById?.areaName) || null,
                            // dbPoint: dbPointList?.find(item => item.value === offerById?.dbPoint) || null,
                            // dealerId: distributorList?.find(item => item.value === offerById?.dealerId) || { value: '', label: 'None' },

                            zoneName: offerById?.zoneName?.map(item => ({ id: item, label: item, value: item })) || [],
                            regionName: offerById?.regionName?.map(item => ({ id: item, label: item, value: item })) || [],
                            areaName: offerById?.areaName?.map(item => ({ id: item, label: item, value: item })) || [],
                            dbPoint: offerById?.dbPoint?.map(item => ({ id: item, label: item, value: item })) || [],
                            dealerId: offerById?.dealerId?.map(item => ({ id: item, label: distributorList?.find(its => its.value === item)?.label, value: item })) || [],

                            conditionName: offerById?.conditionName || "",
                            // productCategory: categoryList?.filter(item => item.value === offerById?.productCategory) || [],
                            productCategory: offerById?.productCategory?.map(item => ({ id: item, label: item, value: item })) || [],
                            offerProductCategory: offerProductCategoryList?.find(item => item.value === offerById?.offerProductCategory) || null,
                            // skuName: productsList?.filter(item => item.value === offerById?.skuName) || [],
                            skuName: offerById?.skuName?.map(item => ({ id: item, label: item, value: item })) || [],
                            offerSkuName: offerProducts?.find(item => item.value === offerById?.offerSkuName) || null,
                            buyQty: offerById?.buyQty || "",
                            freeQty: offerById?.freeQty || "",
                            purchaseBDT: offerById?.purchaseBDT || "",
                            totalBudgetQty: offerById?.totalBudgetQty || "",
                            offerNote: offerById?.offerNote || "",
                            offerStartDate: formatDateToYYYYMMDD(offerById?.offerStartDate) || "",
                            offerEndDate: formatDateToYYYYMMDD(offerById?.offerEndDate) || "",
                            status: statusOptions?.find(item => item.value == offerById?.checkOffer) || null,
                            offerUnit: unitList?.find(item => item.value === offerById?.offerUnit) || null,
                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={updateID ? true : false}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched

                        }) => {
                            console.log(errors)
                            console.log(values)
                            return (
                                <Form onSubmit={handleSubmit}>

                                    {/* <Row>

                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                            <Form.Label>Offer Speciality</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={specialOptions}
                                                placeholder="Select Offer Speciality"
                                                classNamePrefix="react-select"
                                                name="offerUnit"
                                                value={values.offerUnit}
                                                defaultValue={{
                                                    label: "Non Special",
                                                    value: "CTN"
                                                }}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "offerUnit",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.offerUnit && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.offerUnit}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row> */}
                                    <Row >




                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                            <Form.Label>Select Offer Type</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={offerTypesList}
                                                placeholder="Select Offer Type"
                                                classNamePrefix="react-select"
                                                name="offerType"
                                                value={values.offerType}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("offerType", selectedOption);

                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("offerType", true, true) }}
                                            />
                                            {touched.offerType && errors.offerType && (
                                                <div style={{ color: "red" }}>
                                                    {errors.offerType}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    {values?.offerType?.value !== "National" &&
                                        <><Row className="mb-3">
                                            <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                                <Form.Label>Select Zone</Form.Label>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={true}
                                                    options={zoneList}
                                                    placeholder="Select Zone"
                                                    classNamePrefix="react-select"
                                                    name="zoneName"
                                                    value={values.zoneName}
                                                    onChange={(selectedOption) => {
                                                        // setFieldValue("zoneName", selectedOption);
                                                        // setSelectedZone(selectedOption.value);

                                                        const selected = Array.from(
                                                            selectedOption,
                                                            (option) => option
                                                        );

                                                        setFieldValue("zoneName", selected);
                                                        setSelectedZone([...selected.map(itm => itm.value)]);
                                                    }}
                                                    onBlur={event => { handleBlur(event); setFieldTouched("zoneName", true, true) }}
                                                />
                                                {touched.zoneName && errors.zoneName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.zoneName}
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                                <Form.Label>Select Region</Form.Label>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={true}
                                                    options={regionList}
                                                    placeholder="Select Region"
                                                    classNamePrefix="react-select"
                                                    name="regionName"
                                                    value={values.regionName}
                                                    onChange={(selectedOption) => {
                                                        // setFieldValue("regionName", selectedOption);
                                                        // setSelectedRegion(selectedOption.value)

                                                        const selected = Array.from(
                                                            selectedOption,
                                                            (option) => option
                                                        );

                                                        setFieldValue("regionName", selected);
                                                        setSelectedRegion([...selected.map(itm => itm.value)]);

                                                    }}
                                                    onBlur={event => { handleBlur(event); setFieldTouched("regionName", true, true) }}
                                                />
                                                {touched.regionName && errors.regionName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.regionName}
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                                <Form.Label>Select Area</Form.Label>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={true}
                                                    options={areaList}
                                                    placeholder="Select Area"
                                                    classNamePrefix="react-select"
                                                    name="areaName"
                                                    value={values.areaName}
                                                    onChange={(selectedOption) => {
                                                        // setFieldValue("areaName", selectedOption);
                                                        // setSelectedArea(selectedOption.value)

                                                        const selected = Array.from(
                                                            selectedOption,
                                                            (option) => option
                                                        );

                                                        setFieldValue("areaName", selected);
                                                        setSelectedArea([...selected.map(itm => itm.value)]);
                                                    }}
                                                    onBlur={event => { handleBlur(event); setFieldTouched("areaName", true, true); }}
                                                />
                                                {touched.areaName && errors.areaName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.areaName}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Row>
                                            <Row>
                                                <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                                    <Form.Label>Select DB Point</Form.Label>

                                                    <Select
                                                        isMulti
                                                        closeMenuOnSelect={true}
                                                        options={dbPointList}
                                                        placeholder="Select DB Point"
                                                        classNamePrefix="react-select"
                                                        name="dbPoint"
                                                        value={values.dbPoint}
                                                        onChange={(selectedOption) => {

                                                            // setFieldValue("dbPoint", selectedOption);
                                                            // setSelectedDBPoint(selectedOption.value);


                                                            const selected = Array.from(
                                                                selectedOption,
                                                                (option) => option
                                                            );

                                                            setFieldValue("dbPoint", selected);
                                                            setSelectedDBPoint([...selected.map(itm => itm.value)]);

                                                        }}

                                                        onBlur={event => { handleBlur(event); setFieldTouched("dbPoint", true, true); }}
                                                    />
                                                    {touched.dbPoint && errors.dbPoint && (
                                                        <div style={{ color: "red", fontSize: "1rem" }}>
                                                            {errors.dbPoint}
                                                        </div>
                                                    )}
                                                </Form.Group>

                                                {values?.offerType?.value === "Individual" && <Form.Group as={Col} md="12" controlId="exampleFirstName">
                                                    <Form.Label>Select Distributor</Form.Label>
                                                    <Select
                                                        isMulti
                                                        closeMenuOnSelect={true}
                                                        options={distributorList}
                                                        placeholder="Select Distributor"
                                                        classNamePrefix="react-select"
                                                        name="dealerId"
                                                        value={values.dealerId}
                                                        onChange={(selectedOption) => {

                                                            // setFieldValue("dealerId", selectedOption);

                                                            const selected = Array.from(
                                                                selectedOption,
                                                                (option) => option
                                                            );

                                                            setFieldValue("dealerId", selected);
                                                            // setSelectedDBPoint([...selected.map(itm => itm.value)]);

                                                        }}

                                                        onBlur={event => { handleBlur(event); setFieldTouched("dealerId", true, true); }}
                                                    />
                                                    {touched.dealerId && errors.dealerId && (
                                                        <div style={{ color: "red", fontSize: "1rem" }}>
                                                            {errors.dealerId}
                                                        </div>
                                                    )}
                                                </Form.Group>}
                                            </Row></>}
                                    <Row >
                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleState">
                                            <Form.Label>Offer Name</Form.Label>
                                            <Form.Control
                                                name="conditionName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Offer Name"
                                                value={values.conditionName}
                                            />
                                            {touched.conditionName && errors.conditionName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.conditionName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                            <Form.Label>Select Product Category</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={categoryList}
                                                placeholder="Select Product Category"
                                                classNamePrefix="react-select"
                                                name="productCategory"
                                                isMulti
                                                value={values.productCategory}
                                                onChange={(selectedOption) => {

                                                    const selected = Array.from(
                                                        selectedOption,
                                                        (option) => option
                                                    );
                                                    // setFieldValue('options', selected);

                                                    // setFieldValue("productCategory", selectedOption);
                                                    setFieldValue("productCategory", selected);
                                                    // setSelectedCategory(selectedOption.value); 
                                                    setSelectedCategory([...selected.map(itm => itm.value)]);
                                                    // setOfferById({ ...offerById, productCategory: selectedOption.value })
                                                    // setOfferById({ ...offerById, productCategory: selected })

                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("productCategory", true, true); }}
                                            />
                                            {touched.productCategory && errors.productCategory && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.productCategory}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row >
                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                            <Form.Label>Select Product</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={productsList}
                                                placeholder="Select Product"
                                                classNamePrefix="react-select"
                                                name="skuName"
                                                isMulti
                                                value={values.skuName}
                                                onChange={(selectedOption) => {

                                                    const selected = Array.from(
                                                        selectedOption,
                                                        (option) => option
                                                    );

                                                    // setFieldValue("skuName", selectedOption);
                                                    setFieldValue("skuName", selected);
                                                    // setSelectedCategory(selectedOption.value);
                                                    // setOfferById({ ...offerById, skuName: selectedOption.value })
                                                    // setOfferById({ ...offerById, skuName: selected })


                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("skuName", true, true); }}
                                            />
                                            {touched.skuName && errors.skuName && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.skuName}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleState">
                                            <Form.Label>Purchase Qty LTR/KG </Form.Label>
                                            <Form.Control
                                                name="buyQty"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Purchase Qty"
                                                value={values.buyQty}
                                            />
                                            {touched.buyQty && errors.buyQty && (
                                                <div style={{ color: "red" }}>
                                                    {errors.buyQty}
                                                </div>
                                            )}
                                        </Form.Group>


                                        {!values.buyQty &&
                                            (<Form.Group className="mb-3" as={Col} md="12" controlId="exampleState">
                                                <Form.Label>Purchase BDT</Form.Label>
                                                <Form.Control
                                                    name="purchaseBDT"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text"
                                                    placeholder="Enter Purchase (BDT)"
                                                    value={values.purchaseBDT}
                                                />
                                                {touched.purchaseBDT && errors.purchaseBDT && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.purchaseBDT}
                                                    </div>
                                                )}
                                            </Form.Group>)}


                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                            <Form.Label>Select Offer Product Category</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={offerProductCategoryList}
                                                placeholder="Select Offer Product Category"
                                                classNamePrefix="react-select"
                                                name="offerProductCategory"
                                                value={values.offerProductCategory}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("offerProductCategory", selectedOption);
                                                    setSelectedOfferProductCategory(selectedOption.value);
                                                    setOfferById({ ...offerById, offerProductCategory: selectedOption.value })

                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("offerProductCategory", true, true); }}
                                            />
                                            {touched.offerProductCategory && errors.offerProductCategory && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.offerProductCategory}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                            <Form.Label>Select Offer Product</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={offerProducts}
                                                placeholder="Select Offer Product"
                                                classNamePrefix="react-select"
                                                name="offerSkuName"
                                                value={values.offerSkuName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("offerSkuName", selectedOption);
                                                    setSelectedCategory(selectedOption.value);
                                                    setOfferById({ ...offerById, offerSkuName: selectedOption.value })
                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("offerSkuName", true, true); }}
                                            />
                                            {touched.offerSkuName && errors.offerSkuName && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.offerSkuName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                            <Form.Label>Select Unit</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={unitList}
                                                placeholder="Select Unit"
                                                classNamePrefix="react-select"
                                                name="offerUnit"
                                                value={values.offerUnit}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("offerUnit", selectedOption);

                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("offerUnit", true, true); }}
                                            />
                                            {touched.offerUnit && errors.offerUnit && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.offerUnit}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleState">
                                            <Form.Label>Offer Product Qty</Form.Label>
                                            <Form.Control
                                                name="freeQty"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Free Qty"
                                                value={values.freeQty}
                                            />
                                            {touched.freeQty && errors.freeQty && (
                                                <div style={{ color: "red" }}>
                                                    {errors.freeQty}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row >
                                        <Form.Group as={Col} md="12" controlId="exampleState">
                                            <Form.Label>Total Offer Budget Quantity</Form.Label>
                                            <Form.Control
                                                name="totalBudgetQty"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Offer Budget Qty"
                                                required
                                                value={values.totalBudgetQty}
                                            />
                                            {touched.totalBudgetQty && errors.totalBudgetQty && (
                                                <div style={{ color: "red" }}>
                                                    {errors.totalBudgetQty}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleState">
                                            <Form.Label>Offer Note</Form.Label>
                                            <Form.Control
                                                name="offerNote"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                as="textarea"
                                                type="text"
                                                placeholder="Enter Offer Note"
                                                // required
                                                value={values.offerNote}
                                            />
                                            {touched.offerNote && errors.offerNote && (
                                                <div style={{ color: "red" }}>
                                                    {errors.offerNote}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleState">
                                            <Form.Label>Offer Start Date</Form.Label>
                                            <Form.Control
                                                name="offerStartDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter offer Start Date"
                                                required
                                                value={values.offerStartDate}
                                            />
                                            {touched.offerStartDate && errors.offerStartDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.offerStartDate}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleState">
                                            <Form.Label>Offer End Date</Form.Label>
                                            <Form.Control
                                                name="offerEndDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter Offer End Date"
                                                required
                                                value={values.offerEndDate}
                                            />
                                            {touched.offerEndDate && errors.offerEndDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.offerEndDate}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col} md="12" controlId="exampleFirstName">
                                            <Form.Label>Status</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={statusOptions}
                                                placeholder="Select Offer Status"
                                                classNamePrefix="react-select"
                                                name="status"
                                                value={values.status}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "status",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.status && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.status}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/offer")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard >
        </>
    );
};

export default OfferForm;
