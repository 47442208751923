import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import useUserRole from 'hooks/useUserRole'
import { useDropzone } from 'react-dropzone'
import FalconCloseButton from 'components/common/FalconCloseButton'
import cloudUpload from 'assets/img/icons/cloud-upload.svg'
import { authHeaderForm } from 'state/ducs/auth/utils'
import Select from 'react-select'
import { Formik } from 'formik'

const HosStockList = () => {
    const [receivedList, setReceivedList] = useState([])
    const [serachedReceivedList, setSerachedReceivedList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)
    const [pageDataCount, setPageDataCount] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [showUploadCSV, setShowUploadCSV] = useState(false)
    const [count, setCount] = useState(0)
    const userInfo = useSelector((state) => state.auth.user?.data)
    const userRole = useUserRole()
    const [factory, setFactory] = useState('')

    const factoryList = [
        {
            label: 'All',
            value: 'All',
        },
        {
            label: 'SORL',
            value: 'SORL',
        },
        {
            label: 'SVOIL',
            value: 'SVOIL',
        },
        {
            label: 'Narshingdi',
            value: 'Narshingdi',
        },
        {
            label: 'Dhamrai',
            value: 'Dhamrai',
        },
    ]

    const formateExcelData = (data) => {
        const formatDate = (date) => {
            return date ? new Date(date).toLocaleDateString('en-US') : ''
        }

        const formateData = data?.map((item, index) => ({
            Sl: index + 1,
            'Product Category': item.productCategory,
            'Sku Name': item.skuName,
            'Rcv Qty CTN': item.rcvQtyCTN,
            'Rcv Qty PCS': item.rcvQtyPCS,
            'Delivery Qty CTN': item.delivery?.delQtyCtn || 0,
            'Delivery Qty PCS': item.delivery?.delQtyPcs || 0,
            'Balance Qty Ctn': item.rcvQtyCTN - (item.delivery?.delQtyCtn || 0),
            'Balance Qty Pcs': item.rcvQtyPCS - (item.delivery?.delQtyPcs || 0),
            'Do Date': formatDate(item.doDate),
        }))

        return formateData
    }

    //
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1

        const fetchData = async () => {
            let url
            let url2
            //if (userInfo?.userRole === 'Admin') {
                // url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                // url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
                // url2 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`

                url = `${'https://khoz.net:3013/api/v1/'}productsRcv/groupadmin`
                url2 = `${'https://khoz.net:3013/api/v1/'}productsRcv/deliveryadmin`
           // }
            if (userInfo?.userRole === 'Inventory') {
                // url = searchText !== "" ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                // url = searchText !== "" ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}` : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
                // url2 = searchText !== "" ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}` : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`
            }

            try {
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const totalPages =
                        response?.data?.payload?.pagination?.totalPages
                    const result = response?.data?.payload?.result?.map(
                        (element, index) => ({
                            ...element,
                            index: startIndex + index,
                        })
                    )

                    setTotalPages(totalPages)

                    setReceivedList(
                        result.map((item) => ({
                            ...item,
                            factoryName: item.data[0].factoryName,
                        }))
                    )

                    setSerachedReceivedList(
                        result.map((item) => ({
                            ...item,
                            factoryName: item.data[0].factoryName,
                        }))
                    )

                    ///////////////////////////
                    const response2 = await axios.get(url2, {
                        headers: authHeader(),
                    })

                    if (response2.data.success || response2.status === 200) {
                        const totalPages2 =
                            response2?.data?.payload?.pagination?.totalPages
                        const result2 = response2?.data?.payload?.result?.map(
                            (element, index) => ({
                                ...element,
                                index: startIndex + index,
                            })
                        )

                        const modifiedResult = result.map((item) => ({
                            ...item,
                            factoryName: item.data[0].factoryName,
                            delivery: result2.find(
                                (my) => my.skuName == item.skuName
                            ),
                        }))

                        setTotalPages(totalPages)
                        setReceivedList(modifiedResult)
                        setSerachedReceivedList(modifiedResult)
                    }
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [count, paginationPageCount, pageDataCount, searchText])

    // DELETE DISTRIBUTOR

    console.log('receivedList', receivedList)

    if (isLoading) {
        return <LoadingIcon />
    }

    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'productCategory',
            Header: 'Category',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { productCategory } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{productCategory}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'skuName',
            Header: 'skuName',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { skuName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{skuName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyCTN',
            Header: 'Rcv Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyCTN } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{rcvQtyCTN}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyPCS',
            Header: 'Rcv Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyPCS } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{rcvQtyPCS}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'delQtyCTN',
            Header: 'Del Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delivery } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {delivery?.delQtyCtn || 0}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'delQtyPCS',
            Header: 'Del Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delivery } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {delivery?.delQtyPcs || 0}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'balQtyCTN',
            Header: 'Balance Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delivery, rcvQtyCTN } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {rcvQtyCTN - (delivery?.delQtyCtn || 0)}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'balQtyPCS',
            Header: 'Balance Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delivery, rcvQtyPCS } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {rcvQtyPCS - (delivery?.delQtyPcs || 0)}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
    ]

    /*******************
    Columns End.
    *******************/

    console.log('selectedFactory', factory)
    console.log('serachedReceivedList', serachedReceivedList)

    const handleSearch = () => {
        const temp = receivedList.filter((item) => {
            if (factory.length) {
                if (factory == 'All') {
                    return true
                }
                return item.factoryName == factory
            } else {
                return true
            }
        })

        setSerachedReceivedList([
            ...temp.map((itm, ind) => ({ ...itm, index: ind + 1 })),
        ])
    }

    return (
        <>
            <PageHeader
                title="General Stock List"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={serachedReceivedList}
                sortable
                pagination
                perPage={500}
            >
                <Card className="mb-3">
                    <Form>
                        <Row className="mt-3 ms-2">
                            <Form.Group
                                as={Col}
                                md="3"
                                controlId="exampleFirstName"
                            >
                                <Select
                                    closeMenuOnSelect={true}
                                    options={factoryList}
                                    placeholder={
                                        isLoading
                                            ? 'loading...'
                                            : 'Select Factory Name'
                                    }
                                    classNamePrefix="react-select"
                                    onChange={(selectedOption) => {
                                        setFactory(selectedOption.value)
                                    }}
                                    isLoading={isLoading}
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="2"
                                controlId="exampleState"
                            >
                                <Button
                                    onClick={handleSearch}
                                    variant="primary"
                                    className=""
                                    style={{ width: '188px' }}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            // isSearch={true}
                            // setSearchText={setSearchText}
                            table
                            excelFileName="General Stock List"
                            excelData={formateExcelData(serachedReceivedList)}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    {/* <Card.Footer>
                        <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                    </Card.Footer> */}
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}

export default HosStockList
