import Stepper from 'react-stepper-horizontal';
const CustomStepper = ({ activeStep, steps }) => {
    return (
        <Stepper
            steps={steps}
            activeStep={activeStep}
            activeColor="#007bff" // Customize active step color
            completeColor="#28a745" // Customize completed step color
            circleFontSize={12} // Customize circle font size
            size={24} // Customize circle size
            circleTop={0} // Adjust circle position (vertical alignment)
            activeTitleColor="#007bff" // Customize active title color
            completeTitleColor="#28a745" // Customize completed title color
            defaultTitleColor="#ccc" // Customize default title color
        />
    );
}

export default CustomStepper;