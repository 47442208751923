import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const StorageTypesForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [storageTypesInfo, setStorageTypesInfo] = useState({});
    const { updateID } = useParams();
    const navigate = useNavigate();


    // load update  field value
    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + `storage-types/${updateID}`
            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setStorageTypesInfo(response?.data?.data);

                    }
                })
                .catch(error => {
                    handleApiResponseErrors(error.response);

                }).finally(
                    setIsLoading(false)
                )
        }
    }, [updateID])


    const handleSubmit = (values, actions) => {

        const handleInfo = {
            storage_type_name: values.storage_type_name,

        }

        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + "storage-types";
            axios.post(url, handleInfo, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        navigate("/storageTypes")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)

                }).finally(
                    setIsLoading(false)
                )

        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + `storage-types/${updateID}`;
            axios.put(updateUrl, handleInfo, { headers: authHeader() })
                .then(response => {

                    if (response.data.success || response.data.statusCode === 200) {
                        navigate("/storageTypes")
                        toast.success(response.data.message);
                    }
                    setIsLoading(false);

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)

                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const handleSchema = Yup.object().shape({
        storage_type_name: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please enter a value for the Storage Type Name field").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            })

    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Storage Type" : "Add Storage Type"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            storage_type_name: storageTypesInfo?.storage_type_name || '',
                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="10" controlId="exampleState">
                                            <Form.Label>Storage Type Name</Form.Label>
                                            <Form.Control
                                                name="storage_type_name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Storage Type Name"
                                                required
                                                value={values.storage_type_name}
                                            />
                                            {touched.storage_type_name && errors.storage_type_name && (
                                                <div style={{ color: "red" }}>
                                                    {errors.storage_type_name}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <Button
                                        className="me-2"
                                        variant="primary" type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </Button>
                                    <Button
                                        onClick={() => navigate("/storageTypes")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default StorageTypesForm;


