import FalconCardHeader from 'components/common/FalconCardHeader'
import PropTypes from 'prop-types'
import React from 'react'
import { Card, Table } from 'react-bootstrap'

const BottomTab = ({ files, className, title }) => {
    console.log("files", files);
    return (
        <Card style={{overflowX: "auto"}} className={className}>
             <FalconCardHeader
                title={title}
                titleTag="h6"
                className="py-2"
                light
                // endEl={
                //     <div className="py-1 fs--1 font-sans-serif">
                //         Quantity
                //     </div>
                // }
            />
            <Card.Body className="pb-0">
                <Table borderless className="mb-0 fs--1">
                    <thead className="bg-light">
                        <tr className="text-900">
                            {/* <th>Schedule No</th> */}
                            <th>DO No</th>
                            <th>SKU Name</th>
                            <th>Unit</th>
                            <th>Delivery Qty</th>
                            <th className="text-start">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file, index) => (
                            <Bottom
                                key={file._id}
                                file={file}
                                isLast={index === files.length - 1}
                            />
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

const Bottom = ({ file, isLast }) => {
    const { doNo, scheduleNo, delQtyCtn, time, status, skuName, delQtyPcs, dbType, alreadyDeliveredQty } = file
    console.log("file", file);
    return (
        <>
            <tr className="border-top border-200">
                {/* <td className="align-middle text-start fw-semi-bold">{scheduleNo}</td> */}
                <td className="align-middle text-start fw-semi-bold">{doNo}</td>
                <td className="align-middle text-start fw-semi-bold">{skuName}</td>
                 <td className="align-middle text-start fw-semi-bold">
                    {dbType == "SPECIAL DISTRIBUTOR" ? "PCS" : "CTN"}
                </td>
                <td className="align-middle text-start fw-semi-bold">
                    {dbType == "SPECIAL DISTRIBUTOR" ? alreadyDeliveredQty : alreadyDeliveredQty}
                </td>
                <td className="align-middle text-end fw-semi-bold">
                    {status === 10 ? 'Delivered confirmed' : 'Delivered schedules'}
                </td>
            </tr>
        </>
    )
}

Bottom.propTypes = {
    file: PropTypes.shape({
        img: PropTypes.string,
        name: PropTypes.string,
        user: PropTypes.string,
        time: PropTypes.string,
        border: PropTypes.bool,
    }),
    isLast: PropTypes.bool,
}

BottomTab.propTypes = {
    files: PropTypes.arrayOf(Bottom.propTypes.file),
    className: PropTypes.string,
}

export default BottomTab
