import ManagementTarget from "./Target/index";
import DashboardCards from '../Common/DashboardCards/Dashboard';
const { Row } = require("react-bootstrap");

const OrderManagementTarget = ({ setIsLoading }) => {
    return (
        <div>
            <Row className="g-3 mb-3">
                <DashboardCards setIsLoading={setIsLoading}></DashboardCards>
                <ManagementTarget setIsLoading={setIsLoading} />
            </Row>
        </div>
    );
};

export default OrderManagementTarget;