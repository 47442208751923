import React, { useEffect, useState } from 'react'
import { authHeader } from 'utils'
import PendingSales from './PendingSales'
import { Button, Col, Dropdown, Row } from 'react-bootstrap'
import PendingSalesDemand from './PendingSalesDemand'
import ProcessedDemandOrder from './ProcessedDemandOrder'
import ConfirmDemandOrder from './ConfirmDemandOrder'
import ConfirmDOTk from './ConfirmDOTk'
import MonthlyConfirmNo from './MonthlyConfirmNo'
import MonthlyConfirmTk from './MonthlyConfirmTk'
import MonthlyCanceledNo from './MonthlyCanceledNo'
import MonthlyCanceledTk from './MonthlyCanceledTk'
import YearTillDateNo from './YearTillDateNo'
import YearTillDateTk from './YearTillDateTk'
import ProcessedDemandOrderTk from './ProcessedDemandOrderTk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Swal from 'sweetalert2'
import axios from 'axios'
import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'

const DashboardCards = ({ setIsLoading }) => {
    const [selecteDate, setSelecteDate] = useState(new Date())
    const [data, setData] = useState({})

    const handleDashboardData = async () => {
        const month = selecteDate.getMonth() + 1
        const year = selecteDate?.getFullYear()

        const fetchData = async () => {
            const ConfirmDoUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/MonthlyConfirmOrder/${year}/${month}`
            const confirmDoAmountUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/MonthlyConfirmOrderTK/${year}/${month}`
            const CencelDoUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/MonthlyCancelOrder/${year}/${month}`
            const CencelDoAmountUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/MonthlyCancelOrderTK/${year}/${month}`
            const TillDateConfirmDoUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/YearConfirmOrder/${year}`
            const TillDateConfirmDoAmountUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/YearConfirmOrderTK/${year}`

            try {
                setIsLoading(true)
                const [
                    ConfirmDoResponse,
                    ConfirmDoAmountResponse,
                    CencelDoResponse,
                    CencelDoAmountResponse,
                    TillDateConfirmDoResponse,
                    TillDateCOnfirmDoAmount,
                ] = await Promise.all([
                    axios.get(ConfirmDoUrl, { headers: authHeader() }),
                    axios.get(confirmDoAmountUrl, { headers: authHeader() }),
                    axios.get(CencelDoUrl, { headers: authHeader() }),
                    axios.get(CencelDoAmountUrl, { headers: authHeader() }),
                    axios.get(TillDateConfirmDoUrl, { headers: authHeader() }),
                    axios.get(TillDateConfirmDoAmountUrl, {
                        headers: authHeader(),
                    }),
                ])

                if (
                    ConfirmDoResponse.data.success ||
                    ConfirmDoResponse.status === 200
                ) {
                    const data =
                        ConfirmDoResponse?.data?.payload?.distinctDoNoCount
                    setData((prevState) => ({ ...prevState, ConfirmDo: data }))
                }
                if (
                    ConfirmDoAmountResponse.data.success ||
                    ConfirmDoAmountResponse.status === 200
                ) {
                    const data =
                        ConfirmDoAmountResponse?.data?.payload?.totalPrice
                    setData((prevState) => ({
                        ...prevState,
                        confirmDoAmount: data,
                    }))
                }
                if (
                    CencelDoResponse.data.success ||
                    CencelDoResponse.status === 200
                ) {
                    const data =
                        CencelDoResponse?.data?.payload?.distinctDoNoCount
                    setData((prevState) => ({ ...prevState, cancelDo: data }))
                }
                if (
                    CencelDoAmountResponse.data.success ||
                    CencelDoAmountResponse.status === 200
                ) {
                    const data =
                        CencelDoAmountResponse?.data?.payload?.totalPrice
                    setData((prevState) => ({
                        ...prevState,
                        cancelDoAmount: data,
                    }))
                }
                if (
                    TillDateConfirmDoResponse.data.success ||
                    TillDateConfirmDoResponse.status === 200
                ) {
                    const data =
                        TillDateConfirmDoResponse?.data?.payload
                            ?.distinctDoNoCount
                    setData((prevState) => ({
                        ...prevState,
                        TillDateConfirmDo: data,
                    }))
                }
                if (
                    TillDateCOnfirmDoAmount.data.success ||
                    TillDateCOnfirmDoAmount.status === 200
                ) {
                    const data =
                        TillDateCOnfirmDoAmount?.data?.payload?.totalPrice
                    setData((prevState) => ({
                        ...prevState,
                        TillDateConfirmDoAmount: data,
                    }))
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops, something went wrong!',
                    text: error.response.data.message,
                })
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }

    useEffect(() => {
        const fetchData = async () => {
            const date = new Date()
            const year = date.getFullYear()
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')

            const formattedDate = `${year}-${month}-${day}`

            const DemandOrderNoUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalPendingOrder`
            const DemandOrderAmountUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalPendingOrderTK`
            const TodayProcessedDoUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalProcesedOrder/${formattedDate}`
            const TodayProcessedAmountUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalProcesedOrderTK/${formattedDate}`
            const TodayConfirmedOrderUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalConfirmOrder/${formattedDate}`
            const TodayConfirmedOrderAmountUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/TotalConfirmOrderTK/${formattedDate}`

            try {
                setIsLoading(true)
                const [
                    DemandOrderNoResponse,
                    DemandOrderAmountResponse,
                    TodayProcessedDoResponse,
                    TodayProcessedAmountResponse,
                    TodayConfirmOrderResponse,
                    TodayConfirmedOrderAmountResponse,
                ] = await Promise.all([
                    axios.post(DemandOrderNoUrl, {}, { headers: authHeader() }),
                    axios.post(
                        DemandOrderAmountUrl,
                        {},
                        { headers: authHeader() }
                    ),
                    axios.get(TodayProcessedDoUrl, { headers: authHeader() }),
                    axios.get(TodayProcessedAmountUrl, {
                        headers: authHeader(),
                    }),
                    axios.get(TodayConfirmedOrderUrl, {
                        headers: authHeader(),
                    }),
                    axios.get(TodayConfirmedOrderAmountUrl, {
                        headers: authHeader(),
                    }),
                ])

                if (
                    DemandOrderNoResponse.data.success ||
                    DemandOrderNoResponse.status === 200
                ) {
                    const data =
                        DemandOrderNoResponse?.data?.payload?.distinctDoNoCount
                    setData((prevState) => ({
                        ...prevState,
                        demandOrderNo: data,
                    }))
                }
                if (
                    DemandOrderAmountResponse.data.success ||
                    DemandOrderAmountResponse.status === 200
                ) {
                    const data =
                        DemandOrderAmountResponse?.data?.payload?.totalPrice
                    setData((prevState) => ({
                        ...prevState,
                        demandOrderAmount: data,
                    }))
                }
                if (
                    TodayProcessedDoResponse.data.success ||
                    TodayProcessedDoResponse.status === 200
                ) {
                    const data =
                        TodayProcessedDoResponse?.data?.payload
                            ?.distinctDoNoCount
                    setData((prevState) => ({
                        ...prevState,
                        todayProcessedDo: data,
                    }))
                }
                if (
                    TodayProcessedAmountResponse.data.success ||
                    TodayProcessedAmountResponse.status === 200
                ) {
                    const data =
                        TodayProcessedAmountResponse?.data?.payload?.totalPrice
                    setData((prevState) => ({
                        ...prevState,
                        todayProcessedAmount: data,
                    }))
                }
                if (
                    TodayConfirmOrderResponse.data.success ||
                    TodayConfirmOrderResponse.status === 200
                ) {
                    const data =
                        TodayConfirmOrderResponse?.data?.payload
                            ?.distinctDoNoCount
                    setData((prevState) => ({
                        ...prevState,
                        todayConfirmOrder: data,
                    }))
                }
                if (
                    TodayConfirmedOrderAmountResponse.data.success ||
                    TodayConfirmedOrderAmountResponse.status === 200
                ) {
                    const data =
                        TodayConfirmedOrderAmountResponse?.data?.payload
                            ?.totalPrice
                    setData((prevState) => ({
                        ...prevState,
                        todayConfirmedOrderAmount: data,
                    }))
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops, something went wrong!',
                    text: error.response.data.message,
                })
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
        handleDashboardData()
    }, [])


    // const formatData = () => {
    //     const dataFormat = data.map((item, i) => {
    //         const DateFormatted = new Date(item.productionDate)
    //             .toISOString()
    //             .split('T')[0]
    //         const ExpireDate = new Date(item.expireDate)
    //             .toISOString()
    //             .split('T')[0]
    //         return {
    //             SL: i + 1,
    //             'Batch No': item.batchNo,
    //             'Production Date': DateFormatted,
    //             'Rcv Qty Ctn': item.rcvQtyCTN,
    //             'Rcv Qty Pcs': item.rcvQtyPCS,
    //             'Rcv Qty Mt': item.rcvQtyMt,
    //             'Factory Name': item.factoryName,
    //             'Expire Date': ExpireDate,
    //         }
    //     })
    //     return dataFormat
    // }


    return (
        <div>
            <Row>
                <Col className="text-end">
                    <Dropdown className="font-sans-serif btn-reveal-trigger">
                        <Dropdown.Toggle
                            variant="link"
                            size="sm"
                            data-boundary="viewport"
                        >
                            <FontAwesomeIcon
                                icon="ellipsis-h"
                                className="fs--2"
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="border py-0">
                           
                            <div className="d-flex justify-content-center align-items-center gap-2 p-2">
                                <DatePicker
                                    selected={selecteDate}
                                    onChange={(date) => setSelecteDate(date)}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    placeholderText="Select Month"
                                    className="form-control w-100"
                                    width="100%"
                                />
                                <Button onClick={() => handleDashboardData()}>
                                    Search
                                </Button>
                            </div>
                            <div className=" gap-2 p-2">
                                <CustomersTableHeader
                                    isNew={false}
                                    excelFileName="Dashboard data List"
                                   // excelData={formatData()}
                                    
                                />
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col md={6} xxl={3}>
                    <PendingSales data={data} selecteDate={selecteDate} />
                </Col>
                <Col md={6} xxl={3}>
                    <PendingSalesDemand data={data} />
                </Col>
                <Col md={6} xxl={3}>
                    <ProcessedDemandOrder data={data} />
                </Col>
                <Col md={6} xxl={3}>
                    <ProcessedDemandOrderTk data={data} />
                </Col>
            </Row>
            <Row className="g-3 mb-3">
                <Col md={6} xxl={3}>
                    <ConfirmDemandOrder data={data} />
                </Col>
                <Col md={6} xxl={3}>
                    <ConfirmDOTk data={data} />
                </Col>
                <Col md={6} xxl={3}>
                    <MonthlyConfirmNo data={data} selectedDate={selecteDate} />
                </Col>
                <Col md={6} xxl={3}>
                    <MonthlyConfirmTk data={data} selectedDate={selecteDate} />
                </Col>
            </Row>
            <Row>
                <Col md={6} xxl={3}>
                    <MonthlyCanceledNo data={data} selectedDate={selecteDate} />
                </Col>
                <Col md={6} xxl={3}>
                    <MonthlyCanceledTk data={data} selectedDate={selecteDate} />
                </Col>
                <Col md={6} xxl={3}>
                    <YearTillDateNo data={data} selectedDate={selecteDate} />
                </Col>
                <Col md={6} xxl={3}>
                    <YearTillDateTk data={data} selectedDate={selecteDate} />
                </Col>
            </Row>
        </div>
    )
}

export default DashboardCards
