import FalconComponentCard from 'components/common/FalconComponentCard'
import SharedFiles from 'components/dashboards/default/SharedFiles'
import SharedFiles2 from 'components/dashboards/default/SharedFiles2'
import BestSellingProducts from 'components/dashboards/e-commerce/BestSellingProducts'
import AppContext from 'context/Context'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    getAllSchedules,
    getAreas,
    getDBPoints,
    getDistributors,
    getInventoryStocks,
    getOrdersByDealerId,
    getRegions,
    getZones,
    scheduleSubmit
} from 'services/api'

const SchedulingUpdate = ({ user }) => {
    const navigate = useNavigate()

    const location = useLocation()
    const { doNav } = location.state
    console.log('doNav', doNav)

    const [first, setfirst] = useState('')
    const [validated, setValidated] = useState(false)

    const [zoneValue, setZoneValue] = useState(null)
    const [regionValue, setRegionValue] = useState(null)
    const [areaValue, setAreaValue] = useState(null)
    const [dbPointValue, setDBPointValue] = useState(null)
    const [distributorValue, setDistributorValue] = useState(null)
    const [schedulableOrders, setSchedulableOrders] = useState([])
    const [selectedFactoryname, setSelectedFactoryName] = useState('')
    const [selectedSKUname, setSelectedSKUName] = useState('')
    const [selectedDoNo, setSelectedDoNo] = useState('')


    const [submittedOrders, setSubmittedOrders] = useState([])

    const { data: zoneData, isLoading: isLoadingZone } = useQuery({
        queryKey: ['zones'],
        queryFn: getZones,
    })

    const { data: regionData, isLoading: isLoadingRegion } = useQuery({
        queryKey: ['regions'],
        queryFn: getRegions,
    })

    const { data: areaData, isLoading: isLoadingArea } = useQuery({
        queryKey: ['areas'],
        queryFn: getAreas,
    })

    const { data: dbPointData, isLoading: isLoadingDBPoint } = useQuery({
        queryKey: ['dbPoints'],
        queryFn: getDBPoints,
    })

    const { data: distributorData, isLoading: isLoadingDistributor } = useQuery(
        {
            queryKey: ['distributors'],
            queryFn: getDistributors,
        }
    )

    const {
        data: orderData,
        isLoading: isLoadingOrder,
        isError: orderDataError,
        refetch: orderDataRefetch,
    } = useQuery({
        queryKey: ['orders'],
        queryFn: () =>
            getOrdersByDealerId(distributorValue?.userId || doNav.dealerId),
    })

    const {
        data: stockData,
        isLoading: isLoadingStock,
        isError,
        refetch,
    } = useQuery({
        queryKey: ['stocks'],
        queryFn: () => getInventoryStocks(selectedSKUname, selectedFactoryname),
    })

    const {
        data: scheduleData,
        isLoading: isLoadingSchedule,
        isError: scheduleError,
        refetch: scheduleRefetch,
    } = useQuery({
        queryKey: ['schedule'],
        queryFn: () => getAllSchedules(),
    })

    useEffect(() => {
        orderDataRefetch()
    }, [distributorValue])

    useEffect(() => {
        refetch()
    }, [selectedSKUname, selectedFactoryname])

    // useEffect(() => {
    //     scheduleRefetch()
    // }, [selectedDoNo, selectedSKUname])

    useEffect(() => {
        orderDataRefetch()
        refetch()
        scheduleRefetch()
    }, [submittedOrders.length])


    useEffect(() => {
        if (doNav) {
            setDistributorValue({
                ...doNav,
                value: doNav.dealerId,
                label: doNav.distributorName,
                userId: doNav.dealerId,
            })
        }
    }, [doNav])

    const handleSubmit = (event) => {
        console.log('distributorValue', distributorValue)
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }

        setValidated(true)
    }

    const {
        config: { isNavbarVerticalCollapsed, isFluid, isRTL },
        setConfig,
    } = useContext(AppContext)
    useEffect(() => {
        const handleClick = () => {
            document
                .getElementsByTagName('html')[0]
                .classList.toggle('navbar-vertical-collapsed')
            setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed)
        }

        handleClick()
    }, [])

    // const zoneOptions = zoneData?.data?.payload?.result.map((zone) => ({
    //     value: zone._id,
    //     label: zone.name,
    //     ...zone,
    // }))

    let zoneOptions = []
    if (zoneData && zoneData.data.payload.result.length) {
        zoneOptions = zoneData.data.payload.result.map((zone) => ({
            value: zone._id,
            label: zone.name,
            ...zone,
        }))
    }

    // const regionOptions = regionData?.data?.payload?.result.map((region) => ({
    //     value: region._id,
    //     label: region.regionName,
    //     ...region,
    // }))

    let regionOptions = []
    if (regionData && regionData.data.payload.result.length && zoneValue) {
        regionOptions = regionData.data.payload.result
            .filter((item) => item.zoneName == zoneValue.label)
            .map((region) => ({
                value: region._id,
                label: region.regionName,
                ...region,
            }))
    }

    // const areaOptions = areaData?.data?.payload?.result.map((area) => ({
    //     value: area._id,
    //     label: area.areaName,
    //     ...area,
    // }))

    let areaOptions = []
    if (areaData && areaData.data.payload.result.length && regionValue) {
        areaOptions = areaData.data.payload.result
            .filter((item) => item.regionName == regionValue.label)
            .map((area) => ({
                value: area._id,
                label: area.areaName,
                ...area,
            }))
    }

    // const dbPointOptions = dbPointData?.data?.payload?.result.map(
    //     (dbPoint) => ({
    //         value: dbPoint._id,
    //         label: dbPoint.dbPointName,
    //         ...dbPoint,
    //     })
    // )

    let dbPointOptions = []
    if (dbPointData && dbPointData.data.payload.result.length && areaValue) {
        dbPointOptions = dbPointData.data.payload.result
            .filter((item) => item.areaName == areaValue.label)
            .map((dbPoint) => ({
                value: dbPoint._id,
                label: dbPoint.dbPointName,
                ...dbPoint,
            }))
    }

    // const distributorOptions = distributorData?.data?.payload?.distributors.map(
    //     (distributor) => ({
    //         value: distributor._id,
    //         label: distributor.distributorName,
    //         ...distributor,
    //     })
    // )

    let distributorOptions = []
    if (
        distributorData &&
        distributorData.data.payload.distributors.length &&
        dbPointValue
    ) {
        distributorOptions = distributorData.data.payload.distributors
            .filter((item) => item.dbPoint == dbPointValue.label)
            .map((distributor) => ({
                value: distributor._id,
                label: distributor.distributorName,
                ...distributor,
            }))
    }

    // const allOrdersData = orderData?.data?.payload?.result || []

    let allOrdersData = []
    if (orderData && orderData.data.payload.result.length) {
        allOrdersData = orderData.data.payload.result.filter(
            (item) => item.status == 6
        )
    }

    // const allStocksData = stockData?.data?.payload?.result || []

    let allStocksData = []
    if (stockData && stockData.data.payload.result.length) {
        allStocksData = stockData.data.payload.result
    }

    // const allScheduleData = scheduleData?.data?.payload?.result || []

    let allScheduleData = []
    if (scheduleData && scheduleData.data.payload.result.length) {
        allScheduleData = scheduleData.data.payload.result
    }

    const getFormattedDate = (data) => {
        const originalDateString = data
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`

        return formattedDateString
    }

    const getFormattedDate2 = (dt) => {
        const deliveryDate = new Date(dt)

        const year = deliveryDate.getFullYear()
        const month = String(deliveryDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(deliveryDate.getDate()).padStart(2, '0')

        const formattedDate = `${year}-${month}-${day}`

        return formattedDate
    }

    const handleScheduleSubmit = () => {
        const sc =
            Math.floor(Math.random() * 1000000000) + '-' + user.data.userId
        const postArr = schedulableOrders
            .filter((tr) => tr.deliveryQty > 0)
            .map((item) => {
                delete item._id
                return {
                    ...item,
                    factoryName: item.factoryName[0],
                    userId: user.data.userId,
                    scheduleNo: sc,
                    zoneName: item.zone,
                    regionName: item.region,
                    areaName: item.area,
                    delQtyCtn: item.deliveryQty,
                    delQtyPcs: item.pcsCTN * item.deliveryQty,
                    orderDate: item.doDate,
                    scheduleDate: getFormattedDate(new Date()),
                    deliveryDate: getFormattedDate2(item.deliveryDate),
                    status: 8,
                    productCategory: item.categoryName,
                }
            })

        scheduleSubmit(postArr)
            .then((res) => {
                if (res.data?.success) {
                    setSelectedFactoryName('')
                    setSelectedSKUName('')
                    setSelectedDoNo('')
                    // insertedDistributionSkus
                    // window.location.reload()
                    setSubmittedOrders([
                        ...submittedOrders,
                        res.data ? res.data?.insertedDistributionSkus : [],
                    ])

                    toast.success(res.data?.message)
                    // navigate('/distribution/productscheduling/pending')
                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    toast.error(e.response?.data?.errors)
                }
            })
    }

    const getScheduledQuantityBySKU = (skuName, doNo) => {
        const totalQty = allScheduleData
            .filter((item) => item.skuName == skuName && item.doNo == doNo)
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    const getScheduledQuantityBySKUOnly = (skuName, factoryName) => {
        const totalQty = allScheduleData
            .filter(
                (item) =>
                    item.skuName == skuName && item.factoryName == factoryName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    return (
        <>
            <Row className="g-3 mb-3">
                <Col lg={7} xl={8}>
                    <FalconComponentCard noPreview>
                        <FalconComponentCard.Header
                            light={false}
                            title=""
                            noPreview
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <span>Selected Scheduling Distributor:</span>
                                <span style={{ fontWeight: 'bold' }}>
                                    &nbsp;{doNav.distributorName}
                                </span>
                            </div>
                        </FalconComponentCard.Header>
                        <FalconComponentCard.Body language="jsx">
                            {/* <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="mb-3">
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        controlId="exampleCity"
                                    >
                                        <Form.Label>Zone</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={zoneOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={zoneValue}
                                            onChange={(value) =>
                                                setZoneValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid city.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleState"
                                    >
                                        <Form.Label>Region</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={regionOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={regionValue}
                                            onChange={(value) =>
                                                setRegionValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid state.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Area</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={areaOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={areaValue}
                                            onChange={(value) =>
                                                setAreaValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>DB Point</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={dbPointOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={dbPointValue}
                                            onChange={(value) =>
                                                setDBPointValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Distributors</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={distributorOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={distributorValue}
                                            onChange={(value) =>
                                                setDistributorValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Button type="submit">View Undelivered</Button>
                            </Form> */}
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
                <Col lg={5} xl={4}>
                    <SharedFiles
                        title={'Available Stock'}
                        className="h-lg-60"
                        files={
                            selectedSKUname && selectedFactoryname
                                ? [
                                      {
                                          skuName: selectedSKUname,
                                          rcvQtyCTN:
                                              allStocksData.reduce(
                                                  (
                                                      accumulator,
                                                      currentValue
                                                  ) => {
                                                      return (
                                                          accumulator +
                                                          currentValue.rcvQtyCTN
                                                      )
                                                  },
                                                  0
                                              ) -
                                              getScheduledQuantityBySKUOnly(
                                                  selectedSKUname,
                                                  selectedFactoryname
                                              ),
                                      },
                                  ]
                                : []
                        }
                    />
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col lg={7} xl={8}>
                    {isLoadingSchedule && allOrdersData.length == 0 ? (
                        <h1>Loading...</h1>
                    ) : (
                        <BestSellingProducts
                            orders={
                                distributorValue
                                    ? allOrdersData.filter(
                                          (item) =>
                                              item.distributorName ==
                                              distributorValue.label
                                      )
                                    : []
                            }
                            allScheduleData={allScheduleData}
                            setSelectedFactoryName={setSelectedFactoryName}
                            setSelectedSKUName={setSelectedSKUName}
                            setSelectedDoNo={setSelectedDoNo}
                            setSchedulableOrders={setSchedulableOrders}
                            schedulableOrders={schedulableOrders}
                            handleScheduleSubmit={handleScheduleSubmit}
                            getScheduledQuantityBySKU={
                                getScheduledQuantityBySKU
                            }
                            scheduledOrders={submittedOrders}
                        />
                    )}
                </Col>
                <Col lg={5} xl={4}>
                    {isLoadingSchedule && allOrdersData.length == 0 ? (
                        <h1>Loading...</h1>
                    ) : (
                        <SharedFiles2
                            title={'Delivery Info'}
                            files={
                                selectedSKUname &&
                                selectedFactoryname &&
                                selectedDoNo
                                    ? allScheduleData.filter(
                                          (item) =>
                                              item.skuName == selectedSKUname &&
                                              item.factoryName ==
                                                  selectedFactoryname &&
                                              item.doNo == selectedDoNo
                                      )
                                    : []
                            }
                            className="h-lg-100"
                        />
                    )}
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingUpdate)
