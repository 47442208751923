import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { useSelector } from "react-redux";

const SystemUserForm = () => {
    const [selectedZone, setSelectedZone] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [zoneList, setZoneList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const [userTypeList, setUserTypeList] = useState([]);
    const [userById, setUserById] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { updateID } = useParams();
    const navigate = useNavigate();
    const { companyName } = useSelector((state) => state.auth.user.data);



    const userRoleList = [
        { label: "Admin", value: "Admin" },
        { label: "DSM", value: "DSM" },
        { label: "HOS", value: "HOS" },
        { label: "Order Management", value: "Order Management" },
        { label: "Sales Admin", value: "Sales Admin" },
        { label: "DO", value: "DO" },
        { label: "RSM", value: "RSM" },
        { label: "ASM", value: "ASM" },
        { label: "Distribution", value: "Distribution" },
        { label: "Accounts", value: "Accounts" },
        { label: "Finance", value: "Finance" },
        { label: "Inventory", value: "Inventory" },
        { label: "Production", value: "Production" },
        { label: "SCM", value: "SCM" },
        { label: "MIS", value: "MIS" },

    ]

   
    const factoryList = [

        {
            label: "SORL",
            value: "SORL"
        },
        {
            label: "SVOIL",
            value: "SVOIL"
        },
        {
            label: "Narshingdi",
            value: "Narshingdi"
        },
        {
            label: "Dhamrai",
            value: "Dhamrai"
        },
        {
            label: "Dhaka Central Depot",
            value: "Dhaka Central Depot"
        },
        {
            label: "Ctg Depot",
            value: "Ctg Depot"
        },
        {
            label: "Noakhali Depot",
            value: "Noakhali Depot"
        },
        {
            label: "Sylhet Depot",
            value: "Sylhet Depot"
        },
        {
            label: "Jessore Depot",
            value: "Jessore Depot"
        },
        {
            label: "Mymensingh Depot",
            value: "Mymensingh Depot"
        },
        {
            label: "Bogra Depot",
            value: "Bogra Depot"
        },
        {
            label: "Barisal Depot",
            value: "Barisal Depot"
        },
        {
            label: "Tekerhat Depot",
            value: "Tekerhat Depot"
        },
        {
            label: "Rangpur Depot",
            value: "Rangpur Depot"
        },
    ]


    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success) {
                        setUserById(response?.data?.payload?.user);
                        setSelectedZone(response?.data?.payload?.user?.zoneName)
                        setSelectedRegion(response?.data?.payload?.user?.regionName)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setZoneList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedZone) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.regionName,
                            value: data.regionName
                        }));

                        setRegionList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [selectedZone]);

    useEffect(() => {
        if (selectedRegion) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}area?regionName=${selectedRegion}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.areaName,
                            value: data.areaName
                        }));

                        setAreaList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [selectedRegion]);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}designation`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setDesignationList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}userType`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setUserTypeList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSubmitForm = async (values, actions) => {
        const handleSubmitData = {
            name: values.name,
            email: values.email,
            password: values.password,
            designation: values.designation.value,
            companyName: companyName,
            phoneNumber: values.phoneNumber,
            userRole: values.userRole.value,
        };
        if (values.zoneName) {
            handleSubmitData.zoneName = values.zoneName.value
        }
        if (values.regionName) {
            handleSubmitData.regionName = values.regionName.value
        }
        if (values.areaName) {
            handleSubmitData.areaName = values.areaName.value
        }
        if (values.factoryName) {
            handleSubmitData.factoryName = values.factoryName.value
        }
        if (!updateID) {
            handleSubmitData.userId = values.userId;
        }
        setIsLoading(true);
        const url = updateID
            ? `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/${updateID}`
            : `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/create-user`;

        try {
            const axiosMethod = updateID ? axios.put : axios.post;
            const response = await axiosMethod(url, handleSubmitData, { headers: authHeader() });

            if (response.status === 200 || response.data.status) {
                navigate("/user/systemUser");
                toast.success(response.data.message);
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("please enter a value for the name field").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            }),
        email: Yup.string().nullable(),
        userId: Yup.string().required("please enter a value for the user id field"),
        password: Yup.string().min(6, 'User password length can be minimum 6 characters').max(50, 'Too Long!').required("please enter a value for the password field"),
        designation: Yup.object().required("please select a value for the designation field"),
        // zoneName: Yup.object().required("please select a value for the zone name field"),
        // regionName: Yup.object().required("please select a value for the region name field"),
        // areaName: Yup.object().required("please select a value for the area name field"),
        phoneNumber: Yup.string().required("please enter a value for the phone name field"),
        userRole: Yup.object().required("please select a value for the user role field"),
    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update User" : "Add User"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    {isLoading && <LoadingIcon />}
                    <Formik
                        initialValues={{
                            name: userById.name || "",
                            email: userById.email || "",
                            userId: userById.userId || "",
                            password: userById.password || "",
                            designation: designationList.find(item => item.value === userById?.designation) || null,
                            zoneName: zoneList.find(item => item.value === userById.zoneName) || null,
                            regionName: regionList.find(item => item.value === userById?.regionName) || null,
                            areaName: areaList.find(item => item.value === userById?.areaName) || null,
                            companyName: userById.companyName || "",
                            phoneNumber: userById.phoneNumber || "",
                            userRole: userTypeList.find(item => item.value === userById?.userRole) || null,
                            factoryName: factoryList.find(item => item.value === userById?.factoryName) || null

                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched

                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select User Type or Role</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={userRoleList}
                                                placeholder="Select User Type or Role"
                                                classNamePrefix="react-select"
                                                name="userRole"
                                                value={values.userRole}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("userRole", selectedOption);

                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("userRole", true, true); }}
                                            />
                                            {touched.userRole && errors.userRole && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.userRole}
                                                </div>
                                            )}
                                        </Form.Group>

                                        {(values.userRole?.value === 'ZH' || values.userRole?.value === 'RSM' || values.userRole?.value === 'ASM' || values.userRole?.value === "DSM" || values.userRole?.value === 'Inventory' || values.userRole?.value === 'Production') && (
                                            <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                                <Form.Label>Select Zone</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={zoneList}
                                                    placeholder="Select Zone"
                                                    classNamePrefix="react-select"
                                                    name="zoneName"
                                                    value={values.zoneName}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue("zoneName", selectedOption);
                                                        setSelectedZone(selectedOption.value);
                                                    }}
                                                    onBlur={event => { handleBlur(event); setFieldTouched("zoneName", true, true) }}
                                                />
                                                {touched.zoneName && errors.zoneName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.zoneName}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        )}



                                    </Row>
                                    {(values.userRole?.value === 'RSM' || values.userRole?.value === 'ASM' || values.userRole?.value === 'Inventory' || values.userRole?.value === 'Production') && (<Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Region</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={regionList}
                                                placeholder="Select Region"
                                                classNamePrefix="react-select"
                                                name="regionName"
                                                value={values.regionName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("regionName", selectedOption);
                                                    setSelectedRegion(selectedOption.value)

                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("regionName", true, true) }}
                                            />
                                            {touched.regionName && errors.regionName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.regionName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Area</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={areaList}
                                                placeholder="Select Area"
                                                classNamePrefix="react-select"
                                                name="areaName"
                                                value={values.areaName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("areaName", selectedOption);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("areaName", true, true); }}
                                            />
                                            {touched.areaName && errors.areaName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.areaName}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>)}
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>User Id</Form.Label>
                                            <Form.Control
                                                name="userId"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter User Id"
                                                required
                                                value={values.userId}
                                                disabled={updateID}
                                            />
                                            {touched.userId && errors.userId && (
                                                <div style={{ color: "red" }}>
                                                    {errors.userId}
                                                </div>
                                            )}

                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Password"
                                                required
                                                value={values.password}
                                            />
                                            {touched.password && errors.password && (
                                                <div style={{ color: "red" }}>
                                                    {errors.password}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Name"
                                                required
                                                value={values.name}
                                            />
                                            {touched.name && errors.name && (
                                                <div style={{ color: "red" }}>
                                                    {errors.name}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Email"
                                                value={values.email}
                                            />
                                            {touched.email && errors.email && (
                                                <div style={{ color: "red" }}>
                                                    {errors.email}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                name="phoneNumber"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Phone Number"
                                                required
                                                value={values.phoneNumber}
                                            />
                                            {touched.phoneNumber && errors.phoneNumber && (
                                                <div style={{ color: "red" }}>
                                                    {errors.phoneNumber}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Designation</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={designationList}
                                                placeholder="Select Designation"
                                                classNamePrefix="react-select"
                                                name="designation"
                                                value={values.designation}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("designation", selectedOption);

                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("designation", true, true); }}
                                            />
                                            {touched.designation && errors.designation && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.designation}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    {(values?.userRole?.value === "Inventory" || values.userRole?.value === 'Production') && <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Factory/Depot</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={factoryList}
                                                placeholder="Select Factory"
                                                classNamePrefix="react-select"
                                                name="factoryName"
                                                value={values.factoryName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("factoryName", selectedOption);

                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("factoryName", true, true); }}
                                            />
                                            {touched.factoryName && errors.factoryName && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.factoryName}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>}
                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/user/systemUser")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default SystemUserForm;
