import { Button, Card } from "react-bootstrap";
import Flex from 'components/common/Flex';
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { authHeader } from "utils";
import PageHeader from "components/common/PageHeader";
import * as Yup from "yup";
import LoadingIcon from "helpers/LoadingIcon";
import { useNavigate, useParams } from "react-router-dom";
import { formatNumber } from "helpers/utils";
import useUserRole from "hooks/useUserRole";

const AdjustmentOrderDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dealerId, setDealerId] = useState();
    const [paymentInfo, setPaymentInfo] = useState([]);
    const [depositList, setDepositList] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [dueAmount, setDueAmount] = useState(0);
    const [orderItems, setOrderItems] = useState([]);
    const userInfo = useSelector((state) => state.auth.user.data);
    const { id } = useParams()
    const navigate = useNavigate();
    const userRole = useUserRole();


    useEffect(() => {
        const hasHyphen = id.includes('-');
        const dealerId = hasHyphen ? id.split('-')[1] : 'dealer id not found';
        setDealerId(dealerId);
    }, []);


    // Get do data
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/singleOrder/${id}`;
                const response = await axios.get(url, { headers: authHeader() });
                console.log(response.data);
                if (response.data.success || response.status === 200) {
                    const orders = response?.data?.payload?.DoData;
                    setOrderItems(orders);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id]);


    // debit amount list
    useEffect(() => {
        if (dealerId) {
            // setIsLoading(true);
            const fetchData = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/db/payInfo/${dealerId}`,
                        { headers: authHeader() }
                    );

                    if (response.data.success || response.status === 200) {
                        let totalDebit = 0;
                        response?.data?.payload?.payDoData?.forEach((item) => {
                            if (item?.status === 2 && item.drBDT) {
                                totalDebit += item.drBDT;

                            }
                        }
                        )
                        setTotalDebit(parseFloat(totalDebit));

                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [dealerId]);


    // deposit list
    useEffect(() => {
        if (dealerId) {
            // setIsLoading(true);
            const fetchData = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/db/deposit/${dealerId}`,
                        { headers: authHeader() }
                    );

                    if (response.data.success || response.status === 200) {
                        const data = response?.data?.payload?.depositDoData;
                        const deposits = data?.filter(deposit => deposit?.status === 2);
                        setDepositList(deposits);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [dealerId]);


    // payment info
    useEffect(() => {
        // setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/payInfo/${id}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    setPaymentInfo(response?.data?.payload?.payDoData[0]);
                    setDueAmount(response?.data?.payload?.payDoData[0]?.crBDT);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    // Calculate total deposit
    const calculateTotalDeposit = (deposit, debit) => {
        const sumData1 = deposit?.reduce((total, item) => total + item.drBDT, 0);
        return sumData1 - debit;
    }


    const handleSubmit = async () => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "Do you want to pay this amount?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, pay it!"
        });

        if (confirmResult.isConfirmed) {
            setIsLoading(true);
            const orderItems = {
                doId: id,
                crBDT: paymentInfo?.crBDT - dueAmount,
                drBDT: paymentInfo?.drBDT + dueAmount,
                updatePayId: userInfo?.userId,

            }

            try {
                const orderResponse = await axios.put(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/${paymentInfo?._id}`, orderItems, { headers: authHeader() });

                if (orderResponse.data.status || orderResponse.status === 200) {

                    Swal.fire({
                        icon: 'success',
                        title: 'Payment Successful',
                        showConfirmButton: false,
                        timer: 1500
                    });

                    navigate("/accounts/due-payment-list");
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };


    if (isLoading) return <LoadingIcon />


    const handleSchema = Yup.object().shape({
        // BDTAmount: Yup.string().required("BDT Amount is required"),
        // note: Yup.string().nullable(),

    });

    return <>
        <PageHeader
            title={`Available Balance: ${formatNumber(calculateTotalDeposit(depositList, totalDebit))}`}
            /* description2={
                <div>
                    <p style={{ color: "#354151", fontWeight: "500", fontFamily: 'Poppins' }}>DB ID: {distributorInfo?.userId}, Distributor Name: {distributorInfo?.name || distributorInfo?.distributorName}, Mobile No: {distributorInfo?.phoneNumber}, Address: {distributorInfo?.address}</p>
                </div>
            } */
            className="mb-3"
        />
        <div className="bg-white">
            <div className="table-responsive scrollbar">
                <table className="table table-bordered table-striped fs--1 mb-0">
                    <thead >
                        <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                            <th className="align-middle white-space-nowrap text-center ">Serial</th>
                            <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                            <th className="align-middle white-space-nowrap text-center ">Order Qty CTN/Bag</th>
                            <th className="align-middle white-space-nowrap text-center ">Order Qty PCS</th>
                            <th className="align-middle white-space-nowrap text-center ">Price CTN</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Discount BDT</th>
                            <th className="align-middle white-space-nowrap text-center ">Total Price CTN/Bag</th>

                            {/* <th colSpan="1" className="align-middle white-space-nowrap text-center ">Gift Offer Note</th> */}
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Offer</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Offer Quantity</th>

                        </tr>
                    </thead>
                    <tbody className="list" id="table-purchase-body">
                        {
                            orderItems?.map((product, index) => {
                                return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                    <td className="align-middle text-center">{index + 1}</td>
                                    <td className="align-middle text-center">{product?.skuName || product?.offerName}</td>
                                    <td className="align-middle text-center">{formatNumber(product?.orderQtyCtn) || "--"}</td>
                                    <td className="align-middle text-center">{formatNumber(product?.totalOrderQtyPCS) || "--"}</td>
                                    <td className="align-middle text-center">{formatNumber(product?.dpCTN) || "--"}</td>
                                    <td className="align-middle text-center">{formatNumber(product?.discountBDT) || "--"}</td>
                                    <td className="align-middle text-center">{formatNumber(product?.totalPrice) || "--"}</td>
                                    {/* <td className="align-middle text-center">{product?.offerNote}</td> */}
                                    <td className="align-middle text-center">{product?.offerSkuName}</td>
                                    <td className="align-middle text-center">{formatNumber(product?.offerPc)}</td>
                                </tr>

                            })
                        }

                        <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151", backgroundColor: "#98c1d9" }}>
                            <td className="align-middle white-space-nowrap text-center" colSpan="6">Total</td>
                            <td className="align-middle white-space-nowrap text-center" colSpan="1">
                                {
                                    formatNumber(orderItems?.reduce((sum, product) => sum + (product.totalPrice || 0), 0))
                                }
                            </td>
                            <td></td>
                            <td></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>

        {(userRole === "Accounts" || userRole === "Finance") && <Card className="mb-3 mb-lg-0 mt-3">
            <Card.Header as="h6" className="bg-light">
                <Flex justifyContent="between" alignItems="center">
                    <h5 >Total Pay: <span className="bg-success">{formatNumber(paymentInfo?.drBDT)} BDT</span></h5>
                    <br />

                    <h5 >Total Due: <input type="number" value={dueAmount} onChange={(e) => setDueAmount(e.target.value)}></input></h5>
                    <Button className="mt-3" variant="primary" disabled={(calculateTotalDeposit(depositList, totalDebit) < dueAmount) || (orderItems?.reduce((sum, product) => sum + (product.totalPrice || 0), 0)) < dueAmount} onClick={handleSubmit} >pay</Button>
                </Flex>

            </Card.Header>
        </Card>}
        <Button variant="primary" className='me-2 mt-2 px-5' onClick={() => navigate("/accounts/due-payment-list")}>Back</Button>
    </>
}
export default AdjustmentOrderDetails;