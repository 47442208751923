import handleApiResponseErrors from "helpers/handleApiResponse";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { authHeader } from "utils";
import SpecialDistributorOrderUpdateStep1 from "./SpecialDistributorOrderUpdateStep1";
import SpecialDistributorOrderUpdateStep2 from "./SpecialDistributorOrderUpdateStep2";
import SpecialDistributorOrderUpdateStep3 from "./SpecialDistributorOrderUpdateStep3";
import axios from "axios";


const SpecialDistributorOrderUpdate = () => {
    const { doNo } = useParams();
    const [step, setStep] = useState(2);
    const [checkedProducts, setCheckedProducts] = useState([]);
    const [distributorOrderNumber, setDistributorOrderNumber] = useState(doNo);

    const [distributorInfo, setDistributorInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleNext = () => {
        setStep(step + 1);
    }

    const handleBack = () => {
        setStep(step - 1);
    }

    
      //distributor profile API call here
      useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dealer/${doNo.split("-")[1]}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    setDistributorInfo(response.data.payload.distributorData);
                    setIsLoading(false);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [doNo]);


    return (
        <>
            {step === 1 && !isLoading && distributorInfo && (
                <SpecialDistributorOrderUpdateStep1 distributorInfo={distributorInfo}  handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
            )}
            {step === 2 && !isLoading && distributorInfo && (
                <SpecialDistributorOrderUpdateStep2 distributorInfo={distributorInfo} step={step} handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
            )}
            {step === 3 && !isLoading && distributorInfo && (
                <SpecialDistributorOrderUpdateStep3 distributorInfo={distributorInfo} setStep={setStep} distributorOrderNumber={distributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} setDistributorOrderNumber={setDistributorOrderNumber} />
            )}
        </>
    )
}

export default SpecialDistributorOrderUpdate;