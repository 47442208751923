import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import Swal from "sweetalert2";
import handleApiResponseErrors from "helpers/handleApiResponse";

const Sosr = () => {
    const [productsData, setProductsData] = useState([]);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SR_MANAGEMENT_BASE_URL}sales-representitives?rows=${pageDataCount}&page=${paginationPageCount}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const totalPages = response?.data?.payload?.pagination?.totalPages;

                    const result = response.data.payload.
                        salesRepresentatives.map((element, index) => ({
                            ...element,
                            index: startIndex + index
                        }));

                    setTotalPages(totalPages);
                    setProductsData(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount]);

    console.log('sr', productsData);

    // DELETE
    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_SR_MANAGEMENT_BASE_URL}sales-representitives/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 204) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };


    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "Sl",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "ZoneName",
            Header: "Zone Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { zone } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{zone}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "RegionName",
            Header: "Region Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { region } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{region}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "AreaName",
            Header: "Area Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { area } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{area}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "dealer",
            Header: "Dealer",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { dealer } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{dealer}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "designation",
            Header: "Designation",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { designation } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{designation}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "SR-Name",
            Header: "SR Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { srName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{srName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "dobDate",
            Header: "Dob Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { dobDate } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{dobDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "dojDate",
            Header: "Doj Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { dojDate } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{dojDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "contribution",
            Header: "contribution",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { contribution } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{contribution}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "BasicSalary",
            Header: "Basic Salary",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { basicSalary } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{basicSalary}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "HouseRent",
            Header: "House Rent",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { houseRent } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{houseRent}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Medical",
            Header: "Medical",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { medical } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{medical}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "TADA",
            Header: "TA/DA",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { taDa } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{taDa}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "PhoneBill",
            Header: "Phone Bill",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { phoneBill } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{phoneBill}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "MettingTA",
            Header: "Metting TA",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { meetingTa } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{meetingTa}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "MettingDA",
            Header: "Metting DA",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { meetingDa } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{meetingDa}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "MobileNo",
            Header: "Mobile No",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { mobileNo } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{mobileNo}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Company",
            Header: "Company",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { company } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{company}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Y.SSC",
            Header: "Y.SSC",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { sscYear } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{sscYear}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "H.Degree",
            Header: "H.Degree",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { hDegree } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{hDegree}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "Blood",
            Header: "Blood",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { bloodGroup } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{bloodGroup}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "textDataId",
            Header: "text Data Id",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { textDataId } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{textDataId}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "NameEmergency",
            Header: "Name Emergency",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { emergencyContactName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{emergencyContactName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "MobileNoEmergency",
            Header: "Mobile No Emergency",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { emergencyContactMobile } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{emergencyContactMobile}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "RelationwithSO-SR",
            Header: "Relation",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { emergencyContactRelation } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{emergencyContactRelation}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "District",
            Header: "District",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { district } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{district}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "EMail",
            Header: "EMail",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { email } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{email}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "BankName",
            Header: "Bank Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { bankName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{bankName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "BankAccountNo",
            Header: "Bank Account No",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { bankAccountNo } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{bankAccountNo}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Other",
            Header: "Other",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { imageUrl } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{imageUrl}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Remarks",
            Header: "Remarks",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { imageUrl } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{imageUrl}</h5>
                        </div>
                    </Flex>

                );
            },
        },


        {
            accessor: "status",
            Header: "status",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{status === true ? "Active" : "InActive"}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "action",
            Header: "Action",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { _id } = rowData.row.original;
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="">
                                <Link to={`/master/sr/add/${_id}`}>
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="edit"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                    </IconButton>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                                <IconButton
                                    onClick={() => handleDelete(_id)}
                                    variant="falcon-default"
                                    size="sm"
                                    icon="trash-alt"
                                    iconAlign="middle"
                                    className="d-none d-sm-block me-2"
                                // disabled={true}
                                >
                                    <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                </IconButton>

                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                );
            },
        },
    ];


    /*******************
  Columns End.
  *******************/


    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={productsData}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader title="SR" newUrl="/master/sr/add" data={productsData} isExport={false} table />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                        />

                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default Sosr;
