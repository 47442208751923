import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GreetingsDate from "components/dashboards/crm/greetings/GreetingsDate";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../../helpers/utils";

const TransferPanelConfirm = ({ setStep, challanNo, selectedDate, transportData, selectedExpiryDate, batchNo, checkedProducts, setCheckProducts, setCount, factoryName }) => {
    const [isLoading, setIsLoading] = useState(false);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);


    // handle remove product
    const handleRemoveProduct = async (skuName) => {

        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            const result = checkedProducts?.filter(product => product?.skuName !== skuName);
            setCheckProducts(result);

            Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
            });


        }
    }



    const handleSubmit = async () => {

        const receivedProduct = checkedProducts.map((product) => {
            const receivedProduct = {
                challanNo: challanNo,
                userId: distributorInfo?.userId,
                companyName: distributorInfo?.companyName,
                factoryName: factoryName,
                productCategory: product?.productCategory,
                subCategory: product?.subCategory || "",
                skuName: product?.skuName,
                productId: product?.data[0]?.productId || product?._id || "",
                rcvQtyCTN: 0,
                rcvQtyPCS: 0,//product?.pcs,
                rcvQtyMt: 0,
                rcvDate: null,
                transportName: transportData?.transportName?.value,
                transportNo: transportData?.transportNumber,
                driverName: transportData?.driverName,
                driverPhone: transportData?.driverNumber,
                transQtyCTN: product?.transferCTN,
                transQtyPCS: product?.transferPCS,
                transQtyMt: product?.transferWeightMT,
                transDate: selectedDate,
                expireDate: product?.expireDate || null,  //selectedExpiryDate,
                batchNo: product?.batchNo,
                note: batchNo,
                fromFactory: distributorInfo?.factoryName,
                erpId: product?.erpId || product?.data[0]?.erpId,
                perPCPrice: product?.data[0]?.perPCPrice || 0,
                productionDate: product?.productionDate || null,
                tranStatus: 1,
            };

            return receivedProduct;
        });


        const receivedProduct2 = checkedProducts.map((product) => {

            const receivedProduct2 = {
                challanNo: challanNo,
                userId: distributorInfo?.userId,
                companyName: distributorInfo?.companyName,
                factoryName: distributorInfo?.factoryName,
                productCategory: product?.productCategory,
                subCategory: product?.subCategory || "",
                skuName: product?.skuName,
                productId: product?.data[0]?.productId || product?._id || "",
                rcvQtyCTN: -(product?.transferCTN),
                rcvQtyPCS: -(product?.transferPCS),//product?.pcs,
                rcvQtyMt: -(product?.transferWeightMT),
                rcvDate: null,
                transportName: transportData?.transportName?.value,
                transportNo: transportData?.transportNumber,
                driverName: transportData?.driverName,
                driverPhone: transportData?.driverNumber,
                // transQtyCTN: product?.transferCTN,
                // transQtyPCS: product?.transferPCS,
                // transQtyMt: product?.transferWeightMT,
                transDate: selectedDate,
                expireDate: product?.expireDate || null,  //selectedExpiryDate,
                batchNo: product?.batchNo,
                note: batchNo,
                fromFactory: distributorInfo?.factoryName,
                erpId: product?.erpId || product?.data[0]?.erpId,
                perPCPrice: product?.data[0]?.perPCPrice || 0,
                productionDate: product?.productionDate || null,
                status: 1,
            };

            return receivedProduct2;
        });


        try {
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv`, receivedProduct, { headers: authHeader() });
            if (response.data.status || response.status === 200) {

                // Swal.fire({
                //     title: "Transfered!",
                //     text: "Your stocks has been received.",
                //     icon: "success"
                // });

                // setStep(1)
                // setCheckProducts([]);
                // setCount(prevState => prevState + 1);

                const response2 = await axios.post(`${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv`, receivedProduct2, { headers: authHeader() });

                if (response2.data.status || response2.status === 200) {
                    Swal.fire({
                        title: "Transfered!",
                        text: "Your stocks has been received.",
                        icon: "success"
                    });

                    setStep(1)
                    setCheckProducts([]);
                    setCount(prevState => prevState + 1);
                }


            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }




    };

    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>

            <Card className="mb-3">
                <Card.Header>
                    <div className="">
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Selected Factory :</h6>
                                <Form.Control type="text" placeholder="Enter Vendor Name" value={factoryName} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transport :</h6>
                                <Form.Control type="text" placeholder="Enter Transport" value={transportData?.transportName?.value} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Vehicle No. :</h6>
                                <Form.Control type="text" placeholder="Enter Vehicle No." value={transportData?.transportNumber} disabled />
                            </Form.Group>


                        </Row>
                        <Row className="mb-3">

                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Name:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Name" value={transportData?.driverName} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Number:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Number" value={transportData?.driverNumber} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transfer Date :</h6>
                                <Form.Control type="text" placeholder="Enter Vendor Name" value={selectedDate} disabled />
                            </Form.Group>
                        </Row>

                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Transfer QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Transfer QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Transfer QTY Weight(MT)</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Action</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Note</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        checkedProducts?.map((product, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.productCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">
                                                    {product?.transferCTN}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {formatNumber(product?.transferPCS)}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {formatNumber(product?.transferWeightMT)}
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Button variant="danger" onClick={() => handleRemoveProduct(product?.skuName)}>Cancel</Button>
                                                </td>
                                                <td className="align-middle text-center w-50">
                                                    {product?.note}
                                                </td>
                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>

            <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleSubmit(); }}>
                Confirm
            </Button>

            <Button className="mt-2 px-5 ms-3" variant="primary" type="submit" onClick={() => { setStep(1) }}>
                Back
            </Button>

        </>
    );

};

export default TransferPanelConfirm;
