import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import ReturnPanelConfirm from "./ReturnPanelConfirm";
import Select from 'react-select'



const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '180', // Set your desired width here
    }),
    control: (provided) => ({
        ...provided,
        width: '180px', // Ensure control element also respects the width
    }),
};


const ReturnPanel = () => {
    const [step, setStep] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSource, setSelectedSource] = useState(null);
    const [selectedDistributor, setSelectedDistributor] = useState(null);
    const [count, setCount] = useState(0);
    const [batchNo, setBatchNo] = useState(null);
    const [productsData, setProductsData] = useState([]);
    const [distributorsData, setDistributorsData] = useState([]);
    const [checkedProducts, setCheckProducts] = useState([]);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);


    // DATE
    const handleDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
    };




    const handleReceivedProducts = (event, product, fieldName) => {
        const { value } = event.target;
        const calculatedPcs = value * product?.pcsCTN;
        const totalWeight = calculatedPcs * product?.weightPCS || 0;
        const calculateWeight = totalWeight / 1000000;


        setProductsData((prevProducts) =>
            prevProducts.map(productItem =>
                productItem.skuName === product.skuName
                    ? { ...productItem, ctn: Number(value), pcs: Number(calculatedPcs), weightMT: Number(calculateWeight) }
                    : productItem
            )
        );
    };


    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?search=${searchText}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const exactMatchProducts = await response?.data?.payload?.products
                        ?.filter(product =>
                            product?.factoryName?.every(factory =>
                                factory === distributorInfo?.factoryName
                            )
                        )

                    setProductsData(exactMatchProducts);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, distributorInfo?.factoryName]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors`;
                const response = await axios.get(url, { headers: authHeader() });
                console.log(response)
                if (response.data.success || response.status === 200) {
                    const exactMatchProducts = await response?.data?.payload?.distributors


                    setDistributorsData(exactMatchProducts.map((distributor) => ({ label: distributor?.distributorName + "-" + distributor?.userId, value: distributor?.userId })));
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        if (selectedSource?.value === 'distributor') {
            fetchData();
        }



    }, [selectedSource, count, distributorInfo?.factoryName]);



    // Filter products based on search term
    const filteredProducts = productsData.filter(product =>
        product.skuName.toLowerCase().includes(searchText.toLowerCase()) ||
        product.erpId.toLowerCase().includes(searchText.toLowerCase())
    );



    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchText(value);
    };

    // Add Note
    const handleAddNote = (event, product) => {
        const { value } = event.target;

        setProductsData((prevProducts) =>
            prevProducts.map(productItem =>
                productItem.skuName === product.skuName
                    ? { ...productItem, note: value }
                    : productItem
            )
        );
    }


    const handleNext = () => {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select a date",
                icon: "warning"
            });
            return;
        }


        const checkedProducts = productsData.filter((product) => product.ctn && product.pcs);
        if (checkedProducts?.length > 0) {
            setCheckProducts(checkedProducts);
            setStep(2);

        } else {
            Swal.fire({
                title: "Please fill the quantity",
                icon: "warning"
            });
            return;
        }
    }



    if (isLoading) {
        return <LoadingIcon />;
    }

    const sourceData = [
        {
            value: 'factory',
            label: 'Factory'
        },

        {
            value: 'distributor',
            label: 'Distributor'
        },

        {
            value: 'transport',
            label: 'Transport'
        },

    ]





    return (
        <>
            <PageHeader
                title="Products Return"
                className="mb-3"
            ></PageHeader>

            {step === 1 && <><Card className="mb-3">
                <Card.Header>
                    <div className="d-flex ">
                        {/* <CustomersTableHeader isNew={false} isExport={false} isSearch={true} setSearchText={setSearchText} /> */}
                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Search By Sku/ERP ID :</h6>
                                <input type="search" className="form-control" placeholder="Search By Sku/ERP ID" onChange={(e) => handleSearch(e)} />
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Return Source :</h6>
                                <Select
                                    // styles={customStyles}
                                    closeMenuOnSelect={true}
                                    options={sourceData}
                                    placeholder="Select"
                                    // isMulti
                                    classNamePrefix="react-select"
                                    value={selectedSource}
                                    onChange={(value) =>
                                        setSelectedSource(value)
                                    }
                                />
                            </Form.Group>

                            {selectedSource?.value === 'distributor' && (<Form.Group as={Col} md="5" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Select Distributor</h6>
                                <Select
                                    // styles={customStyles}
                                    closeMenuOnSelect={true}
                                    options={distributorsData}
                                    placeholder="Select"
                                    // isMulti
                                    classNamePrefix="react-select"
                                    value={selectedDistributor}
                                    onChange={(value) =>
                                        setSelectedDistributor(value)
                                    }
                                />
                            </Form.Group>)}


                            <Form.Group as={Col} md="4" controlId="exampleFirstName" className="mt-3">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Return Date :</h6>
                                <input type="date" className="form-control" placeholder="" onChange={(e) => handleDateChange(e)}
                                value={selectedDate}
                                />
                            </Form.Group>
                        </Row>

                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Sub Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Note</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        filteredProducts.length ? filteredProducts?.map((product, index) => {
                                            console.log(product)
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.categoryName}</td>
                                                <td className="align-middle text-center">{product?.subCategory}</td>
                                                <td className="align-middle text-center">{`${product?.skuName} (${product.erpId})`}</td>
                                                <td className="align-middle text-center">
                                                    <Form.Control type="text" placeholder="" onChange={(event) => handleReceivedProducts(event, product, "ctn")}
                                                        value={product && product.ctn !== undefined ? product.ctn : ""}
                                                    />
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product && product.pcs !== undefined ? product.pcs : ""}
                                                        // onChange={(event) => handleReceivedProducts(event, index, product, "pcs")}
                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product && product.weightMT !== undefined ? product.weightMT : ""}
                                                        readOnly
                                                    />

                                                </td>

                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        as={"textarea"}
                                                        type="text"
                                                        placeholder="Enter Note"
                                                        style={{ width: "150px" }}
                                                        value={product && product.note !== undefined ? product.note : ""}
                                                        onChange={(event) => handleAddNote(event, product)}
                                                    />

                                                </td>

                                            </tr>

                                        }) : <tr><td colSpan="7" className="text-center"><h6>No data available</h6></td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
                <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleNext(); }}>
                    Return
                </Button></>}

            {
                step === 2 && <ReturnPanelConfirm setStep={setStep} vendorName={selectedSource.value} distributor={selectedDistributor} selectedDate={selectedDate} batchNo={batchNo} checkedProducts={checkedProducts} setCheckProducts={setCheckProducts} setCount={setCount} />
            }

        </>
    );

};

export default ReturnPanel;
