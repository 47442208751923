import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "components/common/IconButton";
import handleApiResponseErrors from "helpers/handleApiResponse";
import PageHeader from "components/common/PageHeader";
import { formatNumber } from "helpers/utils";

const AdjustmentOrdersList = ({ setStep, data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [depositList, setDepositList] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const { dealerId, dealerName, phone } = data[0];
    const navigate = useNavigate();

    // debit amount list
    useEffect(() => {
        if (dealerId) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/db/payInfo/${dealerId}`,
                        { headers: authHeader() }
                    );

                    if (response.data.success || response.status === 200) {
                        let totalDebit = 0;
                        response?.data?.payload?.payDoData?.forEach((item) => {
                            if (item?.status === 2 && item.drBDT) {
                                totalDebit += item.drBDT;

                            }
                        }
                        )
                        setTotalDebit(parseFloat(totalDebit));

                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [dealerId]);


    // deposit list
    useEffect(() => {
        if (dealerId) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/db/deposit/${dealerId}`,
                        { headers: authHeader() }
                    );

                    if (response.data.success || response.status === 200) {
                        const data = response?.data?.payload?.depositDoData;
                        const deposits = data?.filter(deposit => deposit?.status === 2);
                        setDepositList(deposits);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [dealerId]);


    // Calculate total deposit
    const calculateTotalDeposit = (deposit, debit) => {
        const sumData1 = deposit?.reduce((total, item) => total + item.drBDT, 0);
        return sumData1 - debit;
    }

    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>
            <PageHeader
                title={`Available Balance: ${formatNumber(calculateTotalDeposit(depositList, totalDebit))}`}
                description2={
                    <div>
                        <p style={{ color: "#354151", fontWeight: "500", fontFamily: 'Poppins' }}>DB ID: {dealerId}, Distributor Name: {dealerName}, Mobile No: {phone}</p>
                    </div>
                }
                className="mb-3"
            />
            {<Card className="mb-3">
                <Card.Header>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial No.</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DO-No</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB ID</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB Name</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Due Amount</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Pay Amount</th>



                                        <th className="align-middle white-space-nowrap text-center ">
                                            Action</th>

                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        data?.map((order, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{order?.doNo}</td>
                                                <td className="align-middle text-center">{order?.dealerId}</td>
                                                <td className="align-middle text-center">{order?.dealerName}</td>
                                                <td className="align-middle text-center">{formatNumber(order?.crBDT)}</td>
                                                <td className="align-middle text-center">{formatNumber(order?.drBDT)}</td>

                                                <td>
                                                    <Dropdown className="d-inline mx-2">
                                                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                                                            ...
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item>
                                                                <Link to={`/accounts/orders/details/${order?.doNo}`} >
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        size="sm"
                                                                        icon="edit"
                                                                        transform="shrink-2"
                                                                        iconAlign="middle"
                                                                        className="me-2"
                                                                    >
                                                                        <span className="d-none d-xl-inline-block ms-1">More details</span>
                                                                    </IconButton>
                                                                </Link>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>

                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>}

            <Button
                variant="primary"
                className="me-2 mt-2 px-5"
                onClick={() => setStep(prevState => ({ ...prevState, step: 1 }))}>
                Back
            </Button>

        </>
    );

};

export default AdjustmentOrdersList;
