import { Button, Card, CloseButton, Col, Form, Modal, Row } from "react-bootstrap";
import Flex from 'components/common/Flex';
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { authHeader } from "utils";
import PageHeader from "components/common/PageHeader";
import { Formik } from "formik";
import * as Yup from "yup";
import IconButton from "components/common/IconButton";
import LoadingIcon from "helpers/LoadingIcon";

const SuperDistributorOrderCreateStep3 = ({ setStep, handleBack, distributorOrderNumber, checkedProducts, setCheckedProducts, setDistributorOrderNumber }) => {
    const [show, setShow] = useState(false);
    const [totalPayableAmount, setTotalPayableAmount] = useState(0);
    const [paymentData, setPaymentData] = useState([]);
    const [totalDueAmount, setTotalDueAmount] = useState(0);
    const [totalPayAmount, setTotalPayAmount] = useState(0);
    const [depositList, setDepositList] = useState([]);
    const [creditList, setCreditList] = useState([]);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [paymentUpdateId, setPaymentUpdateId] = useState(null);
    const [paymentGetById, setPaymentGetById] = useState([])
    const [totalCredit, setTotalCredit] = useState(0);



    const handleShow = () => {
        setShow(true);
    };


    // Calculate total deposit
    const calculateTotalDeposit = (deposit, credit) => {
        const sumData1 = deposit?.reduce((total, item) => total + item.drBDT, 0);
        const sumData2 = credit?.reduce((total, item) => total + item.drBDT, 0);
        return sumData1 - sumData2;
    }

    // handle remove product
    const handleRemoveProduct = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const result = checkedProducts?.filter(product => product?._id !== id);
                    setCheckedProducts(result);

                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    }

    // handle total payable price
    const handleTotalPayablePrice = (product) => {
        if (product?.productDiscount && product?.productDiscount?.length > 0) {
            const discountBDT = product?.productDiscount[0]?.discountBDT ?? 0;
            return product?.totalPrice - discountBDT;
        } else {
            return product?.totalPrice;
        }
    };

    // Calculate total payable amount
    useEffect(() => {
        const {
            totalOrderQuantityCtn,
            totalOrderQtyPcs,
            totalPriceCtn,
            totalDiscount,
            totalPayable,
        } = checkedProducts?.reduce((accumulator, product) => {
            accumulator.totalOrderQuantityCtn += parseFloat(product?.orderQuantity);
            accumulator.totalOrderQtyPcs += product?.totalOrderQtyPCS;
            accumulator.totalPriceCtn += product?.totalPrice;
            accumulator.totalDiscount += parseFloat(product?.productDiscount?.[0]?.discountBDT ?? 0);
            accumulator.totalPayable += handleTotalPayablePrice(product);
            return accumulator;
        }, {
            totalOrderQuantityCtn: 0,
            totalOrderQtyPcs: 0,
            totalPriceCtn: 0,
            totalDiscount: 0,
            totalPayable: 0,
        });

        // Add total to checkedProducts
        checkedProducts.total = {
            totalOrderQuantityCtn,
            totalOrderQtyPcs,
            totalPriceCtn,
            totalDiscount,
            totalPayable,
        };
        setTotalPayableAmount(totalPayable);
    }, [checkedProducts]);



    // Get payment data
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank?doNo=${distributorOrderNumber}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    setPaymentData(response.data.payload.result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [show, count, distributorOrderNumber]);


    // Calculate total payment and due amount
    useEffect(() => {
        const totalPayment = paymentData?.reduce((accumulator, data) => {
            // Check if BDTAmount exists and is a valid number
            if (data?.drBDT && !isNaN(parseFloat(data?.drBDT))) {
                accumulator += parseFloat(data?.drBDT);
            }
            return accumulator;
        }, 0);

        // Check if checkedProducts is properly initialized and totalPayable exists and is a valid number
        const totalPayable = checkedProducts?.total?.totalPayable ?? 0;
        const calculateTotalDue = totalPayment ? totalPayable - totalPayment : totalPayable;

        setTotalPayAmount(totalPayment);
        setTotalDueAmount(isNaN(calculateTotalDue) ? 0 : calculateTotalDue);
    }, [checkedProducts, paymentData]);


    // Delete payment data
    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                setIsLoading(true)
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Product has been deleted.!",
                        text: "successfully deleted!",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };



    // Show update modal
    const handleShowUpdateModal = id => {
        if (id) {
            setPaymentUpdateId(id);
            setIsLoading(true);
            axios
                .get(
                    process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL + `orderWithBank/${id}`,
                    { headers: authHeader() }
                )
                .then(response => {
                    if (response.data.success || response.status === 200) {
                        setPaymentGetById(response?.data?.payload?.orderbankData);

                    }
                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
        setShow(true);
    };


    const handlePaymentSubmit = async (values) => {
        setIsLoading(true);
        try {

            if (values.BDTAmount > calculateTotalDeposit(depositList, creditList) && paymentUpdateId === null) {
                Swal.fire({
                    title: "You can't pay more than your available balance!",
                    text: `Your available balance is ${calculateTotalDeposit(depositList, creditList)} BDT`,
                    icon: "error"
                });
                return;
            }

            const handleSubmitData = {
                zone: distributorInfo.zoneName,
                region: distributorInfo.regionName,
                area: distributorInfo.areaName,
                paymentNote: values.note,
                orderNumber: distributorOrderNumber,
                distributorId: distributorInfo.userId,
                companyName: distributorInfo.companyName,
                dealerId: distributorInfo.userId,
                dealerName: distributorInfo.distributorName,
                dbType: distributorInfo.userRole,
                superDBId: distributorInfo?.superDBId,
                dbPoint: distributorInfo?.dbPoint,
                phone: distributorInfo.phoneNumber,
                doNo: distributorOrderNumber,
                recordDate: new Date(),
                // paymentMethod: "Cash",
                drBDT: values.BDTAmount,
                crBDT: totalDueAmount,
                status: 1,
                paymentDate: new Date(),
            };

            const url = paymentUpdateId
                ? `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/${paymentUpdateId}`
                : `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/create`;

            const axiosMethod = paymentUpdateId ? axios.put : axios.post;

            const response = await axiosMethod(url, handleSubmitData, { headers: authHeader() });

            if (response.data.status || response.status === 200) {
                setPaymentUpdateId(null);
                setPaymentGetById([]);
                Swal.fire({
                    title: "Good job!",
                    text: "Payment Successfully Added!",
                    icon: "success"
                });
                setShow(false);
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };


    // deposit list
    useEffect(() => {
        // setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositsByDealer/${distributorInfo.userId}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    setDepositList(response?.data?.payload?.result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [paymentData, paymentUpdateId]);



    // Credit list
    useEffect(() => {
        // setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/getAllOrdersWithBankByDB/${distributorInfo.userId}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    let totalCredit = 0;
                    response?.data?.payload?.result?.forEach((item) => {
                        if (item?.status === 2 && item.crBDT) {
                            totalCredit += item.crBDT;

                        }
                    }
                    )
                    setTotalCredit(parseFloat(totalCredit));
                    setCreditList(response?.data?.payload?.result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [paymentData, paymentUpdateId]);



    const handleSubmit = async () => {
        // Calculate total deposit
        let totalDeposit = calculateTotalDeposit(depositList, creditList);
        let totalDrBDT = 0;
        let totalCrBDT = 0;
        // Check if total deposit is greater than total payable amount
        if (totalDeposit > totalPayableAmount) {
            totalDrBDT = totalPayableAmount;
            totalCrBDT = 0;
        }
        // Check if total deposit is equal to total payable amount
        else if (totalDeposit === 0) {
            totalDrBDT = 0;
            totalCrBDT = totalPayableAmount;
        }
        // Check if total deposit is less than total payable amount
        else if (totalDeposit < totalPayableAmount) {
            totalDrBDT = totalDeposit;
            totalCrBDT = totalPayableAmount - totalDeposit;
        }


        try {
            const orderItems = checkedProducts?.map((product) => ({
                _id: product.productId,
                totalPrice: Math.round(product?.totalPrice),
                deliveryQtyCTN: product?.orderQuantity,
                deliveryQtyPcs: product?.totalOrderQtyPCS,
                discountBDT: product?.productDiscount?.length > 0 ? product?.productDiscount[0]?.discountBDT : 0,
                status: 2
            }));

            const orderResponse = await axios.put(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`, orderItems, { headers: authHeader() });

            if (orderResponse.data.status || orderResponse.status === 200) {

                const response = await axios.post(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/create`, {
                    zone: distributorInfo.zoneName,
                    region: distributorInfo.regionName,
                    area: distributorInfo.areaName,
                    // paymentNote: values.note,
                    orderNumber: distributorOrderNumber,
                    distributorId: distributorInfo.userId,
                    companyName: distributorInfo.companyName,
                    dealerId: distributorInfo.userId,
                    dealerName: distributorInfo.distributorName,
                    dbType: distributorInfo.userRole,
                    superDBId: distributorInfo?.superDBId,
                    dbPoint: distributorInfo?.dbPoint,
                    phone: distributorInfo.phoneNumber,
                    doNo: distributorOrderNumber,
                    recordDate: new Date(),
                    // paymentMethod: "Cash",
                    drBDT: totalDrBDT,
                    crBDT: totalCrBDT,
                    status: 2,
                    paymentDate: new Date(),
                }
                    , { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    setStep(1);
                    setDistributorOrderNumber(0);
                    setCheckedProducts([]);

                    Swal.fire({
                        title: "Good job!",
                        text: "Order Successfully Done!",
                        icon: "success"
                    });

                } else {
                    throw new Error('Failed to update payment data');
                }

            } else {
                throw new Error('Failed to place order');
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };


    if (isLoading) return <LoadingIcon />


    const handleSchema = Yup.object().shape({
        BDTAmount: Yup.string().required("BDT Amount is required"),
        note: Yup.string().nullable(),

    });


    return <>
        <PageHeader
            title={`Order No: ${distributorOrderNumber}`}
            description2={
                <div>
                    <p style={{ color: "#354151", fontWeight: "500", fontFamily: 'Poppins' }}>DB ID: {distributorInfo?.userId}, Distributor Name: {distributorInfo?.name || distributorInfo?.distributorName}, Mobile No: {distributorInfo?.phoneNumber}, Address: {distributorInfo?.address}</p>
                </div>
            }
            className="mb-3"
        />
        <div className="bg-white">
            <div className="table-responsive scrollbar">
                <table className="table table-bordered table-striped fs--1 mb-0">
                    <thead >
                        <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                            <th className="align-middle white-space-nowrap text-center ">Serial</th>
                            <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                            <th className="align-middle white-space-nowrap text-center ">Order Qty CTN/Bag</th>
                            <th className="align-middle white-space-nowrap text-center ">Order Qty PCS</th>
                            <th className="align-middle white-space-nowrap text-center ">Price CTN</th>
                            <th className="align-middle white-space-nowrap text-center ">Total Price CTN/Bag</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Discount BDT</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Total Payable</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Gift Offer Note</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Action</th>
                        </tr>
                    </thead>
                    <tbody className="list" id="table-purchase-body">
                        {
                            checkedProducts?.map((product, index) => {
                                return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                    <td className="align-middle text-center">{index + 1}</td>
                                    <td className="align-middle text-center">{product?.skuName}</td>
                                    <td className="align-middle text-center">{product?.orderQuantity}</td>
                                    <td className="align-middle text-center">{product?.totalOrderQtyPCS ? product.totalOrderQtyPCS : 0}</td>
                                    <td className="align-middle text-center">{product?.perCtnPrice}</td>
                                    <td className="align-middle text-center">{product?.totalPrice}</td>
                                    {
                                        product?.productDiscount && product?.productDiscount?.length > 0 ? product?.productDiscount?.map((discount, index) => <td key={index} className="align-middle text-center">{discount?.discountBDT}</td>) : <td className="align-middle text-center"></td>
                                    }
                                    <td className="align-middle text-center">{handleTotalPayablePrice(product)}</td>

                                    {
                                        product?.productOffer && product?.productOffer.length > 0 ? product?.productOffer?.map((offer, index) => <td key={index} className="align-middle text-center">{offer?.offerNote}</td>) : <td className="align-middle text-center"></td>
                                    }
                                    <td className="align-middle text-center">
                                        <Button variant="danger" onClick={() => handleRemoveProduct(product?._id)}>Cancel</Button>
                                    </td>
                                </tr>

                            })
                        }
                        <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151", backgroundColor: "#98c1d9" }} >
                            <td className="align-middle white-space-nowrap text-center" colSpan="2" >Total</td>

                            <td className="align-middle white-space-nowrap text-center" colSpan="1">{checkedProducts?.total?.totalOrderQuantityCtn}</td>
                            <td className="align-middle white-space-nowrap text-center" colSpan="1">{checkedProducts?.total?.totalOrderQtyPcs}</td>
                            <td className="align-middle white-space-nowrap text-center" colSpan="1"></td>
                            <td className="align-middle white-space-nowrap text-center" colSpan="1">{checkedProducts?.total?.totalPriceCtn}</td>
                            <td className="align-middle white-space-nowrap text-center" colSpan="1">{checkedProducts?.total?.totalDiscount}</td>
                            <td className="align-middle white-space-nowrap text-center" colSpan="1">{Math.round(checkedProducts?.total?.totalPayable)}</td>
                            <td></td>
                            <td></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>

        <Card className="mb-3 mb-lg-0 mt-3">
            <Card.Header as="h6" className="bg-light">
                <Flex justifyContent="between" alignItems="center">
                    <h5 >Your available balance: <span className="bg-success">{calculateTotalDeposit(depositList, creditList) | 0} BDT</span></h5>
                    <br />

                    <h5 >Available Credit Limit: <span className="bg-success">{parseFloat(distributorInfo.crBDTLimit) - parseFloat(totalCredit) | 0} BDT</span></h5>
                    {/* <Button className="mt-3" variant="primary" onClick={handleShow}>Add Payment</Button> */}
                </Flex>
            </Card.Header>
            {
                paymentData?.length > 0 && <Card.Body>
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead>
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center">Serial No.</th>
                                        <th className="align-middle white-space-nowrap text-center" colSpan="3">BDT Amount</th>
                                        <th className="align-middle white-space-nowrap text-center" colSpan="auto">Note</th>
                                        <th className="align-middle white-space-nowrap text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentData?.map((data, index) => (
                                        <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                            <td className="align-middle white-space-nowrap text-center">{index + 1}</td>
                                            <td className="align-middle white-space-nowrap text-center" colSpan="3">{data?.drBDT}</td>
                                            <td className="align-middle white-space-nowrap text-center" colSpan="auto">{data?.paymentNote}</td>
                                            <Dropdown className="d-inline mx-2">
                                                <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">

                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="">


                                                        <IconButton
                                                            variant="falcon-default"
                                                            size="sm"
                                                            icon="edit"
                                                            transform="shrink-2"
                                                            iconAlign="middle"
                                                            className="me-2"
                                                            onClick={() => handleShowUpdateModal(data?._id)}
                                                        >
                                                            <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                                        </IconButton>

                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="">
                                                        <IconButton
                                                            onClick={() => handleDelete(data?._id)}
                                                            variant="falcon-default"
                                                            size="sm"
                                                            icon="trash-alt"
                                                            iconAlign="middle"
                                                            className="d-none d-sm-block me-2"
                                                        // disabled={true}
                                                        >
                                                            <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                                        </IconButton>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </tr>


                                    ))}

                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <td className="align-middle white-space-nowrap text-center" colSpan="1">Total Pay</td>

                                        <td className="align-middle white-space-nowrap text-center" colSpan="1">{totalPayAmount}</td>

                                    </tr>
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <td className="align-middle white-space-nowrap text-center" colSpan="1">Due Amount</td>

                                        <td className="align-middle white-space-nowrap text-center" colSpan="1">{totalDueAmount}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>}


        </Card>
        <div>
            <Button className="mt-3 me-3 px-5" variant="primary" type="submit" disabled={(calculateTotalDeposit(depositList, creditList) + parseFloat(distributorInfo.crBDTLimit) - parseFloat(totalCredit)) < totalPayableAmount} onClick={() => { handleSubmit(); }}>
                Submit
            </Button>
            <Button className="mt-3 px-5" variant="primary" onClick={handleBack} >
                Back
            </Button>
            <br />
            {(calculateTotalDeposit(depositList, creditList) + parseFloat(distributorInfo.crBDTLimit) - parseFloat(totalCredit)) < totalPayableAmount && <span style={{ color: "red" }}>Insufficient Balance !</span>}
        </div>

        <Modal show={show} size='lg' onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Payment</Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={() => setShow(false)}
                />
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        BDTAmount: paymentGetById?.drBDT || totalDueAmount,
                        note: paymentGetById?.paymentNote || "",


                    }}
                    validationSchema={handleSchema}
                    onSubmit={handlePaymentSubmit}
                    enableReinitialize={true}
                >
                    {({
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        setFieldValue

                    }) => {

                        return (
                            <Form onSubmit={handleSubmit} className="text-black">
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="exampleState">
                                        <Form.Label>BDT Amount</Form.Label>
                                        <Form.Control
                                            name="BDTAmount"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                            placeholder="Enter BDT Amount"
                                            required
                                            value={values.BDTAmount}
                                        />
                                        {touched.BDTAmount && errors.BDTAmount && (
                                            <div style={{ color: "red" }}>
                                                {errors.BDTAmount}
                                            </div>
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="exampleState">
                                        <Form.Label>Note</Form.Label>
                                        <Form.Control
                                            name="note"
                                            as={"textarea"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                            placeholder="Enter Note"

                                            value={values.note}
                                        />
                                        {touched.note && errors.note && (
                                            <div style={{ color: "red" }}>
                                                {errors.note}
                                            </div>
                                        )}
                                    </Form.Group>
                                </Row>
                                <IconButton
                                    variant="primary"
                                    className="me-2"
                                    type="submit"
                                >
                                    Submit
                                </IconButton>
                                <Button
                                    className="px-5"
                                    onClick={() => {
                                        setShow(false);
                                    }}
                                    variant="danger"
                                    type="button"
                                >
                                    Cancel
                                </Button>


                            </Form>
                        );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    </>
}
export default SuperDistributorOrderCreateStep3;