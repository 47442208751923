import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import axios from 'axios'
import IconButton from 'components/common/IconButton'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table, Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleBarReact from 'simplebar-react'
import { authHeader } from 'utils'
import { usePDF } from 'react-to-pdf'
import StatusCell from 'components/common/StatusCell'
import useUserRole from 'hooks/useUserRole'
import { formatNumber } from 'helpers/utils'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import LoadingIcon from 'helpers/LoadingIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from "react";
import debounce from "lodash/debounce";


const CollapseOrder = ({ data, handleSelectCollapseAll, handleSelectCollapseSingle }) => {
    
    const details = data?.trackLoading;

    // Date Format Function
    const DateFormat = (date) => {
        const originalDateString = date
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${day}-${month}-${year}`

        return formattedDateString
    }

    return (
        <div style={{ maxWidth: '100%' }}>
            <Table striped className="border-bottom">
                <thead className="light">
                    <tr className="bg-primary text-white dark__bg-1000">
                        <th className="white-space-nowrap text-center ">
                            <input
                                type="checkbox"
                                onChange={(e) =>
                                    handleSelectCollapseAll(e, data)
                                }
                                checked={details?.every(order => order.isChecked)}
                            />
                        </th>
                        <th>No.</th>
                        <th>DO No</th>
                        <th>SkuName</th>
                        <th className="text-start">Del Qty Ctn</th>
                        <th className="text-start">Del Qty Pcs</th>
                        <th className="text-start">Offer SKu</th>
                        <th className="text-start">Offer Qty</th>
                        <th className="text-start">Offer UOM</th>
                        <th className="text-start">Order Date</th>
                        <th className="text-start">Delivery Start Date</th>
                        <th className="text-start">Delivery End Date</th>

                    </tr>
                </thead>
                <tbody>
                    {details?.map((order, index) => {
                        return (
                            <tr className="border-top border-200" key={index}>
                                <td className="align-middle text-center">
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            handleSelectCollapseSingle(e, data, order._id)
                                        }
                                        checked={order.isChecked}

                                    />
                                </td>
                                <td className="align-middle text-start fw-semi-bold">{index + 1}</td>
                                <td className="align-middle text-start fw-semi-bold">{order.doNo}</td>
                                <td className="align-middle text-start fw-semi-bold">{`${order.skuName} (${order.erpId ? order.erpId : ""})`}</td>
                                <td className="align-middle text-start fw-semi-bold">{formatNumber(order.delQtyCtn) ||"--"}</td>
                                <td className="align-middle text-start fw-semi-bold">{formatNumber(order.delQtyPcs) || "--"}</td>
                                <td className="align-middle text-start fw-semi-bold">{order?.offerSkuName || "--"}</td>
                                <td className="align-middle text-start fw-semi-bold">{formatNumber(order.offerQtyPCS) || "--"}</td>
                                <td className="align-middle text-start fw-semi-bold">{order?.offerUnit || "--"}</td>
                                <td className="align-middle text-start fw-semi-bold">{DateFormat(order.orderDate)}</td>
                                <td className="align-middle text-start fw-semi-bold">{DateFormat(order.deliveryDate)}</td>
                                <td className="align-middle text-start fw-semi-bold">{DateFormat(order.deliveryDateEnd)}</td>

                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}

export default CollapseOrder;
