import { toast } from "react-toastify";

const handleApiResponseErrors = (response) => {
    const apiResponse = response?.data;

    if (apiResponse?.status === false || apiResponse?.success === false) {
        toast.error(apiResponse.message);
        // Error response, handle the error
        console.error(`Error: ${apiResponse.message}`);

        // Check for data message
        if (apiResponse.data && apiResponse.data.message) {
            toast.error(apiResponse.data.message);
            console.error(`Data message: ${apiResponse.data.message}`);
        }

        // Check for specific field errors
        if (apiResponse.errors && typeof apiResponse.errors === 'object') {
            Object.keys(apiResponse.errors).forEach(field => {
                const fieldError = apiResponse.errors[field];
                console.error(`Field ${field}: ${fieldError.message}`);
                // Show a toast for each specific field error
                toast.error(`${fieldError.message}`);
            });
        } else {
            // Handle other error messages
            console.error(`Error message: ${apiResponse.message}`);
        }
    }
}

export default handleApiResponseErrors;
