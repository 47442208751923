import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import BasicECharts from 'components/common/BasicEChart';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { set } from 'date-fns';
import { BarChart } from 'echarts/charts';
import {
    GridComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import handleApiResponseErrors from 'helpers/handleApiResponse';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import image from 'assets/img/icons/spot-illustrations/corner-1.png';
import Background from 'components/common/Background';
import { Link } from 'react-router-dom';
import LoadingIcon from 'helpers/LoadingIcon';
import axios from 'axios';
import { authHeader } from 'utils';
import { use } from 'echarts';
import { useSelector } from 'react-redux';

const InCompleteOrders = ({ setIsLoading }) => {
    const [doNo, setDoNo] = useState(0);
    const [inCompleteOrdersCount, setInCompleteOrdersCount] = useState(0);
    const distributorInfo = useSelector(state => state.auth.user.data);
    const navigate = useNavigate();


    // fetch orders data from API
    const organizeOrdersByDoNo = (orders) => {
        const ordersByDoNo = {};

        orders?.filter(order => order?.status === 0)?.forEach((order) => {
            const { doNo, dbPoint, region, zone, area, dealerId, distributorName, orderQtyCtn, totalOrderQtyPCS, totalPrice, discountBDT, status } = order;
            if (!ordersByDoNo[doNo]) {
                ordersByDoNo[doNo] = {
                    doNo: doNo,
                    dbPoint: dbPoint,
                    region: region,
                    zone: zone,
                    area: area,
                    dealerId: dealerId,
                    distributorName: distributorName,
                    status: status,

                };
            }
        });

        const result = Object.values(ordersByDoNo);

        return result;
    };



    // fetch orders data from API
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    // `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders?dealerId=${distributorInfo?.userId}`,
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/OrdersZeroStatus/${distributorInfo?.userId}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.result;
                    const ordersByDoNo = organizeOrdersByDoNo(result);
                    setInCompleteOrdersCount(ordersByDoNo?.length);
                    setDoNo(ordersByDoNo?.length > 0 ? ordersByDoNo[0]?.doNo : 0);
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [distributorInfo?.userId]);

    if (inCompleteOrdersCount==0) {
        return null;
    }

    return (
        <Card className='overflow-hidden'  >
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="warning" pill className="">
                    Trash 
                </SoftBadge>
                <h5>

                    Incomplete Orders

                </h5>
                <div
                    className=
                    'display-4 fs-4 mb-2 fw-normal font-sans-serif'

                >
                    {inCompleteOrdersCount || 0}
                </div>
                <Link to={`/master/distributor-orders-create`} className="fw-semi-bold fs--1 text-nowrap">
                    See Details
                    <FontAwesomeIcon
                        icon="angle-right"
                        className="ms-1"
                        transform="down-1"
                    />
                </Link>
            </Card.Body>
        </Card>
    );
};


export default InCompleteOrders;
