import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import { Card, Dropdown } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import useUserRole from 'hooks/useUserRole'
import { formatNumber } from 'helpers/utils';

const ProductionPendingList = () => {
    const [recivedDate, setRecivedDate] = useState([])
    const [receivedList, setReceivedList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)
    const [pageDataCount, setPageDataCount] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [showUploadCSV, setShowUploadCSV] = useState(false)
    const [count, setCount] = useState(0)
    const userInfo = useSelector((state) => state.auth.user?.data)
    const userRole = useUserRole()

    //
    /*     useEffect(() => {
            // setIsLoading(true);
            const startIndex = (paginationPageCount - 1) * pageDataCount + 1;
    
            const fetchData = async () => {
                let url;
                if (userInfo?.userRole === "Admin") {
                    url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                } if (userInfo?.userRole === "Inventory") {
                    url = searchText !== "" ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                }
    
                try {
                    const response = await axios.get(
                        url,
                        { headers: authHeader() }
                    );
    
                    if (response.data.success || response.status === 200) {
                        const totalPages = response?.data?.payload?.pagination?.totalPages;
                        const result = response?.data?.payload?.result?.map((element, index) => ({
                            ...element,
                            index: startIndex + index
                        }));
    
                        setTotalPages(totalPages)
                        setReceivedList(result);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };
    
            fetchData();
        }, [count, paginationPageCount, pageDataCount, searchText]); */

    // Load Received List Data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductionRcvListByRcvNo/${userInfo?.factoryName}`
                const response = await axios.get(url, { headers: authHeader() })
                if (response.data.success || response.status === 200) {
                    const responseData = response.data.payload.prdInfo
                    const result = responseData.map((element, index) => ({
                        ...element,
                        index: index + 1,
                    }))
                    setReceivedList(result)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, [])

    // DELETE DISTRIBUTOR

    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4c8118',
            cancelButtonColor: '#d33',
            confirmButtonText: 'delete',
        })

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${id}`
                const response = await axios.delete(url, {
                    headers: authHeader(),
                })

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Your file has been deleted.',
                        icon: 'success',
                    })

                    setCount((prevCount) => prevCount + 1)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }
    }

    if (isLoading) {
        return <LoadingIcon />
    }

    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvNo',
            Header: 'Received No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">

                            {' '}
                            <h5 className="mb-0 fs--1 ">{rcvNo}</h5>


                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'batchNo',
            Header: 'Batch No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { batchNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{batchNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'productionDate',
            Header: 'Production Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { productionDate } = rowData.row.original
                const date = new Date(productionDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">

                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>

                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyCTN',
            Header: 'Rcv Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyCTN } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{rcvQtyCTN}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyPCS',
            Header: 'Rcv Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyPCS } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{rcvQtyPCS}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyMt',
            Header: 'Weight(MT)',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyMt } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyMt)}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'factoryName',
            Header: 'Factory Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { factoryName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{factoryName}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'expireDate',
            Header: 'Expire Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { expireDate } = rowData.row.original
                const date = new Date(expireDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'action',
            Header: 'Action',
            cellProps: { className: 'py-2' },
            Cell: ({
                row: {
                    original: { rcvNo },
                },
            }) => (
                <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle
                        id="dropdown-autoclose-true"
                        className=" bg-none"
                    >
                        ...
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <Link
                                to={`/shipment/details/${rcvNo}`}
                            >-
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="edit"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">
                                        Details
                                    </span>
                                </IconButton>
                            </Link>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            ),
        },
    ]

    /*******************
    Columns End.
    *******************/

    const formatData = () => {
        const dataFormat = receivedList.map((item, i) => {
            const DateFormatted = new Date(item.productionDate)
                .toISOString()
                .split('T')[0]
            const ExpireDate = new Date(item.expireDate)
                .toISOString()
                .split('T')[0]
            return {
                SL: i + 1,
                'Batch No': item.batchNo,
                'Production Date': DateFormatted,
                'Rcv Qty Ctn': item.rcvQtyCTN,
                'Rcv Qty Pcs': item.rcvQtyPCS,
                'Rcv Qty Mt': item.rcvQtyMt,
                'Factory Name': item.factoryName,
                'Expire Date': ExpireDate,
            }
        })
        return dataFormat
    }

    return (
        <>
            <PageHeader
                title="Pending List"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={receivedList}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            isSearch={true}
                            setSearchText={setSearchText}
                            table
                            excelFileName="Pending Received List"
                            excelData={formatData()}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination
                            table
                            paginationPageCount={paginationPageCount}
                            setPaginationPageCount={setPaginationPageCount}
                            pageDataCount={pageDataCount}
                            setPageDataCount={setPageDataCount}
                            totalPages={totalPages}

                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}

export default ProductionPendingList;
