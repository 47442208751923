import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GreetingsDate from "components/dashboards/crm/greetings/GreetingsDate";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const TransferReceivedPanelConfirm = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [productsData, setProductsData] = useState([]);

    const distributorInfo = useSelector(state => state?.auth?.user?.data);

    const { factory, date, status, InOut } = useParams();

    const formatDecimal = (value) => {
        return parseFloat(value).toFixed(2);
    };

    //back to previopus page
    const handleSubmit=()=>{
        window.history.back();
    }

    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            if (!factory && !date) return;
            setIsLoading(true);
            try {

                let url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductionRcvListDetailsTransOutGoingPending/${factory}/${date}`
                if (status == 2 && InOut === "Out") {
                    url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductionRcvListDetailsConfirmTransOutgoing/${factory}/${date}`;
                } else if (status == 2 && InOut === "In") {
                    url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductionRcvListDetailsConfirmTrans/${factory}/${date}`;
                }
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const responseData = response.data.payload.prdInfoDetails;

                    setProductsData(responseData.map((product) => ({ ...product, ctn: product.transQtyCTN, pcs: product.transQtyPCS, weightMT: formatDecimal(product.transQtyMt), tranStatus: 2 })));
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [factory, date]);

    if (isLoading) {
        return <LoadingIcon />;
    }

    console.log("productsData", productsData);



    return (
        <>
            <PageHeader
                title="Transfer Details"
                className="mb-3"
            ></PageHeader>

            <Card className="mb-3">
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>

                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        productsData?.map((product, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.productCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">
                                                    {product?.ctn}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {product?.pcs}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {product?.weightMT}
                                                </td>

                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>

            <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleSubmit(); }}>
                Back
            </Button>

        </>
    );

};

export default TransferReceivedPanelConfirm;
