import SoftBadge from 'components/common/SoftBadge'
import React from 'react'
import { Card} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import image from 'assets/img/icons/spot-illustrations/corner-1.png'
import Background from 'components/common/Background'
import { formatNumber } from 'helpers/utils'
const MonthlyConfirmTk = ({ data, selectedFromDate, selectedToDate }) => {

    // Formtting the date
    const selectedDate = new Date();
    const month = selectedDate?.toLocaleString('default', { month: 'long' });
    const year = selectedDate?.getFullYear();

    let formattedDate = `${month} ${year}`;

    if (selectedFromDate && selectedToDate) {
        const fromDate = new Date(selectedFromDate);
        const toDate = new Date(selectedToDate);
        const fromDay = fromDate?.getDate();
        const fromMonth = fromDate?.toLocaleString('default', { month: 'long' });
        const fromYear = fromDate?.getFullYear();
        const toDay = toDate?.getDate();
        const toMonth = toDate?.toLocaleString('default', { month: 'long' });
        const toYear = toDate?.getFullYear();

        formattedDate = `${fromDay} ${fromMonth} ${fromYear} - ${toDay} ${toMonth} ${toYear}`;
    }

    const navigate = useNavigate()

    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="warning" pill className="">
                    Monthly
                </SoftBadge>
                <h5 style={{ fontSize: '1rem' }}>Confirm DO</h5>
                <div className="display-4 fs-1 mb-2 fw-normal font-sans-serif">
                    Tk.{formatNumber(data?.confirmDoAmount) || 0}
                </div>
                <span className="fw-semi-bold fs--1 text-nowrap">{formattedDate}</span>
            </Card.Body>
        </Card>
    )
}

export default MonthlyConfirmTk
