import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Modal, Table } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import useUserRole from "hooks/useUserRole";
import { useDropzone } from "react-dropzone";
import FalconCloseButton from "components/common/FalconCloseButton";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { authHeaderForm } from "state/ducs/auth/utils";
import { formatNumber } from "helpers/utils";
import SimpleBarReact from 'simplebar-react'



const ShowDetails = ({ data }) => {
    return <div style={{ maxWidth: "100%" }}>
        <div className="fs--1">
            <SimpleBarReact>
                <Table striped className="border-bottom">
                    <thead className="light">
                        <tr className="bg-primary text-white dark__bg-1000">

                            <th className="border-0 text-center">
                                No.
                            </th>
                            <th className="border-0 text-center">
                                SKU Name
                            </th>
                            <th className="border-0 text-center">
                                Product Category
                            </th>
                            <th className="border-0 text-center">
                                Sub Category
                            </th>
                            <th className="border-0 text-center">
                                Rcv Qty CTN
                            </th>
                            <th className="border-0 text-center">
                                Rcv Qty PCS
                            </th>
                            <th className="border-0 text-center">
                                Rcv Qty Mt
                            </th>

                            <th className="border-0 text-center">
                                Status
                            </th>
                            <th className="border-0 text-center">
                                Note
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => {
                            return (
                                <tr key={index}>

                                    <td className="align-middle text-center">
                                        {index + 1}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item?.skuName}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item?.productCategory}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item?.subCategory}
                                    </td>
                                    <td className="align-middle text-center">
                                        {formatNumber(item?.rcvQtyCTN)}
                                    </td>
                                    <td className="align-middle text-center">
                                        {formatNumber(item?.rcvQtyPCS)}
                                    </td>
                                    <td className="align-middle text-center">
                                        {formatNumber(item?.rcvQtyMt)}
                                    </td>

                                    <td className="align-middle text-center">
                                        {item?.status === 0 && <span className="badge bg-soft-warning text-warning">Pending</span> || item?.status === 1 && <span className="badge bg-soft-success text-success">Received</span>}
                                    </td>
                                    <td className="align-middle text-center">
                                        {item?.note}
                                    </td>


                                </tr>
                            )
                        })}

                    </tbody>
                </Table>
            </SimpleBarReact>
        </div>
    </div>
}

const ProductionConfirmList = () => {
    const [recivedDate, setRecivedDate] = useState([]);
    const [receivedList, setReceivedList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [expand, setExpand] = useState(false);
    const [detialsData, setDetialsData] = useState([]);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [showUploadCSV, setShowUploadCSV] = useState(false);
    const [count, setCount] = useState(0);
    const userInfo = useSelector((state) => state.auth.user?.data);
    const userRole = useUserRole();


    // 
    /*     useEffect(() => {
            // setIsLoading(true);
            const startIndex = (paginationPageCount - 1) * pageDataCount + 1;
    
            const fetchData = async () => {
                let url;
                if (userInfo?.userRole === "Admin") {
                    url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                } if (userInfo?.userRole === "Inventory") {
                    url = searchText !== "" ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                }
    
                try {
                    const response = await axios.get(
                        url,
                        { headers: authHeader() }
                    );
    
                    if (response.data.success || response.status === 200) {
                        const totalPages = response?.data?.payload?.pagination?.totalPages;
                        const result = response?.data?.payload?.result?.map((element, index) => ({
                            ...element,
                            index: startIndex + index
                        }));
    
                        setTotalPages(totalPages)
                        setReceivedList(result);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };
    
            fetchData();
        }, [count, paginationPageCount, pageDataCount, searchText]); */


    // Load Received List Data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductionRcvListConfirm/${userInfo?.factoryName}`;
                const response = await axios.get(url, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const responseData = response.data.payload.prdInfo;
                    const result = responseData.map((element, index) => ({
                        ...element,
                        index: index + 1
                    }));
                    setReceivedList(result);
                }

            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }
        fetchData();
    }, [])

    // DELETE DISTRIBUTOR

    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const expandHandler = (ItemData) => {
        const getData = receivedList;
        getData?.forEach((element) => {
            element.open = false
        })
        const index = getData.findIndex((item) => item.batchNo === ItemData.batchNo)
        getData[index].open = true
        setDetialsData(ItemData?.productionRcv);
        setReceivedList(getData)
        setExpand(!expand)
    }




    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "SL",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "batchNo",
            Header: "Batch No",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { batchNo } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{batchNo}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "rcvDate",
            Header: "Received Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { rcvDate, productionDate } = rowData.row.original;
                const date = new Date(rcvDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <Link to={`/inventory/receivedPanel/${userInfo?.factoryName}/${productionDate}`}> <h5 className="mb-0 fs--1 ">{formattedDate}</h5></Link>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "productionDate",
            Header: "Production Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { productionDate } = rowData.row.original;
                const date = new Date(productionDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                            {/* <Link to={`/inventory/receivedPanel/${userInfo?.factoryName}/${productionDate}`}>
                            </Link> */}
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "expireDate",
            Header: "Expire Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { expireDate } = rowData.row.original;
                const date = new Date(expireDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "rcvQtyCTN",
            Header: "Rcv Qty CTN",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { rcvQtyCTN } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyCTN)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "rcvQtyPCS",
            Header: "Rcv Qty PCS",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { rcvQtyPCS } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyPCS)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "rcvQtyMt",
            Header: "Weight(MT)",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { rcvQtyMt } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyMt)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "factoryName",
            Header: "Factory Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { factoryName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{factoryName}</h5>
                        </div>
                    </Flex>

                );
            },
        },


    ];

    /*******************
    Columns End.
    *******************/


    return (
        <>
            <PageHeader
                title="Production Recived Confirm List"
                className="mb-3"
            ></PageHeader>

            <Card className="mb-3" style={{ maxWidth: "100%" }}>
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader
                            isNew={false}
                            isExport={false}
                            isSearch={true}
                            setSearchText={setSearchText}
                            table
                        />
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white" style={{ maxWidth: "100%" }}>
                        <div className="table-responsive scrollbar">
                            {receivedList?.length > 0 ? (
                                <table className="table table-bordered table-striped fs--1 mb-0">
                                    <thead>
                                        <tr
                                            style={{
                                                fontSize: 'medium',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#354151',
                                            }}
                                        >
                                            <th className="align-middle white-space-nowrap text-center ">
                                                ...
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Batch No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Rcv Date
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Production Date
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Expire Date
                                            </th>

                                            <th className="align-middle white-space-nowrap text-center ">
                                                Rcv Qty CTN
                                            </th>

                                            <th className="align-middle white-space-nowrap text-center ">
                                                Rcv Qty PCS
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Rcv Qty Mt
                                            </th>



                                        </tr>
                                    </thead>
                                    <tbody
                                        className="list"
                                        id="table-purchase-body"
                                    >
                                        {receivedList?.map(
                                            (item, index) => {
                                                return (
                                                    <>
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                fontSize:
                                                                    'medium',
                                                                fontWeight:
                                                                    '500',
                                                                fontFamily:
                                                                    'Poppins',
                                                                color: '#354151',
                                                            }}
                                                        >
                                                            <td className="align-middle text-center border-0">
                                                                <Button
                                                                    onClick={() =>
                                                                        expandHandler(
                                                                            item
                                                                        )
                                                                    }
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={
                                                                        item.open
                                                                    }
                                                                    variant="falcon-primary"
                                                                    className="custom-button" // Apply the custom class
                                                                >
                                                                    {item.open &&
                                                                        expand
                                                                        ? '-'
                                                                        : '+'}
                                                                </Button>
                                                            </td>

                                                            <td className="align-middle text-center">
                                                                {item?.batchNo}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {new Date(item?.rcvDate).toLocaleDateString()}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {new Date(item?.productionDate).toLocaleDateString()}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {new Date(item?.expireDate).toLocaleDateString()}
                                                            </td>


                                                            <td className="align-middle text-center">
                                                                {formatNumber(item?.rcvQtyCTN)}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {formatNumber(item?.rcvQtyPCS)}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {formatNumber(item?.rcvQtyMt)}
                                                            </td>


                                                        </tr>
                                                        <tr
                                                            style={{
                                                                display:
                                                                    item.open &&
                                                                        expand
                                                                        ? 'table-row'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            <td colSpan={15}>
                                                                <div className="p-0">
                                                                    <ShowDetails data={item?.productionRcv} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center p-4">
                                    <h5>No Orders Found</h5>
                                </div>
                            )}
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        table
                        paginationPageCount={paginationPageCount}
                        setPaginationPageCount={setPaginationPageCount}
                        pageDataCount={pageDataCount}
                        setPageDataCount={setPageDataCount}
                        totalPages={totalPages}
                    />
                </Card.Footer>
            </Card>
        </>
    );

};

export default ProductionConfirmList;
