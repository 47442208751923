import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Modal, Row, Table } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import useUserRole from 'hooks/useUserRole'
import { useDropzone } from 'react-dropzone'
import FalconCloseButton from 'components/common/FalconCloseButton'
import cloudUpload from 'assets/img/icons/cloud-upload.svg'
import { authHeaderForm } from 'state/ducs/auth/utils'
import { formatNumber } from 'helpers/utils'
import SimpleBarReact from 'simplebar-react'

const StockList = () => {
    const [receivedList, setReceivedList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)
    const [pageDataCount, setPageDataCount] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [showUploadCSV, setShowUploadCSV] = useState(false)
    const [count, setCount] = useState(0)
    const userInfo = useSelector((state) => state.auth.user?.data)
    const userRole = useUserRole()

    //
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1

        const fetchData = async () => {
            let url
            let url2
            let url3
            if (userInfo?.userRole === 'Admin') {
                // url = `${"process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL"}productsRcv?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
                url2 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`
                url3 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${userInfo.factoryName}`
            }
            if (userInfo?.userRole === 'Inventory') {
                // url = searchText !== "" ? `${"process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL"}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${"process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL"}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                url =
                    searchText !== ''
                        ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
                        : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
                url2 =
                    searchText !== ''
                        ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`
                        : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`

                url3 =
                    searchText !== ''
                        ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${userInfo.factoryName}`
                        : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${userInfo.factoryName}`
            }

            try {
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const totalPages =
                        response?.data?.payload?.pagination?.totalPages
                    const result = response?.data?.payload?.result?.map(
                        (element, index) => ({
                            ...element,
                            index: startIndex + index,
                        })
                    )

                    setTotalPages(totalPages)
                    setReceivedList(result)

                    ///////////////////////////
                    const response2 = await axios.get(url2, {
                        headers: authHeader(),
                    })

                    if (response2.data.success || response2.status === 200) {
                        const totalPages2 =
                            response2?.data?.payload?.pagination?.totalPages
                        const result2 = response2?.data?.payload?.result?.map(
                            (element, index) => ({
                                ...element,
                                index: startIndex + index,
                            })
                        )

                        const modifiedResult = result.map((item) => ({
                            ...item,
                            delivery: result2?.find(
                                (my) => my.skuName == item.skuName
                            ),
                        }))

                        setTotalPages(totalPages)
                        console.log('modifiedResult', modifiedResult)
                        setReceivedList(modifiedResult)
                    }
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [count, paginationPageCount, pageDataCount, searchText])

    console.log('receivedList', receivedList)

    if (isLoading) {
        return <LoadingIcon />
    }

    const formatData = () => {
        const dataFormat = receivedList.map((item, i) => {
            return {
                SL: i + 1,
                'Product Category': item.productCategory,
                'Product Name': item.skuName,
                'Erp Id': item.erpId,
                'Rcv Qty CTN': item.rcvQtyCTN,
                'Rcv Qty PCS': item.rcvQtyPCS,
                'Rcv Qty Mt': item.rcvQtyMt,
                'Del Qty Ctn': item.delivery?.delQtyCtn || 0,
                'Del Qty Pcs': item.delivery?.delQtyPcs || 0,
                'Del Qty Mt': item.delivery?.delQtyMt || 0,
                'Balance Qty Ctn':
                    item.rcvQtyCTN - (item.delivery?.delQtyCtn || 0),
                'Balance Qty PCS':
                    item.rcvQtyPCS - (item.delivery?.delQtyPcs || 0),
                'Balance Qty Mt':
                    item.rcvQtyMt - (item.delivery?.delQtyMt || 0),
            }
        })
        return dataFormat
    }

    return (
        <>
            <PageHeader
                title="General Stock List"
                className="mb-3"
            ></PageHeader>

            <Card>
                <Card.Body>
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            // isSearch={true}
                            // setSearchText={setSearchText}
                            table
                            excelFileName="General Stock List"
                            excelData={formatData()}
                        />
                    </Card.Header>
                    <div className="mt-4 fs--1">
                        <SimpleBarReact>
                            <Table  className="table table-bordered table-striped fs--1 mb-0">
                                <thead>
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            SL
                                        </th>

                                        <th className="align-middle white-space-nowrap text-center " >
                                            Category
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Sku Name
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            ERP ID
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Rcv Qty CTN
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Rcv Qty PCS
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Rcv Qty MT
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Del Qty CTN
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Del Qty PCS
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Del Qty MT
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Balance Qty CTN
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Balance Qty PCS
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center " >
                                            Balance Qty MT
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {receivedList.length
                                        ? receivedList?.map((order, index) => {
                                              return (
                                                  <tr key={index} style={{ fontSize: "medium", fontWeight: "400", fontFamily: 'Poppins', color: "#354151" }}>
                                                      <td className="align-middle text-center">
                                                          {index + 1}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {
                                                              order.productCategory
                                                          }
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {order.skuName}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {order.erpId}
                                                      </td>

                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyCTN
                                                          )}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyPCS
                                                          )}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyMt
                                                          )}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order?.delivery
                                                                  ?.delQtyCtn
                                                          )}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order?.delivery
                                                                  ?.delQtyPcs
                                                          )}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order?.delivery
                                                                  ?.delQtyMt
                                                          )}
                                                      </td>

                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyCTN -
                                                                  (order
                                                                      ?.delivery
                                                                      ?.delQtyCtn ||
                                                                      0)
                                                          )}
                                                      </td>

                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyPCS -
                                                                  (order
                                                                      ?.delivery
                                                                      ?.delQtyPcs ||
                                                                      0)
                                                          )}
                                                      </td>
                                                      <td className="align-middle text-center">
                                                          {formatNumber(
                                                              order.rcvQtyMt -
                                                                  (order
                                                                      ?.delivery
                                                                      ?.delQtyMt ||
                                                                      0)
                                                          )}
                                                      </td>
                                                  </tr>
                                              )
                                          })
                                        : null}

                                    <tr
                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '800',
                                            fontFamily: 'Poppins',
                                            color: 'black',
                                            backgroundColor: '#98c1d9',
                                        }}
                                    >
                                        <td
                                            colSpan={4}
                                            className="align-middle text-center"
                                        >
                                            <h5 className="mb-0 text-nowrap ">
                                                Total
                                            </h5>
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc + obj.rcvQtyCTN
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc + obj.rcvQtyPCS
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc + obj.rcvQtyMt
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj?.delivery
                                                                ?.delQtyCtn ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj?.delivery
                                                                ?.delQtyPcs ||
                                                                0)
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj?.delivery
                                                                ?.delQtyMt || 0)
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj.rcvQtyCTN -
                                                                (obj?.delivery
                                                                    ?.delQtyCtn ||
                                                                    0))
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj.rcvQtyPCS -
                                                                (obj?.delivery
                                                                    ?.delQtyPcs ||
                                                                    0))
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                        <td className="align-middle text-center">
                                            {formatNumber(
                                                receivedList?.reduce(
                                                    (acc, obj) => {
                                                        return (
                                                            acc +
                                                            (obj.rcvQtyMt -
                                                                (obj?.delivery
                                                                    ?.delQtyMt ||
                                                                    0))
                                                        )
                                                    },
                                                    0
                                                )
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </SimpleBarReact>
                    </div>

                   
                </Card.Body>
            </Card>
        </>
    )
}

export default StockList
