import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
        <Form.Control
            size="sm"
            ref={ref}
            onClick={onClick}
            value={value}
            className="ps-4"
            onChange={e => {
                console.log({ e });
            }}
        />
        <FontAwesomeIcon
            icon="calendar-alt"
            className="text-primary position-absolute top-50 translate-middle-y ms-2"
        />
    </div>
));

const GetSingleDate = ({ onDateChange }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const onChange = date => {
        setSelectedDate(date);
        onDateChange(date); // Pass the selected date to the parent component
    };

    return (
        <DatePicker
            selected={selectedDate}
            onChange={onChange}
            dateFormat="dd MM yyyy"
            customInput={<GreetingsDateInput />}
        />
    );
};

GetSingleDate.propTypes = {
    onDateChange: PropTypes.func.isRequired, // Callback function to handle date change
};

GreetingsDateInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
};

export default GetSingleDate;
