import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faClock, faCheck, faCheckCircle, faThumbsUp, faQuestion } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';

const StatusCell = ({ status }) => {
    const getStatusStyles = (status) => {
        switch (status) {
            case 1:
                return { color: 'red', text: 'Canceled', icon: faTimes };
            case 2:
                return { color: 'orange', text: 'Pending..', icon: faClock };
            case 3:
                return { color: 'black', text: 'Forwarded by ASM', icon: faCheck };
            case 4:
                return { color: '#87CEFA', text: 'Forwarded by RSM', icon: faCheck };
            case 5:
                return { color: '#BC8F8F', text: 'Forwarded by SIO', icon: faCheckCircle };
            case 6:
                return { color: 'blue', text: 'Forwarded by Order Management', icon: faThumbsUp };
            case 7:
                return { color: 'green', text: 'Approved', icon: faThumbsUp };
            default:
                return { color: 'gray', text: 'Incomplete', icon: faQuestion };
        }
    };

    const { color, text, icon } = getStatusStyles(status);

    return (
        <Flex alignItems="center" className="justify-content-center">
            <FontAwesomeIcon icon={icon} style={{ color, marginRight: '5px' }} />
            <span style={{ color, fontSize: 'medium' }}>{text}</span>
        </Flex>
    );
};


export default StatusCell;
