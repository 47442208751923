import FalconCardHeader from 'components/common/FalconCardHeader'
import PropTypes from 'prop-types'
import React from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const SharedFiles2 = ({ files, className, title }) => {
    return (
        <Card style={{ overflowX: 'auto' }} className={className}>
            <FalconCardHeader
                title={title}
                titleTag="h6"
                className="py-2"
                light
            // endEl={
            //     <div className="py-1 fs--1 font-sans-serif">
            //         Quantity
            //     </div>
            // }
            />
            <Card.Body className="pb-0">
                <Table borderless className="mb-0 fs--1">
                    <thead className="bg-light">
                        <tr className="text-900">
                            {/* <th>Schedule No</th> */}
                            <th>DO No</th>
                            <th>SKU Name</th>
                            <th>Unit</th>
                            <th>Delivery Qty</th>
                            <th className="text-start">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file, index) => (
                            <SharedFile2
                                key={file._id}
                                file={file}
                                isLast={index === files.length - 1}
                            />
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

const SharedFile2 = ({ file, isLast }) => {
    const { doNo, scheduleNo, delQtyCtn, time, status, skuName, delQtyPcs, dbType, alreadyScheduledQty } = file
    console.log('file', file)
    return (
        <>
            <tr className="border-top border-200">
                {/* <td className="align-middle text-start fw-semi-bold">
                    <Link
                        to={"/distribution/productscheduling/details/" + scheduleNo}
                        className="fw-semi-bold fs--1 text-nowrap"
                    >
                        {scheduleNo}
                    </Link>
                </td> */}
                <td className="align-middle text-start fw-semi-bold">{doNo}</td>
                <td className="align-middle text-start fw-semi-bold">
                    {skuName}
                </td>
                <td className="align-middle text-start fw-semi-bold">
                    {dbType == "SPECIAL DISTRIBUTOR" ? "PCS" : "CTN"}
                </td>
                <td className="align-middle text-start fw-semi-bold">
                    {/* {dbType == "SPECIAL DISTRIBUTOR" ? delQtyPcs : delQtyCtn} */}
                    {dbType == "SPECIAL DISTRIBUTOR" ? delQtyPcs : alreadyScheduledQty}
                    
                </td>
                <td className="align-middle text-end fw-semi-bold">
                    {/* {status === 0 ? 'Pending' : 'Delivered'} */}
                    {"Status"}
                </td>
            </tr>
        </>
    )
}

SharedFile2.propTypes = {
    file: PropTypes.shape({
        img: PropTypes.string,
        name: PropTypes.string,
        user: PropTypes.string,
        time: PropTypes.string,
        border: PropTypes.bool,
    }),
    isLast: PropTypes.bool,
}

SharedFiles2.propTypes = {
    files: PropTypes.arrayOf(SharedFile2.propTypes.file),
    className: PropTypes.string,
}

export default SharedFiles2
