import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    Col,
    Form,
    Row,
    Table,
    InputGroup,
    FormControl,
} from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import GetSingleDate from 'components/common/date/GetSingleDate'
import { useSelector } from 'react-redux'
import TableRow from './TableRow'
import FalconComponentCard from 'components/common/FalconComponentCard'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Flex from 'components/common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatNumber } from 'helpers/utils'

import { getAllOffers } from 'services/api'
import { all } from 'is_js'

const OffersScheduling = () => {
    const [selectedSku, setSelectedSKU] = useState(null)

    const [selectedOfferCategory, setSelectedOfferCategory] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    const [receivedList, setReceivedList] = useState([])

    const [submittedOffers, setSubmittedOffers] = useState([])

    const [allOffersData, setAllOffersData] = useState(null)

    const [offerSkuNames, setOfferSkuNames] = useState([])

    const [offerCategories, setOfferCategories] = useState([])

    const [selectedChoice, setSelectedChoice] = useState(null)

    const [factories, setFactories] = useState([
        { factoryName: 'SORL', OfferRcvQty: 0 },
        { factoryName: 'SVOIL', OfferRcvQty: 0 },
        { factoryName: 'Narshingdi', OfferRcvQty: 0 },
        { factoryName: 'Dhamrai', OfferRcvQty: 0 },
    ])

    const customers = [
        {
            name: 'Ricky Antony',
            avatar: 'team4',
            email: 'ricky@example.com',
        },
    ]

    function uniqueObjects(array, key) {
        let seen = new Set()
        return array.filter((item) => {
            let k = item[key]
            return seen.has(k) ? false : seen.add(k)
        })
    }

    const fetchOffersData = () => {
        setIsLoading(true)
        getAllOffers()
            .then((res) => {
                if (res.data?.success) {
                    console.log(res.data?.message)

                    // setAllOffersData(res.data.payload.offers.map(item=> ({...item, label: item.offerSkuName, value: item.offerSkuPrice})))
                    // let mySet = new Set();

                    const allOffers = res.data.payload.offers

                    // allOffers.forEach(element => {
                    //     mySet.add(JSON.stringify(element));
                    // });

                    // const SETtoArray = Array.from(mySet).map(objStr => JSON.parse(objStr)).map(item => ({...item,  label: item.offerSkuName, value: item._id }))

                    const SETtoArray = uniqueObjects(
                        allOffers,
                        'offerSkuName'
                    )?.map((item) => ({
                        ...item,
                        label: item.offerSkuName,
                        value: item._id,
                    }))

                    const SETtoArrayofferProductCategories = uniqueObjects(
                        allOffers,
                        'offerProductCategory'
                    )?.map((item) => ({
                        ...item,
                        label: item.offerProductCategory,
                        value: item._id,
                    }))

                    const sumallOffers = allOffers.reduce(
                        (accumulator, currentOffer) => {
                            // Get the offerSkuName and totalBudgetQty from the current offer
                            const { offerSkuName, totalBudgetQty } =
                                currentOffer

                            // If the offerSkuName is not in the accumulator, initialize it with 0
                            if (!accumulator[offerSkuName]) {
                                accumulator[offerSkuName] = 0
                            }

                            // Add the totalBudgetQty to the accumulator
                            accumulator[offerSkuName] += totalBudgetQty

                            return accumulator
                        },
                        {}
                    )

                    setAllOffersData(sumallOffers)

                    setOfferSkuNames(SETtoArray)

                    setOfferCategories(SETtoArrayofferProductCategories)

                    setIsLoading(false)
                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    setIsLoading(false)

                    toast.error(e.response?.data?.errors)
                }
            })
    }

    useEffect(() => {
        fetchOffersData()
    }, [])

    useEffect(() => {
        // if (selectedOfferCategory) {
        //     setOfferSkuNames([...offerSkuNames.filter(item => item.offerProductCategory == selectedOfferCategory.label)])
        // }

        console.log('selectedOfferCategory', selectedOfferCategory)
    }, [selectedOfferCategory])

    useEffect(() => {
        const existing = [...factories]

        existing.forEach((obj) => {
            obj.OfferRcvQty = 0
        })

        setFactories([...existing])
    }, [selectedSku])

    const handleCheckboxChange = (event, item, delivered) => {
        item.balance = allOffersData[selectedSku] - delivered
        setSelectedChoice(item)
    }

    const postData = async (url, data) => {
        try {
            const response = await axios.post(url, data, {
                headers: authHeader(),
            })

            console.log('Response data:', response.data)
            if (response.data.success) {
                // alert("Submitted Successfully");
                Swal.fire({
                    title: 'Success',
                    text: 'Submitted Successfully',
                    icon: 'success',
                })
                setSelectedSKU(null)
                setSelectedChoice(null)
                setFactories([
                    ...factories.map((item) => ({ ...item, OfferRcvQty: 0 })),
                ])
                setSubmittedOffers([
                    ...submittedOffers,
                    ...(response.data?.insertedRcvs || []),
                ])
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error response data:', error.response.data)
                console.error('Error response status:', error.response.status)
                console.error('Error response headers:', error.response.headers)
                alert('Error Occurred')
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request)
                alert('Error Occurred')
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message)
                alert('Error Occurred')
            }
            console.error('Error config:', error.config)
            alert('Error Occurred')
        }
    }

    const handleOfferStockSubmit = () => {
        console.log('factoriesWithUpdateQty', factories)

        const submittableFields = factories
            ?.filter((mn) => mn.OfferRcvQty)
            .map((item) => ({
                companyName: 'Happy Time',
                factoryName: item.factoryName,
                OfferRcvQty: item.OfferRcvQty,
                // offerType: selectedChoice.offerType,
                // conditionName: selectedChoice.conditionName,
                offerProductCategory: selectedOfferCategory.label,
                offerSkuName: selectedSku.label,
                deliDateToFactory: new Date(),
                // offerNote: selectedChoice.offerNote,
                // offerStartDate: selectedChoice.offerStartDate,
                // offerEndDate: selectedChoice.offerEndDate,
                // offerUnit: selectedChoice.offerUnit,
                // note: "",
                // offerRcvDate: null
            }))

        postData(
            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/createOfferReceive`,
            submittableFields
        )
    }

    const handleQuantityChange = (event, factoryName) => {
        let OfferRcvQty = event.target.value
        if (!selectedChoice) {
            return
        }
        if (OfferRcvQty > selectedChoice.balance) {
            return
        } else if (OfferRcvQty <= 0) {
            OfferRcvQty = 0
        }
        const existing = [...factories]

        existing.forEach((obj) => {
            if (obj.factoryName === factoryName) {
                obj.OfferRcvQty = +OfferRcvQty
            }
        })

        setFactories([...existing])
    }

    useEffect(() => {
        // setIsLoading(true);

        const fetchData = async () => {
            let url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/allOffersbyadm`

            try {
                const response = await axios.post(
                    url,
                    {},
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.offerRcv

                    setReceivedList(result)
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [submittedOffers.length])

    console.log('receivedList', receivedList)

    // console.log("receivedListallselectedSku", selectedSku.label);

    console.log('receivedListallOffersData', allOffersData)

    useEffect(() => {
        if (allOffersData && selectedSku) {
            let ht = {}

            ht.balance =
                allOffersData[selectedSku.label] -
                receivedList
                    ?.filter((rcv) => rcv.offerSkuName == selectedSku.label)
                    ?.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue.OfferRcvQty
                    }, 0)

            setSelectedChoice(ht)
        }
    }, [selectedSku, allOffersData]) // objects

    return (
        <>
            <PageHeader title="Offers Delivery to Factory " className="mb-3">
                <Form
                    noValidate
                    // validated={validated}
                    // onSubmit={handleSubmit}
                >
                    <Row className="mb-3 mt-3">
                        <Form.Group as={Col} md="6" controlId="exampleZip">
                            <Form.Label>Offer Product Category</Form.Label>
                            <Select
                                closeMenuOnSelect={true}
                                options={offerCategories}
                                placeholder="Select Offer Category"
                                // isMulti
                                classNamePrefix="react-select"
                                value={selectedOfferCategory}
                                onChange={(value) =>
                                    setSelectedOfferCategory(value)
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid zip.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="6" controlId="exampleZip">
                            <Form.Label>Offer SKU Name</Form.Label>
                            <Select
                                closeMenuOnSelect={true}
                                options={offerSkuNames?.filter(
                                    (item) =>
                                        item.offerProductCategory ==
                                        selectedOfferCategory?.label
                                )}
                                placeholder="Select Offer SKU Name"
                                // isMulti
                                classNamePrefix="react-select"
                                value={selectedSku}
                                onChange={(value) => setSelectedSKU(value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid zip.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Form>
            </PageHeader>

            <FalconComponentCard>
                <FalconComponentCard.Body />
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            {/* <th scope="col">Offer Name</th>
                        <th scope="col">Offer Type</th> */}
                            <th className="text-start" scope="col">
                                Budget
                            </th>

                            <th className="text-start" scope="col">
                                Factory Delivery
                            </th>

                            <th className="text-start" scope="col">
                                Balance
                            </th>

                            {/* <th className="text-start" scope="col">

                        </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {allOffersData && selectedSku && (
                            <tr>
                                <td className="py-3">
                                    <Flex alignItems="center">
                                        <h6 className="text-600 mb-0 ms-2">
                                            { formatNumber(allOffersData[selectedSku.label])}
                                        </h6>
                                    </Flex>
                                </td>

                                <td className="py-3">
                                    <Flex alignItems="center">
                                        <h6 className="text-600 mb-0 ms-2">
                                            {formatNumber(
                                                receivedList
                                                    ?.filter(
                                                        (rcv) =>
                                                            rcv.offerSkuName ===
                                                            selectedSku.label
                                                    )
                                                    ?.reduce(
                                                        (
                                                            accumulator,
                                                            currentValue
                                                        ) => {
                                                            return (
                                                                accumulator +
                                                                currentValue.OfferRcvQty
                                                            )
                                                        },
                                                        0
                                                    )
                                            )}
                                        </h6>
                                    </Flex>
                                </td>

                                <td className="py-3">
                                    <Flex alignItems="center">
                                        <h6 className="text-600 mb-0 ms-2">
                                            {formatNumber(allOffersData[selectedSku.label] -
                                                receivedList
                                                    ?.filter(
                                                        (rcv) =>
                                                            rcv.offerSkuName ==
                                                            selectedSku.label
                                                    )
                                                    ?.reduce(
                                                        (
                                                            accumulator,
                                                            currentValue
                                                        ) => {
                                                            return (
                                                                accumulator +
                                                                currentValue.OfferRcvQty
                                                            )
                                                        },
                                                        0
                                                    ))}
                                        </h6>
                                    </Flex>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </FalconComponentCard>

            <FalconComponentCard>
                <FalconComponentCard.Body />
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th scope="col">Factory Name</th>
                            <th className="text-start" scope="col">
                                Quantity
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {factories.map((factory) => (
                            <tr>
                                <td className="py-3">
                                    <Flex alignItems="center">
                                        <h6 className="text-600 mb-0 ms-2">
                                            {factory.factoryName}
                                        </h6>
                                    </Flex>
                                </td>
                                <td className="py-3">
                                    <Flex alignItems="center">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleZip"
                                        >
                                            <InputGroup>
                                                <FormControl
                                                    size="sm"
                                                    type="text"
                                                    className="shadow-none"
                                                    placeholder="Quantity"
                                                    name="OfferRcvQty"
                                                    required
                                                    value={factory.OfferRcvQty}
                                                    onChange={(event) =>
                                                        handleQuantityChange(
                                                            event,
                                                            factory.factoryName
                                                        )
                                                    }
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Flex>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Row className="flex-between-center my-2 pe-2">
                    <Col xs="auto"></Col>

                    <Col xs="auto">
                        <Button
                            onClick={handleOfferStockSubmit}
                            variant="falcon-default"
                            size="sm"
                            disabled={!selectedChoice?.balance}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </FalconComponentCard>
        </>
    )
}

export default OffersScheduling
