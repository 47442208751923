import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const DistributorProductSettingsForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [productSettingsById, distributorProductSettingsById] = useState({});
    const [distributorList, setDistributorList] = useState([])
    const { updateID } = useParams();
    const navigate = useNavigate();


    // Brand Get By ID
    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_SETTINGS_BASE_URL + `distributor-product-settings/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        distributorProductSettingsById(response.data.data);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
    }, [])


    // load Distributor 
    useEffect(() => {
        setIsLoading(true)
        const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + "distributors";
        axios.get(url, { headers: authHeader() })
            .then(response => {

                if (response.data.success || response.data.statusCode === 200) {
                    const modifyData = response?.data?.data?.map(item => {
                        return {
                            id: item.id,
                            label: item.name,
                            value: item.id

                        }
                    })

                    setDistributorList(modifyData);

                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response)
            }).finally(
                setIsLoading(false)
            )
    }, [])


    const handleFormSubmit = (values, actions) => {

        const handleSubmitValue = {
            distributor_type_id: values.distributor_type_id,
            distributor_id: values.distributor_id.value,
            product_sku: values.product_sku,
        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_SETTINGS_BASE_URL + 'distributor-product-settings';
            axios.post(url, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/distributorProductSettings")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_SETTINGS_BASE_URL + `distributor-product-settings/${updateID}`;
            axios.put(updateUrl, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/distributorProductSettings")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({
        distributor_type_id: Yup.string().required("Please enter a value for the distributor type id field"),
        distributor_id: Yup.object().required("Please select a value for the distributor field"),
        product_sku: Yup.string().required("Please enter a value for the product sku field"),
    })


    return (
        <>
            <PageHeader
                title={updateID ? "Update Distributor Product Settings" : "Add New Distributor Product Settings"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            distributor_type_id: productSettingsById.distributor_type_id || "",
                            distributor_id: distributorList.find(item => item.value === productSettingsById.distributor_id) || null,
                            product_sku: productSettingsById.product_sku || "",


                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {
                            console.log(errors)
                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Distributor Type ID</Form.Label>
                                            <Form.Control
                                                name="distributor_type_id"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Distributor Type ID"
                                                required
                                                value={values.distributor_type_id}
                                            />
                                            {touched.distributor_type_id && errors.distributor_type_id && (
                                                <div style={{ color: "red" }}>
                                                    {errors.distributor_type_id}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Distributor</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributorList}
                                                placeholder="Select Distributor"
                                                classNamePrefix="react-select"
                                                name="distributor_id"
                                                value={values.distributor_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "distributor_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.distributor_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.distributor_id}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Product sku</Form.Label>
                                            <Form.Control
                                                name="product_sku"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Product sku"
                                                required
                                                value={values.product_sku}
                                            />
                                            {touched.product_sku && errors.product_sku && (
                                                <div style={{ color: "red" }}>
                                                    {errors.product_sku}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"

                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/distributorProductSettings")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default DistributorProductSettingsForm;


