import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Row, Col, InputGroup } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import useUserData from "hooks/useUserData";
import { parse } from "date-fns";
import { formatNumber } from 'helpers/utils';
const AdminReceivedList = () => {
    const [isLoading, setIsLoading] = useState({
        general: false,
        category: false,
        subCategory: false,
        product: false
    })
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subCategorys, setSubCategorys] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [products, setProducts] = useState([]);
    const [receivedList, setReceivedList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedFactory, setSelectedFactory] = useState(null);
    const userInfo = useUserData()



    // PRODUCT CATEGORY
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(prevState => ({ ...prevState, category: true }));
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productCategory/all/prods`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.proCatData.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name
                    }));

                    setCategoryList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, category: false }));
            }
        };

        fetchData();
    }, []);


    // PRODUCT SUB CATEGORY
    useEffect(() => {
        if (selectedCategory) {
            const fetchData = async () => {
                setIsLoading(prevState => ({ ...prevState, subCategory: true }));
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}ProductSubCategory/all/prods`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const data = response?.data?.payload?.proCatData;
                        const filterData = data?.filter(item => item.productCatName === selectedCategory);
                        const modifyData = filterData?.map(data => ({
                            id: data._id,
                            label: data.productSubCatName,
                            value: data.productSubCatName
                        }));

                        setSubCategorys(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, subCategory: false }));
                }
            };
            fetchData();
        }


    }, [selectedCategory]);


    // PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(prevState => ({ ...prevState, product: true }));
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.products;
                    const filterData = data?.filter(item => item.categoryName === selectedCategory && item.subCategory === selectedSubCategory);

                    const modifyData = filterData?.map(data => ({
                        id: data.id,
                        label: data.skuName,
                        value: data.skuName
                    }));

                    setProducts(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, product: false }));
            }
        };

        fetchData();
    }, [selectedSubCategory]);

    // DELETE DISTRIBUTOR
    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "SL",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "batchNo",
            Header: "Batch No",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { batchNo } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{batchNo}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "productCategory",
            Header: "Category",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { productCategory } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{productCategory}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "skuName",
            Header: "skuName",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { skuName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{skuName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "rcvQtyCTN",
            Header: "Rcv Qty CTN",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { rcvQtyCTN } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyCTN)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "rcvQtyPCS",
            Header: "Rcv Qty PCS",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { rcvQtyPCS } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyPCS)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "rcvQtyMt",
            Header: "Weight(MT)",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { rcvQtyMt } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyMt)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "factoryName",
            Header: "Factory Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { factoryName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{factoryName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "status",
            Header: "Status",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{status === 0 && "Pending.." || status === 1 && "Recived"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "productionDate",
            Header: "Production Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { productionDate } = rowData.row.original;
                const date = new Date(productionDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "rcvDate",
            Header: "Rcv Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { rcvDate } = rowData.row.original;
                const date = new Date(rcvDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{rcvDate ? formattedDate : "-"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "expireDate",
            Header: "Expire Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { expireDate } = rowData.row.original;
                const date = new Date(expireDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            },
        }
    ];

    /*******************
    Columns End.
    *******************/

    const handleSubmitForm = async (values, actions) => {

        const formatDate = (date) => new Date(date).toLocaleDateString();
        const handleSubmitData = {
            factoryName: values.factoryName?.value,
        };
        if (values.categoryName) {
            handleSubmitData.productCategory = values.categoryName?.value;
        }
        if (values.subCategory) {
            handleSubmitData.subCategory = values.subCategory?.value;
        }
        if (values.skuName) {
            handleSubmitData.skuName = values.skuName?.value;
        }
        if (values.productionDateFrom) {
            handleSubmitData.productionDateFrom = formatDate(values.productionDateFrom);
        }
        if (values.productionDateTo) {
            handleSubmitData.productionDateTo = formatDate(values.productionDateTo);
        }
        if (values.rcvDateFrom) {
            handleSubmitData.rcvDateFrom = formatDate(values.rcvDateFrom);
        }
        if (values.rcvDateTo) {
            handleSubmitData.rcvDateTo = formatDate(values.rcvDateTo);
        }
        if (values.expireDateTo) {
            handleSubmitData.expireDateTo = formatDate(values.expireDateTo);
        }
        if (values.expireDateFrom) {
            handleSubmitData.expireDateFrom = formatDate(values.expireDateFrom);
        }
        if (selectedStatus) {
            handleSubmitData.status = parseInt(selectedStatus);
        }


        setIsLoading(preState => ({ ...preState, general: true }));

        const url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productionSearch`
        try {
            const response = await axios.post(url, handleSubmitData, { headers: authHeader() });

            if (response.status === 200 || response.data.status) {
                const data = response?.data?.payload?.productionRcv;
                const modifyData = data.map((item, index) => ({
                    ...item,
                    index: index + 1
                }));
                setReceivedList(modifyData);
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(preState => ({ ...preState, general: false }));
        }
    };


    const handleSchema = Yup.object().shape({
        factoryName: Yup.object().required("Factory Name is required"),
    });




    return (
        <>
            <FalconComponentCard>
                <FalconCardHeader title="Production List" />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            categoryName: "",
                            subCategory: "",
                            skuName: "",
                            productionDateFrom: "",
                            productionDateTo: "",
                            rcvDateFrom: "",
                            rcvDateTo: "",
                            expireDateTo: "",
                            expireDateFrom: ""


                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched,
                            resetForm

                        }) => {
                            const handleReset = () => {
                                resetForm();
                                setReceivedList([]);

                            }
                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-2">
                                        <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                            <Form.Label>Select Category</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={categoryList}
                                                placeholder="Select Category"
                                                classNamePrefix="react-select"
                                                name="categoryName"
                                                value={values.categoryName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "categoryName",
                                                        selectedOption
                                                    ), setSelectedCategory(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("categoryName", true, true) }}
                                                isLoading={isLoading.category}

                                            />

                                            {
                                                touched.categoryName && errors.categoryName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.categoryName}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                            <Form.Label>Select Sub Category</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={subCategorys}
                                                placeholder="Select Sub Category"
                                                classNamePrefix="react-select"
                                                name="subCategory"
                                                value={values.subCategory}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "subCategory",
                                                        selectedOption
                                                    ), setSelectedSubCategory(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("subCategory", true, true) }}
                                                isLoading={isLoading.subCategory}

                                            />

                                            {
                                                touched.subCategory && errors.subCategory && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.subCategory}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                            <Form.Label>Select Product</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={products}
                                                placeholder="Select Product"
                                                classNamePrefix="react-select"
                                                name="skuName"
                                                value={values.skuName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "skuName",
                                                        selectedOption
                                                    )
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("skuName", true, true) }}
                                                isLoading={isLoading.product}

                                            />

                                            {
                                                touched.skuName && errors.skuName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.skuName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-2">
                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Production From Date</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholderText="Select Production From Date"
                                                    type="date"
                                                    name="productionDateFrom"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.productionDateFrom}
                                                />
                                            </InputGroup>
                                            {touched.productionDateFrom &&
                                                errors.productionDateFrom && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.productionDateFrom}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Production To Date</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholderText="Select Production To Date"
                                                    type="date"
                                                    name="productionDateTo"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.productionDateTo}
                                                />
                                            </InputGroup>
                                            {touched.productionDateTo &&
                                                errors.productionDateTo && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.productionDateTo}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Received From Date</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholderText="Select Recived From Date"
                                                    type="date"
                                                    name="rcvDateFrom"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.rcvDateFrom}
                                                />
                                            </InputGroup>
                                            {touched.rcvDateFrom &&
                                                errors.rcvDateFrom && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.rcvDateFrom}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-2">
                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Received To Date</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholderText="Select Recived To Date"
                                                    type="date"
                                                    name="rcvDateTo"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.rcvDateTo}
                                                />
                                            </InputGroup>
                                            {touched.rcvDateTo &&
                                                errors.rcvDateTo && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.rcvDateTo}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Expire To Date</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholderText="Select Expire To Date"
                                                    type="date"
                                                    name="expireDateTo"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.expireDateTo}
                                                />
                                            </InputGroup>
                                            {touched.expireDateTo &&
                                                errors.expireDateTo && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.expireDateTo}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Expire From Date</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholderText="Select Expire From Date"
                                                    type="date"
                                                    name="expireDateFrom"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.expireDateFrom}
                                                />
                                            </InputGroup>
                                            {touched.expireDateFrom &&
                                                errors.expireDateFrom && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.expireDateFrom}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-2">
                                        <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                            <Form.Label>Select Factory</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={[
                                                    { id: 1, label: "SORL", value: "SORL" },
                                                    { id: 2, label: "SVOIL", value: "SVOIL" },
                                                    { id: 3, label: "Narshingdi", value: "Narshingdi" },
                                                    { id: 4, label: "Dhamrai", value: "Dhamrai" }
                                                ]}
                                                placeholder="Select Factory"
                                                classNamePrefix="react-select"
                                                name="factoryName"
                                                value={values.factoryName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "factoryName",
                                                        selectedOption
                                                    ), setSelectedFactory(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("factoryName", true, true) }}
                                                required


                                            />
                                            {
                                                touched.factoryName && errors.factoryName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.factoryName}
                                                    </div>
                                                )}


                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                            <Form.Label>Select Status</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={[{ label: "Pending", value: 3 }, { label: "Recived", value: 1 }, { label: "All", value: null }]}
                                                placeholder="Select Status"
                                                classNamePrefix="react-select"
                                                name="getStatus"
                                                value={values.getStatus}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "getStatus",
                                                        selectedOption
                                                    ), setSelectedStatus(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("getStatus", true, true) }}


                                            />


                                        </Form.Group>
                                    </Row>

                                    <Button className="mt-2 px-5 me-3" variant="primary" type="submit">
                                        Search
                                    </Button>
                                    <Button className="mt-2 px-5" onClick={() => handleReset()} variant="danger" type="reset">
                                        reset
                                    </Button>

                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>

            {isLoading.general ? <div className="text-center"><h6>Loading...</h6></div> : <AdvanceTableWrapper
                columns={columns}
                data={receivedList}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            isSearch={false}
                            isExport={false}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>

                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>}

        </>
    );

};

export default AdminReceivedList;
