import FalconComponentCard from 'components/common/FalconComponentCard'
import PageHeader from 'components/common/PageHeader'
import IconButton from 'components/common/IconButton'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from 'utils'
import * as Yup from 'yup'
import Select from 'react-select'
import LoadingIcon from 'helpers/LoadingIcon'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { toast } from 'react-toastify'

const BankForm = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [salesOrgList, setSalesOrgList] = useState([])
    const [bankByID, setBankById] = useState([])
    const [superDBList, setSuperDBList] = useState([])

    const { updateID } = useParams()
    const navigate = useNavigate()

    // BANK BY ID
    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true)
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}bank/${updateID}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success) {
                        setBankById(response?.data?.payload.result)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading(false)
                }
            }
        }

        fetchData()
    }, [updateID])

    // SALES ORGANIZATION
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}salesOrg`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result.map(
                        (data) => ({
                            id: data.id,
                            label: data.name,
                            value: data.name,
                        })
                    )

                    setSalesOrgList(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?userRole=SUPER DISTRIBUTOR`
                const response = await axios.get(url, { headers: authHeader() })
                if (response.data.success || response.status === 200) {
                    const modifyData =
                        response?.data?.payload?.distributors.map((data) => ({
                            id: data.userId,
                            label: data.distributorName,
                            // value: data.distributorName
                            value: data.userId,
                        }))
                    setSuperDBList(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, [])

    const bankTypes = [
        { label: 'Company', value: 0 },
        { label: 'Super Distributor', value: 1 },
    ]

    const handleSubmitForm = (values, actions) => {
        const handleSubmitData = {
            bankName: values.name,
            salesOrgName: values.sales_organization_id.value,
            bankAccountNo: values.bankAccountNo,
            status: values.status.value,
            superDBId: values.status.value ? values?.superDBId?.value : '',
            distributorName:values?.superDBId?.label,
        }

        setIsLoading(true)
        const url = updateID
            ? `${process.env.REACT_APP_SETTINGS_BASE_URL}bank/${updateID}`
            : `${process.env.REACT_APP_SETTINGS_BASE_URL}bank`

        const axiosMethod = updateID ? axios.patch : axios.post

        axiosMethod(url, handleSubmitData, { headers: authHeader() })
            .then((response) => {
                if (response.data.success) {
                    navigate('/master/bank')
                    toast.success(response.data.message)
                }
            })
            .catch((error) => {
                console.log('test', error.response)
                handleApiResponseErrors(error.response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    isLoading && <LoadingIcon />

    const validationSchema = Yup.object().shape({
        sales_organization_id: Yup.object().required(
            'Please select a value for the sales Organization field'
        ),
        name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter a value for the bank field')
            .test(
                'starts with a number',
                'Filed input should not start with a number',
                (value) => {
                    return !/^\d/.test(value)
                }
            ),
        bankAccountNo: Yup.string()
            .min(2, 'Too Short!')
            .required('Please enter a value for the bank account no field'),
    })

    return (
        <>
            <PageHeader
                title={updateID ? 'Update Bank' : 'Add Bank'}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard>
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            sales_organization_id:
                                salesOrgList.find(
                                    (item) =>
                                        item.value === bankByID?.salesOrgName
                                ) || null,
                            name: bankByID?.bankName || '',
                            bankAccountNo: bankByID?.bankAccountNo || '',
                            status: bankTypes?.find(
                                (item) => item.value == bankByID?.status
                            ) || { label: 'Company', value: 0 },
                            superDBId:
                                superDBList.find(
                                    (item) => item.value === bankByID?.superDBId
                                ) || null,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                        }) => {
                            console.log(errors)

                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Bank Type</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={bankTypes}
                                                placeholder="Select Bank Type"
                                                classNamePrefix="react-select"
                                                name="status"
                                                value={values.status}
                                                defaultValue={{
                                                    label: 'Company',
                                                    value: 0,
                                                }}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'status',
                                                        selectedOption
                                                    )
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {errors.status && (
                                                <div style={{ color: 'red' }}>
                                                    {errors.status}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select Sales Organization
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={salesOrgList}
                                                placeholder="Select Sales Organization"
                                                classNamePrefix="react-select"
                                                name="sales_organization_id"
                                                value={
                                                    values.sales_organization_id
                                                }
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'sales_organization_id',
                                                        selectedOption
                                                    )
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {errors.sales_organization_id && (
                                                <div style={{ color: 'red' }}>
                                                    {
                                                        errors.sales_organization_id
                                                    }
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleState"
                                        >
                                            <Form.Label>Bank Name</Form.Label>
                                            <Form.Control
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Bank Name"
                                                required
                                                value={values.name}
                                            />
                                            {touched.name && errors.name && (
                                                <div style={{ color: 'red' }}>
                                                    {errors.name}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleState"
                                        >
                                            <Form.Label>Account No</Form.Label>
                                            <Form.Control
                                                name="bankAccountNo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Bank Account No"
                                                required
                                                value={values.bankAccountNo}
                                            />
                                            {touched.bankAccountNo &&
                                                errors.bankAccountNo && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.bankAccountNo}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>

                                    {values.status.value ? (
                                        <Row className="mb-3">
                                            <Form.Group
                                                as={Col}
                                                md="6"
                                                controlId="exampleFirstName"
                                            >
                                                <Form.Label>
                                                    Select Super Distributor
                                                </Form.Label>
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={superDBList}
                                                    placeholder="Select Sales Organization"
                                                    classNamePrefix="react-select"
                                                    name="superDBId"
                                                    value={values.superDBId}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setFieldValue(
                                                            'superDBId',
                                                            selectedOption
                                                        )
                                                    }}
                                                    onBlur={handleBlur}
                                                />

                                                {errors.superDBId && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.superDBId}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Row>
                                    ) : null}

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? 'Save' : 'Submit'}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate('/master/bank')}
                                        variant="danger"
                                        type="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    )
}

export default BankForm
