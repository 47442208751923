import FalconComponentCard from 'components/common/FalconComponentCard'
import PageHeader from 'components/common/PageHeader'
import IconButton from 'components/common/IconButton'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from 'utils'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Select from 'react-select'
import LoadingIcon from 'helpers/LoadingIcon'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { PHONE_NUMBER_REGEX } from 'utils/validationRegex'
import useUserData from '../../../../hooks/useUserData'

const SalesRepresentativeRoutesForm = () => {
    const [selectedZone, setSelectedZone] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('')
    const [selectedArea, setSelectedArea] = useState('')
    const [selectedDbPoint, setSelectedDbPoint] = useState('')
    const [zoneList, setZoneList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [areaList, setAreaList] = useState([])
    const [dbPointList, setDbPointList] = useState([])
    const [distributors, setDistributors] = useState([])
    const [distributorById, setDistributorById] = useState([])
    const [isLoading, setIsLoading] = useState({
        general: false,
        zone: false,
        region: false,
        area: false,
        dbPoint: false,
        db: false,
    })
    const { updateID } = useParams()
    const navigate = useNavigate()
    const { companyName } = useUserData()

    //open day

    const openDayList = [
        {
            label: 'Saturday',
            value: 'Saturday',
        },
        {
            label: 'Sunday',
            value: 'Sunday',
        },
        {
            label: 'Monday',
            value: 'Monday',
        },
        {
            label: 'Tuesday',
            value: 'Tuesday',
        },
        {
            label: 'Wednesday',
            value: 'Wednesday',
        },
        {
            label: 'Thursday',
            value: 'Thursday',
        },
        {
            label: 'Friday',
            value: 'Friday',
        },
    ]

    //  Date Format
    function formatDateToYYYYMMDD(isoDateString) {
        const date = new Date(isoDateString)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0') // Month is 0-based
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    const SrRoleList = [
        {
            label: 'SUPER DISTRIBUTOR',
            value: 'SUPER DISTRIBUTOR',
        },
        {
            label: 'GENERAL DISTRIBUTOR',
            value: 'GENERAL DISTRIBUTOR',
        },
        {
            label: 'WHOLESALE DISTRIBUTOR',
            value: 'WHOLESALE DISTRIBUTOR',
        },
        {
            label: 'COMMISSION DISTRIBUTOR',
            value: 'COMMISSION DISTRIBUTOR',
        },
        {
            label: 'SPOT DISTRIBUTOR',
            value: 'SPOT DISTRIBUTOR',
        },
        {
            label: 'SPECIAL DISTRIBUTOR',
            value: 'SPECIAL DISTRIBUTOR',
        },
    ]

    const districts = [
        { label: 'Dhaka', value: 'Dhaka' },
        { label: 'Faridpur', value: 'Faridpur' },
        { label: 'Gazipur', value: 'Gazipur' },
        { label: 'Gopalganj', value: 'Gopalganj' },
        { label: 'Jamalpur', value: 'Jamalpur' },
        { label: 'Kishoreganj', value: 'Kishoreganj' },
        { label: 'Madaripur', value: 'Madaripur' },
        { label: 'Manikganj', value: 'Manikganj' },
        { label: 'Munshiganj', value: 'Munshiganj' },
        { label: 'Mymensingh', value: 'Mymensingh' },
        { label: 'Narayanganj', value: 'Narayanganj' },
        { label: 'Narsingdi', value: 'Narsingdi' },
        { label: 'Netrokona', value: 'Netrokona' },
        { label: 'Rajbari', value: 'Rajbari' },
        { label: 'Shariatpur', value: 'Shariatpur' },
        { label: 'Sherpur', value: 'Sherpur' },
        { label: 'Tangail', value: 'Tangail' },
        { label: 'Bogra', value: 'Bogra' },
        { label: 'Joypurhat', value: 'Joypurhat' },
        { label: 'Naogaon', value: 'Naogaon' },
        { label: 'Natore', value: 'Natore' },
        { label: 'Nawabganj', value: 'Nawabganj' },
        { label: 'Pabna', value: 'Pabna' },
        { label: 'Rajshahi', value: 'Rajshahi' },
        { label: 'Sirajgonj', value: 'Sirajgonj' },
        { label: 'Dinajpur', value: 'Dinajpur' },
        { label: 'Gaibandha', value: 'Gaibandha' },
        { label: 'Kurigram', value: 'Kurigram' },
        { label: 'Lalmonirhat', value: 'Lalmonirhat' },
        { label: 'Nilphamari', value: 'Nilphamari' },
        { label: 'Panchagarh', value: 'Panchagarh' },
        { label: 'Rangpur', value: 'Rangpur' },
        { label: 'Thakurgaon', value: 'Thakurgaon' },
        { label: 'Barguna', value: 'Barguna' },
        { label: 'Barisal', value: 'Barisal' },
        { label: 'Bhola', value: 'Bhola' },
        { label: 'Jhalokati', value: 'Jhalokati' },
        { label: 'Patuakhali', value: 'Patuakhali' },
        { label: 'Pirojpur', value: 'Pirojpur' },
        { label: 'Bandarban', value: 'Bandarban' },
        { label: 'Brahmanbaria', value: 'Brahmanbaria' },
        { label: 'Chandpur', value: 'Chandpur' },
        { label: 'Chittagong', value: 'Chittagong' },
        { label: 'Comilla', value: 'Comilla' },
        { label: "Cox's Bazar", value: "Cox's Bazar" },
        { label: 'Feni', value: 'Feni' },
        { label: 'Khagrachari', value: 'Khagrachari' },
        { label: 'Lakshmipur', value: 'Lakshmipur' },
        { label: 'Noakhali', value: 'Noakhali' },
        { label: 'Rangamati', value: 'Rangamati' },
        { label: 'Habiganj', value: 'Habiganj' },
        { label: 'Maulvibazar', value: 'Maulvibazar' },
        { label: 'Sunamganj', value: 'Sunamganj' },
        { label: 'Sylhet', value: 'Sylhet' },
        { label: 'Bagerhat', value: 'Bagerhat' },
        { label: 'Chuadanga', value: 'Chuadanga' },
        { label: 'Jessore', value: 'Jessore' },
        { label: 'Jhenaidah', value: 'Jhenaidah' },
        { label: 'Khulna', value: 'Khulna' },
        { label: 'Kushtia', value: 'Kushtia' },
        { label: 'Magura', value: 'Magura' },
        { label: 'Meherpur', value: 'Meherpur' },
        { label: 'Narail', value: 'Narail' },
        { label: 'Satkhira', value: 'Satkhira' },
    ]

    const banks = [
        { value: 'AB Bank PLC', label: 'AB Bank PLC' },
        { value: 'Agrani Bank PLC', label: 'Agrani Bank PLC' },
        {
            value: 'Al-Arafah Islami Bank PLC',
            label: 'Al-Arafah Islami Bank PLC',
        },
        {
            value: 'Bangladesh Commerce Bank Limited',
            label: 'Bangladesh Commerce Bank Limited',
        },
        {
            value: 'Bangladesh Development Bank PLC',
            label: 'Bangladesh Development Bank PLC',
        },
        { value: 'Bangladesh Krishi Bank', label: 'Bangladesh Krishi Bank' },
        { value: 'Bank Al-Falah Limited', label: 'Bank Al-Falah Limited' },
        { value: 'Bank Asia PLC.', label: 'Bank Asia PLC.' },
        { value: 'BASIC Bank Limited', label: 'BASIC Bank Limited' },
        {
            value: 'Bengal Commercial Bank PLC.',
            label: 'Bengal Commercial Bank PLC.',
        },
        { value: 'BRAC Bank PLC', label: 'BRAC Bank PLC' },
        { value: 'Citibank N.A', label: 'Citibank N.A' },
        { value: 'Citizens Bank PLC', label: 'Citizens Bank PLC' },
        { value: 'City Bank PLC', label: 'City Bank PLC' },
        {
            value: 'Commercial Bank of Ceylon Limited',
            label: 'Commercial Bank of Ceylon Limited',
        },
        {
            value: 'Community Bank Bangladesh PLC.',
            label: 'Community Bank Bangladesh PLC.',
        },
        { value: 'Dhaka Bank PLC', label: 'Dhaka Bank PLC' },
        { value: 'Dutch-Bangla Bank PLC', label: 'Dutch-Bangla Bank PLC' },
        { value: 'Eastern Bank PLC', label: 'Eastern Bank PLC' },
        { value: 'EXIM Bank PLC', label: 'EXIM Bank PLC' },
        {
            value: 'First Security Islami Bank PLC',
            label: 'First Security Islami Bank PLC',
        },
        { value: 'Global Islami Bank PLC', label: 'Global Islami Bank PLC' },
        { value: 'Habib Bank Ltd.', label: 'Habib Bank Ltd.' },
        { value: 'ICB Islamic Bank Ltd.', label: 'ICB Islamic Bank Ltd.' },
        { value: 'IFIC Bank PLC', label: 'IFIC Bank PLC' },
        {
            value: 'Islami Bank Bangladesh PLC',
            label: 'Islami Bank Bangladesh PLC',
        },
        { value: 'Jamuna Bank PLC', label: 'Jamuna Bank PLC' },
        { value: 'Janata Bank PLC', label: 'Janata Bank PLC' },
        { value: 'Meghna Bank PLC', label: 'Meghna Bank PLC' },
        { value: 'Mercantile Bank PLC', label: 'Mercantile Bank PLC' },
        { value: 'Midland Bank Limited', label: 'Midland Bank Limited' },
        { value: 'Modhumoti Bank PLC', label: 'Modhumoti Bank PLC' },
        { value: 'Mutual Trust Bank PLC', label: 'Mutual Trust Bank PLC' },
        { value: 'Nagad Digital Bank PLC.', label: 'Nagad Digital Bank PLC.' },
        { value: 'National Bank Limited', label: 'National Bank Limited' },
        {
            value: 'National Bank of Pakistan',
            label: 'National Bank of Pakistan',
        },
        {
            value: 'National Credit & Commerce Bank PLC',
            label: 'National Credit & Commerce Bank PLC',
        },
        { value: 'NRB Bank Limited', label: 'NRB Bank Limited' },
        { value: 'NRBC Bank PLC', label: 'NRBC Bank PLC' },
        { value: 'One Bank PLC', label: 'One Bank PLC' },
        { value: 'Padma Bank PLC', label: 'Padma Bank PLC' },
        { value: 'Prime Bank PLC', label: 'Prime Bank PLC' },
        { value: 'Probashi Kollyan Bank', label: 'Probashi Kollyan Bank' },
        { value: 'Pubali Bank PLC', label: 'Pubali Bank PLC' },
        {
            value: 'Rajshahi Krishi Unnayan Bank',
            label: 'Rajshahi Krishi Unnayan Bank',
        },
        { value: 'Rupali Bank PLC', label: 'Rupali Bank PLC' },
        { value: 'SBAC Bank PLC', label: 'SBAC Bank PLC' },
        {
            value: 'Shahjalal Islami Bank PLC',
            label: 'Shahjalal Islami Bank PLC',
        },
        { value: 'Shimanto Bank PLC', label: 'Shimanto Bank PLC' },
        { value: 'Social Islami Bank PLC', label: 'Social Islami Bank PLC' },
        { value: 'Sonali Bank PLC', label: 'Sonali Bank PLC' },
        { value: 'Southeast Bank PLC', label: 'Southeast Bank PLC' },
        { value: 'Standard Bank PLC', label: 'Standard Bank PLC' },
        { value: 'Standard Chartered Bank', label: 'Standard Chartered Bank' },
        { value: 'State Bank of India', label: 'State Bank of India' },
        {
            value: 'The Hong Kong and Shanghai Banking Corporation. Ltd.',
            label: 'The Hong Kong and Shanghai Banking Corporation. Ltd.',
        },
        { value: 'The Premier Bank PLC', label: 'The Premier Bank PLC' },
        { value: 'Trust Bank Limited', label: 'Trust Bank Limited' },
        { value: 'Union Bank PLC', label: 'Union Bank PLC' },
        {
            value: 'United Commercial Bank PLC',
            label: 'United Commercial Bank PLC',
        },
        { value: 'Uttara Bank PLC', label: 'Uttara Bank PLC' },
        { value: 'Woori Bank', label: 'Woori Bank' },
    ]

    const bloodGroups = [
        { label: 'A+', value: 'A+' },
        { label: 'A-', value: 'A-' },
        { label: 'B+', value: 'B+' },
        { label: 'B-', value: 'B-' },
        { label: 'AB+', value: 'AB+' },
        { label: 'AB-', value: 'AB-' },
        { label: 'O+', value: 'O+' },
        { label: 'O-', value: 'O-' },
    ]

    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading((prevState) => ({ ...prevState, general: true }))
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/getSRById/${updateID}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success) {
                        const data = response.data.payload.sr
                        setDistributorById(data)
                        setSelectedZone(data?.zoneName)
                        setSelectedRegion(data?.regionName)
                        setSelectedArea(data?.areaName)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        general: false,
                    }))
                }
            }
        }

        fetchData()
    }, [updateID])

    useEffect(() => {
        setIsLoading((prevState) => ({ ...prevState, zone: true }))
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(
                        (data) => ({
                            id: data._id,
                            label: data.name,
                            value: data.name,
                        })
                    )

                    setZoneList(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, zone: false }))
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (selectedZone) {
            setIsLoading((prevState) => ({ ...prevState, region: true }))
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.regionName,
                                value: data.regionName,
                            })
                        )

                        setRegionList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        region: false,
                    }))
                }
            }

            fetchData()
        }
    }, [selectedZone])

    useEffect(() => {
        if (selectedRegion) {
            setIsLoading((prevState) => ({ ...prevState, area: true }))
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}areas/${selectedRegion}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.areaName,
                                value: data.areaName,
                            })
                        )

                        setAreaList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({ ...prevState, area: false }))
                }
            }

            fetchData()
        }
    }, [selectedRegion])

    useEffect(() => {
        if (selectedArea) {
            setIsLoading((prevState) => ({ ...prevState, dbPoint: true }))
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.dbPointName,
                                value: data.dbPointName,
                            })
                        )
                        setDbPointList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        dbPoint: false,
                    }))
                }
            }
            fetchData()
        }
    }, [selectedArea])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading((prevState) => ({ ...prevState, db: true }))
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?zoneName=${selectedZone}&regionName=${selectedRegion}&areaName=${selectedArea}&dbPoint=${selectedDbPoint}`
                const response = await axios.get(url, { headers: authHeader() })
                if (response.data.success || response.status === 200) {
                    const modifyData =
                        response?.data?.payload?.distributors.map((data) => ({
                            id: data.userId,
                            label: data.distributorName,
                            value: data.distributorName,
                        }))
                    setDistributors(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, db: false }))
            }
        }
        fetchData()
    }, [selectedDbPoint])

    const handleSubmitForm = async (values, actions) => {
        const handleSubmitData = {
            /* 
            
            companyName 
            zoneName 
            regionName 
            areaName 
            dbPoint  
            dealerId 
            dealerName 
            srName   
            userRole  
            userId 
            password  
            email  
            mobileNo  
            sscYear
            hDegree    
            bloodGroup    
            dobDate  
            dojDate
            district  
            bankName    
            bankAccountNo  
            emergencyContactName
            emergencyContactMobile 
            emergencyContactRelation
            basicSalary
            houseRent
            medical
            taDa
            phoneBill
            meetingTa  
            meetingDa:
            */
            companyName: companyName,
            zoneName: values.zoneName.value,
            regionName: values.regionName.value,
            areaName: values.areaName.value,
            dbPoint: values.dbPointName.value,
            dealerId: values.dealerId.id,
            dealerName: values.dealerId.value,
            srName: values.srName,
            userRole: 'SR',
            password: values.password,
            mobileNo: values.mobileNo,
            district: values.district.value,
        }
        if (values.bankName) {
            handleSubmitData.bankName = values.bankName.value
        } else {
            handleSubmitData.bankName = null
        }
        if (values.bankAccountNo) {
            handleSubmitData.bankAccountNo = values.bankAccountNo
        } else {
            handleSubmitData.bankAccountNo = null
        }
        if (values.email) {
            handleSubmitData.email = values.email
        }
        if (values.sscYear) {
            handleSubmitData.sscYear = values.sscYear
        } else {
            handleSubmitData.sscYear = null
        }
        if (values.hDegree) {
            handleSubmitData.hDegree = values.hDegree
        } else {
            handleSubmitData.hDegree = null
        }
        if (values.bloodGroup) {
            handleSubmitData.bloodGroup = values.bloodGroup.value
        } else {
            handleSubmitData.bloodGroup = null
        }
        if (values.dobDate) {
            handleSubmitData.dobDate = values.dobDate
        } else {
            handleSubmitData.dobDate = null
        }
        if (values.dojDate) {
            handleSubmitData.dojDate = values.dojDate
        } else {
            handleSubmitData.dojDate = null
        }
        if (values.basicSalary) {
            handleSubmitData.basicSalary = values.basicSalary
        } else {
            handleSubmitData.basicSalary = null
        }
        if (values.houseRent) {
            handleSubmitData.houseRent = values.houseRent
        } else {
            handleSubmitData.houseRent = null
        }
        if (values.medical) {
            handleSubmitData.medical = values.medical
        } else {
            handleSubmitData.medical = null
        }
        if (values.taDa) {
            handleSubmitData.taDa = values.taDa
        } else {
            handleSubmitData.taDa = null
        }
        if (values.phoneBill) {
            handleSubmitData.phoneBill = values.phoneBill
        } else {
            handleSubmitData.phoneBill = null
        }
        if (values.meetingTa) {
            handleSubmitData.meetingTa = values.meetingTa
        } else {
            handleSubmitData.meetingTa = null
        }
        if (values.meetingDa) {
            handleSubmitData.meetingDa = values.meetingDa
        } else {
            handleSubmitData.meetingDa = null
        }
        if (values.emergencyContactName) {
            handleSubmitData.emergencyContactName = values.emergencyContactName
        } else {
            handleSubmitData.emergencyContactName = null
        }

        if (values.emergencyContactMobile) {
            handleSubmitData.emergencyContactMobile =
                values.emergencyContactMobile
        } else {
            handleSubmitData.emergencyContactMobile = null
        }

        if (values.emergencyContactRelation) {
            handleSubmitData.emergencyContactRelation =
                values.emergencyContactRelation
        } else {
            handleSubmitData.emergencyContactRelation = null
        }
        if (!updateID) {
            handleSubmitData.userId = values.userId
        }
        setIsLoading((prevState) => ({ ...prevState, general: true }))
        const url = updateID
            ? `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/updateSRById/${updateID}`
            : `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/createSR`

        try {
            const axiosMethod = updateID ? axios.put : axios.post
            const response = await axiosMethod(url, handleSubmitData, {
                headers: authHeader(),
            })

            if (response.status === 200 || response.data.status) {
                navigate('/user/sr')
                toast.success(response.data.message)
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading((prevState) => ({ ...prevState, general: false }))
        }
    }

    // Yup validation schema
    const handleSchema = Yup.object().shape({
        zoneName: Yup.object().required(
            'please select a value for the zone field'
        ),
        regionName: Yup.object().required(
            'please select a value for the region field'
        ),
        areaName: Yup.object().required(
            'please select a value for the area field'
        ),
        dbPointName: Yup.object().required(
            'please select a value for the db point field'
        ),
        dealerId: Yup.object().required(
            'please select a value for the dealer id field'
        ),
        userId: Yup.string().required(
            'please enter a value for the dealer id field'
        ),
        password: Yup.string().required(
            'please enter a value for the password field'
        ),
        srName: Yup.string()
            .min(2, 'Too Short!')
            .max(150, 'Too Long!')
            .required('Please enter a value for the sr name field')
            .test(
                'starts with a number',
                'Field input should not start with a number',
                (value) => {
                    return !/^\d/.test(value)
                }
            )
            .test(
                'no special characters',
                'Field input should not contain special characters',
                (value) => {
                    return /^[a-zA-Z0-9\s]*$/.test(value)
                }
            ),
        email: Yup.string().email('Invalid email').nullable(),
        mobileNo: Yup.string().required(
            'please enter a value for the mobile number field'
        ),
        sscYear: Yup.string().nullable(),
        hDegree: Yup.string().nullable(),
        bloodGroup: Yup.object().nullable(),
        dobDate: Yup.date().required('please select a value for the dob field'),
        dojDate: Yup.date().required('please select a value for the doj field'),
        district: Yup.object().required(
            'please select a value for the district field'
        ),
        bankName: Yup.object().nullable(),
        bankAccountNo: Yup.string().nullable(),
        emergencyContactName: Yup.string().nullable(),
        emergencyContactMobile: Yup.string().nullable(),
        emergencyContactRelation: Yup.string().nullable(),
        basicSalary: Yup.number().nullable(),
        houseRent: Yup.number().nullable(),
        medical: Yup.number().nullable(),
        taDa: Yup.number().nullable(),
        phoneBill: Yup.number().nullable(),
        meetingTa: Yup.number().nullable(),
        meetingDa: Yup.number().nullable(),
    })

    return (
        <>
            <PageHeader
                title={updateID ? 'Update  Route Assign' : 'Route Assign'}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard>
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    {isLoading.general && <LoadingIcon />}
                    <Formik
                        initialValues={{
                            zoneName:
                                zoneList.find(
                                    (item) =>
                                        item.value === distributorById.zoneName
                                ) || null,
                            regionName:
                                regionList.find(
                                    (item) =>
                                        item.value ===
                                        distributorById?.regionName
                                ) || null,
                            areaName:
                                areaList.find(
                                    (item) =>
                                        item.value === distributorById?.areaName
                                ) || null,
                            dbPointName:
                                dbPointList.find(
                                    (item) =>
                                        item.value === distributorById?.dbPoint
                                ) || null,
                            dealerId:
                                distributors.find(
                                    (item) =>
                                        item.id === distributorById?.dealerId
                                ) || null,
                            userId: distributorById.userId || '',
                            password: distributorById.password || '',
                            srName: distributorById.srName || '',
                            mobileNo: distributorById.mobileNo || '',
                            bloodGroup:
                                bloodGroups.find(
                                    (item) =>
                                        item.value ===
                                        distributorById.bloodGroup
                                ) || null,
                            dobDate: distributorById.dobDate
                                ? formatDateToYYYYMMDD(distributorById.dobDate)
                                : '',
                            dojDate: distributorById.dojDate
                                ? formatDateToYYYYMMDD(distributorById.dojDate)
                                : '',
                            district:
                                districts.find(
                                    (item) =>
                                        item.value === distributorById.district
                                ) || null,
                            bankName:
                                banks.find(
                                    (item) =>
                                        item.value === distributorById.bankName
                                ) || null,
                            bankAccountNo: distributorById.bankAccountNo || '',
                            basicSalary: distributorById.basicSalary || '',
                            houseRent: distributorById.houseRent || '',
                            medical: distributorById.medical || '',
                            taDa: distributorById.taDa || '',
                            phoneBill: distributorById.phoneBill || '',
                            meetingTa: distributorById.meetingTa || '',
                            meetingDa: distributorById.meetingDa || '',
                            sscYear: distributorById.sscYear || '',
                            hDegree: distributorById.hDegree || '',
                            email: distributorById.email || null,
                            emergencyContactName:
                                distributorById.emergencyContactName || '',
                            emergencyContactMobile:
                                distributorById.emergencyContactMobile || '',
                            emergencyContactRelation:
                                distributorById.emergencyContactRelation || '',
                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched,
                        }) => {
                            console.log(errors)
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Select Zone</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={zoneList}
                                                placeholder="Select Zone"
                                                classNamePrefix="react-select"
                                                name="zoneName"
                                                value={values.zoneName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'zoneName',
                                                        selectedOption
                                                    )
                                                    setSelectedZone(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'zoneName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.zone}
                                            />
                                            {touched.zoneName &&
                                                errors.zoneName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.zoneName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select Region
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={regionList}
                                                placeholder="Select Region"
                                                classNamePrefix="react-select"
                                                name="regionName"
                                                value={values.regionName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'regionName',
                                                        selectedOption
                                                    )
                                                    setSelectedRegion(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'regionName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.region}
                                            />
                                            {touched.regionName &&
                                                errors.regionName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.regionName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Select Area</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={areaList}
                                                placeholder="Select Area"
                                                classNamePrefix="react-select"
                                                name="areaName"
                                                value={values.areaName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'areaName',
                                                        selectedOption
                                                    )
                                                    setSelectedArea(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'areaName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.area}
                                            />
                                            {touched.areaName &&
                                                errors.areaName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.areaName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select DB Point
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={dbPointList}
                                                placeholder="Select DB Point"
                                                classNamePrefix="react-select"
                                                name="dbPointName"
                                                value={values.dbPointName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'dbPointName',
                                                        selectedOption
                                                    )
                                                    setSelectedDbPoint(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'dbPointName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.dbPoint}
                                            />
                                            {touched.dbPointName &&
                                                errors.dbPointName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.dbPointName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Select DB</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributors}
                                                placeholder="Select DB"
                                                classNamePrefix="react-select"
                                                name="dealerId"
                                                value={values.dealerId}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'dealerId',
                                                        selectedOption
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'dealerId',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.db}
                                            />
                                            {touched.dealerId &&
                                                errors.dealerId && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.dealerId}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Select SR</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                //options={distributors}
                                                placeholder="Select Sr"
                                                classNamePrefix="react-select"
                                                name="dealerId"
                                                value={values.userId}
                                                // onChange={(selectedOption) => {
                                                //     setFieldValue("dealerId", selectedOption);
                                                // }}
                                                // onBlur={event => { handleBlur(event); setFieldTouched("dealerId", true, true) }}
                                                isLoading={isLoading.db}
                                            />
                                            {touched.dealerId &&
                                                errors.dealerId && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.dealerId}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Route Name</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                //options={distributors}
                                                placeholder="Route Name"
                                                classNamePrefix="react-select"
                                                name="dealerId"
                                                value={values.userId}
                                                // onChange={(selectedOption) => {
                                                //     setFieldValue("dealerId", selectedOption);
                                                // }}
                                                // onBlur={event => { handleBlur(event); setFieldTouched("dealerId", true, true) }}
                                                isLoading={isLoading.db}
                                            />
                                            {touched.dealerId &&
                                                errors.dealerId && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.dealerId}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Route Open Days</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={openDayList}
                                                placeholder="Route Open Days"
                                                classNamePrefix="react-select"
                                                name="productCategory"
                                                isMulti
                                                value={values.productCategory}
                                                onChange={(selectedOption) => {

                                                    const selected = Array.from(
                                                        selectedOption,
                                                        (option) => option
                                                    );
                                                    

                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("productCategory", true, true); }}
                                            />
                                            {touched.productCategory && errors.productCategory && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.productCategory}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? 'Save' : 'Submit'}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate('/user/sr')}
                                        variant="danger"
                                        type="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    )
}

export default SalesRepresentativeRoutesForm
