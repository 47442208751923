import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import React from 'react'
import { Card } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllDeliveries, getAllSchedules } from 'services/api'
import image from 'assets/img/icons/spot-illustrations/corner-1.png'
import Background from 'components/common/Background'
import SoftBadge from 'components/common/SoftBadge'
const ScheduledOrders = ({ user }) => {
    const {
        data: scheduleDataByFactory,
        isLoading: isLoadingScheduleByFactory,
        isError: scheduleByFactoryError,
        refetch: scheduleByFactoryRefetch,
    } = useQuery({
        queryKey: ['allSchedules'],
        queryFn: () => getAllSchedules(),
    })

    const {
        data: deliveryDataByFactory,
        isLoading: isLoadingDeliveryByFactory,
        isError: deliveryByFactoryError,
        refetch: deliveryByFactoryRefetch,
    } = useQuery({
        queryKey: ['allDeliveries'],
        queryFn: () => getAllDeliveries(),
    })

    const IsShowable = (order, allDeliveries) => {
        // if (!allDeliveries.length) {
        //     return true
        // }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName &&
                    item.scheduleNo == order.scheduleNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return qtySum == order.delQtyCtn
    }

    let undeliveredOrders = 0

    if (
        scheduleDataByFactory?.data.payload.result.length &&
        deliveryDataByFactory?.data.payload.result
    ) {
        const allSchedules = scheduleDataByFactory.data.payload.result
        const allDeliveries = deliveryDataByFactory.data.payload.result

        const filteredSchedules = allSchedules.filter(
            (item) => !item.superDBId && !IsShowable(item, allDeliveries)
        )
        undeliveredOrders = filteredSchedules.length
    }

    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="warning" pill className="">
                    Scheduled Orders
                </SoftBadge>
                <h5 style={{ fontSize: '1rem' }} className="mt-3">
                    {' '}
                    Total: {undeliveredOrders}
                </h5>
                <div>
                    <Link
                        to={'distribution/productscheduling/done'}
                        className="fw-semi-bold fs--1 text-nowrap"
                    >
                        {'See all'}
                        <FontAwesomeIcon
                            icon="angle-right"
                            className="ms-1"
                            transform="down-1"
                        />
                    </Link>
                </div>
                <FontAwesomeIcon icon="clock" className="ms-1" size="xl" />
            </Card.Body>
        </Card>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledOrders)
