import SoftBadge from 'components/common/SoftBadge'
import { formatNumber } from 'helpers/utils'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import image from 'assets/img/icons/spot-illustrations/corner-2.png'
import Background from 'components/common/Background'


const MonthlyConfirmNo = ({ data, selectedFromDate, selectedToDate }) => {

    // Formtting the date
    const selectedDate = new Date();
    const month = selectedDate?.toLocaleString('default', { month: 'long' });
    const year = selectedDate?.getFullYear();

    let formatDate = `${month} ${year}`;

    if (selectedFromDate && selectedToDate) {
        const fromDate = new Date(selectedFromDate);
        const toDate = new Date(selectedToDate);
        const fromDay = fromDate?.getDate();
        const fromMonth = fromDate?.toLocaleString('default', { month: 'long' });
        const fromYear = fromDate?.getFullYear();
        const toDay = toDate?.getDate();
        const toMonth = toDate?.toLocaleString('default', { month: 'long' });
        const toYear = toDate?.getFullYear();

        formatDate = `${fromDay} ${fromMonth} ${fromYear} - ${toDay} ${toMonth} ${toYear}`;
    }


    const navigate = useNavigate()

    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="info" pill className="">
                    Monthly
                </SoftBadge>
                <h5 style={{ fontSize: '1rem' }}>Confirm DO</h5>
                <div className=" display-4 fs-1 mb-2 fw-normal font-sans-serif">
                    No. {formatNumber(data?.ConfirmDo) || 0}
                </div>
                {/* <Link
                    to="/accounts/due-payment-list"
                    className="fw-semi-bold fs--1 text-nowrap"
                >
                    See Details
                    <FontAwesomeIcon
                        icon="angle-right"
                        className="ms-1"
                        transform="down-1"
                    />
                </Link> */}
                <span className="fw-semi-bold fs--1 text-nowrap">{formatDate}</span>
            </Card.Body>
        </Card>
    )
}

export default MonthlyConfirmNo
