const { Row, Col } = require("react-bootstrap");
const { default: ConfirmPayment } = require("./ConfirmPayment");
const { default: DueOrdersAmount } = require("../DueOrdersAmount");

const JuniorAccountsDashboard = ({ setIsLoading }) => {
    return (
        <div>
            <Row className="g-3 mb-3">
                <Col md={6} xxl={3}>
                    <ConfirmPayment setIsLoading={setIsLoading} />
                </Col>
                <Col md={6} xxl={3}>
                    <DueOrdersAmount setIsLoading={setIsLoading} />
                </Col>
            </Row>
        </div>
    );
};

export default JuniorAccountsDashboard;