import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GreetingsDate from "components/dashboards/crm/greetings/GreetingsDate";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";

const PurchaseConfirm = ({ setStep, selectedExpiryDate, productsData, setProductsData, setCount }) => {
    const [isLoading, setIsLoading] = useState(false);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const navigate = useNavigate();

    // handle remove product
    const handleRemoveProduct = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {

            const deleteProduct = (id) => {
                axios.delete(`${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/${id}`, { headers: authHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            const result = productsData?.filter(product => product?._id !== id);
                            setProductsData(result);

                            Swal.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success"
                            });
                        }
                    })
                    .catch((error) => {
                        handleApiResponseErrors(error.response);
                    });
            }

            deleteProduct(id);
        }
    }

    const handleSubmit = async () => {
        const receivedProduct = productsData?.map((product) => {
            const receivedProduct = {
                _id: product?._id,
                userId: distributorInfo?.userId,
                companyName: distributorInfo?.companyName,
                factoryName: distributorInfo?.factoryName,
                productCategory: product?.categoryName,
                skuName: product?.skuName,
                productId: product?._id,
                rcvQtyCTN: product?.ctn,
                rcvQtyPCS: product?.pcs,//product?.pcs,
                rcvQtyMt: product?.weightMT,
                perPCPrice: 0,
                purchaseStatus: 1,
                status: 0,
                note: product?.note || "",
            };
            return receivedProduct;
        });

        try {
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/multiUpdateRcvProduction`, receivedProduct, { headers: authHeader() });
            if (response.data.status || response.status === 200) {
                Swal.fire({
                    title: "Received!",
                    text: "Your file has been received.",
                    icon: "success"
                });
                navigate("/scm/purchase-pending");
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }




    };

    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>

            <Card className="mb-3">
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Action</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Note</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        productsData?.map((product, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.productCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">
                                                    {product?.ctn}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {product?.pcs}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {product?.weightMT}
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Button variant="danger" onClick={() => handleRemoveProduct(product?._id)}>Cancel</Button>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {product?.note}
                                                </td>
                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>

            <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleSubmit(); }}>
                Update
            </Button>

        </>
    );

};

export default PurchaseConfirm;
