import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import IconButton from 'components/common/IconButton'
import React from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { usePDF } from 'react-to-pdf'
import { useReactToPrint } from 'react-to-print'
import useUserData from 'hooks/useUserData'
import { formatNumber } from 'helpers/utils'


const PendingDeliveriesPrint = ({ setStep, data }) => {
    const userData = useUserData();

    // Date Format Function
    const DateFormat = (date) => {
        const originalDateString = date
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${day}-${month}-${year}`

        return formattedDateString
    }

    const { toPDF, targetRef } = usePDF({
        filename: 'Pending Deliveries' + '.pdf',
    })

    // Function to trigger the print
    const handlePrint = useReactToPrint({
        content: () => targetRef.current,
    })

    const handleNext = () => {
        setStep({
            step: 1,
            reload: false,
        })
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">
                                Pending Deliveries
                            </h5>
                        </Col>

                        <Col xs="auto">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="print"
                                className="me-1 mb-2 mb-sm-0"
                                iconClassName="me-1"
                                onClick={() => handlePrint()}
                            >
                                Print
                            </IconButton>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card ref={targetRef} className="h-lg-100 overflow-hidden">
                <Card.Header>
                    <Row className="align-items-center text-center">
                        <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center text-center">
                                <div className="text-sm-start"><img src={logo} alt="invoice" width={100} /></div>
                                <div className="text-sm-start mt-3 mt-sm-0"><h3 style={{ marginLeft: '95px' }}>
                                    T.K Food Products Ltd.
                                </h3>
                                    <p className="mb-0"  >
                                        T. K. Bhaban (2nd Floor), 13, Kawranbazar
                                        Dhaka-1215, Bangladesh
                                    </p></div>
                                <div>

                                </div>
                            </div>
                        </Col>



                        <Col xs={12}>
                            <hr />
                        </Col>

                        <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center text-center">
                                <div></div>
                                <div style={{ marginLeft: '173px' }}>
                                    <h3>Delivery Truck Loading</h3>
                                    <h5>{userData?.factoryName}</h5>
                                </div>
                                <div className="mb-2" style={{ marginRight: '33px', paddingBottom: '24px' }}>
                                    Delivery Date:{' '}
                                    {(() => {
                                        const date = new Date(

                                        )
                                        const day = date.getDate()
                                        const monthNames = [
                                            'Jan',
                                            'Feb',
                                            'Mar',
                                            'Apr',
                                            'May',
                                            'Jun',
                                            'Jul',
                                            'Aug',
                                            'Sep',
                                            'Oct',
                                            'Nov',
                                            'Dec',
                                        ]
                                        const month =
                                            monthNames[date.getMonth()]
                                        const year = date.getFullYear()
                                        return `${day}-${month}-${year}`
                                    })()}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body className="p-0">
                    <Table borderless className="fs--1">
                        <thead className="bg-light">
                            <tr className="text-900">
                                <th className="text-center">No.</th>
                                <th className="text-center">DO No</th>
                                <th className="text-center">DB Name</th>
                                <th className="text-center">Sku Name</th>
                                <th className="text-center">Delivery Qty CTN</th>
                                <th className="text-center">Delivery Qty PCS</th>
                                <th className="text-center">Offer Qty PCS</th>
                                <th className="text-center">Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {
                                return (
                                    <tr className="border-top border-200">
                                        <td className="text-center">
                                            {' '}
                                            {index + 1}
                                        </td>
                                        <td className="text-center fw-semi-bold">
                                            <span className="fw-bold">
                                                {item.doNo}
                                            </span>
                                        </td>
                                        <td className="text-center fw-semi-bold">
                                            <span className="fw-bold">
                                                {item.distributorName} ({item.dealerId ? item.dealerId : ""})
                                            </span>
                                        </td>
                                        <td className="text-center fw-semi-bold">
                                            <span className="fw-bold">
                                                {item.skuName} ({item.erpId ? item.erpId : ""})
                                            </span>
                                        </td>

                                        <td
                                            className="align-middle text-center fw-bold"
                                        >
                                            {formatNumber(item?.delQtyCtn)}
                                        </td>
                                        <td
                                            className="align-middle text-center fw-bold"
                                        >
                                            {formatNumber(item?.delQtyPcs)}
                                        </td>
                                        <td
                                            className="align-middle text-center fw-bold"
                                        >
                                            {formatNumber(item?.offerQtyPCS)}
                                        </td>
                                        <td
                                            className="align-middle text-center fw-bold"
                                        >

                                        </td>

                                    </tr>
                                )
                            })}
                        </tbody>

                        <tfoot className="bg-light">
                            <tr className="text-900">

                                <th className="text-center fw-bold" colSpan={2}></th>
                                <th className="text-center fw-bold"></th>
                                <th className="text-center fw-bold">Total</th>
                                <th className="text-center fw-bold">
                                    {formatNumber(
                                        data.reduce(
                                            (acc, item) =>
                                                acc + item.delQtyCtn,
                                            0
                                        )
                                    )}
                                </th>
                                <th className="text-center fw-bold">
                                    {formatNumber(
                                        data.reduce(
                                            (acc, item) =>
                                                acc + item.delQtyPcs,
                                            0
                                        )
                                    )}
                                </th>
                                <th className="text-center fw-bold">
                                    {formatNumber(
                                        data.reduce(
                                            (acc, item) =>
                                                acc + item.offerQtyPCS,
                                            0
                                        )
                                    )}
                                </th>
                                <th className="text-center fw-bold" colSpan={3}></th>
                            </tr>
                        </tfoot>
                    </Table>

                    <div className="px-3 mt-5 mb-3 float-end">
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            Receiver Signature
                        </p>

                    </div>
                </Card.Body>

            </Card>


            <div>
                <Button
                    variant="primary"
                    className="me-2 px-5 mt-3"
                    onClick={() => handleNext()}
                >
                    Back
                </Button>
            </div>
        </>
    )
}

export default PendingDeliveriesPrint;
