import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
// import CartItem from './CartItem';
// import CartModal from './CartModal';
import { ProductContext } from 'context/Context';
import { getSubtotal } from 'helpers/utils';
import PageHeader from 'components/common/PageHeader';


const GenerateFormat = () => {


    return (
        <>
            <PageHeader
                title="Format List"
                className="mb-3"
            />
            <Card>

                <Card.Body className="p-0">

                    <>
                        <Row className="gx-card mx-0 align-items-center border-bottom border-200">
                            <Col xs={8} className="py-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="flex-1">
                                        <h5 className="fs-0">
                                            <Link
                                                to="/accounts/adjustment"
                                                className="text-900"
                                            >
                                                Payment Adjustment Format
                                            </Link>
                                        </h5>

                                    </div>
                                </div>
                            </Col>
                            <hr></hr>
                            <Col xs={8} className="py-3 ">
                                <div className="d-flex align-items-center">

                                    <div className="flex-1">
                                        <h5 className="fs-0">
                                            <Link
                                                to="/target-format-dowanload"
                                                className="text-900"
                                            >
                                                Target Csv Format
                                            </Link>
                                        </h5>

                                    </div>

                                </div>
                            </Col>
                            <hr></hr>
                            <Col xs={8} className="py-3 ">
                                <div className="d-flex align-items-center">

                                    <div className="flex-1">
                                        <h5 className="fs-0">
                                            <Link
                                                to="/special-credit-db-format"
                                                className="text-900"
                                            >
                                                Special Credit DB Format
                                            </Link>
                                        </h5>

                                    </div>

                                </div>
                            </Col>

                        </Row>


                    </>


                </Card.Body>
            </Card>
        </>
    );
};

export default GenerateFormat;
