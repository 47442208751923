import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import PageHeader from 'components/common/PageHeader'

import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { usePDF } from 'react-to-pdf'
import { useReactToPrint } from 'react-to-print'
import { getDeliveriesScheduleNo, getSchedulesByScheduleNo, getSchedulesByScheduleNoDealerId, getDeliveriesScheduleNoDealerId, getSingleDistributorInfo, getOrdersByDealerId } from 'services/api'
import IconButton from 'components/common/IconButton'
import { useSelector } from 'react-redux'
import SimpleBarReact from 'simplebar-react'

import { useQuery } from 'react-query'
import { formatNumber } from 'helpers/utils'

const formatDate = (dt) => {
    const originalDateString = dt
    const originalDate = new Date(originalDateString)

    const year = originalDate.getFullYear()
    const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
    const day = String(originalDate.getDate()).padStart(2, '0')

    const formattedDateString = `${year}-${month}-${day}`

    return formattedDateString
}

const ScheduleDetails = () => {

    const distributorInfo = useSelector(state => state?.auth?.user?.data);


    const navigate = useNavigate()

    const { scheduleNo, dealerId } = useParams()

    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' })

    // const [allOrdersData, setAllOrdersData] = useState([])

    const isSubset = (subset, set, key) => {
        console.log("subset", subset);
        console.log("set", set);
        console.log("setTruth", subset.every(subItem => set.some(setItem => setItem[key] === subItem)));

        return subset.every(subItem => set.some(setItem => setItem[key] === subItem));
    }



    const {
        data: scheduleData,
        isLoading: isLoadingSchedule,
        isError: scheduleError,
        refetch: scheduleRefetch,
    } = useQuery({
        queryKey: ['schedulesNo'],
        // queryFn: () => getSchedulesByScheduleNo(scheduleNo),
        queryFn: () => getSchedulesByScheduleNoDealerId(scheduleNo, dealerId),

    })

    const {
        data: deliveryData,
        isLoading: isLoadingDeliveryByFactory,
        isError: deliveryByFactoryError,
        refetch: deliveryByFactoryRefetch,
    } = useQuery({
        queryKey: ['deliveriesScheduleNo'],
        // queryFn: () => getDeliveriesScheduleNo(scheduleNo, ),
        queryFn: () => getDeliveriesScheduleNoDealerId(scheduleNo, dealerId),
    })

    // const fetchOrdersData = () => {
    //     getOrdersByDealerId(dealerId)
    //         .then((res) => {
    //             if (res.data?.success) {
    //                 console.log(res.data?.message)
    //                 setAllOrdersData(res.data.payload.result)

    //             }
    //         })
    //         .catch((e) => {
    //             if (e.response?.data?.errors) {
    //                 toast.error(e.response?.data?.errors)
    //             }
    //         })
    // }

    // Function to get unique values for a specific property
    const getUniqueValues = (arr, property) => {
        const values = new Set()

        arr.forEach((obj) => {
            if (obj[property]) {
                values.add(obj[property])
            }
        })

        return [...values]
    }

    const getSum = (order, allDeliveries) => {
        if (!allDeliveries.length) {
            return 0
        }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName &&
                    item.scheduleNo == order.scheduleNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + (obj.dbType == "SPECIAL DISTRIBUTOR" ? obj.delQtyPcs : obj.delQtyCtn)
            }, 0)

        return qtySum
    }

    const IsShowable = (order, allDeliveries) => {
        // if (!allDeliveries.length) {
        //     return true
        // }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName &&
                    item.scheduleNo == order.scheduleNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + (obj.dbType == "SPECIAL DISTRIBUTOR" ? obj.delQtyPcs : obj.delQtyCtn)
            }, 0)

        return qtySum == (order.dbType == "SPECIAL DISTRIBUTOR" ? order.delQtyPcs : order.delQtyCtn)
    }

    const getSkuOrderQuantity = (
        skuName,
        inputArr,
        doNo,
        scheduleNo,
        allDeliveries
    ) => {
        let quantity = 0
        for (let i = 0; i < inputArr.length; i++) {
            const element = inputArr[i]
            if (
                element.skuName == skuName &&
                element.doNo == doNo &&
                element.scheduleNo == scheduleNo
            ) {
                quantity =
                    quantity +
                    parseInt((element.dbType == "SPECIAL DISTRIBUTOR" ? element.delQtyPcs : element.delQtyCtn)) -
                    getSum(element, allDeliveries)
            }
        }

        return quantity
    }

    // useEffect(() => {
    //     fetchOrdersData();
    // }, []);

    function getProductId(skuName, inputArr) {
        let product_id = null

        for (let i = 0; i < inputArr.length; i++) {
            const element = inputArr[i]
            if (element.skuName == skuName) {
                product_id = element.productId
                break
            }
        }

        return product_id
    }

    let distinctSKUNames = []

    let distinctDONos = []

    const outputArr = {}

    let details = null;
    let filteredSchedules = [];
    let hasOffer = false;
    let hasComboOffers = [];
    let totalSum = 0
    let totalSumOffer = 0

    if (
        // allOrdersData.length &&
        scheduleData &&
        scheduleData.data.payload.result.length &&
        deliveryData?.data.payload.result
    ) {
        // console.log('Schedule details data', scheduleData.data.payload.result)

        const allSchedules = scheduleData.data.payload.result
        const allDeliveries = deliveryData.data.payload.result
        filteredSchedules = allSchedules.filter(
            (item) => !IsShowable(item, allDeliveries)
        )

        details = filteredSchedules[0]
        const resArr = filteredSchedules


        hasComboOffers = allSchedules?.filter(offer => offer.offerNo && offer.scheduleNo == scheduleNo && offer.dealerId == dealerId)


        distinctSKUNames = getUniqueValues(resArr, 'skuName')
        distinctDONos = getUniqueValues(resArr, 'doNo')

        for (let i = 0; i < distinctSKUNames.length; i++) {
            const element = distinctSKUNames[i]

            ///inner loop start
            for (let j = 0; j < distinctDONos.length; j++) {
                const inelement = distinctDONos[j]
                const skuNameWithDo = element + '=' + inelement

                const quantity = getSkuOrderQuantity(
                    element,
                    resArr,
                    inelement,
                    scheduleNo,
                    allDeliveries
                )
                outputArr[skuNameWithDo] = quantity
            }
            //inner loop end
        }

        for (let l = 0; l < distinctSKUNames.length; l++) {
            const element = distinctSKUNames[l]
            distinctSKUNames[l] = element + '/' + getProductId(element, resArr)
        }

        {
            Object.values(outputArr).forEach((value) => {
                if (typeof value === 'number') {
                    totalSum += value
                }
            })
        }

        for (const key in outputArr) {
            if (Object.hasOwnProperty.call(outputArr, key)) {
                const element = outputArr[key];
                const offer = filteredSchedules.find(it => it.doNo == key.split('=')[1]).offerSkuName
                console.log("offer", offer);
                if (offer) {
                    hasOffer = true;
                    break;
                }
            }
        }

    }

    // console.log('distinctSKUNames', distinctSKUNames)
    // console.log('distinctDONos', distinctDONos)
    // console.log('outputArr', outputArr)

    // console.log("hasComboOffers", hasComboOffers);


    const {
        data: singleDistributorData,
        isLoading: isLoadingSingleDistributor,
        isError: singleDistributorError,
        refetch: singleDistributorRefetch,
    } = useQuery({
        queryKey: ['singleDistributor'],
        queryFn: () => getSingleDistributorInfo(dealerId),
    })

    const handlePrint = useReactToPrint({
        documentTitle: 'Print This Document',
        onBeforePrint: () => console.log('before printing...'),
        onAfterPrint: () => console.log('after printing...'),
        removeAfterPrint: true,
    })




    return (
        <>
            {/* <PageHeader
                title={'Schedule Report of ' + scheduleNo}
                className="mb-3"
            >
                <Button
                    // onClick={() => {
                    //     handlePrint(null, () => targetRef.current)
                    // }}
                    onClick={() => toPDF()}
                    variant="falcon-default"
                    size="sm"
                    className="mt-2"
                >
                    Print
                </Button>
            </PageHeader> */}
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">Schedule No: {scheduleNo}</h5>
                        </Col>
                        <Col xs="auto">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="arrow-down"
                                className="me-1 mb-2 mb-sm-0"
                                iconClassName="me-1"
                                onClick={() => toPDF()}
                            >
                                Download (.pdf)
                            </IconButton>

                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card ref={targetRef} className="h-lg-100 overflow-hidden">
                <Card.Header>
                    <Row className="align-items-center text-center mb-3">
                        <Col sm={3} className="text-sm-start">
                            <img src={logo} alt="invoice" width={100} />
                        </Col>
                        <Col className="text-sm-start mt-3 mt-sm-0">

                            <h3> T.K Food Products Ltd.</h3>
                            <p className="mb-0">T. K. Bhaban (2nd Floor),
                                13, Kawranbazar Dhaka-1215, Bangladesh</p>
                        </Col>

                        <Col xs={12}>
                            <hr />
                        </Col>

                        <Col xs={12}>
                            <div className='d-flex justify-content-between'>
                                <p>To</p>
                                <div>
                                    <h2>Schedule Report</h2>
                                    <h5 className='text-center'>{'Schedule No: ' + scheduleNo}</h5>
                                    {/* <p className='text-center mb-0'>{details?.regionName}, {details?.zoneName}</p> */}

                                    {/* <p className="mb-0 text-center">{'Schedule No: ' + scheduleNo}</p> */}
                                </div>
                                <p className='mb-0'>
                                    {/* Date: {details?.deliveryDate.split("T")[0]} */}
                                </p>


                            </div>


                            <div className='d-flex justify-content-between my-3'>
                                <div>
                                    <p className="mb-0 text-start">{details?.distributorName || ""}</p>
                                    <p className="mb-0 text-start">Address: {details?.dbPoint}, {details?.areaName}, {details?.regionName}, {details?.zoneName}   </p>
                                    <p className="mb-0 text-start">{'Phone: ' + singleDistributorData?.data?.payload?.distributorData?.phoneNumber || ""}</p>
                                </div>
                                <div>
                                    <p className="mb-0 text-start">{'Delivery Start Date: ' + details?.deliveryDate.split("T")[0]}</p>
                                    <p className="mb-0 text-start">{'Delivery End Date: ' + details?.deliveryDateEnd.split("T")[0]}</p>
                                    {/* <p className="mb-0 text-start">{'Factory Name: ' + details?.factoryName ? details?.factoryName : distributorInfo.distributorName}</p> */}
                                    <p className="mb-0 text-start">Factory Name {details?.factoryName?.length ? details?.factoryName : distributorInfo.distributorName}</p>
                                    {/* <p className="mb-0 text-start">{'Driver Phone: ' + details?.driverPhone}</p> */}
                                </div>
                            </div>



                        </Col>
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="p-0">
                    <SimpleBarReact>
                        <Table borderless className="mb-10 fs--1">
                            <thead className="bg-light">
                                <tr className="text-900">
                                    {/* <th className="text-start">Product Id</th> */}
                                    <th className="text-start">SKU Name</th>

                                    {distinctDONos.map((doNo) => (
                                        <th className="text-start">{doNo}</th>
                                    ))}

                                    <th className="text-start">Total {details?.dbType == "SPECIAL DISTRIBUTOR" ? "PCS" : "CTN"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {distinctSKUNames.map((item) => {
                                    let totalh = 0
                                    return (
                                        <tr className="border-top border-200">
                                            {/* <td className="text-start fw-semi-bold">
                                                {item.split('/')[1]}
                                            </td> */}
                                            <td className="text-start fw-semi-bold">
                                                {item.split('/')[0]}
                                            </td>

                                            {distinctDONos.map((dn) => {
                                                totalh +=
                                                    outputArr[
                                                    item.split('/')[0] +
                                                    '=' +
                                                    dn
                                                    ]
                                                return (
                                                    <td className="align-middle text-start fw-semi-bold">
                                                        {
                                                            outputArr[
                                                            item.split(
                                                                '/'
                                                            )[0] +
                                                            '=' +
                                                            dn
                                                            ]
                                                        }
                                                    </td>
                                                )
                                            })}
                                            <td
                                                style={{ width: '150px' }}
                                                className="align-middle text-start fw-bold"
                                            >
                                                {totalh}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                            <tfoot className="bg-light">
                                <tr className="text-900">
                                    {/* <th className="text-start"></th> */}
                                    <th className="text-start fw-bold">
                                        Total
                                    </th>

                                    {distinctDONos.map((doNo) => {
                                        let otherTotal = 0
                                        for (
                                            let ot = 0;
                                            ot < distinctSKUNames.length;
                                            ot++
                                        ) {
                                            const element = distinctSKUNames[ot]
                                            otherTotal +=
                                                outputArr[
                                                element.split('/')[0] +
                                                '=' +
                                                doNo
                                                ]
                                        }
                                        return (
                                            <th className="text-start fw-bold">
                                                {otherTotal}
                                            </th>
                                        )
                                    })}
                                    <th className="text-start fw-bold">
                                        {totalSum}
                                    </th>
                                </tr>
                            </tfoot>
                        </Table>
                        {(hasOffer || hasComboOffers.length) ? (
                            <Table borderless className="mb-10 fs--1">

                                {/* <thead className="bg-light"> */}
                                <thead className="">
                                    <tr className="text-900">
                                        {/* <th className="text-start">Product Id</th> */}
                                        <th className="text-start">Offer SKU Name</th>

                                        {distinctDONos.map((doNo) => (
                                            <th className="text-start">
                                                {/* <div>
                                                    <p className='fw-bold'>
                                                        {doNo}
                                                    </p>
                                                </div> */}
                                                {doNo}
                                            </th>
                                        ))}

                                        <th className="text-start">
                                            Total PCS
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {distinctSKUNames.map((item) => {
                                        const hasInOffer = filteredSchedules.filter(it => it.offerSkuName &&
                                            it.dealerId == dealerId && it.scheduleNo == scheduleNo &&
                                            it.skuName == item.split('/')[0])

                                        // const hasInOffer= filteredSchedules.filter(it =>
                                        //     it.dealerId==dealerId && it.scheduleNo==scheduleNo && 
                                        //     it.skuName == item.split('/')[0])?.offerSkuName || null
                                        let totalh = 0
                                        console.log("has", hasInOffer);
                                        if (hasInOffer.length) {
                                            return (
                                                <tr className="border-top border-200">

                                                    <td className="text-start fw-semi-bold">
                                                        {/* {filteredSchedules.find(it => it. it.skuName == item.split('/')[0]).offerSkuName} */}
                                                        {/* Offer SKU Name */}
                                                        {filteredSchedules.find(it => it.offerSkuName && it.dealerId == dealerId && it.scheduleNo == scheduleNo && it.skuName == item.split('/')[0])?.offerSkuName}
                                                    </td>

                                                    {distinctDONos.map((dn) => {
                                                        totalh +=
                                                            // outputArr[
                                                            // item.split('/')[0] +
                                                            // '=' +
                                                            // dn
                                                            // ]

                                                            filteredSchedules.find(mt => mt.offerSkuName && mt.doNo == dn && mt.skuName == item.split('/')[0])?.offerQtyPCS || 0
                                                        return (
                                                            <td className="align-middle text-start fw-semi-bold">
                                                                <div
                                                                // style={{ width: '230px' }}
                                                                >
                                                                    {formatNumber(filteredSchedules.find(mt => mt.offerSkuName && mt.doNo == dn && mt.skuName == item.split('/')[0])?.offerQtyPCS || 0)}

                                                                </div>

                                                            </td>
                                                        )
                                                    })}
                                                    <td
                                                        style={{ width: '150px' }}
                                                        className="align-middle text-start fw-bold"
                                                    >
                                                        {totalh}
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    })}

                                    {hasComboOffers?.map(rend => (
                                        <tr>
                                            <td>
                                                <p className='mb-0'>{rend.offerSkuName}</p>
                                                <p>({rend.skuName})</p>
                                            </td>
                                            {/* <td>{rend.dealerId}</td> */}
                                            {/* <td className="text-start">{formatNumber(rend.offerPc) || "--"}</td> */}
                                            {distinctDONos.map((doNo) => {

                                                return (
                                                    <td className="text-start fw-bold">
                                                        {hasComboOffers.find(you => you.doNo == doNo)?.offerQtyPCS || "--"}
                                                    </td>
                                                )


                                            })}


                                            {/* <td>{rend.distributorName}</td>
                                            <td>{rend.offerName}</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td className="text-start">{rend.offerSkuName || "--"}</td> */}
                                            <td className="text-start">{formatNumber(rend.offerQtyPCS) || "--"}</td>
                                            {/* <td>{formatDate(rend.doDate)}</td>
                                            <td>--</td> */}
                                        </tr>
                                    ))}
                                </tbody>

                                {/* <tfoot className="bg-light"> */}
                                <tfoot className="">
                                    <tr className="text-900">
                                        {/* <th className="text-start"></th> */}
                                        <th className="text-start fw-bold">
                                            Total
                                        </th>

                                        {distinctDONos.map((doNo) => {
                                            let otherTotal = 0
                                            for (
                                                let ot = 0;
                                                ot < distinctSKUNames.length;
                                                ot++
                                            ) {
                                                const element = distinctSKUNames[ot]
                                                otherTotal +=
                                                    // outputArr[
                                                    // element.split('/')[0] +
                                                    // '=' +
                                                    // doNo
                                                    // ]

                                                    (filteredSchedules.find(item => item.doNo == doNo && item.skuName == element.split('/')[0])?.offerQtyPCS || 0);


                                            }

                                            totalSumOffer += otherTotal;

                                            return (
                                                <th className="text-start fw-bold">
                                                    {otherTotal + hasComboOffers.filter(it => it.doNo == doNo).reduce((accumulator, currentObj) => {
                                                        return accumulator + currentObj.offerQtyPCS;
                                                    }, 0)}
                                                </th>
                                            )


                                        })}
                                        <th className="text-start fw-bold">
                                            {totalSumOffer + hasComboOffers.reduce((accumulator, currentObj) => {
                                                return accumulator + currentObj.offerQtyPCS;
                                            }, 0)}
                                        </th>
                                    </tr>
                                </tfoot>

                            </Table>
                        ) : null}
                    </SimpleBarReact>
                    <p className='text-end px-5 fw-bold'>Signature</p>
                </Card.Body>
                <Card.Footer className="bg-light py-2">
                    <Row className="flex-between-center">
                        <Col xs="auto"></Col>
                        <Col xs="auto">
                            {/* <Button
                                onClick={handleScheduleSubmit}
                                variant="falcon-default"
                                size="sm"
                            >
                                Submit
                            </Button> */}
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>


            <div>

                <Button variant="primary" className='me-2 px-5 mt-3' onClick={() => navigate(-1)}>Back</Button>
            </div>
        </>
    )
}

export default ScheduleDetails
