import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const DistributionAssignedAreaForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [distributorsList, setDistributorsList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [distributionAssignedAreaById, setDistributionAssignedAreaById] = useState({});
    const { updateID } = useParams();
    const navigate = useNavigate();


    //Get By ID
    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + `distributionassignedareas/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setDistributionAssignedAreaById(response?.data?.data);
                    }
                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
    }, [])


    // load Distributor Data
    useEffect(() => {
        setIsLoading(true)
        const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + "distributors";
        axios.get(url, { headers: authHeader() })
            .then(response => {
                if (response.status === 200) {
                    const modifyDistributor = response?.data?.data?.map(item => {
                        return {
                            id: item.id,
                            label: item.name,
                            value: item.id

                        }
                    })

                    setDistributorsList(modifyDistributor);
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(
                setIsLoading(false)
            )
    }, [])


    // load area Data
    useEffect(() => {
        setIsLoading(true)
        const url = process.env.REACT_APP_AREA_MANAGEMENT_BASE_URL + "areas";
        axios.get(url, { headers: authHeader() })
            .then(response => {
                if (response.data.success || response.data.statusCode === 201 || response.status === 200) {
                    const modifyArea = response?.data?.map(item => {
                        console.log(item)
                        return {
                            id: item.id,
                            label: item.area_name,
                            value: item.id

                        }
                    })

                    setAreaList(modifyArea);
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(
                setIsLoading(false)
            )
    }, [])



    const handleSubmitForm = (values, actions) => {

        const submitData = {
            distributor_id: values.distributor_id.value,
            area_id: values.area_id.value,

        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + "distributionassignedareas";
            axios.post(url, submitData, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/distributionAssignedArea")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + `distributionassignedareas/${updateID}`;
            axios.put(updateUrl, submitData, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/distributionAssignedArea")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const handleSchema = Yup.object().shape({
        distributor_id: Yup.object().required("Distributor is required"),
        area_id: Yup.object().required("Area is required")


    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Distribution Assigned Area" : "Add Distribution Assigned Area"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            distributor_id: distributorsList.find(item => item.id === distributionAssignedAreaById?.distributor_id) || null,
                            area_id: areaList.find(item => item.id === distributionAssignedAreaById?.area_id) || null,


                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Distributors</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributorsList}
                                                placeholder="Select Distributors"
                                                classNamePrefix="react-select"
                                                name="distributor_id"
                                                value={values.distributor_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "distributor_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.distributor_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.distributor_id}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Area</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={areaList}
                                                placeholder="Select Area"
                                                classNamePrefix="react-select"
                                                name="area_id"
                                                value={values.area_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "area_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.area_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.area_id}
                                                    </div>
                                                )}
                                        </Form.Group>



                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button

                                        onClick={() => navigate("/distributionAssignedArea")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default DistributionAssignedAreaForm;


