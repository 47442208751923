import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import Select from 'react-select'
import TransferPanelConfirm from "./TransferPanelConfirm";
import { formatNumber } from 'helpers/utils'

const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '180', // Set your desired width here
    }),
    control: (provided) => ({
        ...provided,
        width: '180px', // Ensure control element also respects the width
    }),
};

const TransferPanel = () => {
    const [step, setStep] = useState(1);
    const [challanNo, setChallanNo] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [count, setCount] = useState(0);
    const [batchNo, setBatchNo] = useState(null);
    const [productsData, setProductsData] = useState([]);
    const [checkedProducts, setCheckProducts] = useState([]);
    const [transportData, setTransportData] = useState({
        transportName: "",
        transportNumber: "",
        driverName: "",
        driverNumber: "",
    });
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const [selectedFactory, setSelectedFactory] = useState(null);



    const transportList = [
        { id: 1, label: "Panocean Limited", value: "Panocean Limited" },
        { id: 2, label: "FedEx World Service Center", value: "FedEx World Service Center" },
        { id: 3, label: "Fashion Transport Agency", value: "Fashion Transport Agency" },
        { id: 4, label: "BD Pack And Shift", value: "BD Pack And Shift" },
    ]


    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchText(value);
    };

    // Add Note
    const handleAddNote = (event, product) => {
        const { value } = event.target;

        setProductsData((prevProducts) =>
            prevProducts.map(productItem =>
                productItem.skuName === product.skuName
                    ? { ...productItem, note: value }
                    : productItem
            )
        );
    }

    // DATE
    const handleDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
    };


    const formatDecimal = (value) => {
        return parseFloat(value).toFixed(2);
    };



    const handleReceivedProducts = (event, index, product, fieldName) => {
        const { value } = event.target;
        const calculatedPcs = value * product?.pcsCTN;
        const totalWeight = calculatedPcs * product?.weightPCS || 0;
        const calculateWeight = totalWeight / 1000000;


        setProductsData((prevProducts) =>
            prevProducts.map(productItem =>
                productItem.skuName === product.skuName
                    ? { ...productItem, transferCTN: Number(value), transferPCS: Number(calculatedPcs), transferWeightMT: Number(calculateWeight) }
                    : productItem
            )
        );
    };


    // RECEIVED PRODUCTS
    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?search=${searchText}`;
        //         const response = await axios.get(url, { headers: authHeader() });
        //         console.log(response)
        //         if (response.data.success || response.status === 200) {
        //             const exactMatchProducts = await response?.data?.payload?.products
        //                 ?.filter(product =>
        //                     product?.factoryName?.every(factory =>
        //                         factory === distributorInfo?.factoryName
        //                     )
        //                 )

        //             setProductsData(exactMatchProducts);
        //         }
        //     } catch (error) {
        //         handleApiResponseErrors(error.response);
        //     } finally {
        //         setIsLoading(false);
        //     }
        // };



        const fetchData = async () => {
            let url
            let url2
            let url3
            if (distributorInfo?.userRole === 'Admin') {
                // url = `${"process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL"}productsRcv?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${distributorInfo.factoryName}`
                url2 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${distributorInfo.factoryName}`
            }
            if (distributorInfo?.userRole === 'Inventory') {
                // url = searchText !== "" ? `${"process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL"}productsRcv?factoryName=${distributorInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${"process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL"}productsRcv?factoryName=${distributorInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                url =
                    searchText !== ''
                        ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${distributorInfo.factoryName}`
                        : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${distributorInfo.factoryName}`
                url2 =
                    searchText !== ''
                        ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${distributorInfo.factoryName}`
                        : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${distributorInfo.factoryName}`

                url3 =
                    searchText !== ''
                        ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${distributorInfo.factoryName}`
                        : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${distributorInfo.factoryName}`
            }

            try {
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {


                    let result = response?.data?.payload?.result.map((item) => {
                        const stockWeightMT = item.rcvQtyMt
                        return {
                            ...item,
                            pcsCTN: item.rcvQtyPCS / item.rcvQtyCTN,
                            stockCtn: item.rcvQtyCTN,
                            stockPcs: item.rcvQtyPCS,
                            stockWeightMT: stockWeightMT,
                            weightPCS: (stockWeightMT / item.rcvQtyPCS) * 1000000,
                            transferCtn: 0,
                        }
                    })


                    setProductsData(result)


                    const response2 = await axios.get(url2, {
                        headers: authHeader(),
                    })

                    if (response2.data.success || response2.status === 200) {

                        const result2 = response2?.data?.payload?.result


                        const modifiedResult = result.map((item) => {

                            const delivery = result2.find(
                                (my) => my.skuName == item.skuName
                            )

                            const stockWeightMT = item.rcvQtyMt - (delivery?.delQtyMt || 0)
                            const stockPcs = item.rcvQtyPCS - (delivery?.delQtyPcs || 0)
                            return {
                                ...item,
                                pcsCTN: item.rcvQtyPCS / item.rcvQtyCTN,
                                stockCtn: item.rcvQtyCTN - (delivery?.delQtyCtn || 0),
                                stockPcs: stockPcs,
                                stockWeightMT: stockWeightMT,
                                weightPCS: (item.rcvQtyMt / item.rcvQtyPCS) * 1000000,
                                transferCtn: 0,
                            }
                        })

                        result = modifiedResult

                        setProductsData(modifiedResult)
                    }

                    // const response3 = await axios.get(url3, {
                    //     headers: authHeader(),
                    // })

                    // if (response3.data.success || response3.status === 200) {
                    //     const result3 = response3?.data?.payload?.result

                    //     const modifiedResult = result.map((item) => {

                    //         const transfer = result3.find(
                    //             (my) => my.skuName == item.skuName
                    //         )

                    //         return {
                    //             ...item,
                    //             // pcsCTN: item.rcvQtyPCS / item.rcvQtyCTN,
                    //             stockCtn: item.stockCtn - (transfer?.rcvQtyCTN || 0),
                    //             stockPcs: item.stockPcs - (transfer?.rcvQtyPCS || 0),
                    //             stockWeightMT: item.stockWeightMT - (transfer?.rcvQtyMt || 0),
                    //             // weightPCS: (stockWeightMT / item.rcvQtyPCS) * 1000000,
                    //             transferCtn: 0,
                    //         }
                    //     })


                    //     setProductsData(modifiedResult)
                    // }
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }

        fetchData();

    }, [count]);


    useEffect(() => {
        let prefix = ''

        if (distributorInfo?.factoryName === 'SORL') {
            prefix = 'SRL'
        } else if (distributorInfo?.factoryName === 'SVOIL') {
            prefix = 'SVL'
        } else if (distributorInfo?.factoryName === 'Narshingdi') {
            prefix = 'NAR'
        } else if (distributorInfo?.factoryName === 'Dhamrai') {
            prefix = 'DHM'
        }
        else if (distributorInfo?.factoryName === 'Dhaka Central Depot') {
            prefix = 'DCD'
        }
        else if (distributorInfo?.factoryName === 'Ctg Depot') {
            prefix = 'CTD'
        }
        else if (distributorInfo?.factoryName === 'Noakhali Depot') {
            prefix = 'NHD'
        }
        else if (distributorInfo?.factoryName === 'Sylhet Depot') {

            prefix = 'SLD'
        }
        else if (distributorInfo?.factoryName === 'Jessore Depot') {
            prefix = 'JSD'
        }
        else if (distributorInfo?.factoryName === 'Mymensingh Depot') {
            prefix = 'MSD'
        }
        else if (distributorInfo?.factoryName === 'Bogra Depot') {
            prefix = 'BGD'
        }
        else if (distributorInfo?.factoryName === 'Barisal Depot') {
            prefix = 'BSD'
        }
        else if (distributorInfo?.factoryName === 'Tekerhat Depot') {
            prefix = 'TKD'
        }
        else if (distributorInfo?.factoryName === 'Rangpur Depot') {
            prefix = 'RPD'
        }

        const challan = prefix + '-' + Math.floor(Math.random() * 1000000000)
        setChallanNo(challan)
    }, [])

    // Filter products based on search term
    const filteredProducts = productsData?.filter(product =>
        product?.skuName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        product?.erpId?.toLowerCase().includes(searchText?.toLowerCase())
    );



    const handleNext = () => {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select a date",
                icon: "warning"
            });
            return;
        }
        if (selectedFactory === null) {
            Swal.fire({
                title: "Please select a factory",
                icon: "warning"
            });
            return;
        }


        const checkedProducts = productsData.filter((product) => product.transferCTN && product.transferPCS);
        if (checkedProducts?.length > 0) {
            setCheckProducts(checkedProducts);
            setStep(2);

        } else {
            Swal.fire({
                title: "Please fill the quantity",
                icon: "warning"
            });
            return;
        }
    }



    if (isLoading) {
        return <LoadingIcon />;
    }



    const factoryList = [

        {
            label: "SORL",
            value: "SORL"
        },
        {
            label: "SVOIL",
            value: "SVOIL"
        },
        {
            label: "Narshingdi",
            value: "Narshingdi"
        },
        {
            label: "Dhamrai",
            value: "Dhamrai"
        },
        {
            label: "Dhaka Central Depot",
            value: "Dhaka Central Depot"
        },
        {
            label: "Ctg Depot",
            value: "Ctg Depot"
        },
        {
            label: "Noakhali Depot",
            value: "Noakhali Depot"
        },
        {
            label: "Sylhet Depot",
            value: "Sylhet Depot"
        },
        {
            label: "Jessore Depot",
            value: "Jessore Depot"
        },
        {
            label: "Mymensingh Depot",
            value: "Mymensingh Depot"
        },
        {
            label: "Bogra Depot",
            value: "Bogra Depot"
        },
        {
            label: "Barisal Depot",
            value: "Barisal Depot"
        },
        {
            label: "Tekerhat Depot",
            value: "Tekerhat Depot"
        },
        {
            label: "Rangpur Depot",
            value: "Rangpur Depot"
        },
    ]


    return (
        <>
            <PageHeader
                // Transfer to: ${selectedFactory?.label || ""}
                title={`Challan No: ${challanNo}`}

                className="mb-3"
            ></PageHeader>

            {step === 1 && <><Card className="mb-3">
                <Card.Header>
                    <div className="">
                        {/* <CustomersTableHeader isNew={false} isExport={false} isSearch={true} setSearchText={setSearchText} /> */}
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Search By Sku/ERP ID :</h6>
                                <input type="search" className="form-control" placeholder="Search By Sku/ERP ID" onChange={(e) => handleSearch(e)} />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Factory/Depot :</h6>
                                <Select

                                    closeMenuOnSelect={true}
                                    options={factoryList}
                                    placeholder="Select"
                                    // isMulti
                                    classNamePrefix="react-select"
                                    value={selectedFactory}
                                    onChange={(value) =>
                                        setSelectedFactory(value)
                                    }
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transport :</h6>
                                <Select

                                    closeMenuOnSelect={true}
                                    options={transportList}
                                    placeholder="Select Transport"
                                    // isMulti
                                    classNamePrefix="react-select"
                                    value={transportData?.transportName}
                                    onChange={(value) =>
                                        setTransportData({ ...transportData, transportName: value })
                                    }
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Vehicle No. :</h6>
                                <Form.Control type="text" placeholder="Enter vehicle No." onChange={(event) => setTransportData({ ...transportData, transportNumber: event.target.value })}
                                    value={transportData?.transportNumber}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Name:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Name" onChange={(event) => setTransportData({ ...transportData, driverName: event.target.value })}
                                    value={transportData?.driverName}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Number:</h6>
                                <Form.Control type="number" placeholder="Enter Driver Number" onChange={(event) => setTransportData({ ...transportData, driverNumber: event.target.value })}
                                    value={transportData?.driverNumber}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transfer Date :</h6>
                                <input type="date" className="form-control" placeholder="Transfer Date :" onChange={(e) => handleDateChange(e)}
                                    value={selectedDate}
                                />
                            </Form.Group>
                        </Row>

                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Sub Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th className="align-middle white-space-nowrap text-center ">ERP ID</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Available Stock Qty (CTN)</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Available Stock Qty (PCS)</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Available Stock Qty {`Weight(MT)`}</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Transfer Qty (CTN)</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Transfer Qty (PCS)</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Transfer Qty {`Weight(MT)`}</th>
                                        <th colSpan="2" className="align-middle white-space-nowrap text-center ">Note</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        filteredProducts?.map((product, index) => {
                                            console.log(product)
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.productCategory}</td>
                                                <td className="align-middle text-center">{product?.subCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">{product?.erpId}</td>
                                                <td className="align-middle text-center">{formatNumber(product?.stockCtn)}</td>
                                                <td className="align-middle text-center">{formatNumber(product?.stockPcs)}</td>
                                                <td className="align-middle text-center">{formatNumber(product?.stockWeightMT)}</td>
                                                <td className="align-middle text-center">
                                                    <Form.Control type="text" placeholder="" onChange={(event) => handleReceivedProducts(event, index, product, "ctn")}
                                                        value={product && product?.transferCTN !== undefined ? product?.transferCTN : ""} />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product && product.transferPCS !== undefined ? product.transferPCS : ""}

                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product && product.transferWeightMT !== undefined ? formatNumber(product.transferWeightMT) : ""}
                                                        readOnly
                                                    />

                                                </td>

                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        as={"textarea"}
                                                        type="text"
                                                        style={{ width: "150px" }}
                                                        placeholder="Enter Note"
                                                        value={product && product.note !== undefined ? product.note : ""}
                                                        onChange={(event) => handleAddNote(event, product)}
                                                    />

                                                </td>



                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
                <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleNext(); }}>
                    Transfer
                </Button></>}

            {
                step === 2 && <TransferPanelConfirm setStep={setStep} challanNo={challanNo} selectedDate={selectedDate} batchNo={batchNo} transportData={transportData} checkedProducts={checkedProducts} factoryName={selectedFactory.value} setCheckProducts={setCheckProducts} setCount={setCount} />
            }

        </>
    );

};

export default TransferPanel;
