import axios from 'axios'
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Target from "./TargetFiles/index";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import Swal from 'sweetalert2'
import Select from "react-select";
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardHeader from 'components/common/FalconCardHeader';



const index = () => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [allDbPoint, setAllDbPoint] = useState([]);
    const [selectedDbPoint, setSelectedDbPoint] = useState(null);
    const [allDistributor, setAllDistributor] = useState([]);
    const [selectedDb, setSelectedDb] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([]);
    const [targetAndAchievement, setTargetAndAchievement] = useState({});
    const { areaName } = useSelector(state => state.auth.user.data);

    // db points list
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${areaName}`;
                const response = await axios.get(url, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.dbPointName,
                        value: data.dbPointName
                    }));
                    setAllDbPoint(modifyData);
                }
            }
            catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        if (selectedDbPoint) {
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?dbPoint=${selectedDbPoint}`
                    const response = await axios.get(url, { headers: authHeader() });
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.distributors.map(data => ({
                            id: data.userId,
                            label: data.distributorName,
                            value: data.userId
                        }));
                        setAllDistributor(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
            fetchData();
        }

    }, [selectedDbPoint])

    const handleSearch = () => {
        if (!selectedMonth) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a month!',
            });
        }
        if (!selectedYear) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a year!',
            });
        }
        if (!selectedYear) return alert("Please select a year");
        if (selectedMonth && selectedYear) {
            const month = selectedMonth.getMonth() + 1;
            const year = selectedYear.getFullYear();

            setIsLoading(true);
            const fetchData = async () => {
                let targetUrl;
                let ordersUrl;
                if (selectedDbPoint && !selectedDb) {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBPointWiseOrderTarget/${selectedDbPoint}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBPointWiseOrdersVsTarget/${selectedDbPoint}/${year}/${month}`;
                } else if (selectedDbPoint && selectedDb) {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrderTarget/${selectedDb}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrdersVsTarget/${selectedDb}/${year}/${month}`;
                } else {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/AreaWiseOrderTarget/${areaName}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/AreaWiseOrdersVsTarget/${areaName}/${year}/${month}`;
                }
                try {
                    const [targetResponse, ordersResponse] = await Promise.all([
                        axios.get(targetUrl, { headers: authHeader() }),
                        axios.get(ordersUrl, { headers: authHeader() })
                    ]);
                    console.log("Target", targetResponse, "Order", ordersResponse);
                    if ((targetResponse.data.success || targetResponse.status === 200) &&
                        (ordersResponse.data.success || ordersResponse.status === 200)) {

                        const targetData = targetResponse.data.payload.targetInfo;
                        const orderVsTarget = ordersResponse.data.payload.orderVsTarget;
                        let totalTargetCnt = 0;
                        let totalAchievementCnt = 0;
                        let totalAchievementAmount = 0;
                        const combinedData = targetData.map(targetItem => {
                            totalTargetCnt += targetItem.targetQuantityCtn;
                            const order = orderVsTarget.find(orderItem => orderItem.skuName === targetItem.skuName);
                            if (order) {
                                totalAchievementCnt += order.orderQtyCtn;
                                totalAchievementAmount += parseFloat(order.orderQtyCtn) * parseFloat(targetItem.ctnPrice);
                            }
                            return order ? { ...targetItem, ...order } : targetItem;
                        });

                        setOrders(combinedData);
                        setTargetAndAchievement({
                            totalTargetCnt,
                            totalAchievementCnt,
                            totalAchievementAmount
                        });
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }

    if (isLoading) {
        return <LoadingIcon />
    }
    return (
        <>
            <FalconComponentCard>
                <FalconCardHeader title="Target Vs Achievement Monthly Report" />
                <FalconComponentCard.Body>
                    <Row className="mb-2">
                        <Col md="4" >
                            <Form.Label>Select Area: </Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <Form.Control type="text" value={areaName} readOnly />
                            </Form.Group>
                        </Col>

                        <Col md="4" >
                            <Form.Label>Select DB Point: </Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allDbPoint}
                                    placeholder="Select DB Point"
                                    classNamePrefix="react-select"
                                    name="dbPoint"
                                    value={allDbPoint.find(option => option.value === selectedDbPoint)}
                                    onChange={(selectedOption) => {
                                        setSelectedDbPoint(selectedOption.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col md="4">
                            <Form.Label>Select DB:</Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <Select
                                    closeMenuOnSelect={true}
                                    options={allDistributor}
                                    placeholder="Select DB"
                                    classNamePrefix="react-select"
                                    name="dbName"
                                    value={allDistributor.find(option => option.value === selectedDb)}
                                    onChange={(selectedOption) => {
                                        setSelectedDb(selectedOption.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" >
                            <Form.Label>Select Month:  </Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <DatePicker
                                    selected={selectedMonth}
                                    onChange={(date) => setSelectedMonth(date)}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    placeholderText="Select Month"
                                    className="form-control w-100"
                                    width="100%"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4">
                            <Form.Label>Select Year:  </Form.Label>
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <DatePicker
                                    selected={selectedYear}
                                    onChange={(date) => setSelectedYear(date)}
                                    dateFormat="yyyy"
                                    showYearPicker
                                    placeholderText="Select Year"
                                    className="form-control"
                                    style={{ width: '100%' }} // Ensures full width
                                />
                            </Form.Group>
                        </Col>
                        <Col md="4" className="d-flex align-items-center mt-md-0 mt-3 text-md-start text-center">
                            <Form.Group controlId="exampleFirstName" className="flex-grow-1">
                                <button className="btn btn-primary px-5 ms-3" onClick={() => handleSearch()}>Search</button>
                            </Form.Group>
                        </Col>
                    </Row>
                </FalconComponentCard.Body>
            </FalconComponentCard>
            <Row className="g-3 my-3">
                <Target orders={orders} targetAndAchievement={targetAndAchievement} />
            </Row>

        </>

    )
}
export default index;