import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import TransferReceivedPanelConfirm from "./TransferReceivedPanelConfirm";
import { useParams } from "react-router-dom";

const TransferReceivedPanel = () => {
    const [step, setStep] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedExpiryDate, setSelecteExpitydDate] = useState(null);
    const [count, setCount] = useState(0);
    const [batchNo, setBatchNo] = useState(null);
    const [allProducts, setAllProducts] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [checkedProducts, setCheckProducts] = useState([]);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const { challanNo } = useParams();

    // DATE
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // DATE
    const handleExpityDateChange = (date) => {
        setSelecteExpitydDate(date);
    };



    const handleReceivedProducts = (event, index, product, fieldName) => {
        let { value } = event.target;
        if (+value > product.transQtyCTN) {
            value = product.transQtyCTN
        } else if (+value < 0) {
            value = 0
        } else {
            value = +value
        }

        const updatedProducts = [...productsData];
        const findProduct = allProducts.find((item) => item.skuName === product.skuName);

        // Depending on the fieldName, perform different actions
        switch (fieldName) {
            case 'ctn':
                const calculatedPcs = +value * findProduct?.pcsCTN;
                const totalWeight = calculatedPcs * findProduct?.weightPCS || 0;
                const calculateWeight = totalWeight / 1000000;
                updatedProducts[index].ctn = +value;
                updatedProducts[index].pcs = calculatedPcs;
                updatedProducts[index].weightMT = calculateWeight;
                updatedProducts[index].erpId = findProduct?.erpId;
                updatedProducts[index].pcsCTN = findProduct?.pcsCTN;
                updatedProducts[index].tranStatus = updatedProducts[index].transQtyCTN !== value ? 1 : 2;
                break;
            case 'pcs':
                updatedProducts[index].pcs = +value;
                updatedProducts[index].tranStatus = updatedProducts[index].transQtyCTN !== value ? 1 : 2;
                break;
            default:
                break;
        }

        setProductsData(updatedProducts);
    };


    // Fetch products
    useEffect(() => {
        const fetchData = async () => {
            // setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.status === 200) {
                    const responseData = response.data.payload.products;
                    setAllProducts(responseData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    //format to two decimals
    const formatDecimal = (value) => {
        return parseFloat(value).toFixed(2);
    };


    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            if (!challanNo) return;
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductionRcvListDetailsTransByChallan/${challanNo}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const responseData = response.data.payload.prdInfoDetails;

                    setProductsData(responseData.map((product) => ({ ...product, pcsCTN: product.transQtyPCS / product.transQtyCTN, ctn: product.transQtyCTN, pcs: product.transQtyPCS, weightMT: formatDecimal(product.transQtyMt), tranStatus: 2 })));
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [challanNo]);


    const handleNext = () => {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select a date",
                icon: "warning"
            });
            return;
        }

        const checkedProducts = productsData.filter((product) => product.ctn && product.pcs);
        if (checkedProducts?.length > 0) {
            setCheckProducts(checkedProducts);
            setStep(2);

        } else {
            Swal.fire({
                title: "Please fill the quantity",
                icon: "warning"
            });
            return;
        }
    }



    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>
            <PageHeader
                title="Transfer Received Panel"
                className="mb-3"
            ></PageHeader>

            {step === 1 && <><Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader isNew={false} isExport={false} isSearch={false} />
                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Batch No. :</h6>
                                <Form.Control type="text" placeholder="Enter Batch No." value={productsData[0]?.batchNo} disabled />
                            </Col>
                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transfer Date :</h6>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder=""
                                    value={productsData[0]?.transDate ? new Date(productsData[0].transDate).toISOString().split('T')[0] : ''}
                                    disabled
                                />
                            </Col>

                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Receive Date :</h6>
                                <input type="date" className="form-control" onChange={(event) => handleDateChange(event.target.value)} />
                            </Col>
                        </Row>

                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Tr. Rvd. QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Tr. Rvd. QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Tr. Rvd. Weight(MT)`}</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Con. QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Con. QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        productsData?.map((product, index) => {
                                            console.log(product)
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.productCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">{product?.transQtyCTN}</td>
                                                <td className="align-middle text-center">{product?.transQtyPCS}</td>
                                                <td className="align-middle text-center">{formatDecimal(product?.transQtyMt)}</td>
                                                <td className="align-middle text-center">
                                                    <Form.Control type="text" placeholder="" value={product?.transQtyCTN} onChange={(event) => handleReceivedProducts(event, index, product, "ctn")} />
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product && product.pcs !== undefined ? product.pcs : ""}
                                                        // onChange={(event) => handleReceivedProducts(event, index, product, "pcs")}
                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product && product.weightMT !== undefined ? product.weightMT : ""}
                                                        readOnly
                                                    />

                                                </td>

                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
                <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleNext(); }}>
                    Received
                </Button></>}

            {
                step === 2 && <TransferReceivedPanelConfirm setStep={setStep} productsData={productsData} selectedDate={selectedDate} checkedProducts={checkedProducts} setCheckProducts={setCheckProducts} setCount={setCount} challanNo={challanNo} />
            }

        </>
    );

};

export default TransferReceivedPanel;
