import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
// import { Link } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { IoIosCheckboxOutline } from "react-icons/io";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons'; // Example icon for pending status
import StatusCell from "components/common/StatusCell";
import CollapseOrder from "../AccountAndFinance/Accounts/ConfirmOrders/CollapseOrder"
import { formatNumber } from "helpers/utils";

const DoPendingOrders = () => {
    const [distributorOrderList, setDistributorOrderList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [expand, setExpand] = useState(false)
    const [count, setCount] = useState(0);
    const [checkedOrders, setCheckedOrders] = useState([]);
    const userInfo = useSelector(state => state?.auth?.user?.data);
    const navigate = useNavigate();




    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

        let url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/authorize?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    url,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.result;
                    const filterData = result.filter((order) => !order.data[0].superDBId ||!order.data[0].superDBId === null ||!order.data[0].superDBId === 0);
                    const totalPages = response?.data?.payload?.pagination?.totalPages;

                    setTotalPages(totalPages)
                    setDistributorOrderList(filterData);
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount, searchText]);

    const handleCheckAll = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setCheckedOrders(distributorOrderList);
        } else {
            setCheckedOrders([]);
        }
    }

    // Handle checkbox change
    const handleCheckboxChange = (order) => {
        if (isChecked(order)) {
            setCheckedOrders(checkedOrders.filter(p => p.doNo !== order.doNo));
        } else {
            setCheckedOrders([...checkedOrders, order]);
        }
    };

    // Check if a product is checked
    const isChecked = (order) => {
        return checkedOrders.some(p => p.doNo === order.doNo);
    };


    const handleCancelOrder = async (order, userInfo) => {
        const orderItems = order?.data?.map((or) => {
            return {
                _id: or._id,
                status: 1,
                checkedId: userInfo?.userId,
                cancelNote: '' // Placeholder for the reason, you can modify this as needed
            };
        }) ?? [];

        try {
            const { value: cancelNote } = await Swal.fire({
                title: 'Reason for cancellation',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                preConfirm: async (cancelNote) => {
                    try {
                        const updatedOrderItems = orderItems.map(item => ({ ...item, cancelNote }));
                        const response = await axios.put(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`, updatedOrderItems, { headers: authHeader() });

                        if (response.data.success) {
                            return true;
                        } else {
                            throw new Error(response.data.message);
                        }
                    } catch (error) {
                        throw new Error(`Request failed: ${error}`);
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            });

            if (cancelNote) {
                setCount(count + 1);
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Order has been successfully cancelled.'
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.message
            });
        }
    };

    if (isLoading) {
        return <LoadingIcon />;
    }



    // Handle form submission
    const handleSubmit = async () => {
        const orderItems = checkedOrders.flatMap((order) => {
            return order?.data?.map((or) => ({
                _id: or._id,
                status: 5,
                checkedId: userInfo?.userId,
            })) ?? [];
        });

        // Check if there are items to update
        if (orderItems.length === 0) {
            // No items to update, show a message or return early
            return;
        }

        // Make a PUT request to update the order items
        try {
            setIsLoading(true);
            const response = await axios.put(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                orderItems,
                { headers: authHeader() }
            );

            // Check if the update was successful
            if (response.data.status || response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Orders have been successfully Forward to Order Management.",
                });
                setCheckedOrders([]);
                setCount((prevCount) => prevCount + 1); // Update count
            } else {
                // Handle unexpected response
                throw new Error("Unexpected response from server");
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditOrder = (order) => {
        const isPcs = order?.data?.[0]?.unitName === "PCS";
        if (isPcs) {
            navigate(`/master/special-distributor-orders-update-other/${order?.doNo}`);
        } else {
            navigate(`/master/db-order-update-do/${order?.doNo}`);
        }
    }

    const expandHandler = (order) => {
        const orders = distributorOrderList
        orders.forEach((element) => {
            element.open = false
        })
        const index = orders.findIndex((item) => item.doNo == order.doNo)
        orders[index].open = true
        setDistributorOrderList(orders)
        setExpand(!expand)
    }

    return (
        <>
            <PageHeader
                title={`Pending orders list`}
                className="mb-3"
            ></PageHeader>

            <Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader
                            searchText={searchText}
                            setSearchText={setSearchText}
                            isNew={false}
                            isExport={false}
                            isSearch={true}
                            
                        />
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            {distributorOrderList?.length > 0 ? (
                                <table className="table table-bordered table-striped fs--1 mb-0">
                                    <thead>
                                        <tr
                                            style={{
                                                fontSize: 'medium',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#354151',
                                            }}
                                        >
                                            <th className="align-middle white-space-nowrap text-center ">
                                                ...
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        checkedOrders?.length ===
                                                        distributorOrderList?.length
                                                    }
                                                    onChange={(e) =>
                                                        handleCheckAll(e)
                                                    }
                                                />
                                            </th>

                                            <th className="align-middle white-space-nowrap text-center ">
                                                DO-No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                DB ID
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                DB Name
                                            </th>

                                            <th className="align-middle white-space-nowrap text-center ">
                                                DB Point
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Order Qty Ctn
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Order Qty PCS
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Discount BDT
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Price
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Status
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        className="list"
                                        id="table-purchase-body"
                                    >
                                        {distributorOrderList?.map(
                                            (order, index) => {
                                                return (
                                                    <>
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                fontSize:
                                                                    'medium',
                                                                fontWeight:
                                                                    '500',
                                                                fontFamily:
                                                                    'Poppins',
                                                                color: '#354151',
                                                            }}
                                                        >
                                                            <td className="align-middle text-center border-0">
                                                                <Button
                                                                    onClick={() =>
                                                                        expandHandler(
                                                                            order
                                                                        )
                                                                    }
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={
                                                                        order.open
                                                                    }
                                                                    variant="falcon-primary"
                                                                    className="custom-button" // Apply the custom class
                                                                >
                                                                    {order.open &&
                                                                        expand
                                                                        ? '-'
                                                                        : '+'}
                                                                </Button>
                                                            </td>
                                                            <td className="align-middle text-center">

                                                                <input
                                                                    type="checkbox"
                                                                    checked={isChecked(
                                                                        order
                                                                    )}
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            order
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {order?.doNo}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    order?.dealerId
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    order?.distributorName
                                                                }
                                                            </td>

                                                            <td className="align-middle text-center">
                                                                {order?.dbPoint}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                   formatNumber(order?.totalOrderQtyCtn) 
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    formatNumber(order?.totalOrderQtyPCS) 
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                     formatNumber(order?.totalDiscountBDT)
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    formatNumber(order?.totalPrice) 
                                                                }
                                                            </td>
                                                            <td
                                                                className="align-middle text-center"
                                                                style={{
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <StatusCell
                                                                    status={
                                                                        order?.status
                                                                    }
                                                                />
                                                            </td>

                                                            <td>
                                                                <Dropdown className="d-inline mx-2">
                                                                    <Dropdown.Toggle
                                                                        id="dropdown-autoclose-true"
                                                                        className=" bg-none"
                                                                    >
                                                                        ...
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item>
                                                                            <Link
                                                                                to={`/master/distributor-orders-view/${order?.doNo}`}
                                                                            >
                                                                                <IconButton
                                                                                    variant="falcon-default"
                                                                                    size="sm"
                                                                                    icon="edit"
                                                                                    transform="shrink-2"
                                                                                    iconAlign="middle"
                                                                                    className="me-2"
                                                                                >
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        View
                                                                                        details
                                                                                    </span>
                                                                                </IconButton>
                                                                            </Link>
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item>
                                                                            <IconButton
                                                                                variant="falcon-default"
                                                                                size="sm"
                                                                                icon="edit"
                                                                                transform="shrink-2"
                                                                                iconAlign="middle"
                                                                                className="me-2"
                                                                                onClick={() =>
                                                                                    handleEditOrder(
                                                                                        order
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    Edit
                                                                                </span>
                                                                            </IconButton>
                                                                        </Dropdown.Item>

                                                                        <Dropdown.Item>
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handleCancelOrder(
                                                                                        order
                                                                                    )
                                                                                }
                                                                                variant="falcon-default"
                                                                                size="sm"
                                                                                icon="trash-alt"
                                                                                iconAlign="middle"
                                                                                className="d-none d-sm-block me-2"
                                                                            >
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    Cancel
                                                                                </span>
                                                                            </IconButton>
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            style={{
                                                                display:
                                                                    order.open &&
                                                                        expand
                                                                        ? 'table-row'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            <td colSpan={15}>
                                                                <div className="p-0">
                                                                    <CollapseOrder doNo={order?.doNo}></CollapseOrder>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center p-4">
                                    <h5>No Orders Found</h5>
                                </div>
                            )}
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        table
                        paginationPageCount={paginationPageCount}
                        setPaginationPageCount={setPaginationPageCount}
                        pageDataCount={pageDataCount}
                        setPageDataCount={setPageDataCount}
                        totalPages={totalPages}
                    />
                </Card.Footer>
            </Card>


            <Button className="mt-2 px-5" variant="primary" type="submit" disabled={checkedOrders?.length === 0} onClick={() => { handleSubmit(); }}>
                Forward to Order Management
            </Button>
        </>
    );

};

export default DoPendingOrders;
