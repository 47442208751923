import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import SorlDeliveryFilter from 'components/common/DeliveryFilter/SorlDeliveryFilter'
import Flex from 'components/common/Flex'
import PageHeader from 'components/common/PageHeader'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import { formatNumber } from 'helpers/utils'
import React, { useState } from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    getDeliveriesByFactory,
    getDeliveriesGroupbyFactory,
} from 'services/api'
import Swal from 'sweetalert2'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import axios from 'axios'
import { authHeader } from 'utils'

const DistrbutorStockFilter = ({ user }) => {
    const [selectedZone, setSelectedZone] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('')
    const [selectedArea, setSelectedArea] = useState('')
    const [selectedDbPoint, setSelectedDbPoint] = useState('')
    const [selectedDb, setSelectedDb] = useState(null)
    const [selectedDoNo, setSelectedDoNo] = useState('')
    const [selectedChallanNo, setSelectedChallanNo] = useState('')
    const [selectedScheduleNo, setSelectedScheduleNo] = useState('')
    const [selectedFactory, setSelectedFactory] = useState('')
    const [selectedSuperDB, setSelectedSuperDB] = useState(null)
    
    const [selectedDateFrom, setSelectedDateFrom] = useState('')
    const [selectedDateTo, setSelectedDateTo] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [deliveredOrders, setDeliveredOrders] = useState([])

    const formateExcelData = (data) => {
        const formatDate = (date) => {
            return date ? new Date(date).toLocaleDateString('en-US') : ''
        }

        const formateData = data?.map((item, index) => ({
            Sl: index + 1,

            'Challan No': item.challanNo,
            'Do No': item.doNo,
            'Delivery Qty CTN': item.delQtyCtn,
            'Delivery Qty PCS': item.delQtyPcs,
            'Offer Qty PCS': item.offerQtyPCS,
            'Schedule No': item.scheduleNo,
            'Factory Name': item?.factoryName || "SDB("+item?.superDBId+")",
            'Dealer Id': item.dealerId,
            'Distributor Name': item.distributorName,
            'Zone Name': item.zoneName,
            'Region Name': item.regionName,
            'Area Name': item.areaName,
            'Db Point': item.dbPoint,
            'Transport Name': item.transportName,
            'Driver Name': item.driverName,
            'Driver Phone': item.driverPhone,
            'Delivery Date': formatDate(item.deliveryDate),

        }))

        return formateData
    }

    // data search
    const handleSearch = () => {
        if (
            selectedZone &&
            selectedRegion &&
            selectedArea &&
            selectedDbPoint &&
            !selectedDateFrom &&
            !selectedDateTo
        ) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (
            selectedZone &&
            selectedRegion &&
            selectedArea &&
            !selectedDateFrom &&
            !selectedDateTo
        ) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (
            selectedZone &&
            selectedRegion &&
            !selectedDateFrom &&
            !selectedDateTo
        ) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (selectedZone && !selectedDateFrom && !selectedDateTo) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (selectedDoNo) {
        }

        const formatData = {
            zoneName: selectedZone,
            regionName: selectedRegion,
            areaName: selectedArea,
            dbPoint: selectedDbPoint,
            dealerId: selectedDb?.id,
            doNo: selectedDoNo,
            challanNo: selectedChallanNo,
            scheduleNo: selectedScheduleNo,
            factoryName: selectedFactory,
            deliDateFrom: new Date(selectedDateFrom),
            deliDateTo: new Date(selectedDateTo),
            superDBId: selectedSuperDB?.value || ""
        }

        const fetchData = async () => {
            try {
                // setIsLoading(true)
                const url = `https://khoz.net:3013/api/v1/orderDelivery/OrderDeliverySearch`
                const response = await axios.post(url, formatData, {
                    headers: authHeader(),
                })

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.deliveryInfo
                    const addIndex = data?.map((item, index) => {
                        return {
                            index,
                            ...item,
                        }
                    })
                    setDeliveredOrders(addIndex)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }

    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index + 1}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        // {
        //     accessor: 'productCategory',
        //     Header: 'Product Category',
        //     headerProps: { className: 'pe-1' },
        //     cellProps: {
        //         className: 'py-2',
        //     },
        //     Cell: (rowData) => {
        //         const { productCategory } = rowData.row.original
        //         return (
        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{productCategory}</h5>
        //                 </div>
        //             </Flex>
        //         )
        //     },
        // },
        // {
        //     accessor: 'productSubCategory',
        //     Header: 'Product Sub Category',
        //     headerProps: { className: 'pe-1' },
        //     cellProps: {
        //         className: 'py-2',
        //     },
        //     Cell: (rowData) => {
        //         const { productSubCategory } = rowData.row.original
        //         return (
        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">
        //                         {productSubCategory}
        //                     </h5>
        //                 </div>
        //             </Flex>
        //         )
        //     },
        // },
        // {
        //     accessor: 'offerName',
        //     Header: 'Offer Name',
        //     headerProps: { className: 'pe-1' },
        //     cellProps: {
        //         className: 'py-2',
        //     },
        //     Cell: (rowData) => {
        //         const { offerName } = rowData.row.original
        //         return (
        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{offerName}</h5>
        //                 </div>
        //             </Flex>
        //         )
        //     },
        // },
        // {
        //     accessor: 'offerCategory',
        //     Header: 'Offer Category',
        //     headerProps: { className: 'pe-1' },
        //     cellProps: {
        //         className: 'py-2',
        //     },
        //     Cell: (rowData) => {
        //         const { offerCategory } = rowData.row.original
        //         return (
        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{offerCategory}</h5>
        //                 </div>
        //             </Flex>
        //         )
        //     },
        // },
        // {
        //     accessor: 'offerSkuName',
        //     Header: 'Offer Sku Name',
        //     headerProps: { className: 'pe-1' },
        //     cellProps: {
        //         className: 'py-2',
        //     },
        //     Cell: (rowData) => {
        //         const { offerSkuName } = rowData.row.original
        //         return (
        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{offerSkuName}</h5>
        //                 </div>
        //             </Flex>
        //         )
        //     },
        // },
        {
            accessor: 'challanNo',
            Header: 'Challan No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { challanNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{challanNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'doNo',
            Header: 'DO No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{doNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'delQtyCtn',
            Header: 'Delivery Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delQtyCtn } = rowData.row.original
                return (
                    <Flex justifyContent={"end"} alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(delQtyCtn)}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'delQtyPcs',
            Header: 'Delivery Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delQtyPcs } = rowData.row.original
                return (
                    <Flex justifyContent={"end"} alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(delQtyPcs)}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'offerQtyPCS',
            Header: 'Offer Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { offerQtyPCS } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {formatNumber(offerQtyPCS)}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'scheduleNo',
            Header: 'Schedule No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { scheduleNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{scheduleNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },

     
        {
            accessor: 'factoryName',
            Header: 'Factory/ SDB',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { factoryName, superDBId } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{factoryName || "SDB("+superDBId+")"}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'dealerId',
            Header: 'Dealer Id',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dealerId } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{dealerId}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'distributorName',
            Header: 'Distributor Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { distributorName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{distributorName}</h5>
                        </div>
                    </Flex>
                )
            },
        },

      

        {
            accessor: 'zoneName',
            Header: 'Zone Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { zoneName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{zoneName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'regionName',
            Header: 'Region Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { regionName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{regionName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'areaName',
            Header: 'Area Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { areaName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{areaName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'dbPoint',
            Header: 'DB Point',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dbPoint } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{dbPoint}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        
     

        {
            accessor: 'transportName',
            Header: 'Transport',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { deliveries } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {deliveries[0]?.transportName}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'driverName',
            Header: 'Driver name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { driverName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{driverName}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'driverPhone',
            Header: 'Driver Phone',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { driverPhone } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{driverPhone}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'deliveryDate',
            Header: 'Delivery Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { deliveryDate } = rowData.row.original
                const date = new Date(deliveryDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dealerId, challanNo } = rowData.row.original
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle
                            id="dropdown-autoclose-true"
                            className=" bg-none"
                        >
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="">
                                {' '}
                                {/* <Button
                                    variant="light"
                                    size="sm"
                                    onClick={() =>
                                        navigate(
                                            `/distribution/productdelivery/update`,
                                            {
                                                state: {
                                                    scheduleNav: rowData.row.original,
                                                },
                                            }
                                        )
                                    }
                                >
                                    Complete Delivery
                                </Button> */}
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                                {' '}
                                <Button
                                    variant="light"
                                    size="sm"
                                    // onClick={() => navigate("/distribution/productdelivery/details/" + challanNo + "/" + scheduleNo)}
                                    onClick={() =>
                                        navigate(
                                            '/distribution/productdelivery/details/' +
                                                challanNo +
                                                '/' +
                                                dealerId
                                        )
                                    }
                                >
                                    View Details
                                </Button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
        },
    ]

    /*******************
    Columns End.
    *******************/

    return (
        <>
            <PageHeader title="Delivery Search" className="mb-3"></PageHeader>

            <SorlDeliveryFilter
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                selectedZone={selectedZone}
                selectedRegion={selectedRegion}
                selectedArea={selectedArea}
                selectedDbPoint={selectedDbPoint}
                selectedDb={selectedDb}
                selectedDoNo={selectedDoNo}
                setSelectedDoNo={setSelectedDoNo}
                setSelectedZone={setSelectedZone}
                setSelectedRegion={setSelectedRegion}
                setSelectedArea={setSelectedArea}
                setSelectedDbPoint={setSelectedDbPoint}
                setSelectedDb={setSelectedDb}
                selectedDateFrom={selectedDateFrom}
                selectedDateTo={selectedDateTo}
                setSelectedDateTo={setSelectedDateTo}
                setSelectedDateFrom={setSelectedDateFrom}
                setSelectedChallanNo={setSelectedChallanNo}
                selectedChallanNo={selectedChallanNo}
                setSelectedScheduleNo={setSelectedScheduleNo}
                selectedScheduleNo={selectedScheduleNo}
                selectedFactory={selectedFactory}
                setSelectedFactory={setSelectedFactory}
                selectedSuperDB={selectedSuperDB}
                setSelectedSuperDB={setSelectedSuperDB}
                deliveredOrders={deliveredOrders}
                handleSearch={handleSearch}
            />

            <AdvanceTableWrapper
                columns={columns}
                data={deliveredOrders}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            excelFileName="Delivery Search List"
                            excelData={formateExcelData(deliveredOrders)}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}

// export default TotalDeliveries

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DistrbutorStockFilter)
