import axios from 'axios';
import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader';
import Flex from 'components/common/Flex';
import ToggleButton from 'components/common/Toggle-button/index';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Dropdown,
    Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { authHeader } from 'utils';
import { toast } from 'react-toastify';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { authHeaderForm } from 'state/ducs/auth/utils';
import LoadingIcon from 'helpers/LoadingIcon';
import NoDataFound from 'components/errors/NoDataFound';
import IconButton from 'components/common/IconButton';
import handleApiResponseErrors from 'helpers/handleApiResponse';

const AssignedSalesOfficer = () => {
    const [assignedSalesOfficer, setAssignedSalesOfficer] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showUploadCSV, setShowUploadCSV] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchItems, setSearchItems] = useState([]);
    const [count, setCount] = useState(0);



    // Load All Assigned Sales Officer
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + 'assigned-sales-officers';
                const response = await axios.get(url, { headers: authHeader() });
                const apiResponse = response.data;
                if (apiResponse.data.length === 0) {
                    return setAssignedSalesOfficer([]);
                }
                if (apiResponse.success === true || apiResponse.statusCode === 200) {
                    const employeeIds = [];
                    const distributorIds = [];
                    const handleDataArray = apiResponse.data.map((element, index) => {
                        if (element.employee_id) {
                            employeeIds.push(element.employee_id);
                        }
                        if (element.distributor_id) {
                            distributorIds.push(element.distributor_id);
                        }
                        index++;
                        return { ...element, index };
                    });

                    const [employeeResponse, distributorResponse] = await Promise.all([
                        axios.post(
                            process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + 'employees/selected',
                            { ids: employeeIds },
                            { headers: authHeader() }
                        ),
                        axios.post(
                            process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + 'bulk-distributors',
                            { ids: distributorIds },
                            { headers: authHeader() }
                        ),
                    ]);

                    // Process employee response
                    const updatedRouteData = handleDataArray.map(element => {
                        const handleFindItem = employeeResponse.data.data.find(item => item.id === element.employee_id);
                        return { ...element, employee: handleFindItem };
                    });

                    // Process distributor response
                    updatedRouteData.forEach(element => {
                        const handleFindItem = distributorResponse.data.distributors.find(item => item.id === element.distributor_id);
                        element.distributor = handleFindItem;
                    });

                    setAssignedSalesOfficer(updatedRouteData);
                }
                else {

                    setAssignedSalesOfficer([]);
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count]);






    // csv Modal 
    const handleShowCSV = () => {
        setShowUploadCSV(true);
    };

    /* 
    ...........................
            CSV FILE UPLOAD
     ..........................
    */
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));


    const handleCSVUpload = () => {

        const csvFile = acceptedFiles[0];
        const formData = new FormData();
        formData.append("file", csvFile);

        setIsLoading(true);

        const regUrl = process.env.REACT_APP_BASE_URL + "route/csvUpload";

        axios
            .post(regUrl, formData, { headers: authHeaderForm() })
            .then((response) => {

                if (response.data) {
                    setIsLoading(false);
                    setShowUploadCSV(false);
                    setCount((prevState) => prevState + 1);
                    toast.success(response?.data?.message);
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error?.message)
            })

    };

    // Handle Delete
    const handleDelete = (id) => {
        if (confirm("Are You Sure ?")) {
            setIsLoading(true);
            const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `assigned-sales-officers/${id}`;
            axios
                .delete(url, { headers: authHeader() })
                .then((response) => {
                    if (response.data.success || response.data.statusCode === 200) {
                        toast.success(response.data.message);
                        setCount(count + 1);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response);
                }).finally(
                    setIsLoading(false)
                )
        }
    };


    // Handle Toggle Status

    const handleToggle = (id, newStatus) => {
        setIsLoading(true);
        const updatedData = assignedSalesOfficer?.map(item => {
            if (item.id === id) {
                return { ...item, status: newStatus }
            }
            return item;
        })

        setAssignedSalesOfficer(updatedData);

        const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `assigned-sales-officers/${id}`
        axios.patch(url, { status: newStatus }, { headers: authHeader() })
            .then(response => {

            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(
                setIsLoading(false)
            )

    }


    if (isLoading) {
        return <LoadingIcon />
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{index}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'employee',
            Header: 'Employee Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { employee } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{`${employee?.first_name} ${employee?.middle_name} ${employee?.last_name}`}</h5>
                        </div>
                    </Flex>

                );
            }
        },

        {
            accessor: 'scheduled_outlet',
            Header: 'Scheduled Outlet',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { scheduled_outlet } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{scheduled_outlet}</h5>
                        </div>
                    </Flex>

                );
            }
        },

        {
            accessor: 'visited_outlet',
            Header: 'Visited Outlet',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { visited_outlet } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{visited_outlet === true ? "Yes" : "No"}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'visiting_frequency',
            Header: 'Visiting Frequency',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { visiting_frequency } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{visiting_frequency}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'route',
            Header: 'Route Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { route } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{route?.name}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'distributor_name',
            Header: 'Distributor Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { distributor } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{distributor?.distributor_name}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'workingDays',
            Header: 'Working Days',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { work_on_sat, work_on_sun, work_on_mon, work_on_tue, work_on_wed, work_on_thu, work_on_fri } = rowData.row.original;
                const findWorkingDays = `${work_on_sat === true ? "Saturday" : ""} ${work_on_sun === true ? "Sunday" : ""} ${work_on_mon === true ? "Monday" : ""} ${work_on_tue === true ? "Thursday" : ""} ${work_on_wed === true ? "Wednesday" : ""} ${work_on_thu === true ? "Thursday" : ""} ${work_on_fri === true ? "Friday" : ""}`
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{findWorkingDays}</h5>
                        </div>
                    </Flex>

                );
            }
        },

        {
            accessor: 'status',
            Header: 'status',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { status, id } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <ToggleButton
                                id={id}
                                status={status}
                                handleToggle={handleToggle}
                            ></ToggleButton>
                        </div>
                    </Flex>

                );
            }
        },

        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { id } = rowData.row.original;
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="">
                                {' '}
                                <Link to={`/assignedSalesOfficer/add/${id}`}>
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="edit"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                    </IconButton>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                                <IconButton
                                    onClick={() => handleDelete(id)}
                                    variant="falcon-default"
                                    size="sm"
                                    icon="trash-alt"
                                    iconAlign="middle"
                                    className="d-none d-sm-block me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                </IconButton>
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                );
            }
        }
    ];

    /*******************
    Columns End.
    *******************/

    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={assignedSalesOfficer}
                sortable
            // pagination
            // perPage={pageDataCount}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            title="Assigned Sales Officer"
                            buttonTitle="Upload CSV"
                            handleShowCSV={handleShowCSV}
                            newUrl="/assignedSalesOfficer/add"
                            isSearch={true}
                            setSearchText={setSearchText}
                            excelUrl="route/excelDownload"
                            excelFileName="Route.xlsx"
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        {
                            searchText.length > 0 && searchItems.length === 0 ? <NoDataFound /> : <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                        }
                    </Card.Body>
                    <Card.Footer>
                        {/* <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} /> */}
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            <Modal
                size="lg"
                show={showUploadCSV}
                onHide={() => setShowCreateCostCenterModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Upload Route CSV File
                    </Modal.Title>
                    <FalconCloseButton
                        onClick={() => setShowUploadCSV(false)}
                    />
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div
                            {...getRootProps({ className: "dropzone-area py-6" })}
                        >
                            <input {...getInputProps({ multiple: false })} />
                            <Flex justifyContent="center">
                                <img src={cloudUpload} alt="" width={25} className="me-2" />
                                <p className="fs-0 mb-0 text-700">Drop your file here</p>
                            </Flex>
                        </div>
                        <div className="mt-3">
                            {acceptedFiles.length > 0 && (
                                <>
                                    <h6>File</h6>
                                    <ul>{files}</ul>
                                </>
                            )}
                        </div>
                    </>

                    <Button variant="primary" onClick={handleCSVUpload}>
                        Submit
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AssignedSalesOfficer;
