import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link, useLocation } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { IoIosCheckboxOutline } from "react-icons/io";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { formatNumber } from "helpers/utils";

const AdjustmentOrdersListSuper = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { state } = useLocation();
    const { data } = state;


    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>

            {<Card className="mb-3">
                <Card.Header>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial No.</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DO-No</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB ID</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB Name</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Due Amount</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Pay Amount</th>



                                        <th className="align-middle white-space-nowrap text-center ">
                                            Action</th>

                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        data?.map((order, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{order?.doNo}</td>
                                                <td className="align-middle text-center">{order?.dealerId}</td>
                                                <td className="align-middle text-center">{order?.dealerName}</td>
                                                <td className="align-middle text-center">{formatNumber(order?.crBDT)}</td>
                                                <td className="align-middle text-center">{formatNumber(order?.drBDT)}</td>

                                                <td>
                                                    <Dropdown className="d-inline mx-2">
                                                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                                                            ...
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item>
                                                                <Link to={`/super/orders/details/${order?.doNo}`} >
                                                                    <IconButton
                                                                        variant="falcon-default"
                                                                        size="sm"
                                                                        icon="edit"
                                                                        transform="shrink-2"
                                                                        iconAlign="middle"
                                                                        className="me-2"
                                                                    >
                                                                        <span className="d-none d-xl-inline-block ms-1">View details</span>
                                                                    </IconButton>
                                                                </Link>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>

                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>}

        </>
    );

};

export default AdjustmentOrdersListSuper;
