import LoginForm from 'components/authentication/LoginForm'
import React from 'react'
import logo from '../../../assets/img/logos/tkgl/techknowgram.fa1a6b163526beb3e5a6.png'
import AuthSimpleLayout from 'layouts/AuthSimpleLayout'
import pustiLogo from 'assets/img/logos/PustiLogo/pusti_logo.png'

const Login = () => {
    return (
        <AuthSimpleLayout
            leftSideContent={
                <>
                    <div className="position-relative mb-2">
                        <a href="https://techknowgram.com/" target="_blank">
                            <img
                                src={logo}
                                style={{ width: '100%', marginTop: '10px' }}
                            ></img>
                        </a>
                    </div>

                </>
            }
        >
            <div className="ms-20">
            <a href="https://tkfoodsbd.com/" target="_blank">
                    <img
                        src={pustiLogo}
                        className="d-none d-md-block"
                        style={{
                            width: '40%',
                            marginLeft: '70%',
                            marginBottom: '35px',
                        }}
                    ></img>
                </a>
            </div>
            <h3>SR Login</h3>
            <LoginForm layout="card" userType="salesRepresentative" hasLabel />
        </AuthSimpleLayout>
    )
}

export default Login
