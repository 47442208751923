import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Table } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader';
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { IoIosCheckboxOutline } from "react-icons/io";
import { BiSolidSelectMultiple } from "react-icons/bi";


const SuperDistributorOrderCreateStep1 = ({ handleNext, distributorOrderNumber, checkedProducts, setCheckedProducts }) => {
    const [searchText, setSearchText] = useState("");
    const [productsData, setProductsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);

    // Organize products by category
    const organizeProductsByCategory = (products) => {
        const categories = {};

        products.forEach((product) => {
            const categoryId = product.categoryName;
            if (!categories[categoryId]) {
                categories[categoryId] = {
                    categoryId: categoryId,
                    categoryName: product.categoryName,
                    data: [],
                };
            }
            categories[categoryId].data.push(product);
        });

        const result = Object.keys(categories).map((categoryId) => categories[categoryId]);
        return result;
    };


    // Fetch products
    useEffect(() => {
        const fetchData = async () => {
            // setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?search=${searchText}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.status === 200) {
                    const productsByCategory = organizeProductsByCategory(response.data.payload.products);
                    setProductsData(productsByCategory);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [searchText]);


    // Handle checkbox change
    const handleCheckboxChange = (product) => {
        const perCartoonPrice = (distributorInfo.userRole == "SUPER DISTRIBUTOR" || distributorInfo.userRole == "GENERAL DISTRIBUTOR") ?
            product.distributorPrice : distributorInfo.userRole == "COMMISSION DISTRIBUTOR" ? product.commPrice : distributorInfo.userRole == "WHOLESALE DISTRIBUTOR" ? product.wholesellPrice : distributorInfo.userRole == "SPOT DISTRIBUTOR" ? product.spotPrice : ""
        if (isChecked(product)) {
            setCheckedProducts(checkedProducts.filter(p => p._id !== product._id).map(item => ({ ...item, orderQuantity: 0, totalOrderQtyPCS: 0, perCartoonPrice: 0, totalPriceCtn: 0, totalPrice: 0, totalPriceWithOutDiscount: 0 })));
        } else {
            setCheckedProducts([...checkedProducts, { ...product, orderQuantity: 0, totalOrderQtyPCS: 0, perCartoonPrice: 0, totalPriceCtn: 0, totalPrice: 0, totalPriceWithOutDiscount: 0 }]);
        }
    };

    // Check if a product is checked
    const isChecked = (product) => {
        return checkedProducts.some(p => p.skuName === product.skuName);
    };

    if (isLoading) return <LoadingIcon />

    // Handle form submission
    const handleSubmit = async () => {
        const isNewProduct = checkedProducts?.filter((product) => product?.doNo !== distributorOrderNumber);
        if (isNewProduct?.length === 0) {
            handleNext();
            return;
        } else {
            try {
                setIsLoading(true);
                const orderItems = checkedProducts.filter((product) => product?.doNo !== distributorOrderNumber).map((product) => ({
                    doNo: distributorOrderNumber,
                    doDate: new Date(),
                    distributorName: distributorInfo?.name || distributorInfo?.distributorName,
                    zone: distributorInfo?.zoneName,
                    region: distributorInfo?.regionName,
                    area: distributorInfo?.areaName,
                    dbPoint: distributorInfo?.dbPoint,
                    dealerId: distributorInfo?.userId,
                    distributorName: distributorInfo?.name || distributorInfo?.distributorName,
                    dbType: distributorInfo?.userRole,
                    companyName: distributorInfo?.companyName,
                    // factoryName: product.factoryName[0],
                    factoryName: product.factoryName,
                    categoryName: product.categoryName,
                    skuName: product.skuName,
                    superDBId: distributorInfo.superDBId,
                    orderYear: new Date().getFullYear(),
                    orderMonth: new Date().getMonth() + 1,
                    subCategory: product?.subCategory || "",
                    unitName: "CTN"


                }));

                const response = await axios.post(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/create`, orderItems, { headers: authHeader() });

                if (response.success === true || response.status === 200) {

                    response.data.insertedOrders.forEach((item) => {
                        checkedProducts.forEach((product) => {
                            if (item.skuName === product.skuName) {
                                product._id = item._id;
                                product.productId = item._id;
                                product.doNo = item.doNo;
                            }
                        });
                        handleNext();
                    });


                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally { setIsLoading(false) }
        }

    };


    return (
        <>
            <PageHeader
                title={`Order No: ${distributorOrderNumber}`}
                description2={
                    <div>
                        <p style={{ color: "#354151", fontWeight: "500", fontFamily: 'Poppins' }}>DB ID: {distributorInfo?.userId}, Distributor Name: {distributorInfo?.name || distributorInfo?.distributorName}, Mobile No: {distributorInfo?.phoneNumber}, Address: {distributorInfo?.address}</p>
                    </div>
                }
                className="mb-3"
            />

            <Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader isNew={false} isExport={false} isSearch={true} setSearchText={setSearchText} />
                        <p style={{ marginRight: "20px", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}><BiSolidSelectMultiple /> Select : {checkedProducts?.length > 0 ? checkedProducts?.length : 0}</p>
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div>
                        {productsData.length === 0 ? (
                            <p style={{ textAlign: "center", fontSize: "18px", color: "#354151", padding: "20px" }}>No product Category found</p>
                        ) : (
                            <Accordion>
                                {productsData.map((category) => (
                                    <Accordion.Item key={category.categoryId} eventKey={category.categoryId}>
                                        <Accordion.Header ><span style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>{category.categoryName}</span></Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th><IoIosCheckboxOutline /></th>
                                                        <th style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>Product Name</th>
                                                        <th style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>Price</th>
                                                        <th style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>Factory Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {category.data.map((product) => {
                                                        const productPrice = (distributorInfo?.userRole == "SUPER DISTRIBUTOR" || distributorInfo?.userRole == "GENERAL DISTRIBUTOR") ?
                                                            product?.distributorPrice : distributorInfo?.userRole == "COMMISSION DISTRIBUTOR" ? product?.commPrice : distributorInfo?.userRole == "WHOLESALE DISTRIBUTOR" ? product?.wholesellPrice : distributorInfo?.userRole == "SPOT DISTRIBUTOR" ? product?.spotPrice : "";
                                                        return <tr key={product.id}>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isChecked(product)}
                                                                    onChange={() => handleCheckboxChange(product)}
                                                                />
                                                            </td>
                                                            <td style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>{product.skuName}</td>
                                                            <td style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>{productPrice}</td>
                                                            <td style={{ fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>{product.factoryName?.join(', ')}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )}
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Button
                        variant="primary"
                        className="me-2 px-5"
                        type="submit"
                        disabled={checkedProducts.length === 0}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        Next
                    </Button>
                </Card.Footer>
            </Card>
        </>
    );

};

export default SuperDistributorOrderCreateStep1;
