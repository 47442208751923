import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import PageHeader from 'components/common/PageHeader'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import handleApiResponseErrors from "helpers/handleApiResponse";
import React, { useEffect, useState } from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import LoadingIcon from "helpers/LoadingIcon";
import { authHeader } from "utils";
import Swal from "sweetalert2";
import { getAllDeliveries, getAllSchedules } from 'services/api'

const ScheduledOrdersListForApprove = ({ user }) => {
    const [approvableOrders, setApprovableOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);


    const navigate = useNavigate()
    const {
        data: scheduleData,
        isLoading: isLoadingScheduleByFactory,
        isError: scheduleByFactoryError,
        refetch: scheduleByFactoryRefetch,
    } = useQuery({
        queryKey: ['allSchedules'],
        queryFn: () => getAllSchedules(),
    })

    const {
        data: deliveryData,
        isLoading: isLoadingDeliveryByFactory,
        isError: deliveryByFactoryError,
        refetch: deliveryByFactoryRefetch,
    } = useQuery({
        queryKey: ['allDeliveries'],
        queryFn: () => getAllDeliveries(),
    })

    useEffect(() => {
        scheduleByFactoryRefetch();
        deliveryByFactoryRefetch();
    }, [count]);

    const IsShowable = (order, allDeliveries) => {
        // if (!allDeliveries.length) {
        //     return true
        // }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName
                    &&
                    item.scheduleNo == order.scheduleNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + (obj.dbType == "SPECIAL DISTRIBUTOR" ? obj.delQtyPcs : obj.delQtyCtn)
            }, 0)

        return qtySum == (order.dbType == "SPECIAL DISTRIBUTOR" ? order.delQtyPcs : order.delQtyCtn)
    }

    const getSum = (order, allDeliveries) => {
        if (!allDeliveries.length) {
            return 0;
        }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName
                    &&
                    item.scheduleNo == order.scheduleNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + (obj.dbType == "SPECIAL DISTRIBUTOR" ? obj.delQtyPcs : obj.delQtyCtn)
            }, 0)

        return qtySum
    }

    let undeliveredOrders = []

    if (
        scheduleData?.data.payload.result.length
        && deliveryData?.data.payload.result
    ) {
        const allSchedules = scheduleData.data.payload.result
        const allDeliveries = deliveryData.data.payload.result

        const filteredSchedules = allSchedules.filter(
            (item) => !item.superDBId && item.status == 8 && !IsShowable(item, allDeliveries)
        )

        

        const schedules = filteredSchedules.map((tot, i) => ({
            ...tot,
            index: i + 1,
            schQty: (tot.dbType == "SPECIAL DISTRIBUTOR" ? tot.delQtyPcs : tot.delQtyCtn) - getSum(tot, allDeliveries),
        }))

        const offers= allSchedules.filter(
            (item) => item.offerNo && item.status == 8
        )

        undeliveredOrders= [...schedules, ...offers]


    }

    if (isLoading) {
        return <LoadingIcon />;
    }

    // Handle form submission
    const handleSubmit = async () => {
        const orderItems = approvableOrders.map(item => {
            delete item.index
            delete item.schQty
            return {
                ...item,
                status: 9
            }
        });

        // return;


        // Check if there are items to update
        if (orderItems.length === 0) {
            // No items to update, show a message or return early
            return;
        }

        // Make a PUT request to update the order items
        try {
            setIsLoading(true);
            const response = await axios.put(
                `https://khoz.net:3014/api/v1/orders-distribution`,
                orderItems,
                { headers: authHeader() }
            );

            // Check if the update was successful
            if (response.data.status || response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Orders have been successfully checked.",
                });
                setApprovableOrders([]);
                setCount((prevCount) => prevCount + 1); // Update count
                // navigate("/finance/productscheduling/approved");
            } else {
                // Handle unexpected response
                throw new Error("Unexpected response from server");
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };

    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: 'check',
            // Header: 'SL',
            Header:
                <input type='checkbox'
                    checked={undeliveredOrders.length == approvableOrders.length}
                    onChange={(e) => {
                        // undeliveredOrders.find(item=> item.scheduleNo == scheduleNo && item.doNo == doNo && item.skuName== skuName)
                        const res = undeliveredOrders
                        console.log("undeliveredOrdersRes", res);
                        if (res.length == approvableOrders.length) {
                            setApprovableOrders([]);
                        } else {
                            setApprovableOrders([...undeliveredOrders]);
                        }
                    }} />,
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { checked, scheduleNo, doNo, skuName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <input
                                type="checkbox"
                                // checked={checkedOrders?.length === distributorOrderList?.length}
                                checked={approvableOrders.find(item => item.scheduleNo == scheduleNo && item.doNo == doNo && item.skuName == skuName) ? true : false}
                                // onChange={(e) => handleCheckAll(e)} />
                                onChange={(e) => {
                                    // undeliveredOrders.find(item=> item.scheduleNo == scheduleNo && item.doNo == doNo && item.skuName== skuName)
                                    const res = undeliveredOrders.find(item => item.scheduleNo == scheduleNo && item.doNo == doNo && item.skuName == skuName)
                                    console.log("undeliveredOrdersRes", res);
                                    const already = approvableOrders.find(item => item.scheduleNo == scheduleNo && item.doNo == doNo && item.skuName == skuName)
                                    if (already) {
                                        setApprovableOrders([...approvableOrders.filter(item => item.scheduleNo != scheduleNo && item.doNo != doNo && item.skuName != skuName)]);
                                    } else {
                                        setApprovableOrders([...approvableOrders, res]);
                                    }

                                }} />
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'scheduleNo',
            Header: 'Schedule No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { scheduleNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{scheduleNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'doNo',
            Header: 'DO No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doNo, dbType } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{doNo} {dbType == "SPECIAL DISTRIBUTOR" ? "(Special)" : ""}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'skuName',
            Header: 'Product Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { skuName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{skuName}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'schQty',
            Header: 'Scheduled Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { schQty } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{schQty}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'factoryName',
            Header: 'Factory Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { factoryName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{factoryName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'distributorName',
            Header: 'Distributor Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { distributorName, dealerId } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{distributorName} ({dealerId})</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'scheduleDate',
            Header: 'Schedule Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { scheduleDate } = rowData.row.original
                const date = new Date(scheduleDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'status',
            Header: 'Status',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original
                
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{status==8 ? "Pendinhg" :""}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { scheduleNo, dealerId } = rowData.row.original
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle
                            id="dropdown-autoclose-true"
                            className=" bg-none"
                        >
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="">
                                {' '}
                                {/* <Button
                                    variant="light"
                                    size="sm"
                                    onClick={() =>
                                        navigate(
                                            `/distribution/productdelivery/update`,
                                            {
                                                state: {
                                                    scheduleNav: rowData.row.original,
                                                },
                                            }
                                        )
                                    }
                                >
                                    Complete Delivery
                                </Button> */}
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                                {' '}
                                <Button
                                    variant="light"
                                    size="sm"
                                    onClick={() => navigate("/distribution/productscheduling/details/" + scheduleNo + "/" + dealerId)}
                                >
                                    View Details
                                </Button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
        },
    ]

    /*******************
    Columns End.
    *******************/

    return (
        <>
            <PageHeader
                title="Scheduled Orders for Approval"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={undeliveredOrders}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            newUrl=""
                            isNew={false}
                            isExport={false}
                            isSearch={false}
                            setSearchText={'setSearchText'}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination
                            table
                            paginationPageCount={1}
                            setPaginationPageCount={() => ({})}
                            pageDataCount={1}
                            setPageDataCount={1}
                            totalPages={1}
                        />
                    </Card.Footer>
                </Card>
                <Button className="mt-2 px-5" variant="primary" type="submit" disabled={approvableOrders?.length === 0} onClick={() => { handleSubmit(); }}>
                    Approve
                </Button>
            </AdvanceTableWrapper>
        </>
    )
}

// export default PendingDeliveries

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledOrdersListForApprove)
