import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const CustomerAdvancePaymentForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [customerAdvancePaymentById, setCustomerAdvancePaymentById] = useState({});
    const { updateID } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + `customer-advance-payments/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setCustomerAdvancePaymentById(response.data.data);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
    }, [])


    const handleFormSubmit = (values, actions) => {

        const handleSubmitValue = {
            customer_type: values.customer_type,
            customer_id: values.customer_id,
            amount: values.amount
        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + 'customer-advance-payments';
            axios.post(url, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/customerAdvancePayment")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + `customer-advance-payments/${updateID}`;
            axios.put(updateUrl, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/customerAdvancePayment")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({
        customer_type: Yup.string().required("Please enter a value for the customer type field"),
        customer_id: Yup.string().required("Please Enter a value for the customer id field"),
        amount: Yup.string().required("Please Enter a value for the Amount field"),
    })


    return (
        <>
            <PageHeader
                title={updateID ? "Update Customer Advance Payment" : "Add New Customer Advance Payment"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            customer_type: customerAdvancePaymentById.customer_type || "",
                            customer_id: customerAdvancePaymentById.customer_id || "",
                            amount: customerAdvancePaymentById.amount || ""


                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Customer Type</Form.Label>
                                            <Form.Control
                                                name="customer_type"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter customer type"
                                                required
                                                value={values.customer_type}
                                            />
                                            {touched.customer_type && errors.customer_type && (
                                                <div style={{ color: "red" }}>
                                                    {errors.customer_type}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Customer Id</Form.Label>
                                            <Form.Control
                                                name="customer_id"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter customer id"
                                                required
                                                value={values.customer_id}
                                            />
                                            {touched.customer_id && errors.customer_id && (
                                                <div style={{ color: "red" }}>
                                                    {errors.customer_id}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control
                                                name="amount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Amount"
                                                required
                                                value={values.amount}
                                            />
                                            {touched.amount && errors.amount && (
                                                <div style={{ color: "red" }}>
                                                    {errors.amount}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <div className="mt-3">
                                        <IconButton
                                            variant="primary"
                                            className="me-2"
                                            type="submit"

                                        >
                                            {updateID ? "Save" : "Submit"}
                                        </IconButton>
                                        <Button
                                            onClick={() => navigate("/customerAdvancePayment")}
                                            variant="danger" type="Cancel"
                                        >
                                            Cancel
                                        </Button>
                                    </div>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default CustomerAdvancePaymentForm;


