import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { parse } from "papaparse";
import PurchaseConfirm from "./PurchaseConfirm";
import { formatNumber } from "helpers/utils";

const PurchaseDetails = () => {
    const [step, setStep] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedProductionDate, setSelectedProductionDate] = useState(null);
    const [selectedExpiryDate, setSelecteExpitydDate] = useState(null);
    const [count, setCount] = useState(0);
    const [batchNo, setBatchNo] = useState(null);
    const [allProducts, setAllProducts] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [checkedProducts, setCheckProducts] = useState([]);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const { poNo } = useParams();

    // DATE
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // Add Note
    const handleAddNote = (event, index, product) => {
        const { value } = event.target;
        const updatedProducts = [...productsData];
        updatedProducts[index].note = value;
        setProductsData(updatedProducts);
    }

    const handleReceivedProducts = (event, index, product, fieldName) => {
        const { value } = event.target;
        const updatedProducts = [...productsData];
        const findProduct = allProducts.find((item) => item.skuName === product.skuName);

        // Depending on the fieldName, perform different actions
        switch (fieldName) {
            case 'ctn':
                const calculatedPcs = parseFloat(value) * findProduct?.pcsCTN;
                const totalWeight = calculatedPcs * findProduct?.weightPCS || 0;
                const calculateWeight = totalWeight / 1000000;
                updatedProducts[index].ctn = parseFloat(value);
                updatedProducts[index].pcs = calculatedPcs;
                updatedProducts[index].weightMT = calculateWeight;
                updatedProducts[index].erpId = findProduct?.erpId;
                updatedProducts[index].pcsCTN = findProduct?.pcsCTN;
                updatedProducts[index].erpId = findProduct?.erpId;
                break;
            case 'pcs':
                updatedProducts[index].pcs = parseFloat(value);
                break;
            default:
                break;
        }

        setProductsData(updatedProducts);
    };




    // Fetch products
    useEffect(() => {
        const fetchData = async () => {
            // setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.status === 200) {
                    const responseData = response.data.payload.products;
                    setAllProducts(responseData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            if (!poNo) return;
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductPurchaseByPO/${poNo}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const responseData = response.data.payload.prdPurchaseDetails;

                    setProductsData(responseData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [poNo]);


    const handleNext = () => {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select recived date",
                icon: "warning"
            });
            return;
        }

        const findEmptyProduct = productsData.filter((product) => !product.ctn || !product.pcs);
        const findProduct = productsData.filter((product) => product.ctn && product.pcs);
        if (findEmptyProduct?.length > 0) {
            const updateProducts = findEmptyProduct.map(product => {
                const findProduct = allProducts.find((item) => item.skuName === product.skuName);
                return {
                    ...product,
                    ctn: product?.rcvQtyCTN,
                    pcs: product?.rcvQtyPCS,
                    weightMT: product?.rcvQtyMt,
                    erpId: product?.erpId,
                    pcsCTN: product?.pcsCTN,
                    erpId: findProduct?.erpId
                }
            })

            setProductsData([...findProduct, ...updateProducts]);

        }

        setStep(2);
    }




    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>
            <PageHeader
                title="Pending Purchase List"
                className="mb-3"
            ></PageHeader>

            {step === 1 && <><Card className="mb-3">
                <Card.Header>
                    <div className="">
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase from :</h6>
                                <Form.Control type="text" placeholder="Enter Vendor Name" value={productsData[0]?.purchaseFrom} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase For :</h6>
                                <Form.Control type="text" placeholder="Enter Factory Name" value={productsData[0]?.factoryName} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">PO No. :</h6>
                                <Form.Control type="text" placeholder="Enter PO No." value={productsData[0]?.PONo} disabled />
                            </Form.Group>
                        </Row  >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transport :</h6>
                                <Form.Control type="text" placeholder="Enter PO No." value={productsData[0]?.transportName} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transport No.:</h6>
                                <Form.Control type="text" placeholder="Enter PO No." value={productsData[0]?.transportNo} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Name:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Name" value={productsData[0]?.driverName} disabled />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Number:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Number" value={productsData[0]?.driverPhone} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase Date :</h6>
                                <Form.Control type="text" placeholder="Enter Driver Number" value={new Date(productsData[0]?.purchaseDate).toLocaleDateString()} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Expiry Date :</h6>
                                <Form.Control type="text" placeholder="" value={new Date(productsData[0]?.expireDate).toLocaleDateString()} disabled />
                            </Form.Group>
                            <Col md="auto" className="mt-3">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Production Date :</h6>
                                <input type="date" className="form-control" onChange={(event) => setSelectedProductionDate(event.target.value)}
                                value={selectedProductionDate}
                                />
                            </Col>
                            <Col md="auto" className="mt-3">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase Receive Date :</h6>
                                <input type="date" className="form-control" onChange={(event) => handleDateChange(event.target.value)}
                                value={selectedDate}
                                />
                            </Col>
                        </Row>
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Prod QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Prod QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Prod Weight(MT)`}</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>
                                        <th colSpan="2" className="align-middle white-space-nowrap text-center ">Note</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        productsData?.map((product, index) => {

                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.productCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">{formatNumber(product?.rcvQtyCTN)}</td>
                                                <td className="align-middle text-center">{formatNumber(product?.rcvQtyPCS)}</td>
                                                <td className="align-middle text-center">{formatNumber(product?.rcvQtyMt)}</td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        onChange={(event) => handleReceivedProducts(event, index, product, "ctn")}
                                                        defaultValue={product?.rcvQtyCTN}
                                                    />
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product?.pcs ? formatNumber(product?.pcs) : formatNumber(product?.rcvQtyPCS)}
                                                        // onChange={(event) => handleReceivedProducts(event, index, product, "pcs")}
                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product.weightMT ? formatNumber(product.weightMT) : formatNumber(product?.rcvQtyMt)}
                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        as={"textarea"}
                                                        type="text"
                                                        placeholder="Enter Note"
                                                        value={product.note}
                                                        style={{width:"150px"}}
                                                        onChange={(event) => handleAddNote(event, index, product)}
                                                    />

                                                </td>

                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
                <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleNext(); }}>
                    Received
                </Button></>}

            {
                step === 2 && <PurchaseConfirm setStep={setStep} selectedProductionDate={selectedProductionDate} selectedDate={selectedDate} productsData={productsData} setProductsData={setProductsData} setCount={setCount} />
            }

        </>
    );

};

export default PurchaseDetails;

