import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CloseButton,
  Dropdown,
  Modal,

} from 'react-bootstrap';
import axios from 'axios';
import { authHeader } from 'utils';
import { toast } from 'react-toastify';
import ToggleButton from 'components/common/Toggle-button/index';
import LoadingIcon from 'helpers/LoadingIcon';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import FalconCloseButton from 'components/common/FalconCloseButton';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { authHeaderForm } from 'state/ducs/auth/utils';
import NoDataFound from 'components/errors/NoDataFound';
import IconButton from 'components/common/IconButton';
import handleApiResponseErrors from 'helpers/handleApiResponse';

const index = () => {
  const [outletData, setOutletData] = useState([]);
  const [singleOutletData, setSingleOutletData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [showUploadCSV, setShowUploadCSV] = useState(false);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [paginationPageCount, setPaginationPageCount] = useState(0);
  const [pageDataCount, setPageDataCount] = useState(10);
  const [totalPages, setTotalPages] = useState(0);



  // Load All Outlet
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + 'outlets';
        const response = await axios.get(url, { headers: authHeader() });
        const apiResponse = response.data;
        if (apiResponse.data.length === 0) {
          return setOutletData([]);
        }
        if (apiResponse.success === true || apiResponse.statusCode === 200) {
          const distributorIds = [];
          const handleDataArray = response.data.data.map((element, index) => {

            if (element.distributor_id) {
              distributorIds.push(element.distributor_id);
            }
            index++;
            return { ...element, index };
          });

          const [distributorResponse] = await Promise.all([

            axios.post(
              process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + 'bulk-distributors',
              { ids: distributorIds },
              { headers: authHeader() }
            ),
          ]);


          // Process distributor response
          handleDataArray.forEach(element => {
            const findDistributor = distributorResponse.data.distributors.find(item => item.id === element.distributor_id);
            element.distributor = findDistributor;
          });

          setOutletData(handleDataArray);
        }
      } catch (error) {
        handleApiResponseErrors(error.response);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [count]);


  // csv Modal 
  const handleShowCSV = () => {
    setShowUploadCSV(true);
  };


  // View Details
  const handleViewDetails = (id) => {
    setViewDetails(true);

    // Load Outlet Data.
    const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `outlets/${id}`;
    axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        if (response.data.success || response.data.status === 200) {
          setSingleOutletData(response.data.data);
        }

      })
      .catch((error) => {
        handleApiResponseErrors(error.response);
      })
  };



  /* 
 ...........................
         CSV FILE UPLOAD
  ..........................
 */
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));


  const handleCSVUpload = () => {

    const csvFile = acceptedFiles[0];
    const formData = new FormData();
    formData.append("file", csvFile);

    setIsLoading(true);

    const regUrl = process.env.REACT_APP_BASE_URL + "outlet/csvUpload";

    axios
      .post(regUrl, formData, { headers: authHeaderForm() })
      .then((response) => {

        if (response.status === 201) {
          setIsLoading(false);
          setShowUploadCSV(false);
          setCount((prevState) => prevState + 1);
          toast.success("Csv File Upload Success");
        }
      })
      .catch(error => {
        setIsLoading(false);
        toast.error(error?.message)
      })

  };


  // Handle Toggle Status

  const handleToggle = (id, newStatus) => {
    setIsLoading(true);
    const updatedData = outletData?.map(item => {
      if (item.id === id) {
        return { ...item, status: newStatus }
      }
      return item;
    })

    setOutletData(updatedData);

    const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `outlets/${id}`
    axios.put(url, { status: newStatus }, { headers: authHeader() })
      .then(response => {


      })
      .catch(error => {
        handleApiResponseErrors(error.response);
      })
      .finally(
        setIsLoading(false)
      )

  }


  if (isLoading) {
    return <LoadingIcon />
  }

  /* 
  ..........................
  Columns Data here
  .........................
  */

  const columns = [
    {
      accessor: 'index',
      Header: 'SL',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { index } = rowData.row.original;
        return (
          <Link to="/e-commerce/customer-details">
            <Flex alignItems="center">
              <div className="flex-1">
                <h5 className="mb-0 fs--1">{index}</h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'routeName',
      Header: 'Route Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { route } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{route?.name}</h5>
            </div>
          </Flex>

        );
      }
    },

    {
      accessor: ' outletName',
      Header: 'Outlet Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'address',
      Header: 'Address',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { address } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{address}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'contactPerson',
      Header: 'Contact Person',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { contact_person } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{contact_person}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'mobile',
      Header: 'Mobile',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { mobile } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{mobile}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id, status } = rowData.row.original;

        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <ToggleButton
                id={id}
                status={status}
                handleToggle={handleToggle}
              ></ToggleButton>

            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'action',
      Header: 'Action',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
              ...
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item >
                <Link to={`/master/outlet/add/${id}`}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="edit"
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-2"
                  >
                    <span className="d-none d-xl-inline-block ms-1">Edit</span>
                  </IconButton>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item href="">
                {' '}
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => handleViewDetails(id)}
                >
                  View Details
                </Button>
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={searchText ? searchItems : outletData}
        // selection
        sortable
        pagination
        perPage={pageDataCount}
      >
        <Card className="mb-3">
          <Card.Header>
            <CustomersTableHeader
              title="Outlets"
              buttonTitle="Upload CSV"
              handleShowCSV={handleShowCSV}
              isSearch={true}
              setSearchText={setSearchText}
              data={outletData}
              newUrl="/master/outlet/add"
              excelUrl="outlet/excelDownload"
              excelFileName="outlet.xlsx"
              table
            />
          </Card.Header>
          <Card.Body className="p-0">
            {
              searchText.length > 0 && searchItems.length === 0 ? <NoDataFound /> : <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            }
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      {/* View Details Modal */}
      <Modal size={'lg'} show={viewDetails} onHide={() => setViewDetails(false)}>
        <Modal.Header>
          {/* <Modal.Title className="text-center">Employee Details</Modal.Title> */}
          <CloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => setViewDetails(false)} />
        </Modal.Header>
        <Modal.Body>
          <div>

            <div class="table-responsive scrollbar">
              <table class="table table-bordered  fs--1 mb-0 ">
                <thead class="bg-200">
                  <tr>
                    <th class=" " data-sort="name">Name</th>

                    <th class="" data-sort="age">Value</th>
                  </tr>
                </thead>
                <tbody class="list text-black">
                  <tr>
                    <td class="name">Sales PerMonth</td>

                    <td>{singleOutletData?.sales_per_month}</td>
                  </tr>
                  <tr>
                    <td class="name">Market Size</td>

                    <td>{singleOutletData?.market_size}</td>
                  </tr>
                  <tr>
                    <td class="name">keyOutlet</td>

                    <td>{singleOutletData?.keyOutlet}</td>
                  </tr>
                  <tr>
                    <td class="name">Outlet Type</td>

                    <td>{singleOutletData?.outletType}</td>
                  </tr>
                  <tr>
                    <td class="name">Outlet Channel</td>

                    <td>{singleOutletData?.outletChannel}</td>
                  </tr>
                  <tr>
                    <td class="name">Display Outlet</td>

                    <td>{singleOutletData?.display_outlet === true ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td class="name">Display Outlet Amount</td>

                    <td>{singleOutletData?.display_outlet_amount === true ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td class="name">Paid Amount</td>

                    <td>{singleOutletData?.paid_amount}</td>
                  </tr>
                  <tr>
                    <td class="name">ShopType</td>

                    <td>{singleOutletData?.shop_type}</td>
                  </tr>
                  <tr>
                    <td class="name">Sales Group</td>

                    <td>{singleOutletData?.sales_group}</td>
                  </tr>
                  <tr>
                    <td class="name">CreditSales</td>

                    <td>{singleOutletData?.credit_sales}</td>
                  </tr>
                  <tr>
                    <td class="name">Comments</td>

                    <td>{singleOutletData?.comments}</td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>

        </Modal.Body>

      </Modal>

      {/* CSV File Upload Modal */}
      <Modal
        size="lg"
        show={showUploadCSV}
        onHide={() => setShowCreateCostCenterModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Upload Outlet CSV File
          </Modal.Title>
          <FalconCloseButton
            onClick={() => setShowUploadCSV(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <>
            <div
              {...getRootProps({ className: "dropzone-area py-6" })}
            >
              <input {...getInputProps({ multiple: false })} />
              <Flex justifyContent="center">
                <img src={cloudUpload} alt="" width={25} className="me-2" />
                <p className="fs-0 mb-0 text-700">Drop your file here</p>
              </Flex>
            </div>
            <div className="mt-3">
              {acceptedFiles.length > 0 && (
                <>
                  <h6>File</h6>
                  <ul>{files}</ul>
                </>
              )}
            </div>
          </>

          <Button variant="primary" onClick={handleCSVUpload}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default index;
