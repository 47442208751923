import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GreetingsDate from "components/dashboards/crm/greetings/GreetingsDate";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import { formatNumber } from "helpers/utils";

const PurchaseConfirm = ({ setStep, selectedDate, selectedProductionDate, selectedExpiryDate, batchNo, productsData, setProductsData, setCount }) => {
    const [isLoading, setIsLoading] = useState(false);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const navigate = useNavigate();


    console.log(selectedDate)
    // handle remove product
    const handleRemoveProduct = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {

            const deleteProduct = (id) => {
                axios.delete(`${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/${id}`, { headers: authHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            const result = productsData?.filter(product => product?._id !== id);
                            setProductsData(result);

                            Swal.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success"
                            });
                        }
                    })
                    .catch((error) => {
                        handleApiResponseErrors(error.response);
                    });
            }

            deleteProduct(id);

        }
    }


    const handleSubmit = async () => {
        const receivedProduct = productsData?.map((product) => {
            const receivedProduct = {
                _id: product?._id,
                userId: distributorInfo?.userId,
                companyName: distributorInfo?.companyName,
                factoryName: distributorInfo?.factoryName,
                productCategory: product?.categoryName,
                skuName: product?.skuName,
                productId: product?._id,
                rcvQtyCTN: product?.ctn,
                rcvQtyPCS: product?.pcs,//product?.pcs,
                rcvQtyMt: product?.weightMT,
                perPCPrice: 0,
                productionDate: selectedProductionDate,
                rcvDate: selectedDate,
                purchaseStatus: 2,
                status: 1,
                note: product?.note || "",
                // rcvMonth: selectedDate?.split("-")[1],
                rcvMonth: selectedDate?.split("-")[1].replace(/^0+/, ""),
                rcvYear: selectedDate?.split("-")[0],
            };
            return receivedProduct;
        });

        try {
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/multiUpdateRcvProduction`, receivedProduct, { headers: authHeader() });
            if (response.data.status || response.status === 200) {
                Swal.fire({
                    title: "Received!",
                    text: "Your file has been received.",
                    icon: "success"
                });
                navigate("/inventory/purchasePanel");
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }




    };

    if (isLoading) {
        return <LoadingIcon />;
    }



    return (
        <>

            <Card className="mb-3">
                <Card.Header>
                    <div className="">
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase from :</h6>
                                <Form.Control type="text" placeholder="Enter Vendor Name" value={productsData[0]?.purchaseFrom} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase For :</h6>
                                <Form.Control type="text" placeholder="Enter Factory Name" value={productsData[0]?.factoryName} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">PO No. :</h6>
                                <Form.Control type="text" placeholder="Enter PO No." value={productsData[0]?.PONo} disabled />
                            </Form.Group>
                        </Row  >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transport :</h6>
                                <Form.Control type="text" placeholder="Enter PO No." value={productsData[0]?.transportName} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transport No.:</h6>
                                <Form.Control type="text" placeholder="Enter PO No." value={productsData[0]?.transportNo} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Name:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Name" value={productsData[0]?.driverName} disabled />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Number:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Number" value={productsData[0]?.driverPhone} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase Date :</h6>
                                <Form.Control type="text" placeholder="Enter Driver Number" value={new Date(productsData[0]?.purchaseDate).toLocaleDateString()} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Expiry Date :</h6>
                                <Form.Control type="text" placeholder="" value={new Date(productsData[0]?.expireDate).toLocaleDateString()} disabled />
                            </Form.Group>

                            <Form.Group as={Col} md="4" className="mt-3" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Production Date :</h6>
                                <Form.Control type="text" placeholder="Enter Production Date" value={new Date(selectedProductionDate).toLocaleDateString()} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="mt-3" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase Receive Date :</h6>
                                <Form.Control type="text" placeholder="Enter Driver Number" value={new Date(selectedDate).toLocaleDateString()} disabled />
                            </Form.Group>
                        </Row>
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Sub Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Action</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Note</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        productsData?.map((product, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.productCategory}</td>
                                                <td className="align-middle text-center">{product?.subCategory}</td>
                                                <td className="align-middle text-center">{`${product?.skuName}(${product.erpId})`}</td>
                                                <td className="align-middle text-center">
                                                    {formatNumber(product?.ctn)}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {formatNumber(product?.pcs)}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {formatNumber(product?.weightMT)}
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Button variant="danger" onClick={() => handleRemoveProduct(product?._id)}>Cancel</Button>
                                                </td>
                                                <td className="align-middle text-center" style={{width:"150px"}}>
                                                    {product?.note}
                                                </td>
                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>

            <Button className="mt-2 px-5 me-3" variant="primary" type="submit" onClick={() => { handleSubmit(); }}>
                Confirm
            </Button>

            <Button className="mt-2 px-5 me-3" variant="primary" type="submit" onClick={() => { setStep(1); }}>
                Cancel
            </Button>

        </>
    );

};

export default PurchaseConfirm;
