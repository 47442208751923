import FalconComponentCard from 'components/common/FalconComponentCard'
import AppContext from 'context/Context'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    getAllDeliveries,
    getAreas,
    getDBPoints,
    getDistributors,
    getInventoryStocks,
    getRegions,
    getSchedulesByScheduleNo,
    getSchedulesDistributor,
    getZones,
    orderDeliverySubmit,
} from 'services/api'
import BottomTab from './BottomTab'
import DeliverableProductsUpdate from './DeliverableProductsUpdate'
import TopTab from './TopTab'

const DeliveryUpdate = ({ user }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { scheduleNav } = location.state
    console.log('scheduleNav', scheduleNav)
    const [first, setfirst] = useState('')
    const [validated, setValidated] = useState(false)

    const [zoneValue, setZoneValue] = useState(null)
    const [regionValue, setRegionValue] = useState(null)
    const [areaValue, setAreaValue] = useState(null)
    const [dbPointValue, setDBPointValue] = useState(null)
    const [distributorValue, setDistributorValue] = useState(null)
    const [scheduleNumber, setScheduleNumber] = useState(null)
    const [deliverableSchedules, setDeliverableSchedules] = useState([])
    const [selectedFactoryname, setSelectedFactoryName] = useState('')
    const [selectedSKUname, setSelectedSKUName] = useState('')
    const [selectedDoNo, setSelectedDoNo] = useState('')

    const { data: zoneData, isLoading: isLoadingZone } = useQuery({
        queryKey: ['zones'],
        queryFn: getZones,
    })

    const { data: regionData, isLoading: isLoadingRegion } = useQuery({
        queryKey: ['regions'],
        queryFn: getRegions,
    })

    const { data: areaData, isLoading: isLoadingArea } = useQuery({
        queryKey: ['areas'],
        queryFn: getAreas,
    })

    const { data: dbPointData, isLoading: isLoadingDBPoint } = useQuery({
        queryKey: ['dbPoints'],
        queryFn: getDBPoints,
    })

    const { data: distributorData, isLoading: isLoadingDistributor } = useQuery(
        {
            queryKey: ['distributors'],
            queryFn: getDistributors,
        }
    )

    const {
        data: scheduleDataByDis,
        isLoading: isLoadingScheduleByDis,
        isError: scheduleByDisError,
        refetch: scheduleByDisRefetch,
    } = useQuery({
        queryKey: ['schedules'],
        queryFn: () => getSchedulesDistributor(distributorValue.userId),
    })

    const {
        data: scheduleDataByNo,
        isLoading: isLoadingScheduleByNo,
        isError: scheduleByNoError,
        refetch: scheduleByNoRefetch,
    } = useQuery({
        queryKey: ['schedulesNo'],
        queryFn: () => getSchedulesByScheduleNo(scheduleNav.scheduleNo),
    })

    const {
        data: stockData,
        isLoading: isLoadingStock,
        isError,
        refetch,
    } = useQuery({
        queryKey: ['stocks'],
        queryFn: () =>
            getInventoryStocks(selectedSKUname, user.data.factoryName),
    })

    const {
        data: deliveryData,
        isLoading: isLoadingDelivery,
        isError: deliveryError,
        refetch: deliveryRefetch,
    } = useQuery({
        queryKey: ['delivery'],
        queryFn: () =>
            // getDeliveriesByDO(
            //     selectedDoNo,
            //     selectedSKUname,
            //     scheduleNumber?.scheduleNo || ''
            // ),
            getAllDeliveries(),
    })

    useEffect(() => {
        scheduleByDisRefetch()
    }, [distributorValue])

    useEffect(() => {
        refetch()
    }, [selectedSKUname, selectedFactoryname])

    useEffect(() => {
        deliveryRefetch()
    }, [selectedDoNo, selectedSKUname, scheduleNumber])

    useEffect(() => {
        if (scheduleNav && !distributorValue) {
            setScheduleNumber({
                value: scheduleNav._id,
                label: scheduleNav.scheduleNo,
                ...scheduleNav,
            })
        } else if (distributorValue) {
        }
    }, [scheduleNav])

    const handleSubmit = (event) => {
        console.log('distributorValue', distributorValue)
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }

        setValidated(true)
    }

    const {
        config: { isNavbarVerticalCollapsed, isFluid, isRTL },
        setConfig,
    } = useContext(AppContext)
    useEffect(() => {
        const handleClick = () => {
            document
                .getElementsByTagName('html')[0]
                .classList.toggle('navbar-vertical-collapsed')
            setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed)
        }

        handleClick()
    }, [])

    // const zoneOptions = zoneData?.data?.payload?.result.map((zone) => ({
    //     value: zone._id,
    //     label: zone.name,
    //     ...zone,
    // }))

    let zoneOptions = []
    if (zoneData && zoneData.data.payload.result.length) {
        zoneOptions = zoneData.data.payload.result.map((zone) => ({
            value: zone._id,
            label: zone.name,
            ...zone,
        }))
    }

    // const regionOptions = regionData?.data?.payload?.result.map((region) => ({
    //     value: region._id,
    //     label: region.regionName,
    //     ...region,
    // }))

    let regionOptions = []
    if (regionData && regionData.data.payload.result.length && zoneValue) {
        regionOptions = regionData.data.payload.result
            .filter((item) => item.zoneName == zoneValue.label)
            .map((region) => ({
                value: region._id,
                label: region.regionName,
                ...region,
            }))
    }

    // const areaOptions = areaData?.data?.payload?.result.map((area) => ({
    //     value: area._id,
    //     label: area.areaName,
    //     ...area,
    // }))

    let areaOptions = []
    if (areaData && areaData.data.payload.result.length && regionValue) {
        areaOptions = areaData.data.payload.result
            .filter((item) => item.regionName == regionValue.label)
            .map((area) => ({
                value: area._id,
                label: area.areaName,
                ...area,
            }))
    }

    // const dbPointOptions = dbPointData?.data?.payload?.result.map(
    //     (dbPoint) => ({
    //         value: dbPoint._id,
    //         label: dbPoint.dbPointName,
    //         ...dbPoint,
    //     })
    // )

    let dbPointOptions = []
    if (dbPointData && dbPointData.data.payload.result.length && areaValue) {
        dbPointOptions = dbPointData.data.payload.result
            .filter((item) => item.areaName == areaValue.label)
            .map((dbPoint) => ({
                value: dbPoint._id,
                label: dbPoint.dbPointName,
                ...dbPoint,
            }))
    }

    // const distributorOptions = distributorData?.data?.payload?.distributors.map(
    //     (distributor) => ({
    //         value: distributor._id,
    //         label: distributor.distributorName,
    //         ...distributor,
    //     })
    // )

    let distributorOptions = []
    if (
        distributorData &&
        distributorData.data.payload.distributors.length &&
        dbPointValue
    ) {
        distributorOptions = distributorData.data.payload.distributors
            .filter((item) => item.dbPoint == dbPointValue.label)
            .map((distributor) => ({
                // value: distributor._id,
                value: distributor.userId,
                label: distributor.distributorName,
                ...distributor,
            }))
    }

    // const scheduleOptions = scheduleDataByDis?.data?.payload?.result.map(
    //     (schedule) => ({
    //         value: schedule._id,
    //         label: schedule.scheduleNo,
    //         ...schedule,
    //     })
    // )

    const getUniqueValues = (arr, property) => {
        const values = new Set()

        arr.forEach((obj) => {
            if (obj[property]) {
                values.add(obj[property])
            }
        })

        return [...values]
    }

    let scheduleOptions = []
    if (
        scheduleDataByDis &&
        scheduleDataByDis.data.payload.result.length &&
        distributorValue
    ) {
        scheduleOptions = scheduleDataByDis.data.payload.result
            .filter((item) => item.dealerId == distributorValue.value)
            .map((schedule) => ({
                value: schedule._id,
                label: schedule.scheduleNo,
                ...schedule,
            }))
    } else if (
        scheduleDataByNo &&
        scheduleDataByNo.data.payload.result.length &&
        scheduleNav
    ) {
        scheduleOptions = scheduleDataByNo?.data.payload.result.map(
            (schedule) => ({
                value: schedule._id,
                label: schedule.scheduleNo,
                ...schedule,
            })
        )
    }

    // const mySchedules = scheduleDataByDis?.data?.payload?.result || []

    let mySchedules = []
    if (scheduleDataByDis && scheduleDataByDis.data.payload.result.length) {
        mySchedules = scheduleDataByDis?.data?.payload?.result || []
    } else if (
        scheduleDataByNo &&
        scheduleDataByNo.data.payload.result.length
    ) {
        mySchedules = scheduleDataByNo?.data?.payload?.result || []
    }

    let allStocksData = []
    if (stockData && stockData.data.payload.result.length) {
        allStocksData = stockData.data.payload.result
    }

    let allDeliveries = []
    if (deliveryData && deliveryData.data.payload.result.length) {
        allDeliveries = deliveryData.data.payload.result
    }

    const getFormattedDate = (data) => {
        const originalDateString = data
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`

        return formattedDateString
    }

    const getFormattedDate2 = (dt) => {
        const deliveryDate = new Date(dt)

        const year = deliveryDate.getFullYear()
        const month = String(deliveryDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(deliveryDate.getDate()).padStart(2, '0')

        const formattedDate = `${year}-${month}-${day}`

        return formattedDate
    }

    const handleScheduleSubmit = () => {
        const challan =
            Math.floor(Math.random() * 1000000000) + '-' + user.data.userId
        const postArr = deliverableSchedules
            .filter((tr) => tr.conQtyCtn > 0)
            .map((item) => {
                return {
                    doNo: item.doNo,
                    dbPoint: item.dbPoint,
                    dealerId: item.dealerId,
                    companyName: item.companyName,
                    factoryName: item.factoryName,
                    skuName: item.skuName,
                    productId: item.productId,
                    userId: user.data.userId,
                    scheduleNo: item.scheduleNo,
                    challanNo: challan,
                    zoneName: item.zoneName,
                    regionName: item.regionName,
                    areaName: item.areaName,
                    delQtyCtn: item.conQtyCtn,
                    delQtyPcs: item.conQtyPcs,
                    orderDate: item.orderDate,
                    scheduleDate: item.scheduleDate,
                    deliveryDate: item.deliveryDate,
                    status: 9,
                    productCategory: item.productCategory,
                }
            })

        orderDeliverySubmit(postArr)
            .then((res) => {
                if (res.data?.success) {
                    setSelectedFactoryName('')
                    setSelectedSKUName('')
                    setSelectedDoNo('')
                    toast.success(res.data?.message)
                    navigate('/distribution/productdelivery/pending')
                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    console.log(e)
                    toast.error(e.response?.data?.errors)
                }
            })
    }

    const getDeliveredQuantityBySKUShD = (skuName, scheduleNo, doNo) => {
        const totalQty = allDeliveries
            .filter(
                (item) =>
                    item.skuName == skuName &&
                    item.scheduleNo == scheduleNo &&
                    item.doNo == doNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    const getDeliveredQuantityBySKUOnly = (skuName, factoryName) => {
        const totalQty = allDeliveries
            .filter(
                (item) =>
                    item.skuName == skuName && item.factoryName == factoryName
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return totalQty
    }

    return (
        <>
            <Row className="g-3 mb-3">
                <Col lg={7} xl={8}>
                    <FalconComponentCard noPreview>
                        <FalconComponentCard.Header light={false} noPreview>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <span>Delivery of the Schedule No: </span>
                                <span style={{ fontWeight: 'bold' }}>
                                    &nbsp;{scheduleNav.scheduleNo}
                                </span>
                            </div>
                        </FalconComponentCard.Header>
                        <FalconComponentCard.Body language="jsx">
                            {/* <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                            >
                                <Row className="mb-3">
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        controlId="exampleCity"
                                    >
                                        <Form.Label>Zone</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={zoneOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={zoneValue}
                                            onChange={(value) =>
                                                setZoneValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid city.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleState"
                                    >
                                        <Form.Label>Region</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={regionOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={regionValue}
                                            onChange={(value) =>
                                                setRegionValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid state.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Area</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={areaOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={areaValue}
                                            onChange={(value) =>
                                                setAreaValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>DB Point</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={dbPointOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={dbPointValue}
                                            onChange={(value) =>
                                                setDBPointValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>Distributors</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={distributorOptions}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={distributorValue}
                                            onChange={(value) =>
                                                setDistributorValue(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        controlId="exampleZip"
                                    >
                                        <Form.Label>
                                            Schedule Numbers
                                        </Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={getUniqueValues(
                                                scheduleOptions,
                                                'label'
                                            ).map((item) => ({
                                                value: item,
                                                label: item,
                                            }))}
                                            placeholder="Select..."
                                            classNamePrefix="react-select"
                                            value={scheduleNumber}
                                            onChange={(value) =>
                                                setScheduleNumber(value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid zip.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Button type="submit">View Undelivered</Button>/

                            </Form> */}
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
                <Col lg={5} xl={4}>
                    <TopTab
                        title={'Available Stock'}
                        className="h-lg-60"
                        files={
                            selectedSKUname &&
                            selectedFactoryname &&
                            deliverableSchedules.length
                                ? [
                                      {
                                          skuName: selectedSKUname,
                                          rcvQtyCTN:
                                              allStocksData.reduce(
                                                  (
                                                      accumulator,
                                                      currentValue
                                                  ) => {
                                                      return (
                                                          accumulator +
                                                          currentValue.rcvQtyCTN
                                                      )
                                                  },
                                                  0
                                              ) -
                                              getDeliveredQuantityBySKUOnly(
                                                  selectedSKUname,
                                                  selectedFactoryname
                                              ),
                                      },
                                  ]
                                : []
                        }
                    />
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col lg={7} xl={8}>
                    {isLoadingDelivery ? (
                        <h1>Loading...</h1>
                    ) : (
                        <DeliverableProductsUpdate
                            setSelectedFactoryName={setSelectedFactoryName}
                            setSelectedSKUName={setSelectedSKUName}
                            setSelectedDoNo={setSelectedDoNo}
                            setDeliverableSchedules={setDeliverableSchedules}
                            deliverableSchedules={deliverableSchedules}
                            mySchedules={mySchedules}
                            scheduleNumber={scheduleNumber}
                            handleScheduleSubmit={handleScheduleSubmit}
                            getDeliveredQuantityBySKUShD={
                                getDeliveredQuantityBySKUShD
                            }
                            myFactory={user?.data?.factoryName}
                            allDeliveries={allDeliveries}
                        />
                    )}
                </Col>
                <Col lg={5} xl={4}>
                    <BottomTab
                        title={'Delivery Confirmed Info'}
                        files={
                            selectedSKUname &&
                            selectedFactoryname &&
                            selectedDoNo &&
                            deliverableSchedules.length
                                ? allDeliveries.filter(
                                      (tom) =>
                                          tom.doNo == selectedDoNo &&
                                          tom.skuName == selectedSKUname
                                  )
                                : []
                        }
                        className="h-lg-100"
                    />
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryUpdate)
