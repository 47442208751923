import { Table } from 'react-bootstrap'
import { formatNumber } from 'helpers/utils'
const DistributorDetailsTable = ({ distributorDetails, distributiorScr }) => {
   
    return (
        <Table bordered striped className="fs--1 mb-0 bg-white mt-5">
            <thead>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    {/* <th className="align-middle white-space-nowrap text-center">Field</th>
                <th className="align-middle white-space-nowrap text-center">Value</th> */}
                </tr>
            </thead>
            <tbody className="list" id="table-purchase-body">
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">User ID</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.userId}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">
                        Credit Limit BDT{' '}
                    </td>
                    <td className="align-middle text-center">
                        {formatNumber(distributorDetails?.crBDTLimit)}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">BG BDT </td>
                    <td className="align-middle text-center">
                        {formatNumber(distributorDetails?.bgAmount)}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Special Credit </td>
                    <td className="align-middle text-center">
                        {formatNumber(distributiorScr && distributiorScr)}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Note </td>
                    <td className="align-middle text-center">
                        {distributorDetails?.note}
                    </td>
                </tr>
                {distributorDetails?.superDBId && (
                    <tr
                        style={{
                            fontSize: 'medium',
                            fontWeight: '500',
                            fontFamily: 'Poppins',
                            color: '#354151',
                        }}
                    >
                        <td className="align-middle text-center">
                            Super DB ID
                        </td>
                        <td className="align-middle text-center">
                            {distributorDetails?.superDBId}
                        </td>
                    </tr>
                )}
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Zone Name</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.zoneName}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Region Name</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.regionName}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Area Name</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.areaName}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Company Name</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.companyName}
                    </td>
                </tr>

                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">
                        DB Opening Date
                    </td>
                    <td className="align-middle text-center">
                        {new Date(
                            distributorDetails?.dbOpeningDate
                        ).toLocaleDateString()}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">DB Point</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.dbPoint}
                    </td>
                </tr>

                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">ERP ID</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.erpId}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Has App</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.hasApp ? 'Yes' : 'No'}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Has PC</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.hasPC ? 'Yes' : 'No'}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Has Printer</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.hasPrinter ? 'Yes' : 'No'}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Has Storage</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.hasStorage ? 'Yes' : 'No'}
                    </td>
                </tr>

                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Phone Number</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.phoneNumber}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Proprietor</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.proprietor}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Status</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.status ? 'Active' : 'Inactive'}
                    </td>
                </tr>

                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">User Role</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.userRole}
                    </td>
                </tr>
                <tr
                    style={{
                        fontSize: 'medium',
                        fontWeight: '500',
                        fontFamily: 'Poppins',
                        color: '#354151',
                    }}
                >
                    <td className="align-middle text-center">Address</td>
                    <td className="align-middle text-center">
                        {distributorDetails?.address}
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}

export default DistributorDetailsTable
