import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TKGL_LOGO from 'assets/img/logos/tkgl/techknowgram.fa1a6b163526beb3e5a6.png'
import ScrollToTop from "react-scroll-to-top";

const Footer = () => (
  <footer className="footer">
    <Row className="my-3" style={{ textAlign: 'center', display: "block" }}>
      <Col sm="auto">
        <p className="mb-0 text-600">
          © All rights reserved TK group of industries | Powered By {' '}
          <span className="d-none d-sm-inline-block"> </span>
          <br className="d-sm-none" />
          <a href="https://techknowgram.com" target='_blank' className='text-decoration-none '><strong style={{ color: '#483285' }}>TechKnowGram Limited</strong></a>
          {/* <img className='ms-3' src={TKGL_LOGO} width="100" /> */}
        </p>
      </Col>
      <Col sm="auto">
        {/* <p className="mb-0 text-600">v{version}</p> */}
      </Col>
    </Row>
    
      
    <ScrollToTop smooth style={{ backgroundColor: "#FFFFFF", color: "#fff" }} />
    
  </footer>
);

export default Footer;
