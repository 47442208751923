import SuperDBTarget from "./Target/index";
const { Row } = require("react-bootstrap");

const SuperDbDashboard = ({ setIsLoading }) => {
    return (
        <div>
            <Row className="g-3 mb-3">
                <SuperDBTarget setIsLoading={setIsLoading} />
            </Row>
        </div>
    );
};

export default SuperDbDashboard;