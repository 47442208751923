import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import ReceivedPanelConfirm from "./ReceivedPanelConfirm";

const DistributorReceivedPanel = () => {
    const [step, setStep] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedExpiryDate, setSelecteExpitydDate] = useState(null);
    const [count, setCount] = useState(0);
    const [batchNo, setBatchNo] = useState(null);
    const [productsData, setProductsData] = useState([]);
    const [checkedProducts, setCheckProducts] = useState([]);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);


    console.log("selectedDate", selectedDate);
    console.log("selectedExpiryDate", selectedExpiryDate);

    // DATE
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    // DATE
    const handleExpityDateChange = (date) => {
        setSelecteExpitydDate(date);
    };



    const handleReceivedProducts = (event, index, product, fieldName) => {
        const { value } = event.target;
        const updatedProducts = [...productsData];

        // Depending on the fieldName, perform different actions
        switch (fieldName) {
            case 'ctn':
                const calculatedPcs = value * product?.pcsCTN;
                updatedProducts[index].ctn = value;
                updatedProducts[index].pcs = calculatedPcs;
                break;
            case 'pcs':
                updatedProducts[index].pcs = value;
                break;
            default:
                break;
        }



        setProductsData(updatedProducts);
    };


    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?search=${searchText}`;
                const response = await axios.get(url, { headers: authHeader() });
                console.log(response)
                if (response.data.success || response.status === 200) {
                    const exactMatchProducts = await response?.data?.payload?.products
                        ?.filter(product =>
                            product?.factoryName?.every(factory =>
                                factory === distributorInfo?.factoryName
                            )
                        )

                    setProductsData(exactMatchProducts);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [searchText, count, distributorInfo?.factoryName]);

    console.log(productsData)

    const handleNext = () => {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select a date",
                icon: "warning"
            });
            return;
        }
        const checkedProducts = productsData.filter((product) => product.ctn && product.pcs);
        if (checkedProducts?.length > 0) {
            setCheckProducts(checkedProducts);
            setStep(2);

        } else {
            Swal.fire({
                title: "Please fill the quantity",
                icon: "warning"
            });
            return;
        }
    }



    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>
            <PageHeader
                title="Received Panel"
                className="mb-3"
            ></PageHeader>

            {step === 1 && <><Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader isNew={false} isExport={false} isSearch={true} setSearchText={setSearchText} />
                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                            <Col xs="auto">
                                <h6 className="text-700 mb-0 text-nowrap">Received Date :</h6>
                            </Col>
                            <Col md="auto">
                                <GetSingleDate onDateChange={handleDateChange} />
                            </Col>

                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap">Expiry Date :</h6>
                                <GetSingleDate onDateChange={handleExpityDateChange} />

                                <h6 className="text-700 mb-0 text-nowrap">Batch No :</h6>
                                <Form.Control type="text" placeholder="" onChange={(event) => setBatchNo(event.target.value)} />

                            </Col>


                        </Row>

                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        productsData?.map((product, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.categoryName}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">
                                                    <Form.Control type="text" placeholder="" onChange={(event) => handleReceivedProducts(event, index, product, "ctn")} />
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product && product.pcs !== undefined ? product.pcs : ""}
                                                        onChange={(event) => handleReceivedProducts(event, index, product, "pcs")}
                                                        readOnly
                                                    />

                                                </td>

                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
                <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleNext(); }}>
                    Received
                </Button></>}

            {
                step === 2 && <ReceivedPanelConfirm setStep={setStep} selectedDate={selectedDate} selectedExpiryDate={selectedExpiryDate} batchNo={batchNo} checkedProducts={checkedProducts} setCheckProducts={setCheckProducts} setCount={setCount} />
            }

        </>
    );

};

export default DistributorReceivedPanel;
