import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SoftBadge from 'components/common/SoftBadge'
import { formatNumber } from 'helpers/utils'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import image from 'assets/img/icons/spot-illustrations/corner-2.png'
import Background from 'components/common/Background'
import { Link } from 'react-router-dom'


const PendingSales = ({ data, selectedFromDate, selectedToDate }) => {
    const navigate = useNavigate()
    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative" >
                <SoftBadge bg="info" pill className="">
                    Pending..
                </SoftBadge>
                <h5 style={{ fontSize: '1rem' }}>Demand Order (DO)</h5>
                <div className=" display-4 fs-1 mb-2 fw-normal font-sans-serif text-black" >
                    No. {formatNumber(data?.demandOrderNo) || 0}
                </div>
                {/* <Link
                    to="/accounts/due-payment-list"
                    className="fw-semi-bold fs--1 text-nowrap"
                > */}

                <FontAwesomeIcon
                    icon="angle-right"
                    className="ms-1"
                    transform="down-1"
                />
                {/* </Link> */}

            </Card.Body>
        </Card>
    )
}

export default PendingSales
