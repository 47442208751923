import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import TargetTable from './TargetTable'
import TargetGraph from './TargetGraph'

export const index = ({ selectedMonth, selectedYear, orders, targetAndAchievement }) => {
    return (
        <>
            <Col lg={7} xl={8}>
                <TargetTable data={orders} />
            </Col>
            <Col lg={5} xl={4}>
                <TargetGraph data={targetAndAchievement} orders={orders} />
            </Col>
        </>
    )
}
export default index;
