import FalconComponentCard from "components/common/FalconComponentCard";
import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const FormatCsv = () => {
    const [products, setProducts] = useState([]);
    const [distributors, setDistributors] = useState([]);
    const [selectedZone, setSelectedZone] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedDbPoint, setSelectedDbPoint] = useState('');
    const [selectedDistributor, setSelectedDistributor] = useState('');
    const [zoneList, setZoneList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [dbPointList, setDbPointList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    // Excel Download
    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const csv = XLSX.utils.sheet_to_csv(ws);
        const data = new Blob([csv], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    // Fetch Zone, Region, Area, DB Point, Distributor
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const url = ` ${process.env.REACT_APP_SETTINGS_BASE_URL}zone`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setZoneList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedZone) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.regionName,
                            value: data.regionName
                        }));

                        setRegionList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [selectedZone]);

    useEffect(() => {
        if (selectedRegion) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}area?regionName=${selectedRegion}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.areaName,
                            value: data.areaName
                        }));

                        setAreaList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedArea) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`;
                    const response = await axios.get(url, { headers: authHeader() });
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(data => ({
                            id: data._id,
                            label: data.dbPointName,
                            value: data.dbPointName
                        }));
                        setDbPointList(modifyData);
                    }
                }
                catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
            fetchData();
        }
    }, [selectedArea])


    useEffect(() => {
        if (selectedZone) {
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?zoneName=${selectedZone}&regionName=${selectedRegion}&areaName=${selectedArea}&dbPoint=${selectedDbPoint}`
                    const response = await axios.get(url, { headers: authHeader() });
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.distributors.map(data => ({
                            id: data.userId,
                            label: data.distributorName,
                            value: data.userId,
                            phoneNumber: data.phoneNumber,
                            userRole: data.userRole,
                        }));
                        setDistributorList(modifyData);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
            fetchData();
        }
    }, [selectedDbPoint])

    // Fetch products
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products/dbUploadProductFormat`;
                const response = await axios.get(url, { headers: authHeader() });
                if (response.status === 200) {
                    setProducts(response.data.payload.productInfo)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            }
        }
        fetchProducts();
    }, [])

    // Fetch Distributor
    useEffect(() => {
        const requestData = {
            zoneName: selectedZone,
            regionName: selectedRegion,
            areaName: selectedArea,
            dbPoint: selectedDbPoint,
            userId: selectedDistributor
        }
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `  ${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/zoneRegionAreaDBFormat`,
                    requestData,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.DBInfo;
                    setDistributors(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            }
        };
        fetchData();
    }, [selectedZone, selectedRegion, selectedArea, selectedDbPoint, selectedDistributor]);

    const handleFormatDowanload = () => {

        const combined = [];
        distributors.forEach(distributor => {
            
            products.forEach(product => {
                const price = (distributor.userRole == "SUPER DISTRIBUTOR" || distributor.userRole == "GENERAL DISTRIBUTOR") ?
                    product.distributorPrice : distributor.userRole == "COMMISSION DISTRIBUTOR" ? product.commPrice : distributor.userRole == "WHOLESALE DISTRIBUTOR" ? product.wholesellPrice : distributor.userRole == "SPOT DISTRIBUTOR" ? product.spotPrice : distributor.userRole == "SPECIAL DISTRIBUTOR" ? product.specialPCPrice : "";
                const formatData = { distributorName: distributor.distributorName, dealerId: distributor.userId, userRole: distributor.userRole, zoneName: distributor.zoneName, regionName: distributor.regionName, areaName: distributor.areaName, dbPoint: distributor.dbPoint, skuName: product.skuName, categoryName: product.categoryName, subcategory: product?.subcategory, skuPrice: price, ctnPrice: (product?.pcsCTN * price), superDBId: distributor?.superDBId, targetQuantityCtn: 0, targetQuantityPcs: 0 }
                combined.push(
                    formatData
                );
            });
        });
        const addIndex = combined.map((data, index) => {
            data.SL = index + 1;
            return data;
        });

        console.log("inde", addIndex);
        console.log("combined", combined)
        exportToCSV(addIndex, "Distributor_Product_Format");

    };


    // if (isLoading) {
    //     return <LoadingIcon />;
    // }

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <CustomersTableHeader
                        isNew={false}
                        title="Target Format CSV"
                        isExport={false}
                        // excelFileName="Payment Adjustment"
                        // excelData={combinedData}
                        table
                    />

                </Card.Header>
            </Card>
            <FalconComponentCard >

                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{

                        }}

                        enableReinitialize={false}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Zone</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={zoneList}
                                                placeholder={isLoading ? "Loading..." : "Select Zone"}
                                                classNamePrefix="react-select"
                                                name="zoneName"
                                                value={values.zoneName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("zoneName", selectedOption);
                                                    setSelectedZone(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("zoneName", true, true) }}
                                                isLoading={isLoading}
                                            />
                                            {touched.zoneName && errors.zoneName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.zoneName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Region</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={regionList}
                                                placeholder={isLoading ? "Loading..." : "Select Region"}
                                                classNamePrefix="react-select"
                                                name="regionName"
                                                value={values.regionName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("regionName", selectedOption);
                                                    setSelectedRegion(selectedOption.value)

                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("regionName", true, true) }}
                                                isLoading={isLoading}
                                            />
                                            {touched.regionName && errors.regionName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.regionName}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Area</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={areaList}
                                                placeholder={isLoading ? "Loading..." : "Select Area"}
                                                classNamePrefix="react-select"
                                                name="areaName"
                                                value={values.areaName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("areaName", selectedOption);
                                                    setSelectedArea(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("areaName", true, true); }}
                                                isLoading={isLoading}
                                            />
                                            {touched.areaName && errors.areaName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.areaName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select DB Point</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={dbPointList}
                                                placeholder={isLoading ? "Loading..." : "Select DB Point"}
                                                classNamePrefix="react-select"
                                                name="dbPointName"
                                                value={values.dbPointName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("dbPointName", selectedOption);
                                                    setSelectedDbPoint(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("dbPointName", true, true); }}
                                                isLoading={isLoading}
                                            />
                                            {touched.dbPointName && errors.dbPointName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.dbPointName}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Distributor</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributorList}
                                                placeholder={isLoading ? "Loading..." : "Select Distributor"}
                                                classNamePrefix="react-select"
                                                name="distributorName"
                                                value={values.distributorName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("distributorName", selectedOption);
                                                    setSelectedDistributor(selectedOption.value);
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("distributorName", true, true); }}
                                                isLoading={isLoading}
                                            />
                                            {touched.distributorName && errors.distributorName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.distributorName}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Button disabled={isLoading} onClick={handleFormatDowanload}> Dowanload</Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default FormatCsv;