import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { date } from "is_js";
import { formatNumber } from "helpers/utils";

const DepositsList = () => {
    const [depositList, setDepositList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState(0);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);


    // deposit list
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;
        const fetchData = async () => {
            try {
                let response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/deposits?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`,
                    { headers: authHeader() }
                );

                if (distributorInfo.userRole == "Accounts") {
                    response = await axios.get(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/getDepositsConfirmed?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`,
                        { headers: authHeader() }
                    );
                } else if (distributorInfo.userRole == "Finance") {
                    response = await axios.get(
                        `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/getDepositsApprovedAll?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`,
                        { headers: authHeader() }
                    );
                }

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.result;
                    const result = data.map((item, index) => { return { ...item, index: startIndex + index } })
                    const totalPages = response?.data?.payload?.pagination?.totalPages;

                    setTotalPages(totalPages)
                    setDepositList(result);
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount, searchText]);

    // DELETE DISTRIBUTOR

    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/deposit/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };

    // VIEW IMAGE
    const viewImage = async (imageId) => {
      
        try {
            const response = await axios.get(`https://khoz.net:3015/api/v1/image/${imageId}`, { headers: authHeader() });
            if (response.data.success && response.data.payload) {
                const base64Image = response.data.payload.imageData.image;

                // Check if the base64 string contains the data URL prefix and remove it if present
                const base64Prefix = 'data:image/jpeg;base64,';
                let base64String;
                if (base64Image.startsWith(base64Prefix)) {
                    base64String = base64Image.substring(base64Prefix.length);
                } else {
                    base64String = base64Image;
                }

                // Convert base64 to Blob
                try {
                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'image/jpeg' });

                    // Create a URL for the Blob and open it in a new tab
                    const imageUrl = URL.createObjectURL(blob);
                    window.open(imageUrl, '_blank');
                } catch (e) {
                    console.error('Error decoding base64 string:', e);
                }
            } else {
                console.error('Failed to fetch image:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching the image:', error);
        }
    };



    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "SL",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "bankNames",
            Header: "Bank Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { bankNames } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{bankNames ? bankNames : "Blance Adjustment"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "bankAccountNo",
            Header: "Account No",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { bankAccountNo } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{bankAccountNo}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "depositorsBankName",
            Header: "Depositor Bank Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { depositorsBankName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{depositorsBankName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "depositorsBranchName",
            Header: "Depositor Branch Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { depositorsBranchName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{depositorsBranchName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "drBDT",
            Header: "Amount BDT",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { drBDT } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{formatNumber(drBDT)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "depositorPhone",
            Header: "Depositor Phone",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { depositorPhone } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{depositorPhone}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "trId",
            Header: "Transaction ID",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { trId } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{trId ? trId : "-"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "paymentNote",
            Header: "Payment Note",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { paymentNote } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{paymentNote}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "paymentDate",
            Header: "Payment Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { paymentDate } = rowData.row.original;
                const date = new Date(paymentDate);
                const formateDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{formateDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "status",
            Header: "Status",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{status === 0 ? "Pending..." : status === 1 ? "Forwarded by accounts" : status === 2 ? "Approved by Finance" : ""}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "action",
            Header: "Action",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { _id, trId, status } = rowData.row.original;
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {trId ? <> <Dropdown.Item onClick={() => viewImage(trId)}>

                                {/* <Link to={image} target="_blank"> */}
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="show"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">View Attachment</span>
                                </IconButton>
                                {/* </Link> */}
                            </Dropdown.Item>


                                {/* <Dropdown.Item href="">

                                    <Link to={`/master/deposit/edit/${_id}`}>
                                        <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon="edit"
                                            transform="shrink-2"
                                            iconAlign="middle"
                                            className="me-2"

                                        >
                                            <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                        </IconButton>
                                    </Link>
                                </Dropdown.Item> */}
                            </> : <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="show"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">No Attachment</span>
                                </IconButton>

                            }


                        </Dropdown.Menu>
                    </Dropdown>
                );
            },
        },
    ];

    /*******************
    Columns End.
    *******************/

    return (
        <>
            <PageHeader
                title="Deposits"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={depositList}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            newUrl="/master/deposit/add"
                            isSearch={true}
                            setSearchText={setSearchText}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );

};

export default DepositsList;
