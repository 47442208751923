import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { authHeader } from "utils";
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const RouteForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [distributionPointList, setDistributionPointList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [getRouteById, setGetRouteById] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();


    // Load Single Route Data
    useEffect(() => {
        if (updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `routes/${updateID}`
            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setGetRouteById(response.data?.data);
                    }
                })
                .catch((error) => {
                    handleApiResponseErrors(error.response);
                })
                .finally(
                    setIsLoading(false)
                )
        }

    }, [updateID])



    // Load All Distribution Point
    useEffect(() => {
        setIsLoading(true);
        const url = process.env.REACT_APP_AREA_MANAGEMENT_BASE_URL + 'distribution-points';
        axios.get(url, { headers: authHeader() })
            .then(response => {
                const modifyDistributionPoint = response.data?.map(item => {
                    return {
                        id: item.id,
                        label: item.distribution_point_name,
                        value: item.id

                    }
                })
                setDistributionPointList(modifyDistributionPoint);

            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            })
            .finally(
                setIsLoading(false)
            )
    }, [])

    // Load All Distributor
    useEffect(() => {
        setIsLoading(true);
        const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + 'distributors';
        axios.get(url, { headers: authHeader() })
            .then(response => {

                const modifyDistributor = response.data.data?.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                        value: item.id

                    }
                })
                setDistributorList(modifyDistributor);

            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(
                setIsLoading(false)
            )
    }, [])


    const handleRouteSubmit = (values, actions) => {

        const routeInfo =
        {
            distribution_point_id: values.distribution_point_id.value,
            distributor_id: values.distributor_id.value,
            name: values.routeName,
            contribution_percentage: values.contributionPercentage,
            can_so_add_pos: values.addPermit,
            can_so_edit_pos: values.editPermit,
            status: true
        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + 'routes';
            axios.post(url, routeInfo, { headers: authHeader() })
                .then(response => {
                    const apiResponse = response.data;
                    if (apiResponse.success) {
                        toast.success(apiResponse.message);
                        navigate('/master/route')
                    }
                })
                .catch(error => {
                    handleApiResponseErrors(error.response);
                })
                .finally(
                    setIsLoading(false)
                )

        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `routes/${updateID}`;
            axios.put(updateUrl, routeInfo, { headers: authHeader() })
                .then(response => {
                    const apiResponse = response.data;
                    if (apiResponse.success) {
                        toast.success(apiResponse.message);
                        navigate('/master/route')
                    }
                })
                .catch(error => {
                    handleApiResponseErrors(error.response);
                })
                .finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const productSchema = Yup.object().shape({
        distribution_point_id: Yup.object().required("Please enter a value for the Distribution Point  field"),
        distributor_id: Yup.object().required("Please enter a value for the Distributor field"),
        routeName: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please enter a value for the Route Name field").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            })
        ,
        contributionPercentage: Yup.number().required("Contribution % is required"),
        addPermit: Yup.boolean(),
        editPermit: Yup.boolean(),
    });


    return (
        <>
            <PageHeader
                title={updateID ? 'Update Route' : 'Add New Route'}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            distribution_point_id: distributionPointList?.find(item => item.id == getRouteById?.distribution_point_id) || null,
                            distributor_id: distributorList?.find(item => item.id == getRouteById?.distributor_id) || null,
                            routeName: getRouteById?.name || '',
                            contributionPercentage: getRouteById?.contribution_percentage || 0,
                            addPermit: getRouteById.can_so_add_pos === 1 ? true : false || false,
                            editPermit: getRouteById.can_so_edit_pos === 1 ? true : false || false,
                        }}
                        validationSchema={productSchema}
                        onSubmit={handleRouteSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            resetForm
                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Distribution Point</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributionPointList}
                                                placeholder="Select Distribution Point"
                                                classNamePrefix="react-select"
                                                name="distribution_point_id"
                                                value={values.distribution_point_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "distribution_point_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {
                                                errors.distribution_point_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.distribution_point_id}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Distributor</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={distributorList}
                                                placeholder="Select Distributor"
                                                classNamePrefix="react-select"
                                                name="distributorId"
                                                value={values.distributor_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "distributor_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {
                                                errors.distributor_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.distributor_id}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>

                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Route Name</Form.Label>
                                            <Form.Control
                                                name="routeName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Route Name"
                                                required
                                                value={values.routeName}
                                            />
                                            {touched.routeName && errors.routeName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.routeName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Contribution %</Form.Label>
                                            <Form.Control
                                                name="contributionPercentage"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                placeholder="Enter Contribution %"
                                                required
                                                value={values.contributionPercentage}
                                            />
                                            {touched.contributionPercentage && errors.contributionPercentage && (
                                                <div style={{ color: "red" }}>
                                                    {errors.contributionPercentage}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <Row className="my-1">

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            {/* <Form.Label>Add Permission</Form.Label> */}
                                            <Form.Check
                                                name="addPermit"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.addPermit}
                                                type="checkbox"
                                                label="Add Permission"
                                                value={values.addPermit}
                                            />
                                            {touched.addPermit && errors.addPermit && (
                                                <div style={{ color: "red" }}>
                                                    {errors.addPermit}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            {/* <Form.Label>Edit Permission</Form.Label> */}
                                            <Form.Check
                                                name="editPermit"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.editPermit}
                                                type="checkbox"
                                                label="Edit Permission"
                                                value={values.editPermit}
                                            />
                                            {touched.editPermit && errors.editPermit && (
                                                <div style={{ color: "red" }}>
                                                    {errors.editPermit}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"

                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/route")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>

    );
};

export default RouteForm;


