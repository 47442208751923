import React from 'react';

const Details = () => {
    const orderSummary = [
        { orderId: '001', date: '2024-08-25', status: 'Delivered' },
        { orderId: '002', date: '2024-08-24', status: 'Processing' },
        // Add more orders as needed
    ];

    const paymentSummary = [
        { paymentId: 'P001', amount: '$150', date: '2024-08-25', method: 'Credit Card' },
        { paymentId: 'P002', amount: '$200', date: '2024-08-24', method: 'PayPal' },
        // Add more payments as needed
    ];

    return (
        <div className='d-flex'>
            <div className='w-50'>
                <h5 className='ms-2'>Order :</h5>
                <div className="table-responsive scrollbar">
                    <table className="table table-bordered table-striped fs--1 mb-0">
                        <thead >
                            <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                <th className="align-middle white-space-nowrap text-center ">
                                    DO-No</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    DB ID</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    DB Name</th>

                                <th className="align-middle white-space-nowrap text-center ">
                                    DB Point</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Order Qty Ctn</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Order Qty PCS</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Discount BDT</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Price</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Status</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Action</th>

                            </tr>
                        </thead>
                        <tbody className="list" id="table-purchase-body">
                            {
                                // distributorOrderList?.map((order, index) => {
                                //     return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                //         <td className="align-middle text-center"><input
                                //             type="checkbox"
                                //             checked={isChecked(order)}
                                //             onChange={() => handleCheckboxChange(order)}
                                //         /></td>
                                //         <td className="align-middle text-center">{order?.doNo}</td>
                                //         <td className="align-middle text-center">{order?.dealerId}</td>
                                //         <td className="align-middle text-center">{order?.distributorName}</td>

                                //         <td className="align-middle text-center">{order?.dbPoint}</td>
                                //         <td className="align-middle text-center">{order?.totalOrderQtyCtn}</td>
                                //         <td className="align-middle text-center">{order?.totalOrderQtyPCS}</td>
                                //         <td className="align-middle text-center">{order?.totalDiscountBDT}</td>
                                //         <td className="align-middle text-center">{order?.totalPrice}</td>
                                //         <td className="align-middle text-center" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                //             {order?.status === 2 && <FontAwesomeIcon icon={faClock} style={{ color: 'orange', marginRight: '5px' }} />} {/* Render the icon if status is 2 and set color to orange */}
                                //             {order?.status === 2 && "Pending.."} {/* Render the text if status is 2 */}
                                //         </td>
                                //     </tr>

                                // })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='w-50'>
                <h5 className='ms-2'>Payment :</h5>
                <div className="table-responsive scrollbar">
                    <table className="table table-bordered table-striped fs--1 mb-0">
                        <thead >
                            <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                <th className="align-middle white-space-nowrap text-center ">
                                    DO-No</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    DB ID</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    DB Name</th>

                                <th className="align-middle white-space-nowrap text-center ">
                                    DB Point</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Order Qty Ctn</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Order Qty PCS</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Discount BDT</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Price</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Status</th>
                                <th className="align-middle white-space-nowrap text-center ">
                                    Action</th>

                            </tr>
                        </thead>
                        <tbody className="list" id="table-purchase-body">
                            {
                                // distributorOrderList?.map((order, index) => {
                                //     return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                //         <td className="align-middle text-center"><input
                                //             type="checkbox"
                                //             checked={isChecked(order)}
                                //             onChange={() => handleCheckboxChange(order)}
                                //         /></td>
                                //         <td className="align-middle text-center">{order?.doNo}</td>
                                //         <td className="align-middle text-center">{order?.dealerId}</td>
                                //         <td className="align-middle text-center">{order?.distributorName}</td>

                                //         <td className="align-middle text-center">{order?.dbPoint}</td>
                                //         <td className="align-middle text-center">{order?.totalOrderQtyCtn}</td>
                                //         <td className="align-middle text-center">{order?.totalOrderQtyPCS}</td>
                                //         <td className="align-middle text-center">{order?.totalDiscountBDT}</td>
                                //         <td className="align-middle text-center">{order?.totalPrice}</td>
                                //         <td className="align-middle text-center" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                //             {order?.status === 2 && <FontAwesomeIcon icon={faClock} style={{ color: 'orange', marginRight: '5px' }} />} {/* Render the icon if status is 2 and set color to orange */}
                                //             {order?.status === 2 && "Pending.."} {/* Render the text if status is 2 */}
                                //         </td>
                                //     </tr>

                                // })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Details;
