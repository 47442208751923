import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Modal, Row, Table } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import useUserRole from 'hooks/useUserRole'
import { useDropzone } from 'react-dropzone'
import FalconCloseButton from 'components/common/FalconCloseButton'
import cloudUpload from 'assets/img/icons/cloud-upload.svg'
import { authHeaderForm } from 'state/ducs/auth/utils'
import { formatNumber } from 'helpers/utils'
import SimpleBarReact from 'simplebar-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SoftBadge from 'components/common/SoftBadge'
import image from 'assets/img/icons/spot-illustrations/corner-2.png'
import Background from 'components/common/Background'

const AvailableStock = () => {
    const [receivedList, setReceivedList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)
    const [pageDataCount, setPageDataCount] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [showUploadCSV, setShowUploadCSV] = useState(false)
    const [count, setCount] = useState(0)
    const userInfo = useSelector((state) => state.auth.user?.data)
    const userRole = useUserRole()

    //
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1

        const fetchData = async () => {
            let url
            let url2
            let url3
            if (userInfo?.userRole === 'Admin') {
                // url = `${"process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL"}productsRcv?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
                url2 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`
                url3 = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${userInfo.factoryName}`
            }
            if (userInfo?.userRole === 'Inventory') {
                // url = searchText !== "" ? `${"process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL"}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${"process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL"}productsRcv?factoryName=${userInfo?.factoryName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                url =
                    searchText !== ''
                        ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
                        : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/group/${userInfo.factoryName}`
                url2 =
                    searchText !== ''
                        ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`
                        : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/delivery/${userInfo.factoryName}`

                url3 =
                    searchText !== ''
                        ? `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${userInfo.factoryName}`
                        : `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/productTransferByDb/${userInfo.factoryName}`
            }

            try {
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const totalPages =
                        response?.data?.payload?.pagination?.totalPages
                    const result = response?.data?.payload?.result?.map(
                        (element, index) => ({
                            ...element,
                            index: startIndex + index,
                        })
                    )

                    setTotalPages(totalPages)
                    setReceivedList(result)

                    ///////////////////////////
                    const response2 = await axios.get(url2, {
                        headers: authHeader(),
                    })

                    if (response2.data.success || response2.status === 200) {
                        const totalPages2 =
                            response2?.data?.payload?.pagination?.totalPages
                        const result2 = response2?.data?.payload?.result?.map(
                            (element, index) => ({
                                ...element,
                                index: startIndex + index,
                            })
                        )

                        const modifiedResult = result.map((item) => ({
                            ...item,
                            delivery: result2?.find(
                                (my) => my.skuName == item.skuName
                            ),
                        }))

                        setTotalPages(totalPages)
                        console.log('modifiedResult', modifiedResult)
                        setReceivedList(modifiedResult)
                    }
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [count, paginationPageCount, pageDataCount, searchText])

    console.log('receivedList', receivedList)

    if (isLoading) {
        return <LoadingIcon />
    }

    const formatData = () => {
        const dataFormat = receivedList.map((item, i) => {
            return {
                SL: i + 1,
                'Product Category': item.productCategory,
                'Product Name': item.skuName,
                'Erp Id': item.erpId,
                'Rcv Qty CTN': item.rcvQtyCTN,
                'Rcv Qty PCS': item.rcvQtyPCS,
                'Rcv Qty Mt': item.rcvQtyMt,
                'Del Qty Ctn': item.delivery?.delQtyCtn || 0,
                'Del Qty Pcs': item.delivery?.delQtyPcs || 0,
                'Del Qty Mt': item.delivery?.delQtyMt || 0,
                'Balance Qty Ctn':
                    item.rcvQtyCTN - (item.delivery?.delQtyCtn || 0),
                'Balance Qty PCS':
                    item.rcvQtyPCS - (item.delivery?.delQtyPcs || 0),
                'Balance Qty Mt':
                    item.rcvQtyMt - (item.delivery?.delQtyMt || 0),
            }
        })
        return dataFormat
    }

    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="info" pill className="">
                    Total Delivery CTN
                </SoftBadge>
                <div className="mt-2">
                    <h2 className="fw-normal text-700 mb-1 lh-1">
                        {formatNumber(
                            receivedList?.reduce((acc, obj) => {
                                return acc + (obj?.delivery?.delQtyCtn || 0)
                            }, 0)
                        )}
                    </h2>
                    <Link
                        to={'inventory/stockList'}
                        className="fw-semi-bold fs--1 text-nowrap"
                    >
                        {'See all'}
                        <FontAwesomeIcon
                            icon="angle-right"
                            className="ms-1"
                            transform="down-1"
                        />
                    </Link>
                </div>
            </Card.Body>
        </Card>
    )
}

export default AvailableStock
