import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../../helpers/utils';
import { useNavigate } from 'react-router-dom';


const OrderAndPaymentSummery = ({ data, selectedToDate, selectedFromDate }) => {
    const navigate = useNavigate();
    let accountPayable = 0;
    let accountReceivable = 0;
    let avialableCreditLimit = 0;

    // Account Payable and Account Receivable
    if (data?.totalDepositApproved > data?.totalExpense) {
        accountPayable = data?.totalDepositApproved - data?.totalExpense;
    } else if (data?.totalExpense > data?.totalDepositApproved) {
        accountReceivable = data?.totalExpense - data?.totalDepositApproved;
    }
    // Credit Limit Avialable
    if (data?.distributor?.crBDTLimit) {
        avialableCreditLimit = data?.distributor?.crBDTLimit - data?.totalExpense;
    }


    function handleDownload() {
        console.log('test', data);
        navigate("/db-reconciliation-report", { state: { data: { data, selectedToDate, selectedFromDate } } });
    }


    return (
        <>
            <Card className="py-3">
                <Card.Body className="py-3">
                    <Row className="g-0">
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4'>
                            <h6 className="pb-1 text-700 fs-1" >{`Total Demand Order No.(Without Approval)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{data.totalDemandOrder}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Demand Order No.(Approved)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{data.totalApprovedDemandOrder}</p>
                            {/* <div className="d-flex align-items-center">
                                <h6 className="fs--1 text-500 mb-0">{stat.subAmount}</h6>
                                <h6
                                    // className={classNames('fs--2 ps-3 mb-0', {
                                    //     'text-success': stat.type === 'up',
                                    //     'text-danger': stat.type === 'down',
                                    //     'text-primary': stat.type === 'standard',
                                    //     'text-warning': stat.type === 'warning',
                                    //     'text-info': stat.type === 'pending'
                                    // })}
                                >
                                    <FontAwesomeIcon icon="caret-up" className="me-1" />
                                    {stat.percent}%
                                </h6>
                            </div> */}
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Demand Order TK.(Without Approved)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalDemandOrderTk)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Demand Order TK.(Approved)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalApprovedDemandOrderTk)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Deposit(Pending)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalDepositPending)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Deposit(Approved)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalDepositApproved)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">Total Expense</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalExpense)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">Account Payable</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(accountPayable)}</p>
                        </Col>
                       
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">Account Receivable</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(accountReceivable)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">Delivery BDT</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(accountPayable)}</p>
                        </Col>

                        {data?.distributor?.crBDTLimit ? <>
                            <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                                <h6 className="pb-1 text-700 fs-1">Credit Limit</h6>
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.distributor?.crBDTLimit)}</p>
                            </Col>

                            <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                                <h6 className="pb-1 text-700 fs-1">Credit Limit Expense</h6>
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalExpense)}</p>
                            </Col>
                            <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                                <h6 className="pb-1 text-700 fs-1">Credit Limit Avialable</h6>
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(avialableCreditLimit)}</p>
                            </Col>
                        </>
                            : null}

                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Button onClick={handleDownload} variant="primary" disabled={!data?.totalDemandOrderTk}>Download PDF</Button>
                </Card.Footer>
            </Card>

        </>
    )
}

export default OrderAndPaymentSummery;