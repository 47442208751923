import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import useUserRole from "hooks/useUserRole";
import { useDropzone } from "react-dropzone";
import FalconCloseButton from "components/common/FalconCloseButton";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { authHeaderForm } from "state/ducs/auth/utils";
import { toast } from "react-toastify";
import { formatNumber } from 'helpers/utils'

const SuperDBDistributor = () => {
    const [distributorList, setDistributorList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [showUploadCSV, setShowUploadCSV] = useState(false);
    const [count, setCount] = useState(0);
    const userInfo = useSelector((state) => state.auth.user?.data);
    const userRole = useUserRole();

    console.log("distributorList", distributorList);


    // csv Modal 
    const handleShowCSV = () => {
        setShowUploadCSV(true);
    };

    // DISTRIBUTOR LIST
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

        const fetchData = async () => {
            let url;
            url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dbsUnderSdb/${userInfo.userId}`

            // if (userInfo?.userRole === "Admin") {
            //     url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
            // } if (userInfo?.userRole === "Sales-ASM") {
            //     url = searchText !== "" ? `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?areaName=${userInfo?.areaName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?areaName=${userInfo?.areaName}`
            // }
            // if (userInfo?.userRole === "Sales-RSM") {
            //     url = searchText !== "" ? `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?regionName=${userInfo?.regionName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors?regionName=${userInfo?.regionName}`
            // }
            try {
                const response = await axios.get(
                    url,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const totalPages = response?.data?.payload?.pagination?.totalPages;
                    const result = response?.data?.payload?.distributorData?.map((element, index) => ({
                        ...element,
                        index: startIndex + index
                    }));

                    setTotalPages(totalPages)
                    setDistributorList(result);
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount, searchText]);

    // DELETE DISTRIBUTOR

    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };


    /* 
...........................
       CSV FILE UPLOAD
..........................
*/
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));


    const handleCSVUpload = () => {


        const csvFile = acceptedFiles[0];
        const formData = new FormData();
        formData.append("csvFile", csvFile);

        const regUrl = process.env.REACT_APP_USER_MANAGEMENT_BASE_URL + "users/dbs/upload";

        setIsLoading(true);
        axios
            .post(regUrl, formData, { headers: authHeaderForm() })
            .then((response) => {
                console.log(response);

                if (response.data.success === true || response.status === 200) {
                    toast.success("Csv File Upload Success");
                    setShowUploadCSV(false);
                    setCount((prevState) => prevState + 1);

                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(
                setIsLoading(false)
            )

    };

    // EXCEL FILE DOWNLOAD
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dbsUnderSdb/${userInfo.userId}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.distributorData;
                    setExcelData(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            }
        };
        fetchData();
    }, []);

    console.log(excelData)

    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "SL",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "zoneName",
            Header: "Zone Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { zoneName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{zoneName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "regionName",
            Header: "Region Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { regionName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{regionName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "areaName",
            Header: "Area Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { areaName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{areaName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "dbPoint",
            Header: "DB Point",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { dbPoint } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{dbPoint}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "userId",
            Header: "Distributor Id",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { userId } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{userId}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "password",
            Header: "Password",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { password } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{password}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "ERP ID",
            Header: "ERP ID",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { erpId } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{erpId}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "DB Name",
            Header: "DB Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { distributorName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{distributorName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "userRole",
            Header: "DB Type",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { userRole } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{userRole}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: 'crBDTLimit',
            Header: 'Credit Limit BDT ',
            cellProps: { className: 'py-2' },
            Cell: ({
                row: {
                    original: { crBDTLimit },
                },
            }) => (
                <Flex alignItems="center">
                    <div className="flex-1">
                        <h5 className="mb-0 fs--1 text-center">{formatNumber(crBDTLimit)}</h5>
                    </div>
                </Flex>
            ),
        },
        {
            accessor: 'bgAmount',
            Header: 'BG BDT ',
            cellProps: { className: 'py-2' },
            Cell: ({
                row: {
                    original: { bgAmount },
                },
            }) => (
                <Flex alignItems="center">
                    <div className="flex-1">
                        <h5 className="mb-0 fs--1 text-center">{formatNumber(bgAmount)}</h5>
                    </div>
                </Flex>
            ),
        },
        {
            accessor: 'note',
            Header: 'Note ',
            cellProps: { className: 'py-2' },
            Cell: ({
                row: {
                    original: { note },
                },
            }) => (
                <Flex alignItems="center">
                    <div className="flex-1">
                        <h5 className="mb-0 fs--1 text-center">{note}</h5>
                    </div>
                </Flex>
            ),
        },
        {
            accessor: "superDBId",
            Header: "Super DB ID",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { superDBId } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{superDBId ? superDBId : "-"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Secondary Apps Live",
            Header: "Apps Live",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { hasLiveApp } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{hasLiveApp === true ? "Yes" : "No"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Direct Sales",
            Header: "Direct Sales",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { hasDirectSale } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{hasDirectSale === true ? "Yes" : "No"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Status",
            Header: "Status",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{status === true ? "Yes" : "No"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Proprietor Name",
            Header: "Proprietor Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { proprietor } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{proprietor}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "DOB",
            Header: "DOB",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { proprietorDOB } = rowData.row.original;
                const date = new Date(proprietorDOB);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "dbOpeningDate",
            Header: "DB Opening Date",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { dbOpeningDate } = rowData.row.original;
                const date = new Date(dbOpeningDate);
                const formattedDate = date.toLocaleDateString();

                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Address",
            Header: "Address",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { address } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1" style={{ width: "200px" }}>
                            <h5 className="mb-0 fs--1">{address}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Mobile",
            Header: "Mobile",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { phoneNumber } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{phoneNumber}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Emergency Person",
            Header: "Emergency Person",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { emergencyContactName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{emergencyContactName ? emergencyContactName : "-"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Emergency Mobile",
            Header: "Emergency Mobile",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { emergencyMobileNumber } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{emergencyMobileNumber ? emergencyMobileNumber : "-"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Relation With Owner",
            Header: "Relation With Owner",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { emergencyContactRelation } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{emergencyContactRelation ? emergencyContactRelation : "-"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "PC Exist",
            Header: "PC Exist",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { hasPC } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{hasPC === true ? "Yes" : "No"}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Printer Exist",
            Header: "Printer Exist",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { hasPrinter } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{hasPrinter === true ? "Yes" : "No"}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "action",
            Header: "Action",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { _id } = rowData.row.original;
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                            ...
                        </Dropdown.Toggle>

                        {userRole === "Admin" && <Dropdown.Menu>
                            <Dropdown.Item href="">

                                <Link to={`/user/distributor/add/${_id}`}>
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="edit"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-2"

                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                    </IconButton>
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                                <IconButton
                                    onClick={() => handleDelete(_id)}
                                    variant="falcon-default"
                                    size="sm"
                                    icon="trash-alt"
                                    iconAlign="middle"
                                    className="d-none d-sm-block me-2"
                                // disabled={true}
                                >
                                    <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                </IconButton>
                            </Dropdown.Item>
                        </Dropdown.Menu>}
                    </Dropdown>
                );
            },
        },
    ];

    /*******************
    Columns End.
    *******************/



    return (
        <>
            <PageHeader
                title="Distributor"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={distributorList}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            newUrl="/user/distributor/add"
                            isSearch={true}
                            setSearchText={setSearchText}
                            buttonTitle="Upload CSV"
                            handleShowCSV={handleShowCSV}
                            excelFileName="Distributors"
                            excelData={excelData}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        {userInfo?.userRole === "Admin" && <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />}
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

            {/* CSV File Upload Modal */}
            <Modal
                size="lg"
                show={showUploadCSV}
                onHide={() => setShowCreateCostCenterModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Upload Distributor CSV File
                    </Modal.Title>
                    <FalconCloseButton
                        onClick={() => setShowUploadCSV(false)}
                    />
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div
                            {...getRootProps({ className: "dropzone-area py-6" })}
                        >
                            <input {...getInputProps({ multiple: false })} />
                            <Flex justifyContent="center">
                                <img src={cloudUpload} alt="" width={25} className="me-2" />
                                <p className="fs-0 mb-0 text-700">Drop your file here</p>
                            </Flex>
                        </div>
                        <div className="mt-3">
                            {acceptedFiles.length > 0 && (
                                <>
                                    <h6>File</h6>
                                    <ul>{files}</ul>
                                </>
                            )}
                        </div>
                    </>

                    <Button variant="primary" onClick={handleCSVUpload}>
                        Submit
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );

};

export default SuperDBDistributor;
