import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'

import IconButton from 'components/common/IconButton'
import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { usePDF } from 'react-to-pdf'
import { useReactToPrint } from 'react-to-print'
import {
    getDeliveriesChallanNo,
    getSingleDistributorInfo,
    getOrdersByDealerId,
} from 'services/api'
import SimpleBarReact from 'simplebar-react'

import { useQuery } from 'react-query'
import { formatNumber } from 'helpers/utils'
import { useSelector } from 'react-redux'

const LoadSheetPrint = () => {
    const distributorInfo = useSelector((state) => state?.auth?.user?.data)
    const { challanNo, dealerId } = useParams()

    const navigate = useNavigate()

    const printableComponentRef = useRef()

    const { toPDF, targetRef } = usePDF({
        filename: 'challan No' + challanNo + '.pdf',
    })

    // Function to trigger the print
    const handlePrint = useReactToPrint({
        content: () => targetRef.current,
    })

    const [allOrdersData, setAllOrdersData] = useState([])

    const isSubset = (subset, set, key) => {
        console.log('subset', subset)
        console.log('set', set)
        console.log(
            'setTruth',
            subset.every((subItem) =>
                set.some((setItem) => setItem[key] === subItem)
            )
        )

        return subset.every((subItem) =>
            set.some((setItem) => setItem[key] === subItem)
        )
    }

    const {
        data: deliveryData,
        isLoading: isLoadingDeliveryByFactory,
        isError: deliveryByFactoryError,
        refetch: deliveryByFactoryRefetch,
    } = useQuery({
        queryKey: ['deliveriesChallanNo'],
        queryFn: () => getDeliveriesChallanNo(challanNo, dealerId),
    })

    console.log('deliveryData', deliveryData)
    const fetchOrdersData = () => {
        getOrdersByDealerId(dealerId)
            .then((res) => {
                if (res.data?.success) {
                    console.log(res.data?.message)
                    setAllOrdersData(res.data.payload.result)
                }
            })
            .catch((e) => {
                if (e.response?.data?.errors) {
                    toast.error(e.response?.data?.errors)
                }
            })
    }

    // Function to get unique values for a specific property
    const getUniqueValues = (arr, property) => {
        const values = new Set()

        arr.forEach((obj) => {
            if (obj[property]) {
                values.add(obj[property])
            }
        })

        return [...values]
    }

    const getSum = (order, allDeliveries) => {
        if (!allDeliveries.length) {
            return 0
        }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName &&
                    item.challanNo == order.challanNo
            )
            .reduce((accumulator, obj) => {
                return (
                    accumulator +
                    (obj.dbType == 'SPECIAL DISTRIBUTOR'
                        ? obj.delQtyPcs
                        : obj.delQtyCtn)
                )
            }, 0)

        return qtySum
    }

    const IsShowable = (order, allDeliveries) => {
        // if (!allDeliveries.length) {
        //     return true
        // }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName &&
                    item.challanNo == order.challanNo
            )
            .reduce((accumulator, obj) => {
                return (
                    accumulator +
                    (obj.dbType == 'SPECIAL DISTRIBUTOR'
                        ? obj.delQtyPcs
                        : obj.delQtyCtn)
                )
            }, 0)

        return (
            qtySum ==
            (order.dbType == 'SPECIAL DISTRIBUTOR'
                ? order.delQtyPcs
                : order.delQtyCtn)
        )
    }

    const getSkuOrderQuantity = (skuName, doNo, challanNo, allDeliveries) => {
        let quantity = 0
        for (let i = 0; i < allDeliveries.length; i++) {
            const element = allDeliveries[i]
            if (
                element.skuName == skuName &&
                element.doNo == doNo &&
                element.challanNo == challanNo
            ) {
                quantity =
                    quantity +
                    // element.delQtyCtn
                    (element.dbType == 'SPECIAL DISTRIBUTOR'
                        ? element.delQtyPcs
                        : element.delQtyCtn)
            }
        }

        return quantity
    }

    useEffect(() => {
        fetchOrdersData()
    }, [])

    function getProductId(skuName, inputArr) {
        let product_id = null

        for (let i = 0; i < inputArr.length; i++) {
            const element = inputArr[i]
            if (element.skuName == skuName) {
                product_id = element.productId
                break
            }
        }

        return product_id
    }

    let distinctSKUNames = []

    let distinctDONos = []

    const outputArr = {}

    let totalSum = 0
    let totalPrice = 0
    let totalSumOffer = 0

    let details = null
    let allDeliveries = []
    let hasOffer = false
    let hasComboOffers = []

    // erpId, perPCPrice by given skuName from allDeliveries
    const getErpIdperPCPriceperCtnPrice = (skuName, allDeliveries) => {
        let erpId = 0
        let perPCPrice = 0
        let pcsCTN = 0
        let delQtyCtn = 0
        let delQtyPcs = 0
        for (let i = 0; i < allDeliveries.length; i++) {
            const element = allDeliveries[i]
            if (element.skuName == skuName) {
                erpId = element.erpId
                perPCPrice = element.perPCPrice
                delQtyCtn = element.delQtyCtn
                delQtyPcs = element.delQtyPcs
                pcsCTN = element.delQtyPcs / element.delQtyCtn;
            }
        }

        const ctnSize = delQtyPcs / delQtyCtn
        const perCtnPrice = (perPCPrice * ctnSize).toFixed(2)
        return { erpId, perPCPrice, perCtnPrice, pcsCTN }
    }

    if (deliveryData?.data.payload.challanData && allOrdersData.length) {
        allDeliveries = deliveryData.data.payload.challanData

        details = allDeliveries[0]

        const resArr = allDeliveries

        hasComboOffers = allDeliveries?.filter(
            (offer) =>
                offer.offerNo &&
                offer.challanNo == challanNo &&
                offer.dealerId == dealerId
        )

        console.log('hasComboOffers', hasComboOffers)

        distinctSKUNames = getUniqueValues(resArr, 'skuName')

        distinctDONos = getUniqueValues(resArr, 'doNo')

        for (let i = 0; i < distinctSKUNames.length; i++) {
            const element = distinctSKUNames[i]

            ///inner loop start
            for (let j = 0; j < distinctDONos.length; j++) {
                const inelement = distinctDONos[j]
                const skuNameWithDo = element + '=' + inelement

                const quantity = getSkuOrderQuantity(
                    element,
                    inelement,
                    challanNo,
                    allDeliveries
                )
                outputArr[skuNameWithDo] = quantity
            }
            //inner loop end
        }

        for (let l = 0; l < distinctSKUNames.length; l++) {
            const element = distinctSKUNames[l]
            distinctSKUNames[l] = element + '/' + getProductId(element, resArr)
        }

        {
            Object.entries(outputArr).forEach(([key, value]) => {
                if (typeof value === 'number') {
                    totalSum += value
                    totalPrice +=
                        value *
                        getErpIdperPCPriceperCtnPrice(
                            key.split('=')[0],
                            allDeliveries
                        ).perCtnPrice
                }
            })
        }

        console.log('outputArr', outputArr)
        console.log('allDeliveries', allDeliveries)

        for (const key in outputArr) {
            if (Object.hasOwnProperty.call(outputArr, key)) {
                const element = outputArr[key]
                const offer = allDeliveries.find(
                    (it) => it.offerSkuName && it.doNo == key.split('=')[1]
                )

                if (offer?.offerSkuName && offer?.offerAccepted == 'product') {
                    hasOffer = true
                    break
                }
            }
        }
    }

    const {
        data: singleDistributorData,
        isLoading: isLoadingSingleDistributor,
        isError: singleDistributorError,
        refetch: singleDistributorRefetch,
    } = useQuery({
        queryKey: ['singleDistributor'],
        queryFn: () => getSingleDistributorInfo(dealerId),
    })

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">
                                Challan No: {challanNo}
                            </h5>
                        </Col>

                        <Col xs="auto">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="print"
                                className="me-1 mb-2 mb-sm-0"
                                iconClassName="me-1"
                                onClick={() => handlePrint()}
                            >
                                Print
                            </IconButton>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card ref={targetRef} className="h-lg-100 overflow-hidden">
                <Card.Header>
                    <Row className="align-items-center text-center">
                        <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center text-center">
                                <div className="text-sm-start"><img src={logo} alt="invoice" width={100} /></div>
                                <div className="text-sm-start mt-3 mt-sm-0"><h3 style={{ marginLeft: '95px' }}>
                                    T.K Food Products Ltd.
                                </h3>
                                    <p className="mb-0"  >
                                        T. K. Bhaban (2nd Floor), 13, Kawranbazar
                                        Dhaka-1215, Bangladesh
                                    </p></div>
                                <div></div>
                            </div>
                        </Col>

                        {/* <Col sm={3} className="text-sm-start">
                            <img src={logo} alt="invoice" width={100} />
                        </Col>
                        <Col className="text-sm-start mt-3 mt-sm-0">
                            <h3 style={{ marginLeft: '194px' }}>
                                Pusti Happy Time
                            </h3>
                            <p className="mb-0"  style={{ marginLeft: '70px' }}>
                                T. K. Bhaban (2nd Floor), 13, Kawranbazar
                                Dhaka-1215, Bangladesh
                            </p>
                        </Col> */}

                        <Col xs={12}>
                            <hr />
                        </Col>

                        <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center text-center">
                                <div></div>
                                <div style={{ marginLeft: '173px' }}>
                                    <h3>Delivery Load Sheet</h3>
                                    <h5>{'Challan No: ' + challanNo}</h5>
                                </div>
                                <div className="mb-2" style={{ marginRight: '33px', paddingBottom: '24px' }}>
                                    Date:{' '}
                                    {(() => {
                                        const date = new Date(
                                            details?.deliveryDate
                                        )
                                        const day = date.getDate()
                                        const monthNames = [
                                            'Jan',
                                            'Feb',
                                            'Mar',
                                            'Apr',
                                            'May',
                                            'Jun',
                                            'Jul',
                                            'Aug',
                                            'Sep',
                                            'Oct',
                                            'Nov',
                                            'Dec',
                                        ]
                                        const month =
                                            monthNames[date.getMonth()]
                                        const year = date.getFullYear()
                                        return `${day}-${month}-${year}`
                                    })()}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>To :</div>
                                <div style={{ marginRight: '117px' }}>
                                    From :
                                </div>
                            </div>
                            <div className="d-flex justify-content-between my-3">
                                <div>
                                    <p className="mb-0 text-start">
                                        {details?.distributorName || ''} (
                                        {details?.dealerId})
                                    </p>
                                    <p
                                        className="mb-0 text-start "
                                        style={{ fontSize: '10px' }}
                                    >
                                        Address: {details?.dbPoint},{' '}
                                        {details?.areaName},{' '}
                                        {details?.regionName},{' '}
                                        {details?.zoneName}{' '}
                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Phone: ' +
                                            singleDistributorData?.data?.payload
                                                ?.distributorData
                                                ?.phoneNumber || ''}
                                    </p>
                                </div>

                                <div>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        Factory/Depot Name :{' '}
                                        {details?.factoryName?.length
                                            ? details?.factoryName
                                            : distributorInfo.distributorName}
                                    </p>

                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Transport Name: ' +
                                            details?.transportName}
                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Vehicle No: ' +
                                            details?.transportNo}

                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Driver Name: ' + details?.driverName}
                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {'Driver Phone: ' +
                                            details?.driverPhone}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body className="p-0">
                    <Table borderless className="fs--1">
                        <thead className="bg-light">
                            <tr className="text-900">
                                <th className="text-start">SL</th>
                                <th className="text-start">SKU Name</th>
                                <th className="text-start rotated-heading">
                                    UOM
                                </th>
                                <th className="text-start rotated-heading">
                                    Per Ctn Pcs
                                </th>

                                {distinctDONos.map((doNo) => (
                                    <th className="text-start rotated-heading">
                                        {doNo}
                                    </th>
                                ))}

                                <th className="text-start rotated-heading">
                                    Total{' '}
                                    {details?.dbType == 'SPECIAL DISTRIBUTOR'
                                        ? 'PCS'
                                        : 'UOM'}
                                </th>
                                {/* <th className="text-start">Total Price</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {distinctSKUNames.map((item, index) => {
                                let totalh = 0
                                return (
                                    <tr className="border-top border-200">
                                        {/* <td className="text-start fw-semi-bold">
                                                {item.split('/')[1]}
                                            </td> */}
                                        <td className="text-start">
                                            {' '}
                                            {index + 1}
                                        </td>

                                        <td className="text-start fw-semi-bold">
                                            {item.split('/')[0]}{' '}
                                            <span className="fw-bold">
                                                {' '}
                                                (
                                                {
                                                    getErpIdperPCPriceperCtnPrice(
                                                        item.split('/')[0],
                                                        allDeliveries
                                                    ).erpId
                                                }
                                                ){' '}
                                            </span>
                                        </td>

                                        <td className="text-start fw-semi-bold">
                                            {details?.dbType ==
                                                'SPECIAL DISTRIBUTOR'
                                                ? 'PCS'
                                                : 'CTN'}
                                        </td>

                                        <td className="text-start fw-semi-bold">
                                            {
                                                getErpIdperPCPriceperCtnPrice(
                                                    item.split('/')[0],
                                                    allDeliveries
                                                ).pcsCTN
                                            }
                                        </td>

                                        {distinctDONos.map((dn) => {
                                            totalh +=
                                                outputArr[
                                                item.split('/')[0] +
                                                '=' +
                                                dn
                                                ]
                                            return (
                                                <td className="align-middle text-start fw-semi-bold">
                                                    {
                                                        outputArr[
                                                        item.split('/')[0] +
                                                        '=' +
                                                        dn
                                                        ]
                                                    }
                                                </td>
                                            )
                                        })}
                                        <td
                                            style={{ width: '150px' }}
                                            className="align-middle text-start fw-bold"
                                        >
                                            {totalh}
                                        </td>
                                        {/* <td
                                            style={{ width: '150px' }}
                                            className="align-middle text-start fw-bold"
                                        >
                                            {formatNumber(
                                                totalh *
                                                getErpIdperPCPriceperCtnPrice(
                                                    item.split('/')[0],
                                                    allDeliveries
                                                ).perCtnPrice
                                            )}
                                        </td> */}
                                    </tr>
                                )
                            })}
                        </tbody>

                        <tfoot className="bg-light">
                            <tr className="text-900">
                                {/* <th className="text-start"></th> */}
                                <th className="text-start fw-bold"></th>
                                <th className="text-start fw-bold">Total</th>
                                <th className="text-start fw-bold"></th>
                                <th className="text-start fw-bold"></th>

                                {distinctDONos.map((doNo) => {
                                    let otherTotal = 0
                                    for (
                                        let ot = 0;
                                        ot < distinctSKUNames.length;
                                        ot++
                                    ) {
                                        const element = distinctSKUNames[ot]
                                        otherTotal +=
                                            outputArr[
                                            element.split('/')[0] +
                                            '=' +
                                            doNo
                                            ]
                                    }
                                    return (
                                        <th className="text-start fw-bold">
                                            {otherTotal}
                                        </th>
                                    )
                                })}
                                <th className="text-start fw-bold">
                                    {totalSum}
                                </th>
                                {/* <th className="text-start fw-bold">
                                    {formatNumber(totalPrice)}
                                </th> */}
                            </tr>
                        </tfoot>
                    </Table>
                    {hasOffer || hasComboOffers.length ? (
                        <Table borderless className="mb-10 fs--1">
                            {/* <thead className="bg-light"> */}
                            <thead className="bg-light">
                                <tr className="text-900">
                                    {/* <th className="text-start">Product Id</th> */}
                                    <th className="text-start">SL</th>
                                    <th className="text-start">
                                        Offer SKU Name
                                    </th>
                                    <th className="text-start rotated-heading">UOM</th>
                                    {/* <th className="text-start rotated-heading">
                                        Unit Price
                                    </th> */}

                                    {distinctDONos.map((doNo) => (
                                        <th className="text-start rotated-heading">
                                            {/* <div>
                                                    <p className='fw-bold'>
                                                        {doNo}
                                                    </p>
                                                </div> */}
                                            {doNo}
                                        </th>
                                    ))}

                                    <th className="text-start rotated-heading">Total PCS</th>
                                    {/* <th className="text-start">Total Price</th> */}

                                </tr>
                            </thead>

                            <tbody>
                                {distinctSKUNames.map((item, index) => {
                                    const hasInOffer = allDeliveries.filter(
                                        (it) =>
                                            it.offerSkuName &&
                                            it.dealerId == dealerId &&
                                            it.challanNo == challanNo &&
                                            it.skuName == item.split('/')[0]
                                    )

                                    // const hasInOffer= allDeliveries.filter(it =>
                                    //     it.dealerId==dealerId && it.challanNo==challanNo &&
                                    //     it.skuName == item.split('/')[0])?.offerSkuName || null
                                    let totalh = 0
                                    console.log('has', hasInOffer)
                                    if (hasInOffer.length) {
                                        return (
                                            <tr className="border-top border-200">
                                                <td className="text-start">
                                                    {' '}
                                                    {index + 1}
                                                </td>

                                                <td className="text-start fw-semi-bold">
                                                    {/* {allDeliveries.find(it => it. it.skuName == item.split('/')[0]).offerSkuName} */}
                                                    {/* Offer SKU Name */}
                                                    {
                                                        allDeliveries.find(
                                                            (it) =>
                                                                it.offerSkuName &&
                                                                it.dealerId ==
                                                                dealerId &&
                                                                it.challanNo ==
                                                                challanNo &&
                                                                it.skuName ==
                                                                item.split(
                                                                    '/'
                                                                )[0]
                                                        )?.offerSkuName
                                                    }
                                                    <span className="fw-bold">
                                                        (
                                                        {
                                                            allDeliveries.find(
                                                                (it) =>
                                                                    it.offerSkuName &&
                                                                    it.dealerId ==
                                                                    dealerId &&
                                                                    it.challanNo ==
                                                                    challanNo &&
                                                                    it.skuName ==
                                                                    item.split(
                                                                        '/'
                                                                    )[0]
                                                            )?.erpId
                                                        }
                                                        )
                                                    </span>
                                                </td>

                                                <td className="text-start fw-semi-bold">
                                                    PCS
                                                </td>

                                                <td className="text-start fw-semi-bold">
                                                    {
                                                        allDeliveries.find(
                                                            (it) =>
                                                                it.offerSkuName &&
                                                                it.dealerId ==
                                                                dealerId &&
                                                                it.challanNo ==
                                                                challanNo &&
                                                                it.skuName ==
                                                                item.split(
                                                                    '/'
                                                                )[0]
                                                        )?.offerSkuPrice
                                                    }
                                                </td>

                                                {distinctDONos.map((dn) => {
                                                    totalh +=
                                                        // outputArr[
                                                        // item.split('/')[0] +
                                                        // '=' +
                                                        // dn
                                                        // ]

                                                        allDeliveries.find(
                                                            (mt) =>
                                                                mt.offerSkuName &&
                                                                mt.doNo == dn &&
                                                                mt.skuName ==
                                                                item.split(
                                                                    '/'
                                                                )[0]
                                                        )?.offerQtyPCS || 0
                                                    return (
                                                        <td className="align-middle text-start fw-semi-bold">
                                                            <div
                                                            // style={{ width: '230px' }}
                                                            >
                                                                {formatNumber(
                                                                    allDeliveries.find(
                                                                        (mt) =>
                                                                            mt.offerSkuName &&
                                                                            mt.doNo ==
                                                                            dn &&
                                                                            mt.skuName ==
                                                                            item.split(
                                                                                '/'
                                                                            )[0]
                                                                    )
                                                                        ?.offerQtyPCS ||
                                                                    0
                                                                )}
                                                            </div>
                                                        </td>
                                                    )
                                                })}
                                                <td
                                                    style={{
                                                        width: '150px',
                                                    }}
                                                    className="align-middle text-start fw-bold"
                                                >
                                                    {totalh}
                                                </td>
                                                {/* <td
                                                    style={{
                                                        width: '150px',
                                                    }}
                                                    className="align-middle text-start fw-bold"
                                                >
                                                    {totalh}
                                                </td> */}
                                            </tr>
                                        )
                                    }
                                })}

                                {hasComboOffers?.map((rend, id) => (
                                    <tr>
                                        <td className="text-start">
                                            {' '}
                                            {id + 1}
                                        </td>

                                        <td>
                                            <p className="mb-0">
                                                {rend.offerSkuName}
                                            </p>
                                        </td>

                                        <td className="text-start">
                                            {'PCS'}
                                        </td>
                                        {/* <td>
                                            <p className="mb-0">
                                                {rend.perPCPrice}
                                            </p>
                                        </td> */}
                                        {distinctDONos.map((doNo) => {
                                            return (
                                                <td className="text-start fw-bold">
                                                    {hasComboOffers.find(
                                                        (you) =>
                                                            you.doNo == doNo
                                                    )?.offerQtyPCS || '--'}
                                                </td>
                                            )
                                        })}

                                        <td className="text-start">
                                            {formatNumber(rend.offerQtyPCS) ||
                                                '--'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                            {/* <tfoot className="bg-light"> */}
                            <tfoot className="bg-light">
                                <tr className="text-900">
                                    <th className="text-start"></th>
                                    <th className="text-start fw-bold">
                                        Total
                                    </th>
                                    <th className="text-start"></th>


                                    {distinctDONos.map((doNo) => {
                                        let otherTotal = 0
                                        for (
                                            let ot = 0;
                                            ot < distinctSKUNames.length;
                                            ot++
                                        ) {
                                            const element = distinctSKUNames[ot]
                                            otherTotal +=
                                                // outputArr[
                                                // element.split('/')[0] +
                                                // '=' +
                                                // doNo
                                                // ]

                                                allDeliveries.find(
                                                    (item) =>
                                                        item.doNo == doNo &&
                                                        item.skuName ==
                                                        element.split(
                                                            '/'
                                                        )[0]
                                                )?.offerQtyPCS || 0
                                        }

                                        totalSumOffer += otherTotal

                                        return (
                                            <th className="text-start fw-bold">
                                                {otherTotal +
                                                    hasComboOffers
                                                        .filter(
                                                            (it) =>
                                                                it.doNo == doNo
                                                        )
                                                        .reduce(
                                                            (
                                                                accumulator,
                                                                currentObj
                                                            ) => {
                                                                return (
                                                                    accumulator +
                                                                    currentObj.offerQtyPCS
                                                                )
                                                            },
                                                            0
                                                        )}
                                            </th>
                                        )
                                    })}
                                    <th className="text-start fw-bold">
                                        {totalSumOffer +
                                            hasComboOffers.reduce(
                                                (accumulator, currentObj) => {
                                                    return (
                                                        accumulator +
                                                        currentObj.offerQtyPCS
                                                    )
                                                },
                                                0
                                            )}
                                    </th>
                                </tr>
                            </tfoot>
                        </Table>
                    ) : null}

                    <div className="d-flex justify-content-between px-3">
                        <p className="fw-bold">Remarks :</p>
                    </div>
                    <div className="d-flex justify-content-between px-3 mt-5 mb-3">
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            Receiver Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            Driver Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            SIC Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            DIC Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                                marginRight: '20px'
                            }}
                        >
                            HOD Signature
                        </p>
                    </div>
                </Card.Body>
                <Card.Footer className="bg-light py-2">
                    <Row className="flex-between-center">
                        <Col xs="auto"></Col>
                        <Col xs="auto">
                            {/* <Button
                                onClick={handleScheduleSubmit}
                                variant="falcon-default"
                                size="sm"
                            >
                                Submit
                            </Button> */}
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>

            <div>
                <Button
                    variant="primary"
                    className="me-2 px-5 mt-3"
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </div>
        </>
    )
}

export default LoadSheetPrint;
