import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link, useNavigate } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import useUserRole from 'hooks/useUserRole'
import StatusCell from 'components/common/StatusCell'
import CollapseOrder from 'components/pages/AccountAndFinance/Accounts/ConfirmOrders/CollapseOrder'
import { formatNumber } from 'helpers/utils'
import { IoIosCheckboxOutline } from 'react-icons/io'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons' // Example icon for pending status

import {
    scheduleSubmit,
    orderUpdate,

} from 'services/api'

const SuperDistributorOrdersPendingOthers = () => {
    const [distributorOrderList, setDistributorOrderList] = useState([])
    const [expand, setExpand] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)
    const [pageDataCount, setPageDataCount] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [count, setCount] = useState(0)
    const [checkedOrders, setCheckedOrders] = useState([])

    const [updatableCombos, setUpdatableCombos] = useState([])

    const userInfo = useSelector((state) => state?.auth?.user?.data)
    const userRole = useUserRole()

    const navigate = useNavigate()

    // orders list
    useEffect(() => {
        setIsLoading(true)
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1
        // let url = searchText !== "" ? `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/pending?area=${userInfo?.areaName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}` : `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/pending?area=${userInfo?.areaName}&&limit=${pageDataCount}&page=${paginationPageCount}`
        let url
        if (userRole === 'Admin') {
            // url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/group?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
            url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/getSDBUnderOrdersPending?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
        } else {
            // url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/group?dealerId=${distributorInfo?.userId}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
            url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/getSDBUnderOrdersPending/${userInfo?.userId}`
        }
        const fetchData = async () => {
            try {
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.result
                    const totalPages =
                        response?.data?.payload?.pagination?.totalPages // pagination not working

                    let comboOffers = []

                    result.forEach((element) => {
                        const offers = element.data.filter((nun) => nun.offerNo)
                        if (offers.length) {
                            comboOffers = [...comboOffers, ...offers]
                        }
                    })

                    // const ordersByDoNo = organizeOrdersByDoNo(result);
                    setTotalPages(totalPages)
                    setDistributorOrderList([...result, ...comboOffers])
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [count, paginationPageCount, pageDataCount, searchText])

    // Handle checkbox change
    const handleCheckboxChange = (order) => {
        if (isChecked(order)) {
            setCheckedOrders(checkedOrders.filter((p) => p.doNo !== order.doNo))
        } else {
            setCheckedOrders([...checkedOrders, order])
        }
    }

    const handleCheckAll = (event) => {
        const isChecked = event.target.checked
        if (isChecked) {
            setCheckedOrders(distributorOrderList)
        } else {
            setCheckedOrders([])
        }
    }

    // Check if a product is checked
    const isChecked = (order) => {
        return checkedOrders.some((p) => p.doNo === order.doNo)
    }

    const getFormattedDate = (data) => {
        const originalDateString = data
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`

        return formattedDateString
    }

    const handleCancelOrder = async (order, userInfo) => {
        const orderItems =
            order?.data?.map((or) => {
                return {
                    _id: or._id,
                    status: 1,
                    checkedId: userInfo?.userId,
                    cancelNote: '', // Placeholder for the reason, you can modify this as needed
                }
            }) ?? []

        try {
            const { value: cancelNote } = await Swal.fire({
                title: 'Reason for cancellation',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                preConfirm: async (cancelNote) => {
                    try {
                        const updatedOrderItems = orderItems.map((item) => ({
                            ...item,
                            cancelNote,
                        }))
                        const response = await axios.put(
                            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                            updatedOrderItems,
                            { headers: authHeader() }
                        )

                        if (response.data.success) {
                            return true
                        } else {
                            throw new Error(response.data.message)
                        }
                    } catch (error) {
                        throw new Error(`Request failed: ${error}`)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })

            if (cancelNote) {
                setCount(count + 1)
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Order has been successfully cancelled.',
                })
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.message,
            })
        }
    }

    if (isLoading) {
        return <LoadingIcon />
    }

    // get deposit data by doNo
    const handleGetOrdersDeposit = async () => {
        const fetchData = async () => {
            const formatData = checkedOrders?.map((order) => ({
                doNo: order.doNo,
            }))
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/DepositDataPaymentByFinance`,
                    formatData,
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload
                    handleUpdateDeposit(data)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }

    // update deposit data by doNo
    const handleUpdateDeposit = (data) => {
        const fetchData = async () => {
            const formatData = data?.map((order) => ({
                _id: order._id,
                status: 2,
            }))
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/updateDepositPaymentByFinnace`,
                    formatData,
                    { headers: authHeader() }
                )

                if (response.data.success || response.status === 200) {
                    handleGetOrderWidthBanks()
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }

    // get order with bank data by doNo
    const handleGetOrderWidthBanks = async () => {
        const formatData = checkedOrders?.map((order) => order.doNo)
        try {
            const url = ` ${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/MultiDosOrderWithBank`
            const response = await axios.post(
                url,
                { DoNos: formatData },
                { headers: authHeader() }
            )

            if (response.data.success || response.status === 200) {
                const data = response?.data?.payload?.moreOrderWithBank

                handleUpdateOrderWidthBanks(data)
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading(false)
        }
    }

    // update order with bank data by doNo
    const handleUpdateOrderWidthBanks = async (data) => {
        const formatData = data?.map((order) => ({
            _id: order._id,
            status: 2,
        }))
        try {
            const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/updateOrdersWithBank`

            const response = await axios.post(url, formatData, {
                headers: authHeader(),
            })

            if (response.data.success || response.status === 200) {
                handleSubmit()
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading(false)
        }
    }

    // update order status
    const handleSubmit = async () => {
        const orderItems = checkedOrders.flatMap((order) => {
            return (
                order?.data?.map((or) => ({
                    _id: or._id,
                    status: 7,
                    checkedId: userInfo?.userId,
                })) ?? []
            )
        })

        // Check if there are items to update
        if (orderItems.length === 0) {
            // No items to update, show a message or return early
            return
        }

        // Make a PUT request to update the order items
        try {
            setIsLoading(true)
            const response = await axios.put(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                orderItems,
                { headers: authHeader() }
            )

            // Check if the update was successful
            if (response.data.status || response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Orders have been successfully checked.',
                })
                setCheckedOrders([])
                setCount((prevCount) => prevCount + 1) // Update count

                // const orderItems = checkedOrders.flatMap((order) => {
                //     return (
                //         order?.data?.map((or) => ({
                //             _id: or._id,
                //             ...or,
                //         })) ?? []
                //     )
                // })

                // var firstDay = new Date();
                // var nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);

                // const postArr = orderItems.map((item) => {
                //     const ID = item._id
                //     delete item._id
                //     return {
                //         ...item,
                //         ID: ID,
                //         skuName: item.skuName,
                //         doNo: item.doNo,
                //         factoryName: "",
                //         userId: userInfo.userId,
                //         scheduleNo: Math.floor(Math.random() * 1000000000) + '-' + userInfo.userId,
                //         zoneName: item.zone,
                //         regionName: item.region,
                //         areaName: item.area,
                //         delQtyCtn: item.orderQtyCtn,
                //         orderQtyCtn: item.orderQtyCtn,
                //         delQtyPcs: item.totalOrderQtyPCS,
                //         weightPerPC: item.weightPerPC,
                //         erpId: item.erpId,
                //         orderDate: item.doDate,
                //         scheduleDate: getFormattedDate(new Date()),
                //         status: 9,
                //         dbType: item.dbType,
                //         productCategory: item.categoryName,
                //         // offerCategory: item.offerScheduleAlreadyDone ? "" : item.offerAccepted == "product" ? item.offerCategoryName : "",
                //         // offerSkuName: item.offerScheduleAlreadyDone ? "" : item.offerAccepted == "product" ? item.offerSkuName : "",
                //         // offerQtyPCS: item.offerScheduleAlreadyDone ? 0 : item.offerAccepted == "product" ? item.offerPc : 0,
                //         offerCategory: item.offerAccepted == "product" ? item.offerCategoryName : "",
                //         offerSkuName: item.offerAccepted == "product" ? item.offerSkuName : "",
                //         offerQtyPCS: item.offerAccepted == "product" ? item.offerPc : 0,
                //         superDBId: item.superDBId,
                //         dealerId: item.dealerId,
                //         distributorName: item.distributorName,
                //         deliveryDate: firstDay,
                //         deliveryDateEnd: nextWeek,
                //         productSubCategory: item.subCategory || "",
                //         perPCPrice: item.perPCPrice
                //     }
                // });




                // const offerPostArr = updatableCombos.filter(item => typeof item.factoryName === "string").map(yap => {

                //     // delete yap._id;
                //     const ID = yap._id

                //     return {

                //         ...yap,
                //         ID: ID,
                //         skuName: yap.skuName,
                //         doNo: yap.doNo,
                //         factoryName: yap.factoryName,
                //         userId: user.data.userId,
                //         scheduleNo: sc,
                //         zoneName: yap.zone,
                //         regionName: yap.region,
                //         areaName: yap.area,
                //         orderDate: yap.doDate,
                //         scheduleDate: getFormattedDate(new Date()),
                //         skuName: yap.offerName,
                //         superDBId: yap.superDBId,
                //         dealerId: yap.dealerId,
                //         dbType: yap.dbType,
                //         distributorName: yap.distributorName,
                //         doNo: yap.doNo,
                //         deliveryDate: firstDay,
                //         deliveryDateEnd: nextWeek,
                //         offerSkuName: yap.offerSkuName,
                //         offerQtyPCS: yap.offerPc,
                //         offerStatus: 1,
                //         offerNo: yap.offerNo,
                //         superDBId: yap.superDBId || "",
                //         status: 9,
                //         unitName: "PCS",
                //         perPCPrice: yap.perPCPrice

                //         // delQtyCtn: yap.offerPc


                //     }
                // })


                // // scheduleSubmit([...postArr, ...offerPostArr])



                // axios.post(`${process.env.REACT_APP_DISTRIBUTION_MANAGEMENT_BASE_URL}orders-distribution`, [...postArr, ...offerPostArr], { headers: authHeader() })


                //     .then((res) => {
                //         if (res.data?.success) {
                //             console.log(res.data?.message)





                //             const postArrWithId = postArr
                //                 // .filter((tr) => tr.deliveryQty > 0)
                //                 // .filter((tr) => tr.factoryName[0])
                //                 .map((item) => {

                //                     // let offerScheduleAlreadyDone = false;
                //                     // const existing = allScheduleData.filter(mn => mn.skuName == item.skuName && mn.doNo == item.doNo);
                //                     // for (let i = 0; i < existing.length; i++) {
                //                     //     const element = existing[i];
                //                     //     if (element.offerSkuName && element.offerCategory && element.offerQtyPCS) {
                //                     //         offerScheduleAlreadyDone = true;
                //                     //         break;
                //                     //     }
                //                     // }

                //                     return {
                //                         ...item,
                //                         _id: item.ID,

                //                     }
                //                 })

                //             const offerPostArrWithId = offerPostArr.filter(item => typeof item.factoryName === "string").map(yap => {



                //                 return {

                //                     ...yap,
                //                     _id: yap.ID,



                //                 }
                //             })



                //             const withIdsMerged = [...postArrWithId, ...offerPostArrWithId];

                //             axios.put(
                //                 `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`,
                //                 withIdsMerged.map((item) => ({ _id: item._id, scheduleStatus: 1 })),
                //                 { headers: authHeader() }
                //             ).then((response) => {

                //                     if (response.data?.success) {

                //                         Swal.fire({
                //                             icon: 'success',
                //                             title: 'Success!',
                //                             text: 'Orders have been successfully checked.',
                //                         })
                //                         setCheckedOrders([])
                //                         setCount((prevCount) => prevCount + 1) // Update count

                //                     }
                //                 })
                //         }
                //     })
                //     .catch((e) => {
                //         if (e.response?.data?.errors) {
                //             toast.error(e.response?.data?.errors)
                //         }
                //     })



            } else {
                // Handle unexpected response
                throw new Error('Unexpected response from server')
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading(false)
        }
    }

    const handleEditOrder = (order) => {
        const isPcs = order?.data?.[0]?.unitName === 'PCS'
        if (isPcs) {
            navigate(`/master/special-distributor-orders-update/${order?.doNo}`)
        } else {
            navigate(`/master/db-order-update-asm/${order?.doNo}`)
        }
    }

    const expandHandler = (order) => {
        const orders = distributorOrderList
        orders.forEach((element) => {
            element.open = false
        })
        const index = orders.findIndex((item) => item.doNo == order.doNo)
        orders[index].open = true
        setDistributorOrderList(orders)
        setExpand(!expand)
    }

    return (
        <>
            <PageHeader
                title={`Orders List Of ${userInfo.zoneName},${userInfo.regionName},${userInfo.areaName}`}
                className="mb-3"
            ></PageHeader>

            <Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader
                            isNew={false}
                            isExport={false}
                            isSearch={true}
                            setSearchText={setSearchText}
                        />
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            {distributorOrderList?.length > 0 ? (
                                <table className="table table-bordered table-striped fs--1 mb-0">
                                    <thead>
                                        <tr
                                            style={{
                                                fontSize: 'medium',
                                                fontWeight: '500',
                                                fontFamily: 'Poppins',
                                                color: '#354151',
                                            }}
                                        >
                                            <th className="align-middle white-space-nowrap text-center ">
                                                ...
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        checkedOrders?.length ===
                                                        distributorOrderList?.length
                                                    }
                                                    onChange={(e) =>
                                                        handleCheckAll(e)
                                                    }
                                                />
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                DO-No
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                DB ID
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                DB Name
                                            </th>

                                            <th className="align-middle white-space-nowrap text-center ">
                                                DB Point
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Order Qty Ctn
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Order Qty PCS
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Discount BDT
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Price
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Status
                                            </th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        className="list"
                                        id="table-purchase-body"
                                    >
                                        {distributorOrderList?.map(
                                            (order, index) => {
                                                return (
                                                    <>
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                fontSize:
                                                                    'medium',
                                                                fontWeight:
                                                                    '500',
                                                                fontFamily:
                                                                    'Poppins',
                                                                color: '#354151',
                                                            }}
                                                        >
                                                            <td className="align-middle text-center border-0">
                                                                <Button
                                                                    onClick={() =>
                                                                        expandHandler(
                                                                            order
                                                                        )
                                                                    }
                                                                    aria-controls="example-collapse-text"
                                                                    aria-expanded={
                                                                        order.open
                                                                    }
                                                                    variant="falcon-primary"
                                                                    className="custom-button" // Apply the custom class
                                                                >
                                                                    {order.open &&
                                                                        expand
                                                                        ? '-'
                                                                        : '+'}
                                                                </Button>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isChecked(
                                                                        order
                                                                    )}
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            order
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {order?.doNo}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    order?.dealerId
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    order?.distributorName
                                                                }
                                                            </td>

                                                            <td className="align-middle text-center">
                                                                {order?.dbPoint}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {formatNumber(order?.totalOrderQtyCtn ||
                                                                    'Offer')}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {formatNumber(order?.totalOrderQtyPCS ||
                                                                    'Offer')}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    formatNumber(order?.totalDiscountBDT)
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {formatNumber(order?.totalPrice ||
                                                                    'Offer')}
                                                            </td>
                                                            <td
                                                                className="align-middle text-center"
                                                                style={{
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                {/* {order?.status === 2 && <FontAwesomeIcon icon={faClock} style={{ color: 'orange', marginRight: '5px' }} />} 
                                                        {order?.status === 2 && "Pending.."}  */}
                                                                <StatusCell
                                                                    status={
                                                                        order?.status
                                                                    }
                                                                />
                                                            </td>

                                                            <td>
                                                                <Dropdown className="d-inline mx-2">
                                                                    <Dropdown.Toggle
                                                                        id="dropdown-autoclose-true"
                                                                        className=" bg-none"
                                                                    >
                                                                        ...
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item>
                                                                            {/* <Link to={`/master/db-order-update-asm/${order?.doNo}`}> */}
                                                                            <Link
                                                                                to={`/master/super-distributor-orders-update-others/${order?.doNo}`}
                                                                            >
                                                                                <IconButton
                                                                                    variant="falcon-default"
                                                                                    size="sm"
                                                                                    icon="edit"
                                                                                    transform="shrink-2"
                                                                                    iconAlign="middle"
                                                                                    className="me-2"
                                                                                    onClick={() =>
                                                                                        handleEditOrder(
                                                                                            order
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        Edit
                                                                                    </span>
                                                                                </IconButton>
                                                                                {/* </Link> */}
                                                                            </Link>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item>
                                                                            <Link
                                                                                to={`/master/distributor-orders-view/${order?.doNo}`}
                                                                            >
                                                                                <IconButton
                                                                                    variant="falcon-default"
                                                                                    size="sm"
                                                                                    icon="edit"
                                                                                    transform="shrink-2"
                                                                                    iconAlign="middle"
                                                                                    className="me-2"
                                                                                >
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        View
                                                                                        details
                                                                                    </span>
                                                                                </IconButton>
                                                                            </Link>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item>
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handleCancelOrder(
                                                                                        order
                                                                                    )
                                                                                }
                                                                                variant="falcon-default"
                                                                                size="sm"
                                                                                icon="trash-alt"
                                                                                iconAlign="middle"
                                                                                className="d-none d-sm-block me-2"
                                                                            >
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    Cancel
                                                                                </span>
                                                                            </IconButton>
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>

                                                        <tr
                                                            style={{
                                                                display:
                                                                    order.open &&
                                                                        expand
                                                                        ? 'table-row'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            <td colSpan={15}>
                                                                <div className="p-0">
                                                                    <CollapseOrder
                                                                        doNo={
                                                                            order?.doNo
                                                                        }
                                                                    ></CollapseOrder>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center p-3 fs-2">
                                    No Pending orders
                                </div>
                            )}
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        table
                        paginationPageCount={paginationPageCount}
                        setPaginationPageCount={setPaginationPageCount}
                        pageDataCount={pageDataCount}
                        setPageDataCount={setPageDataCount}
                        totalPages={totalPages}
                    />
                </Card.Footer>
            </Card>

            <Button
                className="mt-2 px-5"
                variant="primary"
                type="submit"
                disabled={checkedOrders?.length === 0}
                onClick={() => {
                    handleGetOrdersDeposit()
                }}
            >
                Approve
            </Button>
        </>
    )
}

export default SuperDistributorOrdersPendingOthers
