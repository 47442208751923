import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const AddSubCategory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [categoryByID, setCategoryById] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();


    // GET BY ID
    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}ProductSubCategory/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success) {
                        setCategoryById(response?.data?.payload.result);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);

    // PRODUCT CATEGORY
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productCategory/all/prods`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.proCatData.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name
                    }));

                    setCategoryList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    const handleSubmitForm = (values, actions) => {
        const handleSubmitData = {
            productCatName: values.categoryName.value,
            productSubCatName: values.subCategoryName,
            catType: values.catType.value
        };

        setIsLoading(true);
        const url = updateID
            ? `${process.env.REACT_APP_SETTINGS_BASE_URL}ProductSubCategory/${updateID}`
            : `${process.env.REACT_APP_SETTINGS_BASE_URL}ProductSubCategory`;

        const axiosMethod = updateID ? axios.patch : axios.post;

        axiosMethod(url, handleSubmitData, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    navigate("/master/sub-category");
                    toast.success(response.data.message);
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    isLoading && <LoadingIcon />

    const validationSchema = Yup.object().shape({
        subCategoryName: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please Enter a value for the Sub Category Name field").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            }),
        categoryName: Yup.object().required("Please select a category"),
        catType: Yup.object().required("Please select a category type"),
    });

    const categoryTypes = [
        { label: "Order", value: "Order" },
        { label: "Offer", value: "Offer" }
    ]


    return (
        <>
            <PageHeader
                title={updateID ? "Update Sub Category" : "Add New Sub Category"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{

                            categoryName: categoryByID?.productCatName ? {
                                label: categoryByID?.productCatName,
                                value: categoryByID?.productCatName
                            } : "",
                            subCategoryName: categoryByID?.productSubCatName || "",
                            catType: categoryByID?.catType ? {
                                label: categoryByID?.catType,
                                value: categoryByID?.catType
                            } : ""


                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Category</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={categoryList}
                                                placeholder="Select Category"
                                                classNamePrefix="react-select"
                                                name="categoryName"
                                                value={values.categoryName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "categoryName",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("categoryName", true, true) }}

                                            />

                                            {
                                                touched.categoryName && errors.categoryName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.categoryName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Sub Category Name</Form.Label>
                                            <Form.Control
                                                name="subCategoryName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Sub Category Name"
                                                required
                                                value={values.subCategoryName}
                                            />
                                            {touched.subCategoryName && errors.subCategoryName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.subCategoryName}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Category Type</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={categoryTypes}
                                                placeholder="Select Category Type"
                                                classNamePrefix="react-select"
                                                name="catType"
                                                value={values.catType}
                                                defaultValue={{
                                                    label: "Order",
                                                    value: "Order"
                                                }}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("catType", selectedOption);

                                                }}

                                                onBlur={event => { handleBlur(event); setFieldTouched("catType", true, true); }}
                                            />
                                            {touched.catType && errors.catType && (
                                                <div style={{ color: "red", fontSize: "1rem" }}>
                                                    {errors.catType}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"

                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/sub-category")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default AddSubCategory;


