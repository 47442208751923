import FalconComponentCard from 'components/common/FalconComponentCard'
import PageHeader from 'components/common/PageHeader'
import IconButton from 'components/common/IconButton'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from 'utils'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Select from 'react-select'
import LoadingIcon from 'helpers/LoadingIcon'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { PHONE_NUMBER_REGEX } from 'utils/validationRegex'
import useUserData from '../../../../hooks/useUserData'

const RoutesCreateForm = () => {
    const [selectedZone, setSelectedZone] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('')
    const [selectedArea, setSelectedArea] = useState('')
    const [selectedDbPoint, setSelectedDbPoint] = useState('')
    const [zoneList, setZoneList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [areaList, setAreaList] = useState([])
    const [dbPointList, setDbPointList] = useState([])
    const [distributors, setDistributors] = useState([])
    const [distributorById, setDistributorById] = useState([])
    const [isLoading, setIsLoading] = useState({
        general: false,
        zone: false,
        region: false,
        area: false,
        dbPoint: false,
        db: false,
    })
    const { updateID } = useParams()
    const navigate = useNavigate()
    const { companyName } = useUserData()

    const statusList = [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
    ]


    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading((prevState) => ({ ...prevState, general: true }))
                try {
                    const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/getSRById/${updateID}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success) {
                        const data = response.data.payload.sr
                        setDistributorById(data)
                        setSelectedZone(data?.zoneName)
                        setSelectedRegion(data?.regionName)
                        setSelectedArea(data?.areaName)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        general: false,
                    }))
                }
            }
        }

        fetchData()
    }, [updateID])

    useEffect(() => {
        setIsLoading((prevState) => ({ ...prevState, zone: true }))
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`
                const response = await axios.get(url, { headers: authHeader() })

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(
                        (data) => ({
                            id: data._id,
                            label: data.name,
                            value: data.name,
                        })
                    )

                    setZoneList(modifyData)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading((prevState) => ({ ...prevState, zone: false }))
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (selectedZone) {
            setIsLoading((prevState) => ({ ...prevState, region: true }))
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region?zoneName=${selectedZone}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.regionName,
                                value: data.regionName,
                            })
                        )

                        setRegionList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        region: false,
                    }))
                }
            }

            fetchData()
        }
    }, [selectedZone])

    useEffect(() => {
        if (selectedRegion) {
            setIsLoading((prevState) => ({ ...prevState, area: true }))
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}areas/${selectedRegion}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })

                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.areaName,
                                value: data.areaName,
                            })
                        )

                        setAreaList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({ ...prevState, area: false }))
                }
            }

            fetchData()
        }
    }, [selectedRegion])

    useEffect(() => {
        if (selectedArea) {
            setIsLoading((prevState) => ({ ...prevState, dbPoint: true }))
            const fetchData = async () => {
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}dbPoint?areaName=${selectedArea}`
                    const response = await axios.get(url, {
                        headers: authHeader(),
                    })
                    if (response.data.success || response.status === 200) {
                        const modifyData = response?.data?.payload?.result?.map(
                            (data) => ({
                                id: data._id,
                                label: data.dbPointName,
                                value: data.dbPointName,
                            })
                        )
                        setDbPointList(modifyData)
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response)
                } finally {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        dbPoint: false,
                    }))
                }
            }
            fetchData()
        }
    }, [selectedArea])


    const handleSubmitForm = async (values, actions) => {
        const handleSubmitData = {

            zoneName: values.zoneName.value,
            regionName: values.regionName.value,
            areaName: values.areaName.value,
            dbPoint: values.dbPointName.value,
            routeName: values.routeName,
            status: values.status.value,
        }

        setIsLoading((prevState) => ({ ...prevState, general: true }))
        const url = updateID
            ? `${process.env.REACT_APP_SETTINGS_BASE_URL}Route/updateRouteById/${updateID}`
            : `${process.env.REACT_APP_SETTINGS_BASE_URL}Route/createRoute`

        try {
            const axiosMethod = updateID ? axios.put : axios.post
            const response = await axiosMethod(url, handleSubmitData, {
                headers: authHeader(),
            })

            if (response.status === 200 || response.data.status) {
                navigate('/master/create-route')
                toast.success(response.data.message)
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading((prevState) => ({ ...prevState, general: false }))
        }
    }

    // Yup validation schema
    const handleSchema = Yup.object().shape({
        zoneName: Yup.object().required(
            'please select a value for the zone field'
        ),
        regionName: Yup.object().required(
            'please select a value for the region field'
        ),
        areaName: Yup.object().required(
            'please select a value for the area field'
        ),
        dbPointName: Yup.object().required(
            'please select a value for the db point field'
        ),
        routeName: Yup.string().required('Route Name is required'),
        status: Yup.object().required('Status is required')

    })

    return (
        <>
            <PageHeader
                title={updateID ? 'Update  Route ' : 'Create Route'}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard>
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    {isLoading.general && <LoadingIcon />}
                    <Formik
                        initialValues={{
                            zoneName:
                                zoneList.find(
                                    (item) =>
                                        item.value === distributorById.zoneName
                                ) || null,
                            regionName:
                                regionList.find(
                                    (item) =>
                                        item.value ===
                                        distributorById?.regionName
                                ) || null,
                            areaName:
                                areaList.find(
                                    (item) =>
                                        item.value === distributorById?.areaName
                                ) || null,
                            dbPointName:
                                dbPointList.find(
                                    (item) =>
                                        item.value === distributorById?.dbPoint
                                ) || null,
                            routeName: distributorById?.routeName || '',
                            status: statusList.find(item => item.value === distributorById.status) || '',

                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched,
                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Select Zone</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={zoneList}
                                                placeholder="Select Zone"
                                                classNamePrefix="react-select"
                                                name="zoneName"
                                                value={values.zoneName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'zoneName',
                                                        selectedOption
                                                    )
                                                    setSelectedZone(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'zoneName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.zone}
                                            />
                                            {touched.zoneName &&
                                                errors.zoneName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.zoneName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select Region
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={regionList}
                                                placeholder="Select Region"
                                                classNamePrefix="react-select"
                                                name="regionName"
                                                value={values.regionName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'regionName',
                                                        selectedOption
                                                    )
                                                    setSelectedRegion(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'regionName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.region}
                                            />
                                            {touched.regionName &&
                                                errors.regionName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.regionName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>Select Area</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={areaList}
                                                placeholder="Select Area"
                                                classNamePrefix="react-select"
                                                name="areaName"
                                                value={values.areaName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'areaName',
                                                        selectedOption
                                                    )
                                                    setSelectedArea(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'areaName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.area}
                                            />
                                            {touched.areaName &&
                                                errors.areaName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.areaName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleFirstName"
                                        >
                                            <Form.Label>
                                                Select DB Point
                                            </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={dbPointList}
                                                placeholder="Select DB Point"
                                                classNamePrefix="react-select"
                                                name="dbPointName"
                                                value={values.dbPointName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        'dbPointName',
                                                        selectedOption
                                                    )
                                                    setSelectedDbPoint(
                                                        selectedOption.value
                                                    )
                                                }}
                                                onBlur={(event) => {
                                                    handleBlur(event)
                                                    setFieldTouched(
                                                        'dbPointName',
                                                        true,
                                                        true
                                                    )
                                                }}
                                                isLoading={isLoading.dbPoint}
                                            />
                                            {touched.dbPointName &&
                                                errors.dbPointName && (
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.dbPointName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Route Name</Form.Label>
                                            <Form.Control
                                                name="routeName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Route Name"
                                                required
                                                value={values.routeName}

                                            />
                                            {touched.routeName && errors.routeName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.routeName}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Status</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={statusList}
                                                placeholder="Select Status"
                                                classNamePrefix="react-select"
                                                name="status"
                                                value={values.status}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "status",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("status", true, true) }}
                                            />

                                            {
                                                touched.status && errors.status && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.status}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>




                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? 'Save' : 'Submit'}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate('/master/create-route')}
                                        variant="danger"
                                        type="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    )
}

export default RoutesCreateForm;
