import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const ScmPurchaseConfirm = ({ setStep,setSearchText, selectedDate, selectedExpiryDate, batchNo, checkedProducts, setCheckProducts, setCount, vendorName, selectedFactory, transportData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);


    // handle remove product
    const handleRemoveProduct = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            const result = checkedProducts?.filter(product => product?._id !== id);
            setCheckProducts(result);

            Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
            });


        }
    }



    const handleSubmit = async () => {
        const receivedProduct = checkedProducts.map((product) => {
            const receivedProduct = {
                userId: distributorInfo?.userId,
                companyName: distributorInfo?.companyName,
                factoryName: selectedFactory?.value,
                productCategory: product?.categoryName,
                subCategory: product?.subCategory || "",
                skuName: product?.skuName,
                productId: product?.productId,
                rcvQtyCTN: product?.ctn,
                rcvQtyPCS: product?.ctn * product?.pcsCTN,//product?.pcs,
                rcvQtyMt: product?.weightMT,
                perPCPrice: 0,
                transportName: transportData?.transportName?.value,
                transportNo: transportData?.transportNumber,
                driverName: transportData?.driverName,
                driverPhone: transportData?.driverNumber,
                purchaseDate: selectedDate,
                expireDate: selectedExpiryDate,
                PONo: batchNo,
                note: product?.note || "",
                purchaseFrom: vendorName,
                purchaseStatus: 1,
                status: 0
            };
            return receivedProduct;
        });

        try {
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv`, receivedProduct, { headers: authHeader() });
            if (response.data.status || response.status === 200) {
                // Clear the form and update state
                setStep(1);
                setSearchText("");
                setCheckProducts([]); // Make sure this matches your state setter
                setCount(prevState => prevState + 1);

                // Display success message
                Swal.fire({
                    title: "Received!",
                    text: "Your file has been received.",
                    icon: "success"
                });
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }

    };

    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>
            <Card className="mb-3">
            <Card.Header>
                    <div className="">
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase from :</h6>
                                <Form.Control type="text" placeholder="Enter Purchase from" value={vendorName} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase For :</h6>
                                <Form.Control type="text" placeholder="Enter Purchase For" value={selectedFactory?.value} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">PO No. :</h6>
                                <Form.Control type="text" placeholder="Enter PO No." value={batchNo} disabled />
                            </Form.Group>
                        </Row  >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transport :</h6>
                                <Form.Control type="text" placeholder="Enter Transport" value={transportData?.transportName?.value} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Vehicle No. :</h6>
                                <Form.Control type="text" placeholder="Enter Vehicle No." value={transportData?.transportNumber} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Name:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Name" value={transportData?.driverName} disabled />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Number:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Number" value={transportData?.driverNumber} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase Date :</h6>
                                <Form.Control type="text" placeholder="Enter Driver Number" value={new Date(selectedDate).toLocaleDateString()} disabled />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Expiry Date :</h6>
                                <Form.Control type="text" placeholder="" value={selectedExpiryDate} disabled />
                            </Form.Group>
                        </Row>
                    </div>
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Sub Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">Action</th>
                                        <th colSpan="2" className="align-middle white-space-nowrap text-center ">Note</th>

                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        checkedProducts?.map((product, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.categoryName}</td>
                                                <td className="align-middle text-center">{product?.subCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">
                                                    {product?.ctn}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {product?.pcs}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {product?.weightMT}
                                                </td>

                                                <td className="align-middle text-center">
                                                    <Button variant="danger" onClick={() => handleRemoveProduct(product?._id)}>Cancel</Button>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {product?.note}
                                                </td>
                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>

            <Button className="mt-2 px-5 me-3" variant="primary" type="submit" onClick={() => { handleSubmit(); }}>
                Confirm
            </Button>

            <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { setStep(1); }}>
                Back
            </Button>


        </>
    );

};

export default ScmPurchaseConfirm;
