import PropTypes from 'prop-types'
import React, { useEffect, useState, memo } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import SimpleBarReact from 'simplebar-react'
import { formatNumber } from 'helpers/utils';
import Swal from 'sweetalert2'
import axios from 'axios'
import { authHeader } from 'utils';
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";


const formatDate = (dt) => {
    const originalDateString = dt
    const originalDate = new Date(originalDateString)

    const year = originalDate.getFullYear()
    const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
    const day = String(originalDate.getDate()).padStart(2, '0')

    const formattedDateString = `${year}-${month}-${day}`

    return formattedDateString
}

const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '180', // Set your desired width here
    }),
    control: (provided) => ({
        ...provided,
        width: '180px', // Ensure control element also respects the width
    }),
};


const getTotalPrice = (items) =>
    items
        .map(({ unit, price }) => unit * price)
        .reduce((total, currentValue) => total + currentValue, 0)

const getTotalOrder = (items) =>
    items
        .map(({ unit }) => unit)
        .reduce((total, currentValue) => total + currentValue, 0)

const TabContentTableTableRow = ({
    deposit,
    checkedDeposits,
    setCheckedDeposits,
    myStatus,
    viewDetailsByCollection,
    selectedDoNo,
    distributorValue
}) => {


    const handleCheckboxChange = (deposit) => {
        if (isChecked(deposit)) {
            setCheckedDeposits(checkedDeposits.filter(p => p._id !== deposit._id));
        } else {
            setCheckedDeposits([...checkedDeposits, deposit]);
        }
        viewDetailsByCollection(deposit.doNo);

    };

    // Check if a product is checked
    const isChecked = (deposit) => {
        return checkedDeposits.some(p => p._id === deposit._id);
    };

    // let colorClass = "";
    // if (selectedDoNo === deposit.trId) {
    //     colorClass = "bg-soft-primary";

    // }

    // const handleClick = (doNo) => {

    //     viewDetailsByCollection(doNo);
    // };

    const viewImage = async (imageId) => {

        try {
            const response = await axios.get(`https://khoz.net:3015/api/v1/image/${imageId}`, { headers: authHeader() });
            if (response.data.success && response.data.payload) {
                const data = response.data.payload;
                if (!data.imageData) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No image found!',
                    });
                    return;
                }

                const base64Image = response.data.payload.imageData.image;

                // Check if the base64 string contains the data URL prefix and remove it if present
                const base64Prefix = 'data:image/jpeg;base64,';
                let base64String;
                if (base64Image.startsWith(base64Prefix)) {
                    base64String = base64Image.substring(base64Prefix.length);
                } else {
                    base64String = base64Image;
                }

                // Convert base64 to Blob
                try {
                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'image/jpeg' });

                    // Create a URL for the Blob and open it in a new tab
                    const imageUrl = URL.createObjectURL(blob);
                    window.open(imageUrl, '_blank');
                } catch (e) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Failed to open image!',
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No image found!',
                });
            }
        } catch (error) {
            // handleApiResponseErrors(error.response);
        }
    };

    let colorClass = "";
    if (selectedDoNo === deposit.doNo) {
        colorClass = "bg-soft-primary";

    }


    return (
        <tr className={"border-top border-200" + " "
            // + colorClass
        }>
            {myStatus?.value === "pending" && distributorValue?.value ? <td className="align-middle text-center"><input
                type="checkbox"
                checked={isChecked(deposit)}
                onChange={() => handleCheckboxChange(deposit)}
            /></td> : null}

            <td className={"align-middle text-start fw-semi-bold"}
            // onClick={() => handleClick(deposit.doNo)}
            >{deposit.trId}</td>
            <td className={"align-middle text-start fw-bold cursor-pointer"}
            // onClick={() => handleClick(deposit.doNo)}
            >{deposit.doNo}</td>
            <td className="align-middle text-start fw-semi-bold">{formatNumber(deposit.drBDT)}</td>
            <td className="align-middle text-start fw-semi-bold">{formatNumber(deposit?.bankChargeBDT || 0)}</td>

            <td>{deposit.dealerName + " (" + deposit.dealerId + ")"}</td>
            <td className="align-middle text-start fw-semi-bold">{deposit.bankNames}</td>
            <td className="align-middle text-start fw-semi-bold">{deposit.bankAccountNo}</td>

            <td className="align-middle text-start fw-semi-bold">{deposit.depositorsBankName + " - " + deposit.depositorsBranchName}</td>


            <td className="align-middle text-start fw-semi-bold">{deposit.paymentDate.split("T")[0]}</td>

            <td className="text-start">{deposit.status === 2 ? "Approved" : deposit.status === 1 ? "Pending" : "N/A"}</td>

            <td>
                <div className='d-flex align-items-center'>

                    <Button
                        onClick={() => {
                            viewImage(deposit.trId)
                        }}
                        size="sm"
                        className='me-2'
                    >
                        Attach.
                    </Button>


                    {deposit?.status === 1 && <>
                        <Link
                            to={`/master/deposit/update/${deposit?._id}/${deposit?._id}`}
                        >
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="edit"
                                transform="shrink-2"
                                iconAlign="middle"
                                className="me-2"

                            >
                                {/* <span className="d-none d-xl-inline-block ms-1">Edit</span> */}
                            </IconButton>
                        </Link>

                    </>}
                </div>

            </td>
        </tr>
    )
}

const TabContentTableDeposit = ({
    deposits,
    checkedDeposits,
    setCheckedDeposits,
    handleApproveOrder,
    myStatus,
    viewDetailsByCollection,
    selectedDoNo,
    viewDetailsByCollections,
    distributorValue
}) => {

    // const [detailsArray, setDetailsArray] = useState([]);

    // const [selectedDoNo, setSelectedDoNo] = useState(null);


    // const viewDetails = (deposit) => {
    //     if (order.doNo == detailsArray[0]?.doNo) {
    //         setDetailsArray([])

    //     } else {
    //         setDetailsArray(order?.data || []);
    //         setTotalOrder((order?.data || [])?.filter(item => item.orderQtyCtn)?.reduce((a, b) => a + b.totalPrice, 0))
    //         setSelectedDoNo(order?.doNo);
    //     }
    // }

    const handleCheckAllDeposits = () => {
        if (checkedDeposits.length === deposits.length) {
            setCheckedDeposits([]);
        } else {
            setCheckedDeposits(deposits);
            viewDetailsByCollections(deposits.map(item => item.doNo));
        }
    };



    return (
        <>
            <Card className="h-lg-100 overflow-hidden">
                <Card.Body className="p-0">
                    <SimpleBarReact>
                        <Table borderless className="mb-10 fs--1">
                            <thead className="bg-light">
                                <tr className="text-900">
                                    {myStatus?.value === "pending" && distributorValue?.value  ? <td className="align-middle text-center">
                                        <input
                                        type="checkbox"
                                        checked={checkedDeposits.length === deposits.length}
                                        onChange={() => handleCheckAllDeposits()}
                                    /></td> : null}
                                    {/* <th></th> */}
                                    <th>Transaction No</th>
                                    <th>DO No</th>
                                    <th className="text-start">Amount</th>
                                    <th className="text-end">Bank Charge</th>

                                    <th>DB Name</th>
                                    <th className="text-start">Bank</th>
                                    <th className="text-start">Bank Account</th>
                                    <th className="text-start">DB Bank</th>
                                    <th>Payment Date</th>
                                    <th className="text-end">Status</th>

                                    <th className="text-start"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {deposits.map((deposit) => (
                                    <TabContentTableTableRow
                                        deposit={deposit}
                                        checkedDeposits={checkedDeposits}
                                        setCheckedDeposits={setCheckedDeposits}
                                        myStatus={myStatus}
                                        viewDetailsByCollection={viewDetailsByCollection}
                                        selectedDoNo={selectedDoNo}
                                        deposits={deposits}
                                        distributorValue={distributorValue}

                                    />
                                ))}





                            </tbody>
                        </Table>
                    </SimpleBarReact>

                    {/* {detailsArray.length > 0 ?
                        (
                            <SimpleBarReact>
                                <Table borderless className="mb-10 fs--1">
                                    <thead className="bg-light">
                                        <tr className="text-900">

                                            <th>SKU Name</th>
                                            <th className="text-start">Unit Name</th>
                                            <th className="text-start">Quantity</th>
                                            <th className="text-end">Rate</th>
                                            <th className="text-end">Approved Qty</th>
                                            <th className="text-end">Approved Rate</th>
                                            <th className="text-end">Override Rate</th>
                                            <th className="text-end">Total Amount</th>
                                            <th className="text-end">Adjust Amount</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {detailsArray?.filter(item => item.orderQtyCtn)?.map((order) => (
                                            <tr>
                                                <td>{order?.skuName}</td>
                                                <td className="text-start">{order?.unitName}</td>
                                                <td className="text-start">{order?.orderQtyCtn}</td>
                                                <td className="text-end">{formatNumber(order?.dpCTN)}</td>
                                                <td className="text-end">{order?.orderQtyCtn}</td>
                                                <td className="text-end">{formatNumber(order?.dpCTN)}</td>
                                                <td className="text-end">{formatNumber(order?.dpCTN)}</td>
                                                <td className="text-end">{formatNumber(order?.totalPrice)}</td>
                                                <td className="text-end">{formatNumber(order?.totalPrice)}</td>

                                            </tr>
                                        ))}

                                        <tr>
                                            <td></td>
                                            <td className="text-start"></td>
                                            <td className="text-start"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end fw-bold">{detailsArray?.filter(item => item.orderQtyCtn)?.reduce((a, b) => a + b.totalPrice, 0)}</td>
                                            <td className="text-end fw-bold">{detailsArray?.filter(item => item.orderQtyCtn)?.reduce((a, b) => a + b.totalPrice, 0)}</td>

                                        </tr>





                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        ) : null} */}
                </Card.Body>
                <Card.Footer className="bg-light py-2">
                    <Row className="flex-between-center">
                        <Col xs="auto"></Col>
                        <Col xs="auto">
                            <div className='d-flex justify-content-end'>


                                <Button
                                    onClick={() => handleApproveOrder("approved")}
                                    variant="falcon-default"
                                    size="sm"
                                    disabled={myStatus?.value === "approved" || !distributorValue?.value}
                                    

                                >
                                    Approve
                                </Button>
                                <Button
                                    onClick={() => handleApproveOrder("rejected")}
                                    variant="falcon-default"
                                    size="sm"
                                    className='ms-1'
                                    disabled={myStatus?.value === "approved" || !distributorValue?.value}
                                >
                                    Reject
                                </Button>
                            </div>
                        </Col>

                    </Row>
                </Card.Footer>
            </Card>

        </>
    )
}

TabContentTableTableRow.propTypes = {
    totalPrice: PropTypes.number.isRequired,
    totalOrder: PropTypes.number.isRequired,
    product: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        img: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        unit: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
    }).isRequired,
}

TabContentTableDeposit.propTypes = {
    products: PropTypes.arrayOf(TabContentTableTableRow.propTypes.product)
        .isRequired,
}

export default TabContentTableDeposit
