import FalconComponentCard from "components/common/FalconComponentCard";
import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Papa from 'papaparse';
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, InputGroup, Dropdown, Card, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Flex from "components/common/Flex";
import FalconCloseButton from "components/common/FalconCloseButton";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { authHeaderForm } from "state/ducs/auth/utils";
import useUserRole from "hooks/useUserRole";
import GetSingleDate from "components/common/date/GetSingleDate";
import { set } from "date-fns";
import Swal from "sweetalert2";
import ProgressBar from 'react-bootstrap/ProgressBar';

const UploadTarget = () => {
    const [progress, setProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState({ status: false, message: "" });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const navigate = useNavigate();
    const { name } = useSelector((state) => state.auth.user.data);

    // DATE
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    /* CSV FILE UPLOAD */
    const onDrop = (acceptedFiles) => {
        setFiles(acceptedFiles);
    };

    const { getRootProps: getCsvRootProps, getInputProps: getCsvInputProps } = useDropzone({
        onDrop,
        accept: ".csv"
    });

    const getFiles = files.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const handleCSVUpload = () => {
        if (!selectedDate) {
            Swal.fire({ title: "Please select a date", icon: "error" });
            return;
        }
        if (files.length === 0) {
            Swal.fire({ title: "Please select a file", icon: "error" });
            return;
        }

        const csvFile = files[0];
        const requiredFields = ["SL", "distributorName", "dealerId", "userRole", "zoneName", "regionName", "areaName", "dbPoint", "skuName", "skuPrice", "ctnPrice"];

        const validateCSV = (data) => {
            for (let i = 0; i < data.length; i++) {
                const row = data[i];
                for (let field of requiredFields) {
                    const value = row[field]?.trim() || '';
                    console.log(`Validating field "${field}" with value "${value}" in row ${i + 1}`);
                    if (!value) {
                        return { valid: false, message: `The field "${field}" in row ${i + 1} is required and cannot be empty.` };
                    }

                    // Additional validation to check for specific false values
                    if (field === 'skuPrice' && (value === '0' || parseFloat(value) <= 0)) {
                        return { valid: false, message: `The field "${field}" in row ${i + 1} must be a valid positive number.` };
                    }
                    if ((field === 'targetQuantityPcs' || field === 'targetQuantityCtn') && (value === '0' || parseFloat(value) <= 0)) {
                        return { valid: false, message: `The field "${field}" in row ${i + 1} must be a valid positive number.` };
                    }
                    // Add more specific validations as needed
                }
            }
            return { valid: true };
        };


        setIsLoading({ status: true, message: "Uploading..." });
        setProgress(5);

        const config = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentage = Math.min(Math.floor((loaded * 100) / total), 99);
                setProgress(percentage);
            }
        };

        Papa.parse(csvFile, {
            complete: function (results) {
                const checkData = results.data.filter(item => {
                    const targetQuantityPcs = parseFloat(item.targetQuantityPcs);
                    const targetQuantityCtn = parseFloat(item.targetQuantityCtn);
                    return item.skuPrice !== 0 && (!isNaN(targetQuantityPcs) && targetQuantityPcs > 0 || !isNaN(targetQuantityCtn) && targetQuantityCtn > 0);
                }).map(item => {
                    item.targetYear = selectedDate.getFullYear();
                    item.targetMonth = selectedDate.getMonth() + 1;
                    return item;
                });

                const validationResult = validateCSV(checkData);
                console.log("Validation result:", validationResult);
                if (!validationResult.valid) {
                    toast.error(validationResult.message);
                    setIsLoading({ status: false, message: "" });
                    return;
                }


                if (checkData.length === 0) {
                    Swal.fire({ title: "No valid data found in the file", icon: "error" });
                    setIsLoading({ status: false, message: "" });
                    return;
                }

                const csvString = Papa.unparse(checkData);
                const blob = new Blob([csvString], { type: 'text/csv' });
                const formData = new FormData();
                formData.append("csvTargetFile", blob, "checkData.csv");
                formData.append("uploadedBy", name);

                const regUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/orderTargetUpload`;

                axios.post(regUrl, formData, { headers: authHeaderForm(), ...config })
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire({ title: "Success", text: response.data.message, icon: "success" });
                            setFiles([]);
                        } else if (response.data.success === false) {
                            Swal.fire({ title: "Error", text: response.data.message, icon: "error" });
                        }
                    })
                    .catch(error => {
                        handleApiResponseErrors(error.response);
                    })
                    .finally(() => {
                        setIsLoading({ status: false, message: "" });
                    });
            },
            header: true,
            skipEmptyLines: true
        });
    };

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader title="Target upload" isNew={false} isExport={false} />
                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                            <Col xs="auto">
                                <h6 className="text-700 mb-0 text-nowrap">Select Month & Year:</h6>
                            </Col>
                            <Col md="auto">
                                <GetSingleDate onDateChange={handleDateChange} />
                            </Col>
                        </Row>
                    </div>
                </Card.Header>
            </Card>
            <FalconComponentCard>
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <>
                        <div {...getCsvRootProps({ className: "dropzone-area py-6" })}>
                            <input {...getCsvInputProps({ multiple: false })} />
                            <Flex justifyContent="center">
                                <img src={cloudUpload} alt="" width={25} className="me-2" />
                                <p className="fs-0 mb-0 text-700">Drop your file here</p>
                            </Flex>
                        </div>
                        <div className="mt-3">
                            {files.length > 0 && (
                                <>
                                    <h6>File</h6>
                                    <ul>{getFiles}</ul>
                                </>
                            )}
                        </div>
                        <Button variant="primary" disabled={isLoading.status} onClick={handleCSVUpload}>
                            Upload
                        </Button>

                        {isLoading.status && isLoading.message && <div className="mt-4">
                            <ProgressBar now={progress} label={`${progress}%`} animated />
                            <br />
                            <h5 className="text-center">{isLoading.message}</h5>
                        </div>}
                    </>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default UploadTarget;
