import axios from 'axios'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import useUserData from 'hooks/useUserData'
import SimpleBarReact from 'simplebar-react'
import { useEffect, useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { authHeader } from 'utils'
import { formatNumber } from 'helpers/utils'
import PageHeader from 'components/common/PageHeader'
import LoadingIcon from 'helpers/LoadingIcon'
import SoftBadge from 'components/common/SoftBadge'
import image from 'assets/img/icons/spot-illustrations/corner-2.png'
import Background from 'components/common/Background'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PendingDeliveries = () => {
    const [step, setStep] = useState({
        step: 1,
        reload: false,
    })
    const [isLoading, setIsLoading] = useState(false)
    const [pendingDeliveries, setPendingDeliveries] = useState([])
    const { factoryName } = useUserData()

    // Get Pending Deliveries Data
    useEffect(() => {
        if (!factoryName) return
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_DISTRIBUTION_MANAGEMENT_BASE_URL}orders-distribution/TrackLoadPendingDel`,
                    { factoryName: factoryName },
                    { headers: authHeader() }
                )
                if (response.data.success || response.status === 200) {
                    const pendingDeliveriesData =
                        response.data.payload.TrackLoading
                    setPendingDeliveries(pendingDeliveriesData)
                }
            } catch (error) {
                handleApiResponseErrors(error)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [factoryName])


  
    if (isLoading) return <LoadingIcon />

    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="info" pill className="">
                    Undelivered Distributors
                </SoftBadge>
                <div className="mt-2">
                    <h2 className="fw-normal text-700 mb-1 lh-1">
                        {pendingDeliveries?.length || 0}
                    </h2>
                    <Link
                        to={'distribution/productdelivery/pending'}
                        className="fw-semi-bold fs--1 text-nowrap"
                    >
                        {'See all'}
                        <FontAwesomeIcon
                            icon="angle-right"
                            className="ms-1"
                            transform="down-1"
                        />
                    </Link>
                </div>
            </Card.Body>
        </Card>
    )
}

export default PendingDeliveries
