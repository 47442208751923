import PropTypes from 'prop-types'
import React, { useEffect, useState, memo } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import SimpleBarReact from 'simplebar-react'
import { formatNumber } from 'helpers/utils';
import Swal from 'sweetalert2'
import StatusCell from 'components/common/StatusCell'
import axios from 'axios'
import { authHeader } from 'utils';


const formatDate = (dt) => {
    const originalDateString = dt
    const originalDate = new Date(originalDateString)

    const year = originalDate.getFullYear()
    const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
    const day = String(originalDate.getDate()).padStart(2, '0')

    const formattedDateString = `${year}-${month}-${day}`

    return formattedDateString
}

const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '180', // Set your desired width here
    }),
    control: (provided) => ({
        ...provided,
        width: '180px', // Ensure control element also respects the width
    }),
};


const getTotalPrice = (items) =>
    items
        .map(({ unit, price }) => unit * price)
        .reduce((total, currentValue) => total + currentValue, 0)

const getTotalOrder = (items) =>
    items
        .map(({ unit }) => unit)
        .reduce((total, currentValue) => total + currentValue, 0)

const TabContentTableTableRow = ({
    order,
    setCheckedOrders,
    checkedOrders,
    viewDetails,
    selectedDoNo,
    myStatus,
    distributorValue


}) => {




    const handleCheckboxChange = (order) => {
        if (isChecked(order)) {
            setCheckedOrders(checkedOrders.filter(p => p.doNo !== order.doNo));
            // setCheckedOrderWithBanks(checkedOrderWithBanks.filter(p => p.doNo !== order.doNo));
        } else {
            // setCheckedOrders([...checkedOrders, order]);
            setCheckedOrders([order]);
            // fetchPaymentData(order.doNo);
        }
    };

    // Check if a product is checked
    const isChecked = (order) => {
        return checkedOrders.some(p => p.doNo === order.doNo);
    };

    let colorClass = "";
    if (selectedDoNo === order.doNo) {
        colorClass = "bg-soft-primary";

    }

    return (
        <tr className={"border-top border-200" + " " + colorClass}>

            {myStatus?.value === "pending" && distributorValue?.value ? <td className="align-middle text-center"><input
                type="checkbox"
                checked={isChecked(order)}
                onChange={() => {
                    handleCheckboxChange(order);
                    viewDetails(order);
                }}
            /></td> : null}

            <td className={"align-middle text-start fw-bold cursor-pointer"} onClick={() => { viewDetails(order); handleCheckboxChange(order); }}>{order.doNo}</td>
            <td className="align-middle text-start fw-semi-bold">{formatNumber(order.totalPrice)}</td>

            <td>{order.distributorName + " (" + order.dealerId + ")"}</td>


            <td className="align-middle text-start fw-semi-bold">{order.doDate.split("T")[0]}</td>

            {/* <td className="align-middle text-start fw-semi-bold">{"Adjust Amount"}</td>
            <td className="text-start">{"CQR"}</td>
            <td className="align-middle text-end fw-semi-bold">
                {"Credit Limit"}
            </td> */}
            <td className="align-middle pe-x1 text-end">
                {order.status === 7 ? "Approved" : order.status === 6 ? "Pending" : "N/A"}
            </td>

            {/* 


            <td className="text-start">{"--"}</td>
            <td className="text-start">{"--"}</td> */}

            {/* <td>
                <Button
                    onClick={() => {

                    }}
                    size="sm"
                >
                    View
                </Button>
            </td> */}
        </tr>
    )
}

const TabContentTable = ({
    orders,
    checkedOrders,
    setCheckedOrders,
    setTotalOrder,
    selectedDoNo,
    setSelectedDoNo,
    viewDetails,
    detailsArray,
    paymentData,
    setPaymentData,
    checkedOrderWithBanks,
    setCheckedOrderWithBanks,
    handleSubmit2,
    myStatus,
    distributorValue
}) => {

    const updatePaymentData2 = async (orderWithBank) => {

        orderWithBank.drBDT = orderWithBank.crBDT - orderWithBank.drBDT || 0;

        setCheckedOrderWithBanks([...checkedOrderWithBanks, orderWithBank]);
    };


    const handleCheckboxChange2 = (order, orderWithBank) => {
        if (isChecked(order)) {
            // setCheckedOrders(checkedOrders.filter(p => p.doNo !== order.doNo));
        } else {
            setCheckedOrders([...checkedOrders, order]);
            updatePaymentData2(orderWithBank);
        }
    };

    // Check if a product is checked
    const isChecked = (order) => {
        return checkedOrders.some(p => p.doNo === order.doNo);
    };



    return (
        <>
            <Card className="h-lg-100 overflow-hidden">
                <Card.Body className="p-0">
                    <SimpleBarReact>
                        <Table borderless className="mb-10 fs--1">
                            <thead className="bg-light">
                                <tr className="text-900">
                                    {myStatus?.value === "pending" ? <th></th> : null}
                                    {/* <th></th> */}
                                    <th>DO No</th>
                                    <th className="text-start">Total Amount</th>
                                    <th>DB Name</th>
                                    <th>Do Date</th>
                                    {/* <th className="text-start">Adjust Amount</th>
                                    <th className="text-end">CQR</th>
                                    <th className="text-end">Credit Limit</th> */}
                                    <th className="text-end">Status</th>
                                    {/* <th className="text-start">Offer SKU</th>
                                <th className="text-start">Offer Qty (PC)</th>
                                <th className="text-start">Order Date</th> */}
                                    {/* <th className="text-start"></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order) => (
                                    <TabContentTableTableRow
                                        order={order}
                                        checkedOrders={checkedOrders}
                                        setCheckedOrders={setCheckedOrders}
                                        viewDetails={viewDetails}
                                        selectedDoNo={selectedDoNo}
                                        checkedOrderWithBanks={checkedOrderWithBanks}
                                        setCheckedOrderWithBanks={setCheckedOrderWithBanks}
                                        myStatus={myStatus}
                                        distributorValue={distributorValue}


                                    />
                                ))}





                            </tbody>
                        </Table>
                    </SimpleBarReact>

                    {detailsArray.length > 0 ?
                        (
                            <SimpleBarReact>
                                <Table borderless className="mb-10 fs--1">
                                    <thead className="bg-light">
                                        <tr className="text-900">

                                            <th>SKU Name</th>
                                            <th className="text-start">Unit Name</th>
                                            <th className="text-start">Order Quantity</th>
                                            <th className="text-end">Rate</th>
                                            <th className="text-end">Approved Qty</th>
                                            <th className="text-end">Approved Rate</th>
                                            <th className="text-end">Override Rate</th>
                                            <th className="text-end">Offer SKU Name</th>
                                            <th className="text-end">Offer Qty</th>
                                            <th className="text-end">Offer UOM</th>
                                            <th className="text-end">Total Amount</th>
                                            <th className="text-end">Adjust Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {detailsArray?.filter(item => item.orderQtyCtn || item.offerNo)?.map((order) => (
                                            <tr>
                                                <td>{order?.skuName || order?.offerName}</td>
                                                <td className="text-start">{order?.offerNo ? "--" : order?.unitName}</td>
                                                <td className="text-start">{order?.orderQtyCtn || "--"}</td>
                                                <td className="text-end">{formatNumber(order?.dpCTN) || "--"}</td>
                                                <td className="text-end">{order?.orderQtyCtn || "--"}</td>
                                                <td className="text-end">{formatNumber(order?.dpCTN) || "--"}</td>
                                                <td className="text-end">{formatNumber(order?.dpCTN) || "--"}</td>
                                                <td className="text-end">{(order?.offerSkuName)}</td>
                                                <td className="text-end">{(order?.offerPc)}</td>
                                                <td className="text-end">{(order?.offerUnit)}</td>
                                                <td className="text-end">{formatNumber(order?.totalPrice) || "--"}</td>
                                                <td className="text-end">{formatNumber(order?.totalPrice) || "--"}</td>
                                            </tr>
                                        ))}

                                        <tr>
                                            <td></td>
                                            <td className="text-start"></td>
                                            <td className="text-start"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end"></td>
                                            <td className="text-end fw-bold">{formatNumber(detailsArray?.filter(item => item.orderQtyCtn)?.reduce((a, b) => a + b.totalPrice, 0))}</td>
                                            <td className="text-end fw-bold">{formatNumber(detailsArray?.filter(item => item.orderQtyCtn)?.reduce((a, b) => a + b.totalPrice, 0))}</td>

                                        </tr>
                                        {paymentData.length > 0 ? (
                                            <tr>
                                                <td></td>
                                                <td className="text-start"></td>
                                                <td className="text-start"></td>
                                                <td className="text-end"></td>
                                                <td className="text-end"></td>
                                                <td className="text-end"></td>
                                                <td className="text-end"></td>
                                                <td className="text-end"></td>
                                                <td className="text-end"></td>
                                                <td className="text-end"></td>
                                                <td className="text-end fw-bold">
                                                    DR BDT
                                                    {/* {formatNumber(paymentData[0]?.drBDT || 0)} */}
                                                    <input type="number" value={paymentData[0]?.drBDT} onChange={(e) => {
                                                        const existing = [...paymentData];
                                                        // existing[0].drBDT = existing[0].crBDT - e.target.value;
                                                        // existing[0].crBDT = existing[0].drBDT - e.target.value;
                                                        existing[0].drBDT = e.target.value;
                                                        existing[0].crBDT = (existing[0].cpCRBDT + existing[0].cpDRBDT) - e.target.value;
                                                        const order = orders.find(item => item.doNo === existing[0].doNo);
                                                        setPaymentData(existing);
                                                        // handleCheckboxChange2(order, existing[0]);
                                                    }} name='drBDT' className='form-control' />
                                                </td>
                                                <td className="text-end fw-bold">CR BDT
                                                    {/* {formatNumber(paymentData[0]?.crBDT || 0)} */}
                                                    <input type="number" value={paymentData[0]?.crBDT} name='crBDT' readOnly className='form-control' />

                                                </td> </tr>) : null
                                        }






                                    </tbody>
                                </Table>
                            </SimpleBarReact>
                        ) : null}
                </Card.Body>
                <Card.Footer className="bg-light py-2">
                    <Row className="flex-between-center">
                        <Col xs="auto"></Col>
                        <Col xs="auto">
                            <div className='d-flex justify-content-end'>


                                <Button
                                    onClick={() => handleSubmit2("approved")}
                                    variant="falcon-default"
                                    size="sm"
                                    disabled={myStatus?.value === "approved" || !distributorValue?.value}
                                >
                                    Approve
                                </Button>
                                <Button
                                    onClick={() => handleSubmit2("rejected")}
                                    variant="falcon-default"
                                    size="sm"
                                    className='ms-1'
                                    disabled={myStatus?.value === "approved" || !distributorValue?.value}
                                >
                                    Reject
                                </Button>
                            </div>
                        </Col>

                    </Row>
                </Card.Footer>
            </Card>

        </>
    )
}

TabContentTableTableRow.propTypes = {
    totalPrice: PropTypes.number.isRequired,
    totalOrder: PropTypes.number.isRequired,
    product: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        img: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        unit: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
    }).isRequired,
}

TabContentTable.propTypes = {
    products: PropTypes.arrayOf(TabContentTableTableRow.propTypes.product)
        .isRequired,
}

export default TabContentTable
