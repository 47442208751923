import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const ApprovalActivityForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [approvalActivityById, setApprovalActivityById] = useState({});
    const [deliveryApprovalList, setDeliveryApprovalList] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + `approval-activities/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setApprovalActivityById(response.data.data);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
    }, [])


    // Primary Order list
    useEffect(() => {
        setIsLoading(true)
        const url = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + "delivery-approvals";
        axios.get(url, { headers: authHeader() })
            .then(response => {

                if (response.data.success || response.data.statusCode === 200) {
                    const modifyPrimaryOrders = response?.data?.data?.map(item => {
                        return {
                            id: item.id,
                            label: item.title,
                            value: item.id

                        }
                    })

                    setDeliveryApprovalList(modifyPrimaryOrders);

                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response)
            }).finally(
                setIsLoading(false)
            )
    }, [])

    const handleFormSubmit = (values, actions) => {

        const handleSubmitValue = {
            delivery_approval_id: values.delivery_approval_id.value,
            action_comment: values.action_comment
        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + 'approval-activities';
            axios.post(url, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/approvalActivity")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_DELIVERY_APPROVAL_MANAGEMENT_BASE_URL + `approval-activities/${updateID}`;
            axios.put(updateUrl, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/approvalActivity")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({
        delivery_approval_id: Yup.object().required("Please select a value for the delivery approval field"),
        action_comment: Yup.string().required("Please Enter a value for the action comment field"),
    })


    return (
        <>
            <PageHeader
                title={updateID ? "Update Approval Activity" : "Add New Approval Activity"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{

                            delivery_approval_id: deliveryApprovalList.find(item => item.value === approvalActivityById.delivery_approval_id) || null,
                            action_comment: approvalActivityById.action_comment || ""


                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Delivery Approval</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={deliveryApprovalList}
                                                placeholder="Select Primary Order"
                                                classNamePrefix="react-select"
                                                name="delivery_approval_id"
                                                value={values.delivery_approval_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "delivery_approval_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.delivery_approval_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.delivery_approval_id}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Action Comment</Form.Label>
                                            <Form.Control
                                                name="action_comment"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Action Comment"
                                                required
                                                value={values.action_comment}
                                            />
                                            {touched.action_comment && errors.action_comment && (
                                                <div style={{ color: "red" }}>
                                                    {errors.action_comment}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <div className="mt-3">
                                        <IconButton
                                            variant="primary"
                                            className="me-2"
                                            type="submit"

                                        >
                                            {updateID ? "Save" : "Submit"}
                                        </IconButton>
                                        <Button
                                            onClick={() => navigate("/approvalActivity")}
                                            variant="danger" type="Cancel"
                                        >
                                            Cancel
                                        </Button>
                                    </div>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default ApprovalActivityForm;


