import handleApiResponseErrors from 'helpers/handleApiResponse'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { Formik } from 'formik'
import axios from 'axios'
import { authHeader } from 'utils'
import { useSelector } from 'react-redux'

const OrdersFilter = ({
    selectedZone,
    selectedRegion,
    selectedArea,
    selectedDbPoint,
    setSelectedZone,
    setSelectedRegion,
    setSelectedArea,
    setSelectedDbPoint,
    setSelectedDb,
    setSelectedDoNo,
    selectedDoNo,
    selectedDateTo,
    selectedDateFrom,
    setSelectedDateFrom,
    setSelectedDateTo,
    setSelectedStatus,
    selectedStatus,
    handleSearch,
}) => {
    const [allZone, setAllZone] = useState([])
    const [allRegion, setAllRegion] = useState([])
    const [allArea, setAllArea] = useState([])
    const [allDbPoint, setAllDbPoint] = useState([])
    const [distributors, setDistributors] = useState([])
    const [disableDates, setDisableDates] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const userInfo = useSelector((state) => state?.auth?.user?.data)

    const handleClear = () => {
        setSelectedZone('')
        setSelectedRegion('')
        setSelectedArea('')
        setSelectedDbPoint('')
        setSelectedDb('')
        setSelectedDoNo('')
        setSelectedDateFrom('')
        setSelectedDateTo('')
        setSelectedStatus('')
    }

    const statusLookup = [
        {
            id: 1,
            label: 'Canceled',
            value: 'Canceled',
        },
        { id: 2, label: 'Pending', value: 'Pending' },
        { id: 3, label: 'Forwarded by ASM', value: 'Forwarded by ASM' },
        { id: 4, label: 'Forwarded by RSM', value: 'Forwarded by RSM' },
        { id: 5, label: 'Forwarded by SIO', value: 'Forwarded by SIO' },
        {
            id: 6,
            label: 'Forwarded by Order Management',
            value: 'Forwarded by Order Management',
        },
        { id: 7, label: 'Forwarded by Finance', value: 'Forwarded by Finance' },
    ]

    return (
        <>
            <Card className="mb-3 p-2">
                <Formik initialValues={{}} validationSchema={{}} onSubmit={{}}>
                    {({
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        setFieldValue,
                        setFieldTouched,
                    }) => (
                        <Form>
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleFirstName"
                                >
                                    <Form.Label>DO NO</Form.Label>
                                    <Form.Control
                                        placeholder={
                                            isLoading ? 'loading...' : 'DO NO'
                                        }
                                        type="text"
                                        name="doNo"
                                        value={selectedDoNo}
                                        onChange={(e) =>
                                            setSelectedDoNo(e.target.value)
                                        }
                                        isLoading={isLoading}
                                    />
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleState"
                                >
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        name="doDateFrom"
                                        type="date"
                                        required
                                        value={selectedDateFrom}
                                        onChange={(e) =>
                                            setSelectedDateFrom(e.target.value)
                                        }
                                        isLoading={isLoading}
                                        disabled={selectedDoNo}
                                    />
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleState"
                                >
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control
                                        name="doDateTo"
                                        type="date"
                                        required
                                        value={selectedDateTo}
                                        onChange={(e) =>
                                            setSelectedDateTo(e.target.value)
                                        }
                                        isLoading={isLoading}
                                        disabled={selectedDoNo}
                                    />
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleFirstName"
                                >
                                    <Form.Label>
                                        Select Delivery Status
                                    </Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={statusLookup}
                                        placeholder={
                                            isLoading
                                                ? 'loading...'
                                                : 'Select Delivery Status'
                                        }
                                        classNamePrefix="react-select"
                                        name="deliveryList"
                                        value={
                                            selectedStatus
                                                ? statusLookup.find(
                                                      (option) =>
                                                          option.id ===
                                                          selectedStatus
                                                  )
                                                : null
                                        }
                                        onChange={(selectedOption) => {
                                            setSelectedStatus(selectedOption.id)
                                        }}
                                        isLoading={isLoading}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group
                                    as={Col}
                                    md="2"
                                    controlId="exampleState"
                                >
                                    <Button
                                        onClick={handleSearch}
                                        variant="primary"
                                        className="mt-3"
                                        style={{ width: '188px' }}
                                        disabled={
                                            selectedDoNo == '' &&
                                            selectedDateFrom == '' &&
                                            selectedStatus == '' &&
                                            selectedDateTo == ''
                                        }
                                    >
                                        Search
                                    </Button>
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    md="3"
                                    controlId="exampleState"
                                >
                                    <Button
                                        onClick={handleClear}
                                        variant="danger"
                                        className="mt-3"
                                        style={{ width: '188px' }}
                                    >
                                        Clear
                                    </Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Card>
        </>
    )
}

export default OrdersFilter
