
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authOperations } from 'state/ducs/auth';
import { EMAIL_REGEX } from 'utils/validationRegex';
import * as Yup from 'yup';

const LoginForm = ({
  hasLabel,
  layout,
  setlogin,
  loading,
  error,
  isAuthenticated,
  authRedirectPath,
  signIn,
  setAuthRedirectPath
}) => {

  const [userType, setUserType] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const handleLoginSubmit = (values, actions) => {
    signIn(userType, values.email, values.password);
    actions.resetForm();
  };

  const loginSchema = Yup.object().shape({
    // email: Yup.string().required("Email address is Required").test('UEmail address is Valid', 'Email address is IN-Valid', value => {
    //   return EMAIL_REGEX.test(value);
    // }),
    password: Yup.string().required("Password is Required")
  });

  let authRedirect = null;
  if (isAuthenticated) {
    authRedirect = <Navigate to={authRedirectPath} />;
  }



  return (
    <>
      {authRedirect}
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={loginSchema}
        onSubmit={handleLoginSubmit}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          values
        }) => (
          <Form onSubmit={handleSubmit}>
            <div style={{ color: error ? "red" : "black" }}>
              {loading ? "Signing In..." : error}
            </div>
            <Row className="justify-content-between align-items-center">
              <Col xs="auto">
                <Form.Check type="checkbox" id="DB" className="mb-0">
                  <Form.Check.Input
                    type="checkbox"
                    name="DB"
                    onClick={() => setUserType('distributor')}
                    checked={userType === 'distributor'}
                  />
                  <Form.Check.Label className="mb-0 text-700" >
                    DB
                  </Form.Check.Label>
                </Form.Check>
              </Col>

              <Col xs="auto">
                <Form.Check type="checkbox" id="user" className="mb-0">
                  <Form.Check.Input
                    type="checkbox"
                    name="user"
                    onClick={() => setUserType('user')}
                    checked={userType === 'user'}
                  />
                  <Form.Check.Label className="mb-0 text-700" >
                    Manager
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col xs="auto">
                <Form.Check type="checkbox" id="user" className="mb-0">
                  <Form.Check.Input
                    type="checkbox"
                    name="sr"
                    onClick={() => setUserType('salesRepresentative')}
                    checked={userType === 'salesRepresentative'}
                  />
                  <Form.Check.Label className="mb-0 text-700" >
                    SR
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              {<Form.Label>User ID</Form.Label>}
              <InputGroup>
                <span className="input-group-text ">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <Form.Control
                  placeholder={'Enter User ID'}
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  disabled={!userType}
                  required
                />
              </InputGroup>
              {touched.email &&
                errors.email && (
                  <div style={{ color: "red" }}>
                    {errors.email}
                  </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3">
              {hasLabel && <Form.Label>Password</Form.Label>}
              <InputGroup>
                <span className='input-group-text '>
                  <FontAwesomeIcon icon={faKey} />
                </span>
                <Form.Control
                  className='border border-end-0'
                  placeholder={'Enter Password'}
                  value={values.password}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={showPassword ? "text" : "password"}
                  disabled={!values.email}
                  required
                  style={{ outline: 'none !important' }}
                />

              </InputGroup>
              {touched.password &&
                errors.password && (
                  <div style={{ color: "red" }}>
                    {errors.password}
                  </div>
                )}
            </Form.Group>

            <Row className="justify-content-between align-items-center">
              <Col xs="auto">
                <Form.Check type="checkbox" id="rememberMe" className="mb-0">
                  <Form.Check.Input
                    type="checkbox"
                    name="remember"
                    onClick={() => setShowPassword(!showPassword)}

                  />
                  <Form.Check.Label className="mb-0 text-700" >
                    Show Password
                  </Form.Check.Label>
                </Form.Check>
              </Col>

              {/* <Col xs="auto">
                <Link
                  className="fs--1 mb-0"
                  to={`/authentication/${layout}/forgot-password`}
                >
                  Forgot Password?
                </Link>
              </Col> */}
            </Row>

            <Form.Group>
              <Button
                type="submit"
                color="primary"
                className="mt-3 w-100"
                disabled={!values.email || !values.password}
              >
                Log in
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
      <Row className="my-3" style={{ textAlign: 'center', display: "block" }}>
        <Col sm="auto">
          <p className="mb-0 text-500">
            © All rights reserved TK group of industries | Powered By {' '}
            <span className="d-none d-sm-inline-block"> </span>
            <br className="d-sm-none" />
            <a href="https://techknowgram.com" target='_blank' className='text-decoration-none '><strong style={{ color: '#483285' }}>TechKnowGram Limited</strong></a>
            {/* <img className='ms-3' src={TKGL_LOGO} width="100" /> */}
          </p>
        </Col>

      </Row>
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
  setlogin: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  authRedirectPath: PropTypes.string,
  signIn: PropTypes.func,
  setAuthRedirectPath: PropTypes.func
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

const mapDispatchToProps = {
  signIn: authOperations.auth,
  setAuthRedirectPath: authOperations.setAuthRedirectPath
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
