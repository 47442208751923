import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoadingIcon from "helpers/LoadingIcon";

const EmployeeExperienceForm = ({ employeeId, handleNext }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [employeeData, setEmployeeData] = useState({});
    const [employeeExperience, setEmployeeExperience] = useState({});
    const [employeeExperienceId, setEmployeeExperienceId] = useState(null);
    const { updateID } = useParams();

    //  Date Format
    function formatDateToYYYYMMDD(isoDateString) {
        const date = new Date(isoDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }

    // Employee Get By ID
    useEffect(() => {
        if (employeeId) {
            setIsLoading(true)
            const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employees/${employeeId}`;

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.status === 200) {
                        setEmployeeData(response?.data?.data)
                    }
                    setIsLoading(false)
                })
                .catch(error => {
                    toast.error(error.message);
                    setIsLoading(false);
                })
        }
    }, [])

    // load update employee field value
    useEffect(() => {
        if (updateID) {
            setIsLoading(true);
            axios.get(process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employee-experiences`, { headers: authHeader() })
                .then(response => {
                    const employeeId = response.data?.data?.find(item => item.employee_id == updateID)
                    setEmployeeExperienceId(employeeId?.id);
                    if (employeeId) {
                        const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employee-experiences/${employeeId?.id}`;
                        axios.get(url, { headers: authHeader() })
                            .then(response => {
                                setIsLoading(false);
                                if (response.status === 200) {
                                    setEmployeeExperience(response?.data?.data)
                                }
                            })
                            .catch(error => {
                                setIsLoading(false);
                                toast.error(error.message)

                            })
                    }
                })

        }
    }, [])

    const handleSubmit = (values) => {

        const EmployeeExperienceInfo = {
            employee_id: employeeId,
            employeer_name: `${employeeData.first_name} ${employeeData.middle_name} ${employeeData.last_name}`,
            from_date: values.from_date,
            to_date: values.to_date,
            major_works: values.major_works,
            status: true

        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + "employee-experiences";
            axios.post(url, EmployeeExperienceInfo, { headers: authHeader() })
                .then(response => {
                    if (response?.data?.data) {
                        handleNext;
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    toast.error(error.message)
                    setIsLoading(false);

                })


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employee-experiences/${employeeExperienceId}`;
            axios.put(updateUrl, EmployeeExperienceInfo, { headers: authHeader() })
                .then(response => {
                    if (response?.data?.data) {
                        handleNext();
                        setIsLoading(false);
                    }

                })
                .catch(error => {
                    setIsLoading(false);
                    toast.error(error.message)

                })

        }

    }


    isLoading && <LoadingIcon />


    const formSchema = Yup.object().shape({
        from_date: Yup.date().required("Please select a value for the from date field"),
        to_date: Yup.date().required("Please select a value for the to date field"),
        major_works: Yup.string().required("Please select a value for the major works field")

    });


    return (
        <>

            <Formik
                initialValues={{
                    employee_id: employeeExperience.employee_id || null,
                    employer_name: `${employeeData.first_name} ${employeeData.middle_name} ${employeeData.last_name}` || "",
                    from_date: formatDateToYYYYMMDD(employeeExperience.from_date) || "",
                    to_date: formatDateToYYYYMMDD(employeeExperience.to_date) || "",
                    major_works: employeeExperience.major_works || "",

                }}
                validationSchema={formSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {({
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    setFieldValue

                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="exampleState">
                                    <Form.Label>Employer Name</Form.Label>
                                    <Form.Control
                                        name="employer_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        placeholder="Enter Employer Name"
                                        required
                                        disabled
                                        value={values.employer_name}
                                    />
                                    {touched.employer_name && errors.employer_name && (
                                        <div style={{ color: "red" }}>
                                            {errors.employer_name}
                                        </div>
                                    )}
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="exampleState">
                                    <Form.Label>From Date</Form.Label>
                                    <Form.Control
                                        name="from_date"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="date"
                                        placeholder="Enter From Date"
                                        required
                                        value={values.from_date}
                                    />
                                    {touched.from_date && errors.from_date && (
                                        <div style={{ color: "red" }}>
                                            {errors.from_date}
                                        </div>
                                    )}
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="exampleState">
                                    <Form.Label>To Date</Form.Label>
                                    <Form.Control
                                        name="to_date"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="date"
                                        placeholder="Enter To Date"
                                        required
                                        value={values.to_date}
                                    />
                                    {touched.to_date && errors.to_date && (
                                        <div style={{ color: "red" }}>
                                            {errors.to_date}
                                        </div>
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="exampleState">
                                    <Form.Label>Major Works</Form.Label>
                                    <Form.Control
                                        name="major_works"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        as="textarea" rows={2}
                                        placeholder="Enter Major Works"
                                        required
                                        value={values.major_works}
                                    />
                                    {touched.major_works && errors.major_works && (
                                        <div style={{ color: "red" }}>
                                            {errors.major_works}
                                        </div>
                                    )}
                                </Form.Group>
                            </Row>

                            <IconButton
                                variant="primary"
                                className="me-2 px-5"
                                type="submit"
                            >
                                Next
                            </IconButton>
                            <IconButton
                                variant="primary"
                                className="px-5"
                                onClick={handleNext}
                            >
                                Skip
                            </IconButton>
                        </Form>
                    );
                }}
            </Formik>

        </>
    );
};

export default EmployeeExperienceForm;


