import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import PageHeader from 'components/common/PageHeader'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllDeliveries, getAllSchedules } from 'services/api'

const OthersDistributorsScheduledOrdersList = ({ user }) => {
    const navigate = useNavigate()
    const {
        data: scheduleData,
        isLoading: isLoadingScheduleByFactory,
        isError: scheduleByFactoryError,
        refetch: scheduleByFactoryRefetch,
    } = useQuery({
        queryKey: ['allSchedules'],
        queryFn: () => getAllSchedules(),
    })

    const {
        data: deliveryData,
        isLoading: isLoadingDeliveryByFactory,
        isError: deliveryByFactoryError,
        refetch: deliveryByFactoryRefetch,
    } = useQuery({
        queryKey: ['allDeliveries'],
        queryFn: () => getAllDeliveries(),
    })

    const IsShowable = (order, allDeliveries) => {
        // if (!allDeliveries.length) {
        //     return true
        // }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName
                    &&
                    item.scheduleNo == order.scheduleNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return qtySum == order.delQtyCtn
    }

    const getSum = (order, allDeliveries) => {
        if (!allDeliveries.length) {
            return 0;
        }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName
                    &&
                    item.scheduleNo == order.scheduleNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + obj.delQtyCtn
            }, 0)

        return qtySum
    }

    let undeliveredOrders = []

    if (
        scheduleData?.data.payload.result.length
        && deliveryData?.data.payload.result
    ) {
        const allSchedules = scheduleData.data.payload.result
        const allDeliveries = deliveryData.data.payload.result

        const filteredSchedules = allSchedules.filter(
            (item) => item.superDBId==user.data.userId && !IsShowable(item, allDeliveries)
        )

        undeliveredOrders = filteredSchedules.map((tot, i) => ({
            ...tot,
            index: i + 1,
            schQtyCtn: tot.delQtyCtn - getSum(tot, allDeliveries),
        }))
    }

    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'scheduleNo',
            Header: 'Schedule No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { scheduleNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{scheduleNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'doNo',
            Header: 'DO No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{doNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'skuName',
            Header: 'Product Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { skuName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{skuName}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'schQtyCtn',
            Header: 'Scheduled Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { schQtyCtn } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{schQtyCtn}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'factoryName',
            Header: 'Factory Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { factoryName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{factoryName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'distributorName',
            Header: 'Distributor Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { distributorName, dealerId } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{distributorName} ({dealerId})</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'scheduleDate',
            Header: 'Schedule Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { scheduleDate } = rowData.row.original
                const date = new Date(scheduleDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'status',
            Header: 'Status',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original
                // const date = new Date(status)
                // const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{status==9 ? "Approved":""}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { scheduleNo, dealerId } = rowData.row.original
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle
                            id="dropdown-autoclose-true"
                            className=" bg-none"
                        >
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="">
                                {' '}
                                {/* <Button
                                    variant="light"
                                    size="sm"
                                    onClick={() =>
                                        navigate(
                                            `/distribution/productdelivery/update`,
                                            {
                                                state: {
                                                    scheduleNav: rowData.row.original,
                                                },
                                            }
                                        )
                                    }
                                >
                                    Complete Delivery
                                </Button> */}
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                                {' '}
                                <Button
                                    variant="light"
                                    size="sm"
                                    onClick={() => navigate("/distribution/productscheduling/details/" + scheduleNo+"/"+ dealerId)}
                                >
                                    View Details
                                </Button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
        },
    ]

    /*******************
    Columns End.
    *******************/
    const formatData = () => {
        const dataFormat = undeliveredOrders.map((item, i) => {
            const statusLookup = {
                9: 'Approved',
            }
            const deliveryDateFormatted = new Date(item.scheduleDate).toISOString().split('T')[0];
            return {
                SL: i + 1,
                'Schedule No': item.scheduleNo,
                'do No': item.doNo,
                'Product Name': item.skuName,
                'Del Qty Ctn': item.delQtyCtn,
                'Factory Name': item.factoryName,
                'Distributor Name':item.distributorName,
                'Schedule Date':deliveryDateFormatted,
                'Status': statusLookup[item.status] ,
            }
        })
        return dataFormat
    }
    return (
        <>
            <PageHeader
                title="Approved Scheduled Orders"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={undeliveredOrders}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            newUrl=""
                            isSearch={true}
                            setSearchText={'setSearchText'}
                            table
                            excelFileName="Approved Scheduled Orders"
                            excelData={formatData()}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination
                            table
                            paginationPageCount={1}
                            setPaginationPageCount={() => ({})}
                            pageDataCount={1}
                            setPageDataCount={1}
                            totalPages={1}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}

// export default PendingDeliveries

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OthersDistributorsScheduledOrdersList)
