import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import {
    Card,
    Dropdown,

} from 'react-bootstrap';
import axios from 'axios';
import { authHeader } from 'utils';
import LoadingIcon from 'helpers/LoadingIcon';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import handleApiResponseErrors from 'helpers/handleApiResponse';
import Swal from 'sweetalert2';
import useUserRole from 'hooks/useUserRole';

const Discount = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [discountsData, setDiscountsData] = useState([]);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const userRole = useUserRole();



    useEffect(() => {
        setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts?limit=${pageDataCount}&page=${paginationPageCount}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const totalPages = response?.data?.payload?.pagination.totalPages


                    const result = response?.data?.payload.discounts.map((element, index) => ({
                        ...element,
                        index: startIndex + index
                    }));
                    setTotalPages(totalPages);
                    setDiscountsData(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount]);



    // DELETE
    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.data.statusCode === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };

    // Handle Toggle Status

    /*   const handleToggle = (id, newStatus) => {
    
        const updatedData = allDistributor?.map(item => {
          if (item.distributorId == id) {
            return { ...item, status: newStatus }
          }
          return item;
        })
    
        console.log("Update data", updatedData)
        setAllDistributor(updatedData);
    
        const url = process.env.REACT_APP_BASE_URL + `distributor/statusChange/${id}`
        axios.put(url, { status: newStatus }, { headers: authHeader() })
          .then(response => {
    
    
          })
          .catch(error => {
            toast.error(error.message);
          })
    
      } */



    if (isLoading) {
        return <LoadingIcon />
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{index}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'Zone Name',
            Header: 'Zone Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { zoneName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{zoneName ? zoneName : "-"}</h5>
                        </div>
                    </Flex>

                );
            }
        },

        {
            accessor: 'Region Name	',
            Header: 'Region Name	',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { regionName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{regionName ? regionName : "-"}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'Area Name',
            Header: 'Area Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { areaName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{areaName ? areaName : "-"}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'companyName',
            Header: 'Company Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { companyName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{companyName}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'dealerId',
            Header: 'Dealer Id',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { dealerId } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{dealerId}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'conditionName',
            Header: 'Condition Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { conditionName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{conditionName}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'productCategory',
            Header: 'Product Category',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { productCategory } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{productCategory}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'sku_name',
            Header: 'SKU Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { skuName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{skuName}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'buy_qty',
            Header: 'Buy Quantity',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { buyQty } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{buyQty}</h5>
                        </div>
                    </Flex>

                );
            }
        },

        {
            accessor: 'discountBDT',
            Header: 'Discount BDT',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { discountBDT } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{discountBDT}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'discountNote',
            Header: 'Discount Note',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { discountNote } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{discountNote}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'discountStartDate',
            Header: 'Start Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { discountStartDate } = rowData.row.original;
                const date = new Date(discountStartDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'discountEndDate',
            Header: 'End Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { discountEndDate } = rowData.row.original;
                const date = new Date(discountEndDate);
                const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{formattedDate}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { status } = rowData.row.original;
               
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{status == "true" ? "Yes":"No"}</h5>
                        </div>
                    </Flex>

                );
            }
        },
        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { _id } = rowData.row.original;
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                            ...
                        </Dropdown.Toggle>

                        {userRole === "Admin" && <Dropdown.Menu>
                            <Dropdown.Item >
                                <Link to={`/master/discount/add/${_id}`}>
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="edit"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                    </IconButton>
                                </Link>
                            </Dropdown.Item>
                            {/*  <Dropdown.Item href="">
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => handleViewDetails(id)}
                >
                  View
                </Button>
              </Dropdown.Item> */}
                            <Dropdown.Item href="">
                                <IconButton
                                    onClick={() => handleDelete(_id)}
                                    variant="falcon-default"
                                    size="sm"
                                    icon="trash-alt"
                                    iconAlign="middle"
                                    className="d-none d-sm-block me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                </IconButton>

                            </Dropdown.Item>
                        </Dropdown.Menu>}
                    </Dropdown>
                );
            }
        }
    ];


    /*******************
    Columns End.
    *******************/
    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={discountsData}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            title="Discounts"
                            newUrl="/master/discount/add"
                            isExport={false}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

        </>
    );
}

export default Discount;
