import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import PageHeader from 'components/common/PageHeader'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getInventoryStocks } from 'services/api'
import { formatNumber } from 'helpers/utils'

const ReceivedStocksDetails = ({ user }) => {
    const navigate = useNavigate()

    const location = useLocation()
    const { skuNav } = location.state
    console.log('skuNav', skuNav)

    const {
        data: stockData,
        isLoading: isLoadingStock,
        isError: stockError,
        refetch: stockRefetch,
    } = useQuery({
        queryKey: ['stocks'],
        queryFn: () => getInventoryStocks(skuNav, user.data.factoryName),
    })

    let allStocks = []

    if (stockData && stockData.data.payload.result.length) {
        const result = stockData.data.payload.result
        allStocks = result.map((my, i) => ({
            ...my,
            index: i + 1,
        }))
    }

    let totalStocks= 0;

    if (allStocks.length) {
        totalStocks= allStocks.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.rcvQtyCTN;
          }, 0)
    }


    console.log('allStocks', allStocks)

    /*******************
     Columns Start.
    *******************/

    const columns = [
       
        {
            accessor: 'productCategory',
            Header: 'Category',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { productCategory } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{productCategory}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'skuName',
            Header: 'Product Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { skuName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{skuName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyCTN',
            Header: 'Recv. Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyCTN } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(rcvQtyCTN)}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'rcvQtyPCS',
            Header: 'Recvd. Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvQtyPCS, rcvQtyCTN } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">
                                {formatNumber(rcvQtyPCS * rcvQtyCTN)}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'rcvDate',
            Header: 'Recvd. Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { rcvDate } = rowData.row.original
                const date = new Date(rcvDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { scheduleNo } = rowData.row.original
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle
                            id="dropdown-autoclose-true"
                            className=" bg-none"
                        >
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="">
                                {' '}
                                {/* <Button
                                    variant="light"
                                    size="sm"
                                    onClick={() =>
                                        navigate(
                                            `/distribution/productdelivery/update`,
                                            {
                                                state: {
                                                    scheduleNav:
                                                        rowData.row.original,
                                                },
                                            }
                                        )
                                    }
                                >
                                    Complete Delivery
                                </Button> */}
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                                {' '}
                                <Button
                                    variant="light"
                                    size="sm"
                                    // onClick={() => DeleteBankItem(committeeId)}
                                >
                                    View Details
                                </Button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
        },
    ]

    /*******************
    Columns End.
    *******************/

    return (
        <>
            <PageHeader title={"Product: "+skuNav+", Total Stock: "+totalStocks} className="mb-3"></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={allStocks}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            newUrl=""
                            isSearch={true}
                            setSearchText={'setSearchText'}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination
                            table
                            paginationPageCount={1}
                            setPaginationPageCount={() => ({})}
                            pageDataCount={1}
                            setPageDataCount={1}
                            totalPages={1}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}

// export default ReceivedStocksDetails

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ReceivedStocksDetails)
