import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link } from 'react-router-dom'
import OrdersFilter from 'components/common/ordersFilter/OrdersFilter'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
    faCheckCircle,
    faClock,
    faThumbsUp,
} from '@fortawesome/free-regular-svg-icons'
import useUserRole from 'hooks/useUserRole'
import StatusCell from 'components/common/StatusCell'
import FinanceFilter from 'components/common/ordersFilter/FinanceFilter'

const DistributorOrderList = () => {
    const [selectedZone, setSelectedZone] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('')
    const [selectedArea, setSelectedArea] = useState('')
    const [selectedDbPoint, setSelectedDbPoint] = useState('')
    const [selectedDb, setSelectedDb] = useState(null)
    const [selectedDoNo, setSelectedDoNo] = useState('')
    const [selectedDateFrom, setSelectedDateFrom] = useState('')
    const [selectedDateTo, setSelectedDateTo] = useState('')
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)
    const [pageDataCount, setPageDataCount] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [count, setCount] = useState(0)
    const [selectedStatus, setSelectedStatus] = useState('')
    const distributorInfo = useSelector((state) => state?.auth?.user?.data)
    const userRole = useUserRole()
    const [hoseSearch, setHoseSearch] = useState([])

    const formateExcelData = (data) => {
        const statusLookup = {
            1: 'Canceled',
            2: 'Pending',
            3: 'Forwarded by ASM',
            4: 'Forwarded by RSM',
            5: 'Forwarded by SIO',
            6: 'Forwarded by Order Management',
            7: 'Forwarded by Finance',
        }

        const formatDate = (date) => {
            return date ? new Date(date).toLocaleDateString('en-US') : ''
        }

        const formateData = data?.map((item, index) => ({
            Sl: index + 1,
            'Do No': item.doNo,
            'Zone Name': item.zone,
            'Region Name': item.region,
            'Area Name': item.area,
            'Db Point': item.dbPoint,
            'Order Qty Ctn': item.totalOrderQtyCtn,
            'Order Qty Pcs': item.totalOrderQtyPCS,
            'Discount BDT': item.totalDiscountBDT,
            'Total Price': item.totalPrice,
            'Delivery Qty CTN': item.deliveryQtyCTN,
            'Delivery Qty Pcs': item.deliveryQtyPcs,
            Status: statusLookup[item.status] || 'Incomplete',
            'Do Date': formatDate(item.doDate),
        }))

        return formateData
    }

    // data search
    const handleSearch = () => {
        if (
            selectedZone &&
            selectedRegion &&
            selectedArea &&
            selectedDbPoint &&
            !selectedDateFrom &&
            !selectedDateTo
        ) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (
            selectedZone &&
            selectedRegion &&
            selectedArea &&
            !selectedDateFrom &&
            !selectedDateTo
        ) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (
            selectedZone &&
            selectedRegion &&
            !selectedDateFrom &&
            !selectedDateTo
        ) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (selectedZone && !selectedDateFrom && !selectedDateTo) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (selectedDoNo) {
        }

        const formatData = {
            zone: selectedZone,
            region: selectedRegion,
            area: selectedArea,
            dbPoint: selectedDbPoint,
            dealerId: selectedDb?.id,
            doNo: selectedDoNo,
            doDateFrom: new Date(selectedDateFrom),
            doDateTo: new Date(selectedDateTo),
            dealerId: distributorInfo.userId,
            status: selectedStatus,
        }

        const fetchData = async () => {
            try {
                setIsLoading(true)
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/hosSearch`
                const response = await axios.post(url, formatData, {
                    headers: authHeader(),
                })

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.DBorder
                    setHoseSearch(data)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }

    // DELETE DISTRIBUTOR ORDER
    const handleDelete = async (doNo) => {
        try {
            const confirmResult = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4c8118',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete',
            })

            if (confirmResult.isConfirmed) {
                // urls for delete
                const balanceUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/deleteOrderBankByDo/${doNo}`
                const orderUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/delete/${doNo}`

                const balanceResponse = await axios.delete(balanceUrl, {
                    headers: authHeader(),
                })

                // if balance delete success then delete order
                if (!balanceResponse.data.success) {
                    Swal.fire({
                        title: 'Error!',
                        text: `There was an error deleting your order: ${balanceResponse.data.message}`,
                        icon: 'error',
                    })
                    return
                }

                const orderResponse = await axios.delete(orderUrl, {
                    headers: authHeader(),
                })

                if (!orderResponse.data.success) {
                    Swal.fire({
                        title: 'Error!',
                        text: `There was an error deleting your order: ${orderResponse.data.message}`,
                        icon: 'error',
                    })
                }

                // update the state
                setHoseSearch((prevState) =>
                    prevState.filter((element) => element.doNo !== doNo)
                )

                Swal.fire({
                    title: 'Deleted!',
                    text: 'Your order has been deleted.',
                    icon: 'success',
                })
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: `There was an error deleting your order: ${error.message}`,
                icon: 'error',
            })
        }
    }

    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { row } = rowData
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {row.index + 1}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'doNo',
            Header: 'DO No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{doNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'zoneName',
            Header: 'Zone Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { zone } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{zone}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'regionName',
            Header: 'Region Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { region } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{region}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'areaName',
            Header: 'Area Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { area } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">{area}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'dbPoint',
            Header: 'DB Point',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dbPoint } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {dbPoint}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'orderQtyCtn',
            Header: 'Order Qty Ctn',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { totalOrderQtyCtn } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {totalOrderQtyCtn}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'orderQtyPcs',
            Header: 'Order Qty Pcs',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { totalOrderQtyPCS } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {totalOrderQtyPCS}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'discountBDT',
            Header: 'Discount BDT',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { totalDiscountBDT } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {totalDiscountBDT}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'totalPrice',
            Header: 'Total Price',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { totalPrice } = rowData.row.original
                const formattedPrice = totalPrice.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formattedPrice}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'deliveryQtyCTN',
            Header: 'Delivery Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { deliveryQtyCTN } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {deliveryQtyCTN ? deliveryQtyCTN : '-'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'deliveryQtyPcs',
            Header: 'Delivery Qty Pcs',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { deliveryQtyPcs } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {deliveryQtyPcs ? deliveryQtyPcs : '-'}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'Status',
            Header: 'Status',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original
                return <StatusCell status={status} />
            },
        },

        {
            accessor: 'doDate',
            Header: 'Do Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doDate } = rowData.row.original
                const date = new Date(doDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 text-center">
                                {formattedDate}
                            </h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { doNo, status } = rowData.row.original
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle
                            id="dropdown-autoclose-true"
                            className=" bg-none"
                        >
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <Link
                                    to={`/master/distributor-orders-view/${doNo}`}
                                    state={{
                                        doNo: doNo,
                                    }}
                                >
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="edit"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">
                                            View Order
                                        </span>
                                    </IconButton>
                                </Link>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
        },
    ]

    /*******************
    Columns End.
    *******************/
    return (
        <>
            <PageHeader
                title="Distributor Order Search"
                className="mb-3"
            ></PageHeader>
            <OrdersFilter
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                selectedZone={selectedZone}
                selectedRegion={selectedRegion}
                selectedArea={selectedArea}
                selectedDbPoint={selectedDbPoint}
                selectedDb={selectedDb}
                selectedDoNo={selectedDoNo}
                setSelectedDoNo={setSelectedDoNo}
                setSelectedZone={setSelectedZone}
                setSelectedRegion={setSelectedRegion}
                setSelectedArea={setSelectedArea}
                setSelectedDbPoint={setSelectedDbPoint}
                setSelectedDb={setSelectedDb}
                selectedDateFrom={selectedDateFrom}
                selectedDateTo={selectedDateTo}
                setSelectedDateTo={setSelectedDateTo}
                setSelectedDateFrom={setSelectedDateFrom}
                hoseSearch={hoseSearch}
                setSelectedStatus={setSelectedStatus}
                selectedStatus={selectedStatus}
                setHoseSearch={setHoseSearch}
                handleSearch={handleSearch}
            />

            <AdvanceTableWrapper
                columns={columns}
                data={hoseSearch}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3 p-2">
                    <Card.Header>
                        {isLoading && (
                            <div className="text-center text-danger">
                                Data Loading....
                            </div>
                        )}
                        <CustomersTableHeader
                            // title='Distributor Order List'
                            isNew={false}
                            //isSearch={true}
                            setSearchText={setSearchText}
                            excelFileName="DistributorList"
                            excelData={formateExcelData(hoseSearch)}
                            table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    {/* <Card.Footer>
                        <AdvanceTablePagination
                            table
                            paginationPageCount={paginationPageCount}
                            setPaginationPageCount={setPaginationPageCount}
                            pageDataCount={pageDataCount}
                            setPageDataCount={setPageDataCount}
                            totalPages={totalPages}
                        />
                    </Card.Footer> */}
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}

export default DistributorOrderList
