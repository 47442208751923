import DueOrdersAmount from "../DueOrdersAmount";
import ConfirmPayment from "../JuniorAccounts/ConfirmPayment";

const { Row, Col } = require("react-bootstrap");


const SeniorAccountsDashboard = ({ setIsLoading }) => {
    return (
        <div>
            <Row className="g-3 mb-3">
                {/* <Col md={6} xxl={3}>
                    <ConfirmPayment setIsLoading={setIsLoading} />
                </Col> */}
                <Col md={6} xxl={3}>
                    <DueOrdersAmount setIsLoading={setIsLoading} />
                </Col>
            </Row>
        </div>
    );
};

export default SeniorAccountsDashboard;