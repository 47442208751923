import { Button, Card, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { authHeader } from "utils";
import PageHeader from "components/common/PageHeader";
import LoadingIcon from "helpers/LoadingIcon";
import Flex from "components/common/Flex";
import { set } from "date-fns";
import { formatNumber } from "helpers/utils";
import { useNavigate } from "react-router-dom";
import DepositForm from "./DepositModal";

const SpecialDistributorOrderCreateStep2 = ({ setStep, handleBack, distributorOrderNumber, checkedProducts, setCheckedProducts, setDistributorOrderNumber }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [totalDepositAmount, setTotalDepositAmount] = useState(0);
    const [checkAccessData, setCheckAccessData] = useState(null);
    const [depositDataByDoNo, setDepositDataByDoNo] = useState([]);
    const [depositList, setDepositList] = useState([]);
    const [creditList, setCreditList] = useState([]);
    const [totalCredit, setTotalCredit] = useState(0);
    const [remainingBalance, setRemainingBalance] = useState(0);
    const [paymentData, setPaymentData] = useState([]);
    const [totalDueAmount, setTotalDueAmount] = useState(0);
    const [totalPayAmount, setTotalPayAmount] = useState(0);
    const [totalPayableAmount, setTotalPayableAmount] = useState(0);
    const [comboOffer, setComboOffer] = useState([])
    const [offerAccepted, setofferAccepted] = useState('product');
    const [areProductQuantitiesValid, setAreProductQuantitiesValid] = useState(false);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);
    const [totals, setTotals] = useState({
        totalOrderQuantityCtn: 0,
        totalOrderQtyPcs: 0,
        totalPriceCtn: 0,
        totalDiscount: 0,
        totalPayable: 0,
        perCartoonPriceTotal: 0,
        allCartoonPriceTotal: 0,
        totalOfSubtotals: 0
    });
    const navigate = useNavigate();

    const isSubset = (subset, set, key) => {
        return subset.every(subItem => set.some(setItem => setItem[key] === subItem));
    }

    const isAvailable = (item, array) => {
        return array.some(arrayItem => arrayItem === item);
    }

    // Modal functions
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleModal = () => {
        setIsOpenModal(!isOpenModal);
    }

    // 
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SETTINGS_BASE_URL}Rcc`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.rccInfo;
                    const filteredData = data?.find(item => item?.reportId === "RCC101");
                    setCheckAccessData(filteredData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }

        };

        fetchData();
    }, []);


    // Function to update order quantities for a specific product
    const handleOrderQuantities = async (event, index, product) => {
        const { value } = event.target;
        const updatedProducts = [...checkedProducts];
        const currentDate = new Date().toISOString(); // Get current date in ISO format



        // Offer API call
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/allOffers`,
                { headers: authHeader() }
            );
            if (response.data.success || response.status === 200) {
                const offersData = response.data.payload.offers;

                const calculateProductsWeight = (product) => {
                    const calculate = ((parseFloat(value) * parseFloat(product.pcsCTN)) * (parseFloat(product?.weightPCS))) / 1000;
                    return calculate;
                };
                const totalWeight = calculateProductsWeight(product);

                if (offersData?.length > 0) {
                    const currentDateOffers = offersData.filter(offer => {
                        return offer.offerStartDate <= currentDate && offer.offerEndDate >= currentDate;
                    });


                    if (currentDateOffers?.length > 0) {

                        let myIndividualProductOffers = null;
                        const individualProductOffers = currentDateOffers.find(offer => offer.offerType === "Individual" && offer.skuName.length == 1 && offer.skuName[0] === product.skuName && offer.buyQty <= parseFloat(totalWeight));

                        console.log("individualProductOffers", individualProductOffers);

                        if (individualProductOffers?.dealerId.length && isAvailable(distributorInfo?.userId, individualProductOffers?.dealerId)) {
                            myIndividualProductOffers = individualProductOffers;
                        } else if (individualProductOffers?.zoneName.length && isAvailable(distributorInfo?.zoneName, individualProductOffers?.zoneName)
                            && !individualProductOffers?.regionName.length && !individualProductOffers?.areaName.length && !individualProductOffers?.dbPoint.length && !individualProductOffers?.dealerId.length
                        ) {
                            myIndividualProductOffers = individualProductOffers;

                        } else if (individualProductOffers?.regionName.length && isAvailable(distributorInfo?.regionName, individualProductOffers?.regionName)
                            && !individualProductOffers?.areaName.length && !individualProductOffers?.dbPoint.length && !individualProductOffers?.dealerId.length

                        ) {
                            myIndividualProductOffers = individualProductOffers;

                        } else if (individualProductOffers?.areaName.length && isAvailable(distributorInfo?.areaName, individualProductOffers?.areaName)
                            && !individualProductOffers?.dbPoint.length && !individualProductOffers?.dealerId.length

                        ) {
                            myIndividualProductOffers = individualProductOffers;

                        } else if (individualProductOffers?.dbPoint.length && isAvailable(distributorInfo?.dbPoint, individualProductOffers?.dbPoint)
                            && !individualProductOffers?.dealerId.length

                        ) {
                            myIndividualProductOffers = individualProductOffers;
                        } else {
                            myIndividualProductOffers = null;
                            updatedProducts[index] = { ...updatedProducts[index], productOffer: null };

                        }

                        if (myIndividualProductOffers) {
                            // // Assuming 'offers' contains your offer data
                            // const sortedOffers = individualProductOffers.sort((a, b) => b.buyQty - a.buyQty);

                            // // Find the first offer with a buyQty less than or equal to the specified value
                            // const filteredOffer = sortedOffers.find(offer => offer.buyQty <= value);


                            // // Store offer data in updatedProducts at the appropriate index
                            // updatedProducts[index] = { ...updatedProducts[index], productOffer: [filteredOffer] };

                            axios.post(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/OfferQtyValueSum`,
                                {
                                    skuName: product.skuName, offerStartDate: myIndividualProductOffers.offerStartDate, offerEndDate: myIndividualProductOffers.offerEndDate
                                },
                                { headers: authHeader() })
                                .then(response => {
                                    console.log('Response:', response.data.success);
                                    if (response.data.success) {
                                        const available = myIndividualProductOffers.totalBudgetQty - response.data.payload.offerSumData
                                        if (available > 0) {

                                            const offerQtyPcs = available >= myIndividualProductOffers.freeQty ? myIndividualProductOffers.freeQty : available

                                            const multiple = value / myIndividualProductOffers.buyQty

                                            const offerObj = {
                                                ...myIndividualProductOffers,
                                                offerQtyPcs: offerQtyPcs * multiple > available ? available : offerQtyPcs * multiple
                                            }

                                            updatedProducts[index] = { ...updatedProducts[index], productOffer: offerObj };

                                        }
                                    }
                                })
                                .catch(error => {
                                    console.error('Error:', error);
                                });
                        }
                        else {
                            /* 
                                                        const result = currentDateOffers.filter(offer =>
                                                            offer.offerType === "Global" && // Check if offerType is "Global"
                                                            offer.skuName === product.skuName && // Check if skuName matches product.skuName
                                                            offer.buyQty <= parseFloat(value) && // Check if buyQty is less than or equal to the specified value
                                                            (
                                                                offer.zoneName === distributorInfo?.zoneName || // Check if zoneName matches distributorInfo?.zoneName
                                                                offer.regionName === distributorInfo?.regionName || // Check if regionName matches distributorInfo?.regionName
                                                                offer.areaName === distributorInfo?.areaName // Check if areaName matches distributorInfo?.areaName
                                                            )
                                                        );
                            
                            
                                                        // Assuming 'offers' contains your offer data
                            
                                                        const sortedOffers = result.sort((a, b) => b.buyQty - a.buyQty);
                            
                                                        // Find the first offer with a buyQty less than or equal to the specified value
                                                        const filteredOffer = sortedOffers.find(offer => offer.buyQty <= value);
                                                        updatedProducts[index] = { ...updatedProducts[index], productOffer: [filteredOffer] }; */




                            const myResult = currentDateOffers.find(offer =>
                                offer.offerType === "National" && // Check if offerType is "National"
                                offer.skuName.length == 1 &&
                                offer.skuName[0] === product.skuName && // Check if skuName matches product.skuName
                                offer.buyQty <= parseFloat(totalWeight)// Check if buyQty is less than or equal to the specified value
                            );

                            if (myResult) {

                                axios.post(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/OfferQtyValueSum`,
                                    {
                                        skuName: product.skuName, offerStartDate: myResult.offerStartDate, offerEndDate: myResult.offerEndDate
                                    },
                                    { headers: authHeader() })
                                    .then(response => {
                                        console.log('Response:', response.data.success);
                                        if (response.data.success) {
                                            const available = myResult.totalBudgetQty - response.data.payload.offerSumData
                                            if (available > 0) {

                                                const offerQtyPcs = available >= myResult.freeQty ? myResult.freeQty : available

                                                const multiple = value / myResult.buyQty

                                                const offerObj = {
                                                    ...myResult,
                                                    offerQtyPcs: offerQtyPcs * multiple > available ? available : offerQtyPcs * multiple
                                                }

                                                updatedProducts[index] = { ...updatedProducts[index], productOffer: offerObj };

                                            }
                                        }
                                    })
                                    .catch(error => {
                                        console.error('Error:', error);
                                    });
                            }

                        }

                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////////////////////////This Section for Nation wide Combo Offer///////////////////////////////
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                        const getPerCtnPriceForUserRole = (userRole, product) => {
                            switch (userRole) {
                                case 'COMMISSION DISTRIBUTOR':
                                    return (
                                        parseFloat(product?.commPrice) *
                                        parseFloat(product?.pcsCTN)
                                    )
                                case 'WHOLESALE DISTRIBUTOR':
                                    return (
                                        parseFloat(product?.wholesellPrice) *
                                        parseFloat(product?.pcsCTN)
                                    )
                                case 'SPOT DISTRIBUTOR':
                                    return (
                                        parseFloat(product?.spotPrice) *
                                        parseFloat(product?.pcsCTN)
                                    )
                                default:
                                    return (
                                        parseFloat(product?.distributorPrice) *
                                        parseFloat(product?.pcsCTN)
                                    )
                            }
                        }


                        ///////////for national offer//////////


                        //copy the cart products in temp array
                        const myUpdatedProducts = updatedProducts;

                        // update the quantity of the product first
                        myUpdatedProducts[index].orderQuantity = value;

                        //update the price of the product
                        myUpdatedProducts[index].totalPrice = getPerCtnPriceForUserRole(distributorInfo?.userRole, updatedProducts[index]) * value;


                        //filter nationally, CTN offers if they contain the cart products
                        const nationalComboOffer = currentDateOffers.filter(offer => offer.offerType === "National"
                            && offer.skuName.length > 1 && isSubset(offer.skuName, myUpdatedProducts, "skuName")
                            // && offer.buyQty <= parseFloat(value)

                        );

                        let nationalComboOffersForState = [];


                        if (nationalComboOffer.length) {

                            let deletableOffers = [];
                            let quantityUpdateableOffers = [];


                            //check whether price offers
                            const priceOffers = nationalComboOffer.filter(item => item.purchaseBDT);

                            //check whether quantity offers
                            const qtyOffers = nationalComboOffer.filter(item => item.buyQty);



                            let PCOffers = [];
                            //for price based offers
                            if (priceOffers.length) {



                                const response = await axios.post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/MultiSKUOfferQtyValueSum`,
                                    {
                                        offers: priceOffers.map(item => (
                                            { offerNo: item._id, offerStartDate: item.offerStartDate, offerEndDate: item.offerEndDate }
                                        ))
                                    },
                                    { headers: authHeader() }
                                );


                                if (response.data.success || response.status === 200) {
                                    const budgets = response.data.payload;

                                    for (let index = 0; index < priceOffers.length; index++) {
                                        const element = priceOffers[index];

                                        // sum offer matched cart products total price
                                        const totalOrderPrice = myUpdatedProducts.filter(item =>
                                            element.skuName.includes(item.skuName)).reduce((accumulator, currentValue) => {
                                                return accumulator + parseInt(currentValue.totalPrice);
                                            }, 0);

                                        if (totalOrderPrice >= element.purchaseBDT) {


                                            //get the remaining budget of the desired offer

                                            const available = element.totalBudgetQty - (budgets[element._id] || 0) //- response.data.payload.offerSumData

                                            if (available > 0) {

                                                const offerQtyPcs = available >= element.freeQty ? element.freeQty : available

                                                const multiple = totalOrderPrice / element.purchaseBDT

                                                const offerObj = {
                                                    ...element,
                                                    offerQtyPcs: offerQtyPcs * multiple > available ? available : offerQtyPcs * multiple
                                                    // offerQtyPcs: offerQtyPcs * multiple
                                                }

                                                const existingPCOffers = [...comboOffer.filter(item => item.offerType == "National")]
                                                let updatablePCOffer = true;

                                                for (let index = 0; index < existingPCOffers.length; index++) {
                                                    const element1 = existingPCOffers[index];
                                                    const skuS = offerObj.skuName;

                                                    let counterPC = 0;
                                                    for (let k = 0; k < skuS.length; k++) {
                                                        const sku = skuS[k];
                                                        if (element1.skuName.includes(sku)) {
                                                            counterPC++;
                                                        } else {
                                                            break;
                                                        }
                                                    }

                                                    // updatablePCOffer = element1.skuName.length != counterPC;

                                                    if (element1.skuName.length == counterPC) {
                                                        updatablePCOffer = false;
                                                        break;
                                                    }

                                                }

                                                if (updatablePCOffer) {


                                                    console.log("updatablePCOffer", updatablePCOffer);
                                                    console.log("existingPCOffers", existingPCOffers);

                                                    // setComboOffer([...comboOffer, offerObj]);
                                                    PCOffers.push(offerObj)

                                                }

                                                else {
                                                    console.log("updatablePCOfferElse", updatablePCOffer);

                                                    // setComboOffer([...comboOffer]);
                                                    PCOffers = []
                                                    quantityUpdateableOffers.push(offerObj);

                                                }
                                            }
                                        } else {
                                            // setComboOffer([...comboOffer]);
                                            PCOffers = []
                                            deletableOffers.push(element)
                                        }


                                    }
                                }





                            } else {
                                // setComboOffer([...comboOffer]);
                            }

                            let QTYOffers = [];
                            //for quantity based offers
                            if (qtyOffers.length) {

                                const response = await axios.post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/MultiSKUOfferQtyValueSum`,
                                    {
                                        offers: qtyOffers.map(item => (
                                            { offerNo: item._id, offerStartDate: item.offerStartDate, offerEndDate: item.offerEndDate }
                                        ))
                                    },
                                    { headers: authHeader() }
                                );


                                if (response.data.success || response.status === 200) {
                                    const budgets = response.data.payload;

                                    for (let index = 0; index < qtyOffers.length; index++) {
                                        const element = qtyOffers[index];

                                        //sum offer matched cart products quantity
                                        const totalOrderQty = myUpdatedProducts.filter(item => element.skuName.includes(item.skuName)).reduce((accumulator, currentValue) => {
                                            return accumulator + parseInt(currentValue.orderQuantity);
                                        }, 0);

                                        if ((totalOrderQty >= element.buyQty)) {



                                            //calculate the remaining budget qty of the desired offer

                                            const available = element.totalBudgetQty - (budgets[element._id] || 0) //- response.data.payload.offerSumData

                                            if (available > 0) {

                                                const offerQtyPcs = available >= element.freeQty ? element.freeQty : available

                                                //
                                                const multiple = totalOrderQty / element.buyQty

                                                const offerObj = {
                                                    ...element,
                                                    offerQtyPcs: offerQtyPcs * multiple > available ? available : offerQtyPcs * multiple
                                                    // offerQtyPcs: offerQtyPcs * multiple
                                                }


                                                const existingOffers = [...comboOffer.filter(item => item.offerType == "National")]
                                                let updatable = true;

                                                for (let index = 0; index < existingOffers.length; index++) {
                                                    const element2 = existingOffers[index];
                                                    const skuS = offerObj.skuName;

                                                    let counter = 0;
                                                    for (let k = 0; k < skuS.length; k++) {
                                                        const sku = skuS[k];
                                                        if (element2.skuName.includes(sku)) {
                                                            counter++;
                                                        } else {
                                                            break;
                                                        }
                                                    }

                                                    // updatable = element2.skuName.length != counter;

                                                    if (element2.skuName.length == counter) {
                                                        updatable = false;
                                                        break;
                                                    }

                                                }

                                                if (updatable) {
                                                    console.log("updatable", updatable);
                                                    console.log("existingOffers", existingOffers);
                                                    // setComboOffer([...comboOffer, offerObj]);
                                                    QTYOffers.push(offerObj)



                                                } else {
                                                    console.log("updatableElse", comboOffer);

                                                    // setComboOffer([...comboOffer]);
                                                    QTYOffers = []
                                                    quantityUpdateableOffers.push(offerObj);


                                                }






                                            }


                                        } else {
                                            // setComboOffer([...comboOffer]);
                                            QTYOffers = []
                                            deletableOffers.push(element)
                                        }



                                    }
                                }





                            } else {
                                // setComboOffer([...comboOffer]);
                            }



                            console.log("comboOffer", comboOffer);


                            console.log("PCOffers", PCOffers);

                            // setComboOffer([...comboOffer, ...PCOffers])

                            console.log("QTYOffers", QTYOffers);

                            console.log("quantityUpdateableOffers", quantityUpdateableOffers);

                            console.log("deletableOffers", deletableOffers);

                            //filter out the state offers if the quantity decreased to not applicable for offer
                            if (deletableOffers.length) {

                                const existingOffersonState = [...comboOffer.filter(item => item.offerType == "National")];
                                console.log("existingOffers", existingOffersonState);

                                let stayableOffers = [];

                                for (let index = 0; index < existingOffersonState.length; index++) {  // Loop through existingOffersonState
                                    const stateItem = existingOffersonState[index];

                                    let notExistingItem = true;

                                    for (let ind = 0; ind < deletableOffers.length; ind++) {  // Loop through deletableOffers
                                        const myItem = deletableOffers[ind];
                                        const skuS = myItem.skuName;

                                        let counter = 0;
                                        for (let k = 0; k < skuS.length; k++) {  // Matching all the SKU names of the item
                                            const sku = skuS[k];
                                            if (stateItem.skuName.includes(sku)) {
                                                counter++;
                                            } else {
                                                break;
                                            }
                                        }

                                        if (stateItem.skuName.length == counter) { // If exists in the state
                                            notExistingItem = false;
                                            break;
                                        }
                                    }

                                    if (notExistingItem) {
                                        stayableOffers.push(stateItem);
                                    }
                                }

                                console.log("stayableOffers", stayableOffers);

                                // setComboOffer([...stayableOffers, ...QTYOffers, ...PCOffers]);
                                nationalComboOffersForState = [...stayableOffers, ...QTYOffers, ...PCOffers];
                            }

                            else {
                                // setComboOffer([...comboOffer, ...QTYOffers, ...PCOffers])
                                nationalComboOffersForState = [...comboOffer.filter(item => item.offerType == "National"), ...QTYOffers, ...PCOffers];

                                //for quantity update of the offer
                                if (quantityUpdateableOffers.length) {
                                    for (let index = 0; index < nationalComboOffersForState.length; index++) {  // Loop through nationalComboOffersForState
                                        const stateItem = nationalComboOffersForState[index];



                                        for (let ind = 0; ind < quantityUpdateableOffers.length; ind++) {  // Loop through quantityUpdateableOffers
                                            const myItem = quantityUpdateableOffers[ind];
                                            const skuS = myItem.skuName;

                                            let counter = 0;
                                            for (let k = 0; k < skuS.length; k++) {  // Matching all the SKU names of the item
                                                const sku = skuS[k];
                                                if (stateItem.skuName.includes(sku)) {
                                                    counter++;
                                                } else {
                                                    break;
                                                }
                                            }

                                            if (stateItem.skuName.length == counter) { // If exists in the state
                                                //update the quantity
                                                stateItem.offerQtyPcs = myItem.offerQtyPcs
                                            }
                                        }


                                    }
                                }

                            }




                        } else {
                            // setComboOffer([...comboOffer]);
                        }







                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////////////////////////This Section for Nation wide Combo Offer: END///////////////////////////////
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////


                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////This section for Individual Combo Offer: START/////////////////////////////////////////
                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                        let myIndividualComboOffers = [];



                        const individualComboOffers = currentDateOffers.filter(offer => offer.offerType === "Individual"
                            && offer.skuName.length > 1 && isSubset(offer.skuName, myUpdatedProducts, "skuName")
                            // && offer.buyQty <= parseFloat(value)
                        );

                        if (individualComboOffers.length) {
                            for (let of = 0; of < individualComboOffers.length; of++) {
                                const individualComboObj = individualComboOffers[of];

                                if (individualComboObj?.dealerId.length && isAvailable(distributorInfo?.userId, individualComboObj?.dealerId)) {
                                    myIndividualComboOffers.push(individualComboObj);
                                } else if (individualComboObj?.zoneName.length && isAvailable(distributorInfo?.zoneName, individualComboObj?.zoneName)
                                    && !individualComboObj?.regionName.length && !individualComboObj?.areaName.length && !individualComboObj?.dbPoint.length && !individualComboObj?.dealerId.length
                                ) {
                                    myIndividualComboOffers.push(individualComboObj);

                                } else if (individualComboObj?.regionName.length && isAvailable(distributorInfo?.regionName, individualComboObj?.regionName)
                                    && !individualComboObj?.areaName.length && !individualComboObj?.dbPoint.length && !individualComboObj?.dealerId.length

                                ) {
                                    myIndividualComboOffers.push(individualComboObj);

                                } else if (individualComboObj?.areaName.length && isAvailable(distributorInfo?.areaName, individualComboObj?.areaName)
                                    && !individualComboObj?.dbPoint.length && !individualComboObj?.dealerId.length

                                ) {
                                    myIndividualComboOffers.push(individualComboObj);

                                } else if (individualComboObj?.dbPoint.length && isAvailable(distributorInfo?.dbPoint, individualComboObj?.dbPoint)
                                    && !individualComboObj?.dealerId

                                ) {
                                    myIndividualComboOffers.push(individualComboObj);
                                } else {
                                    myIndividualComboOffers = [];
                                }

                            }
                        }




                        let myIndividualComboOffersForState = [];

                        if (myIndividualComboOffers.length) {

                            let deletableOffers = [];

                            let quantityUpdateableOffers = [];


                            //check whether price offers
                            const priceOffers = myIndividualComboOffers.filter(item => item.purchaseBDT);

                            //check whether quantity offers
                            const qtyOffers = myIndividualComboOffers.filter(item => item.buyQty);


                            let PCOffers = [];
                            //for price based offers
                            if (priceOffers.length) {


                                const response = await axios.post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/MultiSKUOfferQtyValueSum`,
                                    {
                                        offers: priceOffers.map(item => (
                                            { offerNo: item._id, offerStartDate: item.offerStartDate, offerEndDate: item.offerEndDate }
                                        ))
                                    },
                                    { headers: authHeader() }
                                );


                                if (response.data.success || response.status === 200) {
                                    const budgets = response.data.payload;

                                    for (let index = 0; index < priceOffers.length; index++) {
                                        const element = priceOffers[index];

                                        // sum offer matched cart products total price
                                        const totalOrderPrice = myUpdatedProducts.filter(item =>
                                            element.skuName.includes(item.skuName)).reduce((accumulator, currentValue) => {
                                                return accumulator + parseInt(currentValue.totalPrice);
                                            }, 0);

                                        if (totalOrderPrice >= element.purchaseBDT) {

                                            //get the remaining budget of the desired offer

                                            const available = element.totalBudgetQty - (budgets[element._id] || 0) //- response.data.payload.offerSumData

                                            if (available > 0) {

                                                const offerQtyPcs = available >= element.freeQty ? element.freeQty : available

                                                const multiple = totalOrderPrice / element.purchaseBDT

                                                const offerObj = {
                                                    ...element,
                                                    offerQtyPcs: offerQtyPcs * multiple > available ? available : offerQtyPcs * multiple
                                                    // offerQtyPcs: offerQtyPcs * multiple
                                                }

                                                const existingPCOffers = [...comboOffer.filter(item => item.offerType == "Individual")]
                                                let updatablePCOffer = true;

                                                for (let index = 0; index < existingPCOffers.length; index++) {
                                                    const element1 = existingPCOffers[index];
                                                    const skuS = offerObj.skuName;

                                                    let counterPC = 0;
                                                    for (let k = 0; k < skuS.length; k++) {
                                                        const sku = skuS[k];
                                                        if (element1.skuName.includes(sku)) {
                                                            counterPC++;
                                                        } else {
                                                            break;
                                                        }
                                                    }

                                                    // updatablePCOffer = element1.skuName.length != counterPC;

                                                    if (element1.skuName.length == counterPC) {
                                                        updatablePCOffer = false;
                                                        break;
                                                    }

                                                }

                                                if (updatablePCOffer) {


                                                    console.log("updatablePCOffer", updatablePCOffer);
                                                    console.log("existingPCOffers", existingPCOffers);

                                                    // setComboOffer([...comboOffer, offerObj]);
                                                    PCOffers.push(offerObj)

                                                }

                                                else {
                                                    console.log("updatablePCOfferElse", updatablePCOffer);

                                                    // setComboOffer([...comboOffer]);
                                                    PCOffers = [];
                                                    quantityUpdateableOffers.push(offerObj);


                                                }
                                            }
                                        } else {
                                            // setComboOffer([...comboOffer]);
                                            PCOffers = []
                                            deletableOffers.push(element)
                                        }


                                    }

                                }



                            } else {
                                // setComboOffer([...comboOffer]);
                            }

                            let QTYOffers = [];
                            //for quantity based offers
                            if (qtyOffers.length) {


                                const response = await axios.post(
                                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/MultiSKUOfferQtyValueSum`,
                                    {
                                        offers: qtyOffers.map(item => (
                                            { offerNo: item._id, offerStartDate: item.offerStartDate, offerEndDate: item.offerEndDate }
                                        ))
                                    },
                                    { headers: authHeader() }
                                );


                                if (response.data.success || response.status === 200) {
                                    const budgets = response.data.payload;

                                    for (let index = 0; index < qtyOffers.length; index++) {
                                        const element = qtyOffers[index];

                                        //sum offer matched cart products quantity
                                        const totalOrderQty = myUpdatedProducts.filter(item => element.skuName.includes(item.skuName)).reduce((accumulator, currentValue) => {
                                            return accumulator + parseInt(currentValue.orderQuantity);
                                        }, 0);

                                        if ((totalOrderQty >= element.buyQty)) {

                                            //calculate the remaining budget qty of the desired offer

                                            const available = element.totalBudgetQty - (budgets[element._id] || 0) //- response.data.payload.offerSumData

                                            if (available > 0) {

                                                const offerQtyPcs = available >= element.freeQty ? element.freeQty : available

                                                //
                                                const multiple = totalOrderQty / element.buyQty

                                                const offerObj = {
                                                    ...element,
                                                    offerQtyPcs: offerQtyPcs * multiple > available ? available : offerQtyPcs * multiple
                                                    // offerQtyPcs: offerQtyPcs * multiple
                                                }


                                                //check if the same offer already exists in the state, if yes then dont push to the state
                                                const existingOffers = [...comboOffer.filter(item => item.offerType == "Individual")]
                                                let updatable = true;

                                                //get all the existing offers from the state and loop through and check if all the skus matches with the desired offer to set
                                                for (let index = 0; index < existingOffers.length; index++) {
                                                    const element2 = existingOffers[index];
                                                    const skuS = offerObj.skuName;

                                                    let counter = 0;
                                                    for (let k = 0; k < skuS.length; k++) {
                                                        const sku = skuS[k];
                                                        if (element2.skuName.includes(sku)) {
                                                            counter++;
                                                        } else {
                                                            break;
                                                        }
                                                    }

                                                    // updatable = element2.skuName.length != counter;

                                                    if (element2.skuName.length == counter) {
                                                        updatable = false;
                                                        break;
                                                    }

                                                }

                                                //if not all the skus matches, that means it not exists in the state, then it can be pushed to the state

                                                if (updatable) {
                                                    console.log("updatable", updatable);
                                                    console.log("existingOffers", existingOffers);
                                                    // setComboOffer([...comboOffer, offerObj]);
                                                    QTYOffers.push(offerObj)



                                                    //otherwise that means it already on the state, no need to set on the state, 

                                                    // but here is the thing, QUANTITY need to be updated
                                                } else {
                                                    console.log("updatableElse", comboOffer);

                                                    // setComboOffer([...comboOffer]);
                                                    QTYOffers = []
                                                    quantityUpdateableOffers.push(offerObj);

                                                }






                                            }


                                        } else {
                                            // setComboOffer([...comboOffer]);
                                            QTYOffers = []
                                            deletableOffers.push(element)
                                        }



                                    }

                                }






                            } else {
                                // setComboOffer([...comboOffer]);
                            }


                            console.log("comboOffer", comboOffer);


                            console.log("PCOffers", PCOffers);

                            // setComboOffer([...comboOffer, ...PCOffers])

                            console.log("QTYOffers", QTYOffers);

                            console.log("deletableOffers", deletableOffers);

                            console.log("quantityUpdateableOffers", quantityUpdateableOffers);


                            if (deletableOffers.length) {

                                const existingOffersonState = [...comboOffer.filter(item => item.offerType == "Individual")];
                                console.log("existingOffers", existingOffersonState);

                                let stayableOffers = [];

                                for (let index = 0; index < existingOffersonState.length; index++) {  // Loop through existingOffersonState
                                    const stateItem = existingOffersonState[index];

                                    let notExistingItem = true;

                                    for (let ind = 0; ind < deletableOffers.length; ind++) {  // Loop through deletableOffers
                                        const myItem = deletableOffers[ind];
                                        const skuS = myItem.skuName;

                                        let counter = 0;
                                        for (let k = 0; k < skuS.length; k++) {  // Matching all the SKU names of the item
                                            const sku = skuS[k];
                                            if (stateItem.skuName.includes(sku)) {
                                                counter++;
                                            } else {
                                                break;
                                            }
                                        }

                                        if (stateItem.skuName.length == counter) { // If exists in the state
                                            notExistingItem = false;
                                            break;
                                        }
                                    }

                                    if (notExistingItem) {
                                        stayableOffers.push(stateItem);
                                    }
                                }

                                console.log("stayableOffers", stayableOffers);

                                // setComboOffer([...stayableOffers, ...QTYOffers, ...PCOffers]);
                                myIndividualComboOffersForState = [...stayableOffers, ...QTYOffers, ...PCOffers];
                            }

                            else {

                                // setComboOffer([...comboOffer, ...QTYOffers, ...PCOffers])
                                myIndividualComboOffersForState = [...comboOffer.filter(item => item.offerType == "Individual"), ...QTYOffers, ...PCOffers];

                                //for quantity update of the offer
                                if (quantityUpdateableOffers.length) {
                                    for (let index = 0; index < myIndividualComboOffersForState.length; index++) {  // Loop through myIndividualComboOffersForState
                                        const stateItem = myIndividualComboOffersForState[index];



                                        for (let ind = 0; ind < quantityUpdateableOffers.length; ind++) {  // Loop through quantityUpdateableOffers
                                            const myItem = quantityUpdateableOffers[ind];
                                            const skuS = myItem.skuName;

                                            let counter = 0;
                                            for (let k = 0; k < skuS.length; k++) {  // Matching all the SKU names of the item
                                                const sku = skuS[k];
                                                if (stateItem.skuName.includes(sku)) {
                                                    counter++;
                                                } else {
                                                    break;
                                                }
                                            }

                                            if (stateItem.skuName.length == counter) { // If exists in the state
                                                //update the quantity
                                                stateItem.offerQtyPcs = myItem.offerQtyPcs
                                            }
                                        }


                                    }
                                }

                            }




                        } else {
                            // setComboOffer([...comboOffer]);
                        }




                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////This section for Individual Combo Offer: END/////////////////////////////////////////
                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                        console.log("nationalComboOffersForState", nationalComboOffersForState);
                        console.log("myIndividualComboOffersForState", myIndividualComboOffersForState);

                        setComboOffer([...nationalComboOffersForState, ...myIndividualComboOffersForState]);

                    }

                }
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        }


        // Discount API call
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/allDiscounts`,
                { headers: authHeader() }
            );
            if (response.data.success || response.status === 200) {
                const discountsData = response?.data?.payload?.discounts;

                if (discountsData?.length > 0) {
                    const currentDateDiscounts = discountsData?.filter(discount => {
                        return discount?.discountUnit == "PCS" && discount?.discountStartDate <= currentDate && discount?.discountEndDate >= currentDate;
                    });

                    if (currentDateDiscounts?.length > 0) {
                        // const individualProductDiscount = currentDateDiscounts.find(discount => discount?.discountUnit == "PCS" && discount?.discountType === "Individual" && (discount?.zoneName === distributorInfo?.zoneName || discount?.regionName === distributorInfo?.regionName || discount?.areaName === distributorInfo?.areaName || discount?.dbPoint === distributorInfo?.dbPoint || discount?.dealerId === distributorInfo?.userId)
                        //     && discount?.skuName === product?.skuName && discount?.buyQty <= parseFloat(value));

                        let myindividualProductDiscount = null;
                        const individualProductDiscount = currentDateDiscounts.find(discount => discount.discountUnit == "PCS" && discount.discountType === "Individual" && discount.skuName === product.skuName && discount.buyQty <= parseFloat(value));
                        if (individualProductDiscount?.dealerId && individualProductDiscount?.dealerId === distributorInfo?.userId) {
                            myindividualProductDiscount = individualProductDiscount;
                        } else if (individualProductDiscount?.zoneName && individualProductDiscount?.zoneName === distributorInfo?.zoneName
                            && !individualProductDiscount?.regionName && !individualProductDiscount?.areaName && !individualProductDiscount?.dbPoint && !individualProductDiscount?.dealerId

                        ) {
                            myindividualProductDiscount = individualProductDiscount;

                        } else if (individualProductDiscount?.regionName && individualProductDiscount?.regionName === distributorInfo?.regionName
                            && !individualProductDiscount?.areaName && !individualProductDiscount?.dbPoint && !individualProductDiscount?.dealerId

                        ) {
                            myindividualProductDiscount = individualProductDiscount;

                        } else if (individualProductDiscount?.areaName && individualProductDiscount?.areaName === distributorInfo?.areaName
                            && !individualProductDiscount?.dbPoint && !individualProductDiscount?.dealerId

                        ) {
                            myindividualProductDiscount = individualProductDiscount;

                        } else if (individualProductDiscount?.dbPoint && individualProductDiscount?.dbPoint === distributorInfo?.dbPoint
                            && !individualProductDiscount?.dealerId

                        ) {
                            myindividualProductDiscount = individualProductDiscount;
                        } else {
                            myindividualProductDiscount = null;
                            updatedProducts[index] = { ...updatedProducts[index], productDiscount: null };

                        }

                        if (myindividualProductDiscount) {
                            // // Assuming 'offers' contains your offer data
                            // const sortedDiscounts = individualProductDiscount.sort((a, b) => b.buyQty - a.buyQty);

                            // // Find the first offer with a buyQty less than or equal to the specified value
                            // const filteredDiscount = sortedDiscounts?.find(discount => discount?.buyQty <= value);
                            // // Store offer data in updatedProducts at the appropriate index

                            // updatedProducts[index] = { ...updatedProducts[index], productDiscount: [filteredDiscount] };

                            // setIsLoading(true);


                            const response = await axios.post(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/discountBDTSum`,
                                {
                                    skuName: product.skuName, discountStartDate: myindividualProductDiscount.discountStartDate, discountEndDate: myindividualProductDiscount.discountEndDate
                                },
                                { headers: authHeader() })

                            if (response.data.success) {
                                // setIsLoading(false);

                                const available = myindividualProductDiscount.totalBudgetDiscount - response.data.payload.discountSumData
                                if (available > 0) {

                                    const discountBDT = available >= myindividualProductDiscount.discountBDT ? myindividualProductDiscount.discountBDT : available

                                    const multiple = value / myindividualProductDiscount.buyQty

                                    const discountObj = {
                                        ...myindividualProductDiscount,
                                        discountBDT: discountBDT * parseInt(multiple) > available ? available : discountBDT * parseInt(multiple)
                                    }

                                    updatedProducts[index] = { ...updatedProducts[index], productDiscount: discountObj };



                                }
                            }
                        }
                        else {

                            const myResult = currentDateDiscounts?.find(discount =>
                                discount?.discountType === "National" && // Check if offerType is "National"
                                discount?.skuName === product?.skuName && // Check if skuName matches product.skuName
                                discount?.buyQty <= parseFloat(value)  // Check if buyQty is less than or equal to the specified value
                                // && (
                                //     discount?.zoneName === distributorInfo?.zoneName || // Check if zoneName matches distributorInfo?.zoneName
                                //     discount?.regionName === distributorInfo?.regionName || // Check if regionName matches distributorInfo?.regionName
                                //     discount?.areaName === distributorInfo?.areaName // Check if areaName matches distributorInfo?.areaName
                                // )
                            );


                            // // Assuming 'offers' contains your offer data
                            // const sortedDiscount = result?.sort((a, b) => b.buyQty - a.buyQty);

                            // // Find the first offer with a buyQty less than or equal to the specified value
                            // const filteredDiscount = sortedDiscount?.find(offer => offer?.buyQty <= value);
                            // updatedProducts[index] = { ...updatedProducts[index], productDiscount: [filteredDiscount] };

                            if (myResult) {

                                // setIsLoading(true);


                                const response = await axios.post(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}discounts/discountBDTSum`,
                                    {
                                        skuName: product.skuName, discountStartDate: myResult.discountStartDate, discountEndDate: myResult.discountEndDate
                                    },
                                    { headers: authHeader() })

                                if (response.data.success) {
                                    // setIsLoading(false);

                                    const available = myResult.totalBudgetDiscount - response.data.payload.discountSumData
                                    if (available > 0) {

                                        const discountBDT = available >= myResult.discountBDT ? myResult.discountBDT : available

                                        const multiple = value / myResult.buyQty

                                        const discountObj = {
                                            ...myResult,
                                            discountBDT: discountBDT * parseInt(multiple) > available ? available : discountBDT * parseInt(multiple)
                                        }

                                        updatedProducts[index] = { ...updatedProducts[index], productDiscount: discountObj };



                                    }
                                }
                            } else {
                                updatedProducts[index] = { ...updatedProducts[index], productDiscount: null };

                            }

                        }
                    }

                }
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        }

        setIsLoading(false)




        updatedProducts[index] = { ...updatedProducts[index], orderQuantity: value };

        const calculateTotalQuantityPcs = parseFloat(value) * parseFloat(product.pcsCTN);

        const totalQuantityPcsWithOutOffer = (value * product.pcsCTN);

        updatedProducts[index] = { ...updatedProducts[index], totalQuantityPcsWithOutOffer: totalQuantityPcsWithOutOffer };
        updatedProducts[index] = { ...updatedProducts[index], totalOrderQtyPCS: value };


        // Calculate total price
        const getPriceForUserRole = (userRole, product) => {
            switch (userRole) {
                case "SPECIAL DISTRIBUTOR":
                    return parseFloat(product?.specialPCPrice);
                default:
                    return "";
            }
        };

        // const totalQuantity = parseFloat(updatedProducts[index]?.totalQuantityPcsWithOutOffer);
        const totalQuantity = parseFloat(updatedProducts[index]?.orderQuantity);
        const price = getPriceForUserRole(distributorInfo.userRole, updatedProducts[index]);
        const totalPrice = totalQuantity * price;
        updatedProducts[index] = { ...updatedProducts[index], totalPrice };
        let totalPriceWithOutDiscount = totalPrice;
        if (updatedProducts[index]?.productDiscount) {
            const discount = parseFloat(updatedProducts[index]?.productDiscount?.discountBDT) || 0;
            totalPriceWithOutDiscount -= discount;
        }
        updatedProducts[index] = { ...updatedProducts[index], totalPriceWithOutDiscount };



        // const getPerCtnPriceForUserRole = (userRole, product) => {
        const getPerPiecePriceForUserRole = (userRole, product) => {
            switch (userRole) {
                case "SPECIAL DISTRIBUTOR":
                    return parseFloat(product?.specialPCPrice);
                default:
                    return "";
            }
        };

        const specialPCPrice = getPerPiecePriceForUserRole(distributorInfo?.userRole, updatedProducts[index]);
        updatedProducts[index] = { ...updatedProducts[index], specialPCPrice };

        setCheckedProducts(updatedProducts);

    }
    // handle remove product
    const handleRemoveProduct = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You can't return back after this action",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete"
        });

        if (confirmResult.isConfirmed) {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const result = checkedProducts.filter(product => product._id !== id);
                    setCheckedProducts(result);

                    Swal.fire({
                        title: "Product has been deleted.!",
                        text: "successfully deleted!",
                        icon: "success"
                    });

                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    }




    // Check if all product quantities are properly provided
    useEffect(() => {
        const allQuantitiesValid = checkedProducts.every(product => parseFloat(product.orderQuantity) > 0);

        // Update the state variable based on the validity of product quantities
        setAreProductQuantitiesValid(allQuantitiesValid);
    }, [checkedProducts]);



    /* PAYMENT START */

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositByDoNo/${distributorOrderNumber}`;
                const response = await axios.get(url, { headers: authHeader() })
                if (response.data.success || response.status === 200) {
                    const data = response.data.payload.depositDoData;
                    const totalDepositAmount = data.reduce((acc, item) => acc + item.drBDT, 0);
                    setTotalDepositAmount(totalDepositAmount);
                    setDepositDataByDoNo(data);
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }, [isOpenModal])

    // deposit list
    useEffect(() => {
        // setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositsByDealer/${distributorInfo.userId}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.result;
                    const result = data?.filter(data => data.status === 2);
                    setDepositList(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Credit list
    useEffect(() => {
        // setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/getAllOrdersWithBankByDB/${distributorInfo.userId}`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const responseData = response?.data?.payload?.result;
                    const data = responseData?.filter(data => data.status === 2);
                    let totalCredit = 0;
                    data?.data?.payload?.result?.forEach((item) => {
                        if (item?.status === 2 && item.crBDT) {
                            totalCredit += item.crBDT;

                        }
                    }
                    )
                    setTotalCredit(parseFloat(totalCredit));
                    setCreditList(data);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Calculate total deposit
    const calculateTotalDeposit = (deposit, credit) => {
        const sumData1 = deposit?.reduce((total, item) => total + item.drBDT, 0);
        const sumData2 = credit?.reduce((total, item) => total + item.drBDT, 0);
        return sumData1 - sumData2;
    }

    // handle total payable price
    const handleTotalPayablePrice = (product) => {
        if (product?.productDiscount && product?.productDiscount) {
            const discountBDT = product?.productDiscount?.discountBDT ?? 0;
            return product?.totalPrice - discountBDT;
        } else {
            return parseFloat(product?.totalPrice);
        }
    };

    // handle total offer price
    const handleTotalOfferPrice = (product) => {
        ;
        if (product?.productOffer) {
            const calculatePrice = Math.round(product?.productOffer?.offerQtyPcs) * Number(product?.productOffer?.offerSkuPrice);
            // const calculateComboOfferPrice= comboOffer.reduce((accumulator, currentObj) => {
            //     return accumulator + (parseInt(currentObj.offerQtyPcs)* currentObj.offerSkuPrice);
            // }, 0);
            // return calculatePrice+calculateComboOfferPrice;
            return calculatePrice;
        } else {
            // return 0;
            // const comboPriceTotal= comboOffer.reduce((accumulator, currentObj) => {
            //     return accumulator + (parseInt(currentObj.offerQtyPcs)* currentObj.offerSkuPrice);
            // }, 0);
            // return comboPriceTotal;
        }
    }

    const handleTotalComboOfferPrice = () => {

        const comboPriceTotal = comboOffer.reduce((accumulator, currentObj) => {
            return accumulator + (parseInt(currentObj.offerQtyPcs) * currentObj.offerSkuPrice);
        }, 0);
        return comboPriceTotal;
    }

    // Calculate total payable amount
    useEffect(() => {
        /* const {
            totalOrderQuantityCtn,
            totalOrderQtyPcs,
            totalPriceCtn,
            totalDiscount,
            totalPayable,
            perCartoonPriceTotal,
            allCartoonPriceTotal,
            totalOfSubtotals
        } = checkedProducts?.reduce((accumulator, product) => {
            accumulator.totalOrderQuantityCtn += parseFloat(product?.orderQuantity);
            accumulator.totalOrderQtyPcs += product?.totalOrderQtyPCS;
            accumulator.totalPriceCtn += product?.totalPrice;
            accumulator.perCartoonPriceTotal += product?.perCartoonPrice;
            accumulator.allCartoonPriceTotal += product?.perCartoonPrice * parseFloat(product?.orderQuantity);
            accumulator.totalDiscount += parseFloat(product?.productDiscount?.[0]?.discountBDT ?? 0);
            accumulator.totalPayable += handleTotalPayablePrice(product);
            return accumulator;
        }, {
            totalOrderQuantityCtn: 0,
            totalOrderQtyPcs: 0,
            totalPriceCtn: 0,
            totalDiscount: 0,
            totalPayable: 0,
            perCartoonPriceTotal: 0,
            allCartoonPriceTotal: 0,
            totalOfSubtotals: 0
        });
    
        // Add total to checkedProducts
        checkedProducts.total = {
            totalOrderQuantityCtn,
            totalOrderQtyPcs,
            totalPriceCtn,
            perCartoonPriceTotal,
            allCartoonPriceTotal,
            totalOfSubtotals,
            totalDiscount,
            totalPayable,
        };
        setTotals({
            totalOrderQuantityCtn: totalOrderQuantityCtn,
            totalOrderQtyPcs: totalOrderQtyPcs,
            totalPriceCtn: totalPriceCtn,
            perCartoonPriceTotal: perCartoonPriceTotal,
            allCartoonPriceTotal: allCartoonPriceTotal,
            totalOfSubtotals,
            totalDiscount: totalDiscount,
            totalPayable: totalPayable,
        });
        setTotalPayableAmount(totalPayable); */


        let totalOrderQuantityCtn = 0;
        let totalPriceCtn = 0;
        let totalOrderQuantityPcs = 0;
        let totalOfferPrice = 0
        let totalDiscountPrice = 0;
        let totalPayablePrice = 0;
        checkedProducts?.forEach(product => {
            totalOrderQuantityCtn += parseFloat(product?.orderQuantity);
            // totalOrderQuantityPcs += parseFloat(product?.totalOrderQtyPCS);
            totalOrderQuantityPcs += parseFloat(product?.orderQuantity);
            totalPriceCtn += parseFloat(product?.totalPrice);
            totalOfferPrice += handleTotalOfferPrice(product) ?? 0
            totalDiscountPrice += parseFloat(product?.productDiscount?.discountBDT ?? 0);
            totalPayablePrice += handleTotalPayablePrice(product);
        })

        checkedProducts.total = {
            totalOrderQuantityCtn,
            totalOrderQuantityPcs,
            totalPriceCtn,
            totalOfferPrice: totalOfferPrice + handleTotalComboOfferPrice(),
            totalDiscountPrice,
            totalPayablePrice
        }
        setTotals({
            totalOrderQuantityCtn: totalOrderQuantityCtn,
            totalOrderQuantityPcs,
            totalPriceCtn: totalPriceCtn,
            totalOfferPrice: totalOfferPrice + handleTotalComboOfferPrice(),
            totalDiscountPrice: totalDiscountPrice,
            totalPayablePrice: totalPayablePrice
        });
        setTotalPayableAmount(totalPayablePrice);
    }, [checkedProducts]);

    console.log("checkedProducts", checkedProducts)
    // Calculate total payment and due amount
    useEffect(() => {
        const totalPayment = paymentData?.reduce((accumulator, data) => {
            // Check if BDTAmount exists and is a valid number
            if (data?.drBDT && !isNaN(parseFloat(data?.drBDT))) {
                accumulator += parseFloat(data?.drBDT);
            }
            return accumulator;
        }, 0);

        // Check if checkedProducts is properly initialized and totalPayable exists and is a valid number
        const totalPayable = checkedProducts?.total?.totalPayable ?? 0;
        const calculateTotalDue = totalPayment ? totalPayable - totalPayment : totalPayable;

        setTotalPayAmount(totalPayment);
        setTotalDueAmount(isNaN(calculateTotalDue) ? 0 : calculateTotalDue);
    }, [checkedProducts]);

    // Handle checkbox change offer accepted
    const handleCheckboxChange = (e) => {
        const { value } = e.target;
        setofferAccepted(value);
    };

    useEffect(() => {
        // Calculate total available balance
        const totalAvilableBalance = calculateTotalDeposit(depositList, creditList) + parseFloat(distributorInfo?.crBDTLimit);
        // total payable amount
        let totalPayableAmount = checkedProducts?.total?.totalPayablePrice || 0;
        if (offerAccepted === 'price') {
            const totalOfferPrice = checkedProducts?.total?.totalOfferPrice || 0;
            totalPayableAmount -= totalOfferPrice;
        }

        const remainingBalance = totalAvilableBalance - totalPayableAmount;

        setRemainingBalance(remainingBalance);

        return () => {
        };

    }, [depositList, creditList, totalPayableAmount, offerAccepted]);


    // Check if the page is loading
    // if (isLoading) return <LoadingIcon />

    // Handle form submission
    const handleSubmit = async () => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "Submit the order!",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit!"
        });

        if (confirmResult.isConfirmed) {
            const orderItems = checkedProducts.map((product) => {

                const orderItem = {
                    _id: product.productId,
                    productId: product.productId,
                    dpCTN: product.specialPCPrice, //product.perCtnPrice,
                    pcsCTN: product.pcsCTN,
                    offerAccepted: offerAccepted,
                    orderQtyCtn: parseFloat(product.orderQuantity),
                    totalOrderQtyPCS: parseFloat(product.orderQuantity),//product.totalOrderQtyPCS,
                    weightPerPC: product?.weightPCS || 0,
                    erpId: product.erpId,
                    // totalPrice: offerAccepted === 'price' ? product.totalPriceWithOutDiscount - handleTotalOfferPrice(product) : product.totalPriceWithOutDiscount,
                    totalPrice: product.totalPriceWithOutDiscount,
                    perPCPrice: product.specialPCPrice,
                    status: 2,

                };

                // Optional chaining used to safely access properties
                if (product.productOffer) {
                    orderItem.offerPc = product?.productOffer.offerQtyPcs;
                    orderItem.offerSkuName = product?.productOffer?.offerSkuName;
                    orderItem.offerSkuPrice = product?.productOffer?.offerSkuPrice;
                    orderItem.offerCategoryName = product?.productOffer?.offerProductCategory;
                    orderItem.offerUnit = product?.productOffer?.offerUnit,
                        orderItem.offerNote = product?.productOffer?.offerNote;
                    orderItem.offerStartDate = product?.productOffer?.offerStartDate;
                    orderItem.offerEndDate = product?.productOffer?.offerEndDate;
                }

                // Optional chaining used to safely access properties
                if (product?.productDiscount) {
                    orderItem.discountBDT = product?.productDiscount?.discountBDT;
                    orderItem.discountNote = product?.productDiscount?.discountNote;
                    orderItem.discountStartDate = product?.productDiscount?.discountStartDate;
                    orderItem.discountEndDate = product?.productDiscount?.discountEndDate;
                }

                return orderItem;
            });

            // Make a PUT request to update the order items
            try {
                setIsLoading(true);
                const response = await axios.put(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders`, orderItems, { headers: authHeader() });
                if (response.data.status || response.status === 200) {
                    handleSubmit2();
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }

        }


    };

    const handleOffersSubmit = async () => {
        // if (!comboOffer.length) {
        //     return;
        // }

        const offers = comboOffer.map(item => ({

            doNo: distributorOrderNumber,
            offerNo: item._id,
            doDate: new Date(),
            zone: distributorInfo?.zoneName,
            region: distributorInfo?.regionName,
            area: distributorInfo?.areaName,
            dbPoint: distributorInfo?.dbPoint,
            dealerId: distributorInfo?.userId,
            distributorName: distributorInfo?.name || distributorInfo?.distributorName,
            dbType: distributorInfo?.userRole,
            companyName: distributorInfo?.companyName,
            // factoryName: product.factoryName[0],
            // categoryName: item.productCategory,
            // skuName: item.skuName,

            factoryName: ['SORL', 'SVOIL', 'Narshingdi', 'Dhamrai'],
            categoryName: "",
            skuName: "",
            offerUnit: item?.offerUnit,
            againstCategoryName: item.productCategory,
            againstSkuName: item.skuName,
            offerName: item.conditionName,
            offerPc: parseInt(item.offerQtyPcs),
            offerSkuPrice: item.offerSkuPrice * parseInt(item.offerQtyPcs),
            offerSkuName: item.offerSkuName,
            offerCategoryName: item.offerProductCategory,
            offerNote: item.offerNote,
            offerStartDate: item.offerStartDate,
            offerEndDate: item.offerEndDate,
            offerAccepted: offerAccepted,
            unitName: "PCS",
            status: 2

        }));

        // Make a Pust request to insert the offer items for the order
        try {
            // setIsLoading(true)
            const response = await axios.post(
                `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/create`,
                offers,
                { headers: authHeader() }
            )
            if (response.data.status || response.status === 200) {
                setDistributorOrderNumber(0)
                setCheckedProducts([])
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Order Successfully Done!',
                    showConfirmButton: false,
                    timer: 1500,
                })
                navigate('/master/special-distributor-orders')
            }
        } catch (error) {
            handleApiResponseErrors(error.response)
        } finally {
            setIsLoading(false)
        }
    }


    // PAYMENT HANDLER
    const handleSubmit2 = async () => {
        let price = 0;
        if (offerAccepted === 'price') {
            price = totalPayableAmount - checkedProducts?.total?.totalOfferPrice;
        } else {
            price = totalPayableAmount;
        }
        // Calculate total deposit
        // let totalDeposit = calculateTotalDeposit(depositList, creditList)
        // let totalDrBDT = 0
        // let totalCrBDT = 0
        // Check if total deposit is greater than total payable amount
        // if (totalDeposit > price) {
        //     totalDrBDT = price
        //     totalCrBDT = 0
        // }
        // Check if total deposit is equal to total payable amount
        // else if (totalDeposit === 0) {
        //     totalDrBDT = 0
        //     totalCrBDT = price
        // }
        // Check if total deposit is less than total payable amount
        // else if (totalDeposit < price) {
        //     totalDrBDT = totalDeposit
        //     totalCrBDT = price - totalDeposit
        // }


        try {

            const response = await axios.post(`${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/create`, {
                zone: distributorInfo.zoneName,
                region: distributorInfo.regionName,
                area: distributorInfo.areaName,
                // paymentNote: values.note,
                orderNumber: distributorOrderNumber,
                distributorId: distributorInfo.userId,
                companyName: distributorInfo.companyName,
                dealerId: distributorInfo.userId,
                dealerName: distributorInfo.distributorName,
                dbType: distributorInfo.userRole,
                superDBId: distributorInfo?.superDBId,
                dbPoint: distributorInfo?.dbPoint,
                phone: distributorInfo.phoneNumber,
                doNo: distributorOrderNumber,
                recordDate: new Date(),
                // paymentMethod: "Cash",
                // drBDT: totalDrBDT,
                drBDT: price,
                crBDT: 0,
                status: 1,
                paymentDate: new Date(),
            }
                , { headers: authHeader() });
            if (response.data.success || response.status === 200) {

                // Swal.fire({
                //     position: "center",
                //     icon: "success",
                //     title: "Order Successfully Done!",
                //     showConfirmButton: false,
                //     timer: 1500
                // });

                // navigate("/master/special-distributor-orders")

                if (comboOffer.length) {
                    handleOffersSubmit();

                } else {
                    setDistributorOrderNumber(0)
                    setCheckedProducts([])
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Order Successfully Done!',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    navigate('/master/special-distributor-orders')
                }


            } else {
                throw new Error('Failed to update payment data');
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };


    // VIEW IMAGE
    const viewImage = async (imageId) => {
        try {
            const response = await axios.get(`https://khoz.net:3015/api/v1/image/${imageId}`, { headers: authHeader() });
            if (response.data.success && response.data.payload) {
                const data = response.data.payload;
                if (!data.imageData) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No image found!',
                    });
                    return;
                }

                const base64Image = data.imageData.image;
                const base64Prefix = 'data:image/jpeg;base64,';
                const base64String = base64Image.startsWith(base64Prefix) ? base64Image : `${base64Prefix}${base64Image}`;

                setImageSrc(base64String);  // Set the base64 image source to display in the modal
                handleShow(); // Show the modal
            } else {
                console.error('Failed to fetch image:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching the image:', error);
        }
    };


    const calculatePrice = offerAccepted === 'price' ? checkedProducts?.total
        ?.totalPayablePrice - checkedProducts?.total
            ?.totalOfferPrice : checkedProducts?.total
        ?.totalPayablePrice;

    return <>
        <PageHeader
            title={`Order No: ${distributorOrderNumber}`}
            description2={
                <div>
                    <p style={{ color: "#354151", fontWeight: "500", fontFamily: 'Poppins' }}>DB ID: {distributorInfo?.userId}, Distributor Name: {distributorInfo?.name || distributorInfo?.distributorName}, Mobile No: {distributorInfo?.phoneNumber}, Address: {distributorInfo?.address}</p>
                </div>
            }
            className="mb-3"
        />

        <div className="bg-white">
            <div className="table-responsive scrollbar">
                <table className="table table-bordered table-striped fs--1 mb-0">
                    <thead >
                        <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                            <th className="align-middle white-space-nowrap text-center " >Serial</th>
                            <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                            {/* <th className="align-middle white-space-nowrap text-center ">Per CTN Pcs</th> */}
                            {/* <th className="align-middle white-space-nowrap text-center ">Order Qty CTN</th> */}
                            <th className="align-middle white-space-nowrap text-center ">Order Qty PCS</th>
                            <th className="align-middle white-space-nowrap text-center ">Per PC Price</th>
                            {/* <th className="align-middle white-space-nowrap text-center "> Per CTN Price</th> */}
                            <th className="align-middle white-space-nowrap text-center ">Total Price PCS</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Discount</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Total Price</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Offer Product</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Offer Product Qty</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Offer UOM</th>
                            <th colSpan="1" className="align-middle white-space-nowrap text-center ">Action</th>
                        </tr>
                    </thead>
                    <tbody className="list" id="table-purchase-body">
                        {
                            checkedProducts?.map((product, index) => {
                                const perPcPrice = (distributorInfo.userRole == "SPECIAL DISTRIBUTOR") ?
                                    product.specialPCPrice : ""
                                return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                    <td className="align-middle text-center">{index + 1}</td>
                                    <td className="align-middle text-center">{product?.skuName}</td>
                                    {/* <td className="align-middle text-center">{product?.pcsCTN}</td> */}
                                    {/* <td className="align-middle text-center">
                                        <Form.Control type="text" placeholder="" onChange={(event) => handleOrderQuantities(event, index, product)} defaultValue={product?.orderQuantity} />
                                    </td>
                                    <td className="align-middle text-center">{product?.totalOrderQtyPCS}</td> */}
                                    <td className="align-middle text-center">
                                        <Form.Control type="text" placeholder=""
                                            // onChange={(event) => handleOrderQuantities(event, index, product)}
                                            onBlur={(event) =>
                                                handleOrderQuantities(
                                                    event,
                                                    index,
                                                    product
                                                )
                                            }

                                            defaultValue={product?.orderQuantity} />
                                    </td>
                                    <td className="align-middle text-end">{perPcPrice?.toFixed(2)}</td>
                                    {/* <td className="align-middle text-center">{perPcPrice * product?.pcsCTN}</td> */}
                                    <td className="align-middle text-end">{product?.totalPrice?.toFixed(2)}</td>
                                    {/* <td className="align-middle text-center">{product.totalQuantityPcsWithOutOffer ? calculateTotalPrice(product.totalQuantityPcsWithOutOffer, product.distributorPrice) : 0}</td> */}
                                    {/* {
                                        product?.productDiscount && product?.productDiscount?.length > 0 ? product?.productDiscount?.map((discount, index) => <td key={index} className="align-middle text-center">{discount?.discountBDT}</td>) : <td className="align-middle text-center"></td>
                                    } */}
                                    <td colSpan="1" className="align-middle white-space-nowrap text-end ">{product.productDiscount ? product.productDiscount.discountBDT : ""}</td>

                                    <td colSpan="1" className="align-middle white-space-nowrap text-end ">{product.totalPriceWithOutDiscount?.toFixed(2)}</td>
                                    <td colSpan="1" className="align-middle white-space-nowrap text-end ">{product.productOffer ? product.productOffer.offerSkuName : ""}</td>
                                    <td colSpan="1" className="align-middle white-space-nowrap text-end ">{product.productOffer ? formatNumber(product.productOffer.offerQtyPcs) : ""}</td>
                                    <th
                                        colSpan="1"
                                        className="align-middle white-space-nowrap text-center "
                                    >
                                        {product.productOffer
                                            ? product.productOffer
                                                .offerUnit

                                            : ''}
                                    </th>
                                    <td className="align-middle text-center">
                                        <Button variant="danger" onClick={() => handleRemoveProduct(product._id)}>delete</Button>
                                    </td>
                                </tr>

                            })
                        }




                        {comboOffer?.length ? (
                            <>
                                {comboOffer.map(itr => (
                                    <tr

                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#354151',
                                        }}
                                    >
                                        <td className="align-middle text-center">
                                            {checkedProducts?.length + 1}
                                        </td>
                                        <td className="align-middle text-center">

                                            {itr?.conditionName}

                                        </td>
                                        <td className="align-middle text-center">
                                            {/* {comboOffer?.freeQty} */}
                                            {"--"}
                                        </td>
                                        <td className="align-middle text-center">
                                            {/* {comboOffer?.freeQty} */}
                                            {"--"}

                                        </td>
                                        <td className="align-middle text-center">
                                            {/* {comboOffer?.freeQty} */}
                                            {"--"}
                                        </td>
                                        <td className="align-middle text-center">
                                            {/* {comboOffer?.offerSkuPrice} */}
                                            {"--"}
                                        </td>
                                        <td className="align-middle text-center">
                                            {/* {comboOffer?.offerSkuPrice} */}
                                            {"--"}
                                        </td>




                                        <th
                                            colSpan="1"
                                            className="align-middle white-space-nowrap text-center "
                                        >

                                            {itr?.offerSkuName}

                                        </th>
                                        <th
                                            colSpan="1"
                                            className="align-middle white-space-nowrap text-center "
                                        >

                                            {parseInt(itr?.offerQtyPcs)} ({formatNumber(itr?.offerSkuPrice * parseInt(itr?.offerQtyPcs))})
                                        </th>
                                        <th className="align-middle text-center">

                                            {(itr?.offerUnit)}
                                        </th>
                                    </tr>
                                ))}
                            </>

                        ) : null}

                        <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151", backgroundColor: "#98c1d9" }} >
                            <td className="align-middle white-space-nowrap text-center" colSpan="2" >Total</td>
                            {/* <td className="align-middle white-space-nowrap text-center" colSpan="1" ></td> */}
                            {/* <td className="align-middle white-space-nowrap text-center" colSpan="1">{checkedProducts?.total?.totalOrderQuantityCtn}</td> */}
                            <td className="align-middle white-space-nowrap text-start" colSpan="1"><span className="ms-3">{checkedProducts?.total?.totalOrderQuantityPcs}</span></td>
                            <td className="align-middle white-space-nowrap text-center" colSpan="1"></td>
                            <td className="align-middle white-space-nowrap text-end" colSpan="1">{checkedProducts?.total?.totalPriceCtn?.toFixed(2)}</td>
                            <td className="align-middle white-space-nowrap text-end" colSpan="1">{checkedProducts?.total?.totalDiscountPrice ? parseInt(checkedProducts?.total?.totalDiscountPrice) : ""}</td>
                            <td className="align-middle white-space-nowrap text-end" colSpan="1">{checkedProducts?.total?.totalPayablePrice?.toFixed(2)}</td>
                            <td></td>
                            <td></td>
                        </tr>

                        {offerAccepted === 'price' && <><tr
                            style={{
                                fontSize: 'medium',
                                fontWeight: '500',
                                fontFamily: 'Poppins',
                                color: '#354151',

                            }}
                        >
                            <td
                                className="align-middle white-space-nowrap text-center"
                                colSpan="1"
                            >

                            </td>
                            <td
                                className="align-middle white-space-nowrap text-center"
                                colSpan="1"
                            ></td>
                            <td
                                className="align-middle white-space-nowrap text-center"
                                colSpan="1"
                            >

                            </td>
                            <td
                                className="align-middle white-space-nowrap text-center"
                                colSpan="1"
                            >

                            </td>
                            {/* <td
                                className="align-middle white-space-nowrap text-center"
                                colSpan="1"
                            ></td>
                            <td
                                className="align-middle white-space-nowrap text-center"
                                colSpan="1"
                            ></td> */}
                            {/* <td
                                className="align-middle white-space-nowrap text-center"
                                colSpan="1"
                            >

                            </td> */}
                            <td
                                className="align-middle white-space-nowrap text-center"
                                colSpan="1"
                            >

                            </td>
                            <td
                                className="align-middle white-space-nowrap text-center"
                                colSpan="1"
                            >
                                Offer Price
                            </td>
                            <td className="align-middle white-space-nowrap text-center">{
                                formatNumber(checkedProducts?.total
                                    ?.totalOfferPrice)
                            }</td>
                            <td></td>
                            <td></td>
                        </tr>
                            <tr
                                style={{
                                    fontSize: 'medium',
                                    fontWeight: '500',
                                    fontFamily: 'Poppins',
                                    color: '#354151',

                                }}
                            >
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >

                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                ></td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >

                                </td>
                                {/* <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >

                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                ></td> */}
                                {/* <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                ></td> */}
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >

                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >

                                </td>
                                <td
                                    className="align-middle white-space-nowrap text-center"
                                    colSpan="1"
                                >
                                    Total Payable Price
                                </td>
                                <td className="align-middle white-space-nowrap text-center">{
                                    formatNumber(checkedProducts?.total
                                        ?.totalPayablePrice - checkedProducts?.total
                                            ?.totalOfferPrice)
                                }</td>
                                <td></td>
                            </tr></>}
                    </tbody>
                </table>
            </div>
        </div>

        <div>
            <Button
                className="mt-3 px-5 me-3"
                variant="primary"
                onClick={handleBack}
            >
                Add Products
            </Button>
            <Button
                className="mt-3 px-5 me-3"
                variant="primary"
                onClick={handleModal}
            >
                Add Deposit
            </Button>
        </div>

        {checkAccessData?.status === true && <Card className="mb-3 mb-lg-0 mt-3">
            <Card.Header as="h6" className="bg-light">
                <div className='d-flex align-items-center justify-content-start'>
                    <h5>Do you want to receive offer</h5>
                    <div className='ms-3'>
                        <input
                            type='checkbox'
                            id='product'
                            value='product'
                            checked={offerAccepted === 'product'}
                            onChange={handleCheckboxChange}
                        />
                        <label className='me-3 ms-2 fs-1' htmlFor="product">Product</label>

                        <input
                            type='checkbox'
                            id='price'
                            value='price'
                            checked={offerAccepted === 'price'}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="price" className='ms-2 fs-1'>Price</label>
                    </div>
                </div>
            </Card.Header>
        </Card>}

        <Card className="mb-3 mb-lg-0 mt-3">
            <Card.Header as="h6" className="bg-light">
                <Flex justifyContent="between" alignItems="center">
                    <h5>
                        Your available balance:{' '}
                        <span
                            style={{ color: 'black', fontWeight: 'bold' }}
                        >
                            {calculateTotalDeposit(depositList, creditList)
                                ? formatNumber(
                                    calculateTotalDeposit(
                                        depositList,
                                        creditList
                                    )
                                )
                                : 0}{' '}
                            BDT
                        </span>
                    </h5>

                    <h5>
                        Available credit limit:{' '}
                        <span
                            style={{ color: 'black', fontWeight: 'bold' }}
                        >
                            {parseFloat(distributorInfo.crBDTLimit) -
                                parseFloat(totalCredit) >
                                0
                                ? formatNumber(
                                    parseFloat(
                                        distributorInfo.crBDTLimit
                                    ) - parseFloat(totalCredit)
                                )
                                : 0}{' '}
                            BDT
                        </span>
                    </h5>

                    <h5>
                        Remaining balance:{' '}
                        <span
                            style={{ color: 'black', fontWeight: 'bold' }}
                        >
                            {formatNumber(remainingBalance)}
                            BDT
                        </span>
                    </h5>
                    {/* <Button className="mt-3" variant="primary" onClick={handleShow}>Add Payment</Button> */}
                </Flex>
            </Card.Header>
        </Card>

        {/* Deposit Information */}
        <Card className="bg-white mt-4">
            <Card.Body className="p-0">
                <div className="bg-white">
                    <div className="table-responsive scrollbar">
                        {depositDataByDoNo?.length > 0 ? (
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >No.</th>
                                        <th className="align-middle white-space-nowrap text-center " >Payment Method</th>
                                        <th className="align-middle white-space-nowrap text-start ">Bank Name</th>
                                        <th className="align-middle white-space-nowrap text-center ">Branch Name</th>
                                        <th className="align-middle white-space-nowrap text-center ">Account No</th>
                                        <th className="align-middle white-space-nowrap text-center ">Amount</th>
                                        <th className="align-middle white-space-nowrap text-center ">Tran. Id/Ref. No</th>
                                        <th className="align-middle white-space-nowrap text-center ">Payment Date</th>
                                        <th className="align-middle white-space-nowrap text-center ">Depositor Mobile No</th>
                                        <th className="align-middle white-space-nowrap text-center ">Status</th>
                                        <th className="align-middle white-space-nowrap text-center ">Note</th>

                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        depositDataByDoNo?.map((data, index) => {
                                            return (<>

                                                <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                    <td className="align-middle text-center">{index + 1}</td>
                                                    <td className="align-middle text-center" style={{ minWidth: "200px" }}>{data?.paymentMethod ? data?.paymentMethod : "-"}</td>

                                                    <td className="align-middle text-center" style={{ minWidth: "200px" }}>{data?.bankNames ? data?.bankNames : "-"}</td>
                                                    <td className="align-middle text-center">{data?.depositorsBranchName ? data?.depositorsBranchName : "-"}</td>
                                                    <td className="align-middle text-center">{data?.bankAccountNo}</td>
                                                    <td className="align-middle text-center">{formatNumber(data?.drBDT)}</td>
                                                    <td className="align-middle text-center">{data?.trId ? data?.trId : "-"}</td>
                                                    <td className="align-middle text-center">{
                                                        data?.paymentDate ? new Date(data?.paymentDate).toLocaleDateString() : "-"
                                                    }</td>
                                                    <td className="align-middle text-center">{data?.depositorPhone ? data?.depositorPhone : "-"}</td>
                                                    <td className="align-middle text-center">
                                                        {data?.status === 0 && (
                                                            <h6 style={{ backgroundColor: "orange", color: "white", display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
                                                                <i className="fas fa-hourglass-half" style={{ marginRight: "5px" }}></i>
                                                                Pending..
                                                            </h6>
                                                        )}
                                                        {data?.status === 1 && (
                                                            <h6 style={{ backgroundColor: "blue", color: "white", display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
                                                                <i className="fas fa-thumbs-up" style={{ marginRight: "5px" }}></i>
                                                                Approved By Account
                                                            </h6>
                                                        )}
                                                        {data?.status === 2 && (
                                                            <h6 style={{ backgroundColor: "green", color: "white", display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
                                                                <i className="fas fa-check-circle" style={{ marginRight: "5px" }}></i>
                                                                Approved
                                                            </h6>
                                                        )}
                                                        {data?.status === 3 && (
                                                            <h6 style={{ backgroundColor: "red", color: "white", display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderRadius: "5px" }}>
                                                                <i className="fas fa-times-circle" style={{ marginRight: "5px" }}></i>
                                                                Rejected
                                                            </h6>
                                                        )}

                                                    </td>

                                                    <td className="align-middle text-center" style={{ minWidth: "200px" }}>{data?.paymentNote}</td>
                                                    {data.trId && (
                                                        <Button
                                                            className="mt-3"

                                                            onClick={() =>
                                                                viewImage(
                                                                    data.trId
                                                                )
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    '#4C8118',
                                                                border:
                                                                    'none',
                                                                color: 'black',
                                                                padding:
                                                                    '10px 20px',
                                                                fontSize:
                                                                    '16px',
                                                                borderRadius:
                                                                    '5px',
                                                                transition:
                                                                    'background-color 0.3s ease, border-color 0.3s ease',
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                    )}

                                                </tr>
                                            </>)

                                        })
                                    }
                                    <tr>
                                        <td></td>
                                        <td colSpan={4} className="align-middle text-center" style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>Total</td>
                                        <td style={{ textAlign: "center", fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>{formatNumber(totalDepositAmount) || 0}</td>
                                        <td colSpan={6}></td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : ""}
                    </div>
                </div>
            </Card.Body>
        </Card>

        <Button className="mt-3  px-5 me-3" variant="primary" type="submit" disabled={!areProductQuantitiesValid || isLoading} onClick={() => { handleSubmit(); }}>
            Submit Order
        </Button>

        {isLoading ? <Button className="mt-3  px-5" variant="info">
            Loading...
        </Button> : ""}

        <DepositForm isOpenModal={isOpenModal} handleModal={handleModal} distributorOrderNumber={distributorOrderNumber} totalPrice={calculatePrice} />

        {/* Modal */}
        <Modal show={show} onHide={handleClose} size="lg" >
            <Modal.Header closeButton>
                <Modal.Title>Image Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {imageSrc ? (
                    <img src={imageSrc} alt="Image Preview" style={{ width: '100%' }} />
                ) : (
                    <p>Loading...</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default SpecialDistributorOrderCreateStep2;
