import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoadingIcon from "helpers/LoadingIcon";
import Select from "react-select";
import { authHeaderForm } from "state/ducs/auth/utils";

const NoticeForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [noticeInformation, setNoticeInformation] = useState({});
    const [uploadedFile, setUploadedFile] = useState(null);
    const { updateID } = useParams();
    const navigate = useNavigate();


    //  Date Format
    function formatDateToYYYYMMDD(isoDateString) {
        const date = new Date(isoDateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }


    // load update Notice field value
    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_BASE_URL + `notice/getNoticeById/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    setIsLoading(false)
                    setNoticeInformation(response.data);
                })
                .catch(error => {
                    toast.error(error.message);
                    setIsLoading(false);

                })
        }
    }, [])



    const noticeList = [
        {
            label: "Text",
            value: "Text"
        },
        {
            label: "JPG",
            value: "JPG"
        },
        {
            label: "PDF",
            value: "PDF"
        },

    ]

    const handleSubmitForm = (values, actions) => {

        setIsLoading(true);
        const formData = new FormData();
        if (uploadedFile) {
            const file = uploadedFile[0]
            formData.append('noticeType', values.noticeType.value);
            formData.append('noticeTitle', values.noticeTitle);
            formData.append('noticeDetails', values.noticeDetails);
            formData.append('effectiveDate', formatDateToYYYYMMDD(values.effectiveDate));
            formData.append('expireDate', formatDateToYYYYMMDD(values.expireDate));
            formData.append('uploadedFile', file);
        }
        else {
            formData.append('noticeType', values.noticeType.value);
            formData.append('noticeTitle', values.noticeTitle);
            formData.append('noticeDetails', values.noticeDetails);
            formData.append('effectiveDate', formatDateToYYYYMMDD(values.effectiveDate));
            formData.append('expireDate', formatDateToYYYYMMDD(values.expireDate));
        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_BASE_URL + 'notice/addNewNotice';
            axios.post(url, formData, { headers: authHeaderForm() })
                .then(response => {
                    setIsLoading(false);
                    if (response.status === 201) {
                        navigate("/master/officenotice");
                        toast.success("New Notice Added Success");

                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    toast.error(error.message);
                })

        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_BASE_URL + `notice/updateNotice/${updateID}`;
            axios.put(updateUrl, formData, { headers: authHeaderForm() })
                .then(response => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        navigate("/master/officenotice");
                        toast.success("Notice Update Success");
                    }

                })
                .catch(error => {
                    setIsLoading(false);
                    toast.error(error.message);
                })

        }

    }


    isLoading && <LoadingIcon />


    const productSchema = Yup.object().shape({
        noticeType: Yup.object().required("Notice Type is required"),
        noticeTitle: Yup.string().min(2, 'Too Short!')
            .max(100, 'Too Long!').required("Title Name is required").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            }),
        effectiveDate: Yup.string().required("Effective Date is required"),
        expireDate: Yup.string().required("ExpiryDate is required"),
        noticeDetails: Yup.string().min(5, 'Too Short!')
            .required("Description is required").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            }),


    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Office Notice" : "Add Office Notice"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            noticeType: noticeList.find(item => item.value === noticeInformation.noticeType) || null,
                            noticeTitle: noticeInformation.noticeTitle || '',
                            effectiveDate: noticeInformation.effectiveDate || '',
                            expireDate: noticeInformation.expireDate || '',
                            noticeDetails: noticeInformation.noticeDetails || '',
                            uploadedFile: null


                        }}
                        validationSchema={productSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Notice Type</Form.Label>
                                            <Select
                                                placeholder="Select Notice Type"
                                                closeMenuOnSelect={true}
                                                options={noticeList}
                                                classNamePrefix="react-select"
                                                name="noticeType"
                                                value={values.noticeType}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "noticeType",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {
                                                errors.noticeType && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.noticeType}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                name="noticeTitle"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Title"
                                                required
                                                value={values.noticeTitle}
                                            />
                                            {touched.noticeTitle && errors.noticeTitle && (
                                                <div style={{ color: "red" }}>
                                                    {errors.noticeTitle}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Effective date</Form.Label>
                                            <Form.Control
                                                name="effectiveDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter Effective Date"
                                                required
                                                value={values.effectiveDate}
                                            />
                                            {touched.effectiveDate && errors.effectiveDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.effectiveDate}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Expiry date</Form.Label>
                                            <Form.Control
                                                name="expireDate"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="date"
                                                placeholder="Enter Expiry date"
                                                required
                                                value={values.expireDate}
                                            />
                                            {touched.expireDate && errors.expireDate && (
                                                <div style={{ color: "red" }}>
                                                    {errors.expireDate}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                name="noticeDetails"
                                                as="textarea" rows={3}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Description"
                                                required
                                                value={values.noticeDetails}
                                            />
                                            {touched.noticeDetails && errors.noticeDetails && (
                                                <div style={{ color: "red" }}>
                                                    {errors.noticeDetails}
                                                </div>
                                            )}
                                        </Form.Group>
                                        {values.noticeType?.value !== "Text" && <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>File Attached</Form.Label>
                                            <Form.Control
                                                name="uploadedFile"
                                                onChange={(e) => setUploadedFile(e.target.files)}
                                                onBlur={handleBlur}
                                                type="file"
                                                placeholder="select File"
                                                required
                                                value={values.uploadedFile}
                                            />
                                            {touched.uploadedFile && errors.uploadedFile && (
                                                <div style={{ color: "red" }}>
                                                    {errors.uploadedFile}
                                                </div>
                                            )}
                                        </Form.Group>}
                                    </Row>



                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"

                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/officenotice")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default NoticeForm;


