import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import Swal from "sweetalert2";
import handleApiResponseErrors from "helpers/handleApiResponse";
import PageHeader from "components/common/PageHeader";

const Product = () => {
  const [productsData, setProductsData] = useState([]);
  const [paginationPageCount, setPaginationPageCount] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    // setIsLoading(true);
    const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`,
          { headers: authHeader() }
        );

        if (response.data.success || response.status === 200) {
          const totalPages = response?.data?.payload?.pagination?.totalPages;

          const result = response.data.payload.
            products.map((element, index) => ({
              ...element,
              index: startIndex + index
            }));

          setTotalPages(totalPages);
          setProductsData(result);
        }
      } catch (error) {
        handleApiResponseErrors(error.response);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [count, paginationPageCount, pageDataCount, searchText]);


  // DELETE
  const handleDelete = async (id) => {
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4c8118",
      cancelButtonColor: "#d33",
      confirmButtonText: "delete"
    });

    if (confirmResult.isConfirmed) {
      try {
        const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products/${id}`;
        const response = await axios.delete(url, { headers: authHeader() });

        if (response.data.success || response.data.statusCode === 200) {
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });

          setCount((prevCount) => prevCount + 1);
        }
      } catch (error) {
        handleApiResponseErrors(error.response);
      } finally {
        setIsLoading(false);
      }
    }
  };


  if (isLoading) {
    return <LoadingIcon />;
  }


  /*******************
   Columns Start.
  *******************/

  const columns = [

    {
      accessor: "index",
      Header: "Sl",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { index } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{index}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "brandName",
      Header: "Brand Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { companyName } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{companyName}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "categoryName",
      Header: "Category Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { categoryName } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{categoryName}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "subCategoryName",
      Header: "Sub Category Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { subCategory } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1 text-center">{subCategory ? subCategory : "-"}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "product_english_name",
      Header: "Product Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { skuName } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{skuName}</h5>
            </div>
          </Flex>

        );
      },
    },

    {
      accessor: "product_bengali_name",
      Header: "Product Name (Bengali)",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { skuBengaliName } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{skuBengaliName}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "Factory Name",
      Header: "factory Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { factoryName } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{factoryName}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "distribution_price",
      Header: "Distribution Price",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { distributorPrice } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{distributorPrice}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "commPrice",
      Header: "Commission Price",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { commPrice } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{commPrice}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "wholesellPrice",
      Header: "wholesale Price",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { wholesellPrice } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{wholesellPrice}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "spotPrice",
      Header: "Spot Price",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { spotPrice } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{spotPrice}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "trade_price",
      Header: "Trade Price",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { tradePrice } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{tradePrice}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "status_new",
      Header: "Is New",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { statusNew } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{statusNew === true ? "Yes" : "No"}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "status",
      Header: "Status",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{status === true ? "Active" : "InActive"}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "erp_id",
      Header: "erp ID",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { erpId } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{erpId}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "unit",
      Header: "Unit",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { unit } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{unit}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "weight_pcs",
      Header: "Weight pcs",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { weightPCS } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{weightPCS}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "pcs_ctn",
      Header: "Pcs ctn",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { pcsCTN } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{pcsCTN}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "product_open_date",
      Header: "Opening Date",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { productOpenDate } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{productOpenDate}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "closing_date",
      Header: "Closing Date",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { closingDate } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{closingDate}</h5>
            </div>
          </Flex>

        );
      },
    },
    // {
    //   accessor: "marketing_communication_link",
    //   Header: "Marketing Link",
    //   headerProps: { className: "pe-1" },
    //   cellProps: {
    //     className: "py-2",
    //   },
    //   Cell: (rowData) => {
    //     const { marketing_communication_link } = rowData.row.original;
    //     return (

    //       <Flex alignItems="center">
    //         <div className="flex-1">
    //           <h5 className="mb-0 fs--1">{marketing_communication_link}</h5>
    //         </div>
    //       </Flex>

    //     );
    //   },
    // },
    {
      accessor: "image_url",
      Header: "Image Url",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { imageUrl } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{imageUrl}</h5>
            </div>
          </Flex>

        );
      },
    },
    {
      accessor: "action",
      Header: "Action",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { _id } = rowData.row.original;
        return (
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
              ...
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="">
                <Link to={`/master/product/add/${_id}`}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="edit"
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-2"
                  >
                    <span className="d-none d-xl-inline-block ms-1">Edit</span>
                  </IconButton>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item href="">
                <IconButton
                  onClick={() => handleDelete(_id)}
                  variant="falcon-default"
                  size="sm"
                  icon="trash-alt"
                  iconAlign="middle"
                  className="d-none d-sm-block me-2"
                // disabled={true}
                >
                  <span className="d-none d-xl-inline-block ms-1">Delete</span>
                </IconButton>

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];


  /*******************
Columns End.
*******************/


  return (
    <>
      <PageHeader title="Products" className="mb-3" />
      <AdvanceTableWrapper
        columns={columns}
        data={productsData}
        sortable
        pagination
        perPage={50}
      >
        <Card className="mb-3">
          <Card.Header>
            <CustomersTableHeader isSearch={true}
              setSearchText={setSearchText} newUrl="/master/product/add" data={productsData} isExport={false} table />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />

          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default Product;
