import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import AppContext from 'context/Context';

const CardDropdown = ({
  data,
  btnRevealClass,
  drop,
  children,
  icon = 'ellipsis-h'
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  // Excel Download
  const fileType = "text/csv;charset=utf-8;";
  const fileExtension = ".csv";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleFormatDowanload = (data) => {
    const formattedData = data?.map((item, index) => {
      return {
        SL: index + 1,
        skuName: item.skuName,
        categoryName: item.categoryName,
        subCategory: item.subCategory,
        skuPrice: item.skuPrice,
        ctnPrice: item.ctnPrice,
        targetQuantityCtn: item.targetQuantityCtn || 0,
        AchievementQty: item?.orderQtyCtn || 0,
        AchievementBDT: (parseFloat(item?.orderQtyCtn) * parseFloat(item?.ctnPrice)) || 0
      }
    })
    exportToCSV(formattedData, "Target ");
  }

  return (
    <Dropdown
      className="font-sans-serif btn-reveal-trigger"
      align={isRTL ? 'start' : 'end'}
      drop={drop}
    >
      <Dropdown.Toggle
        variant="link"
        size="sm"
        data-boundary="viewport"
        className={classNames('text-600', {
          [btnRevealClass]: btnRevealClass,
          'btn-reveal': !btnRevealClass
        })}
      >
        <FontAwesomeIcon icon={icon} className="fs--2" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border py-0">
        {children}
        {!children && (
          <div className="py-2">
            {/* <Dropdown.Item>View</Dropdown.Item> */}
            <Dropdown.Item onClick={() => handleFormatDowanload(data)}>Export</Dropdown.Item>
            {/* <Dropdown.Divider />
            <Dropdown.Item className="text-danger">Remove</Dropdown.Item> */}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CardDropdown.propTypes = {
  btnRevealClass: PropTypes.string,
  drop: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string
};

export default CardDropdown;
