import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SoftBadge from 'components/common/SoftBadge'
import { formatNumber } from 'helpers/utils'
import React from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import image from 'assets/img/icons/spot-illustrations/corner-2.png'
import Background from 'components/common/Background'
import { Link } from 'react-router-dom'

const ProcessedDemandOrder = ({ data }) => {
    // Formtting the date   
    const date = new Date();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    const navigate = useNavigate();
    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="info" pill className="">
                    Today
                </SoftBadge>
                <h5 style={{ fontSize: '1rem' }}>Processed DO</h5>
                <div className=" display-4 fs-1 mb-2 fw-normal font-sans-serif">
                    No. {formatNumber(data?.todayProcessedDo) || 0}
                </div>
                {/* <Link
                    to="/accounts/due-payment-list"
                    className="fw-semi-bold fs--1 text-nowrap"
                >
                    See Details
                    <FontAwesomeIcon
                        icon="angle-right"
                        className="ms-1"
                        transform="down-1"
                    />
                </Link> */}
                <span className="fw-semi-bold fs--1 text-nowrap">{formattedDate}</span>
            </Card.Body>
        </Card>
    )
}

export default ProcessedDemandOrder
