import axios from 'axios';
import { authHeader } from '../../../utils';
import * as types from './types';
import { jwtDecode } from "jwt-decode";

export const authStart = () => {
  return {
    type: types.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: types.AUTH_SUCCESS,
    idToken: token
  };
};

export const setAuthUser = user => {
  return {
    type: types.AUTH_USER,
    user: user
  };
};

export const removeAuthUser = () => {
  return {
    type: types.AUTH_USER_REMOVE
  };
};

export const authFail = error => {
  return {
    type: types.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('expirationDate');
  return {
    type: types.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};


export const auth = (userType, email, password) => {

  return dispatch => {
    dispatch(authStart());

    // Determine the selectField based on userType
    let selectField;
    switch (userType) {
      case "distributor":
        selectField = "userId";
        break;
      case "salesRepresentative":
        selectField = "userId";
        break;
      default:
        selectField = "userId";
    }

    const authData = {
      [selectField]: email,
      password: password
    };

    // Construct the URL based on userType
    const baseUrl = process.env.REACT_APP_USER_MANAGEMENT_BASE_URL;
    // const url = userType === "distributor" ? `${baseUrl}auth/dbLogin` :  `${baseUrl}auth/login`;
    let url;

    if (userType === "distributor") {
      url = `${baseUrl}auth/dbLogin`;
    } else if (userType === "salesRepresentative") {
      url = `${baseUrl}auth/srLogin`;
    } else {
      url = `${baseUrl}auth/login`;
    }

    axios.post(url, authData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        const token = response.data.payload.token;
        const decoded = jwtDecode(token);

        decoded.userType = userType

        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(decoded));
        localStorage.setItem('userType', userType);

        dispatch(authSuccess(token));
        dispatch(setAuthUser(decoded));
      })
      .catch(err => {
        let errorMessage = 'Login failed. Try again with correct username & password';

        // Customize error message if specific error is known
        if (err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }

        dispatch(authFail(errorMessage));
      });
  };
};


export const setAuthRedirectPath = path => {
  return {
    type: types.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authStateCheck = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    if (!token) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token));
      dispatch(setAuthUser(JSON.parse(user)));
    }
  };
};

export const authUserCheck = () => {
  return dispatch => {
    const url = '/api/auth/me';
    axios
      .get(url, { headers: authHeader() })
      .then(response => {
        localStorage.setItem('user', JSON.stringify(response.data.data));
        dispatch(setAuthUser(response.data.data));
      })
      .catch(err => {
        dispatch(
          authFail('Login failed. Try again with correct username & password')
        );
      });
  };
};
