import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, CloseButton, Col, Dropdown, Modal, Row, Table } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import ToggleButton from "components/common/Toggle-button/index";
import { Link } from "react-router-dom";
import LoadingIcon from "helpers/LoadingIcon";
import Background from "components/common/Background";
import ProfileBanner from "../user/ProfileBanner";
import VerifiedBadge from "components/common/VerifiedBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Employee = () => {
  const [allEmployee, setAllEmployee] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [viewDetails, setViewDetails] = useState(false);
  const [fullscreen, setFullscreen] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);

  // Load All Employee Data
  useEffect(() => {
    setIsLoading(true);
    const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + "employees";
    axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let index = 1;
        const result = [];
        if (response?.data?.data) {
          response?.data?.data?.forEach((element) => {
            const addIndex = { ...element, index };
            result.push(addIndex);
            index++;
          });
          setAllEmployee(result);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
        console.log(error);
      });
  }, [count]);

  // Delete Employee..
  const DeleteEmployee = id => {
    if (confirm('Are You Sure ?')) {
      setIsLoading(true);
      axios
        .delete(
          process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employees/${id}`,
          { headers: authHeader() }
        )
        .then(response => {
          if (response.status === 200) {
            setIsLoading(false);
            toast.success('Employee Delete Success');
            setCount(count + 1);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  };

  // View Details
  const handleViewDetails = (id) => {
    setViewDetails(true);

    // Load Employee Data.
    const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employees/${id}`;
    axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        if (response.status === 200) {
          setEmployeeData(response.data?.data);
        }
      })
      .catch((error) => console.log(error));
  };

  // Handle Toggle Status

  const handleToggle = (id, newStatus) => {

    const updatedData = allEmployee?.map(item => {
      if (item.id === id) {
        return { ...item, status: newStatus }
      }
      return item;
    })

    setAllEmployee(updatedData);

    const url = process.env.REACT_APP_HR_MANAGEMENT_BASE_URL + `employees/${id}`
    axios.put(url, { status: newStatus }, { headers: authHeader() })
      .then(response => {


      })
      .catch(error => {
        toast.error(error.message);
      })

  }

  if (isLoading) {
    return <LoadingIcon />;
  }

  /* 
  ..........................
  Columns Data here
  .........................
  */

  const columns = [
    {
      accessor: "index",
      Header: "SN",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { index } = rowData.row.original;
        return (
          // <Link to="/e-commerce/customer-details">
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{index}</h5>
            </div>
          </Flex>
          // </Link>
        );
      },
    },

    /*
    {
      accessor: 'employeeId',
      Header: 'Employee ID',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { employeeId } = rowData.row.original;
        return (
          // <Link to="/e-commerce/customer-details">
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{employeeId}</h5>
            </div>
          </Flex>
          // </Link>
        );
      }
    },
    */
    {
      accessor: "designation_id",
      Header: "Designation",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { designation_id } = rowData.row.original;
        return (
          // <Link to="/e-commerce/customer-details">
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{designation_id}</h5>
            </div>
          </Flex>
          // </Link>
        );
      },
    },
    {
      accessor: "name",
      Header: "Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { first_name, middle_name, last_name } = rowData.row.original;
        return (
          // <Link to="/e-commerce/customer-details">
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{`${first_name} ${middle_name} ${last_name}`}</h5>
            </div>
          </Flex>
          // </Link>
        );
      },
    },

    {
      accessor: "mobileNumber",
      Header: "Mobile Number",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { mobile_number } = rowData.row.original;
        return (
          // <Link to="/e-commerce/customer-details">
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{mobile_number}</h5>
            </div>
          </Flex>
          // </Link>
        );
      },
    },
    {
      accessor: "email",
      Header: "Email",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return (
          // <Link to="/e-commerce/customer-details">
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{email}</h5>
            </div>
          </Flex>
          // </Link>
        );
      },
    },
    {
      accessor: 'status',
      Header: 'Status',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id, status } = rowData.row.original;

        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <ToggleButton
                id={id}
                status={status}
                handleToggle={handleToggle}
              ></ToggleButton>

            </div>
          </Flex>
        );
      }
    },
    {
      accessor: "action",
      Header: "Action",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
              ...
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="">
                <Link to={`/master/employee/add/${id}`}>
                  <Button variant="light" size="sm">
                    Edit
                  </Button>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item href="">

                <Button variant="light" size="sm" onClick={() => handleViewDetails(id)}>
                  View Details
                </Button>

              </Dropdown.Item>
              <Dropdown.Item href="">
                {" "}
                <Button variant="light" size="sm" onClick={() => DeleteEmployee(id)}>
                  Delete
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={allEmployee}
        // selection
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <CustomersTableHeader title="Employee" newUrl="/master/employee/add" data={allEmployee} isFilter={false} isExport={false} table />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            {
              employeeData && <Modal size="xl" show={viewDetails} onHide={() => setViewDetails(false)}>
                <Modal.Header>
                  {/* <Modal.Title className="text-center">Employee Details</Modal.Title> */}
                  <CloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => setViewDetails(false)} />
                </Modal.Header>
                <Modal.Body>
                  <ProfileBanner>
                    <ProfileBanner.Body>
                      <Row>
                        <Col lg={8}>
                          <h4 className="mb-1">
                            {`${employeeData?.first_name} ${employeeData?.middle_name} ${employeeData?.last_name}`}<VerifiedBadge />
                          </h4>
                          <h5 className="fs-0 fw-normal">
                            {employeeData?.designation?.name}
                          </h5>
                          <p className="text-500">{`Village: ${employeeData?.present_village} Post Office: ${employeeData?.present_post_office} Post Code: ${employeeData?.present_post_code}`}</p>
                          <div className="border-dashed border-bottom my-4 d-lg-none" />
                        </Col>
                        {<Col className="ps-2 ps-lg-3">

                          <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                              <h5 className="mb-2 fs-0 fw-normal">Number: {employeeData?.mobile_number}</h5>
                              <h5 className="mb-0 fs-0 fw-normal">Email: {employeeData?.email}</h5>
                            </div>

                          </Flex>


                          {/* <Link to="#!" key={item.title}>
                          <Flex alignItems="center" className="mb-2">
                            <img
                              src={item.image}
                              alt={item.title}
                              width={30}
                              className="me-2"
                            />
                            <div className="flex-1">
                              <h6 className="mb-0">{item.title}</h6>
                            </div>
                          </Flex>
                        </Link>
                    */}
                        </Col>}
                      </Row>
                    </ProfileBanner.Body>
                  </ProfileBanner>
                  <div>
                    {/* <div class="card mb-3">
                  <div class="card-body position-relative">
                    <div class="row">
                      <div class="col-lg-12">
                        <h4 className="text-center">{`${employeeData?.firstName} ${employeeData?.middleInitial} ${employeeData?.lastName}`}</h4>
                        <p class="mt-2 text-center">{employeeData?.employeeType}</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                    <div class="table-responsive scrollbar">
                      <table class="table table-bordered  fs--1 mb-0">
                        <thead class="bg-200">
                          <tr>
                            <th class=" " data-sort="name">Name</th>

                            <th class="" data-sort="age">Value</th>
                          </tr>
                        </thead>
                        <tbody class="list text-black">
                          <tr>
                            <td class="name">Nid Number</td>

                            <td>{employeeData?.nid_number}</td>
                          </tr>
                          <tr>
                            <td class="name">Back Account Number</td>

                            <td>{employeeData?.bank_account?.bank_account_number}</td>
                          </tr>
                          <tr>
                            <td class="name">Blood Group</td>

                            <td>{employeeData?.blood_group}</td>
                          </tr>
                          <tr>
                            <td class="name">Date Of Birth</td>

                            <td>{employeeData?.date_of_birth}</td>
                          </tr>
                          <tr>
                            <td class="name">Sales Organization</td>

                            <td>{employeeData?.sales_organization?.name}</td>
                          </tr>


                          <tr>
                            <td class="name">Date Of Joining</td>

                            <td>{employeeData?.date_of_joining}</td>
                          </tr>
                          <tr>
                            <td class="name">Date Of Resignation</td>

                            <td>{employeeData?.date_of_resignation}</td>
                          </tr>
                          <tr>
                            <td class="name">Basic Salary</td>

                            <td>{employeeData?.basic_salary}</td>
                          </tr>
                          <tr>
                            <td class="name">House Rent</td>

                            <td>{employeeData?.house_rent}</td>
                          </tr>
                          <tr>
                            <td class="name">Medical Allowance </td>

                            <td>{employeeData?.medical_allowance}</td>
                          </tr>
                          <tr>
                            <td class="name">Internet Bill </td>

                            <td>{employeeData?.internet_bill}</td>
                          </tr>
                          <tr>
                            <td class="name">Mobile Bill </td>

                            <td>{employeeData?.mobile_bill}</td>
                          </tr>
                          <tr>
                            <td class="name">Daily Allowance</td>

                            <td>{employeeData?.daily_allowance}</td>
                          </tr>
                          <tr>
                            <td class="name">Travelling Daily Allowance</td>

                            <td>{employeeData?.travelling_daily_allowance}</td>
                          </tr>
                          <tr>
                            <td class="name">Meeting Travelling Allowance</td>

                            <td>{employeeData?.meeting_travelling_allowance}</td>
                          </tr>
                          <tr>
                            <td class="name">Meeting Daily Allowance</td>

                            <td>{employeeData?.meeting_daily_allowance}</td>
                          </tr>
                          <tr>
                            <td class="name">City Allowance</td>

                            <td>{employeeData?.city_allowance}</td>
                          </tr>
                          <tr>
                            <td class="name">Other Allowance</td>

                            <td>{employeeData?.other_allowance}</td>
                          </tr>
                          <tr>
                            <td class="name">Contribution Percentage </td>

                            <td>{employeeData?.contribution_percentage}</td>
                          </tr>
                          <tr>
                            <td class="name">Present Address</td>

                            <td>{`Village: ${employeeData?.present_village}, Post Office: ${employeeData?.present_post_office}, Post Code: ${employeeData?.present_post_code}`}</td>
                          </tr>
                          <tr>
                            <td class="name">Permanent Address</td>

                            <td>{`Village: ${employeeData?.permanent_village}, Post Office: ${employeeData?.permanent_post_office}, Post Code: ${employeeData?.permanent_post_code}`}</td>
                          </tr>
                          <tr>
                            <td class="name">Emergency Contact Name </td>

                            <td>{employeeData?.emergency_contact_name}</td>
                          </tr>
                          <tr>
                            <td class="name">Emergency Mobile Number</td>

                            <td>{employeeData?.emergency_mobile_number}</td>
                          </tr>
                          <tr>
                            <td class="name">Emergency Contact Relation</td>

                            <td>{employeeData?.emergency_contact_relation}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>

                  </div>

                </Modal.Body>

              </Modal>
            }
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default Employee;
