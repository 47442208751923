import handleApiResponseErrors from "helpers/handleApiResponse";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { authHeader } from "utils";
import DistributorOrderUpdateStep1 from "./DistributorOrderUpdateStep1";
import DistributorOrderUpdateStep2 from "./DistributorOrderUpdateStep2";
import DistributorOrderUpdateStep3 from "./DistributorOrderUpdateStep3";

const DistributorOrderUpdate = () => {
    const { doNo } = useParams();
    const [step, setStep] = useState(2);
    const [checkedProducts, setCheckedProducts] = useState([]);
    const [distributorOrderNumber, setDistributorOrderNumber] = useState(doNo);

    const handleNext = () => {
        setStep(step + 1);
    }

    const handleBack = () => {
        setStep(step - 1);
    }


    return (
        <>
            {step === 1 && (
                <DistributorOrderUpdateStep1 handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
            )}
            {step === 2 && (
                <DistributorOrderUpdateStep2 step={step} handleNext={handleNext} distributorOrderNumber={distributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} />
            )}
            {step === 3 && (
                <DistributorOrderUpdateStep3 setStep={setStep} distributorOrderNumber={distributorOrderNumber} handleBack={handleBack} checkedProducts={checkedProducts} setCheckedProducts={setCheckedProducts} setDistributorOrderNumber={setDistributorOrderNumber} />
            )}
        </>
    )
}

export default DistributorOrderUpdate;