import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import IconButton from 'components/common/IconButton';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { authHeader } from 'utils';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Select from 'react-select';
import LoadingIcon from 'helpers/LoadingIcon';
import handleApiResponseErrors from 'helpers/handleApiResponse';
import useUserData from 'hooks/useUserData';

const Index = () => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { updateID } = useParams();
    const navigate = useNavigate();
    const { companyName } = useUserData();

    const userRoles = [
        // User roles array
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_SETTINGS_BASE_URL}Rcc/${updateID}`,
                        { headers: authHeader() }
                    );

                    if (response.data.success || response.status === 200) {
                        const data = response?.data?.payload?.rcInfo;
                        setData(data);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);

    const handleSubmitForm = async (values, actions) => {
        try {
            setIsLoading(true);

            const handleSubmitData = {
                companyName: companyName,
                controlType: values.controlType.value,
                reportName: values.reportName,
                assignedTo: values.assignedTo.value,
                excludedID: values.excludedID.value,
                status: values.status === "Active",
                reportId: values.reportId,
            };

            const url = updateID
                ? `${process.env.REACT_APP_SETTINGS_BASE_URL}Rcc/${updateID}`
                : `${process.env.REACT_APP_SETTINGS_BASE_URL}Rcc`;
            const axiosMethod = updateID ? axios.put : axios.post;
            const response = await axiosMethod(url, handleSubmitData, {
                headers: authHeader(),
            });

            if (response.status === 200 || response.data.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                Form.reset();
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSchema = Yup.object().shape({
        controlType: Yup.object().required('Report/Component Type is required'),
        reportName: Yup.string().required('Report/Component Name is required'),
        assignedTo: Yup.object().nullable(),
        excludedID: Yup.object().nullable(),
        status: Yup.string().required('Status is required'),
        reportId: Yup.string().required('Report/Component ID is required'),
    });

    return (
        <>
            <PageHeader title="Report Component Control Form" className="mb-3" />
            <FalconComponentCard>
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    {isLoading && <LoadingIcon />}
                    <Formik
                        initialValues={{
                            controlType: data?.controlType ? { label: data?.controlType, value: data?.controlType } : '',
                            reportName: data?.reportName || '',
                            assignedTo: data?.assignedTo ? { label: data?.assignedTo, value: data?.assignedTo } : '',
                            excludedID: data?.excludedID ? { label: data?.excludedID, value: data?.excludedID } : '',
                            status: data?.status === true ? "Active" : "Inactive",
                            reportId: data?.reportId || '',
                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({ errors, touched, handleSubmit, handleChange, handleBlur, values, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                        <Form.Label>Report/Component Type</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={[{ label: "Report", value: "Report" }, { label: "Component", value: "Component" }]}
                                            placeholder="Select Report/Component Type"
                                            classNamePrefix="react-select"
                                            name="controlType"
                                            value={values.controlType}
                                            onChange={(selectedOption) => setFieldValue('controlType', selectedOption)}
                                            onBlur={handleBlur}
                                            isDisabled={updateID}
                                        />
                                        {errors.controlType && <div style={{ color: 'red' }}>{errors.controlType}</div>}
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="exampleState">
                                        <Form.Label>Report/Component Name</Form.Label>
                                        <Form.Control
                                            name="reportName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                            placeholder="Enter Report/Component Name"
                                            value={values.reportName}
                                        />
                                        {touched.reportName && errors.reportName && <div style={{ color: 'red' }}>{errors.reportName}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-2">
                                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                        <Form.Label>Assigned To</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={userRoles}
                                            placeholder="Select Role"
                                            classNamePrefix="react-select"
                                            name="assignedTo"
                                            value={values.assignedTo}
                                            onChange={(selectedOption) => setFieldValue('assignedTo', selectedOption)}
                                            onBlur={handleBlur}
                                            isDisabled={updateID}
                                        />
                                        {errors.assignedTo && <div style={{ color: 'red' }}>{errors.assignedTo}</div>}
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                        <Form.Label>Excluded ID</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={[{ label: "None", value: "" }]}
                                            placeholder="Select Excluded ID"
                                            classNamePrefix="react-select"
                                            name="excludedID"
                                            value={values.excludedID}
                                            onChange={(selectedOption) => setFieldValue('excludedID', selectedOption)}
                                            onBlur={handleBlur}
                                            isDisabled={updateID}
                                        />
                                        {errors.excludedID && <div style={{ color: 'red' }}>{errors.excludedID}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="exampleState">
                                        <Form.Label>Report Id</Form.Label>
                                        <Form.Control
                                            name="reportId"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                            placeholder="Enter Report/Component ID"
                                            value={values.reportId}
                                            disabled={updateID}
                                        />
                                        {touched.reportId && errors.reportId && <div style={{ color: 'red' }}>{errors.reportId}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                        <Form.Label>Status</Form.Label>
                                        <div className='d-flex gap-4'>
                                            <Field
                                                type="radio"
                                                name="status"
                                                value="Active"
                                                as={Form.Check}
                                                id="statusActive"
                                                label="Active"
                                                checked={values.status === "Active"}
                                            />
                                            <Field
                                                type="radio"
                                                name="status"
                                                value="Inactive"
                                                as={Form.Check}
                                                id="statusInactive"
                                                label="Inactive"
                                                checked={values.status === "Inactive"}
                                            />
                                        </div>
                                        {errors.status && <div style={{ color: 'red' }}>{errors.status}</div>}
                                    </Form.Group>
                                    
                                </Row>

                                <IconButton variant="primary" className="me-2" type="submit">
                                    {updateID ? "Save" : "Submit"}
                                </IconButton>
                                <Button onClick={() => navigate('/report-component-control-list')} variant="danger" type="button">
                                    Cancel
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default Index;
