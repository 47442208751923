import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import axios from 'axios'
import IconButton from 'components/common/IconButton'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table, Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleBarReact from 'simplebar-react'
import { authHeader } from 'utils'
import { usePDF } from 'react-to-pdf'
import StatusCell from 'components/common/StatusCell'
import useUserRole from 'hooks/useUserRole'
import { formatNumber } from 'helpers/utils'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import LoadingIcon from 'helpers/LoadingIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from "react";
import debounce from "lodash/debounce";


const CollapseSchedule = ({ handleParentCheck, quanityChangeHandler, parentIndex, handleChildCheck, updatableCombos, setUpdatableCombos, comboOffers, ordersInfo, setSelectedSKUName, setSelectedDoNo, setDealerId, setDealerName, setSelectedDbType, isSuper, qtyChangeHandler, qtyChangeHandlerForPieces, deliveryDateChangeHandler, deliveryEndDateChangeHandler }) => {

    const formatDate = (dt) => {
        const originalDateString = dt
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`

        return formattedDateString
    }


    return (
        <div style={{ maxWidth: '100%' }}>
            <Card className="p-0" style={{ maxWidth: '100%' }}>
                <Card.Body>
                    <div className="fs--1">
                        <SimpleBarReact>
                            <Table borderless className="mb-10 fs--1">
                                <thead className="bg-light">
                                    <tr className="text-900">
                                        <th className="white-space-nowrap text-center ">
                                            <input
                                                type="checkbox"
                                                // checked={
                                                //     checkedOrders?.length ===
                                                //     groupedOrders?.length
                                                // }
                                                onChange={(e) =>
                                                    // handleCheckAll(e)
                                                    handleParentCheck(parentIndex, e.target.checked)
                                                }

                                                checked={ordersInfo?.every(order => order.isChecked)}
                                            />
                                        </th>
                                        <th>DO No</th>
                                        <th>DB ID</th>
                                        <th>DB Name</th>
                                        <th className="text-start">SKU Name</th>
                                        <th className="text-start">Unit</th>
                                        <th className="text-end">Order Qty</th>
                                        <th className="text-end">Delivery Qty</th>
                                        {/* {!isSuper && (<th className="text-start">Factory Name</th>)} */}
                                        <th className="text-start">Offer SKU</th>
                                        <th className="text-start">Offer Qty (PC)</th>
                                        <th className="text-start">Order Date</th>
                                        <th className="text-start"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ordersInfo?.map((order, index) => {
                                        return (
                                            <tr className="border-top border-200">
                                                <td className="align-middle text-center">
                                                    <input
                                                        type="checkbox"
                                                        // checked={isChecked(
                                                        //     order
                                                        // )}
                                                        // onChange={() =>
                                                        //     handleCheckboxChange(
                                                        //         order
                                                        //     )
                                                        // }
                                                        checked={order.isChecked}
                                                        onChange={() => handleChildCheck(parentIndex, index, !order.isChecked)}
                                                    />
                                                </td>
                                                <td className="align-middle text-start fw-semi-bold">{order?.doNo}</td>
                                                <td className="align-middle text-start fw-semi-bold">{order?.dealerId}</td>
                                                <td className="align-middle text-start fw-semi-bold">{order?.distributorName}</td>
                                                <td className="align-middle text-start fw-semi-bold">{order?.skuName || (order?.offerNo && order?.offerSkuName)}</td>
                                                <td className="text-start">{order?.dbType == "SPECIAL DISTRIBUTOR" || order?.offerNo ? "PCS" : "CTN"}</td>
                                                {!order?.offerNo ? (
                                                    <td className="align-middle text-end fw-semi-bold">
                                                        {order?.dbType == "SPECIAL DISTRIBUTOR" ? order.totalOrderQtyPCS || (order?.offerNo && order?.offerPc) : order.orderQtyCtn || (order?.offerNo && order?.offerPc)}
                                                    </td>
                                                ) : <td className="align-middle text-end fw-semi-bold">--</td>}


                                                {!order?.offerNo ? (
                                                    <td className="align-middle pe-x1 text-end">
                                                        <Form.Control
                                                            style={{ width: "86px" }}
                                                            required
                                                            type="number"
                                                            placeholder="Qty"
                                                            value={order.deliveryQty}
                                                            onChange={(event) => {
                                                                setSelectedSKUName(order.skuName)
                                                                setSelectedDoNo(order.doNo)
                                                                setDealerId(order.dealerId)
                                                                setDealerName(order.distributorName)
                                                                setSelectedDbType(order.dbType)
                                                                qtyChangeHandler(order._id, event)
                                                                quanityChangeHandler(parentIndex, index, event)
                                                            }}
                                                        />
                                                    </td>

                                                ) : <td className="align-middle text-start fw-semi-bold">--</td>}


                                                <td className="text-start">{order.offerSkuName || "--"}</td>
                                                <td className="text-start">{formatNumber(order.offerPc) || "--"}</td>

                                                <td>{formatDate(order.doDate)}</td>

                                            </tr>
                                        )
                                    })}

                                    {comboOffers.filter(offer => comboShowable(offer) && offer.offerAccepted == "product" && offer.againstSkuName.length > 1 && isSubset([offer.doNo], schedulableOrders, "doNo")).map(item => (
                                        <tr>
                                            <td>{item.doNo}</td>
                                            <td>{item.dealerId}</td>
                                            <td>{item.distributorName}</td>
                                            <td>{item.offerName}</td>
                                            <td>PCS</td>
                                            <td className="text-end">{formatNumber(item.offerPc) || "--"}</td>

                                            <td className='text-start'>
                                                <Form.Control
                                                    style={{ width: "86px" }}
                                                    required
                                                    type="number"
                                                    placeholder="Qty"
                                                    // value={item?.offerQtyPCS}
                                                    onChange={(event) => {
                                                        if (!updatableCombos.length) {
                                                            // setUpdatableCombos([...updatableCombos, { ...item, offerQtyPCS: +event.target.value>item.offerPc ? item.offerPc:  +event.target.value}])
                                                            setUpdatableCombos([...updatableCombos, { ...item, offerPc: item.offerPc }])
                                                        } else {

                                                            const existing = [...updatableCombos];
                                                            const index = existing.findIndex(my => my._id == item._id);
                                                            if (index >= 0) {
                                                                // existing[index].offerQtyPCS = +event.target.value > item.offerPc ? item.offerPc:  +event.target.value;
                                                                existing[index].offerPc = item.offerPc;
                                                                setUpdatableCombos([...existing])
                                                            } else {
                                                                setUpdatableCombos([...updatableCombos, { ...item, offerPc: item.offerPc }])
                                                                // setUpdatableCombos([...updatableCombos, { ...item, offerQtyPCS: +event.target.value > item.offerPc ? item.offerPc:  +event.target.value }])

                                                            }

                                                        }
                                                    }}
                                                />
                                            </td>

                                            {!isSuper &&
                                                <td className="align-middle text-start fw-semi-bold">

                                                    <Select
                                                        closeMenuOnSelect={true}
                                                        options={[

                                                            {

                                                                label: "SORL",
                                                                value: "SORL"
                                                            },
                                                            {

                                                                label: "SVOIL",
                                                                value: "SVOIL"
                                                            },
                                                            {

                                                                label: "Narshingdi",
                                                                value: "Narshingdi"
                                                            },
                                                            {

                                                                label: "Dhamrai",
                                                                value: "Dhamrai"
                                                            }
                                                        ]}
                                                        placeholder="Select"
                                                        classNamePrefix="react-select"
                                                        name="factoryName"
                                                        // value={{ id: item._id, label: offerFactoryName, value: offerFactoryName }}
                                                        onChange={(selectedOption) => {
                                                            // setFieldValue(
                                                            //     "factoryName",
                                                            //     selectedOption
                                                            // );

                                                            if (!updatableCombos.length) {
                                                                setUpdatableCombos([...updatableCombos, { ...item, factoryName: selectedOption.value }])
                                                            } else {

                                                                const existing = [...updatableCombos];
                                                                const index = existing.findIndex(my => my._id == item._id);
                                                                if (index >= 0) {
                                                                    existing[index].factoryName = selectedOption.value;
                                                                    setUpdatableCombos([...existing])
                                                                } else {
                                                                    setUpdatableCombos([...updatableCombos, { ...item, factoryName: selectedOption.value }])

                                                                }

                                                            }
                                                        }}
                                                    // onBlur={handleBlur}

                                                    />
                                                </td>
                                            }
                                            <td className="text-start">{item.offerSkuName || "--"}</td>
                                            <td className="text-start">{formatNumber(item.offerPc) || "--"}</td>

                                            <td>{formatDate(item.doDate)}</td>
                                            <td>--</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>
                        </SimpleBarReact>
                    </div>


                </Card.Body>
            </Card>
        </div>
    )
}

export default CollapseSchedule
