import axios from 'axios'
import { Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Target from "./TargetFiles/index";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import Swal from 'sweetalert2'
import Select from "react-select";
import useUserRole from '../../../../hooks/useUserRole';
import FalconCardHeader from 'components/common/FalconCardHeader';

const index = () => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [underDbList, setUnderDbList] = useState([]);
    const [selectedDb, setSelectedDb] = useState(null);
    const [isLoading, setIsLoading] = useState({
        general: false,
        db: false
    })
    const [orders, setOrders] = useState([]);
    const [targetAndAchievement, setTargetAndAchievement] = useState({});
    const { userId, zoneName, regionName, areaName } = useSelector(state => state.auth.user.data);
    const userRole = useUserRole();

    useEffect(() => {
        setIsLoading(prevState => ({ ...prevState, db: true }));
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dbsUnderSdb/${userId}`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.distributorData?.map(data => ({
                        id: data.userId,
                        label: data.distributorName,
                        value: data.userId
                    }));

                    setUnderDbList([{ id: 'All', value: 'All', label: 'All' }, ...modifyData]);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(prevState => ({ ...prevState, db: false }));
            }
        };

        fetchData();
    }, []);


    const handleSearch = () => {
        if (!selectedMonth) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a month!',
            });
        }
        if (!selectedYear) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a year!',
            });
        }
        if (!selectedYear) return alert("Please select a year");
        if (selectedMonth && selectedYear) {
            const month = selectedMonth.getMonth() + 1;
            const year = selectedYear.getFullYear();

            const fetchData = async () => {
                let targetUrl;
                let ordersUrl;
                if (selectedDb === 'All') {
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/SuperDBWiseUnderOrderTarget/${userId}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/SuperDBWiseUnderVsOrderTarget/${userId}/${year}/${month}`;
                } else {
                    if (!selectedDb) {
                        return Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please select a Distributor!',
                        });
                    }
                    targetUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrderTarget/${selectedDb}/${year}/${month}`;
                    ordersUrl = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/DBWiseOrdersVsTarget/${selectedDb}/${year}/${month}`;
                }
                
                setIsLoading(prevState => ({ ...prevState, general: true }));
                try {
                    const [targetResponse, ordersResponse] = await Promise.all([
                        axios.get(targetUrl, { headers: authHeader() }),
                        axios.get(ordersUrl, { headers: authHeader() })
                    ]);
                    console.log("Target", targetResponse, "Order", ordersResponse);
                    if ((targetResponse.data.success || targetResponse.status === 200) &&
                        (ordersResponse.data.success || ordersResponse.status === 200)) {

                        const targetData = targetResponse.data.payload.targetInfo;
                        const orderVsTarget = ordersResponse.data.payload.orderVsTarget;
                        let totalTargetCnt = 0;
                        let totalAchievementCnt = 0;
                        let totalAchievementAmount = 0;
                        const combinedData = targetData.map(targetItem => {
                            totalTargetCnt += targetItem.targetQuantityCtn;
                            const order = orderVsTarget.find(orderItem => orderItem.skuName === targetItem.skuName);
                            if (order) {
                                totalAchievementCnt += order.orderQtyCtn;
                                totalAchievementAmount += parseFloat(order.orderQtyCtn) * parseFloat(targetItem.ctnPrice);
                            }
                            return order ? { ...targetItem, ...order } : targetItem;
                        });

                        setOrders(combinedData);
                        setTargetAndAchievement({
                            totalTargetCnt,
                            totalAchievementCnt,
                            totalAchievementAmount
                        });
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(prevState => ({ ...prevState, general: false }));
                }
            };

            fetchData();
        }
    }

    if (isLoading.general) {
        return <LoadingIcon />
    }
    return (
        <>
            <div className="d-flex align-items-center justify-content-start">
                <label className="form-label me-2">Select DB: </label>
                <Form.Group className="mb-3 me-2">
                    <Select
                        closeMenuOnSelect={true}
                        options={underDbList}
                        placeholder="Select Distributor"
                        classNamePrefix="react-select"
                        name="dealerId"
                        value={underDbList.find(value => value === selectedDb)}
                        onChange={(selectedOption) => {
                            setSelectedDb(selectedOption.value)
                        }}
                    />
                </Form.Group>
                <label className="form-label me-2">Select Month: </label>
                <Form.Group className="mb-3 me-2">
                    <DatePicker
                        selected={selectedMonth}
                        onChange={(date) => setSelectedMonth(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        placeholderText="Select Month"
                        className="form-control"
                    />
                </Form.Group>
                <label className="form-label me-2">Select Year: </label>
                <Form.Group className="mb-3">
                    <DatePicker
                        selected={selectedYear}
                        onChange={(date) => setSelectedYear(date)}
                        dateFormat="yyyy"
                        showYearPicker
                        placeholderText="Select Year"
                        className="form-control"
                    />
                </Form.Group>
                <Form.Group className="mb-3 ms-3">
                    <button className="btn btn-primary px-5" onClick={() => handleSearch()}>Search</button>
                </Form.Group>
            </div>

            <Row className="g-3 mb-3">
                <Target orders={orders} targetAndAchievement={targetAndAchievement} />
            </Row>
        </>
    )
}
export default index;