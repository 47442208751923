import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import useUserRole from "hooks/useUserRole";
import { useDropzone } from "react-dropzone";
import FalconCloseButton from "components/common/FalconCloseButton";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from "react-toastify";
import { authHeaderForm } from "state/ducs/auth/utils";

const SalesRepresentative = () => {
    const [distributorList, setDistributorList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [showUploadCSV, setShowUploadCSV] = useState(false);
    const [count, setCount] = useState(0);
    const userInfo = useSelector((state) => state.auth.user?.data);
    const userRole = useUserRole();

    // csv Modal 
    const handleShowCSV = () => {
        setShowUploadCSV(true);
    };


    // DISTRIBUTOR LIST
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

        const fetchData = async () => {
            let url;
            if (userInfo?.userRole === "Admin" || userInfo?.userRole === "HOS") {
                url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allSr?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`;
            } else if (userInfo?.userRole === "ASM") {
                url = searchText !== ""
                    ? `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allSr?areaName=${userInfo?.areaName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                    : `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allSr?areaName=${userInfo?.areaName}`;
            } else if (userInfo?.userRole === "RSM") {
                url = searchText !== ""
                    ? `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allSr?regionName=${userInfo?.regionName}&search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`
                    : `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allSr?regionName=${userInfo?.regionName}`;
            }
            try {
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const totalPages = response?.data?.payload?.pagination?.totalPages;
                    const result = response?.data?.payload?.sr?.map((element, index) => ({
                        ...element,
                        index: startIndex + index
                    }));

                    setTotalPages(totalPages);
                    setDistributorList(result);
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount, searchText, userInfo]);

    // DELETE DISTRIBUTOR
    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/deleteSRById/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };

    /* CSV FILE UPLOAD */
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const handleCSVUpload = () => {
        const csvFile = acceptedFiles[0];
        const formData = new FormData();
        formData.append("csvFile", csvFile);

        const regUrl = process.env.REACT_APP_USER_MANAGEMENT_BASE_URL + "users/dbs/upload";

        setIsLoading(true);
        axios
            .post(regUrl, formData, { headers: authHeaderForm() })
            .then((response) => {
                if (response.data.success === true || response.status === 200) {
                    toast.success("Csv File Upload Success");
                    setShowUploadCSV(false);
                    setCount((prevState) => prevState + 1);
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(() => {
                setIsLoading(false);
            });
    };

    // EXCEL FILE DOWNLOAD
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/allDistributors`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.distributors;
                    const result = data.map((element) => {
                        return {
                            "Zone Name": element.zoneName,
                            "Region Name": element.regionName,
                            "Area Name": element.areaName,
                            "DB Point": element.dbPoint,
                            "Distributor Id": element.userId,
                            // "Password": element.password,
                            "ERP ID": element.erpId,
                            "DB Name": element.distributorName,
                            "DB Type": element.userRole,
                            "Super DB ID": element.superDBId ? element.superDBId : "-",
                            "Apps Live": element.hasLiveApp === true ? "Yes" : "No",
                            "Direct Sales": element.hasDirectSale === true ? "Yes" : "No",
                            "Status": element.status === true ? "Active" : "Inactive",
                            "Proprietor Name": element.proprietor,
                            "DOB": new Date(element.proprietorDOB).toLocaleDateString(),
                            "DB Opening Date": new Date(element.dbOpeningDate).toLocaleDateString(),
                            "Address": element.address,
                            "Mobile": element.phoneNumber,
                            "Emergency Person": element.emergencyContactName ? element.emergencyContactName : "-",
                            "Emergency Mobile": element.emergencyMobileNumber ? element.emergencyMobileNumber : "-",
                            "Relation With Owner": element.emergencyContactRelation ? element.emergencyContactRelation : "-",
                            "PC Exist": element.hasPC === true ? "Yes" : "No",
                            "Printer Exist": element.hasPrinter === true ? "Yes" : "No",
                        }
                    })
                    setExcelData(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            }
        };
        fetchData();
    }, []);

    const columns = [
        { accessor: "index", Header: "SL", cellProps: { className: "py-2" }, Cell: ({ row: { original: { index } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{index}</h5></div></Flex> },
        { accessor: "zoneName", Header: "Zone Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { zoneName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{zoneName}</h5></div></Flex> },
        { accessor: "regionName", Header: "Region Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { regionName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{regionName}</h5></div></Flex> },
        { accessor: "areaName", Header: "Area Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { areaName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{areaName}</h5></div></Flex> },
        { accessor: "dbPoint", Header: "DB Point", cellProps: { className: "py-2" }, Cell: ({ row: { original: { dbPoint } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{dbPoint}</h5></div></Flex> },
        { accessor: "delearId", Header: "Distributor Id", cellProps: { className: "py-2" }, Cell: ({ row: { original: { dealerId } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{dealerId}</h5></div></Flex> },
        { accessor: "dealerName", Header: "Dealer Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { dealerName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{dealerName}</h5></div></Flex> },
        { accessor: "srId", Header: "SR Id", cellProps: { className: "py-2" }, Cell: ({ row: { original: { userId } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{userId}</h5></div></Flex> },
       
        { accessor: "password", Header: "Password", cellProps: { className: "py-2" }, Cell: ({ row: { original: { password } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{password}</h5></div></Flex> },
        { accessor: "SR Name", Header: "SR Name", cellProps: { className: "py-2" }, Cell: ({ row: { original: { srName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{srName}</h5></div></Flex> },

        { accessor: "DOB", Header: "DOB", cellProps: { className: "py-2" }, Cell: ({ row: { original: { dobDate } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{new Date(dobDate).toLocaleDateString()}</h5></div></Flex> },
        { accessor: "dojDate", Header: "Joining Date", cellProps: { className: "py-2" }, Cell: ({ row: { original: { dojDate } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{new Date(dojDate).toLocaleDateString()}</h5></div></Flex> },
        
        { accessor: "Mobile", Header: "Mobile", cellProps: { className: "py-2" }, Cell: ({ row: { original: { mobileNo } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{mobileNo}</h5></div></Flex> },
        { accessor: "SSC Year", Header: "SSC Year", cellProps: { className: "py-2" }, Cell: ({ row: { original: { sscYear } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{sscYear ? sscYear : "-"}</h5></div></Flex> },
        { accessor: "Highest Degree", Header: "Highest Degree", cellProps: { className: "py-2" }, Cell: ({ row: { original: { hDegree } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{hDegree ? hDegree : "-"}</h5></div></Flex> },
        { accessor: "Blood Group", Header: "Blood Group", cellProps: { className: "py-2" }, Cell: ({ row: { original: { bloodGroup } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{bloodGroup ? bloodGroup : "-"}</h5></div></Flex> },
        { accessor: "Emergency Person", Header: "Emergency Person", cellProps: { className: "py-2" }, Cell: ({ row: { original: { emergencyContactName } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{emergencyContactName ? emergencyContactName : "-"}</h5></div></Flex> },
        { accessor: "Emergency Mobile", Header: "Emergency Mobile", cellProps: { className: "py-2" }, Cell: ({ row: { original: { emergencyContactMobile } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{emergencyContactMobile ? emergencyContactMobile : "-"}</h5></div></Flex> },
        { accessor: "Relation With Owner", Header: "Relation With Owner", cellProps: { className: "py-2" }, Cell: ({ row: { original: { emergencyContactRelation } } }) => <Flex alignItems="center"><div className="flex-1"><h5 className="mb-0 fs--1 text-center">{emergencyContactRelation ? emergencyContactRelation : "-"}</h5></div></Flex> },
       
        {
            accessor: "action", Header: "Action", cellProps: { className: "py-2" }, Cell: ({ row: { original: { _id, userId } } }) => (
                <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                        ...
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <Link to={`/db/profile/${userId}`}>
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="edit"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="me-2"
                                >
                                    <span className="d-none d-xl-inline-block ms-1">View profile</span>
                                </IconButton>
                            </Link>
                        </Dropdown.Item>
                        {userRole === "Admin" && (
                            <><Dropdown.Item>
                                <Link to={`/user/sr/add/${_id}`}>
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="edit"
                                        transform="shrink-2"
                                        iconAlign="middle"
                                        className="me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                    </IconButton>
                                </Link>
                            </Dropdown.Item>
                                <Dropdown.Item>
                                    <IconButton
                                        onClick={() => handleDelete(_id)}
                                        variant="falcon-default"
                                        size="sm"
                                        icon="trash-alt"
                                        iconAlign="middle"
                                        className="d-none d-sm-block me-2"
                                    >
                                        <span className="d-none d-xl-inline-block ms-1">Delete</span>
                                    </IconButton>
                                </Dropdown.Item></>
                        )}
                    </Dropdown.Menu>

                </Dropdown>
            )
        }
    ];

    return (
        <>
            <PageHeader title="Sales Representative" className="mb-3" />
            {isLoading ? (
                <LoadingIcon />
            ) : (
                <AdvanceTableWrapper columns={columns} data={distributorList} sortable pagination perPage={50}>
                    <Card className="mb-3">
                        <Card.Header>
                            <CustomersTableHeader
                                newUrl="/user/sr/add"
                                isSearch={true}
                                setSearchText={setSearchText}
                                // buttonTitle="Upload CSV"
                                // handleShowCSV={handleShowCSV}
                                excelFileName="Sales Representative"
                                excelData={excelData}
                                table
                            />
                        </Card.Header>
                        <Card.Body className="p-0">
                            {distributorList?.length > 0 ? <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle"
                                tableProps={{
                                    size: "sm",
                                    striped: true,
                                    className: "fs--1 mb-0 overflow-hidden",
                                }}
                            /> : <div className="text-bold text-center m-5">No Data Found!</div>}
                        </Card.Body>
                        <Card.Footer>
                            {(userInfo?.userRole === "Admin" || userInfo?.userRole === "HOS") && (
                                <AdvanceTablePagination
                                    table
                                    paginationPageCount={paginationPageCount}
                                    setPaginationPageCount={setPaginationPageCount}
                                    pageDataCount={pageDataCount}
                                    setPageDataCount={setPageDataCount}
                                    totalPages={totalPages}
                                />
                            )}
                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>
            )}
            {/* CSV File Upload Modal */}
            <Modal
                size="lg"
                show={showUploadCSV}
                onHide={() => setShowUploadCSV(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Upload Distributor CSV File
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShowUploadCSV(false)} />
                </Modal.Header>
                <Modal.Body>
                    <div {...getRootProps({ className: "dropzone-area py-6" })}>
                        <input {...getInputProps({ multiple: false })} />
                        <Flex justifyContent="center">
                            <img src={cloudUpload} alt="" width={25} className="me-2" />
                            <p className="fs-0 mb-0 text-700">Drop your file here</p>
                        </Flex>
                    </div>
                    <div className="mt-3">
                        {acceptedFiles.length > 0 && (
                            <>
                                <h6>File</h6>
                                <ul>{files}</ul>
                            </>
                        )}
                    </div>
                    <Button variant="primary" onClick={handleCSVUpload}>
                        Submit
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SalesRepresentative;
