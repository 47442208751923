import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const ProductSettingsForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [productSettingsById, setProductSettingsById] = useState({});
    const [productList, setProductList] = useState([])
    const [productPackingUnitList, setProductPackingUnitList] = useState([])
    const { updateID } = useParams();
    const navigate = useNavigate();


    // Brand Get By ID
    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_SETTINGS_BASE_URL + `product-settings/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setProductSettingsById(response.data.data);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
    }, [])

    // load Product Items 
    useEffect(() => {
        setIsLoading(true)
        const url = process.env.REACT_APP_PRODUCT_MANAGEMENT_BASE_URL + "products";
        axios.get(url, { headers: authHeader() })
            .then(response => {

                if (response.data.success || response.data.statusCode === 200) {
                    const modifyProductItems = response?.data?.data?.map(item => {
                        return {
                            id: item.id,
                            label: item.product_english_name,
                            value: item.id

                        }
                    })

                    setProductList(modifyProductItems);

                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response)
            }).finally(
                setIsLoading(false)
            )
    }, [])

    // load Product Items 
    useEffect(() => {
        setIsLoading(true)
        const url = process.env.REACT_APP_SETTINGS_BASE_URL + "product-packaging-units";
        axios.get(url, { headers: authHeader() })
            .then(response => {

                if (response.data.success || response.data.statusCode === 200) {
                    const modifyData = response?.data?.data?.map(item => {
                        return {
                            id: item.id,
                            label: item.name,
                            value: item.id

                        }
                    })

                    setProductPackingUnitList(modifyData);

                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response)
            }).finally(
                setIsLoading(false)
            )
    }, [])


    const handleFormSubmit = (values, actions) => {

        const handleSubmitValue = {
            product_id: values.product_id.value,
            product_packaging_unit_id: values.product_packaging_unit_id.value,
            minimum_days_to_deliver: values.minimum_days_to_deliver,
        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_SETTINGS_BASE_URL + 'product-settings';
            axios.post(url, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/productSettings")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_SETTINGS_BASE_URL + `product-settings/${updateID}`;
            axios.put(updateUrl, handleSubmitValue, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/productSettings")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({
        product_id: Yup.object().required("Please select a value for the product field"),
        product_packaging_unit_id: Yup.object().required("Please select a value for the product packaging unit field"),
        minimum_days_to_deliver: Yup.string().required("Please select a value for the minimum days to deliver field"),
    })


    return (
        <>
            <PageHeader
                title={updateID ? "Update Product Settings" : "Add New Product Settings"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            product_id: productList.find(item => item.value === productSettingsById.product_id) || null,
                            product_packaging_unit_id: productPackingUnitList.find(item => item.value === productSettingsById.product_packaging_unit_id) || null,
                            minimum_days_to_deliver: productSettingsById.minimum_days_to_deliver || "",

                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {
                            console.log(errors)
                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Product</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={productList}
                                                placeholder="Select Product"
                                                classNamePrefix="react-select"
                                                name="product_id"
                                                value={values.product_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "product_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.product_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.product_id}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Packing Unit</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={productPackingUnitList}
                                                placeholder="Select Packing Unit"
                                                classNamePrefix="react-select"
                                                name="product_packaging_unit_id"
                                                value={values.product_packaging_unit_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "product_packaging_unit_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.product_packaging_unit_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.product_packaging_unit_id}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Minimum Days to Deliver</Form.Label>
                                            <Form.Control
                                                name="minimum_days_to_deliver"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Minimum Days to Deliver"
                                                required
                                                value={values.minimum_days_to_deliver}
                                            />
                                            {touched.minimum_days_to_deliver && errors.minimum_days_to_deliver && (
                                                <div style={{ color: "red" }}>
                                                    {errors.name}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"

                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/productSettings")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default ProductSettingsForm;


