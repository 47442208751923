import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import ReceivedPanelConfirm from "./ReceivedPanelConfirm";
import { formatNumber } from 'helpers/utils';

const Index = () => {
    const [step, setStep] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [reciveNo, setReciveNo] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedExpiryDate, setSelecteExpitydDate] = useState(null);
    const [count, setCount] = useState(2);
    const [batchNo, setBatchNo] = useState(null);
    const [productsData, setProductsData] = useState([]);
    const [checkedProducts, setCheckProducts] = useState([]);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);


    // DATE
    const handleDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
    };

    // DATE
    const handleExpityDateChange = (e) => {
        const date = e.target.value;
        setSelecteExpitydDate(date);
    };

    const handleAddNote = (event, product) => {
        const { value } = event.target;

        setProductsData((prevProducts) =>
            prevProducts.map((productItem) =>
                productItem.skuName === product.skuName
                    ? { ...productItem, note: value }
                    : productItem
            )
        );
    };


    const handleReceivedProducts = (event, product, fieldName) => {
        const { value } = event.target;
        const calculatedPcs = value * product?.pcsCTN;
        const totalWeight = calculatedPcs * product?.weightPCS || 0;
        const calculateWeight = totalWeight / 1000000;

        setProductsData((prevProducts) =>
            prevProducts.map(productItem =>
                productItem.skuName === product.skuName
                    ? { ...productItem, ctn: Number(value), pcs: Number(calculatedPcs), weightMT: Number(calculateWeight) }
                    : productItem
            )
        );
    };


    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products?search=${searchText}`;
                const response = await axios.get(url, { headers: authHeader() });
                console.log(response)
                if (response.data.success || response.status === 200) {
                    const exactMatchProducts = await response?.data?.payload?.products
                        ?.filter(product =>
                            product?.factoryName?.every(factory =>
                                factory === distributorInfo?.factoryName
                            )
                        )

                    setProductsData(exactMatchProducts);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, distributorInfo?.factoryName]);


    // Filter products based on search term
    const filteredProducts = productsData?.filter(product =>
        product.skuName.toLowerCase().includes(searchText.toLowerCase()) ||
        product.erpId.toLowerCase().includes(searchText.toLowerCase())
    );


    const handleNext = () => {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select a date",
                icon: "warning"
            });
            return;
        }
        if (!batchNo) {
            Swal.fire({
                title: "Please Enter Batch No.",
                icon: "warning"
            });
            return;
        }

        const checkedProducts = productsData.filter((product) => product.ctn && product.pcs);
        if (checkedProducts?.length > 0) {
            setCheckProducts(checkedProducts);
            setStep(2);

        } else {
            Swal.fire({
                title: "Please fill the quantity",
                icon: "warning"
            });
            return;
        }
    }


    useEffect(() => {
        let prefix = ''

        if (distributorInfo?.factoryName === 'SORL') {
            prefix = 'SRL'
        } else if (distributorInfo?.factoryName === 'SVOIL') {
            prefix = 'SVL'
        } else if (distributorInfo?.factoryName === 'Narshingdi') {
            prefix = 'NAR'
        } else if (distributorInfo?.factoryName === 'Dhamrai') {
            prefix = 'DHM'
        }
        else if (distributorInfo?.factoryName === 'Dhaka Central Depot') {
            prefix = 'DCD'
        }
        else if (distributorInfo?.factoryName === 'Ctg Depot') {
            prefix = 'CTD'
        }
        else if (distributorInfo?.factoryName === 'Noakhali Depot') {
            prefix = 'NHD'
        }
        else if (distributorInfo?.factoryName === 'Sylhet Depot') {

            prefix = 'SLD'
        }
        else if (distributorInfo?.factoryName === 'Jessore Depot') {
            prefix = 'JSD'
        }
        else if (distributorInfo?.factoryName === 'Mymensingh Depot') {
            prefix = 'MSD'
        }
        else if (distributorInfo?.factoryName === 'Bogra Depot') {
            prefix = 'BGD'
        }
        else if (distributorInfo?.factoryName === 'Barisal Depot') {
            prefix = 'BSD'
        }
        else if (distributorInfo?.factoryName === 'Tekerhat Depot') {
            prefix = 'TKD'
        }
        else if (distributorInfo?.factoryName === 'Rangpur Depot') {
            prefix = 'RPD'
        }

        const generatedReceivedNo = prefix + '-' + Math.floor(Math.random() * 1000000)
        setReciveNo(generatedReceivedNo)
    }, [])


    if (isLoading) {
        return <LoadingIcon />;
    }


    return (
        <>
            <PageHeader
                title={`Shiftment Received,    No. ${reciveNo}`}
                className="mb-3"
            ></PageHeader>

            {step === 1 && <><Card className="mb-3">
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <CustomersTableHeader isNew={false} isExport={false} isSearch={true} setSearchText={setSearchText} />
                        <Row className="g-3 gy-md-0 h-100 align-items-center">
                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Production Date :</h6>
                                <input type="date" className="form-control" placeholder="" onChange={(e) => handleDateChange(e)}
                                    value={selectedDate}
                                />
                            </Col>
                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Expiry Date :</h6>
                                <input type="date" className="form-control" placeholder="" onChange={(e) => handleExpityDateChange(e)}
                                    value={selectedExpiryDate}
                                />
                            </Col>
                            <Col md="auto">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Batch No. :</h6>
                                <Form.Control type="text" placeholder="Enter Batch No." onChange={(event) => setBatchNo(event.target.value)}
                                    value={batchNo}
                                />
                            </Col>
                        </Row>

                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Sub Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th className="align-middle white-space-nowrap text-center ">ERP ID</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>
                                        <th colSpan="2" className="align-middle white-space-nowrap text-center ">Note</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        filteredProducts?.map((product, index) => {
                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.categoryName}</td>
                                                <td className="align-middle text-center">{product?.subCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">{product?.erpId}</td>
                                                <td className="align-middle text-center">
                                                    <Form.Control type="text" placeholder="" onChange={(event) => handleReceivedProducts(event, product, "ctn")}
                                                        value={formatNumber(product && product.ctn !== undefined ? product.ctn : "")}
                                                    />
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={formatNumber(product && product.pcs !== undefined ? product.pcs : "")}
                                                        // onChange={(event) => handleReceivedProducts(event, index, product, "pcs")}
                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={formatNumber(product && product.weightMT !== undefined ? product.weightMT : "")}
                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Enter Note"
                                                        value={product && product.note !== undefined ? product.note : ""}
                                                        onChange={(event) => handleAddNote(event, product)}
                                                    />
                                                </td>


                                            </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
                <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleNext(); }}>
                    Shift to Store
                </Button></>}

            {
                step === 2 && <ReceivedPanelConfirm step={step} setStep={setStep} reciveNo={reciveNo} selectedDate={selectedDate} selectedExpiryDate={selectedExpiryDate} batchNo={batchNo} checkedProducts={checkedProducts} setCheckProducts={setCheckProducts} setCount={setCount} setSelectedDate={setSelectedDate} setSelecteExpitydDate={setSelecteExpitydDate} setBatchNo={setBatchNo} />
            }

        </>
    );

};

export default Index;
