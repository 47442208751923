import { formatNumber } from 'helpers/utils'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import SimpleBarReact from 'simplebar-react'

const getTotalPrice = (items) =>
    items
        .map(({ unit, price }) => unit * price)
        .reduce((total, currentValue) => total + currentValue, 0)

const getTotalOrder = (items) =>
    items
        .map(({ unit }) => unit)
        .reduce((total, currentValue) => total + currentValue, 0)

const formatDate = (dt) => {
    const originalDateString = dt
    const originalDate = new Date(originalDateString)

    const year = originalDate.getFullYear()
    const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
    const day = String(originalDate.getDate()).padStart(2, '0')

    const formattedDateString = `${year}-${month}-${day}`

    return formattedDateString
}

const DeliverableTableRow = ({
    order,
    setSelectedSKUName,
    setSelectedFactoryName,
    setSelectedDoNo,
    factoryNameChangeHandler,
    qtyChangeHandler,
    deliveryDateChangeHandler,
    setDealerId,
    setDealerName,
    setSelectedDbType,
    index,
}) => {
    const {
        doNo,
        doDate,
        totalOrderQtyPCS,
        orderQtyCtn,
        factoryName,
        skuName,
        deliveryQty,
        _id,
        deliveryDate,
        deliveryDateStart,
        deliveryDateEnd,
        delQtyCtn,
        delQtyPcs,
        scheduleDate,
        conQtyCtn,
        conQtyPcs,
        dealerId,
        distributorName,
        scheduleNo,
        dbType,
        offerSkuName,
        offerQtyPCS,
        offerNo

    } = order

    console.log("order", order);

    const formatDate = (dt) => {
        const originalDateString = dt
        const originalDate = new Date(originalDateString)

        const year = originalDate.getFullYear()
        const month = String(originalDate.getMonth() + 1).padStart(2, '0') // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`

        return formattedDateString
    }

    return (
        <tr className="border-top border-200">

            <td className="align-middle text-start fw-semi-bold">{index + 1}</td>
            <td className="align-middle text-start fw-semi-bold">{dealerId}</td>
            <td className="align-middle text-start fw-semi-bold">{distributorName}</td>
            <td className="align-middle text-start fw-semi-bold">{doNo}</td>
            <td className="align-middle text-start fw-semi-bold">{scheduleNo}</td>
            <td className="align-middle text-start fw-semi-bold">{skuName}</td>
            <td className="text-start">{dbType == "SPECIAL DISTRIBUTOR" ? "PCS" : offerNo ? "PCS" : "CTN"}</td>
            {/* <td className="align-middle text-end fw-semi-bold">{delQtyCtn}</td>
             */}

            <td className="align-middle text-end fw-semi-bold">
                {dbType == "SPECIAL DISTRIBUTOR" ? delQtyPcs : delQtyCtn}
            </td>

            <td className="align-middle pe-x1 text-end">
                <Form.Control
                    style={{ width: "86px" }}
                    required
                    type="number"
                    placeholder="Qty"
                    value={(dbType == "SPECIAL DISTRIBUTOR" ? conQtyPcs : conQtyCtn)}
                    onChange={(event) => {
                        setSelectedSKUName(skuName)
                        setSelectedDoNo(doNo)
                        setSelectedFactoryName(factoryName)
                        setDealerId(dealerId)
                        setDealerName(distributorName)
                        setSelectedDbType(dbType)
                        qtyChangeHandler(_id, event)
                    }}
                />
            </td>
            <td className="text-start">{offerSkuName || "--"}</td>
            <td className="text-start">{formatNumber(offerQtyPCS) || "--"}</td>
            <td>
                {' '}
                <div style={{ width: '150px' }}>
                    <DatePicker
                        selected={new Date(deliveryDate)}
                        onChange={(date) => deliveryDateChangeHandler(_id, date)}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        className="form-control"
                        placeholderText="Select Date"
                        disabled={(dbType == "SPECIAL DISTRIBUTOR" ? conQtyPcs : conQtyCtn) <= 0}
                        popperPlacement="top-start"
                        style={{ width: '100%' }}

                    />
                </div>
            </td>
            <td> {deliveryDateStart ? formatDate(deliveryDateStart) : formatDate(new Date().toISOString())}</td>
            <td> {formatDate(deliveryDateEnd)}</td>
            <td>
                <Button
                    onClick={() => {
                        setSelectedSKUName(skuName)
                        setSelectedDoNo(doNo)
                        setSelectedFactoryName(factoryName)
                    }}
                    size="sm"
                >
                    View
                </Button>
            </td>
        </tr>
    )
}

const DeliverableProductsUpdate = ({
    mySchedules,
    setSelectedFactoryName,
    setSelectedSKUName,
    setSelectedDoNo,
    setDeliverableSchedules,
    deliverableSchedules,
    handleScheduleSubmit,
    getDeliveredQuantityBySKUShD,
    getDeliveredQuantityBySKUShDInPieces,
    // scheduleNumber,
    myFactory,
    allDeliveries,
    setDealerId,
    setDealerName,
    setSelectedDbType,
    comboOffers,
    setComboOffers,
    deliveredOrders
}) => {
    console.log("comboOffers", comboOffers);

    useEffect(() => {
        /////////////////////////////////////////////////////
        //////for filtering out completed orders: START//////
        ///////////////////////////////////////////////////

        const IsShowable = (order, allDeliveries) => {
            if (!allDeliveries.length) {
                return false
            }
            const qtySum = (allDeliveries || [])
                .filter(
                    (item) =>
                        item.doNo == order.doNo &&
                        item.skuName == order.skuName &&
                        item.scheduleNo == order.scheduleNo
                )
                .reduce((accumulator, obj) => {
                    return accumulator + (obj.dbType == "SPECIAL DISTRIBUTOR" ? obj.delQtyPcs : obj.delQtyCtn)
                }, 0)

            return qtySum == (order.dbType == "SPECIAL DISTRIBUTOR" ? order.delQtyPcs : order.delQtyCtn)
        }

        const getSum = (order, allDeliveries) => {
            if (!allDeliveries.length) {
                return 0
            }
            const qtySum = (allDeliveries || [])
                .filter(
                    (item) =>
                        item.doNo == order.doNo &&
                        item.skuName == order.skuName &&
                        item.scheduleNo == order.scheduleNo
                )
                .reduce((accumulator, obj) => {
                    return accumulator + (obj.dbType == "SPECIAL DISTRIBUTOR" ? obj.delQtyPcs : obj.delQtyCtn)
                }, 0)

            return qtySum
        }

        // const filteredOrders = (mySchedules || []).filter(
        //     (order) =>
        //         order.scheduleNo == scheduleNumber?.scheduleNo &&
        //         order.factoryName == myFactory &&
        //         !IsShowable(order, allDeliveries)
        // )

        let filteredOrders = []
        if (mySchedules && mySchedules.length) {
            filteredOrders = (mySchedules || []).filter(
                (order) =>
                    // order.scheduleNo == scheduleNumber?.label &&
                    !order.offerNo &&
                    order.factoryName == myFactory && !IsShowable(order, [...allDeliveries, ...deliveredOrders])
            )
        }

        console.log('mySchedules', mySchedules)
        console.log('allDeliveries', allDeliveries)
        console.log('filteredOrders', filteredOrders)
        console.log('deliveredOrders', deliveredOrders)

        ////////////////////////////////////////////////////
        //////for filtering out completed orders: END//////
        //////////////////////////////////////////////////

        setDeliverableSchedules(
            filteredOrders.map((it) => {
                const totalAmount = (it.dbType == "SPECIAL DISTRIBUTOR" ? it.delQtyPcs : it.delQtyCtn) - getSum(it, [...allDeliveries, ...deliveredOrders])
                const perPcs = it.delQtyPcs / (it.dbType == "SPECIAL DISTRIBUTOR" ? it.delQtyPcs : it.delQtyCtn)
                return {
                    ...it,
                    conQtyPcs: totalAmount * perPcs,
                    // conQtyCtn: totalAmount,
                    conQtyCtn: it.dbType == "SPECIAL DISTRIBUTOR" ? 0 : totalAmount,
                }
            })
        )
        // }, [mySchedules.length, scheduleNumber, allDeliveries.length])
        setComboOffers([...comboOffers]);
    }, [mySchedules.length, deliveredOrders.length, allDeliveries.length])

    const qtyChangeHandler = (id, ev) => {
        const existing = deliverableSchedules
        console.log('existing', existing)
        const returned = existing.map((item) => {
            if (item._id == id) {
                const schQty =
                    getDeliveredQuantityBySKUShD(
                        item.skuName,
                        item.scheduleNo,
                        item.doNo
                    ) || 0
                // const schQty = 0

                let des = 0
                const inputValue = ev.target.value
                if (inputValue > item.delQtyCtn - schQty) {
                    des = item.delQtyCtn - schQty
                } else if (inputValue < 0) {
                    des = 0
                } else {
                    des = inputValue
                }

                return {
                    ...item,
                    conQtyCtn: des,
                    conQtyPcs: des * (item.delQtyPcs / item.delQtyCtn),
                }
            }
            return item
        })

        setDeliverableSchedules(returned)
    }

    const qtyChangeHandlerInPieces = (id, ev) => {
        const existing = deliverableSchedules
        console.log('existing', existing)
        const returned = existing.map((item) => {
            if (item._id == id) {
                const schQtyPcs =
                    getDeliveredQuantityBySKUShDInPieces(
                        item.skuName,
                        item.scheduleNo,
                        item.doNo
                    ) || 0
                // const schQtyPcs = 0

                let des = 0
                const inputValue = ev.target.value
                if (inputValue > item.delQtyPcs - schQtyPcs) {
                    des = item.delQtyPcs - schQtyPcs
                } else if (inputValue < 0) {
                    des = 0
                } else {
                    des = inputValue
                }

                return {
                    ...item,
                    // conQtyCtn: des,
                    conQtyCtn: 0,
                    conQtyPcs: des,
                }
            }
            return item
        })

        setDeliverableSchedules(returned)
    }


    const deliveryDateChangeHandler = (id, date) => {
        const existing = deliverableSchedules
        const returned = existing.map((item) => {
            if (item._id == id) {
                return {
                    ...item,
                    deliveryDate: date,
                }
            }
            return item
        })

        setDeliverableSchedules(returned)
    }

    const isSubset = (subset, set, key) => {
        console.log("subset", subset);
        console.log("set", set);
        console.log("setTruth", subset.every(subItem => set.some(setItem => setItem[key] === subItem)));

        return subset.every(subItem => set.some(setItem => setItem[key] === subItem));
    }

    console.log("comboOffers", comboOffers);


    return (
        <Card className="h-lg-100 overflow-hidden">
            <Card.Body className="p-0">
                <SimpleBarReact>
                    <Table borderless className="mb-10 fs--1">
                        <thead className="bg-light">
                            <tr className="text-900">
                                <th>SL</th>
                                <th>DB ID</th>
                                <th>DB Name</th>
                                <th>DO No</th>
                                <th className="text-end">Schedule No</th>
                                <th className="text-start">SKU Name</th>
                                <th className="text-start">Unit</th>
                                <th className="text-end">Scheduled Qty</th>
                                <th className="text-end">Delivery Qty</th>
                                <th className="text-start">Offer SKU</th>
                                <th className="text-start">Offer Qty (PC)</th>
                                <th className="text-start">Delivery Date</th>
                                <th className="text-start">Delivery Start</th>
                                <th className="text-start">Delivery End</th>
                                <th className="text-start"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {deliverableSchedules.map((order, i) => (
                                <DeliverableTableRow
                                    index= {i}
                                    order={order}
                                    key={order._id}
                                    setSelectedFactoryName={
                                        setSelectedFactoryName
                                    }
                                    setSelectedSKUName={setSelectedSKUName}
                                    setSelectedDoNo={setSelectedDoNo}
                                    qtyChangeHandler={order.dbType == "SPECIAL DISTRIBUTOR" ? qtyChangeHandlerInPieces : qtyChangeHandler}
                                    deliveryDateChangeHandler={
                                        deliveryDateChangeHandler
                                    }
                                    setDealerId={setDealerId}
                                    setDealerName={setDealerName}
                                    setSelectedDbType={setSelectedDbType}
                                />
                            ))}

                            {/* {comboOffers.filter(offer => offer.offerAccepted=="product" && offer.againstSkuName.length > 1 && isSubset(offer.againstSkuName, deliverableSchedules, "skuName")).map(item => ( */}
                            {comboOffers.filter(offer => offer.offerNo).map(item => (
                                <tr>
                                    <td>{item.dealerId}</td>
                                    <td>{item.distributorName}</td>
                                    <td>{item.doNo}</td>
                                    <td>{item.scheduleNo}</td>
                                    <td>{item.skuName}</td>
                                    {/* <td>--</td>
                                    <td>--</td> */}
                                    <td>PCS</td>
                                    <td className='text-end'>
                                        {formatNumber(item?.offerQtyPCS) || "--"}

                                    </td>
                                    <td> <Form.Control
                                        style={{ width: "86px" }}
                                        required
                                        type="number"
                                        placeholder="Qty"
                                        value={item.offerQtyPCS}
                                        onChange={(event) => {
                                            const existing = comboOffers;
                                            const index = comboOffers.findIndex(mm => mm._id == item._id)
                                            // existing[index].offerQtyPCS = +event.target.value;
                                            existing[index].offerQtyPCS = +item.offerQtyPCS;
                                            setComboOffers(existing);
                                        }}
                                    /></td>
                                    <td className="text-start">{item.offerSkuName || "--"}</td>
                                    <td className="text-start">{formatNumber(item.offerQtyPCS) || "--"}</td>
                                    {/* <td>{formatDate(item.deliveryDate)}</td> */}
                                    <td>
                                        <div style={{ width: '150px' }}>
                                            <DatePicker
                                                selected={new Date(item.deliveryDate)}
                                                onChange={(date) => {
                                                    // deliveryDateChangeHandler(_id, date)
                                                    const existing = comboOffers;
                                                    const index = comboOffers.findIndex(mm => mm._id == item._id)
                                                    existing[index].deliveryDate = date;
                                                    setComboOffers(existing);
                                                }}
                                                formatWeekDay={(day) => day.slice(0, 3)}
                                                className="form-control"
                                                placeholderText="Select Date"
                                                // disabled={(dbType == "SPECIAL DISTRIBUTOR" ? conQtyPcs : conQtyCtn) <= 0}
                                                popperPlacement="top-start"
                                                style={{ width: '100%' }}

                                            />
                                        </div>
                                    </td>

                                    <td> {item.deliveryDateStart ? formatDate(item.deliveryDateStart) : formatDate(new Date().toISOString())}</td>
                                    <td> {formatDate(item.deliveryDateEnd)}</td>

                                    <td>--</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </SimpleBarReact>
            </Card.Body>
            <Card.Footer className="bg-light py-2">
                <Row className="flex-between-center">
                    <Col xs="auto"></Col>
                    <Col xs="auto">
                        <Button
                            onClick={handleScheduleSubmit}
                            variant="falcon-default"
                            size="sm"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}

DeliverableTableRow.propTypes = {
    totalPrice: PropTypes.number.isRequired,
    totalOrder: PropTypes.number.isRequired,
    product: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        img: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        unit: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
    }).isRequired,
}

DeliverableProductsUpdate.propTypes = {
    products: PropTypes.arrayOf(DeliverableTableRow.propTypes.product)
        .isRequired,
}

export default DeliverableProductsUpdate
