import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap'
import axios from 'axios'
import { authHeader } from 'utils'
import LoadingIcon from 'helpers/LoadingIcon'
import { Link } from 'react-router-dom'
import IconButton from 'components/common/IconButton'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import handleApiResponseErrors from 'helpers/handleApiResponse'
import Swal from 'sweetalert2'
import PageHeader from 'components/common/PageHeader'
import { useSelector } from 'react-redux'
import { IoIosCheckboxOutline } from 'react-icons/io'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
    faCheckCircle,
    faClock,
    faThumbsUp,
} from '@fortawesome/free-regular-svg-icons'
import StatusCell from 'components/common/StatusCell'
import ASMOrderFilter from 'components/common/ordersFilter/ASMOrderFilter'

const DistributorAllOrdersList = () => {
    const [distributorOrderList, setDistributorOrderList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [paginationPageCount, setPaginationPageCount] = useState(1)
    const [pageDataCount, setPageDataCount] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [count, setCount] = useState(0)
    const [checkedOrders, setCheckedOrders] = useState([])
    const userInfo = useSelector((state) => state?.auth?.user?.data)

    const [selectedZone, setSelectedZone] = useState('')
    const [selectedRegion, setSelectedRegion] = useState('')
    const [selectedArea, setSelectedArea] = useState('')
    const [selectedDbPoint, setSelectedDbPoint] = useState('')
    const [selectedDb, setSelectedDb] = useState(null)
    const [selectedDoNo, setSelectedDoNo] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [selectedDateFrom, setSelectedDateFrom] = useState('')
    const [selectedDateTo, setSelectedDateTo] = useState('')
    const [hoseSearch, setHoseSearch] = useState([])

    const formateExcelData = (data) => {
        const statusLookup = {
            1: 'Canceled',
            2: 'Pending',
            3: 'Forwarded by ASM',
            4: 'Forwarded by RSM',
            5: 'Forwarded by SIO',
            6: 'Forwarded by Order Management',
            7: 'Forwarded by Finance',
        }

        const formatDate = (date) => {
            return date ? new Date(date).toLocaleDateString('en-US') : ''
        }
        const formateData = data?.map((item, index) => ({
            Sl: index + 1,
            'Do No': item.doNo,
            'Zone Name': item.zone,
            'Region Name': item.region,
            'Area Name': item.area,
            'Db Point': item.dbPoint,
            'Order Qty Ctn': item.totalOrderQtyCtn,
            ' Order Qty Pcs': item.totalOrderQtyPCS,
            'Discount BDT': item.totalDiscountBDT,
            'Total Price': item.totalPrice,
            'Delivery Qty CTN': item.deliveryQtyCTN,
            'Delivery Qty Pcs': item.deliveryQtyPcs,
            Note: item.cancelNote,
            Status: statusLookup[item.status] || 'Incomplete',
            'Do Date': formatDate(item.doDate),
        }))

        return formateData
    }

    // data search
    const handleSearch = () => {
        if (
            
            selectedDbPoint &&
            !selectedDateFrom &&
            !selectedDateTo
        ) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (selectedArea && !selectedDateFrom && !selectedDateTo) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (selectedDateFrom && !selectedDateTo) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (selectedDateFrom && !selectedDateTo) {
            Swal.fire({
                title: 'Please Select Date',
                icon: 'error',
            })
            return
        } else if (selectedDoNo) {
        }

        const formatData = {
            area: selectedArea,
            dbPoint: selectedDbPoint,
            dealerId: selectedDb?.id,
            doNo: selectedDoNo,
            status: selectedStatus,
            doDateFrom: new Date(selectedDateFrom),
            doDateTo: new Date(selectedDateTo),
        }

        const fetchData = async () => {
            try {
                setIsLoading(true)
                const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/hosSearch`
                console.log('res', url)
                const response = await axios.post(url, formatData, {
                    headers: authHeader(),
                })

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.DBorder
                    setDistributorOrderList(data)
                }
            } catch (error) {
                handleApiResponseErrors(error.response)
            } finally {
                setIsLoading(false)
            }
        }

        fetchData()
    }

    return (
        <>
            <PageHeader
                title={`Orders List Of ${userInfo.zoneName},${userInfo.regionName},${userInfo.areaName}`}
                className="mb-3"
            ></PageHeader>

            <ASMOrderFilter
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                selectedZone={selectedZone}
                setSelectedZone={setSelectedZone}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                selectedArea={selectedArea}
                setSelectedArea={setSelectedArea}
                selectedDbPoint={selectedDbPoint}
                setSelectedDbPoint={setSelectedDbPoint}
                selectedDb={selectedDb}
                setSelectedDb={setSelectedDb}
                selectedDoNo={selectedDoNo}
                setSelectedDoNo={setSelectedDoNo}
                selectedDateFrom={selectedDateFrom}
                setSelectedDateFrom={setSelectedDateFrom}
                selectedDateTo={selectedDateTo}
                setSelectedDateTo={setSelectedDateTo}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                hoseSearch={hoseSearch}
                setHoseSearch={setHoseSearch}
                handleSearch={handleSearch}
            />

            <Card className="mb-3">
                <Card.Header>
                    <CustomersTableHeader
                        isNew={false}
                        isExport={true}
                        isSearch={false}
                        setSearchText={setSearchText}
                        excelFileName="ASMOrdersList"
                        excelData={formateExcelData(distributorOrderList)}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead>
                                    <tr
                                        style={{
                                            fontSize: 'medium',
                                            fontWeight: '500',
                                            fontFamily: 'Poppins',
                                            color: '#354151',
                                        }}
                                    >
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Serial No.
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DO-No
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Zone Name
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Region Name
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Area Name
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB Point
                                        </th>

                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB Name
                                        </th>

                                        <th className="align-middle white-space-nowrap text-center ">
                                            DB ID
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Order Qty Ctn
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Order Qty PCS
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Discount BDT
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Price
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Status
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Note
                                        </th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    className="list"
                                    id="table-purchase-body"
                                >
                                    {distributorOrderList?.map(
                                        (order, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    style={{
                                                        fontSize: 'medium',
                                                        fontWeight: '500',
                                                        fontFamily: 'Poppins',
                                                        color: '#354151',
                                                    }}
                                                >
                                                    <td className="align-middle text-center">
                                                        {index + 1}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.doNo}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.zone}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.region}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.area}
                                                    </td>

                                                    <td className="align-middle text-center">
                                                        {order?.dbPoint}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.distributorName}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.dealerId}
                                                    </td>

                                                    <td className="align-middle text-center">
                                                        {order?.totalOrderQtyCtn?.toLocaleString()}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.totalOrderQtyPCS?.toLocaleString()}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.totalDiscountBDT?.toLocaleString()}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.totalPrice?.toLocaleString()}
                                                    </td>

                                                    <td className="align-middle text-center">
                                                        <Flex
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            {order?.status ===
                                                                1 && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTimes
                                                                        }
                                                                        style={{
                                                                            color: 'red',
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Canceled
                                                                    </span>
                                                                </>
                                                            )}
                                                            {order?.status ===
                                                                2 && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faClock
                                                                        }
                                                                        style={{
                                                                            color: 'red',
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Pending
                                                                    </span>
                                                                </>
                                                            )}
                                                            {order?.status ===
                                                                3 && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faCheck
                                                                        }
                                                                        style={{
                                                                            color: 'green',
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Forwarded
                                                                        by ASM
                                                                    </span>
                                                                </>
                                                            )}
                                                            {order?.status ===
                                                                4 && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faCheck
                                                                        }
                                                                        style={{
                                                                            color: 'green',
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Forwarded
                                                                        by RSM
                                                                    </span>
                                                                </>
                                                            )}
                                                            {order?.status ===
                                                                5 && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faCheckCircle
                                                                        }
                                                                        style={{
                                                                            color: 'green',
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Forwarded
                                                                        by SIO
                                                                    </span>
                                                                </>
                                                            )}

                                                            {order?.status ===
                                                                6 && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faThumbsUp
                                                                        }
                                                                        style={{
                                                                            color: 'blue',
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Forwarded
                                                                        by
                                                                        Accounts
                                                                    </span>
                                                                </>
                                                            )}
                                                            {order?.status ===
                                                                7 && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faThumbsUp
                                                                        }
                                                                        style={{
                                                                            color: 'blue',
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Forwarded
                                                                        by
                                                                        Finance
                                                                    </span>
                                                                </>
                                                            )}
                                                        </Flex>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {order?.cancelNote}
                                                    </td>
                                                    <td>
                                                        <Dropdown className="d-inline mx-2">
                                                            <Dropdown.Toggle
                                                                id="dropdown-autoclose-true"
                                                                className=" bg-none"
                                                            >
                                                                ...
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item>
                                                                    <Link
                                                                        to={`/master/distributor-orders-view/${order?.doNo}`}
                                                                    >
                                                                        <IconButton
                                                                            variant="falcon-default"
                                                                            size="sm"
                                                                            icon="edit"
                                                                            transform="shrink-2"
                                                                            iconAlign="middle"
                                                                            className="me-2"
                                                                        >
                                                                            <span className="d-none d-xl-inline-block ms-1">
                                                                                View
                                                                                details
                                                                            </span>
                                                                        </IconButton>
                                                                    </Link>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default DistributorAllOrdersList
