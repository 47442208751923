import logo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import IconButton from 'components/common/IconButton'
import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { usePDF } from 'react-to-pdf'
import { useReactToPrint } from 'react-to-print'
import { authHeader } from "utils";
import {
    getDeliveriesChallanNo,
    getSingleDistributorInfo,
    getOrdersByDealerId,
} from 'services/api'
import SimpleBarReact from 'simplebar-react'

import { useQuery } from 'react-query'
import { formatNumber } from 'helpers/utils'
import { useSelector } from 'react-redux'
import handleApiResponseErrors from "helpers/handleApiResponse";
import axios from 'axios'


const TransferheaderData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setApiData] = useState([]); // all data
    const [headerData, setHeaderData] = useState({});
    const { challanNo } = useParams();
    const navigate = useNavigate()

    const { toPDF, targetRef } = usePDF({
        filename: 'challan No' + challanNo + '.pdf',
    })

    // Function to trigger the print
    const handlePrint = useReactToPrint({
        content: () => targetRef.current,
    })

    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductionRcvListDetailsTransByChallan/${challanNo}`;

                const response = await axios.get(url, { headers: authHeader() });
                if (response.data.success || response.status === 200) {
                    const responseData = response.data.payload.prdInfoDetails;
                    setHeaderData({
                        challanNo: responseData[0].challanNo,
                        fromFactory: responseData[0].fromFactory,
                        transportName: responseData[0].transportName,
                        transportNo: responseData[0].transportNo,
                        driverName: responseData[0].driverName,
                        driverPhone: responseData[0].driverPhone,
                        transferDate: responseData[0].transDate,
                        factoryName: responseData[0].factoryName,
                    })
                    setApiData(responseData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [challanNo]);

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">
                                Challan No: {headerData?.challanNo}
                            </h5>
                        </Col>

                        <Col xs="auto">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="print"
                                className="me-1 mb-2 mb-sm-0"
                                iconClassName="me-1"
                                onClick={() => handlePrint()}
                            >
                                Print
                            </IconButton>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card ref={targetRef} className="h-lg-100 overflow-hidden">
                <Card.Header>
                    <Row className="align-items-center text-center">
                        <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center text-center">
                                <div className="text-sm-start"><img src={logo} alt="invoice" width={100} /></div>
                                <div className="text-sm-start mt-3 mt-sm-0"><h3 style={{ marginLeft: '95px' }}>
                                    T.K Food Products Ltd.
                                </h3>
                                    <p className="mb-0"  >
                                        T. K. Bhaban (2nd Floor), 13, Kawranbazar
                                        Dhaka-1215, Bangladesh
                                    </p></div>
                                <div></div>
                            </div>
                        </Col>



                        <Col xs={12}>
                            <hr />
                        </Col>

                        <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center text-center">
                                <div></div>
                                <div style={{ marginLeft: '173px' }}>
                                    <h3>Delivery Challan</h3>
                                    <h5>{'Challan No: ' + headerData?.challanNo}</h5>
                                </div>
                                <div className="mb-2" style={{ marginRight: '33px', paddingBottom: '24px' }}>
                                    Date:{' '}
                                    {(() => {
                                        const date = new Date(
                                            headerData?.transferDate
                                        )
                                        const day = date.getDate()
                                        const monthNames = [
                                            'Jan',
                                            'Feb',
                                            'Mar',
                                            'Apr',
                                            'May',
                                            'Jun',
                                            'Jul',
                                            'Aug',
                                            'Sep',
                                            'Oct',
                                            'Nov',
                                            'Dec',
                                        ]
                                        const month =
                                            monthNames[date.getMonth()]
                                        const year = date.getFullYear()
                                        return `${day}-${month}-${year}`
                                    })()}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>To :</div>
                                <div style={{ marginRight: '250px' }}>
                                    From :
                                </div>
                            </div>
                            <div className="d-flex justify-content-between my-3">
                                <div>
                                    <p className="mb-0 text-start">
                                        {headerData?.factoryName}

                                    </p>
                                    {/* <p
                                        className="mb-0 text-start "
                                        style={{ fontSize: '10px' }}
                                    >
                                        Address: {headerData?.dbPoint},{' '}
                                        {headerData?.areaName},{' '}
                                        {headerData?.regionName},{' '}
                                        {headerData?.zoneName}{' '}
                                    </p> */}
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '15px' }}
                                    >
                                        {/* {'Phone: ' +
                                            singleDistributorData?.data?.payload
                                                ?.distributorData
                                                ?.phoneNumber || ''} */}
                                    </p>
                                </div>

                                <div>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '15px' }}
                                    >
                                        Factory/Depot Name :{' '}
                                        {headerData?.fromFactory}
                                    </p>

                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '15px' }}
                                    >
                                        {'Transport Name: ' +
                                            headerData?.transportName}
                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '15px' }}
                                    >
                                        {'Vehicle No: ' +
                                            headerData?.transportNo}

                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '15px' }}
                                    >
                                        {'Driver Name: ' + headerData?.driverName}
                                    </p>
                                    <p
                                        className="mb-0 text-start"
                                        style={{ fontSize: '15px' }}
                                    >
                                        {'Driver Phone: ' +
                                            headerData?.driverPhone}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body className="p-0">
                    <Table borderless className="fs--1">
                        <thead className="bg-light">
                            <tr className="text-900">
                                <th className="text-center">SL</th>
                                <th className="text-center">SKU Name</th>
                                <th className="text-center">UOM</th>
                                <th className="text-center">
                                    Total{' '}
                                    {headerData?.dbType == 'SPECIAL DISTRIBUTOR'
                                        ? 'PCS'
                                        : 'UOM'}
                                </th>
                                <th className="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {apiData.map((item, index) => {
                                let totalh = 0
                                return (
                                    <tr className="border-top border-200">
                                        <td className="text-center">
                                            {' '}
                                            {index + 1}
                                        </td>
                                        <td className="text-center fw-semi-bold">
                                            <span className="fw-bold">
                                                {item.skuName}
                                            </span>
                                        </td>
                                        <td className="text-center fw-semi-bold">
                                            <span className="fw-bold">
                                                CTN
                                            </span>
                                        </td>
                                        <td
                                            style={{ width: '150px' }}
                                            className="align-middle text-center fw-bold"
                                        >
                                            {item?.transQtyCTN}
                                        </td>

                                    </tr>
                                )
                            })}
                        </tbody>

                        <tfoot className="bg-light">
                            <tr className="text-900">

                                <th className="text-start fw-bold"></th>
                                <th className="text-start fw-bold">Total</th>
                                <th className="text-start fw-bold"></th>

                                <th className="text-center fw-bold">
                                    {apiData.reduce(
                                        (acc, item) =>
                                            acc + item.transQtyCTN,
                                        0
                                    )}
                                </th>
                                <th className="text-start fw-bold">

                                </th>
                            </tr>
                        </tfoot>
                    </Table>


                    <div className="d-flex justify-content-between px-3">
                        <p className="fw-bold">Remarks :</p>
                    </div>
                    <div className="d-flex justify-content-between px-3 mt-5 mb-3">
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            Receiver Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            Driver Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            SIC Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                            }}
                        >
                            DIC Signature
                        </p>
                        <p
                            className="fw-bold"
                            style={{
                                borderTop: '1px solid black',
                                paddingTop: '5px',
                                marginRight: '20px'
                            }}
                        >
                            HOD Signature
                        </p>
                    </div>
                </Card.Body>
                {/* <Card.Footer className="bg-light py-2">
                    <Row className="flex-between-center">
                        <Col xs="auto"></Col>
                        <Col xs="auto">
                            
                        </Col>
                    </Row>
                </Card.Footer> */}
            </Card>


            <div>
                <Button
                    variant="primary"
                    className="me-2 px-5 mt-3"
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </div>
        </>
    )
}

export default TransferheaderData;
