import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import useUserRole from "hooks/useUserRole";
import { useDropzone } from "react-dropzone";
import FalconCloseButton from "components/common/FalconCloseButton";
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { authHeaderForm } from "state/ducs/auth/utils";
import PurchaseDetails from "./PurchaseDetails";
import PurchaseConfirm from "./PurchaseConfirm";
import { formatNumber } from "helpers/utils";

const PurchasePanel = () => {
    const [step, setStep] = useState(1);
    const [purchaseData, setPurchaseData] = useState(null);
    const [purchaseList, setPurchaseList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [showUploadCSV, setShowUploadCSV] = useState(false);
    const [count, setCount] = useState(0);
    const userInfo = useSelector((state) => state.auth.user?.data);
    const userRole = useUserRole();


    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            let url = `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv/ProductPurchaseList/${userInfo?.factoryName}`;

            try {
                const response = await axios.get(
                    url,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.result;
                    const modifyData = result?.map((element, index) => ({
                        ...element,
                        index: index + 1,
                    }));

                    setPurchaseList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);



    // DELETE DISTRIBUTOR

    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleNextStep = (data) => {
        console.log(data);
        setPurchaseData(data);
        setStep(2);
    }

    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/


    /*******************
    Columns End.
    *******************/

    return (
        <>
            <PageHeader
                title="Pending Purchase List"
                className="mb-3"
            ></PageHeader>
            {
                step === 1 && (
                    <Card className="mb-3" style={{ maxWidth: "100%" }}>
                        <Card.Body className="p-0">
                            <div className="bg-white" style={{ maxWidth: "100%" }}>
                                <div className="table-responsive scrollbar">
                                    {purchaseList?.length > 0 ? (
                                        <table className="table table-bordered table-striped fs--1 mb-0">
                                            <thead>
                                                <tr
                                                    style={{
                                                        fontSize: 'medium',
                                                        fontWeight: '500',
                                                        fontFamily: 'Poppins',
                                                        color: '#354151',
                                                    }}
                                                >
                                                    <th className="align-middle white-space-nowrap text-center ">
                                                        No.
                                                    </th>
                                                    <th className="align-middle white-space-nowrap text-center ">
                                                        PO No.
                                                    </th>
                                                    <th className="align-middle white-space-nowrap text-center ">
                                                        Purchase From
                                                    </th>
                                                   
                                                    <th className="align-middle white-space-nowrap text-center ">
                                                        Purchase Date
                                                    </th>
                                                    <th className="align-middle white-space-nowrap text-center ">
                                                        Rcv Qty CTN
                                                    </th>
                                                    <th className="align-middle white-space-nowrap text-center ">
                                                        Rcv Qty PCS
                                                    </th>
                                                    <th className="align-middle white-space-nowrap text-center ">
                                                        Weight(MT)
                                                    </th>
                                                    <th className="align-middle white-space-nowrap text-center ">
                                                        Expire Date
                                                    </th>


                                                </tr>
                                            </thead>
                                            <tbody
                                                className="list"
                                                id="table-purchase-body"
                                            >
                                                {purchaseList?.map(
                                                    (order, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td className="align-middle text-center">
                                                                        {index + 1}
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        <Link to={`/inventory/purchase/${order.PONo}`}>
                                                                            {order.PONo}
                                                                        </Link>
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        {order.purchaseFrom}
                                                                    </td>
                                                                   
                                                                    <td className="align-middle text-center">
                                                                        {new Date(order.purchaseDate).toLocaleDateString()}
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        {formatNumber(order.rcvQtyCTN)}
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        {formatNumber(order.rcvQtyPcs)}
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        {formatNumber(order.rcvQtyMt)}
                                                                    </td>
                                                                    <td className="align-middle text-center">
                                                                        {new Date(order.expireDate).toLocaleDateString()}
                                                                    </td>

                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div className="text-center p-4">
                                            <h5>No Pending Found</h5>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )
            }

            {
                step === 2 && (
                    <PurchaseDetails purchaseData={purchaseData} setPurchaseData={setPurchaseData} setStep={setStep} />
                )
            }

            {
                step === 2 && (
                    <PurchaseConfirm purchaseData={purchaseData} setPurchaseData={setPurchaseData} setStep={setStep} />
                )
            }

        </>
    );

};

export default PurchasePanel;

