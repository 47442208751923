import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const RegionForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [zonesList, setZonesList] = useState([]);
    const [regionById, setRegionById] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();


    // REGION BY ID
    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}region/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success) {
                        setRegionById(response?.data?.payload.result);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);



    // ZONE 
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}zone`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.status || response.status === 200) {
                    const modifyData = response?.data?.payload?.result?.map(data => ({
                        id: data._id,
                        label: data.name,
                        value: data.name
                    }));

                    setZonesList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    const handleSubmitForm = (values, actions) => {
        const handleSubmitData = {
            zoneName: values.zoneName.value,
            regionName: values.regionName,
        };

        setIsLoading(true);
        const url = updateID
            ? `${process.env.REACT_APP_SETTINGS_BASE_URL}region/${updateID}`
            : `${process.env.REACT_APP_SETTINGS_BASE_URL}region`;

        const axiosMethod = updateID ? axios.patch : axios.post;

        axiosMethod(url, handleSubmitData, { headers: authHeader() })
            .then(response => {
                if (response.data.status || response.status === 200) {
                    navigate("/master/region");
                    toast.success(response.data.message);
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };



    isLoading && <LoadingIcon />


    const regionSchema = Yup.object().shape({
        zoneName: Yup.object().required("Please select a value for the zone field"),
        regionName: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please enter a value for the region field").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            })


    });

    console.log(regionById)

    return (
        <>
            <PageHeader
                title={updateID ? "Update Region" : "Add Region"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            zoneName: zonesList.find(item => item.value == regionById?.zoneName) || null,
                            regionName: regionById?.regionName || ''

                        }}
                        validationSchema={regionSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirst">
                                            <Form.Label>Select Zone</Form.Label>
                                            <Select
                                                placeholder="Select Zone"
                                                closeMenuOnSelect={true}
                                                options={zonesList}
                                                classNamePrefix="react-select"
                                                regionName="zoneName"
                                                value={values.zoneName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "zoneName",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                            />

                                            {
                                                errors.zoneName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.zoneName}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Region regionName</Form.Label>
                                            <Form.Control
                                                name="regionName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Region regionName"
                                                required
                                                value={values.regionName}
                                            />
                                            {touched.regionName && errors.regionName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.regionName}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/region")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default RegionForm;


