import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const OfferProductsForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [brandsList, setBrandsList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [productById, setProductById] = useState([]);
    const { updateID } = useParams();
    const navigate = useNavigate();


    const factoryList = [

        {
            label: "SORL",
            value: "SORL"
        },
        {
            label: "SVOIL",
            value: "SVOIL"
        },
        {
            label: "Narshingdi",
            value: "Narshingdi"
        },
        {
            label: "Dhamrai",
            value: "Dhamrai"
        }
    ]

    const unitsList = [
        {
            label: "Pcs",
            value: "Pcs"
        }
    ]

    const isNewProductList = [
        {
            id: true,
            label: "Yes",
            value: true
        }, {
            id: false,
            label: "No",
            value: false
        }
    ]

    const statusList = [
        {
            id: true,
            label: "Active",
            value: true

        },
        {
            id: false,
            label: "InActive",
            value: false
        }
    ]

    // GET BY ID
    useEffect(() => {
        const fetchData = async () => {
            if (updateID) {
                setIsLoading(true);
                try {
                    const url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/createOfferProduct/${updateID}`;
                    const response = await axios.get(url, { headers: authHeader() });

                    if (response.data.success || response.status === 200) {
                        setProductById(response?.data?.payload?.product);
                    }
                } catch (error) {
                    handleApiResponseErrors(error.response);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [updateID]);

    // PRODUCT BRAND
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productBrand`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.status || response.status === 200 || response.status === 201) {
                    const modifyData = response?.data?.payload?.result.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name
                    }));

                    setBrandsList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // PRODUCT CATEGORY
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const url = `${process.env.REACT_APP_SETTINGS_BASE_URL}productCategory/all/prods`;
                const response = await axios.get(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    const modifyData = response?.data?.payload?.proCatData.map(data => ({
                        id: data.id,
                        label: data.name,
                        value: data.name
                    }));

                    setCategoryList(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);



    const handleSubmitForm = (values, actions) => {
        const handleSubmitData = {
            companyName: values.companyName,
            categoryName: values.categoryName.value,
            skuName: values.skuName,
            offerPrice: values.offerPrice,
            unit: values.unit.value,
            weight: values.weight,

        };
        if (values.status) {
            handleSubmitData.status = values.status.value;
        }
        setIsLoading(true);
        const url = updateID
            ? `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/createOfferProduct/${updateID}`
            : `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orders/createOfferProduct`;
        const axiosMethod = updateID ? axios.put : axios.post;

        axiosMethod(url, handleSubmitData, { headers: authHeader() })
            .then(response => {
                if (response.data.status || response.status === 200) {
                    navigate("/master/offer-product");
                    updateID ? toast.success("Product Update Successfully") : toast.success("Product Added Successfully");
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    isLoading && <LoadingIcon />


    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required("Please select a value for the brand field"),
        categoryName: Yup.object().required("Please select a value for the category field"),
        skuName: Yup.string().required("Please enter a value for the product english name field"),
        offerPrice: Yup.number().required("Please enter a value for the product price field"),
        unit: Yup.object().required("Please select a value for the unit field"),
        weight: Yup.string().nullable(),
    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Offer Product" : "Add Offer Product"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            companyName: "Happy Time",
                            categoryName: categoryList.find(item => item.value === productById?.categoryName) || null,
                            skuName: productById?.skuName || "",
                            offerPrice: productById?.offerPrice || "",
                            unit: unitsList.find(item => item.value === productById?.unit) || null,
                            weight: productById?.weight || "",
                            status: statusList.find(status => status.value === productById?.status) || null
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            setFieldTouched

                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control
                                                name="companyName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                required
                                                value="Pusti Happy Time"
                                                defaultValue="Pusti Happy Time"
                                            />
                                            {touched.companyName && errors.companyName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.companyName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Category</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={categoryList}
                                                placeholder="Select Category"
                                                classNamePrefix="react-select"
                                                name="categoryName"
                                                value={values.categoryName}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "categoryName",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("categoryName", true, true) }}

                                            />

                                            {
                                                touched.categoryName && errors.categoryName && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.categoryName}
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Product Name (Sku)</Form.Label>
                                            <Form.Control
                                                name="skuName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Product English Name"
                                                required
                                                value={values.skuName}
                                            />
                                            {touched.skuName && errors.skuName && (
                                                <div style={{ color: "red" }}>
                                                    {errors.skuName}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Product Price</Form.Label>
                                            <Form.Control
                                                name="offerPrice"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Product Price"
                                                required
                                                value={values.offerPrice}
                                            />
                                            {touched.offerPrice && errors.offerPrice && (
                                                <div style={{ color: "red" }}>
                                                    {errors.offerPrice}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Unit</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={unitsList}
                                                placeholder="Select Unit"
                                                classNamePrefix="react-select"
                                                name="unit"
                                                value={values.unit}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "unit",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={event => { handleBlur(event); setFieldTouched("unit", true, true) }}

                                            />

                                            {
                                                touched.unit && errors.unit && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.unit}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Weight/pcs(Gram)</Form.Label>
                                            <Form.Control
                                                name="weight"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Weight/pcs"
                                              
                                                value={values.weight}
                                            />
                                            {touched.weight && errors.weight && (
                                                <div style={{ color: "red" }}>
                                                    {errors.weight}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        {updateID && <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Status</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={statusList}
                                                placeholder="Select Product Status"
                                                classNamePrefix="react-select"
                                                name="status"
                                                value={values.status}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "status",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.status && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.status}
                                                    </div>
                                                )}
                                        </Form.Group>}
                                    </Row>
                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"


                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button
                                        onClick={() => navigate("/master/offer-product")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default OfferProductsForm;


