import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";

const DistrictForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [divisionsList, setDivisionsList] = useState([]);
    const [divisionByID, setDistrictsByID] = useState({});
    const { updateID } = useParams();
    const navigate = useNavigate();


    //Get By ID
    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + `districts/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setDistrictsByID(response?.data?.data);
                    }
                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
    }, [])


    // load divisions Data
    useEffect(() => {
        setIsLoading(true)
        const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + "administrativedivisions";
        axios.get(url, { headers: authHeader() })
            .then(response => {
                if (response.status === 200) {
                    const modifyDivisions = response?.data?.data?.map(item => {
                        return {
                            id: item.id,
                            label: item.division_name,
                            value: item.id

                        }
                    })

                    setDivisionsList(modifyDivisions);
                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response);
            }).finally(
                setIsLoading(false)
            )
    }, [])



    const handleSubmitForm = (values, actions) => {

        const submitData = {
            division_id: values.division_id.value,
            district_name: values.district_name,
            status: false

        }


        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + "districts";
            axios.post(url, submitData, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/district")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + `districts/${updateID}`;
            axios.put(updateUrl, submitData, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 201) {
                        navigate("/district")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const divisionSchema = Yup.object().shape({
        division_id: Yup.object().required("Division is required"),
        district_name: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("District Name is required").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            })


    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update District" : "Add District"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{
                            division_id: divisionsList.find(item => item.id === divisionByID?.division_id) || null,
                            district_name: divisionByID?.district_name || ''

                        }}
                        validationSchema={divisionSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Administrative Division</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={divisionsList}
                                                placeholder="Select Division"
                                                classNamePrefix="react-select"
                                                name="division_id"
                                                value={values.division_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "division_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.division_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.division_id}
                                                    </div>
                                                )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>District Name</Form.Label>
                                            <Form.Control
                                                name="district_name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter District Name"
                                                required
                                                value={values.district_name}
                                            />
                                            {touched.district_name && errors.district_name && (
                                                <div style={{ color: "red" }}>
                                                    {errors.district_name}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button

                                        onClick={() => navigate("/district")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default DistrictForm;


