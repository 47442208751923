import LoginForm from 'components/authentication/LoginForm'
import React from 'react'
import image from 'assets/img/logos/tkgmis.png'
import logo from '../../../assets/img/logos/tkgl/techknowgram.fa1a6b163526beb3e5a6.png'
import pustiLogo from 'assets/img/logos/PustiLogo/pusti_logo.png'
import AuthCardLayout from 'layouts/AuthCardLayout'

const Login = () => {
    return (
        <AuthCardLayout
            leftSideContent={
                <>
                    <div style={{ marginTop: '65px' }}>
                        <a href="https://techknowgram.com/" target="_blank">
                            <img
                                src={logo}
                                style={{ width: '100%', marginTop: '10px' }}
                            ></img>
                        </a>
                    </div>
                </>
            }
        >
            <div className="ms-20">
                <a href="https://tkfoodsbd.com/" target="_blank">
                    <img
                        src={pustiLogo}
                        className="d-none d-md-block"
                        style={{
                            width: '40%',
                            marginLeft: '70%',
                            marginBottom: '35px',
                        }}
                    ></img>
                </a>
            </div>
            {/* <div style={{ display: "flex", justifyContent: "end" }}>
        <img src={pustiLogo} style={{ width: '30%', marginTop: '10px' }}></img>
      </div> */}
            <h3>User Login</h3>
            <LoginForm layout="card" userType="user" hasLabel />
        </AuthCardLayout>
    )
}

export default Login
