import request, { updateAxiosHeaders } from './request';

// Actual login service
export const getProducts = () => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.get('api/v1/products');
};

export const orderSubmit = (data) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.post('api/v1/orders/create', data);
};

export const orderUpdate = (data) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.put('api/v1/orders', data);
};

export const deleteImage = (id) => {
  request.defaults.baseURL = 'https://khoz.net:3015/';
  updateAxiosHeaders();
  return request.delete('api/v1/image/' + id);
};

export const getAllOrdersWithBankByDB = (dbId) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.get('api/v1/orderWithBank/getAllOrdersWithBankByDB/' + dbId);
};

export const getZones = () => {
  request.defaults.baseURL = 'https://khoz.net:3012/';
  updateAxiosHeaders();
  return request.get('api/v1/zone');
};

export const getRegions = () => {
  request.defaults.baseURL = 'https://khoz.net:3012/';
  updateAxiosHeaders();
  return request.get('api/v1/region');
};

export const getAreas = () => {
  request.defaults.baseURL = 'https://khoz.net:3012/';
  updateAxiosHeaders();
  return request.get('api/v1/area');
};


export const getDBPoints = () => {
  request.defaults.baseURL = 'https://khoz.net:3012/';
  updateAxiosHeaders();
  return request.get('api/v1/dbPoint');
};

export const getDistributors = () => {
  request.defaults.baseURL = 'https://khoz.net:3010/';
  updateAxiosHeaders();
  return request.get('api/v1/users/allDistributors');
};

export const getSingleDistributorInfo = (dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3010/';
  updateAxiosHeaders();
  return request.get('api/v1/users/dealer/' + dealerId);
};

export const getOrdersByDealerId = (dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.get('api/v1/orders?dealerId=' + dealerId);
};


export const getOrdersByDealerIdFactory = (data) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.post('api/v1/orders/getOrderByFactory', data);
};

export const getOrdersByDealerIdSuper = (dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.get('api/v1/orders/getSDBUnderOrdersPending/' + dealerId);
};

export const getOrdersByDealerIds = (dealerIds) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.post('api/v1/orders/moredbOrders', dealerIds);
};



export const getOrdersByDealerIdsSDB = (dealerIds) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.post('api/v1/orders/moredbOrdersSDB', dealerIds);
};

export const getSchedulesByDealerIds = (dealerIds) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.post('api/v1/orders-distribution/more-db-orders', dealerIds);
};


export const getSchedulesByFactoryDealerIds = (data) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.post('api/v1/orders-distribution/getSchedByFactory', data);
};


export const getSchedulesBySuperDBIdDealerIds = (data) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.post('api/v1/orders/getSchedBySuperDB', data);
};



export const updateSchedules = (body) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.put('api/v1/orders-distribution', body);
};

export const getAllOrders = () => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.get('api/v1/orders');
};

export const getAllOffers = () => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.get('api/v1/offers/allOffers');
};

export const getInventoryStocks = (skuName, factoryName) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/productsRcv?skuName=' + skuName + "&factoryName=" + factoryName);
};


export const getInventoryStocksSum = (skuName, factoryName) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/productsRcv/rcvDataBySkuFactory/' + factoryName + "/" + skuName);
};


export const getInventoryStocksDeliveredSumSuper = (skuName, superDBId) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery/skuWiseSuperDBdelivery/' + superDBId + "/" + skuName);
};

export const getInventoryStocksSumSuper = (skuName, superDBId) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/productsRcv/skuWiseSuperDBrcv/' + superDBId + "/" + skuName);
};


export const getInventoryStocksDeliveredSum = (skuName, factoryName) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/productsRcv/deliveryDataBySkuFactory/' + factoryName + "/" + skuName);
};

export const getInventoryStocksSuperDistributorReceived = (skuName, dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/productsRcv/skuWiseSuperDBrcv/' + dealerId + "/" + skuName);
};

export const getInventoryStocksSuperDistributorDelivered = (skuName, dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery/skuWiseSuperDBdelivery/' + dealerId + "/" + skuName);
};


export const getInventoryStocksByFactory = (factoryName) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/productsRcv?factoryName=' + factoryName);
};


export const getScheduleByDO = (doNo, skuName) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.get('api/v1/orders-distribution?doNo=' + doNo + "&skuName=" + skuName);
};

export const getScheduleDataBySkuDo = (doNo, skuName) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.get('api/v1/orders-distribution/scheduleDataBySkuDo/' + doNo + "/" + skuName);
}

export const getDeliveryDataBySkuDo = (doNo, skuName) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery/deliveryDataBySkuDo/' + doNo + "/" + skuName);
}

export const getScheduleByDOOnly = (doNo) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.get('api/v1/orders-distribution?doNo=' + doNo);
};

export const scheduleSubmit = (data) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.post('api/v1/orders-distribution', data);
};

export const getSchedulesDistributor = (dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.get('api/v1/orders-distribution?dealerId=' + dealerId);
};

export const getSchedulesByScheduleNo = (scheduleNo) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.get('api/v1/orders-distribution?scheduleNo=' + scheduleNo);
};

export const getSchedulesByScheduleNoDealerId = (scheduleNo, dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.get('api/v1/orders-distribution/scheduleByDealer/' + scheduleNo + "/" + dealerId);
};

export const orderDeliverySubmit = (data) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.post('api/v1/orderDelivery', data);
};

// export const getDeliveriesByDO = (doNo, skuName, scheduleNo) => {
export const getDeliveriesByDO = (doNo, skuName) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery?doNo=' + doNo + "&skuName=" + skuName);
};

export const getDeliveriesByDOOnly = (doNo) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery?doNo=' + doNo);
};


export const getSchedulesByFactory = (factoryName) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.get('api/v1/orders-distribution?factoryName=' + factoryName);
};

export const getAllSchedules = () => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.get('api/v1/orders-distribution');
};



export const getSchedulesByDB = (data) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.post('api/v1/orders-distribution/getSchedulesByDB', data);
};

export const getSchedulesByDBApproved = (data) => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.post('api/v1/orders-distribution/getSchedulesByDBApproved', data);
};

export const getAllSchedulesApproved = () => {
  request.defaults.baseURL = 'https://khoz.net:3014/';
  updateAxiosHeaders();
  return request.get('api/v1/orders-distribution/approvedScheduled');
};

export const getDeliveriesByFactory = (factoryName) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery?factoryName=' + factoryName);
};

export const getAllDeliveries = () => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery');
};

export const getDeliveriesScheduleNo = (scheduleNo) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery?scheduleNo=' + scheduleNo);
};

export const getDeliveriesScheduleNoDealerId = (scheduleNo, dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery/orderDeliveryByDealer/' + scheduleNo + "/" + dealerId);
};


export const getDeliveriesChallanNo = (challanNo, dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery/singleChallan/' + challanNo + "/" + dealerId);
};


export const getDeliveriesSuperDB = (superDBId) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();
  return request.get('api/v1/orderDelivery/superDBDelivery/' + superDBId);
};

// export const getDeliveriesGroupbyFactory = (factory, searchText) => {
//   request.defaults.baseURL = 'https://khoz.net:3013/';
//   updateAxiosHeaders();
//   return request.get('api/v1/orderDelivery/groupbyFactoryDelivery/' + factory ? search = searchText : '');
// };

export const getDeliveriesGroupbyFactory = (factory, searchText) => {
  request.defaults.baseURL = 'https://khoz.net:3013/';
  updateAxiosHeaders();

  // Construct the URL based on searchText
  const url = `api/v1/orderDelivery/groupbyFactoryDelivery/${factory}` + (searchText ? `?search=${searchText}` : '');

  return request.get(url);
};



export const getBalance = (dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.get(
    `api/v1/accounts/depositsByDealer/${dealerId}`,
  );
};

export const getPaymentsValueSumByDealerId = (dealerId) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.get('api/v1/orderWithBank/db/payValueSum/' + dealerId);
};

export const getPendingOrdersSumByAreaName = (areaName) => {
  request.defaults.baseURL = 'https://khoz.net:3011/';
  updateAxiosHeaders();
  return request.get('api/v1/orders/totalordercount/' + areaName);
};
