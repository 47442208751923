import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SoftBadge from 'components/common/SoftBadge'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import image from 'assets/img/icons/spot-illustrations/corner-1.png'
import Background from 'components/common/Background'
import { Link } from 'react-router-dom'
import { formatNumber } from 'helpers/utils'
const MonthlyCanceledTk = ({ data, selectedDate }) => {
    // Formtting the date   
    const month = selectedDate?.toLocaleString('default', { month: 'long' });
    const year = selectedDate?.getFullYear();

    const formattedDate = `${month} ${year}`;
    const navigate = useNavigate()

    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="warning" pill className="">
                    Monthly
                </SoftBadge>
                <h5 style={{ fontSize: '1rem' }}>Canceled DO</h5>
                <div className="display-4 fs-1 mb-2 fw-normal font-sans-serif">
                    Tk.{formatNumber(data?.cancelDoAmount) || 0}
                </div>
               <span className="fw-semi-bold fs--1 text-nowrap">{formattedDate}</span>
            </Card.Body>
        </Card>
    )
}

export default MonthlyCanceledTk
