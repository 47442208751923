import axios from 'axios';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import handleApiResponseErrors from 'helpers/handleApiResponse';
import React, { useEffect, useState } from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { authHeader } from 'utils';

const index = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SETTINGS_BASE_URL}Rcc`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const data = response?.data?.payload?.rccInfo;
                    setData(data);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <>

            <PageHeader
                title="Reports / Components Control List"
                className="mb-3"
            ></PageHeader>
            <Card className="mb-3">
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            {data?.length > 0 ? (
                                <table className="table table-bordered table-striped fs--1 mb-0">
                                    <thead >
                                        <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                No</th>
                                                <th className="align-middle white-space-nowrap text-center ">
                                                Report ID</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Control Type</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Name </th>

                                            <th className="align-middle white-space-nowrap text-center ">
                                                Assigned To</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Excluded ID</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Status</th>
                                            <th className="align-middle white-space-nowrap text-center ">
                                                Action</th>

                                        </tr>
                                    </thead>
                                    <tbody className="list" id="table-purchase-body">
                                        {
                                            data?.map((item, index) => {
                                                return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>

                                                    <td className="align-middle text-center">{index + 1}</td>
                                                    <td className="align-middle text-center">{item?.reportId}</td>
                                                    <td className="align-middle text-center">{item?.controlType}</td>
                                                    <td className="align-middle text-center">{item?.reportName}</td>
                                                    <td className="align-middle text-center">-</td>
                                                    <td className="align-middle text-center">-</td>
                                                    <td className="align-middle text-center">{item?.status === true ? "Active" : "Inactive"}</td>


                                                    <td className="align-middle text-center">
                                                        <Button onClick={() => navigate(`/report-component-control-form/${item?._id}`)}>Update</Button>
                                                    </td>
                                                </tr>

                                            })
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center p-3 fs-2">No Reports / Components Found</div>
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Card >


            {/* <Button className="mt-2 px-5" variant="primary" type="submit" disabled={checkedOrders?.length === 0} onClick={() => { handleSubmit(); }}>
                Forward to RSM
            </Button> */}
        </>
    )
}

export default index;