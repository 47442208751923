import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'

import ScheduledOrders from './ScheduledOrders'
import UnscheduledOrders from './UnscheduledOrders'
import DashboardCards from '../Common/DashboardCards/Dashboard'

const SchedulingDashboard = () => {
    const [isLoading, setIsLoading] = useState(false)
    return (
        <>
            <Row className="g-3 mb-3">
                <DashboardCards setIsLoading={setIsLoading} />
            </Row>
            <Row className="g-3 mb-3">
                <Col md={6} xxl={3}>
                    <UnscheduledOrders />
                </Col>
                <Col md={6} xxl={3}>
                    <ScheduledOrders />
                </Col>
            </Row>
        </>
    )
}

export default SchedulingDashboard
