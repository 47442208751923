import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { formatNumber } from 'helpers/utils'

const AdminOfferReceivedList = () => {
    const [receivedList, setReceivedList] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationPageCount, setPaginationPageCount] = useState(1);
    const [pageDataCount, setPageDataCount] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState(0);
 

    const formateExcelData = (data) => {
        const formatDate = (date) => {
            return date ? new Date(date).toLocaleDateString('en-US') : ''
        }

        const formateData = data?.map((item, index) => ({
            Sl: index + 1,
            'Offer Name': item.conditionName,
            'Offer Sku Name': item.offerSkuName,
            'Offer Type': item.offerType,
            'Offer Rcv Qty PCS': item.OfferRcvQty,
            'Offer Unit': item.offerUnit,
            'Offer Start Date': formatDate(item.offerStartDate),
            'Offer End Date': formatDate(item.offerEndDate),
            'Status': !item?.status ? "Pending" : "Received",
            // 'Do Date': formatDate(item.doDate),
        }))

        return formateData
    }


    // 
    useEffect(() => {
        // setIsLoading(true);
        const startIndex = (paginationPageCount - 1) * pageDataCount + 1;

        const fetchData = async () => {
            let url = `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}offers/allOffersbyadm?search=${searchText}&limit=${pageDataCount}&page=${paginationPageCount}`;

            try {
                const response = await axios.post(
                    url,
                    {},
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const totalPages = response?.data?.payload?.pagination?.totalPages;
                    const result = response?.data?.payload?.offerRcv?.map((element, index) => ({
                        ...element,
                        index: startIndex + index
                    }));

                    setTotalPages(totalPages)
                    setReceivedList(result);
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count, paginationPageCount, pageDataCount, searchText]);

    // DELETE DISTRIBUTOR

    const handleDelete = async (id) => {
        const confirmResult = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4c8118",
            cancelButtonColor: "#d33",
            confirmButtonText: "delete"
        });

        if (confirmResult.isConfirmed) {
            try {
                const url = `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/distributor/${id}`;
                const response = await axios.delete(url, { headers: authHeader() });

                if (response.data.success || response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });

                    setCount((prevCount) => prevCount + 1);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        }
    };



    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "SL",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
       
        {
            accessor: "offerSkuName",
            Header: "Offer SKU Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { offerSkuName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{offerSkuName}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        

        {
            accessor: "OfferRcvQty",
            Header: "Offer Qty",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { OfferRcvQty } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(OfferRcvQty)}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        {
            accessor: "offerUnit",
            Header: "Offer Unit",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { offerUnit } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{offerUnit}</h5>
                        </div>
                    </Flex>

                );
            },
        },

        

        {
            accessor: "factoryName",
            Header: "Factory Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { factoryName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{factoryName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        
        {
            accessor: "status",
            Header: "Status",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { status } = rowData.row.original;
                // const date = new Date(rcvDate);
                // const formattedDate = date.toLocaleDateString();
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{!status ? "Pending" : "Received"}</h5>
                        </div>
                    </Flex>

                );
            },
        }
    ];

    /*******************
    Columns End.
    *******************/



    return (
        <>
            <PageHeader
                title="Offer Product Received List (Pending)"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={receivedList}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            isNew={false}
                            isSearch={true}
                            setSearchText={setSearchText}
                            table
                            excelFileName="Offer Product Received List (Pending)"
                            excelData={formateExcelData(receivedList)}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle"
                            tableProps={{
                                size: "sm",
                                striped: true,
                                className: "fs--1 mb-0 overflow-hidden",
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>

        </>
    );

};

export default AdminOfferReceivedList;
