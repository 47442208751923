import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader'
import Flex from 'components/common/Flex'
import PageHeader from 'components/common/PageHeader'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import { formatNumber } from 'helpers/utils'
import React from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getDeliveriesByFactory, getDeliveriesGroupbyFactory } from 'services/api'

const TotalDeliveries = ({ user }) => {
    const navigate = useNavigate()
    
    const {
        data: deliveryDataByFactory,
        isLoading: isLoadingDeliveryByFactory,
        isError: deliveryByFactoryError,
        refetch: deliveryByFactoryRefetch,
    } = useQuery({
        queryKey: ['deliveryByFactory'],
        // queryFn: () => getDeliveriesByFactory(user.data.factoryName),
        queryFn: () => getDeliveriesGroupbyFactory(user.data.factoryName),
    })

    const IsShowable = (order, allDeliveries) => {
        if (!allDeliveries.length) {
            return true
        }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName &&
                    item.scheduleNo == order.scheduleNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + (obj.dbType == "SPECIAL DISTRIBUTOR" ? obj.delQtyPcs : obj.delQtyCtn)
            }, 0)

        return qtySum == (order.dbType == "SPECIAL DISTRIBUTOR" ? order.delQtyPcs : order.delQtyCtn)
    }

    const getSum = (order, allDeliveries) => {
        if (!allDeliveries.length) {
            return true
        }
        const qtySum = (allDeliveries || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo &&
                    item.skuName == order.skuName &&
                    item.scheduleNo == order.scheduleNo
            )
            .reduce((accumulator, obj) => {
                return accumulator + (obj.dbType == "SPECIAL DISTRIBUTOR" ? obj.delQtyPcs : obj.delQtyCtn)
            }, 0)

        return qtySum
    }

    let deliveredOrders = []

    if (

        deliveryDataByFactory?.data.payload.result.length
    ) {
        // const allSchedules = scheduleDataByFactory.data.payload.result
        const allDeliveries = deliveryDataByFactory.data.payload.result

        // const filteredSchedules = allSchedules.filter(
        //     (item) => !IsShowable(item, allDeliveries)
        // )
        deliveredOrders = allDeliveries.map((tot, i) => ({
            // deliveredOrders = allDeliveries.filter(item => !item.superDBId).map((tot, i) => ({
            ...tot,
            index: i + 1,
            // confiremdQtyCtn: getSum(tot, allDeliveries),
        }))
    }else{
        deliveredOrders = []
    }

    /*******************
     Columns Start.
    *******************/

    const columns = [
        {
            accessor: 'index',
            Header: 'SL',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{index}</h5>
                        </div>
                    </Flex>
                )
            },
        },
       
        {
            accessor: 'challanNo',
            Header: 'Challan No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { challanNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{challanNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'distributorName',
            Header: 'Distributor Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { distributorName, dealerId, dbType } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{distributorName} ({dealerId}) {dbType == "SPECIAL DISTRIBUTOR" ? <>(<span style={{ color: "red" }}>Special</span>)</> : ""}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'delQtyCtn',
            Header: 'Del Qty CTN',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delQtyCtn, dbType, delQtyPcs } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{dbType == "SPECIAL DISTRIBUTOR" ? delQtyPcs : delQtyCtn}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'delQtyPcs',
            Header: 'Del Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delQtyPcs } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(delQtyPcs)}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'offerQtyPCS',
            Header: 'Delivery Offer Qty PCS',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { offerQtyPCS } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(offerQtyPCS)}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'delQtyMt',
            Header: 'Weight (MT)',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { delQtyMt } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(delQtyMt)}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'transportName',
            Header: 'Transport Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { transportName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{transportName}</h5>
                        </div>
                    </Flex>
                )
            },
        },
        {
            accessor: 'transportNo',
            Header: 'Vehicle No',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { transportNo } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{transportNo}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'driverName',
            Header: 'Driver Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { driverName } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{driverName}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'driverPhone',
            Header: 'Driver Phone',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { driverPhone } = rowData.row.original
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{driverPhone}</h5>
                        </div>
                    </Flex>
                )
            },
        },


        // {
        //     accessor: 'scheduleDate',
        //     Header: 'Schedule Date',
        //     headerProps: { className: 'pe-1' },
        //     cellProps: {
        //         className: 'py-2',
        //     },
        //     Cell: (rowData) => {
        //         const { scheduleDate } = rowData.row.original
        //         const date = new Date(scheduleDate)
        //         const formattedDate = date.toLocaleDateString()
        //         return (
        //             <Flex alignItems="center">
        //                 <div className="flex-1">
        //                     <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
        //                 </div>
        //             </Flex>
        //         )
        //     },
        // },

        {
            accessor: 'deliveryDate',
            Header: 'Delivery Date',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { deliveryDate } = rowData.row.original
                const date = new Date(deliveryDate)
                const formattedDate = date.toLocaleDateString()
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formattedDate}</h5>
                        </div>
                    </Flex>
                )
            },
        },

        {
            accessor: 'action',
            Header: 'Action',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2',
            },
            Cell: (rowData) => {
                const { dealerId, challanNo } = rowData.row.original
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle
                            id="dropdown-autoclose-true"
                            className=" bg-none"
                        >
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="">
                                {' '}
                                {/* <Button
                                    variant="light"
                                    size="sm"
                                    onClick={() =>
                                        navigate(
                                            `/distribution/productdelivery/update`,
                                            {
                                                state: {
                                                    scheduleNav: rowData.row.original,
                                                },
                                            }
                                        )
                                    }
                                >
                                    Complete Delivery
                                </Button> */}
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                                {' '}
                                <Button
                                    variant="light"
                                    size="sm"
                                    // onClick={() => navigate("/distribution/productdelivery/details/" + challanNo + "/" + scheduleNo)}
                                    onClick={() => navigate("/distribution/productdelivery/details/" + challanNo + "/" + dealerId)}
                                >
                                    View Details
                                </Button>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
        },
    ]

    /*******************
    Columns End.
    *******************/
    const formatData = () => {
        const dataFormat = deliveredOrders.map((item, i) => {
            const deliveryDateFormatted = new Date(item.deliveryDate).toISOString().split('T')[0];
            return {
                SL: i + 1,
                'Challan No': item.challanNo,
                'Distributor Name':item.distributorName,
                'Del Qty Ctn': item.delQtyCtn,
                'Del Qty Pcs': item.delQtyPcs,
                'Offer Qty PCS': item.offerQtyPCS,
                'Del MT': item.delQtyMt,
                'Transport Name': item.transportName,
                'Vehicle No': item.transportNo,
                'Driver Name': item.driverName,
                'Driver Phone': item.driverPhone,
              'Delivery Date':deliveryDateFormatted,
                
            }
        })
        return dataFormat
    }
    return (
        <>
            <PageHeader
                title="Delivery List"
                className="mb-3"
            ></PageHeader>
            <AdvanceTableWrapper
                columns={columns}
                data={deliveredOrders}
                sortable
                pagination
                perPage={50}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader
                            newUrl=""
                            isSearch={true}
                            setSearchText={'setSearchText'}
                            table
                            excelFileName="Factory Delivery List"
                            excelData={formatData()}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination
                            table
                            paginationPageCount={1}
                            setPaginationPageCount={() => ({})}
                            pageDataCount={1}
                            setPageDataCount={1}
                            totalPages={1}
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    )
}

// export default TotalDeliveries

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TotalDeliveries)
