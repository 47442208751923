import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoadingIcon from "helpers/LoadingIcon";
import { PHONE_NUMBER_REGEX } from "utils/validationRegex";
import handleApiResponseErrors from "helpers/handleApiResponse";

const OutletForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoute] = useState([]);
  const [key_outlet_id, setKeyOutlet] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [outlet_type_ids, setOutletTypes] = useState([]);
  const [outletChanel, setOutletChanel] = useState([]);
  const [outletInformation, setOutletInfo] = useState([]);
  const { updateID } = useParams();
  const navigate = useNavigate();


  const shop_types = [
    {
      label: "Retail",
      value: "Retail",
    },
    {
      label: "Wholesale",
      value: "Wholesale",
    },
    {
      label: "Others",
      value: "Others",
    },
    {
      label: "All",
      value: "All",
    },
    {
      label: "N/A",
      value: "N/A",
    },
  ];


  const display_outlet = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];
  ;


  // Get All Routes
  useEffect(() => {
    setIsLoading(true)
    const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `routes`
    axios.get(url, { headers: authHeader() })
      .then(response => {
        if (response.data.success || response.data.statusCode === 200) {
          const modifyRoute = response.data.data.map((item) => {
            return {
              id: item.id,
              label: item.name,
              value: item.id,
            };
          });
          setRoute(modifyRoute);
        }
      })
      .catch(error => {
        handleApiResponseErrors(error.response)
      }).finally(
        setIsLoading(false)
      )

  }, [])



  // Get All Key Outlets
  useEffect(() => {
    setIsLoading(true)
    const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `key-outlets`
    axios.get(url, { headers: authHeader() })
      .then(response => {
        if (response.data.success || response.data.statusCode === 200) {
          const modifyKeyOutlet = response.data.data.map((item) => {
            return {
              id: item.id,
              label: item.name,
              value: item.id,
            };
          });
          setKeyOutlet(modifyKeyOutlet);
        }
      })
      .catch(error => {
        handleApiResponseErrors(error.response)
      }).finally(
        setIsLoading(false)
      )

  }, [])



  // Get All Key Outlets
  useEffect(() => {
    setIsLoading(true)
    const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `outlet-types`
    axios.get(url, { headers: authHeader() })
      .then(response => {
        if (response.data.success || response.data.statusCode === 200) {
          const modifyOutletTypes = response.data.data.map((item) => {
            return {
              id: item.id,
              label: item.name,
              value: item.id,
            };
          });
          setOutletTypes(modifyOutletTypes);
        }
      })
      .catch(error => {
        handleApiResponseErrors(error.response)
      }).finally(
        setIsLoading(false)
      )

  }, [])


  // Get All Key Outlets
  useEffect(() => {
    setIsLoading(true)
    const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `outlet-channels`
    axios.get(url, { headers: authHeader() })
      .then(response => {
        if (response.data.success || response.data.statusCode === 200) {
          const modifyOutletChanel = response.data.data.map((item) => {
            return {
              id: item.id,
              label: item.name,
              value: item.id,
            };
          });
          setOutletChanel(modifyOutletChanel);
        }
      })
      .catch(error => {
        handleApiResponseErrors(error.response)
      }).finally(
        setIsLoading(false)
      )

  }, [])

  // Load All Distributor
  useEffect(() => {
    setIsLoading(true);
    const url = process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + 'distributors';
    axios.get(url, { headers: authHeader() })
      .then(response => {

        const modifyDistributor = response.data?.map(item => {
          return {
            id: item.id,
            label: item.distributor_name,
            value: item.id

          }
        })
        setDistributors(modifyDistributor);

      })
      .catch(error => {
        handleApiResponseErrors(error.response);
      }).finally(
        setIsLoading(false)
      )
  }, [])



  // load update Outlet field value
  useEffect(() => {
    if (updateID) {
      setIsLoading(true);
      const url =
        process.env.REACT_APP_POS_MANAGEMENT_BASE_URL +
        `outlets/${updateID}`;
      axios
        .get(url, { headers: authHeader() })
        .then((response) => {

          if (response.data.success || response.data.statusCode === 200) {
            setOutletInfo(response.data.data);
          }
        })
        .catch((error) => {
          handleApiResponseErrors(error.response)
        }).finally(setIsLoading(false))
    }
  }, [updateID]);


  const handleOutletSubmit = (values, actions) => {
    const outletInformation =
    {
      route_id: values.route_id.value,
      key_outlet_id: values.key_outlet_id.value,
      outlet_type_id: values.outlet_type_id.value,
      outlet_channel_id: values.outlet_channel_id.value,
      distributor_id: values.distributor_id.value,
      name: values.name,
      address: values.address,
      contact_person: values.contact_person,
      mobile: values.mobile,
      sales_per_month: values.sales_per_month,
      market_size: values.market_size,
      display_outlet: values.display_outlet.value,
      display_outlet_amount: values.display_outlet_amount,
      paid_amount: values.paid_amount,
      credit_sales: values.credit_sales,
      shop_type: values.shop_type.value,
      comments: values.comments,
      sales_group: values.sales_group,
      status: true
    }



    if (!updateID) {
      setIsLoading(true);
      const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + "outlets";
      axios
        .post(url, outletInformation, { headers: authHeader() })
        .then((response) => {

          if (response.data.success || response.data.statusCode === 200) {
            navigate("/master/outlet");
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          handleApiResponseErrors(error.response)
        })
        .finally(
          setIsLoading(false)
        )
    } else {
      setIsLoading(true);
      const updateUrl =
        process.env.REACT_APP_POS_MANAGEMENT_BASE_URL +
        `outlets/${updateID}`;
      axios
        .put(updateUrl, outletInformation, { headers: authHeader() })
        .then((response) => {

          if (response.data.success || response.data.statusCode === 200) {
            navigate("/master/outlet");
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          handleApiResponseErrors(error.response)
        }).finally(
          setIsLoading(false)
        )
    }
  };

  isLoading && <LoadingIcon />;

  const productSchema = Yup.object().shape({
    route_id: Yup.object().required("Please enter a value for the Route field"),
    distributor_id: Yup.object().required("Please enter a value for the Distributor field"),
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Please enter a value for the Name field")
      .test(
        "starts with a number",
        "Filed input should not start with a number",
        (value) => {
          return !/^\d/.test(value);
        }
      ),
    contact_person: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter a value for the Contact Person field")
      .test(
        "starts with a number",
        "Filed input should not start with a number",
        (value) => {
          return !/^\d/.test(value);
        }
      ),
    mobile: Yup.string()
      .required("Please enter a value for the Phone Number field")
      .test("Phone Number is Valid", "Phone Number is Invalid", (value) => {
        return PHONE_NUMBER_REGEX.test(value);
      }),
    sales_per_month: Yup.number().min(1, 'Value must be greater than 0').required("Please enter a value for the Sales Per Month field"),
    key_outlet_id: Yup.object().required("Please enter a value for the Key Outlet field"),
    sales_group: Yup.string().test(
      "starts with a number",
      "Filed input should not start with a number",
      (value) => {
        return !/^\d/.test(value);
      }
    ).required("Please enter a value for the Sales Group field"),
    address: Yup.string().min(5, "Too Short!").required("Please enter a value for the Address field"),
    outlet_type_id: Yup.object().required("Please enter a value for the Outlet Type field"),
    outlet_channel_id: Yup.object().required("Please enter a value for the Outlet Channel field"),
    display_outlet: Yup.object().required("Please enter a value for the Display Outlet field"),
    paid_amount: Yup.number().min(1, 'Value must be greater than 0').required("Please enter a value for the Paid Amount field"),
    credit_sales: Yup.number().required(
      "Please enter a value for the Credit Sales field"
    ),
    display_outlet_amount: Yup.number().required(
      "Please enter a value for the Display Outlet Amount field"
    ),
    shop_type: Yup.object().required(
      "Please enter a value for the Shop Type field"
    )
  });

  return (
    <>
      <PageHeader
        title={updateID ? "Update Outlet" : "Add New Outlet"}
        className="mb-3"
      ></PageHeader>
      <FalconComponentCard>
        <FalconComponentCard.Header light={false} noPreview />
        <FalconComponentCard.Body>
          <Formik
            initialValues={{
              route_id:
                routes.find(
                  (route) => route.value === outletInformation?.route_id
                ) || null,
              distributor_id:
                distributors.find(
                  (distributor) =>
                    distributor.value ===
                    outletInformation?.distributor_id
                ) || null,
              name: outletInformation.name || "",
              contact_person: outletInformation.contact_person || null,
              mobile: outletInformation.mobile || "",
              market_size: outletInformation.market_size || "",
              sales_per_month: outletInformation.sales_per_month || 0,
              sales_group: outletInformation.sales_group || "",
              address: outletInformation.address || "",
              key_outlet_id:
                key_outlet_id.find(
                  (key_outlet_id) => key_outlet_id.value === outletInformation.key_outlet_id
                ) || null,
              outlet_type_id:
                outlet_type_ids.find(
                  (outlet_type_ids) =>
                    outlet_type_ids.value === outletInformation.outlet_type_id
                ) || null,
              outlet_channel_id:
                outletChanel.find(
                  (outletChanel) =>
                    outletChanel.value === outletInformation.outlet_channel_id
                ) || null,

              display_outlet:
                display_outlet.find(
                  (item) => item.value === outletInformation.display_outlet
                ) || null,
              display_outlet_amount: outletInformation.display_outlet_amount
                || 0,
              paid_amount: outletInformation.paid_amount || 0,
              credit_sales: outletInformation.credit_sales || 0,
              shop_type:
                shop_types.find(
                  (item) => item.value === outletInformation.shop_type
                ) || null,
              comments: outletInformation.comments || "",
            }}
            validationSchema={productSchema}
            onSubmit={handleOutletSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              resetForm,
            }) => {
              console.log("test", errors)
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                      <Form.Label>Select Route</Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        options={routes}
                        placeholder="Select Route"
                        classNamePrefix="react-select"
                        name="route_id"
                        value={values.route_id}
                        onChange={(selectedOption) => {
                          setFieldValue("route_id", selectedOption);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.route_id && (
                        <div style={{ color: "red" }}>{errors.route_id}</div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Outlet Name</Form.Label>
                      <Form.Control
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Enter Outlet Name"
                        required
                        value={values.name}
                      />
                      {touched.name && errors.name && (
                        <div style={{ color: "red" }}>{errors.name}</div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        name="address"
                        as="textarea"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Enter  Address"
                        required
                        value={values.address}
                      />
                      {touched.address && errors.address && (
                        <div style={{ color: "red" }}>{errors.address}</div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Contact Person</Form.Label>
                      <Form.Control
                        name="contact_person"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Enter Contact Person"
                        required
                        value={values.contact_person}
                      />
                      {touched.contact_person && errors.contact_person && (
                        <div style={{ color: "red" }}>
                          {errors.contact_person}
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        name="mobile"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Enter Mobile Number"
                        required
                        value={values.mobile}
                      />
                      {touched.mobile && errors.mobile && (
                        <div style={{ color: "red" }}>{errors.mobile}</div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Sales PerMonth</Form.Label>
                      <Form.Control
                        name="sales_per_month"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        placeholder="Enter Sales PerMonth"
                        required
                        value={values.sales_per_month}
                      />
                      {touched.sales_per_month && errors.sales_per_month && (
                        <div style={{ color: "red" }}>
                          {errors.sales_per_month}
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Market Size</Form.Label>
                      <Form.Control
                        name="market_size"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Enter Market Size"
                        required
                        value={values.market_size}
                      />
                      {touched.market_size && errors.market_size && (
                        <div style={{ color: "red" }}>{errors.market_size}</div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                      <Form.Label>Key Outlet</Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        options={key_outlet_id}
                        placeholder="Select Key Outlet"
                        classNamePrefix="react-select"
                        name="key_outlet_id"
                        value={values.key_outlet_id}
                        onChange={(selectedOption) => {
                          setFieldValue("key_outlet_id", selectedOption);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.key_outlet_id && (
                        <div style={{ color: "red" }}>{errors.key_outlet_id}</div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                      <Form.Label>Outlet Type</Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        options={outlet_type_ids}
                        placeholder="Select Outlet Type"
                        classNamePrefix="react-select"
                        name="outlet_type_id"
                        value={values.outlet_type_id}
                        onChange={(selectedOption) => {
                          setFieldValue("outlet_type_id", selectedOption);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.outlet_type_id && (
                        <div style={{ color: "red" }}>{errors.outlet_type_id}</div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                      <Form.Label>Outlet Channel</Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        options={outletChanel}
                        placeholder="Select Outlet Chanel"
                        classNamePrefix="react-select"
                        name="outlet_channel_id"
                        value={values.outlet_channel_id}
                        onChange={(selectedOption) => {
                          setFieldValue("outlet_channel_id", selectedOption);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.outlet_channel_id && (
                        <div style={{ color: "red" }}>
                          {errors.outlet_channel_id}
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                      <Form.Label>Display Outlet</Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        options={display_outlet}
                        placeholder="Select Display Outlet"
                        classNamePrefix="react-select"
                        name="display_outlet"
                        value={values.display_outlet}
                        onChange={(selectedOption) => {
                          setFieldValue("display_outlet", selectedOption);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.display_outlet && (
                        <div style={{ color: "red" }}>
                          {errors.display_outlet}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Display Outlet Amount</Form.Label>
                      <Form.Control
                        name="display_outlet_amount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        placeholder="Enter Outlet Amount"
                        required
                        value={values.display_outlet_amount}
                      />
                      {touched.display_outlet_amount && errors.display_outlet_amount && (
                        <div style={{ color: "red" }}>{errors.display_outlet_amount}</div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Paid Amount</Form.Label>
                      <Form.Control
                        name="paid_amount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        placeholder="Enter Paid Amount"
                        required
                        value={values.paid_amount}
                      />
                      {touched.paid_amount && errors.paid_amount && (
                        <div style={{ color: "red" }}>{errors.paid_amount}</div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Credit Sales</Form.Label>
                      <Form.Control
                        name="credit_sales"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        placeholder="Enter Credit Sales"
                        required
                        value={values.credit_sales}
                      />
                      {touched.credit_sales && errors.credit_sales && (
                        <div style={{ color: "red" }}>{errors.credit_sales}</div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="6"
                      className="mb-3"
                      controlId="formHookname"
                    >
                      <Form.Label>Shop Type</Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        options={shop_types}
                        placeholder="Select Shop Type"
                        classNamePrefix="react-select"
                        name="shop_type"
                        value={values.shop_type}
                        onChange={(selectedOption) => {
                          setFieldValue("shop_type", selectedOption);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.shop_type && (
                        <div style={{ color: "red" }}>{errors.shop_type}</div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Comments</Form.Label>
                      <Form.Control
                        name="comments"
                        as="textarea"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Enter  Comments"
                        required
                        value={values.comments}
                      />
                      {touched.comments && errors.comments && (
                        <div style={{ color: "red" }}>{errors.comments}</div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="exampleState">
                      <Form.Label>Sales Group</Form.Label>
                      <Form.Control
                        name="sales_group"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Enter Sales Group"
                        required
                        value={values.sales_group}
                      />
                      {touched.sales_group && errors.sales_group && (
                        <div style={{ color: "red" }}>{errors.sales_group}</div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="exampleFirstName">
                      <Form.Label>Distributor</Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        options={distributors}
                        placeholder="Select Distributor"
                        classNamePrefix="react-select"
                        name="distributor_id"
                        value={values.distributor_id}
                        onChange={(selectedOption) => {
                          setFieldValue("distributor_id", selectedOption);
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.distributor_id && (
                        <div style={{ color: "red" }}>
                          {errors.distributor_id}
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                  <IconButton
                    variant="primary"
                    className="me-2"
                    type="submit"

                  >
                    {updateID ? "Save" : "Submit"}
                  </IconButton>
                  <Button
                    onClick={() => navigate("/master/outlet")}
                    variant="danger" type="Cancel"
                  >
                    Cancel
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default OutletForm;
