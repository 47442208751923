import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import IconButton from "components/common/IconButton";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { authHeader } from "utils";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "utils/validationRegex";

const StorageForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [storageTypesList, setStorageTypesList] = useState([]);
    const [storageById, setStorageById] = useState({});
    const { updateID } = useParams();
    const navigate = useNavigate();


    //Storage Get By ID
    useEffect(() => {
        if (updateID) {
            setIsLoading(true)
            const url = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + `storages/${updateID}`

            axios.get(url, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        setStorageById(response?.data?.data);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )
        }
    }, [])


    // load Storage Types Data
    useEffect(() => {
        setIsLoading(true)
        const url = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + "storage-types";
        axios.get(url, { headers: authHeader() })
            .then(response => {

                if (response.data.success || response.data.statusCode === 200) {
                    const modifyStorageTypes = response?.data?.data?.map(item => {
                        return {
                            id: item.id,
                            label: item.storage_type_name,
                            value: item.id

                        }
                    })

                    setStorageTypesList(modifyStorageTypes);

                }
            })
            .catch(error => {
                handleApiResponseErrors(error.response)
            }).finally(
                setIsLoading(false)
            )
    }, [])



    const handleSubmitForm = (values, actions) => {

        const handleSubmitInfo = {
            storage_type_id: values.storage_type_id.value,
            name: values.name,
            address: values.address,
            person_in_charge: values.person_in_charge,
            email: values.email,
            telephone: values.telephone,
            mobile: values.mobile

        }

        console.log(typeof handleSubmitInfo.telephone, typeof handleSubmitInfo.mobile)

        if (!updateID) {
            setIsLoading(true);
            const url = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + "storages";
            axios.post(url, handleSubmitInfo, { headers: authHeader() })
                .then(response => {
                    console.log(response)
                    if (response.data.success || response.data.statusCode === 200) {
                        navigate("/storage")
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )


        }
        else {
            setIsLoading(true);
            const updateUrl = process.env.REACT_APP_STORE_MANAGEMENT_BASE_URL + `storages/${updateID}`;
            axios.put(updateUrl, handleSubmitInfo, { headers: authHeader() })
                .then(response => {
                    if (response.data.success || response.data.statusCode === 200) {
                        navigate("/storage");
                        toast.success(response.data.message);
                    }

                })
                .catch(error => {
                    handleApiResponseErrors(error.response)
                }).finally(
                    setIsLoading(false)
                )

        }

    }


    isLoading && <LoadingIcon />


    const handleSchema = Yup.object().shape({
        storage_type_id: Yup.object().required("Please select a value for the Storage Type field"),
        name: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please select a value for the name field").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            }),
        address: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please select a value for the name field"),
        person_in_charge: Yup.string().min(2, 'Too Short!')
            .max(50, 'Too Long!').required("Please select a value for the name field").test('starts with a number', 'Filed input should not start with a number', value => {
                return !/^\d/.test(value);
            }),
        email: Yup.string().email('Invalid Email. Please enter a valid email').matches(EMAIL_REGEX, "Invalid Email. Please enter a valid email").required('Please enter a value for the email field'),
        telephone: Yup.string().required("Please Enter a value for the Telephone field"),
        mobile: Yup.string().matches(PHONE_NUMBER_REGEX, "Invalid phone number. Please enter a valid phone number").required("Please enter a value for the mobile number field"),

    });


    return (
        <>
            <PageHeader
                title={updateID ? "Update Storage" : "Add New Storage"}
                className="mb-3"
            ></PageHeader>
            <FalconComponentCard >
                <FalconComponentCard.Header light={false} noPreview />
                <FalconComponentCard.Body>
                    <Formik
                        initialValues={{

                            storage_type_id: storageTypesList.find(item => item.id === storageById?.storage_type_id) || null,
                            name: storageById?.name || '',
                            address: storageById?.address || '',
                            person_in_charge: storageById?.person_in_charge || '',
                            email: storageById?.email || '',
                            telephone: storageById?.telephone || '',
                            mobile: storageById?.mobile || ''

                        }}
                        validationSchema={handleSchema}
                        onSubmit={handleSubmitForm}
                        enableReinitialize={true}
                    >
                        {({
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue

                        }) => {

                            return (
                                <Form onSubmit={handleSubmit}>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Owner</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={storageTypesList}
                                                placeholder="Select Owner"
                                                classNamePrefix="react-select"
                                                name="owner_id"
                                                value={values.owner_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "owner_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}
                                                isDisabled={true}

                                            />

                                            {
                                                errors.owner_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.owner_id}
                                                    </div>
                                                )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleFirstName">
                                            <Form.Label>Select Storage Type</Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                options={storageTypesList}
                                                placeholder="Select Storage Type"
                                                classNamePrefix="react-select"
                                                name="storage_type_id"
                                                value={values.storage_type_id}
                                                onChange={(selectedOption) => {
                                                    setFieldValue(
                                                        "storage_type_id",
                                                        selectedOption
                                                    );
                                                }}
                                                onBlur={handleBlur}

                                            />

                                            {
                                                errors.storage_type_id && (
                                                    <div style={{ color: "red" }}>
                                                        {errors.storage_type_id}
                                                    </div>
                                                )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Storage Name</Form.Label>
                                            <Form.Control
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Storage Name"
                                                required
                                                value={values.name}
                                            />
                                            {touched.name && errors.name && (
                                                <div style={{ color: "red" }}>
                                                    {errors.name}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                name="address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Storage Address"
                                                required
                                                value={values.address}
                                            />
                                            {touched.address && errors.address && (
                                                <div style={{ color: "red" }}>
                                                    {errors.address}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Person in Charge</Form.Label>
                                            <Form.Control
                                                name="person_in_charge"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Person in Charge"
                                                required
                                                value={values.person_in_charge}
                                            />
                                            {touched.person_in_charge && errors.person_in_charge && (
                                                <div style={{ color: "red" }}>
                                                    {errors.person_in_charge}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Email"
                                                required
                                                value={values.email}
                                            />
                                            {touched.email && errors.email && (
                                                <div style={{ color: "red" }}>
                                                    {errors.email}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Telephone</Form.Label>
                                            <Form.Control
                                                name="telephone"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Telephone Number"
                                                required
                                                value={values.telephone}
                                            />
                                            {touched.telephone && errors.telephone && (
                                                <div style={{ color: "red" }}>
                                                    {errors.telephone}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="exampleState">
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control
                                                name="mobile"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                                required
                                                value={values.mobile}
                                            />
                                            {touched.mobile && errors.mobile && (
                                                <div style={{ color: "red" }}>
                                                    {errors.mobile}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Row>

                                    <IconButton
                                        variant="primary"
                                        className="me-2"
                                        type="submit"
                                    >
                                        {updateID ? "Save" : "Submit"}
                                    </IconButton>
                                    <Button

                                        onClick={() => navigate("/storage")}
                                        variant="danger" type="Cancel"
                                    >
                                        Cancel
                                    </Button>

                                </Form>
                            );
                        }}
                    </Formik>
                </FalconComponentCard.Body>
            </FalconComponentCard >
        </>
    );
};

export default StorageForm;


