import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Dropdown
} from 'react-bootstrap';
import axios from 'axios';
import { authHeader } from 'utils';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import LoadingIcon from 'helpers/LoadingIcon';

const index = () => {
  const [NoticeData, setNoticeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [paginationPageCount, setPaginationPageCount] = useState(0);
  const [pageDataCount, setPageDataCount] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  // Load Notice 
  useEffect(() => {
    setIsLoading(true);
    const startIndex = paginationPageCount * pageDataCount + 1;

    axios
      .get(process.env.REACT_APP_BASE_URL + `notice/getPaginatedNotices?offset=${paginationPageCount}&pageSize=${pageDataCount}`, {
        headers: authHeader()
      })
      .then(response => {
        const result = [];
        if (response.status === 200) {
          setTotalPages(response.data.totalPages);

          response?.data?.content?.forEach((element, index) => {
            const addIndex = { ...element, index: startIndex + index };
            result.push(addIndex);
          });

          setNoticeData(result);
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        toast.error(error.message);
      });
  }, [count, paginationPageCount, pageDataCount]);


  // Delete Notice Item
  const handleDelete = id => {
    if (confirm('Are You Sure ?')) {
      setIsLoading(true);
      axios
        .delete(
          process.env.REACT_APP_BASE_URL + `notice/deleteNotice/${id}`,
          { headers: authHeader() }
        )
        .then(response => {
          if (response) {
            setIsLoading(false);
            toast.success('Delete Success');
            setCount(count + 1);
          }
        })
        .catch(error => {
          toast.error(error.message)
          setIsLoading(false);
        });
    }
  };


  const handleViewFile = (file) => {
    const url = process.env.REACT_APP_IMAGES_BASE_URL + file;
    window.open(url, '_blank');
  }

  if (isLoading) {
    return <LoadingIcon />;
  }

  /* 
  ..........................
  Columns Data here
  .........................
  */

  const columns = [
    {
      accessor: 'index',
      Header: 'SL',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { index } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{index}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'title',
      Header: 'Title',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { noticeTitle } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{noticeTitle}</h5>
            </div>
          </Flex>

        );
      }
    },

    {
      accessor: 'description',
      Header: 'Description',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { noticeDetails } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{noticeDetails}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'effective date',
      Header: 'Effective Date ',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { effectiveDate } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{effectiveDate}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'expiryDate',
      Header: 'Expiry date',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { expireDate } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{expireDate}</h5>
            </div>
          </Flex>

        );
      }
    },



    {
      accessor: 'action',
      Header: 'Action',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id, uploadedFile } = rowData.row.original;
        return (
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
              ...
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="">
                <Link to={`/master/officenotice/add/${id}`}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="edit"
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-2"
                  >
                    <span className="d-none d-xl-inline-block ms-1">Edit</span>
                  </IconButton>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item >
                {uploadedFile !== null && <Button
                  variant="light"
                  size="sm"
                  onClick={() => handleViewFile(uploadedFile)}
                >
                  View File
                </Button>}
              </Dropdown.Item>
              <Dropdown.Item href="">
                <IconButton
                  onClick={() => handleDelete(id)}
                  variant="falcon-default"
                  size="sm"
                  icon="trash-alt"
                  iconAlign="middle"
                  className="d-none d-sm-block me-2"
                >
                  <span className="d-none d-xl-inline-block ms-1">Delete</span>
                </IconButton>

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={NoticeData}
        sortable
        pagination
        perPage={pageDataCount}
      >
        <Card className="mb-3">
          <Card.Header>
            <CustomersTableHeader
              title="Office Notice"
              data={NoticeData}
              newUrl='/master/officenotice/add'
              isFilter={false}
              isExport={false}
              table
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default index;
