import axios from 'axios';
import CustomersTableHeader from 'components/app/e-commerce/customers/CustomersTableHeader';
import Flex from 'components/common/Flex';
import ToggleButton from 'components/common/Toggle-button/index';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Dropdown,
  Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { authHeader } from 'utils';
import { toast } from 'react-toastify';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { authHeaderForm } from 'state/ducs/auth/utils';
import LoadingIcon from 'helpers/LoadingIcon';
import NoDataFound from 'components/errors/NoDataFound';
import IconButton from 'components/common/IconButton';
import handleApiResponseErrors from 'helpers/handleApiResponse';

const PustiRoute = () => {
  const [routeData, setRouteData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showUploadCSV, setShowUploadCSV] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [count, setCount] = useState(0);
  const [paginationPageCount, setPaginationPageCount] = useState(0);
  const [pageDataCount, setPageDataCount] = useState(10);
  const [totalPages, setTotalPages] = useState(0);




  // Search Route Data
  /*   useEffect(() => {
      // setIsLoading(true);
      if (searchText) {
        const url = process.env.REACT_APP_BASE_URL + `route/getAllRoutesByFieldName?field=${searchText}`;
        axios
          .get(url, { headers: authHeader() })
          .then((response) => {
            let index = 1;
            const result = [];
            if (response?.data?.recordCount > 0) {
              response?.data?.response?.forEach((element) => {
                const addIndex = { ...element, index };
                result.push(addIndex);
                index++;
              });
              setSearchItems(result);
  
            }
            else {
              setSearchItems([]);
            }
  
          })
          .catch(error => {
            setIsLoading(false);
            toast.error(error?.message)
          })
      }
    }, [searchText]); */



  // Load All Route Data
  /*   useEffect(() => {
      setIsLoading(true);
      const startIndex = paginationPageCount * pageDataCount + 1;
  
      axios
        .get(process.env.REACT_APP_BASE_URL + `route/getPaginatedRoutes?offset=${paginationPageCount}&pageSize=${pageDataCount}`, {
          headers: authHeader()
        })
        .then(response => {
          const result = [];
          if (response.status === 200) {
            setTotalPages(response.data.totalPages);
  
            response?.data?.content?.forEach((element, index) => {
              const addIndex = { ...element, index: startIndex + index };
              result.push(addIndex);
            });
  
            setRouteData(result);
            setIsLoading(false);
          }
        })
        .catch(error => {
          setIsLoading(false);
          toast.error(error.message);
        });
    }, [count, paginationPageCount, pageDataCount]); */


  // Load All Route
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + 'routes';
        const response = await axios.get(url, { headers: authHeader() });
        const apiResponse = response.data;
        if (apiResponse.data.length === 0) {
          return setRouteData([]);
        }
        if (apiResponse.success === true || apiResponse.statusCode === 200) {
          const distributionPointIds = [];
          const distributorIds = [];
          const routeDataArray = response.data.data.map((element, index) => {
            if (element.distribution_point_id) {
              distributionPointIds.push(element.distribution_point_id);
            }
            if (element.distributor_id) {
              distributorIds.push(element.distributor_id);
            }
            index++;
            return { ...element, index };
          });

          const [distributionPointResponse, distributorResponse] = await Promise.all([
            axios.post(
              process.env.REACT_APP_AREA_MANAGEMENT_BASE_URL + 'distribution-points/selected',
              { ids: distributionPointIds },
              { headers: authHeader() }
            ),
            axios.post(
              process.env.REACT_APP_ORGANIZATION_MANAGEMENT_BASE_URL + 'bulk-distributors',
              { ids: distributorIds },
              { headers: authHeader() }
            ),
          ]);

          // Process distribution point response
          const updatedRouteData = routeDataArray.map(element => {
            const findDistributionPoint = distributionPointResponse.data.Fetchedselected.find(item => item.id === element.distribution_point_id);
            return { ...element, distributionPoint: findDistributionPoint };
          });

          // Process distributor response
          updatedRouteData.forEach(element => {
            const findDistributor = distributorResponse.data.distributors.find(item => item.id === element.distributor_id);
            element.distributor = findDistributor;
          });

          setRouteData(updatedRouteData);
        }
      } catch (error) {
        handleApiResponseErrors(error.response);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [count]);






  // csv Modal 
  const handleShowCSV = () => {
    setShowUploadCSV(true);
  };

  /* 
  ...........................
          CSV FILE UPLOAD
   ..........................
  */
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));


  const handleCSVUpload = () => {

    const csvFile = acceptedFiles[0];
    const formData = new FormData();
    formData.append("file", csvFile);

    setIsLoading(true);

    const regUrl = process.env.REACT_APP_BASE_URL + "route/csvUpload";

    axios
      .post(regUrl, formData, { headers: authHeaderForm() })
      .then((response) => {

        if (response.data) {
          setIsLoading(false);
          setShowUploadCSV(false);
          setCount((prevState) => prevState + 1);
          toast.success(response?.data?.message);
        }
      })
      .catch(error => {
        setIsLoading(false);
        toast.error(error?.message)
      })

  };

  // Handle Delete
  const handleDelete = (id) => {
    if (confirm("Are You Sure ?")) {
      setIsLoading(true);
      const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `routes/${id}`;
      axios
        .delete(url, { headers: authHeader() })
        .then((response) => {
          const apiResponse = response.data;
          if (apiResponse.success || apiResponse.statusCode === 200) {
            setCount(count + 1);
            toast.success(apiResponse.message);
          }

        })
        .catch(error => {
          handleApiResponseErrors(error.response);
        })
        .finally(
          setIsLoading(false)

        )
    }
  };


  // Handle Toggle Status

  const handleToggle = (id, newStatus) => {

    setIsLoading(true);

    const updatedData = routeData?.map(item => {
      if (item.id === id) {
        return { ...item, status: newStatus }
      }
      return item;
    })

    setRouteData(updatedData);

    const url = process.env.REACT_APP_POS_MANAGEMENT_BASE_URL + `routes/${id}`
    axios.patch(url, { status: newStatus }, { headers: authHeader() })
      .then(response => {


      })
      .catch(error => {
        handleApiResponseErrors(error.response);
      })
      .finally(
        setIsLoading(false)
      )

  }


  if (isLoading) {
    return <LoadingIcon />
  }


  /*******************
   Columns Start.
  *******************/

  const columns = [
    {
      accessor: 'index',
      Header: 'SL',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { index } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{index}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'distribution_point_name',
      Header: 'Distribution Point Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { distributionPoint } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{distributionPoint?.distribution_point_name}</h5>
            </div>
          </Flex>

        );
      }
    },

    {
      accessor: 'distributor_id',
      Header: 'Distributor Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { distributor } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{distributor?.distributor_name}</h5>
            </div>
          </Flex>

        );
      }
    },

    {
      accessor: 'name',
      Header: 'Route Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'contribution_percentage',
      Header: 'Contribution Percentage',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { contribution_percentage } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{contribution_percentage}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'can_so_add_pos',
      Header: 'Add Permission',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { can_so_add_pos } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{can_so_add_pos === 1 ? "Yes" : "No"}</h5>
            </div>
          </Flex>

        );
      }
    },
    {
      accessor: 'can_so_edit_pos',
      Header: 'Edit Permission',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { can_so_edit_pos } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{can_so_edit_pos === 1 ? "Yes" : "No"}</h5>
            </div>
          </Flex>

        );
      }
    },

    {
      accessor: 'status',
      Header: 'status',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { status, id } = rowData.row.original;
        return (

          <Flex alignItems="center">
            <div className="flex-1">
              <ToggleButton
                id={id}
                status={status}
                handleToggle={handleToggle}
              ></ToggleButton>
            </div>
          </Flex>

        );
      }
    },

    {
      accessor: 'action',
      Header: 'Action',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Dropdown className="d-inline mx-2">
            <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
              ...
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="">
                {' '}
                <Link to={`/master/route/add/${id}`}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="edit"
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-2"
                  >
                    <span className="d-none d-xl-inline-block ms-1">Edit</span>
                  </IconButton>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item href="">
                <IconButton
                  onClick={() => handleDelete(id)}
                  variant="falcon-default"
                  size="sm"
                  icon="trash-alt"
                  iconAlign="middle"
                  className="d-none d-sm-block me-2"
                >
                  <span className="d-none d-xl-inline-block ms-1">Delete</span>
                </IconButton>
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  /*******************
  Columns End.
  *******************/

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={routeData}
        sortable
      // pagination
      // perPage={pageDataCount}
      >
        <Card className="mb-3">
          <Card.Header>
            <CustomersTableHeader
              title="Route"
              buttonTitle="Upload CSV"
              handleShowCSV={handleShowCSV}
              newUrl="/master/route/add"
              isSearch={true}
              setSearchText={setSearchText}
              excelUrl="route/excelDownload"
              excelFileName="Route.xlsx"
              table
            />
          </Card.Header>
          <Card.Body className="p-0">
            {
              searchText.length > 0 && searchItems.length === 0 ? <NoDataFound /> : <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            }
          </Card.Body>
          <Card.Footer>
            {/* <AdvanceTablePagination table paginationPageCount={paginationPageCount} setPaginationPageCount={setPaginationPageCount} pageDataCount={pageDataCount} setPageDataCount={setPageDataCount} totalPages={totalPages} /> */}
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>

      <Modal
        size="lg"
        show={showUploadCSV}
        onHide={() => setShowCreateCostCenterModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Upload Route CSV File
          </Modal.Title>
          <FalconCloseButton
            onClick={() => setShowUploadCSV(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <>
            <div
              {...getRootProps({ className: "dropzone-area py-6" })}
            >
              <input {...getInputProps({ multiple: false })} />
              <Flex justifyContent="center">
                <img src={cloudUpload} alt="" width={25} className="me-2" />
                <p className="fs-0 mb-0 text-700">Drop your file here</p>
              </Flex>
            </div>
            <div className="mt-3">
              {acceptedFiles.length > 0 && (
                <>
                  <h6>File</h6>
                  <ul>{files}</ul>
                </>
              )}
            </div>
          </>

          <Button variant="primary" onClick={handleCSVUpload}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PustiRoute;
