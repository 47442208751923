import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import IconButton from "components/common/IconButton";
import handleApiResponseErrors from "helpers/handleApiResponse";
import PageHeader from "components/common/PageHeader";
import { formatNumber } from "helpers/utils";
import OrdersList from "./OrdersList";

const DuePaymentList = () => {
    const [step, setStep] = useState({
        step: 1,
        data: null
    });
    const [dueOrders, setDueOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // group data by dealer
    const groupDataByDealer = (data) => {
        const grouped = data.reduce((acc, curr) => {
            const dealerId = curr.dealerId;

            if (!acc[dealerId]) {
                acc[dealerId] = {
                    dealerName: curr.dealerName,
                    dealerId: curr.dealerId,
                    phone: curr.phone,
                    totalAmount: 0,
                    data: []
                };
            }

            acc[dealerId].totalAmount += curr.crBDT;
            acc[dealerId].data.push(curr);

            return acc;
        }, {});

        return Object.values(grouped).map((group, index) => ({ ...group, index: index + 1 }));
    };

    // fetch orders payment data from API
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const orders = response?.data?.payload?.result;
                    const creditOrders = orders?.filter(order => !order.superDBId && order.crBDT > 0);
                    console.log(creditOrders);
                    const result = groupDataByDealer(creditOrders);
                    setDueOrders(result);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    if (isLoading) {
        return <LoadingIcon />;
    }


    /*******************
     Columns Start.
    *******************/

    const columns = [

        {
            accessor: "index",
            Header: "SL",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { index } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{index}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "dealerName",
            Header: "Dealer Name",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { dealerName } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{dealerName}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "crBDT",
            Header: "Amount",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { totalAmount } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1 ">{formatNumber(totalAmount)}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "Mobile",
            Header: "Contact Number",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { phone } = rowData.row.original;
                return (

                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{phone}</h5>
                        </div>
                    </Flex>

                );
            },
        },
        {
            accessor: "action",
            Header: "Action",
            headerProps: { className: "pe-1" },
            cellProps: {
                className: "py-2",
            },
            Cell: (rowData) => {
                const { dealerId, data } = rowData.row.original;
                return (
                    <Dropdown className="d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true" className=" bg-none">
                            ...
                        </Dropdown.Toggle>



                        <Dropdown.Menu>
                            <Dropdown.Item href="">

                                {/* <Link to={`/accounts/orders/${dealerId}`} state={{ data: data }}> */}
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="edit"
                                    transform="shrink-2"
                                    iconAlign="middle"
                                    className="me-2"
                                    onClick={() => setStep({ step: 2, data: data })}

                                >
                                    <span className="d-none d-xl-inline-block ms-1">Details</span>
                                </IconButton>
                                {/* </Link> */}
                            </Dropdown.Item>
                        </Dropdown.Menu>


                    </Dropdown>
                );
            },
        },
    ];

    /*******************
    Columns End.
    *******************/

    return (
        <>
            {step.step === 1 && <>
                <PageHeader
                    title="Due Payment List"
                    className="mb-3"
                ></PageHeader>
                <AdvanceTableWrapper
                    columns={columns}
                    data={dueOrders}
                    sortable
                    pagination
                    perPage={50000}
                >
                    <Card className="mb-3">
                        <Card.Header>

                        </Card.Header>
                        <Card.Body className="p-0">
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle"
                                tableProps={{
                                    size: "sm",
                                    striped: true,
                                    className: "fs--1 mb-0 overflow-hidden",
                                }}
                            />
                        </Card.Body>
                    </Card>
                </AdvanceTableWrapper>
            </>}
            {step.step === 2 && <OrdersList setStep={setStep} data={step?.data} />}
        </>
    );

};

export default DuePaymentList;





