import InCompleteOrders from "./InCompleteOrders";
import DbTarget from "./Target/index";
const { Row, Col } = require("react-bootstrap");

const DistributorDashboard = ({ setIsLoading }) => {
    return (
        <div>

            <Row className="g-3 mb-3">
                <Col md={6} xxl={3}>
                    <InCompleteOrders setIsLoading={setIsLoading} />
                </Col>

            </Row>
            <Row className="g-3 mb-3">
                <DbTarget setIsLoading={setIsLoading} />
            </Row>
        </div>
    );
};

export default DistributorDashboard;