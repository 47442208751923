import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import GetSingleDate from "components/common/date/GetSingleDate";
import { useSelector } from "react-redux";
import Select from 'react-select'
import ScmPurchaseConfirm from "./PurchaseConfirm";



const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '180', // Set your desired width here
    }),
    control: (provided) => ({
        ...provided,
        width: '180px', // Ensure control element also respects the width
    }),
};


const ScmPurchaseCreate = () => {
    const [step, setStep] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedFactory, setSelectedFactory] = useState(null);
    const [transportData, setTransportData] = useState({
        transportName: "",
        transportNumber: "",
        driverName: "",
        driverNumber: "",
    });
    const [selectedExpiryDate, setSelecteExpitydDate] = useState(null);
    const [count, setCount] = useState(0);
    const [batchNo, setBatchNo] = useState(null);
    const [vendorsData, setVendorsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [checkedProducts, setCheckProducts] = useState([]);
    const distributorInfo = useSelector(state => state?.auth?.user?.data);


    // DATE
    const handleDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
    };

    // DATE
    const handleExpityDateChange = (e) => {
        const date = e.target.value;
        setSelecteExpitydDate(date);
    };


    const factoryList = [
        { id: 1, label: "SORL", value: "SORL" },
        { id: 2, label: "SVOIL", value: "SVOIL" },
        { id: 3, label: "Narshingdi", value: "Narshingdi" },
        { id: 4, label: "Dhamrai", value: "Dhamrai" }
    ]

    const transportList = [
        { id: 1, label: "A", value: "A" },
        { id: 2, label: "B", value: "B" },
        { id: 3, label: "C", value: "C" },
    ]


    // Add Note
    const handleAddNote = (event, product) => {
        const { value } = event.target;

        setProductsData((prevProducts) =>
            prevProducts.map(productItem =>
                productItem.skuName === product.skuName
                    ? { ...productItem, note: value }
                    : productItem
            )
        );
    }

    const handleReceivedProducts = (event, product, fieldName) => {
        const { value } = event.target;
        const calculatedPcs = value * product?.pcsCTN;
        const totalWeight = calculatedPcs * product?.weightPCS || 0;
        const calculateWeight = totalWeight / 1000000;


        setProductsData((prevProducts) =>
            prevProducts.map(productItem =>
                productItem.skuName === product.skuName
                    ? { ...productItem, ctn: Number(value), pcs: Number(calculatedPcs), weightMT: Number(calculateWeight) }
                    : productItem
            )
        );
    };


    // RECEIVED PRODUCTS
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}products`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const result = response.data.payload.products;
                    const modifyData = result.map((element, index) => ({
                        ...element,
                        index: index + 1,
                    }));
                    setProductsData(modifyData);
                }
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count]);


    // Filter products based on search term
    const filteredProducts = productsData.filter(product =>
        product.skuName.toLowerCase().includes(searchText.toLowerCase()) ||
        product.erpId.toLowerCase().includes(searchText.toLowerCase())
    );



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SETTINGS_BASE_URL}vendor`, { headers: authHeader() });
                const data = response.data.payload.vendors.map((vendor) => {
                    return { value: vendor.vendorName, label: vendor.vendorName };
                });
                setVendorsData(data);
            } catch (error) {
                handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);


    const handleNext = () => {
        if (selectedDate === null) {
            Swal.fire({
                title: "Please select a date",
                icon: "warning"
            });
            return;
        }
        if (!batchNo) {
            Swal.fire({
                title: "Please Enter Batch No.",
                icon: "warning"
            });
            return;
        }

        const checkedProducts = productsData.filter((product) => product.ctn && product.pcs);
        if (checkedProducts?.length > 0) {
            setCheckProducts(checkedProducts);
            setStep(2);

        } else {
            Swal.fire({
                title: "Please fill the quantity",
                icon: "warning"
            });
            return;
        }
    }


    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchText(value);
    };


    if (isLoading) {
        return <LoadingIcon />;
    }



    return (
        <>
            <PageHeader
                title="Product Purchase"
                className="mb-3"
            >

            </PageHeader>

            {step === 1 && <><Card className="mb-3">
                <Card.Header>

                    <div className="">

                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Search By Sku/ERP ID :</h6>
                                <input type="search" className="form-control" placeholder="Search By Sku/ERP ID" onChange={(e) => handleSearch(e)} />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase from :</h6>
                                <Select
                                    // styles={customStyles}
                                    closeMenuOnSelect={true}
                                    options={vendorsData}
                                    placeholder="Select"
                                    // isMulti
                                    classNamePrefix="react-select"
                                    value={selectedVendor}
                                    onChange={(value) =>
                                        setSelectedVendor(value)
                                    }
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase For :</h6>
                                <Select

                                    closeMenuOnSelect={true}
                                    options={factoryList}
                                    placeholder="Select"
                                    // isMulti
                                    classNamePrefix="react-select"
                                    value={selectedFactory}
                                    onChange={(value) =>
                                        setSelectedFactory(value)
                                    }
                                />
                            </Form.Group>
                        </Row  >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">PO No. :</h6>
                                <Form.Control type="text" placeholder="Enter PO No." onChange={(event) => setBatchNo(event.target.value)}
                                    value={batchNo}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Transport :</h6>
                                <Select

                                    closeMenuOnSelect={true}
                                    options={transportList}
                                    placeholder="Select Transport"
                                    // isMulti
                                    classNamePrefix="react-select"
                                    value={transportData?.transportName}
                                    onChange={(value) =>
                                        setTransportData({ ...transportData, transportName: value })
                                    }
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Vehicle No. :</h6>
                                <Form.Control type="text" placeholder="Enter vehicle No." onChange={(event) => setTransportData({ ...transportData, transportNumber: event.target.value })}
                                    value={transportData?.transportNumber}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Name:</h6>
                                <Form.Control type="text" placeholder="Enter Driver Name" onChange={(event) => setTransportData({ ...transportData, driverName: event.target.value })}
                                    value={transportData?.driverName}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Driver Number:</h6>
                                <Form.Control type="number" placeholder="Enter Driver Number" onChange={(event) => setTransportData({ ...transportData, driverNumber: event.target.value })}
                                    value={transportData?.driverNumber}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Purchase Date :</h6>
                                <input type="date" className="form-control" placeholder="" onChange={(e) => handleDateChange(e)}
                                    value={selectedDate}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4" controlId="exampleFirstName">
                                <h6 className="text-700 mb-0 text-nowrap mb-2">Expiry Date :</h6>
                                <input type="date" className="form-control" placeholder="" onChange={(e) => handleExpityDateChange(e)}
                                    value={selectedExpiryDate}
                                />
                            </Form.Group>
                        </Row>
                    </div>

                </Card.Header>
                <Card.Body className="p-0">
                    <div className="bg-white">
                        <div className="table-responsive scrollbar">
                            <table className="table table-bordered table-striped fs--1 mb-0">
                                <thead >
                                    <tr style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                        <th className="align-middle white-space-nowrap text-center " >Serial</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">
                                            Sub Category</th>
                                        <th className="align-middle white-space-nowrap text-center ">SKU Name</th>
                                        <th className="align-middle white-space-nowrap text-center ">ERP ID</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY CTN</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">QTY PCS</th>
                                        <th colSpan="1" className="align-middle white-space-nowrap text-center ">{`Weight(MT)`}</th>
                                        <th colSpan="2" className="align-middle white-space-nowrap text-center ">Note</th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-purchase-body">
                                    {
                                        filteredProducts.length ? filteredProducts?.map((product, index) => {

                                            return <tr key={index} style={{ fontSize: "medium", fontWeight: "500", fontFamily: 'Poppins', color: "#354151" }}>
                                                <td className="align-middle text-center">{index + 1}</td>
                                                <td className="align-middle text-center">{product?.categoryName}</td>
                                                <td className="align-middle text-center">{product?.subCategory}</td>
                                                <td className="align-middle text-center">{product?.skuName}</td>
                                                <td className="align-middle text-center">{product?.erpId}</td>
                                                <td className="align-middle text-center">
                                                    <Form.Control type="text" placeholder="" onChange={(event) => handleReceivedProducts(event, product, "ctn")}

                                                        value={product && product.ctn !== undefined ? product.ctn : ""}
                                                    />
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product && product.pcs !== undefined ? product.pcs : ""}
                                                        // onChange={(event) => handleReceivedProducts(event, index, product, "pcs")}
                                                        readOnly
                                                    />

                                                </td>
                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        value={product && product.weightMT !== undefined ? product.weightMT : ""}
                                                        readOnly
                                                    />

                                                </td>

                                                <td className="align-middle text-center">
                                                    <Form.Control
                                                        as={"textarea"}
                                                        type="text"
                                                        placeholder="Enter Note"
                                                        value={product && product.note !== undefined ? product.note : ""}
                                                        onChange={(event) => handleAddNote(event, product)}
                                                    />

                                                </td>


                                            </tr>

                                        }) : <tr><td colSpan="7" className="text-center"><h6>No data available</h6></td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
                <Button className="mt-2 px-5" variant="primary" type="submit" onClick={() => { handleNext(); }}>
                    Purchase
                </Button></>}

            {
                step === 2 && <ScmPurchaseConfirm setStep={setStep} transportData={transportData} vendorName={selectedVendor.value} selectedFactory={selectedFactory} selectedDate={selectedDate} selectedExpiryDate={selectedExpiryDate} batchNo={batchNo} checkedProducts={checkedProducts} setCheckProducts={setCheckProducts} setSearchText={setSearchText} setCount={setCount} />
            }

        </>
    );

};

export default ScmPurchaseCreate;
