import CustomersTableHeader from "components/app/e-commerce/customers/CustomersTableHeader";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { authHeader } from "utils";
import LoadingIcon from "helpers/LoadingIcon";
import { Link } from "react-router-dom";
import IconButton from "components/common/IconButton";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import handleApiResponseErrors from "helpers/handleApiResponse";
import Swal from "sweetalert2";
import PageHeader from "components/common/PageHeader";
import { useSelector } from "react-redux";
import { IoIosCheckboxOutline } from "react-icons/io";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import StatusCell from "components/common/StatusCell";
import { inDepthItems } from 'data/support-desk/reportsData';
import { Container, Card, Col, Form, Nav, ProgressBar, Row, Tab, Button, Dropdown, InputGroup, FormControl, Table } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import PropTypes from 'prop-types';
import FalconLink from 'components/common/FalconLink';
import FalconComponentCard from 'components/common/FalconComponentCard'
import TabContentTable from 'components/pages/AccountAndFinance/Finance/SchedulePaymentApprove/TabContentTable';
import TabContentTableDeposit from 'components/pages/AccountAndFinance/Finance/SchedulePaymentApprove/TabContentTableDeposit';
import Select from 'react-select'
import FalconCardHeader from 'components/common/FalconCardHeader'
import DatePicker from 'react-datepicker'
import { getBalance, getSingleDistributorInfo, getAllOrdersWithBankByDB } from "services/api";
import { formatNumber } from "helpers/utils";



const SchedulePaymentApprove = () => {



    const [scheduleValue, setScheduleValue] = useState(null)
    const [transactionStartDate, setTransactionStartDate] = useState(null)
    const [transactionEndDate, setTransactionEndDate] = useState(null)
    const [myStatus, setMyStatus] = useState({ value: "pending", label: 'Pending' })
    const [count, setCount] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [scheduleNoList, setScheduleNoList] = useState([])
    const [scheduleDateList, setScheduleDateList] = useState([])
    const [scheduleData, setScheduleData] = useState([]);
    const [approvableOrders, setApprovableOrders] = useState([]);
    const [selectedParties, setSelectedParties] = useState([]);

    const [customerBalances, setCustomerBalances] = useState([]);
    const [customerBalancesLoading, setCustomerBalancesLoading] = useState(false);

    const [customerInfos, setCustomerInfos] = useState([]);
    const [customerInfosLoading, setCustomerInfosLoading] = useState(false);

    const [onHandInfos, setOnHandInfos] = useState([]);
    const [onHandInfosLoading, setOnHandInfosLoading] = useState(false);


















    const statusList = [
        // { value: "approved", label: 'Approved' },
        { value: "pending", label: 'Pending' },
    ]






    const getAllSchedules = async () => {
        return await axios.get(
            `${process.env.REACT_APP_DISTRIBUTION_MANAGEMENT_BASE_URL}orders-distribution`,
            { headers: authHeader() }
        );
    }

    const callApi2 = async () => {


        try {

            const response = await getAllSchedules();


            if (response.data.success || response.status === 200) {
                const result = response?.data?.payload?.result;


                const filteredSchedules = result.filter(
                    (item) => !item.superDBId && item.status == 8
                )


                const schedules = filteredSchedules.map((tot, i) => ({
                    ...tot,
                    index: i + 1,
                    schQty: (tot.dbType == "SPECIAL DISTRIBUTOR" ? tot.delQtyPcs : tot.delQtyCtn),
                    approvedQty: (tot.dbType == "SPECIAL DISTRIBUTOR" ? tot.delQtyPcs : tot.delQtyCtn),
                    approvedQtyPcs: tot.delQtyPcs,
                }))

                const offers = result.filter(
                    (item) => item.offerNo && item.status == 8
                )

                let withOffers = [...schedules, ...offers]

                if (scheduleValue?.value) {
                    withOffers = withOffers.filter(item => item.scheduleNo == scheduleValue?.value)
                }


                if (transactionStartDate) {
                    withOffers = withOffers.filter(item => new Date(item.deliveryDate) >= new Date(transactionStartDate))
                }


                if (transactionEndDate) {
                    withOffers = withOffers.filter(item => new Date(item.deliveryDateEnd) <= new Date(transactionEndDate))
                }


                // if (myStatus?.value) {
                //     withOffers= withOffers.filter(item => item.status == myStatus?.value)
                // }


                setScheduleData(withOffers);

            }

        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        setApprovableOrders([]);
        setSelectedParties([]);
        callApi2();
    }

    useEffect(() => {

        const callApi = async () => {


            try {

                const response = await getAllSchedules();


                if (response.data.success || response.status === 200) {
                    const result = response?.data?.payload?.result;


                    const filteredSchedules = result.filter(
                        (item) => !item.superDBId && item.status == 8
                    )


                    const schedules = filteredSchedules.map((tot, i) => ({
                        ...tot,
                        index: i + 1,
                        schQty: (tot.dbType == "SPECIAL DISTRIBUTOR" ? tot.delQtyPcs : tot.delQtyCtn),
                    }))

                    const offers = result.filter(
                        (item) => item.offerNo && item.status == 8
                    )

                    const withOffers = [...schedules, ...offers]

                    // extract unique scheduleNo from withOffers
                    const uniqueScheduleNo = [...new Set(withOffers.map(item => item.scheduleNo))];
                    setScheduleNoList(uniqueScheduleNo.map(item => ({ value: item, label: item })));
                    setScheduleDateList(withOffers.map(item => ({ ...item, startDate: item.deliveryDate, endDate: item.deliveryDateEnd })));


                }

            } catch (error) {
                console.log(error);
            }
            finally {
                setIsLoading(false);
            }
        };

        callApi();

    }, [count]);


    console.log("scheduleData", scheduleData);




    // if (isLoading) {
    //     return <LoadingIcon />;
    // }

    // Handle form submission
    const handleSubmit = async () => {
        const orderItems = approvableOrders.map(item => {
            delete item.index
            delete item.schQty
            return {
                ...item,
                delQtyCtn: item.approvedQty,
                delQtyPcs: item.approvedQtyPcs,
                status: 9
            }
        });

        // return;


        // Check if there are items to update
        if (orderItems.length === 0) {
            // No items to update, show a message or return early
            return;
        }

        // Make a PUT request to update the order items
        try {
            setIsLoading(true);
            const response = await axios.put(
                `${process.env.REACT_APP_DISTRIBUTION_MANAGEMENT_BASE_URL}orders-distribution`,
                orderItems,
                { headers: authHeader() }
            );

            // Check if the update was successful
            if (response.data.status || response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Schedules have been successfully approved.",
                });
                setApprovableOrders([]);
                setScheduleData([]);
                setSelectedParties([]);
                setCustomerBalances([]);
                setCustomerInfos([]);
                setOnHandInfos([]);
                setCount((prevCount) => prevCount + 1); // Update count
                // navigate("/finance/productscheduling/approved");
            } else {
                // Handle unexpected response
                throw new Error("Unexpected response from server");
            }
        } catch (error) {
            handleApiResponseErrors(error.response);
        } finally {
            setIsLoading(false);
        }
    };


    const isChecked = (schedule) => {
        return approvableOrders.some(p => p._id === schedule._id);
    }


    const handleCheckboxChange = (schedule) => {

        if (isChecked(schedule)) {
            const ars = approvableOrders.filter(p => p._id !== schedule._id)
            setApprovableOrders(ars);
            if (ars.length === 0) {
                setSelectedParties([]);

            } else {
                // setSelectedParties(selectedParties.filter(p => p !== schedule.dealerId));

            }

        } else {
            setApprovableOrders([...approvableOrders, schedule]);
            setSelectedParties([...selectedParties, schedule.dealerId]);

            // set only the unique dealerId in selectedParties and append the new dealerId
            const uniqueDistributors = [...new Set([...selectedParties, schedule.dealerId])];
            setSelectedParties(uniqueDistributors);

        }

    };

    const handleCheckAllSchedules = () => {
        if (approvableOrders.length === scheduleData.length) {
            setApprovableOrders([]);
            setSelectedParties([]);
        } else {
            const scData = [...scheduleData]
            setApprovableOrders(scData);

            // get unique distributors(dealerId, distributorName) from scData
            const uniqueDistributors = scData.map(item => item.dealerId).filter((value, index, self) => self.indexOf(value) === index);

            setSelectedParties(uniqueDistributors);
        }
    }

    const getSingleDistributorInfo = async (dealerId) => {
        return await axios.get(
            `${process.env.REACT_APP_USER_MANAGEMENT_BASE_URL}users/dealer/${dealerId}`,
            { headers: authHeader() }
        );
    }

    const getBalance = async (dealerId) => {
        return await axios.get(
            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}accounts/depositsByDealer/${dealerId}`,
            { headers: authHeader() }
        );
    }

    const getAllOrdersWithBankByDB = async (dealerId) => {
        return await axios.get(
            `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank/getAllOrdersWithBankByDB/${dealerId}`,
            { headers: authHeader() }
        );
    }

    const getScheduleByDO = async (doNo, skuName) => {

        return await axios.get(
            `${process.env.REACT_APP_DISTRIBUTION_MANAGEMENT_BASE_URL}orders-distribution?doNo=${doNo}&skuName=${skuName}`,
            { headers: authHeader() }
        );

    };

    const getScheduleByFactory = async (factoryName, skuName) => {

        return await axios.get(
            `${process.env.REACT_APP_DISTRIBUTION_MANAGEMENT_BASE_URL}orders-distribution?factoryName=${factoryName}&skuName=${skuName}`,
            { headers: authHeader() }
        );

    };

    const getProductReceives = async (skuName, factoryName) => {

        return await axios.get(
            `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}productsRcv?skuName=${skuName}&factoryName=${factoryName}`,
            { headers: authHeader() }
        );
    };

    const getProductDeliveries = async (skuName, factoryName) => {
        return await axios.get(
            `${process.env.REACT_APP_INVENTORY_MANAGEMENT_BASE_URL}orderDelivery?skuName=${skuName}&factoryName=${factoryName}`,
            { headers: authHeader() }
        );
    };

    useEffect(() => {

        if (selectedParties.length && approvableOrders.length) {
            let balances = [];
            let customerInfos = [];
            let onHandInfos = [];
            selectedParties.forEach(element => {
                const items = approvableOrders.filter(item => item.dealerId === element)
                const partyName = items[0]?.distributorName
                const dealerId = items[0]?.dealerId
                const scheduleBalance = items.reduce((acc, item) => {
                    // const perCTNPrice = ((item.delQtyPcs / item.delQtyCtn) * item.perPCPrice) * item.delQtyCtn
                    const perCTNPrice = ((item.delQtyPcs / item.delQtyCtn) * item.perPCPrice) * item.approvedQty
                    return acc + perCTNPrice
                }, 0);

                const schQty = items.reduce((acc, item) => {
                    return acc + (item.dbType === "SPECIAL DISTRIBUTOR" ? item.delQtyPcs : item.delQtyCtn)
                }, 0);

                const approvedQty = items.reduce((acc, item) => {
                    return acc + item.approvedQty
                }, 0);

                balances.push({ dealerId: dealerId, distributorName: partyName, scheduleBalance, schQty: schQty, approvedQty: approvedQty });

            });


            customerInfos = approvableOrders
            onHandInfos = approvableOrders


            setCustomerInfosLoading(true)


            const customerInfosResponse = axios.all(customerInfos.map((item) => getScheduleByDO(item.doNo, item.skuName)))
                .then(axios.spread((...responses) => {
                    responses.forEach((response) => {
                        const { data } = response;
                        if (data?.payload?.result?.length > 0) {
                            customerInfos.map((item) => {
                                if (item.doNo === data?.payload?.result[0]?.doNo && item.skuName === data?.payload?.result[0]?.skuName) {
                                    // item.pendQty = data?.payload?.result?.filter((dat) => !dat.superDBId && dat.status === 9 && dat.doNo === item.doNo && dat.skuName === item.skuName).reduce((acc, dat) => acc + item.dbType === "SPECIAL DISTRIBUTOR" ? dat.delQtyPcs : dat.delQtyCtn, 0)
                                    item.pendQty = data?.payload?.result?.filter((dat) => !dat.superDBId && dat.status === 9 && dat.doNo === item.doNo && dat.skuName === item.skuName).reduce((acc, dat) => acc + (item.dbType === "SPECIAL DISTRIBUTOR" ? dat.delQtyPcs : dat.delQtyCtn), 0)

                                }

                            })
                        }
                    });


                    setCustomerInfos(customerInfos)

                    setCustomerInfosLoading(false)
                }))
                .catch(errors => console.log(errors)).finally(() => setCustomerInfosLoading(false))




            setOnHandInfosLoading(true)
            const onHandInfosResponse1 = axios.all(onHandInfos.map((item) => getProductReceives(item.skuName, item.factoryName)))
                .then(axios.spread((...responses) => {
                    responses.forEach((response) => {
                        const { data } = response;
                        if (data?.payload?.result?.length > 0) {
                            onHandInfos.map((item) => {
                                if (item.skuName === data?.payload?.result[0]?.skuName && item.factoryName === data?.payload?.result[0]?.factoryName) {
                                    // item.rcvQty = data?.payload?.result?.filter((dat) => dat.skuName === item.skuName && dat.factoryName === item.factoryName).reduce((acc, dat) => acc + item.dbType === "SPECIAL DISTRIBUTOR" ? dat.rcvQtyPCS : dat.rcvQtyCTN, 0)
                                    item.rcvQty = data?.payload?.result?.filter((dat) => dat.skuName === item.skuName && dat.factoryName === item.factoryName).reduce((acc, dat) => acc + (item.dbType === "SPECIAL DISTRIBUTOR" ? dat.rcvQtyPCS : dat.rcvQtyCTN), 0)

                                }


                            })
                        }
                    });

                    const onHandInfosResponse2 = axios.all(onHandInfos.map((item) => getProductDeliveries(item.skuName, item.factoryName)))
                        .then(axios.spread((...responses) => {
                            responses.forEach((response) => {
                                const { data } = response;
                                if (data?.payload?.result?.length > 0) {
                                    onHandInfos.map((item) => {
                                        if (item.skuName === data?.payload?.result[0]?.skuName && item.factoryName === data?.payload?.result[0]?.factoryName) {
                                            // item.delQty = data?.payload?.result?.filter((dat) => dat.skuName === item.skuName && dat.factoryName === item.factoryName).reduce((acc, dat) => acc + item.dbType === "SPECIAL DISTRIBUTOR" ? dat.delQtyPcs : dat.delQtyCtn, 0)
                                            item.delQty = data?.payload?.result?.filter((dat) => dat.skuName === item.skuName && dat.factoryName === item.factoryName).reduce((acc, dat) => acc + (item.dbType === "SPECIAL DISTRIBUTOR" ? dat.delQtyPcs : dat.delQtyCtn), 0)

                                        }


                                    })
                                }
                            });

                            const onHandInfosResponse3 = axios.all(onHandInfos.map((item) => getScheduleByFactory(item.factoryName, item.skuName)))
                                .then(axios.spread((...responses) => {
                                    responses.forEach((response) => {
                                        const { data } = response;
                                        if (data?.payload?.result?.length > 0) {
                                            onHandInfos.map((item) => {
                                                if (item.skuName === data?.payload?.result[0]?.skuName && item.factoryName === data?.payload?.result[0]?.factoryName) {
                                                    // item.pendingDelQty = data?.payload?.result?.filter((dat) => !dat.superDBId && dat.status === 9 && dat.skuName === item.skuName && dat.factoryName === item.factoryName).reduce((acc, dat) => acc + item.dbType === "SPECIAL DISTRIBUTOR" ? dat.delQtyPcs : dat.delQtyCtn, 0)
                                                    item.pendingDelQty = data?.payload?.result?.filter((dat) => !dat.superDBId && dat.status === 9 && dat.skuName === item.skuName && dat.factoryName === item.factoryName).reduce((acc, dat) => acc + (item.dbType === "SPECIAL DISTRIBUTOR" ? dat.delQtyPcs : dat.delQtyCtn), 0)

                                                }

                                            })
                                        }
                                    });


                                    setOnHandInfos(onHandInfos)
                                    setOnHandInfosLoading(false)
                                })).catch((error) => {
                                    console.log(error);
                                }).finally(() => {
                                    setOnHandInfosLoading(false)
                                });
                        })).catch((error) => {
                            console.log(error);
                        }).finally(() => {
                            // setOnHandInfosLoading(false)
                        });


                })).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    // setOnHandInfosLoading(false)
                });







            setCustomerBalancesLoading(true)

            const response1 = axios.all(selectedParties.map((dealerId) => getSingleDistributorInfo(dealerId)))
                .then(axios.spread((...responses) => {
                    responses.forEach((response) => {
                        const { data } = response;
                        if (data?.payload?.distributorData) {

                            balances.map((item) => {
                                if (item.dealerId === data?.payload?.distributorData?.userId) {
                                    item.creditLimit = data?.payload?.distributorData?.crBDTLimit
                                }
                            })


                        }
                    });

                    const response2 = axios.all(selectedParties.map((dealerId) => getBalance(dealerId)))
                        .then(axios.spread((...responses) => {
                            responses.forEach((response) => {
                                const { data } = response;
                                if (data?.payload?.result?.length > 0) {
                                    balances.map((item) => {
                                        if (item.dealerId === data?.payload?.result[0]?.dealerId) {
                                            const allDepositSum = data?.payload?.result?.filter(item => item.status === 2)?.reduce((a, b) => a + b.drBDT, 0);
                                            item.allDepositSum = allDepositSum
                                        }
                                    })
                                }
                            });

                            const response3 = axios.all(selectedParties.map((dealerId) => getAllOrdersWithBankByDB(dealerId)))
                                .then(axios.spread((...responses) => {
                                    responses.forEach((response) => {
                                        const { data } = response;
                                        if (data?.payload?.result?.length > 0) {
                                            balances.map((item) => {
                                                if (item.dealerId === data?.payload?.result[0]?.dealerId) {

                                                    const allExpensesSum = data?.payload?.result?.reduce((a, b) => a + b.drBDT, 0);
                                                    const creditExpense = data?.payload?.result?.reduce((a, b) => a + b.crBDT, 0);
                                                    item.allExpensesSum = allExpensesSum
                                                    item.creditExpense = creditExpense
                                                }
                                            })
                                        }
                                    });
                                }))
                                .catch((errors) => {
                                    console.log(errors);
                                }).finally(() => {
                                    setCustomerBalancesLoading(false)
                                    setCustomerBalances(balances)
                                });
                        }))
                        .catch((errors) => {
                            console.log(errors);
                        }).finally(() => {
                        });
                }))

                .catch((errors) => {
                    console.log(errors);
                }).finally(() => {

                });


        }

    }, [selectedParties.length, approvableOrders.length]);


    const qtyChangeHandler = (_id, event) => {

        let schedules = [...scheduleData];
        const findIndex = schedules.findIndex(item => item._id === _id);

        if (+event.target.value > schedules[findIndex].delQtyCtn) {
            schedules[findIndex].approvedQty = schedules[findIndex].delQtyCtn;
            schedules[findIndex].approvedQtyPcs = schedules[findIndex].delQtyPcs;
        } else if (+event.target.value < 0) {
            schedules[findIndex].approvedQty = 0;
            schedules[findIndex].approvedQtyPcs = 0;
        } else {

            schedules[findIndex].approvedQty = +event.target.value;
            schedules[findIndex].approvedQtyPcs = +event.target.value * (schedules[findIndex].delQtyPcs / schedules[findIndex].delQtyCtn);

        }

        setScheduleData(schedules)


        let approvableSchedules = [...approvableOrders];
        const findApprovableIndex = approvableSchedules.findIndex(item => item._id === _id);
        if (findApprovableIndex >= 0) {
            approvableSchedules[findApprovableIndex].approvedQty = +event.target.value
            approvableSchedules[findApprovableIndex].approvedQtyPcs = +event.target.value * (approvableSchedules[findApprovableIndex].delQtyPcs / approvableSchedules[findApprovableIndex].delQtyCtn)

            if (+event.target.value > approvableSchedules[findApprovableIndex].delQtyCtn) {
                approvableSchedules[findApprovableIndex].approvedQty = approvableSchedules[findApprovableIndex].delQtyCtn;
                approvableSchedules[findApprovableIndex].approvedQtyPcs = approvableSchedules[findApprovableIndex].delQtyPcs;
            } else if (+event.target.value < 0) {
                approvableSchedules[findApprovableIndex].approvedQty = 0;
                approvableSchedules[findApprovableIndex].approvedQtyPcs = 0;
            } else {
                approvableSchedules[findApprovableIndex].approvedQty = +event.target.value;
                schedules[findIndex].approvedQtyPcs = +event.target.value * (schedules[findIndex].delQtyPcs / schedules[findIndex].delQtyCtn);
            }

            setApprovableOrders(approvableSchedules)
        }


    }

    console.log('approvableOrders', approvableOrders);




    return (
        <>
            <PageHeader
                title={`Schedule and Payment Approve`}
                className="mb-3"
            ></PageHeader>


            <Row>
                <Col lg={9} xl={9}>

                    <Row className="g-3 mb-3">
                        <Col lg={12} xl={12}>
                            <FalconComponentCard noPreview>
                                <FalconComponentCard.Header
                                    // title="Form"
                                    light={false}
                                    noPreview
                                />
                                <FalconComponentCard.Body language="jsx">
                                    {count && (<Form
                                        noValidate
                                        // validated={validated}
                                        // onSubmit={handleSubmit}
                                        onSubmit={handleSearch}

                                    >
                                        <Row className="mb-3">


                                            <Form.Group
                                                as={Col}
                                                md="4"
                                                controlId="exampleZip"
                                            >
                                                <Form.Label>Schedule Number</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    options={scheduleNoList}
                                                    placeholder="Select Schedule Number"
                                                    // isMulti
                                                    classNamePrefix="react-select"
                                                    value={scheduleValue}
                                                    onChange={(value) => {

                                                        if (value) {
                                                            setScheduleValue(value);
                                                            const startDate = scheduleDateList.find(item => item.scheduleNo === value.value)?.startDate;
                                                            const endDate = scheduleDateList.find(item => item.scheduleNo === value.value)?.endDate;
                                                            const myStartDate = new Date(startDate);
                                                            const myEndDate = new Date(endDate);
                                                            setTransactionStartDate(myStartDate);
                                                            setTransactionEndDate(myEndDate);

                                                        }

                                                    }

                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid zip.
                                                </Form.Control.Feedback>
                                            </Form.Group>




                                            <Form.Group
                                                as={Col}
                                                md="4"
                                                controlId="exampleZip"
                                            >
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>

                                                <div className="datePickerContainer">
                                                    <DatePicker
                                                        selected={transactionStartDate}
                                                        onChange={(date) => setTransactionStartDate(date)}
                                                        formatWeekDay={(day) => day.slice(0, 3)}
                                                        className="form-control"
                                                        placeholderText="Transaction Start Date"
                                                        popperPlacement="top-start"
                                                        required

                                                    />
                                                </div>

                                            </Form.Group>

                                            <Form.Group
                                                as={Col}
                                                md="4"
                                                controlId="exampleZip"
                                            >



                                                <Form.Label>
                                                    End Date
                                                </Form.Label>

                                                <div className="datePickerContainer">
                                                    <DatePicker
                                                        selected={transactionEndDate}
                                                        onChange={(date) => setTransactionEndDate(date)}
                                                        formatWeekDay={(day) => day.slice(0, 3)}
                                                        className="form-control"
                                                        placeholderText="Transaction End Date"
                                                        // disabled={deliveryQty <= 0}
                                                        style={{ width: '100%!important' }}
                                                        popperPlacement="top-start"
                                                        required

                                                    />
                                                </div>


                                            </Form.Group>

                                            <Form.Group
                                                as={Col}
                                                md="4"
                                                controlId="exampleZip"
                                            >
                                                <Form.Label>
                                                    Status
                                                </Form.Label>

                                                <Select

                                                    closeMenuOnSelect={true}
                                                    options={statusList}
                                                    placeholder="Select Status"
                                                    classNamePrefix="react-select"
                                                    name="status"
                                                    value={myStatus}
                                                    onChange={(selectedOption) => {

                                                        setMyStatus(selectedOption)
                                                    }}

                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid zip.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>

                                        <Row className="mb-3">





                                        </Row>








                                        <Button disabled={isLoading || !scheduleValue?.value || !transactionStartDate || !transactionEndDate} type="submit">Find</Button>
                                    </Form>)}

                                </FalconComponentCard.Body>
                            </FalconComponentCard>
                        </Col>

                    </Row>

                    <Card className="mt-3">

                        <Card.Body className="p-0 overflow-hidden">
                            <SimpleBarReact>
                                <Table borderless className="mb-10 fs--1">
                                    <thead className="bg-light">
                                        <tr className="text-900">

                                            <th className="text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={scheduleData.length > 0 && approvableOrders.length === scheduleData.length}
                                                    onChange={() => handleCheckAllSchedules()}
                                                /></th>

                                            <th>Distributor Name</th>
                                            <th className="text-start">DO No</th>
                                            <th className="text-start">Schedule No</th>
                                            <th className="text-start">SKU Name</th>
                                            <th className="text-start">Quantity</th>
                                            <th className="text-start">Unit</th>
                                            <th className="text-end">Price</th>
                                            <th className="text-end">Schedule Qty</th>
                                            <th className="text-end">Approved Qty</th>
                                            <th className="text-end">Offer SkuName</th>
                                            <th className="text-end">Offer Qty</th>
                                            <th className="text-end">Offer UOM</th>
                                            <th className="text-end">Start Date</th>
                                            <th className="text-end">End Date</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {scheduleData.map((schedule) => {
                                            const perCTNPrice = (schedule.delQtyPcs / schedule.delQtyCtn) * schedule.perPCPrice
                                            return (
                                                <tr>
                                                    <td className="text-center align-middle"><input
                                                        type="checkbox"
                                                        checked={isChecked(schedule)}
                                                        onChange={() => handleCheckboxChange(schedule)}
                                                    /></td>
                                                    <td>{schedule.distributorName}</td>
                                                    <td className="text-start">{schedule.doNo}</td>
                                                    <td className="text-start">{schedule.scheduleNo}</td>
                                                    <td className="text-start">{schedule.skuName}</td>
                                                    <td className="text-end">{schedule.delQtyCtn || "--"}</td>
                                                    <td className="text-end">{schedule.offerNo ? "--" : "CTN"}</td>
                                                    <td className="text-end">{formatNumber(perCTNPrice) || "--"}</td>
                                                    <td className="text-end">{schedule.schQty || "--"}</td>
                                                    {/* <td className="text-end">{schedule.schQty}</td> */}
                                                    {schedule.offerNo ? "--" : (
                                                        <td className="text-end">
                                                            <Form.Control
                                                                style={{ width: "86px" }}
                                                                required
                                                                type="number"
                                                                placeholder="Qty"
                                                                value={schedule.approvedQty}
                                                                onChange={(event) => {
                                                                    qtyChangeHandler(schedule?._id, event)
                                                                }}
                                                            />
                                                        </td>
                                                    )}

                                                    <td className="text-start">{schedule.offerSkuName}</td>
                                                    <td className="text-end">{schedule.offerQtyPCS}</td>
                                                    <td className="text-end">{schedule.offerUnit}</td>
                                                    <td className="text-end">{schedule.deliveryDate.split('T')[0]}</td>
                                                    <td className="text-end">{schedule.deliveryDateEnd.split('T')[0]}</td>

                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </Table>

                            </SimpleBarReact>
                        </Card.Body>

                        <Card.Footer>
                            <Row>
                                <Col lg={10}></Col>
                                <Col lg={2}>
                                    <Button className="mt-2" variant="primary" type="submit" disabled={approvableOrders?.length === 0} onClick={() => { handleSubmit(); }}>
                                        Approve
                                    </Button>
                                </Col>

                            </Row>

                        </Card.Footer>


                    </Card>

                </Col>

                <Col lg={3} xl={3}>
                    <Card style={{ overflowX: 'auto' }} >
                        <FalconCardHeader
                            title={"On Hand Info"}
                            titleTag="h6"
                            className="py-2"
                            light

                        />
                        <Card.Body className="p-0">
                            <Table borderless className="mb-0 fs--1">
                                <thead className="bg-light">
                                    <tr className="text-900">

                                        <th>Item</th>
                                        <th>Sub Inv</th>
                                        <th>Ohh</th>
                                        <th>Pending</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {onHandInfosLoading ? <tr><td colSpan={7}>Loading...</td></tr> : null}
                                    {(selectedParties.length > 0 && onHandInfos.length > 0) ? onHandInfos.map((onHandInfo) => {
                                        return (<tr>
                                            <td>{onHandInfo.skuName}</td>
                                            <td>{onHandInfo.factoryName}</td>
                                            <td>{((onHandInfo.rcvQty || 0) - (onHandInfo.delQty || 0))}</td>
                                            <td>{(onHandInfo.pendingDelQty || 0) - (onHandInfo.delQty || 0)}</td>
                                        </tr>)
                                    }) : null}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card style={{ overflowX: 'auto' }} className={"my-3"}>
                        <FalconCardHeader
                            title={"Customer Info"}
                            titleTag="h6"
                            className="py-2"
                            light

                        />
                        <Card.Body className="p-0">
                            <Table borderless className="mb-0 fs--1">
                                <thead className="bg-light">
                                    <tr className="text-900">
                                        <th>Party Name</th>
                                        <th>Item</th>
                                        <th>Und Qty</th>
                                        <th>Pend Qty</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {customerInfosLoading ? <tr><td colSpan={7}>Loading...</td></tr> : null}

                                    {selectedParties.length > 0 && customerInfos.length > 0 ? customerInfos.map((party) => (
                                        <tr className="border-top border-200">

                                            <td className="align-middle text-start fw-semi-bold">{party.distributorName}</td>
                                            <td className="align-middle text-start fw-semi-bold">
                                                {party.skuName}
                                            </td>

                                            <td className="align-middle text-end fw-semi-bold">
                                                {party.schQty}
                                            </td>

                                            <td className="align-middle text-end fw-semi-bold">
                                                {party.pendQty}
                                            </td>
                                        </tr>
                                    )) : null}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card style={{ overflowX: 'auto' }} >
                        <FalconCardHeader
                            title={"Customer Balance"}
                            titleTag="h6"
                            className="py-2"
                            light

                        />
                        <Card.Body className="p-0">
                            <Table borderless className="mb-0 fs--1">
                                <thead className="bg-light">
                                    <tr className="text-900">

                                        <th>Party Name</th>
                                        <th>Schedule Balance</th>
                                        <th>Balance</th>
                                        <th>Net Receivable</th>
                                        <th>Credit Limit</th>
                                        <th>Schedule Qty</th>
                                        <th>Pending Balance</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {customerBalancesLoading ? <tr><td colSpan={7}>Loading...</td></tr> : null}
                                    {(selectedParties.length > 0 && customerBalances.length > 0 && !customerBalancesLoading) ? customerBalances.map((party) => (
                                        <tr className="border-top border-200">

                                            <td className="align-middle text-start fw-semi-bold">{party.distributorName}</td>
                                            <td className="align-middle text-start fw-semi-bold">
                                                {formatNumber(party.scheduleBalance)}
                                            </td>
                                            <td>{formatNumber(party.allDepositSum - party.allExpensesSum)}</td>
                                            <td>{party.creditExpense}</td>
                                            <td>{party.creditLimit}</td>
                                            {/* <td>{party.schQty}</td> */}
                                            <td>{party.approvedQty}</td>
                                            <td>{onHandInfos?.length && onHandInfos.reduce((a, b) => {
                                                const perCTNPrice = ((b.delQtyPcs / b.delQtyCtn) * b.perPCPrice) * b.delQtyCtn
                                                const quantity = (b.pendingDelQty || 0) - (b.delQty || 0)
                                                return a + (quantity * perCTNPrice)
                                            }, 0)}</td>
                                        </tr>
                                    )) : null}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>





        </>
    );

};

export default SchedulePaymentApprove;