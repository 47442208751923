import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import React from 'react'
import { Card } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllOrders, getAllSchedules } from 'services/api'
import image from 'assets/img/icons/spot-illustrations/corner-2.png'
import Background from 'components/common/Background'
import SoftBadge from 'components/common/SoftBadge'
const UnscheduledOrders = ({ user }) => {
    const {
        data: scheduleData,
        isLoading: isLoadingSchedule,
        isError: scheduleError,
        refetch: scheduleRefetch,
    } = useQuery({
        queryKey: ['allSchedules'],
        queryFn: () => getAllSchedules(),
    })

    const {
        data: orderData,
        isLoading: isLoadingOrders,
        isError: ordersError,
        refetch: ordersRefetch,
    } = useQuery({
        queryKey: ['allOrders'],
        queryFn: () => getAllOrders(),
    })

    const IsShowable = (order, allScheduleData) => {
        let show = true
        const qtySum = (allScheduleData || [])
            .filter(
                (item) =>
                    item.doNo == order.doNo && item.skuName == order.skuName
            )
            .reduce((accumulator, obj) => {
                return (
                    accumulator +
                    (obj.dbType == 'SPECIAL DISTRIBUTOR'
                        ? obj.delQtyPcs
                        : obj.delQtyCtn)
                )
            }, 0)
        const items = (allScheduleData || []).filter(
            (item) => item.doNo == order.doNo && item.skuName == order.skuName
        )

        // for (let i = 0; i < items.length; i++) {
        //     const element = items[i]
        //     if (element.status == 7) {
        //         show = false
        //         break
        //     }
        // }

        return (
            show &&
            qtySum ==
                (order.dbType == 'SPECIAL DISTRIBUTOR'
                    ? order.totalOrderQtyPCS
                    : order.orderQtyCtn)
        )
    }

    let unscheduledOrders = 0

    if (
        orderData?.data.payload.result.length &&
        scheduleData?.data.payload.result
    ) {
        const allOrders = orderData.data.payload.result
        const allSchedules = scheduleData.data.payload.result
        const filteredSchedules = allOrders.filter(
            (item) =>
                !item.superDBId &&
                item.status == 7 &&
                !IsShowable(item, allSchedules)
        )
        unscheduledOrders = filteredSchedules.length
    }

    return (
        <Card className="overflow-hidden">
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="info" pill className="">
                    Unscheduled Orders
                </SoftBadge>
                <h5 style={{ fontSize: '1rem' }} className="mt-3">
                    Total: {unscheduledOrders}
                </h5>
                <div>
                    <h2
                        className="fw-normal mb-1 lh-1"
                        style={{ fontSize: '1rem' }}
                    ></h2>
                    <Link
                        to={'distribution/productscheduling/pending'}
                        className="fw-semi-bold fs--1 text-nowrap"
                    >
                        {'See all'}
                        <FontAwesomeIcon
                            icon="angle-right"
                            className="ms-1"
                            transform="down-1"
                        />
                    </Link>
                </div>
                <FontAwesomeIcon icon="clock" className="ms-1" size="xl" />
            </Card.Body>
        </Card>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UnscheduledOrders)
