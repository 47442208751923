import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import BasicECharts from 'components/common/BasicEChart';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { set } from 'date-fns';
import { BarChart } from 'echarts/charts';
import {
    GridComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import handleApiResponseErrors from 'helpers/handleApiResponse';
import { formatNumber, getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import image from 'assets/img/icons/spot-illustrations/corner-2.png';
import Background from 'components/common/Background';
import { Link } from 'react-router-dom';
import LoadingIcon from 'helpers/LoadingIcon';
import { authHeader } from 'utils';
import axios from 'axios';

const DueOrdersAmount = ({ setIsLoading }) => {
    const [creditAmount, setCreditAmount] = useState(0);
    const [creditOrdersQuantity, setCreditOrdersQuantity] = useState(0);
    const navigate = useNavigate();

    // fetch orders payment data from API
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_ORDER_MANAGEMENT_BASE_URL}orderWithBank`,
                    { headers: authHeader() }
                );

                if (response.data.success || response.status === 200) {
                    const orders = response?.data?.payload?.result;

                    const { totalAmount, count } = orders.reduce((acc, order) => {
                        if (order.crBDT > 0) {
                            acc.totalAmount += order.crBDT;
                            acc.count += 1;
                        }
                        return acc;
                    }, { totalAmount: 0, count: 0 });

                    setCreditAmount(totalAmount);
                    setCreditOrdersQuantity(count);
                }
            } catch (error) {
                // handleApiResponseErrors(error.response);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <Card className='overflow-hidden'  >
            <Background image={image} className="bg-card" />
            <Card.Body className="position-relative">
                <SoftBadge bg="info" pill className="">
                    Due
                </SoftBadge>
                <h5>

                    Due Orders Payment

                </h5>
                <div
                    className=
                    ' display-4 fs-4 mb-2 fw-normal font-sans-serif'

                >
                    {formatNumber(creditAmount) || 0}
                </div>
                <Link to="/accounts/due-payment-list" className="fw-semi-bold fs--1 text-nowrap">
                    See Details
                    <FontAwesomeIcon
                        icon="angle-right"
                        className="ms-1"
                        transform="down-1"
                    />
                </Link>
            </Card.Body>
        </Card>
    );
};


export default DueOrdersAmount;
