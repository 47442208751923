import logo from 'assets/img/logos/PustiLogo/pusti_logo.png';
import axios from 'axios';
import IconButton from 'components/common/IconButton';
import handleApiResponseErrors from 'helpers/handleApiResponse';
import React, { useEffect } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import { authHeader } from 'utils';
import { usePDF } from 'react-to-pdf';
import StatusCell from 'components/common/StatusCell';
import useUserRole from 'hooks/useUserRole';
import { formatNumber } from 'helpers/utils';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import LoadingIcon from "helpers/LoadingIcon";

const Index = () => {
    const { toPDF, targetRef } = usePDF({ filename: "DB Reconciliation" });
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.data?.data;
    const getDate = location.state?.data;
    const { distributorName, userRole, address, areaName, zoneName, regionName, dbPoint } = data?.distributor || {};

    let accountPayable = 0;
    let accountReceivable = 0;
    let avialableCreditLimit = 0;

    // Account Payable and Account Receivable
    if (data?.totalDepositApproved > data?.totalExpense) {
        accountPayable = data?.totalDepositApproved - data?.totalExpense;
    } else if (data?.totalExpense > data?.totalDepositApproved) {
        accountReceivable = data?.totalExpense - data?.totalDepositApproved;
    }
    // Credit Limit Avialable
    if (data?.distributor?.crBDTLimit) {
        avialableCreditLimit = data?.distributor?.crBDTLimit - data?.totalExpense;
    }

    return (
        <div >
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-end align-items-center">
                        <Col xs="auto">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="arrow-down"
                                className="me-1 mb-2 mb-sm-0"
                                iconClassName="me-1"
                                onClick={() => toPDF()}
                            >
                                Download (.pdf)
                            </IconButton>

                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="mb-3" ref={targetRef}>
                <Card.Body>
                    {<Row className="align-items-center text-center mb-3">
                        <Col sm={6} className="text-sm-start">
                            <img src={logo} alt="invoice" width={150} />
                        </Col>
                        <Col className="text-sm-end mt-3 mt-sm-0">
                            <h5 className="">{distributorName}</h5>
                            <h5>{userRole}</h5>
                            <h6>Address: {`${zoneName}, ${regionName}, ${areaName}, ${dbPoint}`}</h6>
                            <h6>Local Address: {address}</h6>
                        </Col>
                        <Col xs={12}>
                        {getDate?.selectedToDate && getDate?.selectedFromDate && <h5 className='text-center'>Date: {`${new Date(getDate?.selectedToDate).toLocaleDateString()} - ${new Date(getDate?.selectedFromDate).toLocaleDateString()}`}</h5>}
                            <hr />
                        </Col>
                    </Row>}
                    <Row className="g-0">
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4'>
                            <h6 className="pb-1 text-700 fs-1" >{`Total Demand Order No.(Without Approval)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{data.totalDemandOrder}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Demand Order No.(Approved)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{data.totalApprovedDemandOrder}</p>
                            {/* <div className="d-flex align-items-center">
                                <h6 className="fs--1 text-500 mb-0">{stat.subAmount}</h6>
                                <h6
                                    // className={classNames('fs--2 ps-3 mb-0', {
                                    //     'text-success': stat.type === 'up',
                                    //     'text-danger': stat.type === 'down',
                                    //     'text-primary': stat.type === 'standard',
                                    //     'text-warning': stat.type === 'warning',
                                    //     'text-info': stat.type === 'pending'
                                    // })}
                                >
                                    <FontAwesomeIcon icon="caret-up" className="me-1" />
                                    {stat.percent}%
                                </h6>
                            </div> */}
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Demand Order TK.(Without Approved)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalDemandOrderTk)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Demand Order TK.(Approved)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalApprovedDemandOrderTk)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Deposit(Pending)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalDepositPending)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">{`Total Deposit(Approved)`}</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalDepositApproved)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">Total Expense</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalExpense)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">Account Payable</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(accountPayable)}</p>
                        </Col>
                        <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                            <h6 className="pb-1 text-700 fs-1">Account Receivable</h6>
                            <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(accountReceivable)}</p>
                        </Col>

                        {data?.distributor?.crBDTLimit ? <>
                            <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                                <h6 className="pb-1 text-700 fs-1">Credit Limit</h6>
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.distributor?.crBDTLimit)}</p>
                            </Col>

                            <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                                <h6 className="pb-1 text-700 fs-1">Credit Limit Expense</h6>
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(data?.totalExpense)}</p>
                            </Col>
                            <Col xs={6} md={4} className='text-center border-300 border-bottom border-end pb-4 mt-3'>
                                <h6 className="pb-1 text-700 fs-1">Credit Limit Avialable</h6>
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{formatNumber(avialableCreditLimit)}</p>
                            </Col>
                        </>
                            : null}

                    </Row>
                </Card.Body>
                <Card.Footer className="bg-light text-end" >
                    <span className=''>Print Date: {new Date().toLocaleDateString()}</span>
                </Card.Footer>
            </Card>

            <div>
                {/* {
                    userRole === 'Finance' && (<Button variant="success" className='me-2' onClick={() => handleForwardToSIO(ordersInfo, userInfo)}>Forward Back to SIO</Button>)
                } */}
                <Button variant="primary" className='me-2 px-5' onClick={() => navigate(-1)}>Back</Button>
            </div>
        </div>
    );
};

export default Index;
